import {
  CloseOutlined,
  LoadingOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { RichTextArea, RichTextAreaRef } from '@web/components/RichTextArea';
import { UserAvatar } from '@web/components/UserAvatar';
import { Row } from '@web/components/layout';
import { Button, message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { ActionBar, EditCommentContainer, SendButton } from './styles';

const EMPTY_COMMENT_STRING = '<p><br></p>';
interface NewCommentProps {
  onSend: () => void;
  onDiscard?: () => void;
  entityToken: string;
  autoFocus?: boolean;
  visibleName?: 'comment' | 'note';
  disabled?: boolean;
  showUserAvatar?: boolean;
}
export const NewComment: React.FC<NewCommentProps> = ({
  entityToken,
  onSend,
  onDiscard,
  autoFocus = false,
  visibleName = 'comment',
  disabled = false,
  showUserAvatar = false,
}) => {
  const { user } = useAuth();
  const [isSaving, setIsSaving] = React.useState(false);
  const [comment, setComment] = React.useState(EMPTY_COMMENT_STRING);
  const textareaRef = React.useRef<RichTextAreaRef>();

  const handleSend = async () => {
    if (!comment || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      await post(`/comments`, { text: comment, entityToken });
      void message.success('Success');
      if (textareaRef.current) {
        textareaRef.current.setContents(EMPTY_COMMENT_STRING);
      }
      onSend();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDiscard = () => {
    if (textareaRef.current) {
      textareaRef.current.setContents(EMPTY_COMMENT_STRING);
    }
    onDiscard();
  };

  const isCommentEmpty = comment === EMPTY_COMMENT_STRING;
  return (
    <Row style={{ width: '100%', gap: 10, alignItems: 'flex-start' }}>
      {showUserAvatar && (
        <UserAvatar user={user} size={24} style={{ marginTop: 5 }} />
      )}
      <EditCommentContainer>
        <RichTextArea
          initialValue={comment}
          onChange={setComment}
          placeholder={`Add a ${visibleName}`}
          autoFocus={autoFocus}
          disabled={disabled}
          ref={textareaRef}
        />
        <ActionBar>
          {onDiscard &&
            (isCommentEmpty ? (
              <Button type="text" size="small" style={{ width: 36 }}>
                <CloseIcon onClick={handleDiscard} />
              </Button>
            ) : (
              <ConfirmButton
                onConfirm={handleDiscard}
                description={`Discard ${visibleName}?`}
                type="text"
                size="small"
                style={{ width: 36 }}
              >
                <CloseIcon />
              </ConfirmButton>
            ))}
          <SendButton
            disabled={isCommentEmpty || isSaving} // TODO how to really know if no content exists?
            title={`Add ${visibleName}`}
            onClick={handleSend}
          >
            {isSaving ? (
              <LoadingOutlined style={{ fontSize: 14 }} spin />
            ) : (
              <SendOutlined style={{ fontSize: 14 }} />
            )}
          </SendButton>
        </ActionBar>
      </EditCommentContainer>
    </Row>
  );
};

const CloseIcon = styled(CloseOutlined)`
  svg {
    font-size: 14px;
  }
`;
