import { differenceInDays, format } from 'date-fns';

export const formatLongDate = (
  dateOrString: string | Date,
  includeTime = false,
) => {
  const date = new Date(dateOrString);
  const olderThanOneYear = differenceInDays(new Date(), date) >= 365;
  const formatString = `MMMM d${olderThanOneYear ? ', yyyy' : ''}${
    includeTime ? ' h:mmaaa' : ''
  }`;
  return format(date, formatString);
};
