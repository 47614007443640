import { IFeedback, UserMapItem } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { useAuth } from '@web/auth/useAuth';
import { patch } from '@web/common/api';
import * as React from 'react';

import { FeedbackItem } from './FeedbackItem';
import { UserFeedback } from './UserFeedback';

export const BasicFeedback: React.FC<{
  feedback: IFeedback;
  giver: UserMapItem;
  receiver: UserMapItem;
}> = ({ feedback, receiver, giver }) => {
  const { user } = useAuth();
  const isGiver = user.token === giver?.token;
  const isReceiver = user.token === receiver?.token;
  const isManager = isManagerOf(receiver, user.token);
  const feebackDate = feedback.updatedDate;
  const handleSave = async (text: string) => {
    await patch(`/feedback/${feedback.token}/question`, { text });
  };

  return (
    <UserFeedback giver={giver} receiver={receiver} date={feebackDate}>
      <FeedbackItem
        response={feedback.responses?.[0]}
        feedbackRequest={feedback.feedbackRequest}
        receiver={receiver}
        onSave={isGiver ? handleSave : undefined}
        readonly={!isGiver && !isReceiver && !isManager}
      />
    </UserFeedback>
  );
};
