/* eslint-disable @typescript-eslint/naming-convention */
import { GithubOutlined } from '@ant-design/icons';
import { IGithubPull, parsePullUrl } from '@shared/github';
import { Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Tag } from 'antd';
import React from 'react';

export const PullRequestItem: React.FC<{ pull: IGithubPull }> = ({ pull }) => {
  const { owner, repo, number } = parsePullUrl(pull.url);
  return (
    <Row gap={6}>
      <GithubOutlined style={{ color: '#333' }} />
      <Text style={{ lineHeight: '18px' }}>
        {pull.title} #{number}
      </Text>
      <Tag style={{ margin: 0 }}>
        {owner}/{repo}
      </Tag>
    </Row>
  );
};

export const PullRequestShortItem: React.FC<{ pull: IGithubPull }> = ({
  pull,
}) => {
  const { owner, repo, number } = parsePullUrl(pull.url);
  return (
    <a href={pull.url} target="_blank" rel="noreferrer">
      <Tag style={{ margin: 0 }}>
        <Row>
          <GithubOutlined style={{ color: '#333' }} />
          <Spacer size={6} />
          {owner}/{repo}/{number}
        </Row>
      </Tag>
    </a>
  );
};
