import { useQuestionSet } from '@client/QuestionSetsClient';
import { IQuestionSet, getQuestionPositions } from '@shared/questionSets';
import { IQuestionResponse } from '@shared/question_response';
import { IFeedback, UserMapItem } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { QuestionResponses } from '@web/questions/QuestionResponses';
import * as React from 'react';

import { Text } from '../../components/typography';
import { UserFeedback } from './UserFeedback';

export const TemplatedUserFeedback: React.FC<{
  feedback: IFeedback;
  giver: UserMapItem;
  receiver: UserMapItem;
  requester?: UserMapItem;
}> = ({ feedback, giver, receiver, requester }) => {
  if (!feedback.feedbackRequest?.questionSetToken) {
    return null;
  }

  const { data: questionSet } = useQuestionSet(
    feedback.feedbackRequest?.questionSetToken,
  );

  if (!questionSet) {
    return null;
  }

  const orderedResponses = feedback.responses
    ? sortResponsesByQuestionSet(questionSet, feedback.responses)
    : [];

  return (
    <UserFeedback giver={giver} receiver={receiver} date={feedback.updatedDate}>
      {receiver && <FeedbackTitle receiver={receiver} />}
      <QuestionResponses
        responses={orderedResponses}
        receiverName={receiver.name}
        questions={questionSet.questions}
        hideNullResponses
      />
    </UserFeedback>
  );
};

const FeedbackTitle: React.FC<{
  receiver: UserMapItem;
}> = ({ receiver }) => {
  const { user } = useAuth();
  const isYou = receiver.token === user.token;

  return (
    <Text>
      Feedback requested for {receiver.name}
      {isYou && ' (you)'}
    </Text>
  );
};

const sortResponsesByQuestionSet = (
  questionSet: IQuestionSet,
  responses?: IQuestionResponse[],
) => {
  if (!responses) {
    return [];
  }

  const questionPositions = getQuestionPositions(questionSet);
  return (
    responses?.sort(
      (a, b) =>
        questionPositions[a.questionToken as any] -
        questionPositions[b.questionToken as any],
    ) ?? []
  );
};
