import { dateString } from '@shared/dateString';
import { Column, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Progress } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const Status: React.FC<{
  children?: React.ReactNode;
  title: string;
  startDate?: Date;
  endDate?: Date;
  text?: React.ReactNode;
  progressPercent?: number;
  progressSuffix?: string;
  actions?: React.ReactNode;
}> = ({
  children,
  text,
  title,
  startDate,
  endDate,
  progressPercent,
  progressSuffix = 'completed',
  actions,
}) => {
  return (
    <StatusContainer>
      <Column>
        <Header3>{title}</Header3>
        <Text>
          {dateString(startDate)} - {dateString(endDate)}
        </Text>
      </Column>
      {text && <Text>{text}</Text>}
      {progressPercent !== undefined && (
        <Row gap={6} style={{ maxWidth: 300 }}>
          <Progress
            percent={progressPercent}
            style={{ margin: 0 }}
            showInfo={false}
          />
          <Text style={{ whiteSpace: 'nowrap' }}>
            {progressPercent}% {progressSuffix}
          </Text>
        </Row>
      )}
      {children}
      {actions && (
        <Row gap={6} style={{ marginTop: 6 }}>
          {actions}
        </Row>
      )}
    </StatusContainer>
  );
};
const StatusContainer = styled(Column)`
  gap: 6px;
`;
