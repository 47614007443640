import { ITagGroup } from '@shared/tags';
import { FormButtons } from '@web/components/FormButtons';
import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';

export interface TagGroupFormProps {
  onSubmit: (fields: TagGroupFormFields) => void;
  onCancel?: () => void;

  initialTagGroup?: ITagGroup;
}

export interface TagGroupFormFields {
  name: string;
}

export const TagGroupForm: React.FC<TagGroupFormProps> = ({
  onSubmit,
  onCancel,
  initialTagGroup,
}) => {
  const [form] = Form.useForm<TagGroupFormFields>();
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    let fields: TagGroupFormFields;
    try {
      fields = await form.validateFields();
    } catch (error) {
      setIsSaving(false);
      return;
    }

    try {
      await onSubmit(fields);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Form form={form} layout="vertical" disabled={isSaving}>
      <Form.Item label="Name" name="name" initialValue={initialTagGroup?.name}>
        <Input />
      </Form.Item>
      <FormButtons>
        <Button
          type="primary"
          disabled={isSaving}
          onClick={() => {
            void handleSave();
          }}
        >
          Save
        </Button>
        {onCancel && (
          <Button
            disabled={isSaving}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        )}
      </FormButtons>
    </Form>
  );
};
