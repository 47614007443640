import { GithubOutlined, SearchOutlined } from '@ant-design/icons';
import { GithubPullToken, IGithubPull } from '@shared/github';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { Column } from '@web/components/layout';
import { Button, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

import { PullRequestItem } from '../../components/PullRequestItem';
import { WorkItems } from './WorkItems';
import { useGithubPullSearch } from './useGithubPullSearch';

interface Props {
  onChange: (pulls: IGithubPull[]) => void;
  buttonStyle?: React.CSSProperties;
  omitTokens?: GithubPullToken[];
  initialPulls: IGithubPull[];
}

export const SelectPullRequests: React.FC<Props> = ({
  onChange,
  buttonStyle,
  omitTokens,
  initialPulls,
}) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = (pulls: IGithubPull[]) => {
    onChange(pulls);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={showModal} style={buttonStyle}>
        <GithubOutlined /> Select pull requests
      </Button>
      <SelectPullRequestsModal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        omitTokens={omitTokens}
        initialPulls={initialPulls}
      />
    </>
  );
};

interface SelectPullRequestsModalProps {
  onCancel: () => void;
  onOk: (pulls: IGithubPull[]) => void;
  open: boolean;
  omitTokens?: GithubPullToken[];
  initialPulls: IGithubPull[];
}

const SelectPullRequestsModal: React.FC<SelectPullRequestsModalProps> = ({
  onOk,
  onCancel,
  open,
  omitTokens,
  initialPulls,
}) => {
  const [selectedTokens, setSelectedTokens] = useState<Set<GithubPullToken>>(
    new Set<GithubPullToken>(initialPulls.map((pull) => pull.token)),
  );

  const [query, setQuery] = React.useState('');
  const { pulls, pullsMap, search } = useGithubPullSearch(
    {
      omitTokens,
    },
    initialPulls,
  );
  React.useEffect(() => {
    if (open) {
      void search(query);
    }
  }, [open, query, omitTokens]);

  React.useEffect(() => {
    setSelectedTokens(
      new Set<GithubPullToken>(initialPulls.map((pull) => pull.token)),
    );
  }, [initialPulls]);

  const handleClose = () => {
    onCancel();
    setQuery('');
  };

  const handleSelect = (pull: IGithubPull) => {
    const newSelectedTokens = new Set(selectedTokens);
    if (selectedTokens.has(pull.token)) {
      newSelectedTokens.delete(pull.token);
    } else {
      newSelectedTokens.add(pull.token);
    }
    setSelectedTokens(newSelectedTokens);
  };

  const handleRemove = (token: GithubPullToken) => {
    if (selectedTokens.has(token)) {
      const newSelectedTokens = new Set(selectedTokens);
      newSelectedTokens.delete(token);
      setSelectedTokens(newSelectedTokens);
    }
  };

  const selectedPullTokens = Array.from(selectedTokens.values());
  const selectedPulls = selectedPullTokens.map((token) => pullsMap.get(token));

  const handleOk = () => {
    onOk(selectedPulls);
    setQuery('');
  };

  const selectablePulls = pulls.filter(
    (pull) => !selectedTokens.has(pull.token),
  );

  return (
    <Modal
      title="Select pull requests"
      open={open}
      afterClose={handleClose}
      onCancel={onCancel}
      width="800px"
      okButtonProps={{
        disabled: selectedTokens.size === 0,
        style: { width: 100 },
      }}
      cancelButtonProps={{ style: { width: 100 } }}
      onOk={handleOk}
    >
      <Column gap={12}>
        <WorkItems pulls={selectedPulls} onRemove={handleRemove} />
        <Input
          value={query}
          onChange={(e) => {
            setQuery(e.currentTarget.value);
          }}
          autoFocus
          allowClear
          prefix={<SearchOutlined />}
        />
        <Results>
          {selectablePulls.map((pull) => (
            <UserResult
              onClick={() => {
                handleSelect(pull);
              }}
              key={pull.token}
            >
              <PullRequestItem pull={pull} />
            </UserResult>
          ))}
        </Results>
      </Column>
    </Modal>
  );
};

const Results = styled(Column)`
  border: 1px solid #ddd;
  border-radius: var(--default-border-radius);
  height: 182px;
  overflow: auto;
`;
const UserResult = styled(Row)`
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background: ${complimentaryColor.lighten(0.9)};
  }

  &.selected {
    background: ${complimentaryColor.lighten(0.7)};
  }
`;
