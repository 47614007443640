import { UserMapItem } from '@shared/types';
import { useTime } from '@web/common/useTime';
import { Small } from '@web/components/typography';
import * as React from 'react';
import styled from 'styled-components';

export const LastTyped: React.FC<{
  otherUser: UserMapItem;
  lastTyped: number;
}> = ({ otherUser, lastTyped }) => {
  const now = useTime();
  if (now - lastTyped > 5000) {
    return null;
  }

  return <StyledText>{otherUser.name} is typing</StyledText>;
};
const StyledText = styled(Small)`
  position: relative;
  top: -22px;
  color: #888;
`;
