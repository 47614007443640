import { useListGoals } from '@client/GoalsClient';
import { IGoal, isAssignedGoal, isPersonalGoal } from '@shared/goals';
import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { MiniGoalStateIndicator } from '@web/goals/GoalStateIndicator';
import { Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const OneOnOneGoals: React.FC<{ userToken: UserToken }> = ({
  userToken,
}) => {
  const { user } = useAuth();

  const { data: goalsResponse } = useListGoals(false, userToken);
  if (!goalsResponse) {
    return <Skeleton />;
  }
  const goals: IGoal[] =
    goalsResponse?.goals?.filter(
      (goal) => isPersonalGoal(goal) || isAssignedGoal(userToken)(goal),
    ) ?? [];
  const isAuthor = user.token === userToken;
  return (
    <Column>
      <Link to={isAuthor ? `/goals` : `/team/${userToken}/goals`}>
        <Header2>Goals</Header2>
      </Link>
      <Spacer size={8} />
      <Column gap={6}>
        {goals.map((goal) => (
          <Row key={goal.token} gap={12}>
            <MiniGoalStateIndicator state={goal.state} />
            <Link
              to={
                isAuthor
                  ? `/goals/${goal.token}`
                  : `/team/${userToken}/goals/${goal.token}`
              }
            >
              <Text style={{ lineHeight: '18px' }}>{goal.title}</Text>
            </Link>
          </Row>
        ))}
      </Column>
      {goalsResponse.goals.length === 0 && <Text>No active goals</Text>}
    </Column>
  );
};
