import { METRIC_COLORS } from '@shared/analytics';
import { Grid, Row } from '@web/components/layout';
import { Header1 } from '@web/components/typography';
import { Progress } from 'antd';
import * as React from 'react';

export const CircleProgressMetric: React.FC<{
  value: number;
  total: number;
  color?: string;
  suffix?: React.ReactNode;
}> = ({ value, total, color = METRIC_COLORS[3], suffix }) => {
  return (
    <Grid
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gap={12}
      columns="1fr 1fr"
    >
      <Progress
        type="circle"
        percent={Math.round((value / total) * 100)}
        size={100}
        strokeLinecap="square"
        strokeColor={color}
        status="normal"
      />
      <Row gap={6}>
        <Header1 style={{ fontSize: 24, color: '#333' }}>{value}</Header1>
        {suffix}
      </Row>
    </Grid>
  );
};

export const ProgressMetric: React.FC<{
  value: number;
  total: number;
  color?: string;
}> = ({ value, total, color = METRIC_COLORS[3] }) => {
  return (
    <Row
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Progress
        percent={Math.round((value / total) * 100)}
        size={[150, 40]}
        strokeLinecap="square"
        strokeColor={color}
        format={(percent) => (
          <Header1 style={{ fontSize: 18 }}>{percent}%</Header1>
        )}
        style={{ whiteSpace: 'nowrap' }}
      />
    </Row>
  );
};
