import { IComment } from '@shared/comments';
import { ISearchResults } from '@shared/types';
import { del, get, patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function updateComment(commentToken: string, commentText: string) {
  return patch<{ text: string }>(`/comments/${commentToken}`, {
    text: commentText,
  });
}

export function removeComment(commentToken: string) {
  return del(`/comments/${commentToken}`);
}

export function addComment(entityToken: string, text: string) {
  return post<{
    entityToken: string;
    text: string;
  }>(`/comments`, {
    entityToken: entityToken,
    text: text,
  });
}

export function useEntityComments(entityToken: string) {
  const url = stringifyUrl({
    url: `/comments/${entityToken}`,
    query: {},
  });

  return useApi<ISearchResults<IComment>>(url);
}

export function entityComments(entityToken: string) {
  const url = stringifyUrl({
    url: `/comments/${entityToken}`,
    query: {},
  });

  return get<ISearchResults<IComment>>(url);
}
