import styled from 'styled-components';

export const InvisibleButton = styled.button`
  appearance: none;
  border: 0;
  background: unset;
  height: 30px;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 4px;

  &:hover {
    background: #eee;
  }
`;
