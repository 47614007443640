import { IComment } from '@shared/comments';
import { ISearchResults } from '@shared/types';
import { get } from '@web/common/api';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import { ReactionSummaryContext } from '../reactions/ReactionSummaryContext';
import { NewComment } from './NewComment';
import { UserComment } from './UserComment';

interface Props {
  entityToken: string;
  commentsSearchResult?: ISearchResults<IComment>;
  onChange?: () => void;
  onHideNewComment?: () => void;
  alwaysShowNewComment?: boolean;
  showNewComment?: boolean;
  isReply?: boolean;
  readonly?: boolean;
  visibleName?: 'comment' | 'note';
  disabled?: boolean;
  showUserAvatar?: boolean;
}

export const CommentsList: React.FC<Props> = ({
  entityToken,
  commentsSearchResult,
  alwaysShowNewComment,
  onHideNewComment,
  showNewComment = false,
  isReply = false,
  readonly = false,
  visibleName = 'comment',
  disabled,
  showUserAvatar = false,
}) => {
  const [searchResults, setSearchResults] =
    React.useState(commentsSearchResult);
  const fetchComments = async () => {
    const results = await get<ISearchResults<IComment>>(
      `/comments/${entityToken}`,
    );
    setSearchResults(results);
  };
  React.useEffect(() => {
    if (!commentsSearchResult) {
      void fetchComments();
    }
  }, []);

  const {
    results: comments,
    userMap,
    reactionSummaryMap,
  } = searchResults ?? {};

  const handleCommentsChange = () => {
    void fetchComments();
    onHideNewComment?.();
  };

  if (!comments || comments?.length === 0) {
    if (alwaysShowNewComment && !readonly) {
      return (
        <Column gap={12}>
          <NewComment
            onSend={handleCommentsChange}
            entityToken={entityToken}
            visibleName={visibleName}
            disabled={disabled}
            showUserAvatar={showUserAvatar}
          />
        </Column>
      );
    }
  }

  return (
    <ReactionSummaryContext.Provider value={{ reactionSummaryMap, userMap }}>
      <Column gap={12}>
        {comments?.length > 0 ? (
          <CommentsContainer>
            {comments?.map((comment) => (
              <UserComment
                key={comment.token}
                comment={comment}
                author={userMap[comment.authorToken]}
                onChange={handleCommentsChange}
                isReply={isReply}
                canReact={!readonly}
                visibleName={visibleName}
              />
            ))}
          </CommentsContainer>
        ) : alwaysShowNewComment ? (
          <Text style={{ margin: '8px 0' }}>
            There are no {pluralize(visibleName, 0)}
          </Text>
        ) : null}
        {(showNewComment || alwaysShowNewComment) && !readonly && (
          <NewComment
            autoFocus={showNewComment}
            onSend={handleCommentsChange}
            onDiscard={showNewComment ? onHideNewComment : undefined}
            entityToken={entityToken}
            visibleName={visibleName}
            showUserAvatar={showUserAvatar}
          />
        )}
      </Column>
    </ReactionSummaryContext.Provider>
  );
};

const CommentsContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;
