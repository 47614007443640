import { LogoutOutlined } from '@ant-design/icons';
import { overrideBuildExpiry, useBuildInfo } from '@client/AuthClient';
import { formatDate } from '@shared/formatDate';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { SelectDateTime } from '@web/components/SelectDateTime';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { Button, message } from 'antd';
import { addDays } from 'date-fns';
import * as React from 'react';

export const AppConfigPage: React.FC = () => {
  const { confirm, contextHolder } = useModalConfirm();
  const { logout, user } = useAuth();
  const { isMobile } = useResponsive();
  const { data: buildInfo, mutate: reloadBuildInfo } = useBuildInfo();
  const [forceRefreshExpiryOverride, setForceRefreshExpiryOverride] =
    React.useState(addDays(new Date(), -7));
  const handleOverride = async () => {
    const confirmed = await confirm(
      'Setting an override will force all users to refresh their browser tabs if they were loaded before the override date/time. Do you want to continue?',
      { title: '🚨 Force Refresh Override' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await overrideBuildExpiry(forceRefreshExpiryOverride.getTime());
      void reloadBuildInfo();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };
  React.useEffect(() => {
    if (buildInfo?.forceRefreshExpiryOverride) {
      setForceRefreshExpiryOverride(
        new Date(buildInfo.forceRefreshExpiryOverride),
      );
    }
  }, [buildInfo]);

  return (
    <PageContent>
      <PageHeader title={'Configuration'} mobileTitle={'Configuration'} />
      {isMobile && <Spacer />}
      <Column gap={24}>
        <Pane>
          <Header2>Build Info</Header2>
          <Spacer />
          <Text>
            <strong>Latest build hash</strong>
          </Text>
          <Text>{buildInfo?.latestBuildHash ?? 'N/A'}</Text>
          <Spacer />
          <Text>
            <strong>Latest build date</strong>
          </Text>
          <Text>
            {buildInfo?.latestBuildTimestamp
              ? formatDate(new Date(buildInfo.latestBuildTimestamp), true)
              : 'N/A'}
          </Text>
          <Spacer />
          <Text>
            <strong>Force refresh expiry</strong>
          </Text>
          <Text>
            {buildInfo?.forceRefreshExpiry
              ? formatDate(new Date(buildInfo.forceRefreshExpiry), true)
              : 'N/A'}
          </Text>
          <Spacer />
          <Text>
            <strong>Force refresh expiry override</strong>
          </Text>
          <Row gap={6}>
            <SelectDateTime
              value={forceRefreshExpiryOverride}
              onChange={setForceRefreshExpiryOverride}
            />
            <Button
              style={{ alignSelf: 'flex-start' }}
              onClick={handleOverride}
            >
              Override
            </Button>
          </Row>
        </Pane>
        <Column>
          <Text>You are signed in as {user.name}</Text>
          <Button
            onClick={logout}
            style={!isMobile ? { width: 200, marginTop: 24 } : undefined}
          >
            <LogoutOutlined /> Sign out
          </Button>
        </Column>
        {isMobile && <Spacer size={64} />}
      </Column>
      {contextHolder}
    </PageContent>
  );
};
