import { LoadingOutlined } from '@ant-design/icons';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/text';
import React from 'react';

export const LoadingDetails: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Column gap={24} style={{ alignItems: 'center', padding: 36 }}>
    <LoadingOutlined
      style={{ fontSize: 36, color: 'var(--ant-primary-color)' }}
    />
    <Text>{children}</Text>
  </Column>
);
