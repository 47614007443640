import { IReviewCycle } from '@shared/review-cycles';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReviewCyclesTable } from './ReviewCyclesTable';

const ReviewCyclesPage: React.FC = () => {
  const { data: reviewCycles } = useApi<IReviewCycle[]>('/review-cycles/list');
  const navigate = useNavigate();

  const handleReviewCycleClick = (reviewCycle: IReviewCycle) => {
    navigate(`/cycles/${reviewCycle.token}`);
  };

  return (
    <PageContent>
      <PageHeader title="Cycles" mobileTitle="Cycles" />
      <ReviewCyclesTable
        reviewCycles={reviewCycles}
        onReviewCycleClick={handleReviewCycleClick}
        hideParticipants
      />
    </PageContent>
  );
};

export default ReviewCyclesPage;
