import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const TabCss = css`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  cursor: pointer;
  color: #333;
`;

export const TabLink = styled(NavLink)<{ active?: boolean }>`
  ${TabCss}
  background: #e0e0e0;

  &.active {
    background-color: white;
  }
`;

export const TabButton = styled.div<{ active?: boolean }>`
  ${TabCss}
  background: ${(props) => (props.active ? 'white' : '#e0e0e0')};
`;

export const Tabs = styled.div<{ border?: boolean }>`
  display: flex;
  gap: 6px;

  ${(props) =>
    props.border
      ? css`
          ${TabButton}, ${TabLink} {
            border: 1px solid #ddd;
            position: relative;
            top: 1px;
            border-bottom: 0;
          }
        `
      : ''}
`;
