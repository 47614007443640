import { EllipsisOutlined } from '@ant-design/icons';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { Row } from '@web/components/layout';
import { Button, Dropdown, MenuProps, Modal, Typography } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { IPageAction } from './PageContext';
import { useResponsive } from './responsive';

export const PageActions: React.FC<{
  primaryAction?: IPageAction;
  secondaryActions?: IPageAction[];
}> = ({ primaryAction, secondaryActions }) => {
  const { isMobile } = useResponsive();
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();

  if (!primaryAction && !secondaryActions) {
    return null;
  }

  const secondaryMenuItems: MenuProps['items'] = (secondaryActions ?? [])
    ?.filter((action) => !!action)
    .map((action, index) => ({
      key: index,
      icon: action.icon,
      label: (
        <Typography.Text style={{ lineHeight: '40px' }}>
          {action.label}
        </Typography.Text>
      ),
      disabled: action.disabled,
      onClick: () => {
        handleActionClicked(action);
      },
    }));

  const handleActionConfirm = ({ linkTo, onClick }: IPageAction) => {
    if (linkTo) {
      navigate(linkTo);
    } else if (onClick) {
      onClick();
    }
  };

  const handleActionClicked = (action: IPageAction) => {
    if (action.confirm) {
      const {
        title,
        description,
        okText = 'Yes',
        cancelText = 'No',
      } = action.confirm;
      void modal.confirm({
        title,
        content: description,
        okText,
        cancelText,
        icon: null,
        onOk: () => handleActionConfirm(action),
      });
      return;
    }

    handleActionConfirm(action);
  };

  return (
    <ActionsContainer>
      {primaryAction && <PrimaryActionButton {...primaryAction} />}
      {secondaryMenuItems.length > 0 && (
        <Dropdown
          placement={isMobile ? 'topRight' : 'bottomRight'}
          menu={{ items: secondaryMenuItems }}
          trigger={['click']}
        >
          <Button>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )}
      {contextHolder}
    </ActionsContainer>
  );
};

const ActionsContainer = styled(Row)`
  gap: 6px;

  .ant-btn {
    min-width: 40px;
  }
`;

const PrimaryActionButton: React.FC<IPageAction> = ({
  icon,
  label,
  linkTo,
  onClick,
  disabled,
  confirm,
}) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    if (linkTo) {
      navigate(linkTo);
    } else if (onClick) {
      onClick();
    }
  };
  if (confirm) {
    return (
      <ConfirmButton
        onConfirm={handleConfirm}
        title={confirm.title}
        description={confirm.description}
        okText={confirm.okText}
        style={{ minWidth: 40 }}
      >
        {icon}
        {label}
      </ConfirmButton>
    );
  }

  return (
    <Button
      style={{ minWidth: 40 }}
      icon={icon}
      onClick={handleConfirm}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
