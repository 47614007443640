import {
  IJournalAlignmentIndicator,
  createJournalEntryLink,
  suggestJournalEntryLink,
} from '@shared/alignment';

export const journalAlignmentCallToActionLink = (
  isReport: boolean,
  indicator: IJournalAlignmentIndicator,
): string => {
  if (isReport) {
    return createJournalEntryLink(indicator.data);
  }
  return suggestJournalEntryLink(indicator);
};
