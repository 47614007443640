import { CloseOutlined } from '@ant-design/icons';
import { ITag } from '@shared/tags';
import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const StyledTag: React.FC<{
  onClose?: (tag: ITag) => void;
  tag: ITag;
}> = ({ onClose, tag }) => {
  const closeable = !!onClose;

  return (
    <Tag style={{ lineHeight: '24px' }}>
      {tag.label}
      {closeable && ' '}
      {closeable && (
        <CloseButton
          onClick={() => {
            onClose?.(tag);
          }}
        />
      )}
    </Tag>
  );
};

const CloseButton = styled(CloseOutlined)`
  cursor: pointer;
  margin-left: 3px;
`;
