import { entityNotes as getEntityNotes } from '@client/OneOnOnesClient';
import { IComment } from '@shared/comments';
import {
  ActionItemToken,
  AgendaItemToken,
  OneOnOneToken,
} from '@shared/one-on-one';
import { UserToken } from '@shared/types';
import { IWebSocketEvent, WebSocketEventType } from '@shared/webSocketEvents';
import { useWebSocketEvent } from '@web/common/useWebSocketEvent';
import { cloneDeep } from 'lodash';
import React, { createContext, useEffect, useState } from 'react';

type EntityNotes = Record<AgendaItemToken | ActionItemToken, IComment[]>;
type LastUpdates = Record<AgendaItemToken | ActionItemToken, Date>;
type UserPresence = Record<UserToken, AgendaItemToken | ActionItemToken | null>;
export interface IOneOnOneContextValue {
  entityNotes: EntityNotes;
  lastUpdates: LastUpdates;
  userPresence: UserPresence;
}

export const OneOnOneContext = createContext<IOneOnOneContextValue>({
  entityNotes: {},
  lastUpdates: {},
  userPresence: {},
});

export const OneOnOneProvider: React.FC<{
  oneOnOneToken?: OneOnOneToken;
  children: React.ReactNode;
}> = ({ oneOnOneToken, children }) => {
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.PRESENCE,
    (
      event: IWebSocketEvent<{
        entityToken: AgendaItemToken | ActionItemToken | null;
        userToken: UserToken;
      }>,
    ) => {
      const { userToken, entityToken } = event.payload;
      setUserPresence({
        ...userPresence,
        [userToken]: entityToken,
      });
    },
  );
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.ENTITY_NOTE_ADDED,
    (event: IWebSocketEvent<IComment>) => {
      const newNote = event.payload;
      const newEntityNotes = cloneDeep(entityNotes);
      if (!newEntityNotes[newNote.entityToken]) {
        newEntityNotes[newNote.entityToken] = [];
      }

      newEntityNotes[newNote.entityToken].push(newNote);
      setEntityNotes(newEntityNotes);
    },
  );
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.ENTITY_NOTE_UPDATED,
    (event: IWebSocketEvent<IComment>) => {
      const updatedNote = event.payload;
      const newEntityNotes = cloneDeep(entityNotes);
      const notes = newEntityNotes[updatedNote.entityToken];
      const existingIndex = notes.findIndex(
        (n) => n.token === updatedNote.token,
      );
      newEntityNotes[updatedNote.entityToken] = [
        ...notes.slice(0, existingIndex),
        updatedNote,
        ...notes.slice(existingIndex + 1),
      ];
      setEntityNotes(newEntityNotes);
    },
  );
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.ENTITY_NOTE_DELETED,
    (
      event: IWebSocketEvent<{
        entityToken: AgendaItemToken | ActionItemToken;
        deletedNoteToken: string;
      }>,
    ) => {
      const { entityToken, deletedNoteToken } = event.payload;
      const newEntityNotes = cloneDeep(entityNotes);
      newEntityNotes[entityToken] = newEntityNotes[entityToken].filter(
        (note) => note.token !== deletedNoteToken,
      );
      setEntityNotes(newEntityNotes);
    },
  );
  const fetchEntityNotes = async (oneOnOneToken: OneOnOneToken) => {
    try {
      const notes = await getEntityNotes(oneOnOneToken);
      setEntityNotes(notes);
    } catch (error) {
      // todo what to do here?
    }
  };
  const [entityNotes, setEntityNotes] = useState<EntityNotes>({});
  const [userPresence, setUserPresence] = useState<UserPresence>({});
  useEffect(() => {
    if (oneOnOneToken) {
      void fetchEntityNotes(oneOnOneToken);
    }
  }, [oneOnOneToken]);

  const lastUpdates: LastUpdates = {};
  for (const token of Object.keys(entityNotes)) {
    if (entityNotes[token].length === 0) {
      continue;
    }

    const lastNoteIndex = entityNotes[token].length - 1;
    if (entityNotes[token][lastNoteIndex]) {
      lastUpdates[token] = new Date(
        entityNotes[token][lastNoteIndex].updatedDate,
      );
    }
  }

  return (
    <OneOnOneContext.Provider
      value={{ entityNotes, lastUpdates, userPresence }}
    >
      {children}
    </OneOnOneContext.Provider>
  );
};
