import * as React from 'react';

import { Row } from './layout';

export interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const stopPropagation = (e: any) => {
  e.stopPropagation();
  e.preventDefault();
  return false;
};

export const stopPropagationHandlers = {
  onClick: stopPropagation,
  onMouseDown: stopPropagation,
  onMouseUp: stopPropagation,
};

export const StopPropagation: React.FC<Props> = ({ children, style }) => {
  return (
    <Row gap={6} {...stopPropagationHandlers} style={style}>
      {children}
    </Row>
  );
};

export const StopPropagationSpan: React.FC<Props> = ({ children, style }) => {
  return (
    <span {...stopPropagationHandlers} style={style}>
      {children}
    </span>
  );
};
