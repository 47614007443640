import { IUser, UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { UserPicker } from '@web/components/UserPicker';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export interface ReflectionUserPickerProps {
  user?: IUser;
}

export const ReflectionUserPicker: React.FC<ReflectionUserPickerProps> = ({
  user,
}) => {
  const { userToken } = useParams<{ userToken: UserToken }>();
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();
  const { data: reports } = useApi<IUser[], ServerResponseError>(
    '/users/reports',
  );

  if (!reports) {
    return null;
  }

  const handleUserPicked = (userToken: UserToken) => {
    const pickedUser =
      currentUser.token === userToken
        ? currentUser
        : reports.find((report) => report.token === userToken);
    if (pickedUser.token === currentUser.token) {
      navigate(`/reflections`);
    } else {
      navigate(`/team/${pickedUser.token}/reflections`);
    }
  };

  return (
    <UserPicker
      users={[currentUser, ...reports]}
      onChange={handleUserPicked}
      selectedUserToken={userToken ?? currentUser.token}
    />
  );
};
