import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { IQuestionResponse } from '@shared/question_response';
import { IQuestion } from '@shared/questions';
import {
  FeedbackVisibility,
  IFeedbackRequest,
  UserMapItem,
} from '@shared/types';
import { ActionBar } from '@web/comments/ActionBar';
import { EditedLabel } from '@web/components/EditedLabel';
import { Column, Row } from '@web/components/layout';
import { renderReceiverQuestionText } from '@web/questions/renderReceiverQuestionText';
import { Button, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as React from 'react';

import { Header3, Text } from '../../components/typography';
import { PrivateFeedbackTag } from './PrivateFeedbackTag';

export const FeedbackItem: React.FC<{
  response?: IQuestionResponse;
  feedbackRequest?: IFeedbackRequest;
  giver?: UserMapItem;
  receiver?: UserMapItem;
  visibility?: FeedbackVisibility;
  onSave?: (text: string) => Promise<void>;
  onReply?: () => void;
  hideVisibility?: boolean;
  readonly?: boolean;
}> = ({
  response,
  feedbackRequest,
  receiver,
  visibility,
  onReply,
  onSave,
  hideVisibility,
  readonly = false,
}) => {
  const [editing, setEditing] = React.useState(false);

  if (!response?.text) {
    return null;
  }

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async (text: string) => {
    if (onSave) {
      await onSave(text);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  return (
    <Column gap={6} style={{ maxWidth: 800 }}>
      <FeedbackQuestion
        feedbackRequest={feedbackRequest}
        question={response.question}
        receiverName={receiver?.name}
      />
      <FeedbackText
        prefix={
          !hideVisibility &&
          receiver && (
            <PrivateFeedbackTag
              visibility={visibility ?? (response.visibility as any)}
              receiver={receiver}
            />
          )
        }
        response={response}
        onSave={onSave ? handleSave : undefined}
        onCancel={handleCancel}
        onEdit={onSave ? handleEdit : undefined}
        editing={editing}
        onReply={onReply}
        readonly={readonly}
      />
    </Column>
  );
};

const FeedbackQuestion: React.FC<{
  feedbackRequest?: IFeedbackRequest;
  question?: IQuestion;
  receiverName?: string;
}> = ({ feedbackRequest, question, receiverName }) => {
  if (!question && !feedbackRequest) {
    return null;
  }

  const questionText = question
    ? renderReceiverQuestionText(question, receiverName)
    : feedbackRequest?.text;
  return (
    <Header3
      style={{
        marginRight: 12,
      }}
    >
      {questionText ?? feedbackRequest?.text}
    </Header3>
  );
};

const FeedbackText: React.FC<{
  response: IQuestionResponse;
  onSave?: (text?: string) => any;
  onCancel?: () => void;
  prefix?: React.ReactNode;
  editing: boolean;
  onEdit?: () => void;
  onReply?: () => void;
  readonly?: boolean;
}> = ({
  response,
  onSave,
  prefix,
  onCancel,
  editing,
  onEdit,
  onReply,
  readonly = false,
}) => {
  const [saveError, setSaveError] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [previousText, setPreviousText] = React.useState(response.text);
  const [updatedDate, setUpdatedDate] = React.useState(response.updatedDate);
  const [text, setText] = React.useState(response.text);

  const handleSave = async () => {
    setSaveError(false);
    setSaving(true);
    try {
      if (onSave) {
        await onSave(text);
      }
      setPreviousText(text);
      setUpdatedDate(new Date());
    } catch (error) {
      setSaveError(true);
    } finally {
      setSaving(false);
    }
  };
  const handleCancel = () => {
    setText(previousText);
    if (onCancel) {
      onCancel();
    }
  };

  React.useEffect(() => {
    if (response) {
      setText(response.text);
    }
  }, [response?.text]);

  if (editing) {
    return (
      <Column gap={12}>
        <TextArea
          disabled={saving}
          value={text}
          onChange={(e) => {
            void setText(e.target.value);
          }}
        />
        <Row gap={12}>
          <Button
            style={{ width: 120 }}
            onClick={handleSave}
            type="primary"
            disabled={saving}
          >
            Save {saving && <LoadingOutlined />}
          </Button>
          <Button
            style={{ width: 120 }}
            onClick={handleCancel}
            disabled={saving}
          >
            Cancel
          </Button>
          {saveError && (
            <Typography.Text style={{ color: 'var(--color-error)' }}>
              <WarningOutlined /> Something went wrong
            </Typography.Text>
          )}
        </Row>
      </Column>
    );
  }

  return (
    <Column gap={8}>
      {prefix}
      <Text
        style={{
          whiteSpace: 'pre-wrap',
        }}
      >
        {text}
      </Text>
      <EditedLabel
        entity={{ createdDate: response.createdDate, updatedDate }}
      />
      <Row style={{ width: '100%' }}>
        <ActionBar
          entityToken={response.token}
          onEdit={readonly ? undefined : onEdit}
          onReply={readonly ? undefined : onReply}
          canReact={!readonly}
        />
      </Row>
    </Column>
  );
};
