import { LogoutOutlined } from '@ant-design/icons';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Spacer } from '@web/components/layout';
import { Header2 } from '@web/components/typography';
import { Button } from 'antd';
import * as React from 'react';

import { UserNotificationSettings } from './UserNotificationSettings';

const UserSettingsPage: React.FC = () => {
  const { logout } = useAuth();
  const { isMobile } = useResponsive();

  return (
    <PageContent>
      <PageHeader title={'Settings'} mobileTitle={'Settings'} />
      {isMobile && <Spacer />}
      <Column gap={24}>
        <Pane>
          <Header2>Notifications</Header2>
          <Spacer />
          <UserNotificationSettings />
        </Pane>
        <Button
          onClick={logout}
          style={!isMobile ? { width: 200, marginTop: 24 } : undefined}
        >
          <LogoutOutlined /> Sign out
        </Button>
        {isMobile && <Spacer size={64} />}
      </Column>
    </PageContent>
  );
};

export default UserSettingsPage;
