import { LoadingOutlined } from '@ant-design/icons';
import { Row, Spacer } from '@web/components/layout';
import { Checkbox } from 'antd';
import * as React from 'react';

import { InlineInput } from '../InlineInput';
import { DragAnchor, NewRow, RowItem } from './TaskItemRow';

export const NewTaskItem: React.FC<{
  onAdd: (text: string) => Promise<void>;
  onHide: () => void;
}> = ({ onAdd, onHide }) => {
  const [saving, setSaving] = React.useState(false);
  const [text, setText] = React.useState<string | null>(null);

  const handleSave = async () => {
    if (!text) {
      onHide();
      return;
    }

    setSaving(true);
    const trimmed = text.trim();
    setText(trimmed);
    try {
      await onAdd(trimmed);
    } finally {
      setSaving(false);
    }
  };

  return (
    <NewRow gap={6}>
      <Row style={{ paddingTop: 4 }}>
        <RowItem>
          <DragAnchor style={{ opacity: 0 }} />
        </RowItem>
        <RowItem>
          <Checkbox />
        </RowItem>
      </Row>
      <InlineInput
        value={text}
        onChange={setText}
        placeholder="Add a description"
        onBlur={handleSave}
        onEnter={handleSave}
        autofocus
        style={{
          padding: '5px 6px',
          color: '#515c65',
          lineHeight: '18px',
          position: 'relative',
          left: 0,
          flex: 1,
        }}
        disabled={saving}
      />
      {saving ? (
        <div style={{ width: 24 }}>
          <LoadingOutlined
            style={{ marginTop: 8, color: 'var(--primary-color)' }}
          />
        </div>
      ) : (
        <Spacer size={24} />
      )}
    </NewRow>
  );
};
