import { IImpact } from '@shared/impacts';
import { IFeedbackResponse } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { Column, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Divider } from 'antd';
import { groupBy } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { JournalEntryFeedback } from './JournalEntryFeedback';

interface Props {
  impactEntry: IImpact;
  feedbackResponse: IFeedbackResponse;
  readonly?: boolean;
}

export const ImpactFeedbackList: React.FC<Props> = ({
  impactEntry,
  feedbackResponse,
  readonly = false,
}) => {
  const { user } = useAuth();

  if (!feedbackResponse) {
    return null;
  }

  const isAuthor = impactEntry.user.token === user.token;
  const feedbackByUser = groupBy(feedbackResponse.feedback, 'giverToken');
  const giverTokens = Object.keys(feedbackByUser).filter(
    (giverToken) => giverToken !== user.token,
  );

  return giverTokens.length > 0 ? (
    <Column>
      <Divider style={{ margin: '24px 0' }} />
      <Text>
        {isAuthor
          ? 'Feedback is visible to you and your manager unless marked otherwise.'
          : `Feedback is visible to both you and ${impactEntry.user.name} unless marked otherwise.`}
      </Text>
      <Spacer />
      <FeedbackContainer>
        {giverTokens.map((giverToken, index) => (
          <React.Fragment key={giverToken}>
            {index > 0 && <Divider style={{ margin: '24px 0' }} />}
            <JournalEntryFeedback
              receiver={impactEntry.user}
              giver={feedbackResponse.userMap[giverToken]}
              responses={feedbackByUser[giverToken][0].responses}
              readonly={readonly}
            />
          </React.Fragment>
        ))}
      </FeedbackContainer>
    </Column>
  ) : null;
};

const FeedbackContainer = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: var(--default-border-radius);
  margin-bottom: 24px;
`;
