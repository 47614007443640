import {
  CheckOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { IComment } from '@shared/comments';
import { del, patch } from '@web/common/api';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { RichTextArea } from '@web/components/RichTextArea';
import { Row } from '@web/components/layout';
import { message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { ActionBar, EditCommentContainer, SaveButton } from './styles';

interface EditCommentProps {
  onSave: () => void;
  comment: IComment;
  visibleName?: 'comment' | 'note';
}
export const EditComment: React.FC<EditCommentProps> = ({
  comment,
  onSave,
  visibleName = 'comment',
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [commentText, setCommentText] = React.useState(comment.text);

  const handleSave = async () => {
    if (!comment || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      await patch(`/comments/${comment.token}`, { text: commentText });
      void message.success('Success');
      onSave();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      await del(`/comments/${comment.token}`);
      void message.success('Success');
      onSave();
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Row style={{ width: '100%', gap: 10 }}>
      <EditCommentContainer>
        <RichTextArea initialValue={commentText} onChange={setCommentText} />
        <ActionBar>
          <ConfirmButton
            onConfirm={handleDelete}
            description={`Delete ${visibleName}?`}
            size="small"
            type="text"
            style={{ width: 36 }}
          >
            <DeleteIcon />
          </ConfirmButton>
          <SaveButton
            disabled={comment.text === commentText || isSaving}
            title={`Update ${visibleName}`}
            onClick={handleSave}
          >
            {isSaving ? (
              <LoadingOutlined style={{ fontSize: 14 }} spin />
            ) : (
              <CheckOutlined style={{ fontSize: 14 }} />
            )}
          </SaveButton>
        </ActionBar>
      </EditCommentContainer>
    </Row>
  );
};

const DeleteIcon = styled(DeleteOutlined)`
  svg {
    font-size: 14px;
  }
`;
