interface TokenEntity<T extends string = string> {
  token: T;
}

export const mapByToken = <ItemType extends TokenEntity<T>, T extends string>(
  items: ItemType[] = [],
  existingMap?: Map<ItemType['token'], ItemType>,
) => {
  const itemMap = new Map<ItemType['token'], ItemType>(existingMap);
  for (const item of items) {
    itemMap.set(item.token, item);
  }
  return itemMap;
};

export const mapByProp = <PropertyType, ItemType>(
  items: ItemType[] = [],
  propertyName: string,
) => {
  const itemMap = new Map<PropertyType, ItemType>();
  for (const item of items) {
    itemMap.set(item[propertyName], item);
  }
  return itemMap;
};
