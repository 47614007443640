import { IPeerReview, PeerReviewToken } from '@shared/review-cycles';
import { OrganizationToken, UserMapItem, UserToken } from '@shared/types';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { Modal, Skeleton, message } from 'antd';
import React, { useEffect, useState } from 'react';

export const ModifyPeersModal: React.FC<{
  onCancel: () => void;
  onSave: () => void;
  organizationToken: OrganizationToken;
  peerReviewToken: PeerReviewToken;
  user: UserMapItem;
}> = ({ onSave, onCancel, organizationToken, peerReviewToken, user }) => {
  const { data: peerReview, mutate: reloadPeerReview } = useApi<IPeerReview>(
    `/peer-reviews/${peerReviewToken}`,
  );
  const [approvedPeers, setApprovedPeers] = useState<UserToken[]>([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const initialPeers = peerReview?.approvedPeers;
  useEffect(() => {
    if (initialPeers) {
      const initialPeerTokens = initialPeers.map((peer) => peer.token);
      setApprovedPeers(initialPeerTokens);
    }
  }, [initialPeers]);

  const handleOk = async () => {
    setIsSaving(true);

    try {
      await post<{ approvedPeers: UserToken[] }>(
        `/peer-reviews/${peerReviewToken}/modifyPeers`,
        { approvedPeers },
      );
      void message.success('Success');
      void reloadPeerReview();
      onSave();
    } catch (error) {
      void message.error('Failed to modify peers');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleUsersSelected = (userTokens: UserToken[]) => {
    setApprovedPeers(userTokens);
  };

  return (
    <Modal
      title="Modify Peer Selection"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Submit"
      okButtonProps={{
        loading: !peerReview,
        disabled: approvedPeers.length === 0,
      }}
    >
      {peerReview ? (
        <SelectUsers
          style={{ width: '100%' }}
          organizationToken={organizationToken}
          onChange={handleUsersSelected}
          initialUsers={initialPeers}
          hideUsers={[user.token]}
        />
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
};
