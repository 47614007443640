import {
  DashboardRowFilter,
  IReviewCycleDashboardRow,
  ReviewCycleToken,
} from '@shared/review-cycles';
import { ISearchResults } from '@shared/types';
import { post } from '@web/common/api';
import { usePagination } from '@web/common/usePagination';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

interface IDashboardSearchRequest {
  skip?: number;
  limit?: number;
  filter?: DashboardRowFilter;
}
export const useAdminDashboardSearch = (reviewCycleToken: ReviewCycleToken) => {
  const [searchResults, setSearchResults] =
    React.useState<ISearchResults<IReviewCycleDashboardRow>>(null);
  const { pagination, setPage, page, pageSize } = usePagination(
    searchResults?.total,
    { pageSize: 50 },
  );
  const [searchParams, setSearchParams] = useSearchParams({});
  const filter: DashboardRowFilter = searchParams.get('filter') as any;

  const setFilter = (value: DashboardRowFilter) => {
    setSearchParams((prev) => {
      prev.set('filter', value);
      return prev;
    });
  };

  const clearFilter = () => {
    setSearchParams((prev) => {
      prev.delete('filter');
      return prev;
    });
  };

  const search = async () => {
    try {
      const skip = (page - 1) * pageSize;
      const limit = pageSize;
      const results = await post<
        IDashboardSearchRequest,
        ISearchResults<IReviewCycleDashboardRow>
      >(`/review-cycles/${reviewCycleToken}/admin/dashboard/search`, {
        limit,
        skip,
        filter: filter as any,
      });
      setSearchResults(results);
    } catch (error) {}
  };

  React.useEffect(() => {
    void search();
  }, [page, pageSize, filter]);

  return {
    searchResults,
    pagination,
    setPage,
    search,
    filter,
    setFilter,
    clearFilter,
  };
};
