import { CodeSandboxOutlined } from '@ant-design/icons';
import { IOrganization } from '@shared/types';
import { Button } from 'antd';
import React, { useState } from 'react';

import { AddSandboxModal } from './AddSandboxModal';

interface Props {
  onAdd: (organization: IOrganization) => void;
}

export const AddSandboxButton: React.FC<Props> = ({ onAdd }) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAdded = (organization: IOrganization) => {
    onAdd(organization);
    setOpen(false);
  };

  return (
    <>
      <Button type="default" onClick={showModal}>
        <CodeSandboxOutlined /> Add Sandbox
      </Button>
      <AddSandboxModal
        open={open}
        onAdd={handleAdded}
        onCancel={handleCancel}
      />
    </>
  );
};
