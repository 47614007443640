import { IReviewCycleStatus } from '@shared/review-cycles';
import { Row } from '@web/components/layout';
import { Skeleton } from 'antd';
import * as React from 'react';

import { BorderedPane } from './BorderedPane';
import { Status } from './Status';

export const AdminReviewCycleStatus: React.FC<{
  reviewCycleStatus?: IReviewCycleStatus;
}> = ({ reviewCycleStatus }) => {
  if (!reviewCycleStatus) {
    return <Skeleton />;
  }

  const { peerReview, selfReview, managerReview, upwardFeedback } =
    reviewCycleStatus;
  const statuses = [
    peerReview,
    upwardFeedback,
    selfReview,
    managerReview,
  ].filter((status) => !!status);
  return (
    <Row gap={12}>
      {statuses.map((reviewStatus) => (
        <BorderedPane
          key={reviewStatus.title}
          style={{ width: 300, alignSelf: 'stretch' }}
        >
          <Status
            title={reviewStatus.title}
            startDate={reviewStatus.startDate}
            endDate={reviewStatus.endDate}
            text={reviewStatus.text}
            progressPercent={reviewStatus.progressPercent}
          />
        </BorderedPane>
      ))}
    </Row>
  );
};
