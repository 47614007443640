import { EditOutlined } from '@ant-design/icons';
import { useGoalsForEntry } from '@client/GoalsClient';
import { Feature } from '@shared/features';
import { IFullImpactResponse, IImpact, ImpactToken } from '@shared/impacts';
import { UserToken } from '@shared/types';
import { hasManager } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { IPageAction } from '@web/app/PageContext';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { ViewGoals } from '@web/goals/ViewGoals';
import { ReactionSummaryContext } from '@web/reactions/ReactionSummaryContext';
import { Skeleton } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ImpactFeedbackList } from './ImpactFeedbackList';
import { JournalEntryCard } from './JournalEntryCard';
import { JournalEntryFeedbackForm } from './feedback/JournalEntryFeedbackForm';

export const ViewJournalEntryPage: React.FC = () => {
  const { booleanValue: journalRelatedGoalsEnabled } = useFeature(
    Feature.JOURNAL_RELATED_GOALS,
  );

  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const { userToken, impactToken } = useParams<{
    userToken: UserToken;
    impactToken: ImpactToken;
  }>();
  const {
    data: impactResponse,
    error,
    mutate: reloadJournalEntry,
  } = useApi<IFullImpactResponse, ServerResponseError>(
    `/impacts/${impactToken}/full`,
  );
  const { data: relatedGoals, error: goalsError } =
    useGoalsForEntry(impactToken);
  const { booleanValue: enable1on1s } = useFeature(Feature.ENABLE_1ON1s);

  if (error || goalsError) {
    return (
      <ErrorPageContent
        statusCode={error?.statusCode ?? goalsError?.statusCode}
      />
    );
  } else if (!impactResponse || !relatedGoals) {
    return (
      <PageContent>
        <PageHeader navigateBack />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  const {
    permissions,
    assignedTask,
    impactEntry,
    reactionSummaryMap,
    reactionsUserMap,
  } = impactResponse;
  const isAuthor = impactEntry.user.token === user.token;
  // userToken is only defined if already on the team entries path
  const isTeamPage = !!userToken;
  const { primaryAction, secondaryActions } = pageActions({
    isMobile,
    isAuthor,
    hasManager: hasManager(user),
    enable1on1s,
    impactEntry,
  });

  return (
    <ReactionSummaryContext.Provider
      value={{ reactionSummaryMap, userMap: reactionsUserMap }}
    >
      <PageContent>
        <PageHeader
          navigateBack
          title="Journal Entry"
          mobileTitle="Journal Entry"
          defaultNavigateBackTo={
            isTeamPage
              ? `/team/${impactEntry.user.token}/journal`
              : isAuthor
              ? '/journal'
              : '/requests'
          }
          primaryAction={primaryAction}
          secondaryActions={secondaryActions}
        />
        <Pane>
          <JournalEntryCard
            impactEntry={impactEntry}
            canReact={permissions.canReact}
          />
          {journalRelatedGoalsEnabled && relatedGoals?.length > 0 && (
            <>
              <Spacer size={12} />
              <ViewGoals
                title={<Text>Related Goals</Text>}
                collapsable={false}
                goals={relatedGoals}
                hideDueDate={isMobile}
              />
            </>
          )}
          <Column>
            {permissions.canViewFeedback && (
              <ImpactFeedbackList
                feedbackResponse={impactResponse.feedbackResponse}
                impactEntry={impactEntry}
                readonly={!permissions.canComment}
              />
            )}
            {permissions.canGiveFeedback && (
              <JournalEntryFeedbackForm
                impactEntry={impactEntry}
                assignedTask={assignedTask}
                onDecline={reloadJournalEntry}
              />
            )}
          </Column>
          <Spacer size={isMobile ? 60 : 24} />
        </Pane>
      </PageContent>
    </ReactionSummaryContext.Provider>
  );
};

const pageActions = ({
  isMobile,
  isAuthor,
  hasManager,
  enable1on1s,
  impactEntry,
}: {
  isMobile: boolean;
  isAuthor: boolean;
  hasManager: boolean;
  enable1on1s: boolean;
  impactEntry: IImpact;
}): {
  primaryAction: IPageAction | null;
  secondaryActions: IPageAction[] | null;
} => {
  if (isMobile || !enable1on1s || (isAuthor && !hasManager)) {
    if (isAuthor) {
      return {
        primaryAction: {
          label: isMobile ? undefined : 'Edit',
          icon: isMobile ? <EditOutlined /> : undefined,
          linkTo: `/journal/${impactEntry.token}/edit`,
        },
        secondaryActions: null,
      };
    }
    return { primaryAction: null, secondaryActions: null };
  }

  return {
    primaryAction: isAuthor
      ? {
          label: 'Edit',
          linkTo: `/journal/${impactEntry.token}/edit`,
        }
      : null,
    secondaryActions: null,
  };
};
