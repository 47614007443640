import { Feature } from '@shared/features';
import { useFeature } from '@web/common/useFeature';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Tour, TourProps } from 'antd';
import { filter } from 'lodash';
import React from 'react';

export const dataTourTitle = 'entry-title';
export const dataTourDescription = 'entry-description';
export const dataTourRelatedGoals = 'entry-related-goals';
export const dataTourCollaborators = 'entry-collaborators';
export const dataTourSave = 'entry-save-button';

export const NewJournalEntryTour: React.FC<{
  onClose: () => void;
  open: boolean;
}> = ({ onClose, open }) => {
  const { booleanValue: journalRelatedGoalsEnabled } = useFeature(
    Feature.JOURNAL_RELATED_GOALS,
  );
  const tourSteps: TourProps['steps'] = [
    {
      title: 'Create a Journal Entry',
      placement: 'bottom',
      target: () => document.getElementById('page-title'),
      description: (
        <Column gap={24}>
          <Text>
            Entries are records of your work that highlight your impact. They
            are valuable for collecting timely feedback from your peers.
          </Text>
        </Column>
      ),
      nextButtonProps: {
        children: 'Continue',
      },
    },
    {
      title: 'Add a Title',
      description: (
        <Column gap={24}>
          <Text>Add a short, descriptive summary of your work.</Text>
          <Text>
            {' '}
            If you&apos;d like some inspiration,{' '}
            <a
              href="https://flint.cc/faq-journal/#journal3"
              target="_blank"
              rel="noreferrer"
              style={{
                color: 'var(--primary-color)',
                textDecoration: 'underline',
              }}
            >
              here
            </a>{' '}
            are some examples of journal entries.
          </Text>
        </Column>
      ),
      target: () => document.querySelector(`[data-tour="${dataTourTitle}"`),
      placement: 'bottom',
      nextButtonProps: {
        children: 'Continue',
      },
    },
    {
      title: 'Describe the Work',
      description: (
        <Column gap={24}>
          <Text>
            Add background information, explain your impact, and share resources
            that tell your peers about your work.
          </Text>
        </Column>
      ),
      target: () =>
        document.querySelector(`[data-tour="${dataTourDescription}"`),
      placement: 'bottom',
      nextButtonProps: {
        children: 'Continue',
      },
    },
    journalRelatedGoalsEnabled
      ? {
          title: 'Attach related goals',
          description: (
            <Column gap={24}>
              <Text>Add any goals that this work contributes to.</Text>
            </Column>
          ),
          target: () =>
            document.querySelector(`[data-tour="${dataTourRelatedGoals}"`),
          placement: 'bottom',
          nextButtonProps: {
            children: 'Continue',
          },
        }
      : null,
    {
      title: 'Share the Entry',
      description: (
        <Column gap={24}>
          <Text>
            Choose peers or anyone familiar with the work. Flint suggests
            selecting 2-3 people.
          </Text>
        </Column>
      ),
      target: () =>
        document.querySelector(`[data-tour="${dataTourCollaborators}"`),
      placement: 'bottom',
      nextButtonProps: {
        children: 'Continue',
      },
    },
    {
      title: 'Save the Entry',
      description: (
        <Column gap={24}>
          <Text>
            Your manager and anyone you&apos;ve shared it with will be notified
            when you save your entry.
          </Text>
        </Column>
      ),
      target: () => document.querySelector(`[data-tour="${dataTourSave}"`),
      placement: 'top',
    },
  ];

  return <Tour open={open} onClose={onClose} steps={filter(tourSteps)} />;
};
