import { Feature, IFeatureOverride, IFeatureValue } from '@shared/features';
import { OrganizationToken } from '@shared/types';
import { get } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { Dictionary } from 'lodash';
import { stringifyUrl } from 'query-string/base';

export function useGetOrganizationFeatures(
  organizationToken?: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/features/organization/${organizationToken}`,
    query: {},
  });

  return useApi<{
    featureDefaults: Record<Feature, IFeatureValue>;
    featureOverrides: Dictionary<IFeatureOverride>;
  }>(url);
}

export function getOrganizationFeatures(organizationToken?: OrganizationToken) {
  const url = stringifyUrl({
    url: `/features/organization/${organizationToken}`,
    query: {},
  });

  return get<{
    featureDefaults: Record<Feature, IFeatureValue>;
    featureOverrides: Dictionary<IFeatureOverride>;
  }>(url);
}
