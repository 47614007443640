import { AlignmentState, AlignmentType } from '@shared/alignment';

import { IAlignmentDashboardRow } from './AlignmentPage';

export interface ITeamIndicatorSummary {
  red: number;
  yellow: number;
  green: number;
  unenrolled: number;
  total: number;
}

export const rowsToIndicatorSummary = (
  rows: IAlignmentDashboardRow[],
): Record<AlignmentType, ITeamIndicatorSummary> => {
  const summary: Record<AlignmentType, ITeamIndicatorSummary> = {
    [AlignmentType.ACTIVE_ONE_ON_ONES]: emptyIndicatorSummary(),
    [AlignmentType.ACTIVE_JOURNAL]: emptyIndicatorSummary(),
  };

  for (const { oneOnOneState, accomplishmentState } of rows) {
    const indicatorStates: Array<
      readonly [AlignmentType, AlignmentState | 'not-enrolled']
    > = [
      [AlignmentType.ACTIVE_ONE_ON_ONES, oneOnOneState],
      [AlignmentType.ACTIVE_JOURNAL, accomplishmentState],
    ];

    for (const [type, state] of indicatorStates) {
      const indicatorSummary = summary[type];
      switch (state) {
        case 'not-enrolled':
          indicatorSummary.unenrolled += 1;
          break;
        case AlignmentState.GREEN:
          indicatorSummary.green += 1;
          break;
        case AlignmentState.YELLOW:
          indicatorSummary.yellow += 1;
          break;
        case AlignmentState.RED:
          indicatorSummary.red += 1;
          break;
      }
      indicatorSummary.total += 1;
    }
  }

  return summary;
};

const emptyIndicatorSummary = (): ITeamIndicatorSummary => ({
  red: 0,
  yellow: 0,
  green: 0,
  unenrolled: 0,
  total: 0,
});
