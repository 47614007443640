import { sortSurveySections } from '@client/SurveyCyclesClient';
import { mapByToken } from '@shared/mapByToken';
import { QuestionToken } from '@shared/questions';
import { ISurveyCycle, SurveySectionType } from '@shared/surveys';
import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { QuestionItemRow } from './QuestionItemRow';
import { SurveySectionItemRow } from './SurveySectionItemRow';

interface ISortable {
  id: SurveySectionType;
}

interface Props {
  surveyCycle: ISurveyCycle;
  onEdit: (section: QuestionToken | SurveySectionType) => void;
  onClone: (questionToken: QuestionToken) => void;
  onDelete: (section: QuestionToken | SurveySectionType) => void;
  readonly?: boolean;
}

const hasChanged = (itemsA: ISortable[], itemsB: ISortable[]) => {
  const idHashA = itemsA.map((item) => item.id).join(':');
  const idHashB = itemsB.map((item) => item.id).join(':');
  return idHashA !== idHashB;
};
export const SectionsList: React.FC<Props> = ({
  surveyCycle,
  onEdit,
  onClone,
  onDelete,
  readonly,
}) => {
  const {
    token: surveyCycleToken,
    sortedSections,
    questions = [],
  } = surveyCycle;

  const [sortedSectionItems, setSortedSectionItems] = React.useState<
    ISortable[]
  >(createSortableQuestionItems(surveyCycle));
  const [questionMap, setQuestionMap] = React.useState(mapByToken(questions));
  React.useEffect(() => {
    setSortedSectionItems(createSortableQuestionItems(surveyCycle));
    setQuestionMap(mapByToken(questions));
  }, [questions, sortedSections]);

  const sortQuestionItems = (newSortedQuestionItems: ISortable[]) => {
    if (!hasChanged(sortedSectionItems, newSortedQuestionItems)) {
      return;
    }

    void sortSurveySections(
      surveyCycleToken,
      newSortedQuestionItems.map((item) => item.id),
    );
    setSortedSectionItems(newSortedQuestionItems);
  };

  return (
    <ReactSortable
      list={sortedSectionItems}
      setList={sortQuestionItems}
      easing="cubic-bezier(0.55, 0, 1, 0.45)"
      animation={100}
      handle=".drag-anchor"
    >
      {sortedSectionItems.map((item, i) => {
        if (item.id === SurveySectionType.ONE_ON_ONE_PREPAREDNESS) {
          return (
            <SurveySectionItemRow
              key={item.id}
              tag="1-on-1s"
              label="Review details of your upcoming 1-on-1 with your manager"
              position={i + 1}
              onClick={() => {
                onEdit(item.id);
              }}
              onDelete={
                !readonly
                  ? () => {
                      onDelete(item.id);
                    }
                  : undefined
              }
            />
          );
        }

        const question = questionMap.get(item.id);
        if (!question) {
          throw new Error(`Unknown question: ${item.id}`);
        }

        return (
          <QuestionItemRow
            key={question.token}
            question={question}
            position={i + 1}
            onClick={() => {
              onEdit(question.token);
            }}
            onDelete={
              !readonly
                ? () => {
                    onDelete(question.token);
                  }
                : undefined
            }
            onClone={
              !readonly
                ? () => {
                    onClone(question.token);
                  }
                : undefined
            }
          />
        );
      })}
    </ReactSortable>
  );
};

const createSortableQuestionItems = (
  surveyCycle: ISurveyCycle,
): ISortable[] => {
  return surveyCycle.sortedSections
    .map((section) => {
      if (section === SurveySectionType.ONE_ON_ONE_PREPAREDNESS) {
        return {
          id: section,
        };
      } else {
        return {
          id: section,
        };
      }
    })
    .filter((section) => !!section) as ISortable[];
};
