import { EditOutlined } from '@ant-design/icons';
import { Feature } from '@shared/features';
import { IUser } from '@shared/types';
import { patch } from '@web/common/api';
import { useFeature } from '@web/common/useFeature';
import { Button, Form, Modal, message } from 'antd';
import Input from 'antd/es/input/Input';
import React, { useState } from 'react';

export const EditUserButton: React.FC<{
  onChange: () => void;
  user: IUser;
}> = ({ onChange, user }) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange = () => {
    onChange();
    setOpen(false);
  };

  return (
    <>
      <Button size="small" type="text" onClick={showModal}>
        <EditOutlined />
      </Button>
      <EditUserModal
        open={open}
        user={user}
        onChange={handleChange}
        onCancel={handleCancel}
      />
    </>
  );
};

interface Props {
  onCancel: () => void;
  onChange: () => void;
  open: boolean;
  user: IUser;
}

export const EditUserModal: React.FC<Props> = ({
  onChange,
  onCancel,
  open,
  user,
}) => {
  const { booleanValue: adminUserAddEnabled } = useFeature(
    Feature.ADMIN_USER_ADD,
  );
  const [form] = Form.useForm<IUser>();
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);
    let userFields: Partial<IUser>;
    try {
      userFields = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      await patch<IUser>(`/users/${user.token}`, userFields);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error(error.displayMessage ?? 'Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  return (
    <Modal
      title="Edit Details"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Save"
    >
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        layout="vertical"
        initialValues={{ name: user.name, email: user.email }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input disabled={isSaving} />
        </Form.Item>
        {adminUserAddEnabled && (
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email' },
            ]}
          >
            <Input disabled={isSaving} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
