import { PlusOutlined } from '@ant-design/icons';
import {
  jiraDebugInfo,
  scheduleDebugReport,
  useDebugReports,
} from '@client/BedrockConfigurationClient';
import { IBedrockConfigDebugRun } from '@shared/bedrock';
import { formatLongDate } from '@shared/formatLongDate';
import { IJiraDebugInfo } from '@shared/jira';
import { OrganizationToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { SelectDateHour } from '@web/components/SelectDateHour';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { useScheduledEvent } from '@web/surveys/cycles/useScheduledEvent';
import { Button, Divider, Modal, Skeleton, Table, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ViewConfigDebugReport } from './BedrockConfigDebugRunPage';
import { BedrockTabs } from './BedrockTabs';

export const BedrockDebugPage: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  const { data: runs } = useDebugReports(organizationToken);
  const navigate = useNavigate();

  const [showModal, setShowModal] = React.useState(false);

  const handleRun = async ({
    startDate,
    endDate,
  }: ScheduleConfigDebugRunFormData) => {
    await scheduleDebugReport(organizationToken, startDate, endDate);
    void message.success('Scheduled');
    setShowModal(false);
  };

  const handleRowClick = (row: IBedrockConfigDebugRun) => {
    navigate(`${row.token}`);
  };

  if (!runs) {
    return (
      <PageContent>
        <PageHeader title="Scribe AI" />
        <BedrockTabs />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }
  return (
    <PageContent>
      <PageHeader title="Scribe AI" />
      <BedrockTabs />

      <Column gap={24}>
        {runs.length > 0 && (
          <Pane>
            <Header2>Latest Debug Run</Header2>
            <ViewConfigDebugReport
              run={runs[0]}
              defaultShowLinear={true}
              defaultShowJira={true}
              defaultShowGithub={true}
            />
          </Pane>
        )}
        <Pane>
          <Column gap={12}>
            <Row>
              <Header2>Config Debug Runs</Header2>
              <GrowingSpacer />
              <Row gap={6}>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <PlusOutlined /> Schedule Run
                </Button>
              </Row>
            </Row>
            <BedrockConfigDebugRunsTable
              runs={runs}
              onRowClick={handleRowClick}
            />
          </Column>
        </Pane>
        <JiraDebugPane organizationToken={organizationToken} />
      </Column>
      <ScheduleConfigDebugRunModal
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        onSubmit={handleRun}
      />
    </PageContent>
  );
};

export const JiraDebugPane: React.FC<{
  organizationToken: OrganizationToken;
}> = ({ organizationToken }) => {
  const [isLoadingDebug, setIsLoadingDebug] = React.useState(false);
  const [loadedJiraDebugInfo, setLoadedJiraDebugInfo] =
    React.useState<IJiraDebugInfo | null>(null);

  const startDate = useScheduledEvent();
  const endDate = useScheduledEvent();

  const handleDebugJiraTickets = async () => {
    setIsLoadingDebug(true);
    try {
      // const startDate = new Date(`${startDate.date} ${startDate.hour}:00`);
      // const endDate = new Date(`${endDate.date} ${endDate.hour}:00`);

      const debugInfo = await jiraDebugInfo(
        organizationToken,
        new Date(`${startDate.date} ${startDate.hour}:00`).toJSON(),
        new Date(`${endDate.date} ${endDate.hour}:00`).toJSON(),
      );

      setLoadedJiraDebugInfo(debugInfo);
    } catch (error) {
      void message.error('Failed to load');
    } finally {
      setIsLoadingDebug(false);
    }
  };

  return (
    <Pane>
      <Header2>Debug Jira Tickets</Header2>
      <Column gap={12}>
        <Column gap={6}>
          <Text>Start Date</Text>
          <SelectDateHour
            disabled={isLoadingDebug}
            date={startDate.date}
            hour={startDate.hour}
            onChange={startDate.onChange}
            timezone={startDate.timezone}
          />
        </Column>
        <Column gap={6}>
          <Text>End Date</Text>
          <SelectDateHour
            disabled={isLoadingDebug}
            date={endDate.date}
            hour={endDate.hour}
            timezone={endDate.timezone}
            onChange={endDate.onChange}
          />
        </Column>
        <Row>
          <Button
            loading={isLoadingDebug}
            disabled={isLoadingDebug}
            onClick={handleDebugJiraTickets}
          >
            Debug
          </Button>
        </Row>
      </Column>
      {loadedJiraDebugInfo && <Divider />}
      {loadedJiraDebugInfo ? (
        <Column gap={12}>
          <Column gap={6}>
            <Text strong>Ticket Count</Text>
            <Text>{loadedJiraDebugInfo.ticketCount}</Text>
          </Column>
          <Column gap={6}>
            <Text strong>Date Range</Text>
            <Text>
              {formatLongDate(loadedJiraDebugInfo.startDate, true)} -{' '}
              {formatLongDate(loadedJiraDebugInfo.endDate, true)}
            </Text>
          </Column>
          <Column gap={6}>
            <Text strong>Ticket States Seen</Text>
            <TextArea
              value={JSON.stringify(
                loadedJiraDebugInfo.ticketStatesSeen,
                null,
                4,
              )}
              autoSize
            />
          </Column>
          <Column gap={6}>
            <Text strong>Assignee Emails Seen</Text>
            <TextArea
              value={JSON.stringify(
                loadedJiraDebugInfo.assigneeEmailsSeen,
                null,
                4,
              )}
              autoSize
            />
          </Column>
          {loadedJiraDebugInfo.randomTickets.map((ticket, index) => (
            <Column key={ticket.key} gap={6}>
              <Text strong>Ticket {index + 1}</Text>
              <TextArea value={JSON.stringify(ticket, null, 4)} autoSize />
            </Column>
          ))}
        </Column>
      ) : null}
    </Pane>
  );
};

export const BedrockConfigDebugRunsTable: React.FC<{
  runs?: IBedrockConfigDebugRun[];
  onRowClick: (batch: IBedrockConfigDebugRun) => void;
}> = ({ runs, onRowClick }) => {
  const columns: ColumnsType<IBedrockConfigDebugRun> = [
    {
      dataIndex: 'createdDate',
      title: 'Created',
      render(createdDate: Date) {
        return formatLongDate(createdDate);
      },
    },
    {
      dataIndex: 'startDate',
      title: 'Start Date',
      render(startDate: Date | string) {
        return formatLongDate(startDate);
      },
    },
    {
      dataIndex: 'endDate',
      title: 'End Date',
      render(endDate: Date | string) {
        return formatLongDate(endDate);
      },
    },
  ];
  return (
    <Table
      rowKey="token"
      columns={columns}
      dataSource={runs}
      loading={!runs}
      pagination={false}
      onRow={(batch: IBedrockConfigDebugRun) => ({
        onClick: () => {
          onRowClick(batch);
        },
      })}
    />
  );
};

interface ScheduleConfigDebugRunFormData {
  startDate: Date;
  endDate: Date;
}
export const ScheduleConfigDebugRunModal: React.FC<{
  open: boolean;
  onCancel: () => void;
  onSubmit: (data: ScheduleConfigDebugRunFormData) => void;
}> = ({ open, onCancel, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const startDate = useScheduledEvent();
  const endDate = useScheduledEvent();

  const handleClose = () => {
    setIsSubmitting(false);
    onCancel();
  };

  const handleOk = async () => {
    setIsSubmitting(true);
    try {
      const data: ScheduleConfigDebugRunFormData = {
        startDate: new Date(`${startDate.date} ${startDate.hour}:00`),
        endDate: new Date(`${endDate.date} ${endDate.hour}:00`),
      };
      await onSubmit(data);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Schedule Debug Config Run"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSubmitting}
      onCancel={onCancel}
      width="500px"
      okText="Schedule"
      okButtonProps={{
        disabled: isSubmitting,
      }}
    >
      <Column gap={12}>
        <Text>
          This feature scans all messages from enrolled channels over the given
          period, extracts GitHub usernames, Linear names, and Jira names from
          those messages.
        </Text>
        <Text>
          A report is then produced with the names that aren&apos;t in our
          system, and which users have a message that matches their configured
          GitHub username / ticketing system name.
        </Text>
        <Column gap={6}>
          <Text>Start Date</Text>
          <SelectDateHour
            disabled={isSubmitting}
            date={startDate.date}
            hour={startDate.hour}
            onChange={startDate.onChange}
            timezone={startDate.timezone}
          />
        </Column>
        <Column gap={6}>
          <Text>End Date</Text>
          <SelectDateHour
            disabled={isSubmitting}
            date={endDate.date}
            hour={endDate.hour}
            timezone={endDate.timezone}
            onChange={endDate.onChange}
          />
        </Column>
      </Column>
    </Modal>
  );
};
