import { IQuestionSet, QuestionSetToken } from '@shared/questionSets';
import { IQuestion, QuestionToken } from '@shared/questions';
import { del, get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function usePeerQuestionSets() {
  const url = stringifyUrl({
    url: `/question-sets/peerQuestionSets`,
    query: {},
  });

  return useApi<IQuestionSet[]>(url);
}

export function peerQuestionSets() {
  const url = stringifyUrl({
    url: `/question-sets/peerQuestionSets`,
    query: {},
  });

  return get<IQuestionSet[]>(url);
}

export function useSelfQuestionSets() {
  const url = stringifyUrl({
    url: `/question-sets/selfQuestionSets`,
    query: {},
  });

  return useApi<IQuestionSet[]>(url);
}

export function selfQuestionSets() {
  const url = stringifyUrl({
    url: `/question-sets/selfQuestionSets`,
    query: {},
  });

  return get<IQuestionSet[]>(url);
}

export function useManagerQuestionSets() {
  const url = stringifyUrl({
    url: `/question-sets/managerQuestionSets`,
    query: {},
  });

  return useApi<IQuestionSet[]>(url);
}

export function managerQuestionSets() {
  const url = stringifyUrl({
    url: `/question-sets/managerQuestionSets`,
    query: {},
  });

  return get<IQuestionSet[]>(url);
}

export function useQuestionSet(questionSetToken: QuestionSetToken) {
  const url = stringifyUrl({
    url: `/question-sets/${questionSetToken}`,
    query: {},
  });

  return useApi<IQuestionSet>(url);
}

export function questionSet(questionSetToken: QuestionSetToken) {
  const url = stringifyUrl({
    url: `/question-sets/${questionSetToken}`,
    query: {},
  });

  return get<IQuestionSet>(url);
}

export function sortQuestionSet(
  questionSetToken: QuestionSetToken,
  sortedQuestions: QuestionToken[],
) {
  return put<`q_${string}`[]>(
    `/question-sets/${questionSetToken}/questions/positions`,
    sortedQuestions,
  );
}

export function addQuestion(
  questionSetToken: QuestionSetToken,
  questionAttributes: Partial<IQuestion>,
) {
  return post<Partial<IQuestion>>(
    `/question-sets/${questionSetToken}/questions`,
    questionAttributes,
  );
}

export function cloneQuestion(
  questionSetToken: QuestionSetToken,
  questionToken: QuestionToken,
) {
  return post<{}, IQuestion>(
    `/question-sets/${questionSetToken}/questions/${questionToken}/clone`,
  );
}

export function updateQuestion(
  questionSetToken: QuestionSetToken,
  questionToken: QuestionToken,
  questionAttributes: Partial<IQuestion>,
) {
  return patch<Partial<IQuestion>>(
    `/question-sets/${questionSetToken}/questions/${questionToken}`,
    questionAttributes,
  );
}

export function removeQuestion(
  questionSetToken: QuestionSetToken,
  questionToken: QuestionToken,
) {
  return del(`/question-sets/${questionSetToken}/questions/${questionToken}`);
}
