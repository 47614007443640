import {
  updateAlignmentSettings,
  useAlignmentSettings,
} from '@client/AlignmentClient';
import { DEFAULT_JOURNAL_THRESHOLD_DAYS } from '@shared/alignment';
import { useAuth } from '@web/auth/useAuth';
import { LabelledCheckbox } from '@web/components/LabelledCheckbox';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { InputNumber, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';

export const AlignmentSettingsModal: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { user } = useAuth();
  const { data: settings } = useAlignmentSettings();
  const [journalAlignmentEnabled, setJournalAlignmentEnabled] = useState(
    settings?.journalAlignmentEnabled ?? false,
  );
  const [oneOnOnesAlignmentEnabled, setOneOnOnesAlignmentEnabled] = useState(
    settings?.oneOnOnesAlignmentEnabled ?? false,
  );
  const [journalAlignmentThreshold, setJournalAlignmentThreshold] = useState(
    settings?.journalAlignmentThreshold ?? DEFAULT_JOURNAL_THRESHOLD_DAYS,
  );
  const [isSaving, setIsSaving] = React.useState(false);
  useEffect(() => {
    if (settings) {
      setJournalAlignmentEnabled(settings.journalAlignmentEnabled);
      setOneOnOnesAlignmentEnabled(settings.oneOnOnesAlignmentEnabled);
      setJournalAlignmentThreshold(settings.journalAlignmentThreshold);
    }
  }, [settings]);
  const handleOk = async () => {
    if (!user) {
      return;
    }

    setIsSaving(true);

    try {
      await updateAlignmentSettings({
        journalAlignmentEnabled,
        journalAlignmentThreshold,
        oneOnOnesAlignmentEnabled,
      });
      void message.success('Success');
      onClose();
    } catch (error) {
      void message.error('Failed to participants');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      title="Alignment Settings"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      confirmLoading={isSaving}
      onCancel={onClose}
      width="500px"
      okText="Save"
    >
      <Column gap={12}>
        <LabelledCheckbox
          label="Enable Journal alignment indicators"
          value={journalAlignmentEnabled}
          onChange={setJournalAlignmentEnabled}
        />
        <Column>
          <Text>Maximum number of days between Journal entries</Text>
          <InputNumber
            precision={0}
            min={1}
            max={30}
            value={journalAlignmentThreshold}
            onChange={(newValue) => {
              setJournalAlignmentThreshold(newValue);
            }}
            disabled={!journalAlignmentEnabled}
          />
        </Column>
        <LabelledCheckbox
          label="Enable 1-on-1 alignment indicators"
          value={oneOnOnesAlignmentEnabled}
          onChange={setOneOnOnesAlignmentEnabled}
        />
      </Column>
    </Modal>
  );
};
