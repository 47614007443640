import {
  useAlignmentIndicators,
  useTeamAlignmentIndicators,
} from '@client/AlignmentClient';
import {
  ALIGNMENT_DESCRIPTION_TEXT,
  ALIGNMENT_INDICATOR_NAMES,
  AlignmentState,
  AlignmentType,
  IAlignmentIndicator,
  TeamAlignmentIndicators,
} from '@shared/alignment';
import { IUser, UserMapItem, UserToken } from '@shared/types';
import { hasManager, isPrimaryManagerOf } from '@shared/users';
import {
  AlignmentStateIndicator,
  NotEnrolledIndicator,
} from '@web/1on1s/AlignmentStateIndicator';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { BackButton } from '@web/components/BackButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import {
  Column,
  GrowingSpacer,
  Row,
  Spacer,
  WrapRow,
} from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { UserProfile } from '@web/home/UserProfile';
import { Divider, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AlignmentCallToAction } from './AlignmentCallToAction';
import {
  ITeamIndicatorSummary,
  rowsToIndicatorSummary,
} from './rowsToIndicatorSummary';

const PAGE_TITLE = 'Alignment';
export const AlignmentPage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const { userToken } = useParams<{
    userToken?: UserToken;
  }>();
  const navigateBack = !!userToken;
  const targetUserToken = userToken ?? user?.token;
  const { data: targetUser } = useApi<UserMapItem>(`/users/${targetUserToken}`);
  const { data: indicators } = useAlignmentIndicators(targetUserToken);
  const { data: teamIndicators } = useTeamAlignmentIndicators(targetUserToken);
  const rows = teamIndicators ? toDashboardRows(teamIndicators) : [];
  const summaries = rowsToIndicatorSummary(rows);
  const hasTeam = teamIndicators
    ? Object.keys(teamIndicators).length > 0
    : false;

  if (!indicators || !teamIndicators || !targetUser) {
    return (
      <PageContent>
        <PageHeader
          title={PAGE_TITLE}
          mobileTitle={PAGE_TITLE}
          navigateBack={navigateBack}
        />
        <Skeleton />
      </PageContent>
    );
  }

  return (
    <PageContent>
      {!isMobile && navigateBack ? <BackButton /> : null}
      <Spacer size={12} />
      <UserProfile user={targetUser as any} hideOneOnOneLink />
      <Spacer size={24} />
      {isMobile && (
        <PageHeader mobileTitle={PAGE_TITLE} navigateBack={navigateBack} />
      )}
      <Column gap={24}>
        <Pane>
          {indicators.length > 0 ? (
            <IndividualAlignmentStatuses indicators={indicators} />
          ) : (
            <Text>
              Alignment Indicators are not enabled for{' '}
              {userToken ? `${targetUser.name}` : `you`}
            </Text>
          )}
          {hasTeam && (
            <>
              <Divider style={{ margin: '24px 0' }} />
              <Column gap={12}>
                <Header2>Team</Header2>
                <AlignmentIndicatorDashboard
                  summaries={summaries}
                  rows={rows}
                />
              </Column>
            </>
          )}
        </Pane>
      </Column>
    </PageContent>
  );
};

const IndividualAlignmentStatuses: React.FC<{
  indicators: IAlignmentIndicator[];
}> = ({ indicators }) => {
  if (indicators.length === 0) {
    return null;
  }

  return (
    <WrapRow gap={24}>
      {indicators.map((indicator, index) => {
        return (
          <React.Fragment key={indicator.token}>
            {index !== 0 && <VerticalDivider />}
            <AlignmentStatus
              key={indicator.token}
              indicator={indicator}
              style={{ maxWidth: 420, padding: 12 }}
            />
          </React.Fragment>
        );
      })}
    </WrapRow>
  );
};

export const AlignmentStatus: React.FC<{
  indicator: IAlignmentIndicator;
  style?: React.CSSProperties;
}> = ({ indicator, style }) => {
  const { user } = useAuth();
  const indicatorUserHasManager = hasManager(indicator.user);
  if (
    indicator.type === AlignmentType.ACTIVE_ONE_ON_ONES &&
    !indicatorUserHasManager
  ) {
    return null;
  }

  return (
    <Column style={style}>
      <Row>
        <Header2>{ALIGNMENT_INDICATOR_NAMES[indicator.type]}</Header2>
        <GrowingSpacer />
        <AlignmentStateIndicator state={indicator.state} />
      </Row>
      <Spacer size={6} />
      <StyledText>{ALIGNMENT_DESCRIPTION_TEXT[indicator.type]}</StyledText>
      <Spacer size={14} />
      <AlignmentCallToAction
        isIndicatorUser={user.token === indicator.userToken}
        isManager={isPrimaryManagerOf(indicator.user, user.token)}
        indicator={indicator}
      />
    </Column>
  );
};

const VerticalDivider = styled.div`
  width: 1px;
  background: rgba(5, 5, 5, 0.06);
`;

export interface IAlignmentDashboardRow {
  user: IUser;
  oneOnOneState: AlignmentState | 'not-enrolled';
  accomplishmentState: AlignmentState | 'not-enrolled';
}

const AlignmentIndicatorDashboard: React.FC<{
  rows: IAlignmentDashboardRow[];
  summaries: Record<AlignmentType, ITeamIndicatorSummary>;
}> = ({ rows, summaries }) => {
  const navigate = useNavigate();
  const { organization } = useAuth();
  const columns: ColumnsType<IAlignmentDashboardRow> = [
    {
      title: '',
      dataIndex: 'user',
      render: (user: IUser) => {
        return <UserMessage user={user} style={{ whiteSpace: 'nowrap' }} />;
      },
    },
  ];
  if (organization.oneOnOnesAlignmentEnabled) {
    columns.push({
      title: ALIGNMENT_INDICATOR_NAMES[AlignmentType.ACTIVE_ONE_ON_ONES],
      dataIndex: 'oneOnOneState',
      render: (state: IAlignmentDashboardRow['oneOnOneState']) => {
        return (
          <Column style={{ alignItems: 'flex-start' }}>
            {state === 'not-enrolled' ? (
              <NotEnrolledIndicator />
            ) : (
              <AlignmentStateIndicator state={state} />
            )}
          </Column>
        );
      },
    });
  }

  if (organization.journalAlignmentEnabled) {
    columns.push({
      title: ALIGNMENT_INDICATOR_NAMES[AlignmentType.ACTIVE_JOURNAL],
      dataIndex: 'accomplishmentState',
      render: (state: IAlignmentDashboardRow['oneOnOneState']) => {
        return (
          <Column style={{ alignItems: 'flex-start' }}>
            {state === 'not-enrolled' ? (
              <NotEnrolledIndicator />
            ) : (
              <AlignmentStateIndicator state={state} />
            )}
          </Column>
        );
      },
    });
  }

  return (
    <ClickableTableContainer>
      <Table<IAlignmentDashboardRow>
        scroll={{
          x: true,
        }}
        columns={columns}
        dataSource={rows}
        onRow={(row) => ({
          onClick: () => {
            navigate(`/alignment/${row.user.token}`);
          },
        })}
        pagination={false}
      />
    </ClickableTableContainer>
  );
};

const toDashboardRows = (
  teamIndicators: TeamAlignmentIndicators,
): IAlignmentDashboardRow[] => {
  return Object.keys(teamIndicators).map(
    (userToken: UserToken): IAlignmentDashboardRow => {
      const { user, indicators } = teamIndicators[userToken];
      return {
        user,
        oneOnOneState:
          indicators.find(
            (indicator) => indicator.type === AlignmentType.ACTIVE_ONE_ON_ONES,
          )?.state ?? 'not-enrolled',
        accomplishmentState:
          indicators.find(
            (indicator) => indicator.type === AlignmentType.ACTIVE_JOURNAL,
          )?.state ?? 'not-enrolled',
      };
    },
  );
};

const ClickableTableContainer = styled(Column)`
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-summary {
    display: table-header-group;
  }
`;

export const StyledText = styled(Text)`
  line-height: 20px;
`;
