import { mapByToken } from '@shared/mapByToken';
import { IQuestionResponse } from '@shared/question_response';
import { IQuestion, QuestionToken, isQuestionToken } from '@shared/questions';
import { ISurveyCycle, SurveySectionType } from '@shared/surveys';
import { Column } from '@web/components/layout';
import { InputQuestion } from '@web/questions/InputQuestions';
import React from 'react';

import { OneOnOneSection } from './OneOnOneSection';

export const SurveySections: React.FC<{
  surveyCycle: ISurveyCycle;
  responseMap: Omit<
    Map<QuestionToken, IQuestionResponse>,
    'set' | 'clear' | 'delete'
  >;
  onResponseChanged?: (
    question: IQuestion,
    response: IQuestionResponse,
  ) => void;
  disabled?: boolean;
  errors?: Record<QuestionToken, string>;
}> = ({ surveyCycle, responseMap, onResponseChanged, disabled, errors }) => {
  const questionsMap = mapByToken(surveyCycle.questions);
  return (
    <Column style={{ flex: 1 }} gap={24}>
      {surveyCycle.sortedSections.map((section, index) => {
        if (isQuestionToken(section)) {
          const question = questionsMap.get(section);
          if (!question) {
            return null;
          }

          return (
            <InputQuestion
              key={section}
              question={question}
              response={responseMap.get(section) as any}
              onResponseChanged={onResponseChanged}
              autoFocus={index === 0}
              disabled={disabled}
              error={errors?.[section]}
            />
          );
        } else if (section === SurveySectionType.ONE_ON_ONE_PREPAREDNESS) {
          return <OneOnOneSection key={section} />;
        }

        return null;
      })}
    </Column>
  );
};
