import {
  completeAgendaItem,
  removeAgendaItem,
  uncompleteAgendaItem,
  updateAgendaItem,
} from '@client/OneOnOnesClient';
import { IAgendaItem, OneOnOneToken } from '@shared/one-on-one';
import { UserMapItem } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { publishPresenceEvent } from '@web/common/useWebSocketEvent';
import { Column, Row } from '@web/components/layout';
import { Drawer, Skeleton, message } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import * as React from 'react';
import styled from 'styled-components';

import { DrawerTitle } from './DrawerTitle';
import { GoalDetails } from './GoalDetails';
import { InlineInput } from './InlineInput';
import { ItemDates } from './ItemDates';
import { JournalEntryDetails } from './JournalEntryDetails';
import { NotesList } from './notes/NotesList';

export const AgendaItemDrawer: React.FC<{
  oneOnOneToken: OneOnOneToken;
  agendaItem: IAgendaItem;
  onClose: () => void;
  otherUser: UserMapItem;
}> = ({ agendaItem, onClose, oneOnOneToken, otherUser }) => {
  const { user } = useAuth();
  const { confirm, contextHolder } = useModalConfirm();
  const [originalText, setOriginalText] = React.useState(agendaItem.text ?? '');
  const [text, setText] = React.useState(agendaItem.text ?? '');
  const [resolved, setResolved] = React.useState(!!agendaItem.completedDate);
  React.useEffect(() => {
    publishPresenceEvent(oneOnOneToken, agendaItem.token, user.token);

    return () => {
      publishPresenceEvent(oneOnOneToken, null, user.token);
    };
  }, []);

  const handleSaveText = async () => {
    if (!text || text === originalText) {
      return;
    }
    const trimmed = text.trim();
    if (trimmed === originalText) {
      setText(trimmed);
      return;
    }

    try {
      await updateAgendaItem(oneOnOneToken, agendaItem.token, trimmed);
      setOriginalText(trimmed);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleClose = async () => {
    await handleSaveText();
    onClose();
  };

  const toggleResolved = async () => {
    const newResolved = !resolved;
    setResolved(newResolved);
    try {
      if (newResolved) {
        await completeAgendaItem(oneOnOneToken, agendaItem.token);
      } else {
        await uncompleteAgendaItem(oneOnOneToken, agendaItem.token);
      }
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleDelete = async () => {
    const confirmed = await confirm(
      'Did you mean to resolve this topic instead? Removing this topic will remove all associated comments and is irreversible.',
      { title: 'Remove Agenda Item' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await removeAgendaItem(oneOnOneToken, agendaItem.token);
      void handleClose();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleEnterPressed = async () => {
    changeFocus();
  };

  const handleTextBlur = async () => {
    await handleSaveText();
    changeFocus();
  };

  const changeFocus = () => {
    const closeButton: HTMLButtonElement =
      document.querySelector('.ant-drawer-close');
    closeButton?.focus();
  };

  const canUpdateDescription = agendaItem.journalEntries?.length > 0;

  return (
    <Drawer
      title={<DrawerTitle onDelete={handleDelete} />}
      placement="right"
      onClose={handleClose}
      open={true}
      width={500}
      bodyStyle={{ overflow: 'hidden', paddingTop: 0 }}
    >
      {agendaItem?.token ? (
        <NotesList
          entityToken={agendaItem?.token}
          oneOnOneToken={oneOnOneToken}
          otherUser={otherUser}
        >
          <TitleContainer>
            <Row gap={6} style={{ alignItems: 'flex-start', paddingTop: 6 }}>
              <Checkbox checked={resolved} onClick={toggleResolved} />
              <InlineInput
                disabled={canUpdateDescription}
                value={text}
                onChange={setText}
                autofocus={!agendaItem}
                onEnter={handleEnterPressed}
                onBlur={handleTextBlur}
                placeholder="Enter topic"
                style={{
                  cursor: 'text',
                  color: 'var(--color-text)',
                  fontSize: 18,
                  lineHeight: '22px',
                  marginTop: -5,
                  textDecoration: agendaItem.completedDate
                    ? 'line-through'
                    : undefined,
                }}
              />
            </Row>
            <ItemDates item={agendaItem} style={{ marginTop: -6 }} />
          </TitleContainer>
          <JournalEntryDetails agendaItem={agendaItem} />
          <GoalDetails agendaItem={agendaItem} />
        </NotesList>
      ) : (
        <Skeleton />
      )}
      {contextHolder}
    </Drawer>
  );
};

const TitleContainer = styled(Column)`
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`;
