import { ISurveyCycle, SurveyType } from '@shared/surveys';
import { put } from '@web/common/api';
import { SelectDateHour } from '@web/components/SelectDateHour';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Button, Checkbox, Divider, Select, message } from 'antd';
import React, { useState } from 'react';

import { useScheduledEvent } from './useScheduledEvent';

export const SurveyCycleSettingsForm: React.FC<{
  surveyCycle: ISurveyCycle;
  onSave: (surveyCycle: ISurveyCycle) => void;
}> = ({ onSave, surveyCycle }) => {
  const [anonymous, setAnonymous] = useState(surveyCycle.anonymous);
  const startScheduledEvent = useScheduledEvent(
    surveyCycle?.startEvent ?? { manual: true },
  );
  const [reminderScheduled, setReminderScheduled] = useState(
    !!surveyCycle?.reminderEvent,
  );
  const reminderScheduledEvent = useScheduledEvent(surveyCycle?.reminderEvent);
  const [finalReminderScheduled, setFinalReminderScheduled] = useState(
    !!surveyCycle?.finalReminderEvent,
  );
  const finalReminderScheduledEvent = useScheduledEvent(
    surveyCycle?.finalReminderEvent,
  );
  const endScheduledEvent = useScheduledEvent(
    surveyCycle?.endEvent ?? { manual: true },
  );

  const handleSave = async () => {
    try {
      const updatedSurveyCycle = await put<any, ISurveyCycle>(
        `/survey-cycles/${surveyCycle.token}`,
        {
          anonymous,
          startEvent: startScheduledEvent.getEntity(),
          endEvent: endScheduledEvent.getEntity(),
          reminderEvent: reminderScheduled
            ? reminderScheduledEvent.getEntity()
            : null,
          finalReminderEvent: finalReminderScheduled
            ? finalReminderScheduledEvent.getEntity()
            : null,
        },
      );
      onSave(updatedSurveyCycle);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column gap={24}>
      <Column gap={6} style={{ alignItems: 'flex-start' }}>
        {surveyCycle.type === SurveyType.BASIC && (
          <Row gap={6}>
            <Checkbox
              checked={anonymous}
              onChange={() => {
                setAnonymous(!anonymous);
              }}
              disabled={!!surveyCycle.startedDate}
            />{' '}
            <Text>Survey responses are anonymous</Text>
          </Row>
        )}
        {surveyCycle.type === SurveyType.BASIC && <Divider />}
        <Header3>Scheduling</Header3>
        <Spacer size={1} />
        <Text>Start</Text>
        <Row gap={6}>
          <Select
            value={startScheduledEvent.manual ? 'manual' : 'scheduled'}
            onChange={(value) => {
              startScheduledEvent.setManual(value === 'manual');
            }}
            style={{ width: 220 }}
          >
            <Select.Option value={'manual'}>Manual Start</Select.Option>
            <Select.Option value={'scheduled'}>Scheduled Start</Select.Option>
          </Select>
          {!startScheduledEvent.manual && (
            <SelectDateHour
              date={startScheduledEvent.date}
              hour={startScheduledEvent.hour}
              timezone={startScheduledEvent.timezone as string}
              onChange={(newDate, newHour) => {
                startScheduledEvent.onChange(newDate, newHour);
              }}
            />
          )}
        </Row>
      </Column>

      <Column gap={6} style={{ alignItems: 'flex-start' }}>
        <Text>Reminder</Text>
        <Row gap={6}>
          <Select
            value={!reminderScheduled ? 'manual' : 'scheduled'}
            onChange={(value) => {
              setReminderScheduled(value !== 'manual');
            }}
            style={{ width: 220 }}
          >
            <Select.Option value={'manual'}>No Reminder</Select.Option>
            <Select.Option value={'scheduled'}>
              Scheduled Reminder
            </Select.Option>
          </Select>
          {reminderScheduled && (
            <SelectDateHour
              date={reminderScheduledEvent.date}
              hour={reminderScheduledEvent.hour}
              timezone={reminderScheduledEvent.timezone as string}
              onChange={(newDate, newHour) => {
                reminderScheduledEvent.onChange(newDate, newHour);
              }}
            />
          )}
        </Row>
      </Column>

      <Column gap={6} style={{ alignItems: 'flex-start' }}>
        <Text>Final Reminder</Text>
        <Row gap={6}>
          <Select
            value={!finalReminderScheduled ? 'manual' : 'scheduled'}
            onChange={(value) => {
              setFinalReminderScheduled(value !== 'manual');
            }}
            style={{ width: 220 }}
          >
            <Select.Option value={'manual'}>No Final Reminder</Select.Option>
            <Select.Option value={'scheduled'}>
              Scheduled Final Reminder
            </Select.Option>
          </Select>
          {finalReminderScheduled && (
            <SelectDateHour
              date={finalReminderScheduledEvent.date}
              hour={finalReminderScheduledEvent.hour}
              timezone={finalReminderScheduledEvent.timezone as string}
              onChange={(newDate, newHour) => {
                finalReminderScheduledEvent.onChange(newDate, newHour);
              }}
            />
          )}
        </Row>
      </Column>

      <Column gap={6} style={{ alignItems: 'flex-start' }}>
        <Text>End</Text>
        <Row gap={6}>
          <Select
            value={endScheduledEvent.manual ? 'manual' : 'scheduled'}
            onChange={(value) => {
              endScheduledEvent.setManual(value === 'manual');
            }}
            style={{ width: 220 }}
          >
            <Select.Option value={'manual'}>Manual End</Select.Option>
            <Select.Option value={'scheduled'}>Scheduled End</Select.Option>
          </Select>
          {!endScheduledEvent.manual && (
            <SelectDateHour
              date={endScheduledEvent.date}
              hour={endScheduledEvent.hour}
              timezone={endScheduledEvent.timezone as string}
              onChange={(newDate, newHour) => {
                endScheduledEvent.onChange(newDate, newHour);
              }}
            />
          )}
        </Row>
      </Column>
      <Row>
        <Button type="primary" onClick={handleSave}>
          {surveyCycle ? 'Update' : 'Continue'}
        </Button>
      </Row>
    </Column>
  );
};
