import {
  CreateWorkdayFakePayloadRequest,
  CreateWorkdayFakePayloadResponse,
  GetWorkdayFakePayloadResponse,
  UpdateWorkdayFakePayloadRequest,
  UpdateWorkdayFakePayloadResponse,
  WorkdayReportRow,
} from '@shared/workday';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CodeMirror from '@uiw/react-codemirror';
import { PageContent } from '@web/app/Page';
import { del, patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane, PaneHeader } from '@web/components/Pane';
import { Column, Row } from '@web/components/layout';
import { Alert, Button, Input, Skeleton, Typography, message } from 'antd';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const WorkdayFakePayloadPage: React.FC = () => {
  const navigate = useNavigate();
  const { workdayFakePayloadToken } = useParams();
  const [error, setError] = React.useState<string>(null);
  const [name, setName] = React.useState('');
  const [rawPayload, setRawPayload] = React.useState(
    workdayFakePayloadToken
      ? ''
      : JSON.stringify(defaultPayload, ' ' as any, 2).trim(),
  );

  const { data: response, mutate: reloadPayload } =
    useApi<GetWorkdayFakePayloadResponse>(
      workdayFakePayloadToken
        ? `/workday/payloads/${workdayFakePayloadToken}`
        : null,
    );

  React.useEffect(() => {
    if (response?.payload) {
      setRawPayload(response.payload.raw);
      setName(response.payload.name);
    }
  }, [response]);

  if (workdayFakePayloadToken && !response?.payload) {
    return (
      <PageContent>
        <PageHeader />
        <Skeleton />
      </PageContent>
    );
  }
  const fakePayload = response?.payload;

  const handleSave = async () => {
    setError(null);

    if (!rawPayload.trim() || !name.trim()) {
      void message.error('Name and payload are required');
      return;
    }

    try {
      JSON.parse(rawPayload);
    } catch (error) {
      setError(`Invalid payload: ${error.message}`);
      return;
    }

    try {
      if (fakePayload) {
        const response = await patch<
          UpdateWorkdayFakePayloadRequest,
          UpdateWorkdayFakePayloadResponse
        >(`/workday/payloads/${fakePayload.token}`, {
          payload: { raw: rawPayload, name: name.trim() },
        });
        await reloadPayload({ payload: response.payload });
      } else {
        await post<
          CreateWorkdayFakePayloadRequest,
          CreateWorkdayFakePayloadResponse
        >('/workday/payloads', {
          payload: { raw: rawPayload, name: name.trim() },
        });
      }

      void message.success('Payload saved');
      navigate(-1);
    } catch (error) {
      console.log(error);
      void message.error('Error');
    }
  };

  const handleDelete = async () => {
    try {
      await del(`/workday/payloads/${fakePayload.token}`);
      void message.success('Template deleted');
      navigate(-1);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageContent>
      <PaneHeader
        title={workdayFakePayloadToken ? 'Edit Payload' : 'New Payload'}
        navigateBack
        extras={
          workdayFakePayloadToken ? (
            <ConfirmButton
              onConfirm={handleDelete}
              description="Are you sure you want to delete this payload? This can not be reversed."
            >
              Delete payload
            </ConfirmButton>
          ) : undefined
        }
      />
      <Pane>
        <Column gap={24}>
          <Column>
            <Typography.Text>Name</Typography.Text>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Column>
          <Column>
            <Typography.Text>Payload</Typography.Text>
            {error && (
              <Alert
                type="error"
                message={error}
                style={{ margin: '10px 0' }}
              />
            )}
            <CodeMirror
              value={rawPayload}
              onChange={setRawPayload}
              height="500px"
              theme={vscodeDark}
            />
            <Typography>
              super_ref is the manager&apos; user id. ids are treated as opaque
              but are likely numbers.
            </Typography>
          </Column>
          <Row gap={12}>
            <Button type="primary" onClick={handleSave}>
              Save payload
            </Button>
            <Button type="default" onClick={handleCancel}>
              Cancel
            </Button>
          </Row>
        </Column>
      </Pane>
    </PageContent>
  );
};

const defaultPayload: WorkdayReportRow[] = [
  {
    user_id: '123',
    first_name: 'OneTwo',
    last_name: 'McThree',
    email: 'onetwo@example.com',
    active: true,
    super_ref: 'asd',
  },
  {
    user_id: 'asd',
    first_name: 'Aster',
    last_name: '',
    email: 'aster@example.com',
    active: true,
    super_ref: '',
  },
];
