import { useSummaries } from '@client/PerformanceClient';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Skeleton } from 'antd';
import * as React from 'react';

import { SummariesTable } from './SummariesTable';

const ListSummariesPage: React.FC = () => {
  const { data: summaries, mutate: reloadSummaries } = useSummaries();

  return (
    <PageContent>
      <PageHeader
        title="Performance Summaries"
        primaryAction={{
          label: 'New Summary',
          linkTo: 'new',
        }}
      />
      {summaries ? (
        <SummariesTable summaries={summaries} onChange={reloadSummaries} />
      ) : (
        <Skeleton />
      )}
    </PageContent>
  );
};

export default ListSummariesPage;
