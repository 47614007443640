import { DAY_IN_MILLISECONDS } from '@shared/constants';
import { ISearchImpactsResults } from '@shared/impacts';
import { warningColor } from '@web/app/styles/ColorStyles';
import { useApi } from '@web/common/useApi';
import { NonBreakingLink } from '@web/components/NonBreakingLink';
import { Text } from '@web/components/typography';
import * as React from 'react';

import { Banner } from './Banners';

export const JournalInactivityBanner: React.FC = () => {
  const { data: mostRecentImpactEntryResults } =
    useApi<ISearchImpactsResults>(`/impacts?limit=1`);

  if (!mostRecentImpactEntryResults) {
    return null;
  }

  const daySinceLastEntry = mostRecentImpactEntryResults.impacts?.[0]
    ?.createdDate
    ? Math.floor(
        (Date.now() -
          new Date(
            mostRecentImpactEntryResults.impacts[0].createdDate,
          ).getTime()) /
          DAY_IN_MILLISECONDS,
      )
    : 0;

  if (daySinceLastEntry <= 7) {
    return null;
  }

  return (
    <Banner background={warningColor.lighten(0.1)}>
      <Text>
        It’s been {daySinceLastEntry} days since you added a journal entry.{' '}
        <NonBreakingLink to="new">Create one now</NonBreakingLink>
      </Text>
    </Banner>
  );
};
