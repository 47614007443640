import { IReviewCycle } from '@shared/review-cycles';

export const countCycleComponents = ({
  managerReflectionCycleEnabled,
  peerReviewCycleEnabled,
  selfReflectionCycleEnabled,
}: IReviewCycle): number =>
  (managerReflectionCycleEnabled ? 1 : 0) +
  (peerReviewCycleEnabled ? 1 : 0) +
  (selfReflectionCycleEnabled ? 1 : 0);
