import { formatDate } from '@shared/formatDate';
import {
  ITask,
  ITasksResponse,
  IUser,
  TaskFilter,
  UserMap,
} from '@shared/types';
import { useApi } from '@web/common/useApi';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Spacer } from '@web/components/layout';
import { Header2, Text, TruncateText } from '@web/components/typography';
import { getTaskInfo } from '@web/requests/getTaskInfo';
import { Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Widget, WidgetDate, WidgetRow } from './Widget';

export const RequestsWidget: React.FC<{
  user: IUser;
  readonly?: boolean;
}> = ({ user, readonly = false }) => {
  const { data: response } = useApi<ITasksResponse>(
    `/tasks?filter=${TaskFilter.OPEN}&limit=3&userToken=${user.token}`,
  );

  return (
    <Column>
      <Link to={readonly ? null : '/requests'}>
        <Header2>Open Requests</Header2>
      </Link>
      <Spacer size={6} />
      {!response?.tasks ? (
        <Skeleton />
      ) : response?.tasks.length > 0 ? (
        <Widget>
          {response.tasks.map((task, index) => (
            <TaskWidgetRow
              hideBorder={index === 0}
              key={task.token}
              task={task}
              userMap={response.userMap}
              readonly={readonly}
            />
          ))}
        </Widget>
      ) : (
        <Text>No open requests</Text>
      )}
    </Column>
  );
};
const TaskWidgetRow: React.FC<{
  task: ITask;
  userMap: UserMap;
  readonly: boolean;
  hideBorder: boolean;
}> = ({ task, userMap, readonly, hideBorder }) => {
  const info = getTaskInfo(task, userMap);
  if (!info) {
    return null;
  }

  return readonly ? (
    <WidgetRow readonly hideBorder={hideBorder}>
      <UserAvatar user={info.user} size={20} />
      <TruncateText>{info.title}</TruncateText>
      <GrowingSpacer />
      <WidgetDate>{formatDate(task.updatedDate)}</WidgetDate>
    </WidgetRow>
  ) : (
    <Link to={info.linkTo}>
      <WidgetRow hideBorder={hideBorder}>
        <UserAvatar user={info.user} size={20} />
        <TruncateText>{info.title}</TruncateText>
        <WidgetDate>{formatDate(task.updatedDate)}</WidgetDate>
      </WidgetRow>
    </Link>
  );
};
