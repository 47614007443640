import { SurveyCycleToken } from '@shared/surveys';
import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUser } from '@web/components/users/SelectUser';
import { Modal, message } from 'antd';
import Checkbox from 'rc-checkbox';
import React, { useState } from 'react';

export const AddParticipantsModal: React.FC<{
  onCancel: () => void;
  onSave: () => void;
  surveyCycleToken: SurveyCycleToken;
}> = ({ onSave, onCancel, surveyCycleToken }) => {
  const { user } = useAuth();
  const [participant, setParticipant] = useState<UserToken | null>(null);
  const [includeTeam, setIncludeTeam] = useState(true);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    if (!participant) {
      return;
    }

    setIsSaving(true);

    try {
      await post(`/survey-cycles/${surveyCycleToken}/participants`, {
        participant,
        includeTeam,
      });
      void message.success('Success');
      onSave();
    } catch (error) {
      void message.error('Failed to participants');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleUsersSelected = (userToken: UserToken) => {
    setParticipant(userToken);
  };

  return (
    <Modal
      title="Add participants"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Add"
    >
      <Column gap={12}>
        <Text>Select someone to add to this survey.</Text>
        <SelectUser
          style={{ width: '100%' }}
          organizationToken={user.organizationToken}
          onChange={handleUsersSelected}
        />
        <Row gap={12}>
          <Checkbox
            checked={includeTeam}
            onChange={() => {
              setIncludeTeam(!includeTeam);
            }}
          />
          <Text>Add user and their team</Text>
        </Row>
      </Column>
    </Modal>
  );
};
