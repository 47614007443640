import { IF_MOBILE } from '@web/app/responsive';
import styled from 'styled-components';

export const FormButtons = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;

  ${IF_MOBILE} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
