import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column } from '@web/components/layout';
import { Skeleton } from 'antd';
import * as React from 'react';

import { GithubTabs } from './GithubTabs';

export const GithubLoadingPage: React.FC = () => (
  <PageContent>
    <PageHeader title={'Github'} />
    <Column>
      <GithubTabs />
      <Pane>
        <Skeleton />
      </Pane>
    </Column>
  </PageContent>
);
