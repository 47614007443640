import { IF_MOBILE, useResponsive } from '@web/app/responsive';
import { ResponsiveRow, Spacer } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Button } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const JournalGetStarted: React.FC = () => {
  const { isMobile } = useResponsive();

  return (
    <GetStartedContainer>
      <Header3>Get started</Header3>
      <Text>
        Flint enables you to keep a record of your work. Log entries in your
        journal to track your projects and accomplishments. Share your entries
        with peers who can give you feedback.
      </Text>

      <Spacer size={24} />
      <Header3>Collaborate & support your peers</Header3>
      <Text>
        Aim to log 2-3 journal entries per week. Support your peers by giving
        feedback on their entries.
      </Text>
      <Spacer size={24} />
      <Header3>Personal growth</Header3>
      <Text>
        You and your manager can use Flint to discuss progress towards your
        goals, opportunities to grow, and your recent journal entries.
      </Text>
      <Spacer size={24} />
      <ResponsiveRow
        gap={12}
        style={isMobile ? { alignItems: 'stretch' } : undefined}
      >
        <Link to="/journal/new">
          <Button type="primary" size="large">
            Create a new journal entry
          </Button>
        </Link>
        <Text>
          <a
            href="https://www.flint.cc/faq"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#333', textDecoration: 'underline' }}
          >
            <Button size="large">View the Flint FAQ</Button>
          </a>
        </Text>
      </ResponsiveRow>
    </GetStartedContainer>
  );
};

const GetStartedContainer = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-width: 800px;

  ${IF_MOBILE} {
    margin-bottom: 60px;
    padding-top: 24px;
  }
`;
