import { BedrockReportSendMode } from '@shared/bedrock';
import { Select } from 'antd';
import * as React from 'react';

export interface SendModeOption {
  label: string;
  value: BedrockReportSendMode;
}

export const SelectSendMode: React.FC<{
  disabled: boolean;
  value?: BedrockReportSendMode;
  onChange: (sendMode: BedrockReportSendMode) => void;
}> = ({ disabled, value, onChange }) => {
  const options: SendModeOption[] = [
    {
      label:
        "Normal - Only resend runs that haven't been sent already (best effort)",
      value: BedrockReportSendMode.ONCE,
    },
    {
      label: 'Force - Re-send even if already sent',
      value: BedrockReportSendMode.FORCE,
    },
  ];

  return (
    <Select
      disabled={disabled}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
