import { useSurveyList } from '@client/SurveyCyclesClient';
import { ISurveySummaryRow } from '@shared/surveys';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { SurveyCyclesTable } from './SurveyCyclesTable';

const SurveyCyclesPage: React.FC = () => {
  const { data: response } = useSurveyList();
  const navigate = useNavigate();

  const handleReviewCycleClick = (row: ISurveySummaryRow) => {
    navigate(`/surveys/${row.token}/summary`);
  };

  return (
    <PageContent>
      <PageHeader title="Surveys" mobileTitle="Surveys" />
      {response ? (
        <SurveyCyclesTable
          rows={response.rows}
          onRowClick={handleReviewCycleClick}
          showColumns={['name', 'startedDate', 'endedDate']}
        />
      ) : (
        <Skeleton />
      )}
    </PageContent>
  );
};

export default SurveyCyclesPage;
