import { useGetOrganizationFeatures } from '@client/FeaturesClient';
import { Feature, IFeatureValue, featureDefaults } from '@shared/features';
import { OrganizationToken } from '@shared/types';

export const useOrganizationFeature = (
  organizationToken: OrganizationToken,
  feature: Feature,
): IFeatureValue => {
  const { data: featureData } = useGetOrganizationFeatures(organizationToken);
  const overrides = featureData?.featureOverrides;
  return overrides?.[feature] ?? featureDefaults[feature];
};
