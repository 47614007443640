import { useTeamAnalytics } from '@client/AnalyticsClient';
import {
  ITeamMetrics,
  METRIC_GREEN,
  METRIC_RED,
  METRIC_YELLOW,
  MetricType,
  TeamMetrics,
} from '@shared/analytics';
import { ITeam, TeamToken } from '@shared/types';
import { usePagination } from '@web/common/usePagination';
import { UserAvatar } from '@web/components/UserAvatar';
import { NoWrap, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Avatar, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { every } from 'lodash';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { MetricColumnTitle, MetricLabel } from './MetricColumnTitle';
import { USER_PAGE_PARAM } from './UserMetricsTable';
import { LineGraphMetric } from './visualizations/LineGraphMetric';
import { PieChartMetric } from './visualizations/PieChartMetric';
import { ValuePercentMetric } from './visualizations/ValuePercentMetric';

export const TEAM_PAGE_PARAM = 'tpage';

export const TeamMetricsTable: React.FC<{
  teamToken?: TeamToken;
  metricTypes: MetricType[];
}> = ({ teamToken, metricTypes }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data } = useTeamAnalytics(teamToken);
  const { pagination } = usePagination(data?.teams?.length ?? 0, {
    pageSize: 10,
    paramName: TEAM_PAGE_PARAM,
  });
  if (!data) {
    return <Skeleton />;
  }

  const { teamMetrics, teams } = data;

  const columns: ColumnsType<ITeam> = [
    {
      title: <MetricLabel>Team Manager</MetricLabel>,
      dataIndex: 'manager',
      key: 'manager',
      fixed: 'left',
      render(manager) {
        return manager ? (
          <Row gap={6}>
            <UserAvatar user={manager} size={28} />{' '}
            <NoWrap>{manager.name}</NoWrap>
          </Row>
        ) : (
          <Row gap={6}>
            <Avatar
              size={28}
              style={{
                flexGrow: 0,
                flexShrink: 0,
                backgroundColor: '#aaa',
                border: 0,
                fontSize: 12,
              }}
            >
              -
            </Avatar>{' '}
            <NoWrap>No manager</NoWrap>
          </Row>
        );
      },
    },
    {
      title: <MetricLabel>Members</MetricLabel>,
      key: 'members',
      align: 'center',
      render: (_, team) => <MetricLabel>{team.members.length}</MetricLabel>,
    },
  ];
  for (const metricType of metricTypes) {
    columns.push(createMetricColumn(metricType, teamMetrics));
  }
  if (metricTypes.length < 4) {
    columns.push({
      title: '',
      width: '100%',
      render: () => '',
    });
  }

  const handleRowClicked = (team: ITeam) => {
    searchParams.delete(USER_PAGE_PARAM);
    navigate(
      `/analytics/individuals?filter=${team.token}&${searchParams.toString()}`,
    );
  };

  return (
    <Table
      dataSource={teams}
      columns={columns}
      scroll={{ x: true }}
      pagination={pagination}
      onRow={(team) => ({
        onClick: () => handleRowClicked(team),
        style: {
          cursor: 'pointer',
          height: 90,
        },
      })}
    />
  );
};

const createMetricColumn = (
  metricType: MetricType,
  teamMetrics: TeamMetrics,
): ColumnsType<ITeam>[number] => {
  return {
    title: <MetricColumnTitle metricType={metricType} />,
    key: metricType,
    align: 'center',
    render(_, team: ITeam) {
      return (
        <ViewTableMetric metrics={teamMetrics[team.token]} type={metricType} />
      );
    },
  };
};

export const ViewTableMetric: React.FC<{
  type: MetricType;
  metrics: ITeamMetrics;
}> = ({ type, metrics }) => {
  switch (type) {
    case MetricType.GOALS:
      if (
        metrics.goalsOnTrack + metrics.goalsAtRisk + metrics.goalsOffTrack ===
        0
      ) {
        return <Text>-</Text>;
      }

      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.goalsOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.goalsAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.goalsOffTrack,
              color: METRIC_RED,
            },
          ]}
          circleSize={50}
        />
      );
    case MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED:
      if (metrics.userCount === 0) {
        return <Text>-</Text>;
      }

      return (
        <ValuePercentMetric
          value={metrics.oneOnOnesUsersWithCompleted}
          total={metrics.userCount}
        />
      );
    case MetricType.JOURNAL_USER_WITH_ENTRIES:
      if (metrics.userCount === 0) {
        return <Text>-</Text>;
      }

      return (
        <ValuePercentMetric
          value={metrics.journalUsersWithEntries}
          total={metrics.userCount}
        />
      );
    case MetricType.CHECKIN_ACTIVITY:
      if (metrics.usersAsParticipants === 0) {
        return <Text>-</Text>;
      }

      return (
        <ValuePercentMetric
          value={metrics.usersWithCheckins}
          total={metrics.usersAsParticipants}
        />
      );
    case MetricType.JOURNAL_WEEKLY_ENTRIES:
      if (
        !metrics.journalWeeklyUsage ||
        every(metrics.journalWeeklyUsage, (usage) => usage.value === 0)
      ) {
        return <Text>-</Text>;
      }

      return (
        <LineGraphMetric
          labeledMetrics={metrics.journalWeeklyUsage}
          width={150}
          height={70}
          hideLabels
        />
      );
    case MetricType.WEEKLY_CHECKINS:
      if (
        !metrics.checkinWeeklyUsage ||
        every(metrics.checkinWeeklyUsage, (usage) => usage.value === 0)
      ) {
        return <Text>-</Text>;
      }

      return (
        <LineGraphMetric
          labeledMetrics={metrics.checkinWeeklyUsage}
          width={150}
          height={70}
          hideLabels
        />
      );
    case MetricType.ONE_ON_ONES_ALIGNMENT: {
      if (
        metrics.oneOnOnesAlignmentOnTrack +
          metrics.oneOnOnesAlignmentAtRisk +
          metrics.oneOnOnesAlignmentOffTrack ===
        0
      ) {
        return <Text>-</Text>;
      }
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.oneOnOnesAlignmentOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.oneOnOnesAlignmentAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.oneOnOnesAlignmentOffTrack,
              color: METRIC_RED,
            },
          ]}
          circleSize={50}
        />
      );
    }
    case MetricType.JOURNAL_ALIGNMENT: {
      if (
        metrics.journalAlignmentOnTrack +
          metrics.journalAlignmentAtRisk +
          metrics.journalAlignmentOffTrack ===
        0
      ) {
        return <Text>-</Text>;
      }
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.journalAlignmentOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.journalAlignmentAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.journalAlignmentOffTrack,
              color: METRIC_RED,
            },
          ]}
          circleSize={50}
        />
      );
    }
    case MetricType.MANAGER_ONE_ON_ONES_SCHEDULED:
      if (metrics.userCount === 0) {
        return <Text>-</Text>;
      }

      return (
        <ValuePercentMetric
          value={metrics.managerOneOnOnesScheduled}
          total={metrics.userCount}
        />
      );
    default:
      return null;
  }
};
