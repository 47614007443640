import {
  EyeInvisibleOutlined,
  EyeOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  hidePerformanceSummary,
  revealPerformanceSummary,
} from '@client/PerformanceClient';
import { IPerformanceSummary } from '@shared/performance';
import { useAuth } from '@web/auth/useAuth';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { StopPropagation } from '@web/components/StopPropagation';
import { UserAvatar } from '@web/components/UserAvatar';
import { Row } from '@web/components/layout';
import { SubduedText, Text } from '@web/components/typography';
import { Button, Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import humanparser from 'humanparser';
import { filter } from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const SummariesTable: React.FC<{
  summaries: IPerformanceSummary[];
  onChange: () => void;
}> = ({ summaries, onChange }) => {
  const { impersonate } = useAuth();
  const navigate = useNavigate();

  const handleHideClicked = async (summary: IPerformanceSummary) => {
    try {
      await hidePerformanceSummary(summary.token);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleRevealClicked = async (summary: IPerformanceSummary) => {
    try {
      await revealPerformanceSummary(summary.token);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleViewAs = async (summary: IPerformanceSummary) => {
    try {
      await impersonate(summary.user.token, `/performance/${summary.token}`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const columns: ColumnsType<IPerformanceSummary> = filter([
    {
      title: 'Description',
      dataIndex: 'name',
      key: 'name',
      render(name) {
        return (
          name ?? <SubduedText>&lt;&lt;User generated&gt;&gt;</SubduedText>
        );
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render(user) {
        return (
          <Row gap={6}>
            <UserAvatar user={user} size={20} />
            <Text>{user.name}</Text>
          </Row>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Visibility',
      key: 'visibility',
      render(_, summary) {
        const { firstName } = summary.user
          ? humanparser.parseName(summary.user.name)
          : { firstName: 'Unknown' };
        if (!summary.hiddenDate) {
          return (
            <Row gap={6}>
              <EyeOutlined />
              <Text>{firstName} + manager</Text>
            </Row>
          );
        }

        return (
          <Row gap={6}>
            <EyeInvisibleOutlined />
            <Text>Internal only</Text>
          </Row>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render(_, summary) {
        const { firstName } = summary.user
          ? humanparser.parseName(summary.user.name)
          : { firstName: 'Unknown' };
        if (!summary.hiddenDate) {
          return (
            <StopPropagation>
              <Button
                size="small"
                onClick={() => {
                  void handleViewAs(summary);
                }}
                icon={<UserSwitchOutlined />}
              >
                View
              </Button>
              <ConfirmButton
                size="small"
                title="Hide Summary"
                description={`Do you want to hide this summary from ${firstName} and their manager?`}
                onConfirm={() => {
                  void handleHideClicked(summary);
                }}
              >
                Hide
              </ConfirmButton>
            </StopPropagation>
          );
        }

        return (
          <StopPropagation>
            <ConfirmButton
              size="small"
              title="Reveal Summary"
              description={`Do you want to mark this summary visible to ${firstName} and their manager?`}
              onConfirm={() => {
                void handleRevealClicked(summary);
              }}
            >
              Reveal
            </ConfirmButton>
          </StopPropagation>
        );
      },
    },
  ]);

  return (
    <Table
      rowKey="token"
      columns={columns}
      dataSource={summaries}
      onRow={(row) => ({
        onClick: () => {
          navigate(row.token);
        },
        style: {
          cursor: 'pointer',
        },
      })}
    />
  );
};
