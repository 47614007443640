/* eslint-disable no-template-curly-in-string */
import { INotificationTemplate } from '@shared/notitifications';
import { KnownBlock } from '@slack/types';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CodeMirror from '@uiw/react-codemirror';
import { PageContent } from '@web/app/Page';
import { del, patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane, PaneHeader } from '@web/components/Pane';
import { Column, Row, Spacer } from '@web/components/layout';
import { Alert, Button, Input, Skeleton, Typography, message } from 'antd';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const NotificationTemplatePage: React.FC = () => {
  const navigate = useNavigate();
  const { notificationTemplateToken } = useParams();
  const [error, setError] = React.useState<string>(null);
  const [type, setType] = React.useState('');
  const [blocks, setBlocks] = React.useState(
    notificationTemplateToken
      ? ''
      : JSON.stringify(defaultBlocks, ' ' as any, 2).trim(),
  );

  const { data: notificationTemplate } = useApi<INotificationTemplate>(
    notificationTemplateToken
      ? `/notifications/templates/${notificationTemplateToken}`
      : null,
  );

  React.useEffect(() => {
    if (notificationTemplate) {
      setBlocks(notificationTemplate.blocks);
      setType(notificationTemplate.type);
    }
  }, [notificationTemplate]);

  if (notificationTemplateToken && !notificationTemplate) {
    return (
      <PageContent>
        <PageHeader />
        <Skeleton />
      </PageContent>
    );
  }

  const handleSave = async () => {
    setError(null);

    if (!blocks.trim() || !type.trim()) {
      void message.error('Type and Blocks are required');
      return;
    }

    try {
      JSON.parse(blocks);
    } catch (error) {
      setError(`Invalid Blocks: ${error.message}`);
      return;
    }

    try {
      if (notificationTemplate) {
        await patch<INotificationTemplate>(
          `/notifications/templates/${notificationTemplate.token}`,
          { blocks, type: type.trim() },
        );
      } else {
        await post<INotificationTemplate>('/notifications/templates', {
          blocks,
          type,
        });
      }

      void message.success('Template saved');
      navigate(-1);
    } catch (error) {
      console.log(error);
      void message.error('Error');
    }
  };

  const handleDelete = async () => {
    try {
      await del(`/notifications/templates/${notificationTemplate.token}`);
      void message.success('Template deleted');
      navigate(-1);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageContent>
      <PaneHeader
        title={notificationTemplateToken ? 'Edit Template' : 'New Template'}
        navigateBack
        extras={
          notificationTemplateToken ? (
            <ConfirmButton onConfirm={handleDelete}>
              Delete template
            </ConfirmButton>
          ) : undefined
        }
      />
      <Pane>
        <Column gap={24}>
          <Column>
            <Typography.Text>Type</Typography.Text>
            <Input
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              placeholder="e.g. CUSTOM_USER_REMINDER"
            />
          </Column>
          <Column>
            <Typography.Text>Blocks</Typography.Text>
            {error && (
              <Alert
                type="error"
                message={error}
                style={{ margin: '10px 0' }}
              />
            )}
            <CodeMirror
              value={blocks}
              onChange={setBlocks}
              height="500px"
              theme={vscodeDark}
            />
            <Typography>
              Check out Slack{"'"}s{' '}
              <a
                href="https://api.slack.com/block-kit"
                target="_blank"
                rel="noreferrer"
              >
                BlockKit documentation
              </a>{' '}
              to learn more.
            </Typography>
          </Column>
          <Row gap={12}>
            <Button type="primary" onClick={handleSave}>
              Save Template
            </Button>
            <Button type="default" onClick={handleCancel}>
              Cancel
            </Button>
          </Row>
        </Column>
      </Pane>
      <Spacer />
      <Pane>
        <SendNotificationPreview blocks={blocks} />
      </Pane>
    </PageContent>
  );
};

const BUILTIN_PLACEHOLDERS = ['${name}', '${flintUrl}'];

const defaultBlocks: KnownBlock[] = [
  {
    type: 'section',
    text: {
      type: 'plain_text',
      text: ':wave: Welcome to Flint ${name}!. Flint makes it easy to track your accomplishments while getting feedback and recognition from your peers.',
      emoji: true,
    },
  },
  {
    type: 'actions',
    elements: [
      {
        type: 'button',
        text: {
          type: 'plain_text',
          text: 'View in Flint',
          emoji: true,
        },
        url: '${flintUrl}',
      },
    ],
  },
];

const SendNotificationPreview: React.FC<{ blocks: string }> = ({ blocks }) => {
  const [placeholderValues, setPlaceholderValues] = React.useState({});

  let placeholders: string[] = blocks.match(/\$\{[a-zA-Z]+\}/g) ?? [];
  placeholders = placeholders.filter(
    (placholder) => !BUILTIN_PLACEHOLDERS.includes(placholder),
  );

  const handlePreview = async () => {
    try {
      JSON.parse(blocks);
    } catch (error) {
      void message.error(`Invalid Blocks: ${error.message}`);
      return;
    }

    for (const placeholder of placeholders) {
      if (!placeholderValues[placeholder]) {
        void message.error(
          `Missing placholder value for ${placeholder.slice(2, -1)}`,
        );
        return;
      }
    }

    try {
      await post('/slack/send-preview', {
        blocks,
        placeholderValues,
      });
      void message.success('Preview sent');
    } catch (error) {
      console.log(error);
      void message.error('Error');
    }
  };

  return (
    <Column gap={24}>
      <Typography.Text>
        Send a preview of this template to #notifications-preview
      </Typography.Text>
      {placeholders?.map((placeholder) => (
        <Row key={placeholder}>
          <Typography.Text style={{ width: 150 }}>
            {placeholder.slice(2, -1)}
          </Typography.Text>
          <Input
            value={placeholderValues[placeholder]}
            disabled={BUILTIN_PLACEHOLDERS.includes(placeholder)}
            onChange={(e) => {
              setPlaceholderValues((values) => ({
                ...values,
                [placeholder]: e.target.value,
              }));
            }}
          />
        </Row>
      ))}
      <Row gap={12}>
        <Button type="default" onClick={handlePreview}>
          Send Preview
        </Button>
      </Row>
    </Column>
  );
};
