import { LOGIN_REDIRECT_PATH_KEY } from '@shared/constants';
import { UserRole } from '@shared/types';
import { NotFoundPage } from '@web/app/NotFoundPage';
import { useAuth } from '@web/auth/useAuth';
import * as React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

interface Props {
  /** When provided only users with this role or the admin role can access the guarded routes */
  roles?: UserRole[];
}

/**
 * This component is meant to be used with nested routes in order
 * to avoid rendering any nested route if auth conditions are not met.
 *
 * This component should only be used in AppRoutes to ensure it only exists
 * once per path
 */
export const RolesRouteGuard: React.FC<Props> = ({ roles }) => {
  const { validateRole, isLoggedIn } = useAuth();
  const outlet = useOutlet();

  if (!isLoggedIn) {
    window.localStorage[LOGIN_REDIRECT_PATH_KEY] =
      window.location.pathname + window.location.search;
    return <Navigate to="/auth/login" />;
  } else if (!roles.some(validateRole)) {
    return <NotFoundPage />;
  }

  return outlet;
};
