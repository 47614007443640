import { UserMap, UserToken } from '@shared/types';
import { primaryColor } from '@web/app/styles/ColorStyles';
import { useAuth } from '@web/auth/useAuth';
import { Column, Spacer } from '@web/components/layout';
import { Tooltip, Typography } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const Reaction: React.FC<{
  emoji: string;
  onClick: (emoji: string) => void;
  readonly?: boolean;
  userMap: UserMap;
  userTokens: UserToken[];
}> = ({ emoji, userMap, userTokens, onClick, readonly }) => {
  const { user } = useAuth();
  const ownReaction = userTokens.includes(user.token);
  const handleClick = () => {
    onClick(emoji);
  };

  return (
    <Tooltip
      title={
        <ReactionTooltipTitle
          emoji={emoji}
          userMap={userMap}
          userTokens={userTokens}
        />
      }
      overlayStyle={{ width: 160 }}
    >
      <ReactionContainer
        onClick={handleClick}
        className={ownReaction && !readonly ? 'active' : null}
        readonly={readonly}
      >
        <ReactionEmoji emoji={emoji} key={emoji} />
        <Spacer size={2} />
        <Typography.Text
          style={{ fontFamily: 'monospace', fontSize: 12, lineHeight: '16px' }}
        >
          {userTokens.length}
        </Typography.Text>
      </ReactionContainer>
    </Tooltip>
  );
};

const ReactionTooltipTitle: React.FC<{
  userTokens: UserToken[];
  userMap: UserMap;
  emoji: string;
}> = ({ emoji, userTokens, userMap }) => {
  const { user } = useAuth();
  const getUserName = (userToken: string) => {
    if (userToken === user.token) {
      return user.name;
    }

    return userMap?.[userToken]?.name ?? '';
  };

  return (
    <Column style={{ gap: 10, alignItems: 'center' }}>
      <ReactionTitleEmoji emoji={emoji} />
      <Typography.Text
        style={{ fontSize: 12, color: 'white', textAlign: 'center' }}
      >
        {userTokens.map(getUserName).join(', ')} reacted
      </Typography.Text>
    </Column>
  );
};

const emojiTopAdjust = {
  '🙌': -2,
};

export const ReactionEmoji: React.FC<{ emoji: string; size?: number }> = ({
  emoji,
  size = 16,
}) => (
  <EmojiContainer style={{ height: size }}>
    <span
      style={{
        fontSize: size,
        lineHeight: `${size}px`,
        position: 'relative',
        top: emojiTopAdjust[emoji] ?? 0,
      }}
    >
      {emoji}
    </span>
  </EmojiContainer>
);

export const ReactionTitleEmoji: React.FC<{ emoji: string; size?: number }> = ({
  emoji,
  size = 32,
}) => (
  <EmojiTitleContainer>
    <span style={{ fontSize: size, lineHeight: `${size}px` }}>{emoji}</span>
  </EmojiTitleContainer>
);
const EmojiContainer = styled.span`
  color: white;

  > span {
    display: block;
    width: auto !important;
    height: auto !important;
  }
`;
const EmojiTitleContainer = styled.div`
  background: white;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--default-border-radius);

  > span {
    display: block;
    width: auto !important;
    height: auto !important;
  }
`;
const ReactionContainer = styled.div<{ readonly?: boolean }>`
  display: inline-flex;
  margin-right: 8px;
  margin-bottom: 8px;
  height: 16px;
  box-sizing: content-box;
  padding: 5px 10px;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 16px;
  align-items: center;
  justify-content: center;

  .ant-typography {
    color: '#333';
  }

  svg {
    color: #888;
  }

  ${(props) =>
    props.readonly
      ? null
      : `
  &:hover {
    cursor: pointer;
    background: white;
    border-color: #ccc;

    svg {
      color: #333;
    }
  }
  `}

  &.active {
    background: ${primaryColor.lighten(0.9)};
    border-color: var(--ant-primary-7);
    .ant-typography {
      color: ${primaryColor.darken(0.1)};
    }
  }
`;
