import { IReviewCycle } from '@shared/review-cycles';
import { IUser } from '@shared/types';
import { Column } from '@web/components/layout';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';

export const ReviewCyclesTable: React.FC<{
  reviewCycles: IReviewCycle[];
  onReviewCycleClick: (reviewCycle: IReviewCycle) => void;
  hideParticipants?: boolean;
}> = ({ reviewCycles, onReviewCycleClick, hideParticipants = false }) => {
  const columns: ColumnsType<IReviewCycle> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    hideParticipants
      ? null
      : ({
          title: 'Participants',
          dataIndex: 'participants',
          key: 'participants',
          align: 'right',
          render: (participants: IUser[]) => participants.length,
        } as any),
  ].filter((column) => !!column);

  return (
    <Column>
      <Table
        columns={columns}
        dataSource={reviewCycles}
        pagination={false}
        locale={{
          emptyText: <Empty description="No cycles yet" />,
        }}
        onRow={(reviewCycle) => ({
          onClick: () => {
            onReviewCycleClick(reviewCycle);
          },
          style: {
            cursor: 'pointer',
          },
        })}
        rowKey="token"
      />
    </Column>
  );
};
