import { message } from 'antd';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MESSAGES = {
  GITHUB_OAUTH_SUCCESS: 'Successfully connected to your Github account',
};

const ERRORS = {
  GITHUB_OAUTH_ERROR: 'Failed to connect to your Github account',
};

export const Messages: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('message')) {
      const messageKey = searchParams.get('message');
      if (MESSAGES[messageKey]) {
        void message.success(MESSAGES[messageKey]);
      } else if (ERRORS[messageKey]) {
        void message.error(ERRORS[messageKey]);
      }
      searchParams.delete('message');
      navigate(`${location.pathname}${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, []);

  return null;
};
