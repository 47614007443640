import { IGoal, IGoalList } from '@shared/goals';
import { UserMapItem, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CreateGoal } from './CreateGoal';

export const NewGoalPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { userToken } = useParams<{ userToken?: UserToken }>();
  const [searchParams] = useSearchParams();
  const { data: userDetails } = useApi<UserMapItem>(
    userToken ? `/users/${userToken}` : null,
  );
  const { data: parent } = useApi<{ goal: IGoal }>(
    searchParams.has('parent') ? `/goals/${searchParams.get('parent')}` : null,
  );
  const { data: goalList } = useApi<IGoalList>(
    searchParams.has('list')
      ? `/goals/lists/${searchParams.get('list')}`
      : null,
  );

  const isLoading =
    (userToken && !userDetails) ||
    (searchParams.has('list') && !goalList) ||
    (searchParams.has('parent') && !parent?.goal);
  const handleNewGoal = (goal: IGoal) => {
    if (goalList || parent?.goal) {
      navigate(-1);
      return;
    }

    navigate(
      userToken
        ? `/team/${userToken}/goals/${goal.token}`
        : `/goals/${goal.token}`,
      { replace: true },
    );
  };
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageContent>
      <PageHeader
        title={
          userToken && userDetails
            ? `New Goal for ${userDetails.name}`
            : 'New Goal'
        }
        mobileTitle="New Goal"
        navigateBack
      />
      <Pane>
        {isLoading ? (
          <Skeleton />
        ) : (
          <CreateGoal
            userDetails={userDetails}
            onSave={handleNewGoal}
            onCancel={handleCancel}
            initialList={goalList}
            initialParent={parent?.goal}
            initialAssigned={userToken ? [userDetails] : [user]}
            initialFollowers={userToken ? [user] : []}
          />
        )}
      </Pane>
    </PageContent>
  );
};
