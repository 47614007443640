import { ITag } from '@shared/tags';
import { FormButtons } from '@web/components/FormButtons';
import { Button, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';

export interface TagFormFields {
  name: string;
  description: string;
}

export interface TagFormProps {
  initialTag?: ITag;
  onSave: (fields: TagFormFields) => void;
  onCancel: () => void;
}

export const TagForm: React.FC<TagFormProps> = ({
  initialTag,
  onSave,
  onCancel,
}) => {
  const [form] = Form.useForm<TagFormFields>();
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    form.resetFields();
  }, [initialTag]);

  const handleSave = async () => {
    setIsSaving(true);
    let fields: TagFormFields;
    try {
      fields = await form.validateFields();
    } catch (error) {
      setIsSaving(false);
      return;
    }

    try {
      await onSave(fields);
    } catch (error) {
      void message.error('Error');
    } finally {
      form.resetFields();
      setIsSaving(false);
    }
  };
  const handleCancel = async () => {
    onCancel();
    form.resetFields();
  };
  return (
    <Form form={form} layout="vertical" disabled={isSaving}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}
        initialValue={initialTag?.label}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        initialValue={initialTag?.description}
      >
        <Input />
      </Form.Item>
      <FormButtons>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </FormButtons>
    </Form>
  );
};
