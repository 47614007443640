import { errorColor, primaryColor } from '@web/app/styles/ColorStyles';
import styled from 'styled-components';

export const EditCommentContainer = styled.section`
  border: 1px solid #ddd;
  border-radius: var(--default-border-radius);
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  flex: 1;
  background-color: white;

  > :first-child {
    flex: 1;
  }

  .ql-container {
    background-color: white;
    border: 0;
  }

  .ql-container.ql-snow .ql-editor {
    font-size: 14px;
    padding: 4px 8px;
  }

  .ql-editor.ql-blank::before {
    left: 8px;
  }
`;

export const ActionBar = styled.div`
  display: flex;
  gap: 4px;
  padding: 4px;
  background-color: white;
  border-radius: 0;
`;

export const sendButtonBackgroundColor = primaryColor;

export const ActionBarButton = styled.button`
  appearance: none;
  color: white;
  border: 0;
  height: 24px;
  width: 36px;
  border-radius: var(--default-border-radius);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
`;

export const SendButton = styled(ActionBarButton)`
  background-color: ${sendButtonBackgroundColor.string()};
  &:hover {
    background-color: ${sendButtonBackgroundColor.lighten(0.2)};
  }
  &:disabled {
    background-color: #ddd;
  }
`;
export const SaveButton = styled(ActionBarButton)`
  background-color: ${sendButtonBackgroundColor.string()};
  &:hover {
    background-color: ${sendButtonBackgroundColor.lighten(0.2)};
  }
  &:disabled {
    background-color: #ddd;
  }
`;
export const DeleteButton = styled(ActionBarButton)`
  background-color: ${errorColor.string()};
  &:hover {
    background-color: ${errorColor.lighten(0.2)};
  }
  &:disabled {
    background-color: #ddd;
  }
`;
