import { useOneOnOneRelationships } from '@client/OneOnOnesClient';
import { oneOnOneUrl } from '@shared/one-on-one';
import { IUser } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Spacer } from '@web/components/layout';
import { Divider, Empty, Row, Skeleton } from 'antd';
import { sortBy } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Add1on1Modal } from './Add1on1Modal';

export const List1on1sPage: React.FC<{ archived?: boolean }> = ({
  archived = false,
}) => {
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const [showAdd, setShowAdd] = React.useState(false);
  const { data: relationships } = useOneOnOneRelationships(archived);

  const otherUsers = sortBy(
    relationships?.map((relationship) =>
      relationship.ownerUser.token === user.token
        ? relationship.counterpartUser
        : relationship.ownerUser,
    ) ?? [],
    'name',
  );
  const title = archived ? 'Archived 1-on-1s' : '1-on-1s';
  const emptyText = archived
    ? 'You have no archived 1-on-1s'
    : 'You have no 1-on-1s';
  return (
    <PageContent>
      <PageHeader
        title={title}
        mobileTitle={title}
        primaryAction={
          archived
            ? null
            : {
                label: 'Add new 1-on-1',
                onClick: () => {
                  setShowAdd(true);
                },
              }
        }
        navigateBack={archived}
        secondaryActions={
          !archived
            ? [
                {
                  linkTo: '/1on1s/archived',
                  label: 'Archived 1-on-1s',
                },
              ]
            : []
        }
      />
      <Pane>
        {isMobile && <Spacer />}
        {relationships ? (
          <Column>
            {otherUsers.map((otherUser, index) => (
              <>
                {index !== 0 && <Divider />}
                <ListItem1on1 key={otherUser.token} user={otherUser} />
              </>
            ))}
            {otherUsers.length === 0 && <Empty description={emptyText} />}
          </Column>
        ) : (
          <Skeleton />
        )}
      </Pane>
      {showAdd && (
        <Add1on1Modal
          onCancel={() => {
            setShowAdd(false);
          }}
          omitUserTokens={[...otherUsers.map((user) => user.token), user.token]}
        />
      )}
    </PageContent>
  );
};

export const ListItem1on1: React.FC<{ user: IUser }> = ({ user }) => {
  const { user: currentUser } = useAuth();
  return (
    <Link to={oneOnOneUrl(user.token, currentUser.token)}>
      <Row>
        <UserMessage user={user} />
      </Row>
    </Link>
  );
};
