import { UserToken } from '@shared/types';
import { useLocalStorage } from '@web/common/useLocalStorage';
import { Form, FormInstance } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export interface ImpactFields {
  title: string;
  descriptionMarkdown: string;
  collaboratorTokens: UserToken[];
  date: dayjs.Dayjs;
  isPublic: boolean;
}

export const useSaveLocalDraft = (
  form: FormInstance<ImpactFields>,
  isEdit: boolean,
) => {
  const [draftValues] = useLocalStorage<Partial<ImpactFields>>(
    'draftNewJournalEntry',
    {},
  );
  const title = Form.useWatch('title', form);
  const descriptionMarkdown = Form.useWatch('descriptionMarkdown', form);
  const date = Form.useWatch('date', form);
  useEffect(() => {
    const fieldsTouched = form.isFieldsTouched([
      'title',
      'descriptionMarkdown',
      'date',
    ]);
    if (!isEdit && fieldsTouched) {
      localStorage.setItem(
        'draftNewJournalEntry',
        JSON.stringify({
          title,
          descriptionMarkdown,
          date: date?.toString() ?? undefined,
        }),
      );
    }
  }, [title, descriptionMarkdown, date]);

  const resetDraft = () => {
    localStorage.setItem('draftNewJournalEntry', JSON.stringify({}));
  };

  return {
    draftValues: {
      ...draftValues,
      date: draftValues.date
        ? dayjs(new Date(draftValues.date as any))
        : dayjs(),
    },
    resetDraft,
  };
};
