import { IOrganization } from '@shared/types';
import { useOrganizationFilter } from '@web/app/organizationFilter';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { Text } from '@web/components/text';
import { Table, Tag } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  organizations: IOrganization[];
  total: number;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onSelect: (organization: IOrganization) => void;
}

export const SelectOrganization: React.FC<Props> = ({
  page,
  organizations,
  total,
  pageSize,
  onPageChange,
  onSelect,
}) => {
  const { organizationTokenFilter } = useOrganizationFilter();
  const columns: ColumnsType<IOrganization> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, organization) => (
        <Text>
          {name}{' '}
          {organization.token === organizationTokenFilter ? '(selected)' : ''}
        </Text>
      ),
    },
    {
      title: 'Sandbox',
      dataIndex: 'sandboxMode',
      key: 'sandboxMode',
      render: (sandboxMode) =>
        sandboxMode ? <Tag color="#aaa">Sandbox</Tag> : null,
    },
  ];

  const pagination: TablePaginationConfig = {
    total,
    onChange: onPageChange,
    current: page,
    pageSize,
    showSizeChanger: false,
  };

  return (
    <StyledTable
      rowKey="token"
      pagination={pagination}
      columns={columns}
      dataSource={organizations}
      showHeader={false}
      onRow={(organization: IOrganization) => {
        return {
          onClick: () => {
            onSelect(organization);
          },
          className:
            organizationTokenFilter === organization.token ? 'selected' : '',
        };
      }}
    />
  );
};

const StyledTable = styled(Table)`
  tr {
    cursor: pointer;
  }

  tr.selected td {
    background: ${complimentaryColor.lighten(0.9)}!important;
  }
`;
