import { GithubOutlined } from '@ant-design/icons';
import { Row, Spacer } from '@web/components/layout';
import { Tag } from 'antd';
import React from 'react';

export const GithubMember: React.FC<{ login: string }> = ({ login }) => {
  return (
    <a href={`https://github.com/${login}`} target="_blank" rel="noreferrer">
      <Tag style={{ margin: 0 }}>
        <Row>
          <GithubOutlined style={{ color: '#333' }} />
          <Spacer size={6} />
          {login}
        </Row>
      </Tag>
    </a>
  );
};
