import { DeleteOutlined } from '@ant-design/icons';
import {
  useBedrockEnrolledChannelsAdmin,
  useBedrockEnrolledChannelsInternal,
} from '@client/BedrockConfigurationClient';
import { OrganizationToken } from '@shared/types';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Modal, Skeleton, message } from 'antd';
import { sortBy } from 'lodash';
import * as React from 'react';

export const ShowChannelsButton: React.FC<{
  children?: React.ReactNode;
  onDelete: (channelId: string) => Promise<void>;
  organizationToken?: OrganizationToken;
}> = ({ children, onDelete, organizationToken }) => {
  const [open, setOpen] = React.useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Column>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </Button>
      {open && (
        <ShowChannelsModel
          organizationToken={organizationToken}
          onDelete={onDelete}
          onClose={closeModal}
        />
      )}
    </Column>
  );
};

const ShowChannelsModel: React.FC<{
  onDelete: (channelId: string) => Promise<void>;
  onClose: () => void;
  organizationToken?: OrganizationToken;
}> = ({ onDelete, onClose, organizationToken }) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { data: channels, mutate: reloadChannels } = organizationToken
    ? useBedrockEnrolledChannelsInternal(organizationToken)
    : useBedrockEnrolledChannelsAdmin();

  const handleDeleteChannel = async (channelId: string) => {
    setIsDeleting(true);
    try {
      await onDelete(channelId);
      await reloadChannels();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      open={true}
      title="Channels"
      onCancel={onClose}
      onOk={onClose}
      width="400px"
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="Close"
    >
      <Column gap={3}>
        {channels ? (
          sortBy(channels, 'name').map((channel) => (
            <Row key={channel.id} gap={6}>
              <DeleteOutlined
                disabled={isDeleting}
                onClick={async () => {
                  await handleDeleteChannel(channel.id);
                }}
              />
              <Text>#{channel.name}</Text>
            </Row>
          ))
        ) : (
          <Skeleton />
        )}
      </Column>
    </Modal>
  );
};
