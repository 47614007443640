import { SendMessageRequest, messageHeaderPrefix } from '@shared/messages';
import { ListSurveyCyclesResponse, SurveyCycleToken } from '@shared/surveys';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { FormButtons } from '@web/components/FormButtons';
import { Markdown } from '@web/components/Markdown';
import { MarkdownEditor } from '@web/components/MarkdownEditor';
import { Column } from '@web/components/layout';
import { Subheading } from '@web/components/text';
import { Text } from '@web/components/typography';
import { BorderedPane } from '@web/review-cycles/BorderedPane';
import { Button, Popconfirm, Select, message } from 'antd';
import * as React from 'react';
import { useState } from 'react';

export const AdminMessagesPage: React.FC = () => {
  const { user } = useAuth();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [audience, setAudience] = useState<
    SendMessageRequest['audience'] | undefined
  >();
  const [markdown, setMarkdown] = useState<string>('');

  const previewMarkdown = React.useMemo(() => {
    return `_${messageHeaderPrefix} ${user.name}:_\n\n${markdown}`;
  }, [user, markdown]);

  const handleMarkdownOnChange = (value: string) => {
    setMarkdown(value);
  };

  const handleSend = async () => {
    setIsSending(true);
    const request: SendMessageRequest = {
      audience,
      markdown,
    };
    try {
      await post<SendMessageRequest>(`/messages`, request);
      void message.success('Success');
    } catch (error) {
      void message.error('Failed');
    } finally {
      setIsSending(false);
    }
  };

  const handleSurveyChange = async (surveyToken: SurveyCycleToken) => {
    setAudience(surveyToken);
  };

  const handleDryRun = async () => {
    setIsSending(true);
    const request: SendMessageRequest = {
      audience: user.token,
      markdown,
    };
    try {
      await post<SendMessageRequest>(`/messages`, request);
      void message.success('Success');
    } catch (error) {
      void message.error('Failed');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <AdminPageContent>
      <Column gap={12}>
        <Subheading>Messages</Subheading>
        <Column gap={6}>
          <Column>
            <Text>Message</Text>
            <MarkdownEditor onChange={handleMarkdownOnChange} />
          </Column>
          <Column>
            <Text>Survey</Text>
            <SelectSurvey disabled={isSending} onChange={handleSurveyChange} />
          </Column>
        </Column>
        <Column>
          <Text>MD Preview</Text>
          <BorderedPane>
            <Column>
              <Markdown value={previewMarkdown} />
            </Column>
          </BorderedPane>
        </Column>
        <FormButtons>
          <Popconfirm
            disabled={isSending || !markdown.trim() || !audience}
            title="Send mass message"
            description="Are you sure you want to send a message to all participants in the survey?"
            onConfirm={handleSend}
          >
            <Button
              type="primary"
              disabled={isSending || !markdown.trim() || !audience}
            >
              Send
            </Button>
          </Popconfirm>
          <Button
            disabled={isSending || !markdown.trim()}
            onClick={handleDryRun}
          >
            Send To Me
          </Button>
        </FormButtons>
      </Column>
    </AdminPageContent>
  );
};

const SelectSurvey: React.FC<{
  disabled?: boolean;
  onChange?: (surveyToken: SurveyCycleToken) => void;
}> = ({ disabled, onChange }) => {
  const { data: response } = useApi<ListSurveyCyclesResponse>(
    '/survey-cycles/admin/list',
  );
  const options = response?.cycles
    ? response.cycles.map((survey) => ({
        label: survey.name,
        value: survey.token,
      }))
    : [];

  return <Select disabled={disabled} options={options} onChange={onChange} />;
};
