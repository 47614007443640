import { CreateSandboxRequest, IOrganization } from '@shared/types';
import { post } from '@web/common/api';
import { Form, Input, Modal, message } from 'antd';
import React from 'react';

interface Props {
  onCancel: () => void;
  onAdd: (organization: IOrganization) => void;
  open: boolean;
}

export const AddSandboxModal: React.FC<Props> = ({ onAdd, onCancel, open }) => {
  const [form] = Form.useForm<CreateSandboxRequest>();
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);
    let request: CreateSandboxRequest;
    try {
      request = await form.validateFields();
    } catch (error) {
      setIsSaving(false);
      return;
    }

    try {
      const sandbox = await post<IOrganization>('/sandbox', request);
      void message.success('Successfully added sandbox');
      form.resetFields();
      onAdd(sandbox);
    } catch (error) {
      void message.error('Failed to add sandbox');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Add Sandbox"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
    >
      <Form form={form} name="basic" autoComplete="off" layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input disabled={isSaving} autoFocus />
        </Form.Item>
        <Form.Item label="Manager Name" name="managerName">
          <Input disabled={isSaving} />
        </Form.Item>
        <Form.Item label="Report Name" name="reportName">
          <Input disabled={isSaving} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
