import { TablePaginationConfig } from 'antd';
import { useSearchParams } from 'react-router-dom';

export interface PaginationOptions {
  pageSize?: number;
  paramName?: string;
}

export const usePagination = (
  totalResults: number = 0,
  { pageSize = 10, paramName = 'page' }: PaginationOptions = {
    pageSize: 10,
    paramName: 'page',
  },
) => {
  const [searchParams, setSearchParams] = useSearchParams({
    [paramName]: '1',
  });
  const setPage = (newPage: number) => {
    setSearchParams((prev) => {
      prev.set(paramName, `${newPage}`);
      return prev;
    });
  };
  const page = parseInt(searchParams.get(paramName) ?? '1');
  const pagination: TablePaginationConfig = {
    total: totalResults,
    onChange: setPage,
    current: page,
    pageSize,
    showSizeChanger: false,
    showTotal: (total) => `Total: ${total}`,
  };

  return { pagination, pageSize, page, setPage };
};
