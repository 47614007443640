/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { QuestionType } from '@shared/QuestionType';
import { IQuestionResponse } from '@shared/question_response';
import {
  IOpenEndedQuestion,
  IQuestion,
  IRatingQuestion,
} from '@shared/questions';
import { RatingTrend, RatingTrendItem } from '@shared/rating_scales';
import { SurveyCycleToken } from '@shared/surveys';
import { EditedLabel } from '@web/components/EditedLabel';
import { Column } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { renderReceiverQuestionText } from '@web/questions/renderReceiverQuestionText';
import React from 'react';
import styled from 'styled-components';

import { RatingTrendChart } from './RatingTrendChart';

export const SurveyResponse: React.FC<{
  question: IQuestion;
  response?: IQuestionResponse;
  trend?: RatingTrend;
  currentSurveyCycleToken?: SurveyCycleToken;
}> = ({ question, response, trend, currentSurveyCycleToken }) => {
  switch (question.type) {
    case QuestionType.OPEN_ENDED:
      return (
        <SurveyOpenEndedResponse
          key={question.token}
          question={question}
          response={response}
        />
      );
    case QuestionType.RATING:
      return (
        <SurveyRatingResponse
          key={question.token}
          question={question}
          response={response}
          trend={trend}
          currentSurveyCycleToken={currentSurveyCycleToken}
        />
      );
    default:
      return null;
  }
};

const SurveyRatingResponse: React.FC<{
  question: IRatingQuestion;
  response?: IQuestionResponse;
  receiverName?: string;
  trend?: RatingTrendItem[];
  currentSurveyCycleToken?: SurveyCycleToken;
}> = ({ question, response, receiverName, trend, currentSurveyCycleToken }) => {
  if (!response || response.rating === null) {
    return null;
  }

  const responseRating = response.rating
    ? question.scale?.find((rating) => rating.value === response.rating)
    : null;

  return (
    <Column gap={12}>
      <Header3>{renderReceiverQuestionText(question, receiverName)}</Header3>
      {responseRating ? (
        <RatingResponse>
          {responseRating.value} - {responseRating.label}
        </RatingResponse>
      ) : (
        <Text>No response</Text>
      )}
      {trend && trend.length > 1 && (
        <>
          <Header3>Previous responses</Header3>
          <RatingTrendChart
            trend={trend as any}
            currentKey={currentSurveyCycleToken}
          />
        </>
      )}
      <EditedLabel
        entity={{
          createdDate: response.createdDate,
          updatedDate: response.updatedDate,
        }}
      />
    </Column>
  );
};

const SurveyOpenEndedResponse: React.FC<{
  question: IOpenEndedQuestion;
  response?: IQuestionResponse;
  receiverName?: string;
}> = ({ question, response, receiverName }) => {
  if (!response || response.text === null) {
    return null;
  }

  return (
    <Column gap={6}>
      <Header3>{renderReceiverQuestionText(question, receiverName)}</Header3>
      <Text style={{ whiteSpace: 'pre' }}>
        {response?.text ?? 'No response provided'}
      </Text>
      <EditedLabel
        entity={{
          createdDate: response.createdDate,
          updatedDate: response.updatedDate,
        }}
      />
    </Column>
  );
};

const RatingResponse = styled(Text)`
  padding: 6px 12px;
  gap: 6px;
  border: 1px solid #aaa;
  border-radius: var(--default-border-radius);
  max-width: 300px;
`;
