import { CheckCircleFilled, SlackOutlined } from '@ant-design/icons';
import { formatDate } from '@shared/formatDate';
import { ISlackAccess, IUser } from '@shared/types';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { get } from '@web/common/api';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Header3, Small } from '@web/components/typography';
import { Button, message } from 'antd';
import * as React from 'react';
import { useFetch } from 'usehooks-ts';

import { AdminPageContent } from './AdminPageContent';

const AdminSlackPage: React.FC = () => {
  const { data: slackInstall } = useFetch<{
    url: string;
    access?: ISlackAccess;
    user?: IUser;
  }>(`${process.env.API_ROOT ?? ''}/api/slack/install`, {
    credentials: 'include',
  });

  if (!slackInstall) {
    return null;
  }

  const { access, user } = slackInstall;

  const handleTestAccess = async () => {
    const { ok, error } = await get<{ ok: boolean; error?: string }>(
      '/slack/test',
    );
    if (ok) {
      void message.success(
        'Slack is working as expected and you should receive a message from Flint',
      );
    } else {
      void message.error(error);
    }
  };

  return (
    <AdminPageContent>
      <Text>
        Flint integrates with Slack to send notifications and simplify the
        process of setting up your teams.
      </Text>
      <Column gap={24}>
        {access &&
          (user ? (
            <Row
              gap={12}
              style={{
                padding: '18px',
                background: complimentaryColor.lighten(0.9),
                borderRadius: 'var(--default-border-radius)',
              }}
            >
              <CheckCircleFilled
                style={{
                  fontSize: 36,
                  color: 'var(--complimentary-color)',
                }}
              />
              <Column>
                <Header3 style={{ lineHeight: '20px' }}>
                  Connected to {slackInstall.access.teamName ?? 'unknown'}
                </Header3>
                <Small style={{ lineHeight: '20px' }}>
                  {user.name} added Flint to your Slack workspace on{' '}
                  {formatDate(slackInstall.access.updatedDate)}
                </Small>
              </Column>
            </Row>
          ) : (
            <Header3>Flint has been added to your Slack workspace</Header3>
          ))}
        <Row gap={12}>
          <a href={slackInstall.url}>
            <Button type={access ? 'default' : 'primary'}>
              <SlackOutlined
                style={{
                  top: -2,
                  position: 'relative',
                }}
              />
              Authorize Flint on Slack
            </Button>
          </a>
          <Button onClick={handleTestAccess}>Test Slack</Button>
        </Row>
      </Column>
    </AdminPageContent>
  );
};

export default AdminSlackPage;
