import { IQuestionResponse } from '@shared/question_response';
import {
  ISurveyCycle,
  ISurveyParticipant,
  SurveyCycleToken,
} from '@shared/surveys';
import { IUser } from '@shared/types';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { useApi } from '@web/common/useApi';
import { usePagination } from '@web/common/usePagination';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Empty, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { filter, sortBy } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AddParticipantsModal } from './AddParticipantsModal';
import { AdminSurveyContent } from './AdminSurveyContent';
import { DashboardRowActionsMenu } from './DashboardRowActionsMenu';

const DEFAULT_COLUMN_WIDTH = '150px';

export const AdminSurveyCycleParticipantsPage: React.FC = () => {
  const [showAddParticipants, setShowAddParticipants] = React.useState(false);
  const { surveyCycleToken } = useParams<{
    surveyCycleToken?: SurveyCycleToken;
  }>();
  const { data: surveyCycle, mutate: reloadSurveyCycle } = useApi<ISurveyCycle>(
    `/survey-cycles/${surveyCycleToken}`,
  );
  const { pagination } = usePagination();

  if (!surveyCycle) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const columns: ColumnsType<ISurveyParticipant> = filter([
    {
      title: 'Participant',
      dataIndex: 'user',
      key: 'user',
      render: (user: IUser) => {
        return <UserMessage user={user} style={{ whiteSpace: 'nowrap' }} />;
      },
    },
    surveyCycle.startedDate && !surveyCycle.anonymous
      ? {
          title: 'Responded',
          dataIndex: 'responses',
          key: 'responses',
          align: 'center',
          render: (responses: IQuestionResponse[]) => {
            return responses?.length > 0 ? <Text>Yes</Text> : <Text>No</Text>;
          },
        }
      : (null as any),
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: DEFAULT_COLUMN_WIDTH,
      render: (_, row) => {
        return (
          <DashboardRowActionsMenu
            surveyCycle={surveyCycle}
            participant={row}
            onChange={handleRowChange}
          />
        );
      },
    },
  ]);

  const handleRowChange = () => {
    void reloadSurveyCycle();
  };

  const handleAddParticipants = () => {
    void reloadSurveyCycle();
    setShowAddParticipants(false);
  };

  const sortedParticipants = sortBy(
    surveyCycle.participants ?? [],
    'user.name',
  );
  return (
    <AdminSurveyContent surveyCycle={surveyCycle} onChange={reloadSurveyCycle}>
      <Column gap={12}>
        <Row>
          <Text>
            There {surveyCycle.participants.length === 1 ? 'is' : 'are'}{' '}
            {surveyCycle.participants.length}{' '}
            {pluralize('participant', surveyCycle.participants.length)}
          </Text>
          <GrowingSpacer />
          {!surveyCycle.endedDate && (
            <Button
              onClick={() => {
                setShowAddParticipants(true);
              }}
            >
              Add participants
            </Button>
          )}
        </Row>
        <Table<ISurveyParticipant>
          columns={columns}
          dataSource={sortedParticipants}
          locale={{
            emptyText: (
              <Empty description="No participants have been added to this survey" />
            ),
          }}
          rowKey="token"
          tableLayout="auto"
          sticky={true}
          pagination={pagination}
        />
      </Column>
      {showAddParticipants && (
        <AddParticipantsModal
          surveyCycleToken={surveyCycle.token}
          onSave={handleAddParticipants}
          onCancel={() => {
            setShowAddParticipants(false);
          }}
        />
      )}
    </AdminSurveyContent>
  );
};
