import { formatDate } from '@shared/formatDate';
import { ITask, UserMap } from '@shared/types';
import { useOutstandingCount } from '@web/common/useOutstandingCount';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DeclineRequestButton } from './DeclineRequestButton';
import { TaskTag } from './TaskTag';
import { getTaskInfo } from './getTaskInfo';

export const RequestListItem: React.FC<{
  task: ITask;
  userMap: UserMap;
  onChange: () => void;
}> = ({ onChange, task, userMap }) => {
  const { reloadOutstandingTaskCount } = useOutstandingCount();
  const [declined, setDeclined] = React.useState(!!task.declinedDate);

  const handleTaskDeclined = () => {
    setDeclined(true);
    void reloadOutstandingTaskCount();
    onChange();
  };

  const info = getTaskInfo(task, userMap);
  if (!info) {
    return null;
  }

  const {
    isCycleRequest,
    user: requestAssociatedUser,
    linkTo: linkToRequest,
    title,
  } = info;

  const completed = !!task.completedDate;

  return (
    <RequestLink to={linkToRequest}>
      <Column style={{ width: '100%' }}>
        <Row gap={12} style={{ alignItems: 'flex-start' }}>
          <UserAvatar user={requestAssociatedUser} size={30} />
          <Column style={{ width: '100%' }}>
            <Row style={{ alignItems: 'flex-start' }}>
              <Row style={{ flexWrap: 'wrap' }}>
                <Header3 style={{ lineHeight: '30px' }}>
                  {requestAssociatedUser.name}
                </Header3>
                <Spacer size={6} />
                <TaskTag
                  task={task}
                  declined={declined}
                  receiver={requestAssociatedUser}
                />
              </Row>
              <GrowingSpacer />
              <RequestDate task={task} />
            </Row>
            <Row gap={12}>
              <Text>{title}</Text>
              <GrowingSpacer />
              {!isCycleRequest && !declined && !completed && (
                <DeclineRequestButton
                  onDecline={handleTaskDeclined}
                  task={task}
                  className="show-on-hover"
                />
              )}
            </Row>
          </Column>
        </Row>
      </Column>
    </RequestLink>
  );
};

const RequestDate: React.FC<{ task: ITask }> = ({ task }) => {
  if (task.completedDate) {
    return (
      <Text style={{ whiteSpace: 'nowrap', lineHeight: '30px' }}>
        {formatDate(task.completedDate)}
      </Text>
    );
  } else if (task.declinedDate) {
    return (
      <Text style={{ whiteSpace: 'nowrap', lineHeight: '30px' }}>
        {formatDate(task.declinedDate)}
      </Text>
    );
  } else if (task.dueDate) {
    return (
      <Text style={{ whiteSpace: 'nowrap', lineHeight: '30px' }}>
        Due {formatDate(task.dueDate)}
      </Text>
    );
  }

  return (
    <Text style={{ whiteSpace: 'nowrap', lineHeight: '30px' }}>
      {formatDate(task.createdDate)}
    </Text>
  );
};

const RequestLink = styled(Link)`
  padding: 24px;
  border: 1px solid #eee;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
  border-radius: var(--default-border-radius);

  h5 {
    margin: 0;
  }

  .hide-on-hover {
    display: block;
  }

  .show-on-hover {
    display: none;
  }

  &:hover {
    background-color: #eaf8ff8a;

    .show-on-hover {
      display: block;
    }

    .hide-on-hover {
      display: none;
    }
  }
`;
