import { UserRole, UserRoleLabels } from '@shared/types';
import { Tag } from 'antd';
import * as React from 'react';

export const UserRoleTag: React.FC<{ role: UserRole }> = ({ role }) => {
  return <Tag color={UserRoleColorMap[role]}>{UserRoleLabels[role]}</Tag>;
};
const UserRoleColorMap: Record<UserRole, string> = {
  [UserRole.SUPER]: 'red',
  [UserRole.ADMIN]: 'pink',
  [UserRole.HR_ADMIN]: 'purple',
  [UserRole.HRBP]: 'purple',
  [UserRole.EMPLOYEE]: 'gray',
  [UserRole.ENROLLED]: 'blue',
  [UserRole.OPERATIONS]: 'red',
  [UserRole.INACTIVE]: 'yellow',
  [UserRole.INVITED]: 'orange',
};
