import { PeerReviewToken } from '@shared/review-cycles';
import { OrganizationToken, UserMapItem, UserToken } from '@shared/types';
import { post } from '@web/common/api';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { Modal, message } from 'antd';
import React, { useState } from 'react';

export const SelectPeersModal: React.FC<{
  onCancel: () => void;
  onSave: () => void;
  organizationToken: OrganizationToken;
  peerReviewToken: PeerReviewToken;
  user?: UserMapItem;
}> = ({ onSave, onCancel, organizationToken, peerReviewToken, user }) => {
  const [selectedPeers, setSelectedPeers] = useState<UserToken[]>([]);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);

    try {
      await post<{ selectedPeers: UserToken[] }>(
        `/peer-reviews/${peerReviewToken}/selectedPeers`,
        { selectedPeers },
      );
      void message.success('Success');
      onSave();
    } catch (error) {
      void message.error(error.displayMessage ?? 'Failed to add user');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleUsersSelected = (userTokens: UserToken[]) => {
    setSelectedPeers(userTokens);
  };

  return (
    <Modal
      title="Select Peers"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Save"
      okButtonProps={{
        disabled: selectedPeers.length === 0,
      }}
    >
      <SelectUsers
        style={{ width: '100%' }}
        organizationToken={organizationToken}
        onChange={handleUsersSelected}
        hideUsers={[user.token]}
      />
    </Modal>
  );
};
