import { Feature } from '@shared/features';
import { FLINT_INTERNAL_USER_ROLES } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { IS_PRODUCTION } from '@web/common/const';
import { useFeature } from '@web/common/useFeature';
import { H } from 'highlight.run';
import * as React from 'react';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { EmojiSelectorProvider } from './EmojiSelectorProvider';
import { ErrorBoundary } from './ErrorBoundary';
import { ForceReload } from './ForceReload';
import { Messages } from './Messages';
import { Page } from './Page';
import { OrganizationFilterProvider } from './organizationFilter';
import { StylesProvider } from './styles/StylesProvider';

export const App: FC = () => {
  const { isLoading, user, organization, isImpersonating } = useAuth();
  const { booleanValue: highlightDisabled } = useFeature(
    Feature.HIGHLIGHT_DISABLED,
  );
  if (isLoading) {
    return null;
  }

  const key = process.env.HIGHLIGHT_KEY;
  if (
    user &&
    key &&
    !highlightDisabled &&
    !FLINT_INTERNAL_USER_ROLES.includes(user.role) &&
    !isImpersonating &&
    IS_PRODUCTION
  ) {
    H.init(key, {
      serviceName: 'Flint',
      tracingOrigins: false,
      enableStrictPrivacy: true,
      networkRecording: false,
      disableConsoleRecording: true,
      enablePerformanceRecording: false,
      environment: 'production',
    });
    H.identify(user.token, {
      organizationToken: user.organizationToken,
      sandbox: organization.sandboxMode,
    });
  }

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <StylesProvider>
          <ForceReload />
          <OrganizationFilterProvider>
            <Messages />
            <EmojiSelectorProvider>
              <Page>
                <AppRoutes />
              </Page>
            </EmojiSelectorProvider>
          </OrganizationFilterProvider>
        </StylesProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
