import { Typography } from 'antd';
import styled from 'styled-components';

export const BlockQuote = styled(Typography.Text)`
  background: var(--hover-background);
  border-left: 3px solid var(--complimentary-color);
  padding: 12px 16px;
  line-height: 24px;
  font-size: 16px;
  width: 100%;
  display: inline-block;
`;
