import { EditOutlined } from '@ant-design/icons';
import {
  IReflectionScheduleEvent,
  UpdateScheduledEventRequest,
  UpdateScheduledEventResponse,
} from '@shared/reflections';
import { post, put } from '@web/common/api';
import { Row } from '@web/components/layout';
import { Button, DatePicker, Form, Input, Modal, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { isAfter, isBefore, isEqual } from 'date-fns';
import dayjs from 'dayjs';
import React, { useState } from 'react';

export interface EditReflectionEventButtonProps {
  event: IReflectionScheduleEvent;
  onChange: () => void;
}

export const EditReflectionEventButton: React.FC<
  EditReflectionEventButtonProps
> = ({ onChange, event }) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const handleSave = () => {
    onChange();
    closeModal();
  };

  return (
    <span>
      <EditOutlined onClick={showModal} />
      <EditReflectionEventModal
        event={event}
        onSave={handleSave}
        onCancel={closeModal}
        onAction={onChange}
        open={open}
      />
    </span>
  );
};

interface EditReflectionEventModalProps {
  onSave: () => void;
  onCancel: () => void;
  onAction: () => void;
  event: IReflectionScheduleEvent;
  open: boolean;
  title?: string;
}

interface EditReflectionEventFields {
  title: string;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  dueDate: dayjs.Dayjs;
  draftDate: dayjs.Dayjs;
  taskDate: dayjs.Dayjs;
}
export const EditReflectionEventModal: React.FC<
  EditReflectionEventModalProps
> = ({
  onSave,
  onCancel,
  onAction,
  event,
  open,
  title = 'Edit Reflection Event',
}) => {
  const [form] = Form.useForm<EditReflectionEventFields>();
  const [isSaving, setIsSaving] = useState(false);

  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);
  const dueDate = Form.useWatch('dueDate', form);
  const draftDate = Form.useWatch('draftDate', form);
  const taskDate = Form.useWatch('taskDate', form);

  const handleSave = async () => {
    setIsSaving(true);
    let fields: EditReflectionEventFields;
    try {
      fields = await form.validateFields();
    } catch (error) {
      setIsSaving(false);
      return;
    }
    try {
      await put<UpdateScheduledEventRequest, UpdateScheduledEventResponse>(
        `/reflection-configuration/event/${event.token}`,
        {
          title: fields.title,
          startDate: fields.startDate.toDate(),
          endDate: fields.endDate.toDate(),
          dueDate: fields.dueDate.toDate(),
          draftDate: fields.draftDate.toDate(),
          taskDate: fields.taskDate.toDate(),
        },
      );
      await onSave();
    } finally {
      setIsSaving(false);
    }
  };

  const sendTasks = async () => {
    setIsSaving(true);
    try {
      await post(`/reflection-configuration/send-tasks/${event.token}`);
      onAction();
      void message.success('Sent tasks');
    } catch (error) {
      void message.error('Failed to send tasks');
    } finally {
      setIsSaving(false);
    }
  };

  const sendDrafts = async () => {
    setIsSaving(true);
    try {
      await post(`/reflection-configuration/send-drafts/${event.token}`);
      onAction();
      void message.success('Sent draft');
    } catch (error) {
      void message.error('Failed to send draft');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      title={title}
      open={open}
      afterClose={onCancel}
      onCancel={onCancel}
      okButtonProps={{
        disabled: isSaving,
        onClick: handleSave,
      }}
    >
      <Form
        form={form}
        disabled={isSaving}
        layout="vertical"
        initialValues={{
          ...event,
          startDate: dayjs(event.startDate),
          endDate: dayjs(event.endDate),
          dueDate: dayjs(event.dueDate),
          draftDate: dayjs(event.draftDate),
          taskDate: dayjs(event.taskDate),
        }}
      >
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item
          label="Start Date"
          name="startDate"
          dependencies={['endDate', 'dueDate']}
          rules={[
            { required: true, message: 'Start date is required' },
            {
              validator: dateIsBefore('start date', [
                { target: () => endDate, label: 'end date' },
                { target: () => dueDate, label: 'due date' },
              ]),
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="endDate"
          dependencies={['dueDate', 'draftDate', 'taskDate', 'startDate']}
          rules={[
            { required: true, message: 'End date is required' },
            {
              validator: dateIsBefore('end date', [
                { target: () => dueDate, label: 'due date' },
              ]),
            },
            {
              validator: dateIsAfter('end date', [
                { target: () => startDate, label: 'start date' },
              ]),
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Due Date"
          name="dueDate"
          dependencies={['startDate', 'endDate']}
          rules={[
            { required: true, message: 'Due date is required' },
            {
              validator: dateIsAfter('due date', [
                { target: () => startDate, label: 'start date' },
                { target: () => startDate, label: 'end date' },
              ]),
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Draft Date"
          name="draftDate"
          dependencies={['dueDate', 'taskDate']}
          rules={[
            { required: true, message: 'Draft date is required' },
            {
              validator: dateIsBefore('draft date', [
                { target: () => dueDate, label: 'due date' },
              ]),
            },
            {
              validator: dateValidator(
                [{ target: () => taskDate, label: 'request date' }],
                (d1, d2) => isBefore(d1, d2) || isEqual(d1, d2),
                (errorListStr) =>
                  `draft date must be before or equal to ${errorListStr}`,
              ),
            },
          ]}
        >
          <ActionableDatePicker
            onClick={
              isBefore(new Date(event.startDate), new Date())
                ? sendDrafts
                : undefined
            }
            disabled={!!event.draftCreatedDate}
          />
        </Form.Item>
        <Form.Item
          label="Request Date"
          name="taskDate"
          dependencies={['dueDate', 'draftDate']}
          rules={[
            { required: true, message: 'Request date is required' },
            {
              validator: dateIsBefore('request date', [
                { target: () => dueDate, label: 'due date' },
              ]),
            },
            {
              validator: dateValidator(
                [{ target: () => draftDate, label: 'draft date' }],
                (d1, d2) => isAfter(d1, d2) || isEqual(d1, d2),
                (errorListStr) =>
                  `request date must be after or equal to ${errorListStr}`,
              ),
            },
          ]}
        >
          <ActionableDatePicker
            onClick={
              isBefore(new Date(event.startDate), new Date())
                ? sendTasks
                : undefined
            }
            disabled={!!event.taskCreatedDate}
            disabledButton={!event.draftCreatedDate}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ActionableDatePicker: React.FC<{
  onChange?: (value: dayjs.Dayjs) => void;
  onClick?: () => void;
  value?: dayjs.Dayjs;
  disabledButton?: boolean;
  disabled?: boolean;
}> = ({ onChange, onClick, value, disabledButton, disabled }) => {
  return (
    <Row gap={12}>
      <DatePicker onChange={onChange} value={value} disabled={disabled} />
      {onClick && (
        <Button onClick={onClick} disabled={disabled || disabledButton}>
          Send Now
        </Button>
      )}
    </Row>
  );
};

type DateValidator = (rule: RuleObject, date: dayjs.Dayjs) => Promise<void>;

const dateIsBefore: (
  fieldName: string,
  beforeList: Array<{ target: () => dayjs.Dayjs; label: string }>,
) => DateValidator = (fieldName, beforeList) => {
  const errorMessage = (errorListStr) =>
    `${fieldName} must be before ${errorListStr}`;

  return dateValidator(beforeList, isBefore, errorMessage);
};

const dateIsAfter: (
  fieldName: string,
  afterList: Array<{ target: () => dayjs.Dayjs; label: string }>,
) => DateValidator = (fieldName, afterList) => {
  const errorMessage = (errorListStr) =>
    `${fieldName} must be after ${errorListStr}`;
  return dateValidator(afterList, isAfter, errorMessage);
};

const dateValidator: (
  comparisons: Array<{
    target: () => dayjs.Dayjs;
    label: string;
  }>,
  validator: (date: Date | number, dateToCompare: Date | number) => boolean,
  errorMessage: (errorListStr: string) => string,
) => DateValidator = (comparisons, validator, errorMessage) => {
  return async (_, date: dayjs.Dayjs) => {
    if (!date) {
      return await Promise.resolve();
    }

    const errors = comparisons.flatMap((comparison) => {
      if (!comparison.target()) {
        return [];
      }
      if (!validator(date.toDate(), comparison.target().toDate())) {
        return [comparison.label];
      }
      return [];
    });

    if (errors.length > 0) {
      const errorListStr =
        errors.length > 2
          ? `${errors.slice(0, errors.length - 1).join(', ')}, and ${
              errors[errors.length - 1]
            }`
          : errors.join(' and ');
      return await Promise.reject(new Error(errorMessage(errorListStr)));
    }
    return await Promise.resolve();
  };
};
