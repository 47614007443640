import { IUser, OrganizationToken, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { IS_PRODUCTION } from '@web/common/const';
import { useApi } from '@web/common/useApi';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Grid, Row } from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { SendNotificationTemplate } from '@web/notifications/SendNotificationTemplate';
import { ButtonProps, Typography, message } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router';

import { UserRoleTag } from '../UserRoleTag';
import { BooleanField } from './BooleanField';
import { DateField } from './DateField';
import { UserNotifications } from './UserNotifications';

const UserActivityPage: React.FC = () => {
  const { impersonate } = useAuth();
  const { userToken, organizationToken } = useParams<{
    userToken: UserToken;
    organizationToken: OrganizationToken;
  }>();
  const { data: viewingUser, mutate: refreshViewingUser } = useApi<IUser>(
    `/users/${userToken}`,
  );

  if (!viewingUser) {
    return null;
  }

  return (
    <PageContent>
      <PageHeader
        title={
          <Row gap={12}>
            <Header1>{viewingUser.name}</Header1>
            <UserRoleTag role={viewingUser.role} />
          </Row>
        }
        description={
          <Row gap={12}>
            <Text>{viewingUser.email}</Text>
          </Row>
        }
        navigateBack
        defaultNavigateBackTo={`/organizations/${organizationToken}/users`}
        primaryAction={{
          label: 'Impersonate',
          confirm: {
            title: `Impersonate ${viewingUser.name}`,
            description: 'Are you sure you want to impersonate this user?',
          },
          onClick: async () => {
            await impersonate(userToken);
          },
        }}
        secondaryActions={[
          {
            label: 'Edit',
            linkTo: `/organizations/${organizationToken}/users/${userToken}`,
          },
        ]}
      />
      <Column gap={24}>
        <Pane>
          <Grid gap={24} columns="2fr 1fr">
            <Column>
              <DateField
                label="Created Date"
                user={viewingUser}
                property="createdDate"
              />
              <DateField
                label="Updated Date"
                user={viewingUser}
                property="updatedDate"
              />
              <DateField
                label="First Login Date"
                user={viewingUser}
                property="firstLoginDate"
                onChange={refreshViewingUser}
              />
              <DateField
                label="First Entry Date"
                user={viewingUser}
                property="firstEntryCreatedDate"
                onChange={refreshViewingUser}
              />
              <BooleanField
                label="Show Journal Entry Tour"
                user={viewingUser}
                property="showSlackJournalEntryTour"
                onChange={refreshViewingUser}
              />
              <BooleanField
                label="Show Suggested Entry Tour"
                user={viewingUser}
                property="showSlackSuggestedEntryTour"
                onChange={refreshViewingUser}
              />
            </Column>
            <Column gap={12}>
              <UserActionButton
                label="Send a creation reminder"
                postUrl={`/internal/notifications/users/${userToken}/sendCreateReminder`}
                userToken={userToken}
              />
              <UserActionButton
                label="Send a suggest entry notification"
                postUrl={`/internal/notifications/users/${userToken}/sendSuggestEntry`}
                userToken={userToken}
              />
              {!IS_PRODUCTION && (
                <UserActionButton
                  label="Send manager inactivity notification"
                  postUrl={`/impacts/notifications/${userToken}/sendInactivityToManager`}
                  userToken={userToken}
                />
              )}
              <UserActionButton
                label="Start peer review cycle"
                postUrl={`/peer-reviews/${userToken}/new`}
                userToken={userToken}
              />
              <UserActionButton
                label="Send bedrock summary of work to user"
                postUrl={`/bedrock-configuration/generate/${organizationToken}/${userToken}/dm`}
                userToken={userToken}
              />
              <UserActionButton
                label="Create bedrock summary of work journal entries"
                postUrl={`/bedrock-configuration/generate/${organizationToken}/${userToken}/journal`}
                userToken={userToken}
              />
            </Column>
          </Grid>
        </Pane>
        <Column>
          <Typography.Title level={5} style={{ fontWeight: 400 }}>
            Send Notification
          </Typography.Title>
          <SendNotificationTemplate user={viewingUser} />
        </Column>
        <UserNotifications user={viewingUser} />
      </Column>
    </PageContent>
  );
};

export default UserActivityPage;

const UserActionButton: React.FC<{
  userToken: UserToken;
  label: string;
  postUrl: string;
  disabled?: boolean;
  type?: ButtonProps['type'];
  onSuccess?: () => void;
}> = ({ label, postUrl, userToken, disabled, type, onSuccess }) => {
  const handleSendCreationReminder = async () => {
    try {
      await post(postUrl);
      void message.success('Sent');
      onSuccess?.();
    } catch (error) {
      void message.error(error.message ?? 'Error');
    }
  };

  return (
    <ConfirmButton
      onConfirm={handleSendCreationReminder}
      title="Confirm"
      description={`Please confirm you wish to "${label.toLowerCase()}" for user ${userToken}.`}
      okText="Continue"
      disabled={disabled}
      type={type}
    >
      {label}
    </ConfirmButton>
  );
};
