import { CheckCircleFilled } from '@ant-design/icons';
import { exhaustiveCheck } from '@shared/exhaustiveCheck';
import { productDetails } from '@shared/reflections';
import {
  IReviewCycle,
  IReviewCycleDashboardRow,
  ShareMode,
} from '@shared/review-cycles';
import { UserRole } from '@shared/types';
import { hasManager, isManagerOf } from '@shared/users';
import { successColor, tertiaryColor } from '@web/app/styles/ColorStyles';
import { useAuth } from '@web/auth/useAuth';
import { put } from '@web/common/api';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Column, Row } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { ApprovePeersButton } from '@web/notifications/ApprovePeersButton';
import { Button, Progress, Tooltip, message } from 'antd';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface CellProps {
  row: IReviewCycleDashboardRow;
  reviewCycle: IReviewCycle;
  onChange?: () => void;
  isAdminView?: boolean;
}

const NoWrapText = styled(Text)`
  white-space: nowrap;
`;

export const PeerSelectionActionCell: React.FC<
  CellProps & { align: React.CSSProperties['justifyContent'] }
> = ({ row, reviewCycle, onChange, align = 'center' }) => {
  if (!reviewCycle.peerReviewCycleStarted) {
    return null;
  }

  const { user } = useAuth();
  const peerSelectionOverdue = hasDatePast(reviewCycle.peerSelectionEndDate);
  const arePeersSelected = row.peersSelected > 0;
  const arePeersApproved = row.peersApproved > 0;
  const canApprovePeers =
    (peerSelectionOverdue || arePeersSelected) &&
    !row.peerRequestsSent &&
    isManagerOf(row.participant, user.token);
  const statusText = arePeersApproved ? (
    <Tooltip title={row.peersSummary}>{row.peersApproved}</Tooltip>
  ) : arePeersSelected ? (
    <Tooltip title={row.peersSummary}>{row.peersSelected}</Tooltip>
  ) : (
    <Text>0</Text>
  );

  const buttonText = arePeersApproved
    ? 'Edit'
    : arePeersSelected
    ? 'Approve'
    : 'Assign';

  return (
    <Row style={{ justifyContent: align }} gap={12}>
      {statusText}
      {canApprovePeers && (
        <ApprovePeersButton
          peerReviewToken={row.peerReviewToken}
          organizationToken={reviewCycle.organizationToken}
          onChange={onChange}
          user={row.participant}
          type={arePeersApproved ? 'default' : 'primary'}
          buttonText={buttonText}
          modalTitle={
            peerSelectionOverdue && !arePeersSelected
              ? 'Assign Peers'
              : 'Approve Peers'
          }
        />
      )}
    </Row>
  );
};

export const AdminPeerSelectionStatusCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
}) => {
  if (!reviewCycle.peerReviewCycleStarted) {
    return null;
  }

  const peersCount = row.peersApproved ?? row.peersSelected ?? 0;
  const countDescription = `${peersCount} ${pluralize('peer', peersCount)}`;

  if (row.peersApproved > 0) {
    return (
      <Tooltip title={`${countDescription} approved: ${row.peersSummary}`}>
        <CheckCircleFilled
          style={{ color: successColor.string(), alignSelf: 'center' }}
        />{' '}
      </Tooltip>
    );
  } else if (row.peersSelected > 0) {
    return (
      <Tooltip title={row.peersSummary}>
        <NoWrapText>
          {row.peersSelected} {pluralize('peer', row.peersSelected)} selected
        </NoWrapText>
      </Tooltip>
    );
  }

  return <NoWrapText>None selected</NoWrapText>;
};

export const AdminReportsApprovedStatusCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
}) => {
  if (!reviewCycle.upwardReviewCycleStarted) {
    return null;
  }

  const reportsCount = row.approvedReports;
  const countDescription = `${reportsCount} ${pluralize(
    'report',
    reportsCount,
  )}`;

  if (row.approvedReports > 0) {
    return (
      <Tooltip title={`${countDescription}: ${row.reportsSummary}`}>
        <CheckCircleFilled
          style={{ color: successColor.string(), alignSelf: 'center' }}
        />{' '}
      </Tooltip>
    );
  } else if (row.approvedReports === 0) {
    return <NoWrapText>None</NoWrapText>;
  }

  return null;
};

export const PeerFeedbackReceivedCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
  isAdminView,
}) => {
  const { user } = useAuth();
  if (!reviewCycle.peerReviewFeedbackRequested || !row.peerRequestsSent) {
    return null;
  }

  const linkViewFeedback = isAdminView && user.role === UserRole.HR_ADMIN;
  return (
    <Column gap={12} style={{ width: '100%', alignItems: 'center' }}>
      <Tooltip title={row.peersFeedbackSummary}>
        {linkViewFeedback ? (
          <Link
            to={`/cycles/${reviewCycle?.token}/users/${row.participantToken}/feedback`}
          >
            <PeerFeedbackProgress
              row={row}
              reviewCycle={reviewCycle}
              isAdminView={isAdminView}
            />
          </Link>
        ) : (
          <PeerFeedbackProgress
            row={row}
            reviewCycle={reviewCycle}
            isAdminView={isAdminView}
          />
        )}
      </Tooltip>
    </Column>
  );
};

export const UpwardFeedbackReceivedCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
  isAdminView,
}) => {
  const { user } = useAuth();
  if (!reviewCycle.upwardReviewFeedbackRequested || !row.upwardRequestsSent) {
    return null;
  }

  const linkViewFeedback = isAdminView && user.role === UserRole.HR_ADMIN;
  return (
    <Column gap={12} style={{ width: '100%', alignItems: 'center' }}>
      <Tooltip title={row.reportsFeedbackSummary}>
        {linkViewFeedback ? (
          <Link
            to={`/cycles/${reviewCycle?.token}/users/${row.participantToken}/feedback`}
          >
            <UpwardFeedbackProgress
              row={row}
              reviewCycle={reviewCycle}
              isAdminView={isAdminView}
            />
          </Link>
        ) : (
          <UpwardFeedbackProgress
            row={row}
            reviewCycle={reviewCycle}
            isAdminView={isAdminView}
          />
        )}
      </Tooltip>
    </Column>
  );
};

export const ReportsFeedbackReceivedCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
  isAdminView,
}) => {
  const { user } = useAuth();
  if (!reviewCycle.upwardReviewFeedbackRequested || !row.upwardRequestsSent) {
    return (
      <AdminReportsApprovedStatusCell
        row={row}
        reviewCycle={reviewCycle}
        isAdminView={isAdminView}
      />
    );
  }

  const linkViewFeedback = isAdminView && user.role === UserRole.HR_ADMIN;
  return (
    <Column gap={12} style={{ width: '100%', alignItems: 'center' }}>
      <Tooltip title={row.reportsFeedbackSummary}>
        {linkViewFeedback ? (
          <Link
            to={`/cycles/${reviewCycle?.token}/users/${row.participantToken}/feedback`}
          >
            <UpwardFeedbackProgress
              row={row}
              reviewCycle={reviewCycle}
              isAdminView={isAdminView}
            />
          </Link>
        ) : (
          <UpwardFeedbackProgress
            row={row}
            reviewCycle={reviewCycle}
            isAdminView={isAdminView}
          />
        )}
      </Tooltip>
    </Column>
  );
};

const PeerFeedbackProgress: React.FC<CellProps> = ({
  row,
  reviewCycle,
  isAdminView,
}) => {
  if (!reviewCycle.peerReviewFeedbackRequested || !row.peerRequestsSent) {
    return null;
  }

  return (
    <Row gap={12}>
      <Progress
        showInfo={false}
        style={{ margin: 0, width: 25 }}
        percent={Math.round(
          (row.peerFeedbackReceived / row.peerRequestsSent) * 100,
        )}
      />
      <Small style={{ whiteSpace: 'nowrap' }}>
        {row.peerFeedbackReceived}/{row.peerRequestsSent}
      </Small>
      {!isAdminView && (
        <Link
          to={`/cycles/${reviewCycle?.token}/users/${row.participantToken}/feedback`}
        >
          <Button size="small">View</Button>
        </Link>
      )}
    </Row>
  );
};

const UpwardFeedbackProgress: React.FC<CellProps> = ({
  row,
  reviewCycle,
  isAdminView,
}) => {
  if (!reviewCycle.upwardReviewFeedbackRequested || !row.upwardRequestsSent) {
    return null;
  }

  return (
    <Row gap={12}>
      <Progress
        showInfo={false}
        style={{ margin: 0, width: 25 }}
        percent={Math.round(
          (row.upwardFeedbackReceived / row.upwardRequestsSent) * 100,
        )}
      />
      <Small style={{ whiteSpace: 'nowrap' }}>
        {row.upwardFeedbackReceived}/{row.upwardRequestsSent}
      </Small>
      {!isAdminView && (
        <Link
          to={`/cycles/${reviewCycle?.token}/users/${row.participantToken}/feedback`}
        >
          <Button size="small">View</Button>
        </Link>
      )}
    </Row>
  );
};

export const PeerFeedbackGivenCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
}) => {
  if (!reviewCycle.peerReviewFeedbackRequested || !row.peerRequestsReceived) {
    return null;
  }

  return (
    <Column gap={12} style={{ width: '100%', alignItems: 'center' }}>
      <Tooltip title={row.peerRequestsSummary}>
        <Row gap={12}>
          <Progress
            showInfo={false}
            style={{ margin: 0, width: 25 }}
            percent={Math.round(
              (row.peerFeedbackGiven / row.peerRequestsReceived) * 100,
            )}
          />
          <Small style={{ whiteSpace: 'nowrap' }}>
            {row.peerFeedbackGiven}/{row.peerRequestsReceived}
          </Small>
        </Row>
      </Tooltip>
    </Column>
  );
};

export const AdminSelfReflectionCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
}) => {
  const { user } = useAuth();

  if (!row.selfReflectionSent) {
    return <Text>Not started</Text>;
  }
  if (!row.selfReflectionCompleted) {
    return <Text>Incomplete</Text>;
  }
  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );

  return (
    <Column gap={12} style={{ justifyContent: 'center' }}>
      {user.role === UserRole.HR_ADMIN ? (
        <Tooltip title={`View self ${selfReviewProduct.lowerCase}`}>
          <Link to={`/reflections/${row.selfReflectionToken}`}>
            <CheckCircleFilled
              style={{ color: successColor.string(), alignSelf: 'center' }}
            />
          </Link>
        </Tooltip>
      ) : (
        <CheckCircleFilled
          style={{ color: successColor.string(), alignSelf: 'center' }}
        />
      )}
    </Column>
  );
};

export const SelfReflectionCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
}) => {
  if (!row.selfReflectionSent) {
    return null;
  }

  let cellContent: JSX.Element;
  if (row.selfReflectionCompleted) {
    cellContent = (
      <Link to={`/reflections/${row.selfReflectionToken}`}>
        <Button size="small">View</Button>
      </Link>
    );
  } else {
    cellContent = <Text>Incomplete</Text>;
  }

  return (
    <Column gap={12} style={{ justifyContent: 'center' }}>
      {cellContent}
    </Column>
  );
};

export const AdminManagerReflectionCell: React.FC<CellProps> = ({
  reviewCycle,
  row,
}) => {
  const { user } = useAuth();

  if (!hasManager(row.participant)) {
    return <Text>Not applicable</Text>;
  }
  if (!row.managerReflectionSent) {
    return <Text>Not started</Text>;
  }
  if (!row.managerReflectionCompleted) {
    return <Text>Incomplete</Text>;
  }
  const managerReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );

  return (
    <Column gap={12} style={{ justifyContent: 'center' }}>
      {user.role === UserRole.HR_ADMIN ? (
        <Tooltip title={`View manager ${managerReviewProduct.lowerCase}`}>
          <Link to={`/reflections/${row.managerReflectionToken}`}>
            <CheckCircleFilled
              style={{ color: successColor.string(), alignSelf: 'center' }}
            />
          </Link>
        </Tooltip>
      ) : (
        <CheckCircleFilled
          style={{ color: successColor.string(), alignSelf: 'center' }}
        />
      )}
    </Column>
  );
};

export const ManagerReflectionCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
}) => {
  const { user } = useAuth();

  if (!hasManager(row.participant)) {
    return <Text>Not applicable</Text>;
  }
  if (!row.managerReflectionSent) {
    return null;
  }

  let cellContent: JSX.Element;
  if (row.managerReflectionCompleted) {
    cellContent = (
      <Link
        to={`/cycles/${reviewCycle.token}/users/${row.participantToken}/reflection`}
      >
        <Button size="small">View</Button>
      </Link>
    );
  } else {
    if (
      !reviewCycle.closedDate &&
      user.token === row.managerReflectionAuthorToken
    ) {
      cellContent = (
        <Link
          to={`/cycles/${reviewCycle.token}/users/${row.participantToken}/reflection/edit`}
        >
          <Button size="small" type="primary">
            Complete
          </Button>
        </Link>
      );
    } else {
      cellContent = <Text>Incomplete</Text>;
    }
  }

  return (
    <Column gap={12} style={{ justifyContent: 'center' }}>
      {cellContent}
    </Column>
  );
};

export const ReviewsSharedCell: React.FC<CellProps> = ({
  row,
  reviewCycle,
  onChange,
  isAdminView,
}) => {
  const { confirm, contextHolder } = useModalConfirm();
  const { user } = useAuth();

  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );
  const handleReleaseFeedback = async () => {
    const sharedContentDescription = [
      reviewCycle.managerReflectionCycleEnabled
        ? `your ${managerReviewProduct.lowerCase}`
        : null,
      reviewCycle.peerReviewCycleEnabled &&
      reviewCycle.peerReviewShareMode === ShareMode.SHARED
        ? 'peer feedback'
        : null,
    ]
      .filter((c) => !!c)
      .join(' and ');
    if (
      !(await confirm(
        `Are you sure you want to share ${sharedContentDescription} with ${row.participant.name}?`,
      ))
    ) {
      return;
    }

    try {
      await put(
        `/review-cycles/${reviewCycle.token}/participants/${row.participant.token}/release-feedback`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const unsharedContent = [
    !row.managerReviewReleased ? 'manager review' : undefined,
    reviewCycle.peerReviewCycleEnabled &&
    reviewCycle.peerReviewShareMode === ShareMode.SHARED &&
    !row.feedbackReleased
      ? 'peer feedback'
      : undefined,
  ]
    .filter((str) => !!str)
    .join(' and ');
  const unsharedContentStr = capitalize(unsharedContent);

  const isContentReady = reviewCycle.managerReflectionCycleEnabled
    ? row.managerReflectionCompleted
    : row.peerFeedbackReceived;
  if (isContentReady && !isAdminView && row.releasedContent !== 'all') {
    return isManagerOf(row.participant, user.token) ? (
      <Column gap={12}>
        <Button onClick={handleReleaseFeedback} size="small" type="primary">
          Share
        </Button>
        {contextHolder}
      </Column>
    ) : (
      <Tooltip
        title={`${unsharedContentStr} is not yet visible to ${row.participant.name}`}
      >
        No
      </Tooltip>
    );
  }

  switch (row.releasedContent) {
    case 'all': {
      const sharedContent = [
        row.managerReviewReleased ? 'manager review' : undefined,
        row.feedbackReleased &&
        reviewCycle.peerReviewShareMode === ShareMode.SHARED
          ? 'peer feedback'
          : undefined,
      ]
        .filter((str) => !!str)
        .join(' and ');
      return (
        <Tooltip
          title={`${capitalize(sharedContent)} is visible to ${
            row.participant.name
          }`}
        >
          <CheckCircleFilled
            style={{
              color: successColor.string(),
              alignSelf: 'center',
            }}
          />
        </Tooltip>
      );
    }
    case 'partial': {
      return (
        <Tooltip
          title={`${unsharedContentStr} is not yet visible to ${row.participant.name}`}
        >
          <CheckCircleFilled
            style={{
              color: tertiaryColor.string(),
              alignSelf: 'center',
            }}
          />
          *
        </Tooltip>
      );
    }
    case 'none':
      return (
        <Tooltip
          title={`${unsharedContentStr} is not yet visible to ${row.participant.name}`}
        >
          No
        </Tooltip>
      );
    default:
      exhaustiveCheck(row.releasedContent);
  }
};

function hasDatePast(date: Date | string) {
  return new Date(date).getTime() < new Date().getTime();
}
