import { PlusOutlined } from '@ant-design/icons';
import { IReviewCycle } from '@shared/review-cycles';
import { useApi } from '@web/common/useApi';
import { GrowingSpacer, Row } from '@web/components/layout';
import { Button } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminPageContent } from '../admin/AdminPageContent';
import { AddCycleModal } from './AddCycleModal';
import { ReviewCyclesTable } from './ReviewCyclesTable';

const AdminReviewCyclesPage: React.FC = () => {
  const { data: reviewCycles } = useApi<IReviewCycle[]>('/review-cycles');
  const navigate = useNavigate();
  const [showAdd, setShowAdd] = React.useState(false);

  const handleReviewCycleClick = (reviewCycle: IReviewCycle) => {
    navigate(`/admin/review-cycles/${reviewCycle.token}/dashboard`);
  };
  const handleCycleAdded = (cycle: IReviewCycle) => {
    void navigate(`${cycle.token}/settings`);
  };

  return (
    <AdminPageContent>
      <Row gap={6}>
        <GrowingSpacer />
        <Button
          type="primary"
          style={{ maxWidth: 200 }}
          onClick={() => {
            setShowAdd(true);
          }}
        >
          <PlusOutlined /> Add cycle
        </Button>
      </Row>
      <ReviewCyclesTable
        reviewCycles={reviewCycles}
        onReviewCycleClick={handleReviewCycleClick}
      />
      {showAdd && (
        <AddCycleModal
          onAdd={handleCycleAdded}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      )}
    </AdminPageContent>
  );
};

export default AdminReviewCyclesPage;
