import styled from 'styled-components';

export const BorderedPane = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 24px;
  border: 1px solid #ddd;
  border-radius: var(--default-border-radius);
`;
