import { QuestionType } from '@shared/QuestionType';
import { QuestionToken } from '@shared/questions';
import {
  ISurveyCycle,
  ISurveyParticipant,
  sortedSurveyQuestions,
} from '@shared/surveys';
import { post } from '@web/common/api';
import { Column, Row } from '@web/components/layout';
import { InputQuestions } from '@web/questions/InputQuestions';
import { useQuestionResponseMap } from '@web/questions/useQuestionResponseMap';
import { Button, message } from 'antd';
import React from 'react';

import { SurveySections } from './SurveySections';

interface ITakeSurveyProps {
  surveyCycle: ISurveyCycle;
  participant: ISurveyParticipant;
  onComplete: () => void;
}
export const TakeSurvey: React.FC<ITakeSurveyProps> = ({
  surveyCycle,
  participant,
  onComplete,
}) => {
  const [requiredErrors, setRequiredErrors] = React.useState<
    Record<QuestionToken, string>
  >({});
  const { responseMap, handleQuestionResponse, validResponses } =
    useQuestionResponseMap(surveyCycle.questions, participant.responses);

  const handleSubmit = async () => {
    const requiredErrors: Record<QuestionToken, string> = {};
    for (const question of surveyCycle.questions) {
      if (question.required) {
        const response = responseMap.get(question.token);
        if (
          !response ||
          (question.type === QuestionType.RATING && !response?.rating) ||
          (question.type === QuestionType.OPEN_ENDED && !response?.text)
        ) {
          requiredErrors[question.token] = 'Response is required';
        }
      }
    }
    if (Object.values(requiredErrors).length > 0) {
      setRequiredErrors(requiredErrors);
      return;
    }

    try {
      await post(`/survey-cycles/${surveyCycle.token}/responses`, {
        responses: validResponses,
      });
      void message.success('Success');
      onComplete();
    } catch (error) {
      void message.error('Error');
    }
  };

  if (participant.submittedDate) {
    return (
      <InputQuestions
        sortedQuestions={sortedSurveyQuestions(surveyCycle)}
        responseMap={responseMap}
        onResponseChanged={handleQuestionResponse}
        errors={requiredErrors}
      />
    );
  }

  return (
    <Column gap={24}>
      <SurveySections
        surveyCycle={surveyCycle}
        responseMap={responseMap}
        onResponseChanged={handleQuestionResponse}
        errors={requiredErrors}
      />
      <Row gap={12}>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={validResponses.length === 0}
        >
          Submit
        </Button>
      </Row>
    </Column>
  );
};
