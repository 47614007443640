import { GithubOutlined } from '@ant-design/icons';
import { Logo } from '@web/app/Logo/Logo';
import { useResponsive } from '@web/app/responsive';
import { Center, Row, Spacer } from '@web/components/layout';
import { Typography } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const InstallGithubSuccessPage: React.FC = () => {
  const { isMobile } = useResponsive();

  return (
    <PageContainer>
      <Spacer size={!isMobile ? 100 : 0} />
      <Center>
        <PagePane>
          <Row style={{ alignItems: 'center' }}>
            <Logo />
            <Spacer size={20} />
            <Typography.Title level={3} style={{ margin: 0 }}>
              +
            </Typography.Title>
            <Spacer size={20} />
            <GithubOutlined style={{ fontSize: '28px' }} />
          </Row>
          <Spacer size={20} />
          <Typography.Title level={5}>Success!</Typography.Title>
          <Typography.Text>
            Flint has been sucessfully connected to your Github organization.
          </Typography.Text>
        </PagePane>
      </Center>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  background: white;
  height: 100vh;
`;

const PagePane = styled.section`
  width: 700px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-btn {
    width: 250px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    h5 {
      margin: 0;
    }
  }
`;
