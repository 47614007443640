import { GetImpactFeedbackResponse, IImpact } from '@shared/impacts';
import { IQuestionResponse } from '@shared/question_response';
import { IFeedback, ITask } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { useAuth } from '@web/auth/useAuth';
import { get, put } from '@web/common/api';
import { useOutstandingCount } from '@web/common/useOutstandingCount';
import { Column } from '@web/components/layout';
import { Button, Divider, Skeleton, message } from 'antd';
import React from 'react';

import { JournalEntryFeedback } from '../JournalEntryFeedback';
import { EntryFeedbackFormFields } from './EntryFeedbackFormFields';

interface IImpactGiveFeedbackRequest {
  responses: IQuestionResponse[];
}

interface Props {
  impactEntry: IImpact;
  assignedTask?: ITask;
  onDecline: () => void;
}
export const JournalEntryFeedbackForm: React.FC<Props> = ({
  impactEntry,
  assignedTask,
  onDecline,
}) => {
  const { user } = useAuth();
  const [feedback, setFeedback] = React.useState<IFeedback | null>(null);
  const isManager = isManagerOf(impactEntry.user, user.token);
  const canGiveFeedback = assignedTask || isManager;
  const isAssignedTaskOpen =
    assignedTask && !assignedTask?.completedDate && !assignedTask?.declinedDate;
  const [initialFetchComplete, setInitialFetchComplete] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(isAssignedTaskOpen);

  const { reloadOutstandingTaskCount } = useOutstandingCount();

  const fetchFeedback = async () => {
    const response = await get<GetImpactFeedbackResponse>(
      `/impacts/${impactEntry.token}/feedback`,
    );
    setFeedback(response.feedback);
    setInitialFetchComplete(true);
  };

  React.useEffect(() => {
    if (canGiveFeedback) {
      void fetchFeedback();
    }
  }, []);

  if (!canGiveFeedback) {
    return null;
  } else if (!initialFetchComplete) {
    return <Skeleton />;
  }

  const handleSave = async (responses: IQuestionResponse[]) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      if (responses.length > 0) {
        const updatedFeedback = await put<
          IImpactGiveFeedbackRequest,
          IFeedback
        >(`/impacts/${impactEntry.token}/feedback`, {
          responses,
        });
        setFeedback(updatedFeedback);
        void message.success('Success');
        void reloadOutstandingTaskCount();
        setIsEditing(false);
        void fetchFeedback();
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDecline = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      await put(`/tasks/${assignedTask?.token}/decline`);
      void message.success('Success');
      void reloadOutstandingTaskCount();
      setIsEditing(false);
      onDecline();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  if (isEditing) {
    return (
      <Column style={{ position: 'relative' }}>
        <Divider style={{ margin: '24px 0' }} />
        <EntryFeedbackFormFields
          assignedTaskToken={assignedTask?.token}
          disabled={isSaving}
          feedback={feedback}
          impactEntry={impactEntry}
          showDecline={isAssignedTaskOpen}
          onCancel={() => setIsEditing(false)}
          onDecline={handleDecline}
          onSave={(feedback) => {
            void handleSave(feedback);
          }}
        />
      </Column>
    );
  }

  return (
    <Column style={{ position: 'relative' }}>
      <Divider style={{ margin: '24px 0' }} />
      {feedback && feedback.responses.length > 0 ? (
        <JournalEntryFeedback
          responses={feedback.responses}
          giver={user}
          receiver={impactEntry.user}
          onEdit={() => setIsEditing(true)}
        />
      ) : (
        <Button
          style={{ alignSelf: 'flex-start' }}
          type="primary"
          onClick={() => setIsEditing(true)}
        >
          Give Feedback
        </Button>
      )}
    </Column>
  );
};
