import { Row } from '@web/components/layout';
import { TypographyCss } from '@web/components/typography';
import { Button } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { ReactionsSummary } from '../reactions/ReactionSummary';

export const ActionBar: React.FC<{
  entityToken: string;
  onEdit?: () => void;
  onReply?: () => void;
  canReact?: boolean;
}> = ({ entityToken, onEdit, onReply, canReact = true }) => {
  return (
    <ReactionsSummary entityToken={entityToken} readonly={!canReact}>
      {(onEdit || onReply) && (
        <Row gap={6} style={{ margin: '0 8px 8px 0' }}>
          {onEdit && <ActionBarButton onClick={onEdit}>Edit</ActionBarButton>}
          {onReply && (
            <ActionBarButton onClick={onReply}>Reply</ActionBarButton>
          )}
        </Row>
      )}
    </ReactionsSummary>
  );
};
export const ActionBarButton = styled(Button).attrs({
  type: 'link',
  size: 'small',
})`
  &.ant-btn-link {
    ${TypographyCss.Text}
    height: 28px;
  }
  &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    background: #f0f0f0;
  }
`;
