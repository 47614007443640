import { useAuth } from '@web/auth/useAuth';
import { useModalConfirm } from '@web/common/useModalConfirm';
import * as React from 'react';

export const ForceReload: React.FC = () => {
  const { contextHolder, confirm } = useModalConfirm();
  const { needsRefresh } = useAuth();
  React.useEffect(() => {
    if (needsRefresh) {
      void refresh();
    }
  }, [needsRefresh]);
  const refresh = async () => {
    const confirmRefresh = await confirm(
      'Flint has updates that are needed to run correctly. Please refresh your browser.',
      { title: 'Refresh Required', okText: 'Refresh' },
    );
    if (confirmRefresh) {
      window.location.reload();
    }
  };

  return contextHolder;
};
