import { UserOutlined } from '@ant-design/icons';
import { Row } from '@web/components/layout';
import { TypographyCss } from '@web/components/typography';
import { Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const PersonalGoalBadge: React.FC<{ text?: string }> = ({
  text = 'Personal Goal',
}) => {
  return (
    <Tooltip
      title={`This goal is visible to you and your manager. Personal goals cannot have a parent goal or supporting goals, and can't
        be added to lists.`}
    >
      <BadgeContainer>
        <BadgeIcon /> <span>{text}</span>
      </BadgeContainer>
    </Tooltip>
  );
};

const BadgeIcon = styled(UserOutlined)`
  position: relative;
  top: -1px;
`;

const BadgeContainer = styled(Row)`
  ${TypographyCss.Text}
  padding: 0 12px;
  background: #f5f5f5;
  border-radius: 12px;
  height: 24px;
  gap: 3px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  align-self: flex-start;
`;
