import { useUserDetails } from '@client/UsersClient';
import { QuestionToken, feedbackRequestQuestions } from '@shared/questions';
import { FeedbackVisibility, IFeedbackRequest, UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { FormButtons } from '@web/components/FormButtons';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import {
  GrowingSpacer,
  Max,
  ResponsiveRow,
  Spacer,
} from '@web/components/layout';
import { SelectUser } from '@web/components/users/SelectUser';
import { Button, Form, Modal, Switch, Typography, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface RequestFeedbackFields {
  giverToken: UserToken;
  receiverToken?: UserToken;
  text: string;
  shareWithManager: boolean;
}

const CUSTOM_QUESTION_TOKEN: QuestionToken = 'q_custom';

export const RequestFeedbackPage: React.FC = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const [form] = Form.useForm<RequestFeedbackFields>();
  const [isSaving, setIsSaving] = React.useState(false);
  const { userToken } = useParams<{ userToken: UserToken }>();
  const { data: receiver } = userToken
    ? useUserDetails(userToken)
    : { data: user };

  const handleSubmit = async () => {
    setIsSaving(true);
    let fields: RequestFeedbackFields;
    try {
      fields = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      let visibility = fields.shareWithManager
        ? FeedbackVisibility.RECEIVER_MANAGER
        : FeedbackVisibility.RECEIVER_ONLY;
      if (receiver && receiver.token !== user.token) {
        if (!isManagerOf(receiver, user.token)) {
          void message.error(
            'Only managers can request feedback for individuals on their team',
          );
          return;
        }

        visibility = FeedbackVisibility.MANAGER_ONLY;
      }

      await post<IFeedbackRequest>('/feedback/request', {
        receiverToken: receiver?.token,
        giverToken: fields.giverToken,
        text: fields.text,
        visibility,
      });
      navigateBack();
      void message.success('Success');
    } catch (error) {
      form.resetFields();
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleQuestionSelected = (token: QuestionToken) => {
    if (token === CUSTOM_QUESTION_TOKEN) {
      form.setFieldsValue({ text: '' });
    } else {
      const question = feedbackRequestQuestions.find((q) => q.token === token);
      if (question) {
        form.setFieldsValue({ text: question.text });
      }
    }
  };

  const isReceiver = receiver && receiver.token === user.token;
  const title =
    receiver && receiver.token !== user.token
      ? `Request feedback for ${receiver?.name}`
      : 'Request feedback';
  return (
    <PageContent>
      <PageHeader
        title={title}
        mobileTitle={title}
        navigateBack
        defaultNavigateBackTo="/feedback"
      />
      <Pane>
        <Max width="800px">
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            layout="vertical"
            initialValues={{ shareWithManager: true }}
          >
            <Form.Item
              label="Who would you like feedback from?"
              name="giverToken"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <SelectUser disabled={isSaving} omitUserTokens={[user.token]} />
            </Form.Item>
            <ResponsiveRow style={{ alignItems: 'flex-start' }}>
              <Typography.Text>
                What would you like feedback about?
              </Typography.Text>
              {!isMobile && <GrowingSpacer />}
              <SelectFeedbackRequestQuestionModal
                onOk={handleQuestionSelected}
              />
            </ResponsiveRow>
            <Form.Item
              name="text"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <TextArea style={{ height: '100px' }} disabled={isSaving} />
            </Form.Item>
            {isReceiver && (
              <Form.Item
                name="shareWithManager"
                valuePropName="checked"
                label={'Share feedback with your manager'}
              >
                <Switch disabled={isSaving} />
              </Form.Item>
            )}
            <FormButtons>
              <Button
                type="primary"
                disabled={isSaving}
                onClick={() => {
                  void handleSubmit();
                }}
              >
                Submit request
              </Button>
              <Button
                disabled={isSaving}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </FormButtons>
          </Form>
        </Max>
      </Pane>
    </PageContent>
  );
};

const SelectFeedbackRequestQuestionModal: React.FC<{
  onOk: (token: QuestionToken) => void;
}> = ({ onOk }) => {
  const [open, setOpen] = React.useState(false);
  const { isMobile } = useResponsive();

  const handleQuestionSelected = (questionToken: QuestionToken) => {
    onOk(questionToken);
    setOpen(false);
  };

  return (
    <>
      <a
        onClick={() => {
          setOpen(true);
        }}
      >
        Use an existing question
      </a>
      <Spacer size={8} />
      <Modal
        title="Select a feedback question"
        open={open}
        onCancel={() => setOpen(false)}
        width="500px"
        footer={!isMobile ? <Spacer size={20} /> : null}
      >
        <div
          style={{
            maxHeight: !isMobile ? '500px' : 'calc(100vh - 80px)',
            overflowY: 'auto',
          }}
        >
          {feedbackRequestQuestions.map((question) => (
            <QuestionButton
              key={question.token}
              onClick={() => {
                handleQuestionSelected(question.token);
              }}
              style={{ whiteSpace: 'normal' }}
            >
              {question.text}
            </QuestionButton>
          ))}
        </div>
      </Modal>
    </>
  );
};

const QuestionButton = styled.div`
  display: flex;
  cursor: pointer;
  padding: 12px;
  font-size: 14px;
  line-height: 18px;
  align-items: center;

  &:hover {
    background: #eee;
  }

  &:last-child {
    border-bottom: none;
  }
`;
