import { useEntityComments } from '@client/CommentsClient';
import { addEntityNote } from '@client/OneOnOnesClient';
import useSize from '@react-hook/size';
import { IComment } from '@shared/comments';
import {
  ActionItemToken,
  AgendaItemToken,
  OneOnOneToken,
} from '@shared/one-on-one';
import { UserMapItem, UserToken } from '@shared/types';
import { IWebSocketEvent, WebSocketEventType } from '@shared/webSocketEvents';
import { InputNote } from '@web/1on1s/notes/InputNote';
import { NotesScroller } from '@web/1on1s/notes/NotesScroller';
import { useAuth } from '@web/auth/useAuth';
import { useWindowSize } from '@web/common/useWindowSize';
import { Column } from '@web/components/layout';
import { Skeleton, message } from 'antd';
import * as React from 'react';

import {
  publishTypingEvent,
  useWebSocketEvent,
} from '../../common/useWebSocketEvent';
import { LastTyped } from './LastTyped';

export const NotesList: React.FC<{
  oneOnOneToken: OneOnOneToken;
  entityToken: AgendaItemToken | ActionItemToken;
  otherUser: UserMapItem;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ oneOnOneToken, entityToken, children, otherUser }) => {
  const { user } = useAuth();
  const [otherUserLastTyped, setOtherUserLastTyped] = React.useState<number>(
    Date.now() - 10000,
  );
  const inputContainerRef = React.useRef<HTMLDivElement>(null);
  const [, inputContainerHeight] = useSize(inputContainerRef.current, {
    initialHeight: DEFAULT_INPUT_HEIGHT,
    initialWidth: 0,
  });
  const [notes, setNotes] = React.useState<IComment[]>(null);
  const { data: searchResults } = useEntityComments(entityToken);
  const { height: windowHeight } = useWindowSize();
  React.useEffect(() => {
    if (searchResults) {
      setNotes(searchResults.results);
    }
  }, [searchResults]);
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.ENTITY_NOTE_ADDED,
    (event: IWebSocketEvent<IComment>) => {
      if (entityToken !== event.payload.entityToken) {
        return;
      }

      appendNote(event.payload);
    },
  );
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.ENTITY_NOTE_UPDATED,
    (event: IWebSocketEvent<IComment>) => {
      if (entityToken !== event.payload.entityToken) {
        return;
      }

      updateNote(event.payload);
    },
  );
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.ENTITY_NOTE_DELETED,
    (
      event: IWebSocketEvent<{
        entityToken: AgendaItemToken | ActionItemToken;
        deletedNoteToken: string;
      }>,
    ) => {
      deleteNote(event.payload.deletedNoteToken);
    },
  );
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.TYPING,
    (
      event: IWebSocketEvent<{
        userToken: UserToken;
        itemToken: AgendaItemToken | ActionItemToken;
      }>,
    ) => {
      if (
        event.payload.itemToken === entityToken &&
        user.token !== event.payload.userToken
      ) {
        setOtherUserLastTyped(Date.now());
      }
    },
  );
  const updateNote = (note: IComment) => {
    setNotes((notes) => {
      const existingIndex = notes.findIndex((n) => n.token === note.token);
      return [
        ...notes.slice(0, existingIndex),
        note,
        ...notes.slice(existingIndex + 1),
      ];
    });
  };
  const appendNote = (note: IComment) => {
    setNotes((notes) => {
      const exists = notes.some((n) => n.token === note.token);
      return exists ? notes : [...notes, note];
    });
  };
  const deleteNote = (token: string) => {
    setNotes((notes) => {
      return notes.filter((n) => n.token !== token);
    });
  };

  if (!notes) {
    return <Skeleton />;
  }

  const handleNoteSubmit = async (html: string) => {
    try {
      const note = await addEntityNote(oneOnOneToken, entityToken, html);
      appendNote(note);
    } catch (error) {
      void message.error('Error saving note');
    }
  };
  const handleTyping = (html: string) => {
    if (html === '<p></p>') {
      return;
    }

    publishTypingEvent(oneOnOneToken, entityToken, user.token);
  };

  return (
    <Column gap={INTERNAL_PADDING}>
      <NotesScroller
        notes={notes}
        maxHeight={
          windowHeight -
          DRAWER_HEADER_HEIGHT -
          DRAWER_BODY_PADDING -
          INTERNAL_PADDING -
          inputContainerHeight
        }
        oneOnOneToken={oneOnOneToken}
        entityToken={entityToken}
      >
        {children}
      </NotesScroller>
      <InputNote
        onSubmit={handleNoteSubmit}
        placeholder="Jot down some notes"
        ref={inputContainerRef}
        onTextChange={handleTyping}
      />
      <LastTyped otherUser={otherUser} lastTyped={otherUserLastTyped} />
    </Column>
  );
};
const DRAWER_HEADER_HEIGHT = 70;
const DRAWER_BODY_PADDING = 24;
const INTERNAL_PADDING = 24;
const DEFAULT_INPUT_HEIGHT = 62;
