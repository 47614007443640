import { UserFeedbackResponse } from '@shared/feedback';
import { IQuestionSet, sortQuestions } from '@shared/questionSets';
import { IQuestionResponse } from '@shared/question_response';
import { IQuestion, QuestionToken } from '@shared/questions';
import { IReviewCycle, ShareMode } from '@shared/review-cycles';
import { IDraftResponse, UserMapItem } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import { FormButtons } from '@web/components/FormButtons';
import { UserMessage } from '@web/components/UserMessage';
import { Column, ResponsiveRow, Spacer } from '@web/components/layout';
import { InputQuestions } from '@web/questions/InputQuestions';
import { useQuestionResponseMap } from '@web/questions/useQuestionResponseMap';
import { AutoSaveLabel, AutoSaveState } from '@web/reflections/AutoSaveState';
import { Button, Typography, message } from 'antd';
import { debounce, pick } from 'lodash';
import React, { useState } from 'react';

import { runTemplate } from './templatedFeedback';

interface FeedbackRequestFormProps {
  questionSet: IQuestionSet;
  responses: IDraftResponse[];
  parameters: Record<string, string>;
  receiver?: UserMapItem;
  disabled: boolean;
  isReviewCycleRequest?: boolean;
  isUpwardFeedback?: boolean;
  reviewCycle?: IReviewCycle;
  onSubmit: (submittedResponses: UserFeedbackResponse[]) => void;
  onCancel: () => void;
  onAutosave?: (
    questionToken: QuestionToken,
    response: Pick<IQuestionResponse, 'text' | 'rating'>,
  ) => Promise<void>;
}

export interface RequestedFeedbackFields {
  feedbackResponses: Record<string, string | undefined>;
}

export const GiveRequestedFeedbackForm: React.FC<FeedbackRequestFormProps> = ({
  responses,
  disabled,
  questionSet,
  parameters,
  onSubmit,
  onCancel,
  onAutosave,
  receiver,
  isReviewCycleRequest = false,
  isUpwardFeedback = false,
  reviewCycle,
}: FeedbackRequestFormProps) => {
  const { isMobile } = useResponsive();
  const { responseMap, handleQuestionResponse } = useQuestionResponseMap(
    questionSet.questions,
    responses as any,
  );
  const [autoSaveState, setAutoSaveState] = useState<AutoSaveState>(null);
  const [lastAutoSave, setLastAutoSave] = useState<Date | null>(null);

  const handleSubmit = async () => {
    const responses = Array.from(responseMap.values());
    if (responses.length === 0) {
      void message.error('At least one response is required');
      return;
    }
    onSubmit(responses as any);
  };
  const handleAutosave = debounce(async (response: IQuestionResponse) => {
    if (!onAutosave) {
      return;
    }

    try {
      setAutoSaveState('loading');
      await onAutosave(
        response.questionToken as any,
        pick(response, ['text', 'rating']),
      );
      setAutoSaveState('success');
      setLastAutoSave(new Date());
    } catch (error) {
      setAutoSaveState('error');
    }
  }, 500);

  const handleResponseChanged = (
    question: IQuestion,
    response: IQuestionResponse,
  ) => {
    handleQuestionResponse(question, response);
    void handleAutosave(response);
  };

  const sortedQuestions = sortQuestions(questionSet);
  return (
    <Column>
      {receiver && (
        <UserMessage user={receiver} large style={{ marginBottom: 24 }} />
      )}
      {!isReviewCycleRequest && (
        <>
          <FeedbackHeader parameters={parameters} />
          <Spacer />
        </>
      )}
      <Spacer />
      <InputQuestions
        sortedQuestions={sortedQuestions}
        responseMap={responseMap}
        onResponseChanged={handleResponseChanged}
        receiver={receiver}
      />
      <Spacer />
      <Typography.Text style={{ fontStyle: 'italic' }}>
        {isReviewCycleRequest
          ? isUpwardFeedback
            ? runTemplate(
                'Note: Responses will not be directly shared with {receiver}, but will be visible to their manager.',
                parameters,
              )
            : reviewCycle?.peerReviewShareMode === ShareMode.SHARED
            ? runTemplate(
                'Note: Your feedback will be visible to {receiver} and their manager',
                parameters,
              )
            : runTemplate(
                'Note: Responses will not be directly shared with {receiver}, but will be visible to their manager.',
                parameters,
              )
          : runTemplate(
              "Note: Your feedback will only be visible to {receiver}'s manager",
              parameters,
            )}
      </Typography.Text>
      <Spacer size={12} />
      <ResponsiveRow gap={12}>
        <FormButtons
          style={{ width: isMobile ? '100%' : undefined, marginTop: 0 }}
        >
          <Button disabled={disabled} type="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button disabled={disabled} onClick={onCancel}>
            Cancel
          </Button>
        </FormButtons>
        {lastAutoSave && (
          <AutoSaveLabel saveDate={lastAutoSave} lastState={autoSaveState} />
        )}
      </ResponsiveRow>
    </Column>
  );
};

const FeedbackHeader: React.FC<{
  parameters: Record<string, string>;
}> = ({ parameters }) => {
  return (
    <Typography.Text>
      {runTemplate(
        '{requester} has requested your feedback on {receiver}.',
        parameters,
      )}
    </Typography.Text>
  );
};
