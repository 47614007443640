import { formatDate } from '@shared/formatDate';
import { INotificationTemplate } from '@shared/notitifications';
import { PageContent } from '@web/app/Page';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Table, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NotificationTemplatesPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: notificationTemplates, error } = useApi<
    INotificationTemplate[],
    ServerResponseError
  >(`/notifications/templates`);

  if (error) {
    void message.error('Failed to load templates');
    return (
      <ErrorPageContent
        statusCode={error.statusCode}
        subTitle={error.displayMessage}
      />
    );
  }

  return (
    <PageContent>
      <PageHeader
        title="Notification Templates"
        primaryAction={{
          label: 'New Template',
          linkTo: 'new',
        }}
        navigateBack
      />
      <Table
        dataSource={notificationTemplates}
        columns={columns}
        onRow={(template) => ({
          onClick: () => {
            navigate(template.token);
          },
        })}
        rowKey="token"
      />
    </PageContent>
  );
};

export default NotificationTemplatesPage;

const columns: ColumnsType<INotificationTemplate> = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Blocks',
    dataIndex: 'blocks',
    key: 'blocks',
    render: (_, template) => {
      return (
        <Tooltip
          overlayInnerStyle={{
            width: 500,
            maxHeight: 500,
            overflowY: 'auto',
          }}
          title={<NotificationContent>{template.blocks}</NotificationContent>}
        >
          View blocks
        </Tooltip>
      );
    },
  },

  {
    title: 'Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (createdDate) => formatDate(createdDate),
  },
];

const NotificationContent = styled.div`
  white-space: pre-wrap;
  font-family: monospace;
`;
