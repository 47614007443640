import { PlayCircleOutlined } from '@ant-design/icons';
import { RunBedrockRequest } from '@shared/bedrock';
import { IUser } from '@shared/types';
import { Column } from '@web/components/layout';
import { Button, message } from 'antd';
import * as React from 'react';

import { SetupRunModal } from './SetupRunModal';

export const SetupRunButton: React.FC<{
  userOptions: IUser[];
  onRun: (data: RunBedrockRequest) => Promise<void>;
}> = ({ userOptions, onRun }) => {
  const [showSetupRunModal, setShowSetupRunModal] = React.useState(false);

  const handleRun = async (data: RunBedrockRequest) => {
    try {
      await onRun(data);
      setShowSetupRunModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column>
      <Button
        type="primary"
        onClick={() => {
          setShowSetupRunModal(true);
        }}
      >
        <PlayCircleOutlined /> Run
      </Button>
      <SetupRunModal
        open={showSetupRunModal}
        onCancel={() => {
          setShowSetupRunModal(false);
        }}
        onSubmit={handleRun}
        userOptions={userOptions}
      />
    </Column>
  );
};
