import { assignManager } from '@client/TeamClient';
import { Feature } from '@shared/features';
import {
  IUser,
  OrganizationToken,
  UserMapItem,
  UserToken,
} from '@shared/types';
import { useFeature } from '@web/common/useFeature';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUser } from '@web/components/users/SelectUser';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { Modal, message } from 'antd';
import * as React from 'react';
import { useState } from 'react';

export const SelectManagerModal: React.FC<{
  organizationToken: OrganizationToken;
  user: IUser;
  managers: UserMapItem[];
  omitUserTokens: UserToken[];
  onCancel: () => void;
  onSave: () => void;
}> = ({
  organizationToken,
  user,
  managers,
  omitUserTokens,
  onCancel,
  onSave,
}) => {
  const initialManagerTokens =
    managers && managers.length > 0
      ? managers.map((manager) => manager.token)
      : [null];
  const [managerTokens, setManagerTokens] = useState<UserToken[] | null>(
    initialManagerTokens,
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const { booleanValue: allowMultipleManagers } = useFeature(
    Feature.ALLOW_MULTIPLE_MANAGERS,
  );

  const handleOk = async () => {
    setIsSaving(true);

    // When unassigning a manager, send an empty array.
    const newManagerTokens =
      managerTokens.length === 1 && managerTokens[0] === null
        ? []
        : managerTokens;

    try {
      await assignManager(user.token, null, newManagerTokens);
      void message.success('Success');
      onSave();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleManagerSelected = (managerToken: UserToken) => {
    const newManagerTokens = [managerToken, ...managerTokens.slice(1)];
    setManagerTokens(newManagerTokens);
  };

  const handleOtherManagersSelected = (otherManagerTokens: UserToken[]) => {
    const newManagerTokens = [managerTokens[0], ...otherManagerTokens];
    setManagerTokens(newManagerTokens);
  };

  return (
    <Modal
      title={allowMultipleManagers ? 'Assign Managers' : 'Assign Manager'}
      open={true}
      onOk={() => {
        void handleOk();
      }}
      okButtonProps={{
        disabled: managerTokens.length > 1 && managerTokens[0] === null,
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Assign"
    >
      <Column gap={12}>
        {!allowMultipleManagers && (
          <>
            <Text>Select a manager for {user.name}.</Text>
            <SelectUser
              style={{ width: '100%' }}
              organizationToken={organizationToken}
              initialUser={managers ? managers[0] : null}
              onChange={handleManagerSelected}
              omitUserTokens={omitUserTokens}
              allowClear={true}
            />
          </>
        )}
        {allowMultipleManagers && (
          <>
            <Text>Select managers for {user.name}.</Text>
            <Text>Primary Manager</Text>
            <SelectUser
              style={{ width: '100%' }}
              organizationToken={organizationToken}
              initialUser={managers ? managers[0] : null}
              onChange={handleManagerSelected}
              omitUserTokens={omitUserTokens}
              allowClear={true}
            />
            <Text>Other Managers</Text>
            <SelectUsers
              style={{ width: '100%' }}
              organizationToken={organizationToken}
              initialUsers={
                managers && managers.length > 0 ? managers.slice(1) : null
              }
              onChange={handleOtherManagersSelected}
              hideUsers={omitUserTokens}
            />
          </>
        )}
      </Column>
    </Modal>
  );
};
