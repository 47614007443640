import { Feature } from '@shared/features';
import { ListReflectionsResponse } from '@shared/reflections';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Row } from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { Empty, Skeleton } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReflectionItem } from './ReflectionItem';
import { ReflectionUserPicker } from './ReflectionUserPicker';

export const ReflectionsPage: React.FC = () => {
  const { booleanValue: reflectionTagsEnabled } = useFeature(
    Feature.REFLECTION_TAGS_ENABLED,
  );
  const { managesPeople } = useAuth();

  const isTagManagementEnabled = reflectionTagsEnabled && managesPeople;

  return (
    <PageContent>
      <PageHeader
        title={
          <Row gap={12} style={{ marginBottom: '8px' }}>
            <Header1
              style={{
                margin: 0,
                lineHeight: '40px',
              }}
            >
              Reflections on your work
            </Header1>
            {managesPeople ? <ReflectionUserPicker /> : undefined}
          </Row>
        }
        description="Thoughts about impact and performance that build alignment between each person and their manager."
        primaryAction={
          reflectionTagsEnabled &&
          isTagManagementEnabled && {
            label: 'Manage Tags',
            linkTo: `/tags`,
          }
        }
      />
      <Column gap={6}>
        <ReflectionsList />
      </Column>
    </PageContent>
  );
};

const ReflectionsList: React.FC = () => {
  const { data: response } = useApi<ListReflectionsResponse>('/reflections');
  if (!response?.reflections) {
    return <Skeleton />;
  }
  if (response.reflections.length === 0) {
    return (
      <Pane>
        <Empty
          description={
            <Column gap={24}>
              <Column>
                <Text>No reflections yet</Text>
                <Text>
                  You’ll be notified when your manager shares a reflection with
                  you.
                </Text>
              </Column>
              <Column style={{ alignItems: 'center' }}>
                <Text style={{ maxWidth: '535px' }}>
                  To ensure the most helpful and accurate reflection, verify
                  that your{' '}
                  <Link to="/journal" style={{ color: 'var(--primary-color)' }}>
                    Journal
                  </Link>{' '}
                  is up to date with entries that cover your work and
                  accomplishments.
                </Text>
              </Column>
            </Column>
          }
        />
      </Pane>
    );
  }
  return (
    <Pane>
      {response.reflections.map((reflection) => (
        <ReflectionItem key={reflection.token} reflection={reflection} />
      ))}
    </Pane>
  );
};
