import { daysAgo } from '@shared/dates';
import { OrganizationToken } from '@shared/types';
import { post } from '@web/common/api';
import { Column, Spacer } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { Button, Modal, message } from 'antd';
import { DatePicker } from 'antd/lib';
import dayjs from 'dayjs';
import * as React from 'react';

export const SeedPullRequestsButton: React.FC<{
  organizationToken: OrganizationToken;
  onChange: () => void;
  disabled?: boolean;
}> = ({ organizationToken, onChange, disabled }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        disabled={disabled}
      >
        Seed pull requests
      </Button>
      {open && (
        <SeedPullRequestsModal
          organizationToken={organizationToken}
          onCancel={() => {
            setOpen(false);
          }}
          onChange={onChange}
        />
      )}
    </>
  );
};

interface Props {
  onCancel: () => void;
  organizationToken: OrganizationToken;
  onChange: () => void;
}

const SeedPullRequestsModal: React.FC<Props> = ({
  onCancel,
  organizationToken,
  onChange,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [mergedDate, setMergedDate] = React.useState(dayjs(daysAgo(30)));

  const handleOk = async () => {
    setIsSaving(true);
    try {
      await post('/github/pulls/seed', {
        organizationToken,
        mergedDate: mergedDate.toISOString(),
      });
      void message.success('Success');
      onChange();
      onCancel();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    onCancel();
  };

  return (
    <Modal
      title="Seed Pull Requests"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="600px"
      okButtonProps={{ loading: isSaving }}
      okText={isSaving ? 'Seeding' : 'Continue'}
    >
      <Column gap={12}>
        <Text>
          Discover pull requests from this organization&apos;s Github
          repositories and populate them in Flint&apos;s database to be used for
          suggesting journal entries.
        </Text>
        <Column>
          <Text>Merged date</Text>
          <DatePicker
            value={mergedDate}
            onChange={(value) => {
              setMergedDate(value);
            }}
          />
          <Small>
            Seed pull requests that were merged on or after this date
          </Small>
        </Column>
        <Spacer size={12} />
      </Column>
    </Modal>
  );
};
