import { formatDate } from '@shared/formatDate';
import { OrganizationToken } from '@shared/types';
import {
  IWorkdayFakePayload,
  ListWorkdayFakePayloadResponse,
} from '@shared/workday';
import { PageContent } from '@web/app/Page';
import { ServerResponseError, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { StopPropagation } from '@web/components/StopPropagation';
import { Button, Table, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

export const WorkdayFakePayloadsPage: React.FC = () => {
  const navigate = useNavigate();
  const { confirmThenDo, contextHolder } = useModalConfirm();
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();

  const { data: response, error } = useApi<
    ListWorkdayFakePayloadResponse,
    ServerResponseError
  >(`/workday/payloads`);

  if (error) {
    void message.error('Failed to load templates');
    return (
      <ErrorPageContent
        statusCode={error.statusCode}
        subTitle={error.displayMessage}
      />
    );
  }

  const columns: ColumnsType<IWorkdayFakePayload> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Payload',
      dataIndex: 'payload',
      key: 'payload',
      render: (_, payload) => {
        return (
          <Tooltip
            overlayInnerStyle={{
              width: 500,
              maxHeight: 500,
              overflowY: 'auto',
            }}
            title={<NotificationContent>{payload.raw}</NotificationContent>}
          >
            View payload
          </Tooltip>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (createdDate) => formatDate(createdDate),
    },
    {
      title: 'Action',
      dataIndex: 'token',
      key: 'action',
      render: (token) => (
        <StopPropagation>
          <Button
            onClick={confirmThenDo(
              'Are you sure you want to sync using SLACK and WORKDAY?',
              async () => {
                await post(
                  `/user-syncing/${organizationToken}/sync/workday/${token}`,
                );
              },
            )}
          >
            Sync Organization
          </Button>
        </StopPropagation>
      ),
    },
  ];

  return (
    <PageContent>
      <PageHeader
        title="Workday Fake Payloads"
        primaryAction={{
          label: 'New Fake Payload',
          linkTo: 'new',
        }}
        navigateBack
      />
      <Table
        loading={!response?.payloads}
        dataSource={response?.payloads}
        columns={columns}
        onRow={(template) => ({
          onClick: () => {
            navigate(template.token);
          },
        })}
        rowKey="token"
      />
      {contextHolder}
    </PageContent>
  );
};

const NotificationContent = styled.div`
  white-space: pre-wrap;
  font-family: monospace;
`;
