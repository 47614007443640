import { TeamToken } from '@shared/types';
import { BackButton } from '@web/components/BackButton';
import { Column } from '@web/components/layout';
import { EditTeamForm } from '@web/components/teams/EditTeamForm';
import { Subheading, Text } from '@web/components/text';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AdminPageContent } from './AdminPageContent';

export const AdminEditTeamPage: React.FC = () => {
  const navigate = useNavigate();
  const { teamToken } = useParams<{ teamToken?: TeamToken }>();

  const isEdit = !!teamToken;

  const handleSave = async () => {
    navigate(-1);
  };

  const handleCancel = async () => {
    navigate(-1);
  };

  return (
    <AdminPageContent>
      <Column gap={6}>
        <BackButton />
        <Subheading>{isEdit ? 'Edit team' : 'Add a team'}</Subheading>
        <Text>
          Teams in Flint should reflect your org chart. Members can only exist
          on one team at a time.
        </Text>
      </Column>
      <EditTeamForm
        teamToken={teamToken}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </AdminPageContent>
  );
};
