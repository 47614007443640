import { Row } from '@web/components/layout';
import * as React from 'react';

import { Colors } from './styles/ColorStyles';

const ENVIRONMENT_DETAILS = [
  {
    name: 'production',
    backgroundColor: Colors.red,
  },
  {
    name: 'staging',
    backgroundColor: '#f4b700',
  },
  {
    name: 'development',
    backgroundColor: Colors.azure,
  },
];
export const EnvironmentBanner: React.FC = () => {
  const { name, backgroundColor } = ENVIRONMENT_DETAILS.find(
    (d) => d.name === process.env.NODE_ENV,
  );
  return (
    <Row
      style={{
        padding: 8,
        justifyContent: 'center',
        backgroundColor,
        color: 'white',
        fontSize: 16,
      }}
    >
      {name.toUpperCase()}
    </Row>
  );
};
