import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { GrowingSpacer } from '@web/components/layout';
import { Collapse } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { SectionHeader } from './SectionHeader';

export interface Collapsable {
  setExpanded: (value: boolean) => void;
}

interface Props {
  title: React.ReactNode;
  startExpanded?: boolean;
  children: React.ReactNode;
  ref?: React.Ref<Collapsable>;
  extra?: React.ReactNode;
}
// eslint-disable-next-line react/display-name
export const CollapsableSection = React.forwardRef<Collapsable, Props>(
  ({ extra, title, startExpanded = false, children }, ref) => {
    const [expanded, setExpanded] = React.useState(startExpanded);
    React.useImperativeHandle(ref, () => ({
      setExpanded(value: boolean) {
        setExpanded(value);
      },
    }));

    const Arrow = expanded ? DownOutlined : RightOutlined;

    return (
      <CollapsablePanelContainer>
        <Collapse
          activeKey={expanded ? ['1'] : undefined}
          onChange={(key) => {
            setExpanded(key.length > 0);
          }}
        >
          <Collapse.Panel
            key={1}
            header={
              <SectionHeader>
                {title}
                <Arrow
                  style={{
                    fontSize: 12,
                  }}
                />
                <GrowingSpacer />
                {extra}
              </SectionHeader>
            }
            showArrow={false}
          >
            {children}
          </Collapse.Panel>
        </Collapse>
      </CollapsablePanelContainer>
    );
  },
);

const CollapsablePanelContainer = styled.section`
  .ant-collapse {
    border: 0;
    background: none;

    .ant-collapse-item {
      border: 0;

      .ant-collapse-header {
        padding: 0;
      }

      .ant-collapse-content {
        border-top: 0;
      }

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`;
