import { useSelectGoalListsSearch } from '@client/GoalsClient';
import { GoalListToken, IGoalList } from '@shared/goals';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Empty, Modal, Select } from 'antd';
import React, { useState } from 'react';

export const SelectGoalListModal: React.FC<{
  title: string;
  instructions?: string;
  initialGoalList?: IGoalList;
  okText: string;
  omitGoalLists?: GoalListToken[];
  onCancel: () => void;
  onSave: (goal: IGoalList) => Promise<void>;
}> = ({
  title,
  initialGoalList,
  instructions,
  onSave,
  onCancel,
  okText,
  omitGoalLists = [],
}) => {
  const [selectedGoalList, setSelectedGoalList] = useState<GoalListToken>(
    initialGoalList?.token ?? null,
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: goalLists } = useSelectGoalListsSearch();

  const handleOk = async () => {
    setIsSaving(true);

    try {
      const goalList = goalLists.find((g) => g.token === selectedGoalList);
      await onSave(goalList);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleGoalListChange = (token: GoalListToken) => {
    setSelectedGoalList(token);
  };

  if (!goalLists) {
    return null;
  }

  const visibleGoalLists = omitGoalLists
    ? goalLists.filter((goal) => !omitGoalLists.includes(goal.token))
    : goalLists;
  return (
    <Modal
      title={title}
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText={okText}
      okButtonProps={{
        disabled: !selectedGoalList,
      }}
    >
      <Column gap={12}>
        {instructions && <Text>{instructions}</Text>}
        <Select
          onChange={handleGoalListChange}
          value={selectedGoalList}
          placeholder="Select a list"
          notFoundContent={
            <Empty
              style={{ margin: '12px 0' }}
              description="No goal lists to select"
            />
          }
        >
          {visibleGoalLists.map((goalList) => (
            <Select.Option value={goalList.token} key={goalList.token}>
              {goalList.title}
            </Select.Option>
          ))}
        </Select>
      </Column>
    </Modal>
  );
};
