import { SearchOutlined } from '@ant-design/icons';
import { omnisearch } from '@client/OmnisearchClient';
import { Column, Row } from '@web/components/layout';
import { Input, InputRef } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { debounce } from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  QuickSearchResults,
  Result,
  isOrganization,
} from './QuickSearchResults';

interface Props {
  onClose: () => void;
  open: boolean;
}
export const QuickSearchModal: React.FC<Props> = ({ onClose, open }) => {
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();
  const searchRef = React.useRef<InputRef>();
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState<Result[]>([]);
  const search = async () => {
    let newResults: Result[];
    if (query.length > 0) {
      newResults = await omnisearch(query);
    } else {
      newResults = [];
    }
    setResults(newResults);
    setPage(1);
  };
  const debouncedSearch = debounce(search, 300);

  React.useEffect(() => {
    void debouncedSearch();
  }, [query]);

  React.useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  const handleQueryChanged = (newQuery: string) => {
    setQuery(newQuery);
  };

  const handleSelect = (result: Result) => {
    if (isOrganization(result)) {
      navigate(`/organizations/${result.token}`);
    } else {
      navigate(
        `/organizations/${result.organizationToken}/users/${result.token}/activity`,
      );
    }
    onClose();
  };

  const handleCancel = () => {
    onClose();
    setQuery('');
  };

  return (
    <Modal
      title={null}
      open={open}
      onCancel={handleCancel}
      width="800px"
      footer={null}
      closeIcon={null}
    >
      <Column gap={12}>
        <Row gap={12}>
          <Input
            placeholder="Search for organizations or users"
            ref={searchRef}
            prefix={<SearchOutlined />}
            allowClear
            value={query}
            onChange={(e) => {
              handleQueryChanged(e.currentTarget.value);
            }}
            style={{ fontSize: 18 }}
          />
        </Row>
        <QuickSearchResults
          results={results}
          onSelect={handleSelect}
          page={page}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </Column>
    </Modal>
  );
};
