import { IComment } from '@shared/comments';
import { UserMapItem } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { EditedLabel } from '@web/components/EditedLabel';
import { RichText } from '@web/components/RichText';
import { UserMessage } from '@web/components/UserMessage';
import { Spacer } from '@web/components/layout';
import * as React from 'react';
import styled from 'styled-components';

import { ActionBar } from './ActionBar';
import { EditComment } from './EditComment';

export const UserComment: React.FC<{
  comment: IComment;
  author: UserMapItem;
  onChange: () => void;
  style?: React.CSSProperties;
  isReply?: boolean;
  canReact?: boolean;
  visibleName?: 'comment' | 'note';
}> = ({
  comment,
  author,
  onChange,
  isReply = false,
  canReact = true,
  visibleName = 'comment',
}) => {
  const [editing, setEditing] = React.useState(false);
  const { user } = useAuth();
  const isAuthor = user.token === author.token;

  const handleCommentEdit = () => {
    setEditing(!editing);
  };
  const handleCommentSaved = () => {
    setEditing(false);
    onChange();
  };

  const userCommentStyles = isReply
    ? {
        borderLeft: '3px solid #eee',
        paddingLeft: 17,
      }
    : {};

  return (
    <Container style={userCommentStyles}>
      <UserMessage
        user={author}
        date={comment.createdDate}
        avatarSize={24}
        fontSize={12}
      >
        {editing ? (
          <EditComment
            comment={comment}
            onSave={handleCommentSaved}
            visibleName={visibleName}
          />
        ) : (
          <RichText value={comment.text} />
        )}
        {!editing && <EditedLabel entity={comment} />}
        <Spacer size={6} />
        <ActionBar
          entityToken={comment.token}
          onEdit={isAuthor ? handleCommentEdit : undefined}
          canReact={canReact}
        />
      </UserMessage>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 13px;
    width: 0;
    border-left: 3px solid #eee;
    border-color: transparent;
  }

  &:last-child:before {
    border-color: transparent;
  }
`;
