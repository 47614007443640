import { ActionItemToken, AgendaItemToken, OneOnOneToken } from './one-on-one';

export interface IWebSocketEvent<T = any> {
  type: WebSocketEventType;
  entityToken: WebSocketEventEntityToken;
  payload?: T;
}
export type WebSocketEventEntityToken = OneOnOneToken;
export enum WebSocketEventType {
  ONE_ON_ONE_ENDED = 'ONE_ON_ONE_ENDED',
  AGENDA_ITEMS_UPDATED = 'AGENDA_ITEMS_UPDATED',
  ACTION_ITEMS_UPDATED = 'ACTION_ITEMS_UPDATED',
  SHARED_NOTES_UPDATED = 'SHARED_NOTES_UPDATED',
  ENTITY_NOTE_ADDED = 'ENTITY_NOTE_ADDED',
  ENTITY_NOTE_UPDATED = 'ENTITY_NOTE_UPDATED',
  ENTITY_NOTE_DELETED = 'ENTITY_NOTE_DELETED',
  TYPING = 'TYPING',
  PRESENCE = 'PRESENCE',
}
export interface ITypingEvent extends IWebSocketEvent {
  type: WebSocketEventType.TYPING;
  entityToken: OneOnOneToken;
  itemToken: AgendaItemToken | ActionItemToken;
}
