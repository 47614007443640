import { updatePrimaryManager } from '@client/UsersClient';
import { UserMapItem, UserToken } from '@shared/types';
import { primaryManager } from '@shared/users';
import { useAuth } from '@web/auth/useAuth';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Modal, Select, message } from 'antd';
import React, { useState } from 'react';

export const SelectPrimaryManagerModal: React.FC<{
  onCancel: () => void;
  onSave: () => void;
  userToken: UserToken;
  managers: UserMapItem[];
}> = ({ userToken, onSave, onCancel, managers }) => {
  const { user } = useAuth();
  const [selectedPrimaryManager, setSelectedPrimaryManager] =
    useState<UserToken>(primaryManager(managers).token);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    if (!user) {
      return;
    }

    setIsSaving(true);

    try {
      await updatePrimaryManager(userToken, selectedPrimaryManager);
      void message.success('Success');
      onSave();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    onCancel();
  };

  return (
    <Modal
      title="Choose primary manager"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="500px"
      okText="Save"
    >
      <Column gap={12}>
        <Text>
          A primary manager will receive notifications for events relating to
          this individual.
        </Text>
        <Select
          value={selectedPrimaryManager}
          onChange={setSelectedPrimaryManager}
        >
          {managers.map((manager) => (
            <Select.Option value={manager.token} key={manager.token}>
              {manager.name}
            </Select.Option>
          ))}
        </Select>
      </Column>
    </Modal>
  );
};
