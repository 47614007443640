import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Input, Modal } from 'antd';
import React from 'react';

interface Props {
  onCancel: () => void;
  onSave: (text: string) => void;
}

export const SaveSummaryModal: React.FC<Props> = ({ onSave, onCancel }) => {
  const [name, setName] = React.useState('');

  return (
    <Modal
      title="Save New Summary"
      open={true}
      onOk={() => {
        onSave(name);
      }}
      afterClose={onCancel}
      onCancel={onCancel}
      width="600px"
      okText="Save"
      okButtonProps={{
        disabled: !name,
      }}
    >
      <Column>
        <Text>Summary Name</Text>
        <Input
          value={name}
          onChange={(e) => {
            setName(e.currentTarget.value);
          }}
        />
      </Column>
    </Modal>
  );
};
