import Color from 'color';
import { css } from 'styled-components';

// placeholder to define system colours
export enum Colors {
  white = '#FFFFFF',
  grey = '#f9f1ed',
  slate = '#333',
  pink = '#ef4a81',
  tangelo = '#f45000',
  azure = '#00A4F4',
  red = '#FF3333',
  yellow = '#FFE7A0',
  green = '#3cbf3c',
  lightBlue = '#eaf8ff',
  supernova = '#f4cb00',
}

export const primaryColor = Color(Colors.tangelo);
export const complimentaryColor = Color(Colors.azure);
export const tertiaryColor = Color(Colors.supernova);
export const successColor = Color(Colors.green);
export const errorColor = Color(Colors.red);
export const warningColor = Color(Colors.yellow);

export const ColorStyles = css`
  :root {
    --color-success: ${successColor.string()};
    --color-error: ${errorColor.string()};
    --color-white: ${Colors.white};
    --body-background: ${Colors.white};
    --page-background: #f5f5f5;
    --color-text: ${Colors.slate};
    --header-background: ${Colors.white};
    --header-border-color: ${Colors.slate};
    --primary-color: ${primaryColor.string()};
    --complimentary-color: ${complimentaryColor.string()};
    --border-color: #e3e3e3;
    --pane-border-color: transparent;
    --tabs-border-color: #ccc;
    --hover-background: ${Colors.lightBlue};

    --modal-header-background: ${Colors.slate};
    --modal-header-text: ${Colors.white};

    --default-border-radius: 4px;

    --ant-primary-color: ${primaryColor.string()};
    --ant-primary-color-hover: ${primaryColor.lighten(0.1)};
    --ant-primary-color-active: ${primaryColor.darken(0.1)};
    --ant-primary-color-outline: ${primaryColor.alpha(0.2)};
    --ant-primary-1: ${primaryColor.lighten(0.5)};
    --ant-primary-2: ${primaryColor.lighten(0.4)};
    --ant-primary-3: ${primaryColor.lighten(0.3)};
    --ant-primary-4: ${primaryColor.lighten(0.2)};
    --ant-primary-5: ${primaryColor.lighten(0.1)};
    --ant-primary-6: ${primaryColor.string()};
    --ant-primary-7: ${primaryColor.darken(0.1)};

    --ant-error-color: ${errorColor.string()};
    --ant-error-color-hover: ${errorColor.lighten(0.2)};
    --ant-error-color-active: ${errorColor.lighten(0.1)};
    --ant-error-color-outline: ${errorColor.alpha(0.2)};
  }
`;
