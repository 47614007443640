import { MailOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import {
  deleteEnrolledUser,
  onboardNewUsers,
  useEnrolledUsers,
} from '@client/AlignmentClient';
import { IUser } from '@shared/types';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Button, Skeleton, Tooltip, message } from 'antd';
import * as React from 'react';

import { AdminPageContent } from '../AdminPageContent';
import { AlignmentSettingsModal } from './AlignmentSettingsModal';
import { EnrollUsersModal } from './EnrollUsersModal';
import { EnrolledUsersTable } from './EnrolledUsersTable';

const AdminAlignmentPage: React.FC = () => {
  const { data: enrolledUsers, mutate: reloadEnrolledUsers } =
    useEnrolledUsers();
  const [showEnrollModal, setShowEnrollModal] = React.useState(false);
  const [showSettingsModal, setShowSettingsModal] = React.useState(false);
  const [isSendingOnboarding, setIsSendingOnboarding] = React.useState(false);

  const { confirmThenDo, contextHolder } = useModalConfirm();

  const handleEnrolledUsersAdded = () => {
    void reloadEnrolledUsers();
    setShowEnrollModal(false);
  };
  const handleDeleteUser = async (user: IUser) => {
    await confirmThenDo(`Do you want to remove ${user.name}?`, async () => {
      await deleteEnrolledUser(user.token);
      void reloadEnrolledUsers();
    })();
  };

  const handleSendOnboarding = async () => {
    await confirmThenDo(
      <Column gap={6}>
        <Text>
          Do you want to notify all newly enrolled users about Alignment?
        </Text>
        <Text>
          Only users who haven&apos;t received this intro message previously
          will be notified.
        </Text>
      </Column>,
      async () => {
        setIsSendingOnboarding(true);
        try {
          await onboardNewUsers();
        } catch (error) {
          void message.error('Error');
        } finally {
          setIsSendingOnboarding(false);
        }
      },
    )();
  };

  return (
    <AdminPageContent>
      <Row gap={6}>
        <GrowingSpacer />
        <Button
          type="primary"
          onClick={() => {
            setShowEnrollModal(true);
          }}
          style={{ maxWidth: 200 }}
        >
          <PlusOutlined /> Enroll Users
        </Button>
        <Button
          style={{ maxWidth: 240 }}
          disabled={isSendingOnboarding}
          onClick={handleSendOnboarding}
        >
          <MailOutlined /> Send Intro Message
        </Button>
        <Tooltip title="Alignment Settings">
          <Button
            onClick={() => {
              setShowSettingsModal(true);
            }}
          >
            <SettingOutlined />
          </Button>
        </Tooltip>
      </Row>
      {enrolledUsers ? (
        <EnrolledUsersTable users={enrolledUsers} onDelete={handleDeleteUser} />
      ) : (
        <Skeleton />
      )}
      {showEnrollModal && (
        <EnrollUsersModal
          onCancel={() => {
            setShowEnrollModal(false);
          }}
          onSave={handleEnrolledUsersAdded}
        />
      )}
      {showSettingsModal && (
        <AlignmentSettingsModal
          onClose={() => {
            setShowSettingsModal(false);
          }}
        />
      )}
      {contextHolder}
    </AdminPageContent>
  );
};

export default AdminAlignmentPage;
