import { useTeams } from '@client/TeamClient';
import { TeamToken } from '@shared/types';
import { Select } from 'antd';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

const ALL_TEAMS_VALUE: TeamToken = 'tm_all';

export const useSelectedTeam = () => {
  const { data: teamOptions } = useTeams();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTeamToken: TeamToken =
    (searchParams.get('team') as any) ?? ALL_TEAMS_VALUE;
  const handleSelectTeam = (teamToken: TeamToken) => {
    setSearchParams((prev) => {
      prev.delete('team');
      if (teamToken) {
        prev.append('team', teamToken);
      }
      return prev;
    });
  };

  const teamToken =
    selectedTeamToken === ALL_TEAMS_VALUE ? undefined : selectedTeamToken;

  return {
    teamOptions,
    teamToken,
    selectedTeamToken,
    handleSelectTeam,
    isLoadingTeams: !teamOptions,
  };
};

export const SelectTeam: React.FC = () => {
  const { teamOptions, selectedTeamToken, handleSelectTeam } =
    useSelectedTeam();

  return (
    <Select
      style={{ maxWidth: 400 }}
      value={selectedTeamToken}
      onChange={handleSelectTeam}
    >
      <Select.Option value={ALL_TEAMS_VALUE}>All Teams</Select.Option>
      {teamOptions?.map((team) => (
        <Select.Option value={team.token} key={team.token}>
          {team.manager?.name ?? '-'}
        </Select.Option>
      ))}
    </Select>
  );
};
