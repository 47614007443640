import { QuestionType } from '@shared/QuestionType';
import { IQuestionResponse } from '@shared/question_response';
import {
  IOpenEndedQuestion,
  IQuestion,
  IRatingQuestion,
  QuestionToken,
} from '@shared/questions';
import { Rating, RatingScale, RatingTrend } from '@shared/rating_scales';
import {
  ISurveyParticipant,
  SurveyCycleToken,
  SurveyParticipantToken,
} from '@shared/surveys';
import { UserMapItem } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import { complimentaryColor, primaryColor } from '@web/app/styles/ColorStyles';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header3, Small, Text } from '@web/components/typography';
import { Divider, Empty, Modal, Tag } from 'antd';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { sortBy, sum } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { RatingTrendChart } from '../RatingTrendChart';

export const ResponseSummaries: React.FC<{
  questions: IQuestion[];
  summaries?: any;
  participantMap?: Record<SurveyParticipantToken, UserMapItem>;
  averageRatingTrendMap?: Record<QuestionToken, RatingTrend>;
  currentSurveyCycleToken?: SurveyCycleToken;
  totalParticipants: number;
  participants?: ISurveyParticipant[];
}> = ({
  questions,
  summaries,
  participantMap,
  averageRatingTrendMap,
  currentSurveyCycleToken,
  totalParticipants,
  participants,
}) => {
  const hasSummaries = summaries && Object.keys(summaries).length > 0;

  return (
    <Column>
      {!hasSummaries ? (
        <Empty description="No response summaries available" />
      ) : (
        questions.map((question, index) => (
          <>
            {index !== 0 && <Divider />}

            {question.type === QuestionType.RATING ? (
              <RatingQuestionSummary
                key={question.token}
                question={question}
                summary={summaries[question.token]}
                averageTrend={
                  averageRatingTrendMap?.[question.correlationToken as any]
                }
                currentSurveyCycleToken={currentSurveyCycleToken}
                totalParticipants={totalParticipants}
                participants={participants}
              />
            ) : question.type === QuestionType.OPEN_ENDED ? (
              <OpenEndedQuestionSummary
                key={question.token}
                question={question}
                summary={summaries[question.token]}
                participantMap={participantMap}
              />
            ) : null}
          </>
        ))
      )}
    </Column>
  );
};
const OpenEndedQuestionSummary: React.FC<{
  question: IOpenEndedQuestion;
  summary?: IQuestionResponse[];
  participantMap?: Record<SurveyParticipantToken, UserMapItem>;
}> = ({ question, summary, participantMap }) => {
  if (!summary || summary?.length === 0) {
    return (
      <ResponseSummary>
        <Header3>{question.text}</Header3>
        <Text>No responses</Text>
      </ResponseSummary>
    );
  }

  return (
    <ResponseSummary>
      <Header3>{question.text}</Header3>
      <Column gap={18}>
        {summary.map((response) => (
          <Column gap={6} key={response.token}>
            <UserLabel
              user={participantMap?.[response.surveyParticipantToken as any]}
            />
            <Text>{response.text}</Text>
          </Column>
        ))}
      </Column>
    </ResponseSummary>
  );
};
const RatingQuestionSummary: React.FC<{
  question: IRatingQuestion;
  summary: Record<number, number>;
  averageTrend?: RatingTrend;
  currentSurveyCycleToken?: SurveyCycleToken;
  totalParticipants: number;
  participants?: ISurveyParticipant[];
}> = ({
  question,
  summary,
  averageTrend,
  currentSurveyCycleToken,
  totalParticipants,
  participants,
}) => {
  const [ratingFilter, setRatingFilter] = React.useState<Rating | null>(null);
  const { isMobile } = useResponsive();
  const totalResponses = sum(Object.values(summary));
  const totalRatings = sum(
    [1, 2, 3, 4, 5].map((value) => {
      return summary[value] ? summary[value] * value : 0;
    }),
  );
  const percent =
    totalResponses > 0
      ? Math.round((totalResponses / totalParticipants) * 100)
      : 0;

  const ratingFilterParticipants = ratingFilter
    ? participants?.filter((participant) => {
        const questionResponse = participant.responses?.find(
          (response) => response.questionToken === question.token,
        );
        return questionResponse?.rating === ratingFilter?.value;
      })
    : [];

  return (
    <ResponseSummary>
      <Row style={{ maxWidth: 800 }}>
        <Header3>{question.text}</Header3>
      </Row>
      <Row gap={24} style={{ alignItems: 'flex-start' }}>
        <Column>
          <Row style={{ maxWidth: 600 }}>
            <Tag>
              Responses: {totalResponses} ({percent}%)
            </Tag>
            <GrowingSpacer />
            <Tag color={primaryColor.string()}>
              Average:{' '}
              {totalResponses > 0
                ? (totalRatings / totalResponses).toFixed(1)
                : 'N/A'}
            </Tag>
          </Row>
          <ReactECharts
            option={createRatingScaleChartOptions(
              question.scale ?? [],
              summary,
            )}
            style={{ width: isMobile ? 350 : 600, height: 150, padding: 0 }}
            onEvents={
              participants
                ? {
                    click: (params) => {
                      if (
                        params.componentType === 'series' &&
                        params.componentSubType === 'bar'
                      ) {
                        const dataIndex = params.dataIndex;
                        const sortedScale = sortBy(question.scale, 'value');
                        const rating = sortedScale[dataIndex];
                        setRatingFilter(rating);
                      }
                    },
                  }
                : undefined
            }
          />

          {averageTrend && averageTrend.length > 1 && (
            <Column>
              <Spacer size={12} />
              <Header3>Previous averages</Header3>
              <RatingTrendChart
                trend={averageTrend}
                currentKey={currentSurveyCycleToken}
              />
            </Column>
          )}
        </Column>
        {ratingFilter &&
          ratingFilterParticipants &&
          currentSurveyCycleToken && (
            <ViewIndividualsPerRatingModal
              question={question}
              rating={ratingFilter}
              participants={ratingFilterParticipants}
              onClose={() => {
                setRatingFilter(null);
              }}
              surveyCycleToken={currentSurveyCycleToken}
            />
          )}
      </Row>
    </ResponseSummary>
  );
};
const createRatingScaleChartOptions = (
  scale: RatingScale,
  summary: any,
): EChartsOption => {
  const sortedScale = sortBy(scale, 'value');
  const data = sortedScale.map((rating) => summary[rating.value]);
  return {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '13%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: sortedScale.map((rating) => `${rating.value} - ${rating.label}`),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data,
        type: 'bar',
        itemStyle: {
          color: complimentaryColor.string(),
        },
        barWidth: 30,
      },
    ],
  };
};
const ResponseSummary = styled(Column)`
  gap: 12px;
`;

const UserLabel: React.FC<{ user?: UserMapItem }> = ({ user }) =>
  user ? (
    <Row gap={6}>
      <UserAvatar user={user} size={16} />
      <Text>{user.name}</Text>
    </Row>
  ) : null;

export const ViewIndividualsPerRatingModal: React.FC<{
  surveyCycleToken: SurveyCycleToken;
  question: IQuestion;
  rating: Rating;
  participants: ISurveyParticipant[];
  onClose: () => void;
}> = ({ onClose, participants, question, rating, surveyCycleToken }) => {
  return (
    <Modal
      title="Individual Responses"
      open={true}
      onOk={onClose}
      onCancel={onClose}
      width="400px"
      cancelButtonProps={{
        style: { display: 'none' },
      }}
    >
      <Column gap={6}>
        <Text>{question.text}</Text>
        <Tag style={{ alignSelf: 'flex-start' }}>
          {rating.value} - {rating.label}
        </Tag>
      </Column>
      <Spacer size={24} />
      <Small>
        {participants.length} {pluralize('participant', participants.length)}{' '}
        responded:
      </Small>
      <Column
        style={{
          maxHeight: 400,
          overflow: 'auto',
          borderRadius: 'var(--default-border-radius',
        }}
      >
        {participants.map((participant, index) => (
          <Link
            key={participant.token}
            to={`/admin/surveys/${surveyCycleToken}/participants/${participant.token}`}
            style={{
              borderBottom:
                index === participants.length - 1
                  ? '1px solid transparent'
                  : '1px solid #eee',
              padding: '8px',
            }}
          >
            <Row gap={6}>
              <UserAvatar user={participant.user} size={20} />
              <Text>{participant.user.name}</Text>
            </Row>
          </Link>
        ))}
      </Column>
    </Modal>
  );
};
