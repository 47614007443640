import { formatDate } from '@shared/formatDate';
import { possessive } from '@shared/possessive';
import { IReflection, productDetails } from '@shared/reflections';
import { IUser } from '@shared/types';

export const pageTitle = (
  currentUser: IUser,
  reflection: IReflection,
): string => {
  const details = productDetails(reflection.productName);

  const isSelfReview = reflection.authorToken === reflection.receiverToken;
  const isCycleReflection = !!reflection.reviewCycleToken;
  const isAuthor = currentUser.token === reflection.authorToken;
  const isReceiver = currentUser.token === reflection.receiverToken;

  if (isCycleReflection) {
    if (isSelfReview) {
      return isAuthor
        ? `Self ${details.titleCase}`
        : `Self ${details.titleCase} by ${reflection.author.name}`;
    } else {
      return isReceiver
        ? `Manager ${details.titleCase}`
        : `Manager ${details.titleCase} for ${reflection.receiver.name}`;
    }
  }

  if (isSelfReview) {
    return `Self ${details.titleCase}`;
  }
  const author = isAuthor ? 'Your' : possessive(reflection.author.name);
  const receiver =
    currentUser.token === reflection.receiverToken
      ? 'your'
      : possessive(reflection.receiver.name);
  return `${author} ${reflection.title} on ${receiver} work`;
};

export const pageDescription = (reflection: IReflection): string => {
  if (reflection.reviewCycle?.name) {
    return reflection.reviewCycle?.name;
  }
  return `On work from ${formatDate(reflection.startDate)} - ${formatDate(
    reflection.endDate,
  )}`;
};
