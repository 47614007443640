import { LeftOutlined, MenuOutlined } from '@ant-design/icons';
import { useAuth } from '@web/auth/useAuth';
import { useBackNavigation } from '@web/common/useBackNavigation';
import { useScrollDirection } from '@web/common/useScrollDirection';
import { GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Drawer, Typography } from 'antd';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { FlintLogo } from './Logo/Logo';
import { PageContext } from './PageContext';
import { CustomerSidebarLinks } from './Sidebar';

const HEADER_HEIGHT = 50;

export const MobilePageHeader: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const {
    pageDetails: { title, navigateBack, defaultNavigateBackTo },
  } = React.useContext(PageContext);
  const [showDrawer, setShowDrawer] = React.useState(false);
  React.useEffect(() => {
    setShowDrawer(false);
  }, [location.pathname]);
  const { goBack } = useBackNavigation(
    navigateBack ?? false,
    defaultNavigateBackTo,
  );
  const { hasScroll } = useScrollDirection();

  const showHeader = isLoggedIn && !location.pathname.startsWith('/slack');

  if (!showHeader) {
    return null;
  }

  return (
    <HeaderContainer shadow={hasScroll}>
      <Row>
        {goBack ? (
          <HeaderButton onClick={goBack}>
            <LeftOutlined />
          </HeaderButton>
        ) : (
          <HeaderLink to="/home">
            <FlintLogo />
          </HeaderLink>
        )}
        {title ? <HeaderTitle>{title}</HeaderTitle> : <GrowingSpacer />}
        {!goBack ? (
          <HeaderButton
            onClick={() => {
              setShowDrawer(true);
            }}
          >
            <MenuOutlined />
          </HeaderButton>
        ) : (
          <Spacer size={HEADER_HEIGHT} />
        )}
      </Row>
      <Drawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
        width={300}
        keyboard
        className="mobile-menu"
      >
        <CustomerSidebarLinks />
      </Drawer>
    </HeaderContainer>
  );
};
const HeaderContainer = styled.div<{ shadow?: boolean }>`
  margin: 0 -20px 12px;
  justify-content: center;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid var(--border-color);
  z-index: 2;
  ${(props) => (props.shadow ? 'box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);' : '')}
`;
export const Extras = styled.div`
  display: flex;
  gap: 5px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: white;
  z-index: 1000;

  .ant-btn {
    background: #666;
    color: white;
    border-color: #666;
    height: 40px;
  }
`;
const HeaderLink = styled(NavLink)`
  display: inline-flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${HEADER_HEIGHT}px;
  min-width: ${HEADER_HEIGHT}px;
  position: relative;

  > span {
    font-size: 12px;

    svg {
      font-size: 18px;
    }
  }
`;
const HeaderButton = styled.button`
  appearance: none;
  border: 0;
  outline: 0;
  background: unset;
  display: inline-flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${HEADER_HEIGHT}px;
  min-width: ${HEADER_HEIGHT}px;

  > span {
    font-size: 12px;

    svg {
      font-size: 18px;
    }
  }
`;
const HeaderTitle = styled(Typography.Text)`
  padding-top: 2px;
  line-height: 48px;
  font-size: 16px;
  align-self: flex-start;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
