import { IReflection } from '@shared/reflections';
import { Row } from '@web/components/layout';
import React from 'react';

import { StyledTag } from './StyledTag';

export const TagList: React.FC<{ reflection: IReflection }> = ({
  reflection,
}) => {
  return (
    <Row>
      {reflection.tags.map((tag) => (
        <StyledTag key={tag.token} tag={tag} />
      ))}
    </Row>
  );
};
