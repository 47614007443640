import { ISurveyCycle, SurveyCycleToken } from '@shared/surveys';
import { useApi } from '@web/common/useApi';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AdminPageContent } from '../../admin/AdminPageContent';
import { AdminSurveyContent } from './AdminSurveyContent';
import { SurveyCycleSettingsForm } from './SurveyCycleSettingsForm';

const AdminSurveyCycleSettingsPage: React.FC = () => {
  const { surveyCycleToken } = useParams<{
    surveyCycleToken?: SurveyCycleToken;
  }>();
  const { data: surveyCycle, mutate: reloadSurveyCycle } = useApi<ISurveyCycle>(
    `/survey-cycles/${surveyCycleToken}`,
  );

  if (!surveyCycle) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const handleSave = () => {
    void reloadSurveyCycle();
  };

  return (
    <AdminSurveyContent surveyCycle={surveyCycle} onChange={reloadSurveyCycle}>
      <SurveyCycleSettingsForm onSave={handleSave} surveyCycle={surveyCycle} />
    </AdminSurveyContent>
  );
};

export default AdminSurveyCycleSettingsPage;
