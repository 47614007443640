import { IQuestion } from '@shared/questions';
import humanparser from 'humanparser';
import Mustache from 'mustache';

export const renderReceiverQuestionText = (
  question: IQuestion,
  receiverFullname?: string,
) =>
  Mustache.render(
    question.text,
    receiverFullname ? humanparser.parseName(receiverFullname) : {},
  );
