import { UserMapItem } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import { UserAvatar } from '@web/components/UserAvatar';
import { Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

const AVATAR_SIZE = 24;

export const GoalAssignedAvatars: React.FC<{
  assignedUsers?: UserMapItem[];
  alignLeft?: boolean;
}> = ({ assignedUsers, alignLeft = false }) => {
  if (!assignedUsers || assignedUsers.length === 0) {
    return null;
  }

  const { isMobile } = useResponsive();
  const maxAvatars = isMobile ? 3 : 5;
  let subsetUsers: UserMapItem[];
  if (assignedUsers.length > maxAvatars) {
    subsetUsers = assignedUsers.slice(0, maxAvatars - 1);
  } else if (assignedUsers.length === maxAvatars) {
    subsetUsers = assignedUsers.slice(0, maxAvatars);
  } else {
    subsetUsers = assignedUsers;
  }
  const moreCount = assignedUsers.length - subsetUsers.length;
  const totalAvatars = Math.min(maxAvatars, assignedUsers.length);
  const computeRight = (index: number) =>
    (index + 1 - totalAvatars) * (AVATAR_SIZE / 2);
  const computeLeft = (index: number) => index * (AVATAR_SIZE / 2) * -1;

  return (
    <Tooltip
      trigger={isMobile ? 'click' : 'hover'}
      title={`Assigned to ${assignedUsers.map((user) => user.name).join(', ')}`}
    >
      <Container>
        {subsetUsers.map((user, index) => (
          <AvatarContainer
            key={user.token}
            style={
              alignLeft
                ? { left: computeLeft(index) }
                : { right: computeRight(index) }
            }
          >
            <UserAvatar user={user} size={AVATAR_SIZE} />
          </AvatarContainer>
        ))}
        {moreCount > 0 && (
          <MoreAssignedCircle
            style={
              alignLeft ? { left: computeLeft(subsetUsers.length) } : undefined
            }
          >
            +{moreCount}
          </MoreAssignedCircle>
        )}
      </Container>
    </Tooltip>
  );
};

const Container = styled.section`
  display: flex;
`;
const AvatarContainer = styled.section`
  position: relative;
  border: 1px solid white;
  width: ${AVATAR_SIZE + 2}px;
  height: ${AVATAR_SIZE + 2}px;
  border-radius: 50%;
`;
const MoreAssignedCircle = styled.span`
  display: block;
  position: relative;
  border: 1px solid white;
  width: ${AVATAR_SIZE + 2}px;
  height: ${AVATAR_SIZE + 2}px;
  border-radius: 50%;
  background: #666;
  color: white;
  line-height: ${AVATAR_SIZE}px;
  font-size: 10px;
  text-align: center;
  padding-right: 1px;
`;
