import { IFullImpactResponse } from '@shared/impacts';
import { IAgendaItem } from '@shared/one-on-one';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { Pane } from '@web/components/Pane';
import { JournalEntryCard } from '@web/journal/JournalEntryCard';
import * as React from 'react';

export const JournalEntryDetails: React.FC<{ agendaItem: IAgendaItem }> = ({
  agendaItem,
}) => {
  const journalEntryToken =
    agendaItem.journalEntries.length > 0
      ? agendaItem.journalEntries[0].token
      : null;
  const { data: impactResponse } = useApi<
    IFullImpactResponse,
    ServerResponseError
  >(journalEntryToken ? `/impacts/${journalEntryToken}/full` : null);

  return journalEntryToken ? (
    <Pane>
      {impactResponse ? (
        <JournalEntryCard
          impactEntry={impactResponse.impactEntry}
          canReact={impactResponse.permissions.canReact}
          includeLink
        />
      ) : null}
    </Pane>
  ) : null;
};
