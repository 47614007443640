import { updateGoal } from '@client/GoalsClient';
import { dateString } from '@shared/dateString';
import { Feature } from '@shared/features';
import { formatDate } from '@shared/formatDate';
import { GoalState, IGoal, UpdateGoalRequest } from '@shared/goals';
import { IImpact } from '@shared/impacts';
import { useResponsive } from '@web/app/responsive';
import { del, post } from '@web/common/api';
import { useFeature } from '@web/common/useFeature';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { SelectDate } from '@web/components/SelectDate';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header3, Text, TruncateText } from '@web/components/typography';
import { WidgetDate } from '@web/home/Widget';
import { Divider, message } from 'antd';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { GoalAssignedUsers } from './GoalAssignedUsers';
import { GoalFollowers } from './GoalFollowers';
import { GoalParents } from './GoalParents';
import { SelectState } from './SelectState';

export const GoalMetaPanel: React.FC<{
  goal: IGoal;
  entries: IImpact[];
  isReadonly: boolean;
  onChange: () => void;
}> = ({ goal, entries, isReadonly, onChange }) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { booleanValue: journalRelatedGoalsEnabled } = useFeature(
    Feature.JOURNAL_RELATED_GOALS,
  );

  const [dueDate, setDueDate] = React.useState<string>(null);
  const [state, setState] = React.useState<GoalState | null>(null);

  React.useEffect(() => {
    if (goal) {
      setDueDate(goal.dueDate ? dateString(goal.dueDate) : null);
      setState(goal.state);
    }
  }, [goal]);

  const patchGoal = async (attrs: UpdateGoalRequest) => {
    try {
      await updateGoal(attrs, goal.token);
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleArchiveGoal = async () => {
    try {
      await post(`/goals/${goal.token}/archive`);
      navigate(-1);
      void message.success('Goal has been archived');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleUnarchiveGoal = async () => {
    try {
      await del(`/goals/${goal.token}/archive`);
      void message.success('Goal has been unarchived');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column
      style={
        isMobile
          ? { paddingLeft: 11 }
          : {
              padding: 24,
              borderLeft: '1px solid #eee',
            }
      }
    >
      <SelectState
        state={state}
        onChange={(newState: GoalState) => {
          setState(newState);
          void patchGoal({
            state: newState,
          });
        }}
        disabled={isReadonly}
      />
      <Spacer size={12} />
      <Column gap={6}>
        <GoalAssignedUsers goal={goal} onChange={onChange} />
        <GoalFollowers goal={goal} onChange={onChange} />
        {goal.isPublic && <GoalParents goal={goal} onChange={onChange} />}
        <Row>
          <Label>Due Date</Label>
          {isReadonly ? (
            <Text>{dueDate ?? '-'}</Text>
          ) : (
            <Row style={{ width: 180, position: 'relative', left: -13 }}>
              <SelectDate
                value={dueDate}
                onChange={(newValue) => {
                  setDueDate(newValue);
                  void patchGoal({
                    dueDate: newValue
                      ? (`${newValue}T23:59:59.999Z` as any)
                      : null,
                  });
                }}
                bordered={false}
                style={{ cursor: 'pointer' }}
              />
            </Row>
          )}
        </Row>
        <Row>
          <Label>Created</Label>
          <Text>{formatDate(goal.createdDate, true)}</Text>
        </Row>
        <Row>
          <Label>Created by</Label>
          <UserMessage style={{ gap: 6 }} avatarSize={18} user={goal.author} />
        </Row>
      </Column>
      <Spacer size={18} />
      <Column gap={12}>
        {isReadonly ? null : goal.archivedDate ? (
          <>
            <Row>
              <Label>Archived</Label>
              <Text>{formatDate(goal.archivedDate, true)}</Text>
            </Row>
            <Spacer size={12} />
            <ConfirmButton
              onConfirm={handleUnarchiveGoal}
              description="Are you sure you wish to unarchive this goal?"
              style={{ width: 120 }}
            >
              Unarchive
            </ConfirmButton>
          </>
        ) : (
          <ConfirmButton
            onConfirm={handleArchiveGoal}
            description="Are you sure you wish to archive this goal?"
            style={{ width: 120 }}
          >
            Archive
          </ConfirmButton>
        )}
      </Column>
      {journalRelatedGoalsEnabled && <Divider />}
      {journalRelatedGoalsEnabled && (
        <Column gap={6}>
          <Header3>Public Journal Entries</Header3>
          <Column>
            {entries.length === 0 ? (
              <Text>No journal entries</Text>
            ) : (
              entries.map((entry) => (
                <Column key={entry.token}>
                  <Link to={`/journal/${entry.token}`}>
                    <JournalRow>
                      <TruncateText>{entry.title}</TruncateText>
                      <GrowingSpacer />
                      <WidgetDate>{formatDate(entry.date)}</WidgetDate>
                    </JournalRow>
                  </Link>
                </Column>
              ))
            )}
          </Column>
        </Column>
      )}
    </Column>
  );
};
const Label = styled(Text)`
  min-width: 120px;
`;

const JournalRow = styled(Row)`
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
`;
