import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { PageHeader } from '@web/components/PageHeader';
import { Spacer } from '@web/components/layout';
import { Skeleton } from 'antd';
import * as React from 'react';

import { FeedbackList } from './FeedbackList/FeedbackList';
import { useRecentFeedback } from './useRecentFeedback';

export const ViewReceivedFeedbackPage: React.FC = () => {
  const { feedbackResponse, impactEntryMap, isLoading } = useRecentFeedback();
  const { isMobile } = useResponsive();

  return (
    <PageContent>
      <PageHeader
        title="Feedback"
        mobileTitle="Feedback"
        description="View feedback you’ve received. Request feedback from your peers."
        secondaryActions={[
          {
            label: 'Give feedback',
            linkTo: '/feedback/give',
          },
          {
            label: 'Request feedback',
            linkTo: '/feedback/request',
          },
          {
            label: 'View feedback you gave',
            linkTo: '/feedback/given',
          },
        ]}
      />
      {isMobile && <Spacer />}
      {isLoading || !feedbackResponse ? (
        <Skeleton />
      ) : (
        <FeedbackList
          direction="Received"
          feedbackResponse={feedbackResponse}
          impactEntryMap={impactEntryMap}
        />
      )}
    </PageContent>
  );
};
