import { Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import * as React from 'react';

export const ValuePercentMetric: React.FC<{
  value: number;
  total: number;
}> = ({ value, total }) => {
  return (
    <Row gap={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
      <Header3>{value}</Header3>
      <Text>({total > 0 ? Math.round((100 * value) / total) : 0}%)</Text>
    </Row>
  );
};
