import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

/**
 * Go back to the last path in the browser history
 * @param defaultBackToPath Navigate to this path if there is nothing to go back to
 */
type BackOrGotoFunction = (defaultBackToPath?: string) => void;

export const useNavigateBack = (): BackOrGotoFunction => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const backToSearchParam = searchParams.get('backTo');
  const canGoBack = history.length > 1 && location.key !== 'default';

  return (defaultBackToPath?: string) => {
    if (canGoBack) {
      navigate(-1);
    } else if (defaultBackToPath) {
      navigate(defaultBackToPath);
    } else if (backToSearchParam) {
      navigate(backToSearchParam);
    }
  };
};
