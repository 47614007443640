import { useAuth } from '@web/auth/useAuth';
import * as React from 'react';
import styled from 'styled-components';

import { EnvironmentBanner } from './EnvironmentBanner';
import { ImpersonationBanner } from './ImpersonationBanner';
import { MobilePage } from './MobilePage';
import { Sidebar } from './Sidebar';
import { IF_MOBILE, useResponsive } from './responsive';

export interface IPageProps {
  children: React.ReactNode;
}

export const PageContent: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => (
  <PageContentContainer style={style}>{children}</PageContentContainer>
);

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 24px;

  ${IF_MOBILE} {
    padding: 0;
  }
`;

export const Page: React.FC<IPageProps> = ({ children }) => {
  const { isLoggedIn, isInternal } = useAuth();
  const { isMobile, isFullPageView } = useResponsive();

  if (isMobile) {
    return <MobilePage>{children}</MobilePage>;
  }

  return (
    <PageContainer>
      {isInternal && <EnvironmentBanner />}
      <Layout>
        <RightSide showingSidebar={isLoggedIn && !isFullPageView}>
          {children}
        </RightSide>
        <ImpersonationBanner />
      </Layout>
      {isLoggedIn && !isFullPageView && (
        <Sidebar top={0} width={SIDEBAR_WIDTH} />
      )}
    </PageContainer>
  );
};

export const SIDEBAR_WIDTH = 240;

const PageContainer = styled.div``;

const Layout = styled.div`
  display: flex;
  position: relative;

  > :first-child {
    min-width: ${SIDEBAR_WIDTH}px;
  }
`;

const RightSide = styled.section<{ showingSidebar: boolean }>`
  ${(props) =>
    props.showingSidebar
      ? `
    margin-left: ${SIDEBAR_WIDTH}px;
  `
      : 'margin-left: 0px;'}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--page-background);
  flex: 1;
`;
