import { IReviewCycle, ReviewCycleToken } from '@shared/review-cycles';
import { UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Spacer } from '@web/components/layout';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { FeedbackList } from './FeedbackList/FeedbackList';
import { useRecentFeedback } from './useRecentFeedback';

export const ViewReviewCycleFeedbackPage: React.FC = () => {
  const { user } = useAuth();
  const { reviewCycleToken, userToken } = useParams<{
    reviewCycleToken: ReviewCycleToken;
    userToken: UserToken;
  }>();
  const { feedbackResponse, impactEntryMap, isLoading } = useRecentFeedback(
    userToken,
    reviewCycleToken,
  );
  const { data: reviewCycle } = useApi<IReviewCycle>(
    reviewCycleToken ? `/review-cycles/${reviewCycleToken}` : null,
  );
  const { isMobile } = useResponsive();

  if (isLoading || !reviewCycle) {
    return (
      <PageContent>
        <PageHeader title="Feedback" navigateBack />
        <Skeleton />
      </PageContent>
    );
  }

  const receiver = userToken ? feedbackResponse?.userMap[userToken] : undefined;
  const isReceiver = receiver && !isManagerOf(receiver, user.token);

  return (
    <PageContent>
      <PageHeader
        title={isReceiver ? 'Feedback' : `Feedback for ${receiver?.name}`}
        description={reviewCycle?.name}
        navigateBack
      />
      {isMobile && <Spacer />}
      {isLoading || !feedbackResponse ? (
        <Skeleton />
      ) : (
        <FeedbackList
          direction="Received"
          feedbackResponse={feedbackResponse}
          impactEntryMap={impactEntryMap}
        />
      )}
    </PageContent>
  );
};
