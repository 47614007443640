import { DAY_IN_MILLISECONDS } from '@shared/constants';
import { formatDate } from '@shared/formatDate';
import { Small } from '@web/components/typography';
import { Tooltip } from 'antd';
import { format, formatDistance } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

interface IEditableEntity {
  createdDate: Date;
  updatedDate: Date;
}
export const EditedLabel: React.FC<{ entity: IEditableEntity }> = ({
  entity,
}) => {
  if (entity.createdDate === entity.updatedDate) {
    return null;
  }

  return (
    <Tooltip title={formatEditedDate(entity.updatedDate)}>
      <EditedLabelContainer>(edited)</EditedLabelContainer>
    </Tooltip>
  );
};
const formatEditedDate = (date: string | Date) => {
  const edited = new Date(date);
  const now = new Date();
  if (now.getTime() - edited.getTime() < DAY_IN_MILLISECONDS) {
    return formatDistance(edited, now, {
      addSuffix: true,
    });
  }

  return `${formatDate(edited)} at ${format(edited, 'h:mm a')}`;
};
const EditedLabelContainer = styled(Small)`
  color: #999;
  align-self: flex-start;
`;
