import { Feature } from '@shared/features';
import { UserMapItem } from '@shared/types';
import { useFeature } from '@web/common/useFeature';
import { Column, Grid } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Modal } from 'antd';
import React from 'react';

export const ConfirmAddUserModal: React.FC<{
  onCancel: () => void;
  onMoveTeam: () => void;
  onAddManager: () => void;
  user?: UserMapItem;
}> = ({ onMoveTeam, onAddManager, onCancel, user }) => {
  const { booleanValue: allowMultipleManagers } = useFeature(
    Feature.ALLOW_MULTIPLE_MANAGERS,
  );

  return (
    <Modal
      title={`${user.name} is already on a team`}
      open={true}
      onCancel={onCancel}
      width="500px"
      okText="Continue"
      footer={
        allowMultipleManagers ? (
          <Grid columns="1fr 1fr 1fr">
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onMoveTeam();
              }}
            >
              Move to this team
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onAddManager();
              }}
            >
              Add additional manager
            </Button>
          </Grid>
        ) : (
          <Grid columns="1fr 1fr">
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onMoveTeam();
              }}
            >
              Continue
            </Button>
          </Grid>
        )
      }
    >
      <Column gap={12}>
        {allowMultipleManagers ? (
          <Text>
            Would you like to move them to this team or would you like to add an
            additional manager?
          </Text>
        ) : (
          <Text>Would you like to move them to this team?</Text>
        )}
      </Column>
    </Modal>
  );
};
