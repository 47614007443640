import {
  DeleteOutlined,
  EditOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import { deactivateUser } from '@client/DeactivationClient';
import { Feature } from '@shared/features';
import { ISearchResults, IUser, UserMapItem } from '@shared/types';
import { otherManagerTokensOf, primaryManagerTokenOf } from '@shared/users';
import { useFeature } from '@web/common/useFeature';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/text';
import { EditUserButton, EditUserModal } from '@web/users/EditUserButton';
import { Button, Table, Tooltip, message } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AdminUsersRowActionsMenu } from './AdminUsersRowActionsMenu';
import { SelectManagerModal } from './SelectManagerModal';
import { SelectPrimaryManagerModal } from './SelectPrimaryManagerModal';

interface Props {
  searchResults: ISearchResults<IUser>;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onChange: () => void;
}

export const AdminUsersTable: React.FC<Props> = ({
  page,
  searchResults,
  pageSize,
  onPageChange,
  onChange,
}) => {
  const { confirm, contextHolder } = useModalConfirm();
  const { booleanValue: allowMultipleManagers } = useFeature(
    Feature.ALLOW_MULTIPLE_MANAGERS,
  );
  const { booleanValue: enableNewUserModel } = useFeature(
    Feature.ENABLE_NEW_USER_MODEL,
  );
  const [editUser, setEditUser] = React.useState<IUser>();
  const [editManagerUser, setEditManagerUser] = React.useState<IUser>();
  const [editPrimaryManagerUser, setEditPrimaryManagerUser] =
    React.useState<UserMapItem>();
  const {
    results: users,
    total,
    userMap,
  } = searchResults ?? { results: [], total: 0 };

  const columns: ColumnsType<IUser> = [
    {
      title: 'Person',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      render: (name, user) => {
        return (
          <Row gap={12}>
            <UserAvatar user={user} />
            <Column>
              <Row>
                <Text
                  style={{
                    whiteSpace: 'nowrap',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  {name}
                </Text>
                <Spacer size={6} />
                {!enableNewUserModel && (
                  <EditUserButton user={user} onChange={onChange} />
                )}
              </Row>
              <Text style={{ whiteSpace: 'nowrap' }}>{user.email}</Text>
            </Column>
          </Row>
        );
      },
    },
    {
      title: '',
      key: 'profile',
      align: 'left',
      width: '10%',
      render: (_, user) => {
        return (
          <Link to={`/profile/${user.token}`}>
            <Tooltip title={`View profile page`}>
              <Button type="text">
                <IdcardOutlined />
              </Button>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      title: allowMultipleManagers ? 'Primary Manager' : 'Manager',
      key: 'manager',
      width: allowMultipleManagers ? '20%' : '50%',
      render: (_, user) => {
        const managerToken = primaryManagerTokenOf(user);
        return (
          <Row gap={3}>
            {userMap[managerToken] && (
              <Text style={{ whiteSpace: 'nowrap' }}>
                {userMap[managerToken].name}
              </Text>
            )}
            {!userMap[managerToken] && (
              <Text style={{ whiteSpace: 'nowrap', color: '#ccc' }}>n/a</Text>
            )}
            {enableNewUserModel && (
              <Button
                size="small"
                type="text"
                onClick={() => {
                  setEditManagerUser(user);
                }}
              >
                <EditOutlined />
              </Button>
            )}
            {!enableNewUserModel && user.managerTokens?.length > 1 && (
              <Button
                size="small"
                type="text"
                onClick={() => {
                  setEditPrimaryManagerUser(user);
                }}
              >
                <EditOutlined />
              </Button>
            )}
          </Row>
        );
      },
    },
  ];

  if (allowMultipleManagers) {
    columns.push({
      title: 'Other Managers',
      key: 'manager',
      width: '40%',
      render: (_, user) => {
        const managerTokens = otherManagerTokensOf(user);
        return managerTokens?.length > 0 ? (
          <Text>
            {managerTokens.map((token) => userMap[token].name).join(', ')}
          </Text>
        ) : (
          ''
        );
      },
    });
  }

  columns.push({
    title: 'Actions',
    key: 'actions',
    align: 'center',
    render: (_, user) => {
      const handleDeactivate = async () => {
        const confirmed = await confirm(
          `This will remove ${user.name} from your organization entirely.`,
        );
        if (!confirmed) {
          return;
        }

        try {
          await deactivateUser(user.token);
          void message.success('User');
          onChange();
        } catch (error) {
          void message.error('Error');
        }
      };

      if (enableNewUserModel) {
        return (
          <AdminUsersRowActionsMenu
            user={user}
            onEditUser={() => {
              setEditUser(user);
            }}
            onAssignManager={() => {
              setEditManagerUser(user);
            }}
            onDeactivateUser={handleDeactivate}
          />
        );
      } else {
        return (
          <Tooltip title={`Deactivate this account`}>
            <Button type="text" onClick={handleDeactivate}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        );
      }
    },
  });

  const pagination: TablePaginationConfig = {
    total,
    onChange: onPageChange,
    current: page,
    pageSize,
    showSizeChanger: false,
    showTotal: (total) => `${total} users`,
  };

  return (
    <>
      <NoRowHoverTable
        rowKey="token"
        pagination={pagination}
        columns={columns}
        dataSource={users}
      />
      {editPrimaryManagerUser && (
        <SelectPrimaryManagerModal
          onCancel={() => {
            setEditPrimaryManagerUser(null);
          }}
          onSave={() => {
            setEditPrimaryManagerUser(null);
            onChange();
          }}
          userToken={editPrimaryManagerUser.token}
          managers={editPrimaryManagerUser.managerTokens.map(
            (token) => userMap[token],
          )}
        />
      )}
      {editManagerUser && (
        <SelectManagerModal
          onCancel={() => {
            setEditManagerUser(null);
          }}
          onSave={() => {
            onChange();
            setEditManagerUser(null);
          }}
          organizationToken={editManagerUser.organizationToken}
          user={editManagerUser}
          managers={editManagerUser?.managerTokens?.map(
            (token) => userMap[token],
          )}
          omitUserTokens={[editManagerUser.token]}
        />
      )}
      {editUser && (
        <EditUserModal
          open={true}
          user={editUser}
          onChange={() => {
            onChange();
            setEditUser(null);
          }}
          onCancel={() => {
            setEditUser(null);
          }}
        />
      )}

      {contextHolder}
    </>
  );
};

const NoRowHoverTable = styled(Table)`
  tbody tr:hover td {
    background: unset !important;
  }
`;
