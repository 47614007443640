/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CheckOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import Quill from 'quill';
import randomstring from 'randomstring';
import * as React from 'react';
import styled from 'styled-components';

import { Column, GrowingSpacer, Row } from '../../components/layout';
import { TypographyCss } from '../../components/typography';

interface Props {
  initialValue?: string;
  onSubmit: (html: string) => void;
  onTextChange?: (html: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  ref?: React.Ref<HTMLDivElement>;
  autoFocus?: boolean;
  onCancel?: () => void;
}

// eslint-disable-next-line react/display-name
export const InputNote: React.FC<Props> = React.forwardRef<
  HTMLDivElement,
  Props
>(
  (
    {
      initialValue,
      onSubmit,
      placeholder,
      style,
      onTextChange,
      autoFocus = false,
      onCancel,
    },
    ref,
  ) => {
    const [hasChanged, setHasChanged] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement>();
    React.useImperativeHandle(ref, () => containerRef.current);
    const elementId = React.useMemo(
      () => `quill_${randomstring.generate(8)}`,
      [],
    );
    const elementRef = React.useRef<HTMLDivElement>();
    const [quill, setQuill] = React.useState<Quill>(null);
    const handleSubmit = React.useMemo(() => {
      return () => {
        const html = getHtml(quill);
        if (html === '<p></p>') {
          return;
        }

        onSubmit(html);
        quill.deleteText(0, quill.getLength());
      };
    }, [quill]);
    React.useEffect(() => {
      if (elementRef.current) {
        const quill = new Quill(`#${elementId}`, {
          placeholder,
          modules: {
            keyboard: {
              bindings: {
                'enter submit': {
                  key: 'Enter',
                  shiftKey: false,
                  handler: () => {
                    const html = getHtml(quill);
                    if (html === '<p></p>') {
                      return;
                    }

                    onSubmit(html);
                    quill.deleteText(0, quill.getLength());
                  },
                },
              },
            },
          },
        });
        quill.on('text-change', () => {
          const newValue = getHtml(quill);
          setHasChanged(newValue !== initialValue);
          onTextChange?.(newValue);
        });
        if (autoFocus) {
          setTimeout(() => {
            quill.setSelection(quill.getLength(), 0);
          }, 0);
        }
        setQuill(quill);
      }
    }, [elementRef.current]);

    return (
      <InputHtmlContainer style={style} ref={containerRef}>
        <div
          id={elementId}
          ref={elementRef}
          spellCheck={false}
          dangerouslySetInnerHTML={
            initialValue ? { __html: initialValue } : undefined
          }
        ></div>
        <Row
          style={{ justifyContent: 'flex-end' }}
          onClick={() => {
            quill?.focus();
          }}
        >
          <GrowingSpacer />
          {onCancel &&
            (hasChanged ? (
              <Popconfirm
                title="Cancel changes"
                description="Are you sure you want to revert all changes?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  onCancel();
                }}
              >
                <Button type="text" size="small">
                  <CloseOutlined />
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type="text"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onCancel();
                }}
              >
                <CloseOutlined />
              </Button>
            ))}
          <Button
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              handleSubmit();
            }}
            size="small"
          >
            {onCancel ? <CheckOutlined /> : <SendOutlined />}
          </Button>
        </Row>
      </InputHtmlContainer>
    );
  },
);

const getHtml = (quill: Quill) => {
  const html = quill.editor.getHTML(0, quill.getLength());
  return html;
};
const InputHtmlContainer = styled(Column)`
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: var(--default-border-radius);
  width: 100%;
  cursor: text;
  word-wrap: break-word;

  .ql-container {
    ${TypographyCss.Text}

    .ql-editor {
      padding: 0 6px;
    }
    .ql-editor.ql-blank:before {
      color: #999;
      left: 6px;
      right: 6px;
      font-style: normal;
    }
  }
`;
