import { PlusOutlined } from '@ant-design/icons';
import { OrganizationToken } from '@shared/types';
import { Column } from '@web/components/layout';
import { Button, message } from 'antd';
import * as React from 'react';

import { AddChannelsModal, AddChannelsModalV2 } from './AddChannelsModal';

export const AddChannelsButton: React.FC<{
  organizationToken?: OrganizationToken;
  onAddChannels: (channelIds: string[]) => Promise<void>;
  onRefreshChannelList: () => Promise<void>;
  useNewModal: boolean;
}> = ({
  organizationToken,
  useNewModal,
  onAddChannels,
  onRefreshChannelList,
}) => {
  const [showAddChannelsModal, setShowAddChannelsModal] = React.useState(false);

  const handleAddChannels = async (channelIds: string[]) => {
    try {
      await onAddChannels(channelIds);
      setShowAddChannelsModal(false);
    } catch (error) {
      void message.error(channelIds);
    }
  };

  return (
    <Column>
      <Button
        type="primary"
        onClick={() => {
          setShowAddChannelsModal(true);
        }}
      >
        <PlusOutlined /> Add Channels
      </Button>
      {showAddChannelsModal &&
        (useNewModal ? (
          <AddChannelsModalV2
            organizationToken={organizationToken}
            onAdd={handleAddChannels}
            onRefresh={onRefreshChannelList}
            onCancel={() => {
              setShowAddChannelsModal(false);
            }}
          />
        ) : (
          <AddChannelsModal
            organizationToken={organizationToken}
            onAdd={handleAddChannels}
            onCancel={() => {
              setShowAddChannelsModal(false);
            }}
          />
        ))}
    </Column>
  );
};
