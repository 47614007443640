import { css } from 'styled-components';

import { IF_MOBILE, IF_NOT_MOBILE } from '../responsive';

export const ModalStyles = css`
  .ant-modal.flint-base-modal {
    .ant-modal-close-x {
      width: 60px;
      line-height: 60px;
      height: 60px;
    }

    .ant-modal-body {
      padding: 0px 24px;
    }

    .ant-modal-content {
      border-radius: var(--default-border-radius);

      ${IF_MOBILE} {
        border-radius: 0;
      }
    }

    .ant-modal-content {
      ${IF_MOBILE} {
        box-shadow: none;
      }
    }

    .ant-modal-footer .ant-btn {
      width: calc(50% - 4px);
      ${IF_NOT_MOBILE} {
        max-width: 160px;
      }
    }

    .ant-modal-footer {
      border-top: 0;

      ${IF_NOT_MOBILE} {
        padding-top: 0;
        justify-content: flex-end;
      }

      ${IF_MOBILE} {
        position: fixed;
        width: 100vw;
        bottom: 0;
      }
    }

    ${IF_MOBILE} {
      margin: 0;
      width: 100vw;
      max-width: unset;
      height: 100vh;
      top: 0;
      background: white;
    }

    .ant-modal-content {
      ${IF_MOBILE} {
        min-height: 100vh;
        padding-bottom: 80px;
      }
    }

    .ant-modal-header {
      border: 0;
      padding: 18px 24px;
      background: unset !important;
      border-radius: var(--default-border-radius) var(--default-border-radius) 0
        0;

      ${IF_MOBILE} {
        border-radius: 0;
      }
    }

    .ant-modal-title {
      font-size: 18px;
      font-weight: 400;
    }

    .ant-modal-footer {
      display: flex;
      padding: 18px 24px;

      button {
        width: 160px;

        ${IF_MOBILE} {
          margin: 0;
          height: 60px;
          width: calc(50%);
        }
      }

      ${IF_MOBILE} {
        padding-top: 10px;
        background: white;
      }
    }
  }

  .ant-modal-confirm-btns {
    display: flex;
    gap: 10px;

    .ant-btn {
      flex: 1;
    }
  }

  .ant-tour {
    ${IF_MOBILE} {
      max-width: 340px;
      top: 100px;
      left: calc(50% - 170px);
    }

    .ant-tour-close-x {
      width: 60px;
      line-height: 60px;
      height: 60px;
    }

    .ant-tour-inner {
      border-radius: var(--default-border-radius);

      .ant-tour-description {
        padding: 0px 24px;
      }

      .ant-tour-footer .ant-btn {
        height: 32px;
      }

      .ant-tour-footer {
        border-top: 0;
      }

      .ant-tour-header {
        border: 0;
        padding: 18px 24px;
        background: unset !important;
        border-radius: var(--default-border-radius) var(--default-border-radius)
          0 0;
      }

      .ant-tour-title {
        font-size: 18px;
        font-weight: 400;
      }

      .ant-tour-footer {
        display: flex;
        padding: 18px 24px;

        button {
          width: 160px;
        }
      }
    }
  }
`;
