import { ErrorPageContent } from '@web/components/ErrorPageContent';
import * as React from 'react';

const NetworkErrorPage: React.FC = () => (
  <ErrorPageContent
    statusCode={404}
    title={'Network failure'}
    subTitle={'We were unable to reach our servers - please try again.'}
  />
);

export default NetworkErrorPage;
