import { formatDate } from '@shared/formatDate';
import { IReflection, ReflectionState } from '@shared/reflections';
import { IF_MOBILE } from '@web/app/responsive';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReflectionSharedDate } from './ReflectionSharedDate';

export interface ReflectionItemProps {
  reflection: IReflection;
}

export const ReflectionItem: React.FC<ReflectionItemProps> = ({
  reflection,
}) => {
  const startDate = formatDate(reflection.startDate);
  const endDate = formatDate(reflection.endDate);
  const description = `On work from ${startDate} to ${endDate}`;
  return (
    <ReflectionLink
      to={
        reflection.state === ReflectionState.SHARED
          ? `/reflections/${reflection.token}`
          : `/reflections/${reflection.token}/edit`
      }
    >
      <Row style={{ alignItems: 'flex-start', gap: 24 }}>
        <Header3>{reflection.title ? reflection.title : 'No Title'}</Header3>
        <GrowingSpacer min={10} />
        <ReflectionSharedDate reflection={reflection} />
      </Row>
      <ReflectionMetaInfo>
        <Column style={{ flexGrow: 1 }}>
          <Text>{description}</Text>
        </Column>
      </ReflectionMetaInfo>
    </ReflectionLink>
  );
};

const ReflectionLink = styled(Link)`
  padding: 24px;
  border-top: 1px solid #eee;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;

  ${IF_MOBILE} {
    margin: 0 -20px;
    padding: 20px;
  }

  h5 {
    margin: 0;
  }

  &:hover {
    background: var(--hover-background);
  }

  &:first-child {
    border-top: 0;
  }
`;
const ReflectionMetaInfo = styled(Row)`
  gap: 6px;
  width: 100%;
  justify-content: flex-end;

  ${IF_MOBILE} {
    flex-direction: column;
    align-items: stretch;
  }
`;
