import styled from 'styled-components';

export const OpenRequestsLabel = styled.label`
  background-color: var(--ant-primary-3);
  font-size: 11px;
  color: white;
  line-height: 17px;
  border-radius: 9px;
  padding: 0 9px;
  white-space: nowrap;
`;
