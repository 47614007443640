import { TabLink, Tabs } from '@web/components/Tabs';
import * as React from 'react';

export const GithubTabs: React.FC = () => (
  <Tabs>
    <TabLink to="../feature">Feature</TabLink>
    <TabLink to="../members">Members</TabLink>
    <TabLink to="../pulls">Pull Requests</TabLink>
  </Tabs>
);
