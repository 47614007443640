import { IReviewCycleParticipantSummary } from '@shared/review-cycles';
import { IUser } from '@shared/types';
import { useApi } from '@web/common/useApi';
import { FullWidthDivider } from '@web/components/Pane';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header2 } from '@web/components/typography';
import { IndividualReviewCycleStatus } from '@web/review-cycles/IndividualReviewCycleStatus';
import { Button } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const ReviewCycleWidget: React.FC<{ user: IUser }> = ({ user }) => {
  const { data: reviewCycleSummary } = useApi<
    IReviewCycleParticipantSummary | false
  >(`/review-cycles/latest?userToken=${user.token}`);

  if (!reviewCycleSummary) {
    return null;
  }

  return (
    <Column>
      <FullWidthDivider />
      <Row>
        <Header2>{reviewCycleSummary.reviewCycle.name}</Header2>
        <GrowingSpacer min={12} />
        {reviewCycleSummary.reviewCycle?.token && (
          <Link to={`/cycles`}>
            <Button>View all</Button>
          </Link>
        )}
      </Row>
      <Spacer size={6} />
      <IndividualReviewCycleStatus reviewCycleSummary={reviewCycleSummary} />
    </Column>
  );
};
