import { QuestionToken } from '@shared/questions';
import {
  FeedbackRequestToken,
  IDraftResponse,
  IFeedbackRequest,
} from '@shared/types';
import { get, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useGetRequest(feedbackRequestToken: FeedbackRequestToken) {
  const url = stringifyUrl({
    url: `/feedback_requests/${feedbackRequestToken}`,
    query: {},
  });

  return useApi<IFeedbackRequest>(url);
}

export function getRequest(feedbackRequestToken: FeedbackRequestToken) {
  const url = stringifyUrl({
    url: `/feedback_requests/${feedbackRequestToken}`,
    query: {},
  });

  return get<IFeedbackRequest>(url);
}

export function useDraftResponses(feedbackRequestToken: FeedbackRequestToken) {
  const url = stringifyUrl({
    url: `/feedback_requests/${feedbackRequestToken}/draft_responses`,
    query: {},
  });

  return useApi<IDraftResponse[]>(url);
}

export function draftResponses(feedbackRequestToken: FeedbackRequestToken) {
  const url = stringifyUrl({
    url: `/feedback_requests/${feedbackRequestToken}/draft_responses`,
    query: {},
  });

  return get<IDraftResponse[]>(url);
}

export function saveDraftResponse(
  feedbackRequestToken: FeedbackRequestToken,
  questionToken: QuestionToken,
  text?: string,
  rating?: number,
) {
  return put<{
    questionToken: `q_${string}`;
    text: string;
    rating: number;
  }>(`/feedback_requests/${feedbackRequestToken}/draft_responses`, {
    questionToken: questionToken,
    text: text,
    rating: rating,
  });
}
