import { IImpact } from '@shared/impacts';
import { useAuth } from '@web/auth/useAuth';
import { Markdown } from '@web/components/Markdown';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import { ReactionsSummary } from '@web/reactions/ReactionSummary';
import { Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { EditedLabel } from '../components/EditedLabel';
import { ImpactEntryCollaborators } from './ImpactEntryCollaborators';

interface Props {
  impactEntry: IImpact;
  canReact: boolean;
  includeLink?: boolean;
}

export const JournalEntryCard: React.FC<Props> = ({
  impactEntry,
  canReact,
  includeLink,
}) => {
  const { user } = useAuth();

  if (!impactEntry) {
    return <Skeleton avatar />;
  }

  const isAuthor = user.token === impactEntry.userToken;

  return (
    <div>
      <UserMessage user={impactEntry.user} date={impactEntry.date} large />
      <Spacer size={10} />
      <Row style={{ alignItems: 'flex-start', gap: 10 }}>
        {includeLink ? (
          <Link
            to={
              isAuthor
                ? `/journal/${impactEntry.token}`
                : `/team/${impactEntry.userToken}/journal/${impactEntry.token}`
            }
          >
            <Header3>{impactEntry.title}</Header3>
          </Link>
        ) : (
          <Header3>{impactEntry.title}</Header3>
        )}
        <GrowingSpacer />
        <ImpactEntryCollaborators collaborators={impactEntry.collaborators} />
      </Row>
      <Spacer size={10} />
      {impactEntry.descriptionMarkdown &&
        !EMPTY_MARKDOWN_VALUES.includes(impactEntry.descriptionMarkdown) && (
          <Column style={{ maxWidth: 824 }}>
            <Markdown value={impactEntry.descriptionMarkdown} />
            <EditedLabel entity={impactEntry} />
            <Spacer size={10} />
          </Column>
        )}
      <ReactionsSummary entityToken={impactEntry.token} readonly={!canReact} />
    </div>
  );
};

const EMPTY_MARKDOWN_VALUES = ['&#x20;', '', ' '];
