import { TypographyColors, TypographyCss } from '@web/components/typography';
import { css } from 'styled-components';

export const MdxEditorStyles = css`
  .mdxeditor,
  .mdxeditor-readonly {
    display: flex;
    flex-direction: column;

    [role='toolbar'] {
      display: flex;
      gap: 6px;
      border-radius: var(--default-border-radius) var(--default-border-radius) 0
        0;
      border: 1px solid #d9d9d9;
      border-bottom: 0;
      background-color: unset;

      & > div {
        display: flex;
        gap: 3px;
      }

      [role='group'] {
        display: flex;
        gap: 3px;
      }

      button {
        appearance: none;
        border: 0;
        background: none;
        border-radius: var(--default-border-radius);
        color: ${TypographyColors.text};
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }

        &:hover {
          background: #eee;
        }
      }
    }

    [role='textbox'] {
      border-radius: 0 0 var(--default-border-radius)
        var(--default-border-radius);
      border: 1px solid #d9d9d9;
    }

    .cm-sourceView {
      border-radius: 0 0 var(--default-border-radius)
        var(--default-border-radius);
      border: 1px solid #d9d9d9;

      [role='textbox'] {
        border: 0;
      }
    }
  }

  .mdxeditor-readonly {
    [role='textbox'] {
      border: 0;
      padding: 0;
    }

    [data-editor-block-type='image'] {
      button {
        display: none;
      }
    }

    .mdx-editor-content {
      > p:last-child {
        br:first-child {
          display: none;
        }
      }
    }
  }

  .mdx-editor-content {
    ${TypographyCss.Text}

    a {
      color: var(--primary-color);
      text-decoration: underline;
    }

    ol,
    ul {
      padding-inline-start: 24px;

      li {
        margin: 0;
      }
    }

    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 1em;
      margin-top: 5px;
      padding-left: 16px;
    }

    pre > code {
      display: block;
      padding: 12px;
    }
  }
`;
