import { PlusOutlined } from '@ant-design/icons';
import {
  cloneQuestion,
  deleteQuestion,
  deleteSurveySection,
} from '@client/SurveyCyclesClient';
import { mapByToken } from '@shared/mapByToken';
import { QuestionToken, isQuestionToken } from '@shared/questions';
import {
  ISurveyCycle,
  SurveyCycleToken,
  SurveySectionType,
  SurveyTypeLabels,
  isSurveySectionType,
} from '@shared/surveys';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { useApi } from '@web/common/useApi';
import { GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Skeleton, message } from 'antd';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { EditSectionModal } from '../questions/EditSectionModal';
import { SectionsList } from '../questions/SectionsList';
import { AdminSurveyContent } from './AdminSurveyContent';

export const AdminSurveyCycleSectionsPage: React.FC = () => {
  const { surveyCycleToken } = useParams<{
    surveyCycleToken?: SurveyCycleToken;
  }>();
  const { data: surveyCycle, mutate: reloadSurvey } = useApi<ISurveyCycle>(
    `/survey-cycles/${surveyCycleToken}`,
  );
  const [showAddSection, setShowAddSection] = React.useState(false);
  const [editingSection, setEditingSection] = React.useState<
    QuestionToken | SurveySectionType | undefined
  >(undefined);

  const handleQuestionUpdated = () => {
    setShowAddSection(false);
    setEditingSection(undefined);
    void reloadSurvey();
  };

  const handleQuestionCancel = () => {
    setShowAddSection(false);
    setEditingSection(undefined);
  };

  const handleEditSection = (section: QuestionToken | SurveySectionType) => {
    setEditingSection(section);
  };

  const handleDeleteQuestion = async (questionToken: QuestionToken) => {
    if (!surveyCycle) {
      return;
    }

    try {
      await deleteQuestion(surveyCycle.token, questionToken);
      void reloadSurvey();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleCloneQuestion = async (questionToken: QuestionToken) => {
    if (!surveyCycle) {
      return;
    }

    try {
      await cloneQuestion(surveyCycle.token, questionToken);
      void message.success('Success');
      void reloadSurvey();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleDeleteReviewSection = async (
    surveySectionType: SurveySectionType,
  ) => {
    if (!surveyCycle) {
      return;
    }
    try {
      await deleteSurveySection(surveyCycle.token, surveySectionType);
      void message.success('Success');
      void reloadSurvey();
    } catch (error) {
      void message.error('Error');
    }
  };

  if (!surveyCycle) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const questionMap = mapByToken(surveyCycle?.questions);
  const existingSections =
    surveyCycle.sortedSections.filter(isSurveySectionType);
  return (
    <AdminSurveyContent surveyCycle={surveyCycle} onChange={reloadSurvey}>
      {!surveyCycle.startedDate && (
        <Row style={{ marginBottom: 12 }}>
          {surveyCycle.questions?.length > 0 ? (
            <Text>
              {capitalize(SurveyTypeLabels[surveyCycle.type])} sections:
            </Text>
          ) : (
            <Text>No sections have been added</Text>
          )}
          <GrowingSpacer />
          <Button
            onClick={() => {
              setShowAddSection(true);
            }}
          >
            <PlusOutlined /> Add section
          </Button>
        </Row>
      )}
      <SectionsList
        surveyCycle={surveyCycle}
        onEdit={handleEditSection}
        onDelete={(section) => {
          if (isQuestionToken(section)) {
            void handleDeleteQuestion(section);
          } else {
            void handleDeleteReviewSection(section);
          }
        }}
        onClone={handleCloneQuestion}
        readonly={!!surveyCycle.startedDate}
      />
      {(showAddSection || !!editingSection) && (
        <EditSectionModal
          surveyCycle={surveyCycle}
          onUpdate={handleQuestionUpdated}
          onCancel={handleQuestionCancel}
          open={true}
          question={
            isQuestionToken(editingSection)
              ? questionMap.get(editingSection)
              : undefined
          }
          isNew={showAddSection}
          readonly={
            !!surveyCycle.startedDate || isSurveySectionType(editingSection)
          }
          section={editingSection}
          blacklistSectionTypes={existingSections}
        />
      )}
    </AdminSurveyContent>
  );
};
