import { validateEmail } from '@shared/validation/validateEmail';
import { Logo } from '@web/app/Logo/Logo';
import { IF_MOBILE, useResponsive } from '@web/app/responsive';
import { post } from '@web/common/api';
import { Pane } from '@web/components/Pane';
import { Center, Column, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Divider, Input, Typography, message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { GoogleSignInButton, MicrosoftSignInButton } from './SignInButtons';
import { useAuth } from './useAuth';

export const LoginPage: React.FC = () => {
  const [email, setEmail] = React.useState(null);
  const [showLoginCode, setShowLoginCode] = React.useState(false);

  const { isMobile } = useResponsive();

  const handleEmailSignIn = (email: string) => {
    setEmail(email);
    setShowLoginCode(true);
  };
  const handleGoBack = () => {
    setShowLoginCode(false);
  };

  return (
    <LoginPageContainer>
      <Spacer size={!isMobile ? 100 : 0} />
      <Center>
        <LoginPagePane>
          {showLoginCode ? (
            <LoginCodeForm email={email} onGoBack={handleGoBack} />
          ) : (
            <LoginForm
              email={email}
              setEmail={setEmail}
              onEmailSignIn={handleEmailSignIn}
            />
          )}
        </LoginPagePane>
      </Center>
    </LoginPageContainer>
  );
};

const LoginForm: React.FC<{
  onEmailSignIn: (email: string) => void;
  email: string;
  setEmail: (email: string) => void;
}> = ({ onEmailSignIn, email, setEmail }) => {
  const [invalidEmail, setInvalidEmail] = React.useState<string>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('invalid_email')) {
      setInvalidEmail(queryParams.get('invalid_email'));
      navigate('/auth/login', { replace: true });
    }
  }, []);

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      await post(`/auth/loginCode`, { email });
      onEmailSignIn(email);
    } catch (error) {
      void message.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };
  const isValidEmail = validateEmail(email);
  const loginCodesEnabled = process.env.LOGIN_CODES_ENABLED === 'true';
  const apiRoot = process.env.API_ROOT ?? '';

  return (
    <LoginFormContainer>
      <Logo />
      <Spacer size={20} />
      <Typography.Title level={4}>Welcome</Typography.Title>
      <Column gap={12} style={{ alignItems: 'center' }}>
        {invalidEmail && (
          <>
            <Typography.Text type="danger" style={{ textAlign: 'center' }}>
              The email address {'"'}
              {invalidEmail}
              {'"'} does not belong to a Flint user. Please try another email.
            </Typography.Text>
          </>
        )}
        <a href={`${apiRoot}/api/auth/google`}>
          <GoogleSignInButton />
        </a>
        <a href={`${apiRoot}/api/auth/microsoft`}>
          <MicrosoftSignInButton />
        </a>
      </Column>
      {loginCodesEnabled && (
        <>
          <Spacer size={10} />
          <Divider>OR</Divider>
          <Spacer size={10} />
          <Typography.Text>Sign in with your email address</Typography.Text>
          <Spacer size={20} />
          <Input
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value.trim())}
            placeholder="Email address"
            autoFocus
            disabled={isLoading}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && isValidEmail) {
                void handleContinue();
              }
            }}
          />
          <Spacer size={10} />
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValidEmail}
            size="large"
            style={{ width: '100%' }}
            onClick={handleContinue}
            loading={isLoading}
          >
            Continue
          </Button>
        </>
      )}
      <Spacer size={20} />
    </LoginFormContainer>
  );
};

const LoginCodeForm: React.FC<{
  email: string;
  onGoBack: () => void;
}> = ({ email, onGoBack }) => {
  const [code, setCode] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { loginWithCode } = useAuth();

  const handleContinue = async () => {
    setError(false);
    setIsLoading(true);
    try {
      await loginWithCode(email, code);
      window.location.replace('/redirect');
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleGoBack = () => {
    onGoBack();
  };
  const handleCodeChanged = (newCode: string) => {
    if (!newCode) {
      setCode('');
    } else if (/^[0-9]+$/.test(newCode) && newCode.length < 7) {
      setCode(newCode);
    }
  };

  const isCodeValid = code?.length > 0;

  return (
    <LoginFormContainer>
      <Logo />
      <Spacer size={20} />
      <Typography.Title level={4}>Check your email</Typography.Title>
      <Typography.Text>
        Enter the code sent to <strong>{email}</strong>
      </Typography.Text>
      <Spacer size={20} />
      {error && (
        <>
          <Typography.Text type="danger" style={{ textAlign: 'center' }}>
            Code entered is not valid. Please try again.
          </Typography.Text>
          <Spacer size={20} />
        </>
      )}
      <Text style={{ alignSelf: 'flex-start' }}>Verification code</Text>
      <Spacer size={6} />
      <Input
        value={code}
        onChange={(e) => handleCodeChanged(e.currentTarget.value)}
        autoFocus
        onKeyDown={(e) => {
          if (e.key === 'Enter' && isCodeValid) {
            void handleContinue();
          }
        }}
      />
      <Spacer size={12} />
      <Button
        type="primary"
        htmlType="submit"
        disabled={!isCodeValid}
        size="large"
        style={{ width: '100%' }}
        onClick={handleContinue}
        loading={isLoading}
      >
        Sign In
      </Button>
      <Spacer size={12} />
      <Button size="large" style={{ width: '100%' }} onClick={handleGoBack}>
        Go Back
      </Button>
      <Spacer size={20} />
    </LoginFormContainer>
  );
};

const LoginPageContainer = styled.div`
  background: #444;
  height: 100vh;
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;

  ${IF_MOBILE} {
    background: white;
  }
`;

const LoginPagePane = styled(Pane)`
  width: 400px;
  padding: 30px;
  box-shadow: 0px 10px 30px rgb(0 0 0);

  ${IF_MOBILE} {
    box-shadow: none;
    border: 0;
  }
`;

const LoginFormContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
