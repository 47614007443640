import { PlusOutlined, TrophyOutlined } from '@ant-design/icons';
import {
  addListGoal,
  addSupportingGoal,
  removeListGoal,
  updateGoal,
  useFindOneGoal,
} from '@client/GoalsClient';
import { usePublicEntriesForGoal } from '@client/ImpactsClient';
import { GoalToken, IGoal, IGoalList } from '@shared/goals';
import { UserRole, UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { IF_MOBILE, IF_NOT_MOBILE, useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { CommentsList } from '@web/comments/CommentsList';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { BackButton } from '@web/components/BackButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { StopPropagation } from '@web/components/StopPropagation';
import { Column, Row, Spacer } from '@web/components/layout';
import { Button, Result, Skeleton, message } from 'antd';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { EditGoal } from './EditGoal';
import { GoalInfo } from './GoalInfo';
import { GoalMetaPanel } from './GoalMetaPanel';
import { SectionHeader } from './SectionHeader';
import { SelectGoalListModal } from './SelectGoalListModal';
import { ViewGoals } from './ViewGoals';
import { AddGoalDropdown } from './lists/AddGoalDropdown';
import { AddGoalListDropdown } from './lists/AddGoalListDropdown';
import { AddGoalListModal } from './lists/AddGoalListModal';
import { AddGoalToListModal } from './lists/AddGoalToListModal';
import { ViewGoalLists } from './lists/ViewGoalLists';

export const GoalPage: React.FC = () => {
  const { confirm, contextHolder } = useModalConfirm();
  const { isMobile } = useResponsive();
  const { goalToken, userToken } = useParams<{
    userToken: UserToken;
    goalToken: GoalToken;
  }>();
  const { user } = useAuth();
  const { data, mutate: reloadGoal, error } = useFindOneGoal(goalToken);
  const { data: entries, error: entriesError } =
    usePublicEntriesForGoal(goalToken);
  const [editing, setEditing] = React.useState(false);
  const navigate = useNavigate();
  const [showAddExistingGoalList, setShowAddExistingGoalList] =
    React.useState(false);
  const [showAddGoal, setShowAddGoal] = React.useState(false);
  const [showAddGoalList, setShowAddGoalList] = React.useState(false);

  if ((!data?.goal && !error) || (!entries && !entriesError)) {
    return (
      <PageContent>
        <Column gap={12}>
          <BackButton
            defaultBackTo={userToken ? `/team/${userToken}/goals` : `/goals`}
          />
          <Pane>
            <Skeleton />
          </Pane>
        </Column>
      </PageContent>
    );
  } else if (error) {
    return (
      <PageContent>
        <Column gap={12}>
          <BackButton
            defaultBackTo={userToken ? `/team/${userToken}/goals` : `/goals`}
          />
          <Pane>
            <Result
              status={404}
              title={'Cannot view goal'}
              subTitle={
                'Either this goal does not exist or you do not have access to it'
              }
              extra={
                <Link to="/goals">
                  <Button>
                    <TrophyOutlined /> Return to Goals
                  </Button>
                </Link>
              }
            />
          </Pane>
        </Column>
      </PageContent>
    );
  } else if (entriesError) {
    return (
      <PageContent>
        <Column gap={12}>
          <BackButton
            defaultBackTo={userToken ? `/team/${userToken}/goals` : `/goals`}
          />
          <Pane>
            <Result
              status={404}
              title={'Cannot view goal'}
              subTitle={'Something went wrong'}
              extra={
                <Link to="/goals">
                  <Button>
                    <TrophyOutlined /> Return to Goals
                  </Button>
                </Link>
              }
            />
          </Pane>
        </Column>
      </PageContent>
    );
  }

  const isHrAdmin = user.role === UserRole.HR_ADMIN;
  const isManager = isManagerOf(data.goal.user, user.token);
  const isOwner = data.goal.user.token === user.token;
  const isReadonly =
    !data.goal.isPublic && !isHrAdmin && !isManager && !isOwner;

  const handleSaved = async () => {
    void reloadGoal();
    setEditing(false);
  };
  const commentsList = (
    <>
      <SectionHeader>Comments</SectionHeader>
      <CommentsList
        entityToken={goalToken}
        alwaysShowNewComment
        readonly={isReadonly}
      />
    </>
  );
  const metaPanel = (
    <GoalMetaPanel
      goal={data.goal}
      entries={entries}
      onChange={reloadGoal}
      isReadonly={isReadonly}
    />
  );
  const hasSupportingGoals = data.goal.supporting?.length > 0;
  const hasGoalLists = data.goal.goalLists?.length > 0;
  const handleAddGoalList = async (goalList: IGoalList) => {
    try {
      await addListGoal(goalList.token, data.goal.token);
      setShowAddExistingGoalList(false);
      void reloadGoal();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleRemoveGoalList = async (goalList: IGoalList) => {
    const confirmed = await confirm(
      'Individuals following this list will no longer receive updates for this goal. Do you wish to continue?',
    );
    if (!confirmed) {
      return;
    }

    try {
      await removeListGoal(goalList.token, data.goal.token);
      setShowAddExistingGoalList(false);
      void reloadGoal();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleAddGoal = async (supportingGoalToken: GoalToken) => {
    try {
      await addSupportingGoal(data.goal.token, supportingGoalToken);
      setShowAddGoal(false);
      void reloadGoal();
    } catch (Error) {
      void message.error('Error');
    }
  };

  const handleRemoveGoal = async (supportingGoal: IGoal) => {
    const confirmed = await confirm(
      'This will remove this as a supporting goal. Do you wish to continue?',
    );
    if (!confirmed) {
      return;
    }

    try {
      await updateGoal({ parent: null }, supportingGoal.token);
      void reloadGoal();
    } catch (Error) {
      void message.error('Error');
    }
  };

  const handleGoalListAdded = () => {
    void reloadGoal();
    setShowAddGoalList(false);
  };

  return (
    <PageContent key={`goal-page-${goalToken}`}>
      <Column gap={12}>
        {isMobile ? (
          <PageHeader navigateBack mobileTitle="Goal" />
        ) : (
          <BackButton
            defaultBackTo={userToken ? `/team/${userToken}/goals` : `/goals`}
          />
        )}
        <Pane>
          <FormLayout>
            <Column gap={24}>
              {editing ? (
                <EditGoal
                  goal={data.goal}
                  onSave={handleSaved}
                  onCancel={() => {
                    setEditing(false);
                  }}
                />
              ) : (
                <GoalInfo
                  goal={data.goal}
                  onEdit={() => {
                    setEditing(true);
                  }}
                  isReadonly={isReadonly}
                />
              )}
              {isMobile && metaPanel}
              {data.goal.isPublic && (!hasSupportingGoals || !hasGoalLists) ? (
                <Row gap={6}>
                  {!hasSupportingGoals && (
                    <AddGoalDropdown
                      onCreateNew={() => {
                        navigate(`/goals/new?parent=${data.goal.token}`);
                      }}
                      onAddExisting={() => {
                        setShowAddGoal(true);
                      }}
                    >
                      <Button type="text">
                        <PlusOutlined /> Add supporting goal
                      </Button>
                    </AddGoalDropdown>
                  )}
                  {!hasGoalLists && (
                    <AddGoalListDropdown
                      onAddExisting={() => {
                        setShowAddExistingGoalList(true);
                      }}
                      onCreateNew={() => {
                        setShowAddGoalList(true);
                      }}
                    >
                      <Button type="text">
                        <PlusOutlined /> Add to list
                      </Button>
                    </AddGoalListDropdown>
                  )}
                </Row>
              ) : null}
              {hasSupportingGoals && (
                <Column gap={12} style={{ maxWidth: 800 }}>
                  <ViewGoals
                    goals={data.goal.supporting}
                    title={'Supporting Goals'}
                    extra={
                      <StopPropagation>
                        <AddGoalDropdown
                          onCreateNew={() => {
                            navigate(`/goals/new?parent=${data.goal.token}`);
                          }}
                          onAddExisting={() => {
                            setShowAddGoal(true);
                          }}
                        >
                          <Button size="small" type="text">
                            <PlusOutlined
                              style={{ position: 'relative', top: -1 }}
                            />
                            Add
                          </Button>
                        </AddGoalDropdown>
                      </StopPropagation>
                    }
                    onRemove={handleRemoveGoal}
                  />
                </Column>
              )}
              {hasGoalLists && (
                <Column gap={12} style={{ maxWidth: 800 }}>
                  <ViewGoalLists
                    title="Lists"
                    goalLists={data.goal.goalLists}
                    onRemove={handleRemoveGoalList}
                    extra={
                      <StopPropagation>
                        <AddGoalListDropdown
                          onAddExisting={() => {
                            setShowAddExistingGoalList(true);
                          }}
                          onCreateNew={() => {
                            setShowAddGoalList(true);
                          }}
                        >
                          <Button size="small" type="text">
                            <PlusOutlined
                              style={{ position: 'relative', top: -1 }}
                            />
                            Add
                          </Button>
                        </AddGoalListDropdown>
                      </StopPropagation>
                    }
                  />
                </Column>
              )}
              {!isMobile && (
                <Column gap={12} style={{ maxWidth: 800 }}>
                  {commentsList}
                </Column>
              )}
            </Column>
            {!isMobile && <Column>{metaPanel}</Column>}
            {isMobile && <Column gap={12}>{commentsList}</Column>}
          </FormLayout>
        </Pane>
      </Column>
      {isMobile && <Spacer />}
      {showAddExistingGoalList && (
        <SelectGoalListModal
          title="Add goal to List"
          okText="Add"
          onCancel={() => {
            setShowAddExistingGoalList(false);
          }}
          onSave={handleAddGoalList}
          omitGoalLists={data.goal.goalLists.map((gl) => gl.token)}
        />
      )}
      {showAddGoal && (
        <AddGoalToListModal
          onCancel={() => {
            setShowAddGoal(false);
          }}
          onSave={handleAddGoal}
          omitGoals={data.goal.supporting.map((goal) => goal.token)}
        />
      )}
      {showAddGoalList && (
        <AddGoalListModal
          onAdd={handleGoalListAdded}
          onCancel={() => {
            setShowAddGoalList(false);
          }}
          goals={[goalToken]}
        />
      )}
      {contextHolder}
    </PageContent>
  );
};

const FormLayout = styled.form`
  display: flex;
  gap: 24px;
  cursor: text;

  ${IF_NOT_MOBILE} {
    > :nth-child(1) {
      flex: 1;
      max-width: 66%;
    }
    > :nth-child(2) {
      width: 34%;
      max-width: 34%;
      min-width: 380px;
    }
  }

  ${IF_MOBILE} {
    flex-direction: column;
  }
`;
