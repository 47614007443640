import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column } from '@web/components/layout';
import * as React from 'react';

import { AdminTabs } from './AdminTabs';

export const AdminPageContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <PageContent>
      <PageHeader title="Admin" />
      <AdminTabs />
      <Pane style={{ borderTopLeftRadius: 0 }}>
        <Column gap={24}>{children}</Column>
      </Pane>
    </PageContent>
  );
};
