import { ShopOutlined } from '@ant-design/icons';
import { IOrganization, IUser, isOrganizationToken } from '@shared/types';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import styled from 'styled-components';

export type Result = IUser | IOrganization;

interface Props {
  results: Result[];
  onSelect: (result: Result) => void;
  onPageChange: (page: number) => void;
  page: number;
}

export function isOrganization(result: Result): result is IOrganization {
  return isOrganizationToken(result.token);
}

export const QuickSearchResults: React.FC<Props> = ({
  results,
  onSelect,
  onPageChange,
  page,
}) => {
  if (!results || results.length === 0) {
    return null;
  }

  const columns: ColumnsType<Result> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, result) => {
        if (isOrganization(result)) {
          return (
            <Row gap={6}>
              <ShopOutlined style={{ fontSize: 32, color: '#666' }} />
              <Text>{result.name}</Text>
            </Row>
          );
        }

        return (
          <Row gap={6}>
            <UserAvatar user={result} size={32} />
            <Column>
              <Row gap={6}>
                <Text>{result.name}</Text>
                {result.organization && <Tag>{result.organization.name}</Tag>}
              </Row>
              <Text style={{ color: '#999' }}>{result.email}</Text>
            </Column>
          </Row>
        );
      },
    },
  ];

  return (
    <StyledTable
      rowKey="token"
      columns={columns}
      dataSource={results}
      showHeader={false}
      onRow={(organization: IOrganization) => {
        return {
          onClick: () => {
            onSelect(organization);
          },
        };
      }}
      pagination={{
        current: page,
        pageSize: 6,
        onChange: onPageChange,
      }}
    />
  );
};

const StyledTable = styled(Table)`
  tr {
    cursor: pointer;
  }
`;
