import { useSelectGoalsSearch } from '@client/GoalsClient';
import { GoalToken, IGoal } from '@shared/goals';
import { Column } from '@web/components/layout';
import { Empty, Modal, Select } from 'antd';
import React, { useState } from 'react';

export const AddGoalToListModal: React.FC<{
  onCancel: () => void;
  onSave: (goal: GoalToken) => Promise<void>;
  omitGoals?: GoalToken[];
}> = ({ onSave, onCancel, omitGoals }) => {
  const [selectedGoal, setSelectedGoal] = useState<GoalToken>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: relevantGoals } = useSelectGoalsSearch();

  const handleOk = async () => {
    setIsSaving(true);

    try {
      await onSave(selectedGoal);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleGoalChange = (token: GoalToken) => {
    setSelectedGoal(token);
  };

  if (!relevantGoals) {
    return null;
  }

  const isNotOmitted = (goal: IGoal) =>
    !omitGoals || !omitGoals.includes(goal.token);
  const isPublic = (goal: IGoal) => goal.isPublic;

  const visibleGoals = relevantGoals.filter(isNotOmitted).filter(isPublic);
  return (
    <Modal
      title="Add Goal to List"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Add"
      okButtonProps={{
        disabled: !selectedGoal,
      }}
    >
      <Column>
        <Select
          onChange={handleGoalChange}
          notFoundContent={
            <Empty description="No goals found" style={{ margin: '12px 0' }} />
          }
        >
          {visibleGoals.map((goal) => (
            <Select.Option value={goal.token} key={goal.token}>
              {goal.title}
            </Select.Option>
          ))}
        </Select>
      </Column>
    </Modal>
  );
};
