import { IComment } from '@shared/comments';
import { formatDate } from '@shared/formatDate';
import { OneOnOneToken } from '@shared/one-on-one';
import { Divider } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { UserAvatar } from '../../components/UserAvatar';
import { Column, Row } from '../../components/layout';
import { IDateCluster, INoteCluster } from './NotesScroller';
import { ViewEditNote } from './ViewEditNote';

export const NoteDateCluster: React.FC<{
  dateCluster: IDateCluster;
  onDelete: (token: string) => void;
  oneOnOneToken: OneOnOneToken;
  onScrollNeeded: () => void;
}> = ({ dateCluster, onDelete, oneOnOneToken, onScrollNeeded }) => {
  return (
    <>
      <DateDivider>{dateCluster.dateLabel}</DateDivider>
      {dateCluster.clusters.map((cluster, index) => (
        <NoteCluster
          key={index}
          cluster={cluster}
          onDelete={onDelete}
          oneOnOneToken={oneOnOneToken}
          onScrollNeeded={onScrollNeeded}
        />
      ))}
    </>
  );
};
const NoteCluster: React.FC<{
  cluster: INoteCluster;
  onDelete: (token: string) => void;
  oneOnOneToken: OneOnOneToken;
  onScrollNeeded: () => void;
}> = ({ cluster, onDelete, oneOnOneToken, onScrollNeeded }) => {
  return (
    <Row style={{ alignItems: 'flex-start' }}>
      <UserAvatar user={cluster.user} size={24} />
      <ClusterNotesContainer>
        {cluster.notes.map((note, index) => (
          <ViewEditNote
            key={index}
            onDelete={onDelete}
            note={note}
            oneOnOneToken={oneOnOneToken}
            onEditModeChange={onScrollNeeded}
          />
        ))}
      </ClusterNotesContainer>
    </Row>
  );
};
const ClusterNotesContainer = styled(Column)`
  gap: 6px;
  width: calc(100% - 24px);
  flex: 1;
`;

export const NotesContainer = styled(Column)`
  gap: 24px;
  width: 100%;
`;
export const DateDivider = styled(Divider).attrs({})`
  &.ant-divider-horizontal.ant-divider-with-text {
    margin: 6px 0;
  }

  .ant-divider-inner-text {
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #ddd;
    border-radius: 20px;
    color: #888;
  }

  &::before,
  &::after {
    background: #f5f5f5;
  }
`;
export const createDateClusters = (notes: IComment[]) => {
  const dateClusters: IDateCluster[] = [];
  let currentDateCluster: IDateCluster = null;
  let currentCluster: INoteCluster = null;
  for (const note of notes) {
    const dateLabel = formatDate(note.createdDate);
    if (!currentDateCluster || currentDateCluster.dateLabel !== dateLabel) {
      currentDateCluster = {
        dateLabel,
        clusters: [],
      };
      dateClusters.push(currentDateCluster);
      currentCluster = null;
    }
    if (!currentCluster || currentCluster.user.token !== note.author.token) {
      currentCluster = {
        user: note.author,
        notes: [],
      };
      currentDateCluster.clusters.push(currentCluster);
    }

    currentCluster.notes.push(note);
  }

  return dateClusters;
};
