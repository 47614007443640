import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { PageHeader } from '@web/components/PageHeader';
import { Row } from '@web/components/layout';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { TeamPicker } from './TeamPicker';

export const TeamPageHeader: React.FC<{ path: string }> = ({ path }) => {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();

  const handleTeamChange = (userToken: UserToken) => {
    if (userToken === currentUser.token) {
      navigate(`/team/${path}`);
    } else {
      navigate(`/team/${userToken}/${path}`);
    }
  };

  return (
    <PageHeader
      mobileTitle="Team"
      title={
        <Row gap={24}>
          Team
          <TeamPicker onChange={handleTeamChange} />
        </Row>
      }
    />
  );
};
