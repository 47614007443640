import { Typography } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const InfoText: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => (
  <LightText style={{ fontStyle: 'italic', fontSize: 14 }}>
    {children}
  </LightText>
);

export const Subheading = styled.h5`
  font-weight: 400 !important;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin: 0;
`;

export const Text = styled(Typography.Text)``;
export const LightText = styled(Typography.Text)`
  color: #888;
`;

export const BoldLink = styled.a`
  font-weight: bold;
  text-decoration: underline !important;
`;
