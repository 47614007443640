import { ALIGNMENT_STATE_NAMES, AlignmentState } from '@shared/alignment';
import { errorColor, successColor } from '@web/app/styles/ColorStyles';
import { Small, TypographyColors } from '@web/components/typography';
import Color from 'color';
import * as React from 'react';
import styled from 'styled-components';

export const AlignmentStateIndicator: React.FC<{
  state?: AlignmentState;
}> = ({ state }) => {
  if (!state) {
    return <NotEnrolledIndicator />;
  }

  return (
    <AlignmentStateContainer
      style={{
        backgroundColor: BackgroundColors[state],
      }}
    >
      <Small style={{ color: TextColors[state] }}>
        {ALIGNMENT_STATE_NAMES[state]}
      </Small>
    </AlignmentStateContainer>
  );
};

export const NotEnrolledIndicator: React.FC = () => {
  return (
    <AlignmentStateContainer
      style={{
        backgroundColor: Color('#f4f4f4').darken(0.05),
      }}
    >
      <Small>Not Enrolled</Small>
    </AlignmentStateContainer>
  );
};

const AlignmentStateContainer = styled.div`
  height: 24px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: 1px solid transparent;
  width: 100px;

  ${Small} {
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const BackgroundColors: Record<AlignmentState, string> = {
  [AlignmentState.GREEN]: successColor.lighten(0.9),
  [AlignmentState.YELLOW]: 'rgb(252 238 164)',
  [AlignmentState.RED]: errorColor.lighten(0.5),
};
const TextColors: Record<AlignmentState, string> = {
  [AlignmentState.GREEN]: TypographyColors.text,
  [AlignmentState.YELLOW]: TypographyColors.text,
  [AlignmentState.RED]: TypographyColors.text,
};
