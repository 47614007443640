import { IComment } from '@shared/comments';
import {
  ActionItemToken,
  AgendaItemToken,
  IActionItem,
  IAgendaItem,
  IOneOnOne,
  IOneOnOneDetails,
  IOneOnOneRelationship,
  OneOnOneRelationshipToken,
  OneOnOneToken,
} from '@shared/one-on-one';
import { IUser, OrganizationToken, UserToken } from '@shared/types';
import { del, get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useManagerOneOnOne() {
  const url = stringifyUrl({
    url: `/one-on-ones/manager`,
    query: {},
  });

  return useApi<IOneOnOneDetails>(url);
}

export function managerOneOnOne() {
  const url = stringifyUrl({
    url: `/one-on-ones/manager`,
    query: {},
  });

  return get<IOneOnOneDetails>(url);
}

export function sendWelcome(organizationToken: OrganizationToken) {
  return post<{ organizationToken: `o_${string}` }, void>(
    `/one-on-ones/welcome`,
    { organizationToken: organizationToken },
  );
}

export function useOneOnOneRelationships(archived?: boolean) {
  const url = stringifyUrl({
    url: `/one-on-ones/relationships`,
    query: {
      archived: archived,
    },
  });

  return useApi<IOneOnOneRelationship[]>(url);
}

export function oneOnOneRelationships(archived?: boolean) {
  const url = stringifyUrl({
    url: `/one-on-ones/relationships`,
    query: {
      archived: archived,
    },
  });

  return get<IOneOnOneRelationship[]>(url);
}

export function useRelationship(relationshipToken: OneOnOneRelationshipToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/relationships/${relationshipToken}`,
    query: {},
  });

  return useApi<IOneOnOneRelationship>(url);
}

export function relationship(relationshipToken: OneOnOneRelationshipToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/relationships/${relationshipToken}`,
    query: {},
  });

  return get<IOneOnOneRelationship>(url);
}

export function updateRelationship(
  relationshipToken: OneOnOneRelationshipToken,
  updates: Pick<
    IOneOnOneRelationship,
    'startDate' | 'schedule' | 'scheduleUpdatedDate' | 'daysOfWeek'
  >,
) {
  return patch<
    Pick<
      IOneOnOneRelationship,
      'startDate' | 'schedule' | 'scheduleUpdatedDate' | 'daysOfWeek'
    >,
    IOneOnOneRelationship
  >(`/one-on-ones/relationships/${relationshipToken}`, updates);
}

export function useOneOnOneNotes(oneOnOneToken: OneOnOneToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${oneOnOneToken}/notes`,
    query: {},
  });

  return useApi<{
    otherSharedNotes?: string;
    sharedNotes?: string;
    privateNote?: string;
    otherUser: IUser;
    oneOnOneToken: OneOnOneToken;
  }>(url);
}

export function oneOnOneNotes(oneOnOneToken: OneOnOneToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${oneOnOneToken}/notes`,
    query: {},
  });

  return get<{
    otherSharedNotes?: string;
    sharedNotes?: string;
    privateNote?: string;
    otherUser: IUser;
    oneOnOneToken: OneOnOneToken;
  }>(url);
}

export function useEntityNotes(oneOnOneToken: OneOnOneToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${oneOnOneToken}/entity-notes`,
    query: {},
  });

  return useApi<Record<`ai_${string}` | `act_${string}`, IComment[]>>(url);
}

export function entityNotes(oneOnOneToken: OneOnOneToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${oneOnOneToken}/entity-notes`,
    query: {},
  });

  return get<Record<`ai_${string}` | `act_${string}`, IComment[]>>(url);
}

export function useOneOnOneDetails(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${userToken}/details`,
    query: {},
  });

  return useApi<IOneOnOneDetails>(url);
}

export function oneOnOneDetails(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${userToken}/details`,
    query: {},
  });

  return get<IOneOnOneDetails>(url);
}

export function usePastOneOnOne(
  otherUserToken: UserToken,
  oneOnOneToken: OneOnOneToken,
) {
  const url = stringifyUrl({
    url: `/one-on-ones/${otherUserToken}/${oneOnOneToken}/details`,
    query: {},
  });

  return useApi<IOneOnOneDetails>(url);
}

export function pastOneOnOne(
  otherUserToken: UserToken,
  oneOnOneToken: OneOnOneToken,
) {
  const url = stringifyUrl({
    url: `/one-on-ones/${otherUserToken}/${oneOnOneToken}/details`,
    query: {},
  });

  return get<IOneOnOneDetails>(url);
}

export function useListOneOnOnes(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${userToken}/list`,
    query: {},
  });

  return useApi<Pick<IOneOnOne, 'token' | 'endedDate'>[]>(url);
}

export function listOneOnOnes(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${userToken}/list`,
    query: {},
  });

  return get<Pick<IOneOnOne, 'token' | 'endedDate'>[]>(url);
}

export function archiveRelationship(
  relationshipToken: OneOnOneRelationshipToken,
) {
  return put<{}>(`/one-on-ones/${relationshipToken}/archive`);
}

export function unarchiveRelationship(
  relationshipToken: OneOnOneRelationshipToken,
) {
  return del(`/one-on-ones/${relationshipToken}/archive`);
}

export function updateSharedNote(
  oneOnOneToken: OneOnOneToken,
  note: string | null,
) {
  return put<{ note: string }>(`/one-on-ones/${oneOnOneToken}/shared-notes`, {
    note: note,
  });
}

export function createAgendaItem(
  oneOnOneToken: OneOnOneToken,
  text: string | null,
) {
  return post<{ text: string }, IAgendaItem>(
    `/one-on-ones/${oneOnOneToken}/agenda_items`,
    { text: text },
  );
}

export function useAgendaItems(oneOnOneToken: OneOnOneToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${oneOnOneToken}/agenda_items`,
    query: {},
  });

  return useApi<IAgendaItem[]>(url);
}

export function agendaItems(oneOnOneToken: OneOnOneToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${oneOnOneToken}/agenda_items`,
    query: {},
  });

  return get<IAgendaItem[]>(url);
}

export function useActionItems(oneOnOneToken: OneOnOneToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${oneOnOneToken}/action_items`,
    query: {},
  });

  return useApi<IActionItem[]>(url);
}

export function actionItems(oneOnOneToken: OneOnOneToken) {
  const url = stringifyUrl({
    url: `/one-on-ones/${oneOnOneToken}/action_items`,
    query: {},
  });

  return get<IActionItem[]>(url);
}

export function useAssignedActionItems() {
  const url = stringifyUrl({
    url: `/one-on-ones/assigned_action_items`,
    query: {},
  });

  return useApi<IActionItem[]>(url);
}

export function assignedActionItems() {
  const url = stringifyUrl({
    url: `/one-on-ones/assigned_action_items`,
    query: {},
  });

  return get<IActionItem[]>(url);
}

export function createActionItem(
  oneOnOneToken: OneOnOneToken,
  text: string | null,
) {
  return post<{ text: string }, IActionItem>(
    `/one-on-ones/${oneOnOneToken}/action_items`,
    { text: text },
  );
}

export function endOneOnOne(oneOnOneToken: OneOnOneToken) {
  return put<{}, void>(`/one-on-ones/${oneOnOneToken}/endDate`);
}

export function sortAgendaItems(
  oneOnOneToken: OneOnOneToken,
  sortedAgenda: AgendaItemToken[],
) {
  return put<`ai_${string}`[], void>(
    `/one-on-ones/${oneOnOneToken}/agenda_items/sorted`,
    sortedAgenda,
  );
}

export function sortActionItems(
  oneOnOneToken: OneOnOneToken,
  sortedActionItems: ActionItemToken[],
) {
  return put<`act_${string}`[], void>(
    `/one-on-ones/${oneOnOneToken}/action_items/sorted`,
    sortedActionItems,
  );
}

export function updatePrivateNote(oneOnOneToken: OneOnOneToken, note: string) {
  return put<{ note: string }, void>(
    `/one-on-ones/${oneOnOneToken}/private-note`,
    { note: note },
  );
}

export function addEntityNote(
  oneOnOneToken: OneOnOneToken,
  entityToken: AgendaItemToken | ActionItemToken,
  text: string,
) {
  return post<{ text: string }, IComment>(
    `/one-on-ones/${oneOnOneToken}/entities/${entityToken}/notes`,
    { text: text },
  );
}

export function updateEntityNote(
  oneOnOneToken: OneOnOneToken,
  entityToken: AgendaItemToken | ActionItemToken,
  commentToken: string,
  text: string,
) {
  return patch<{ text: string }, IComment>(
    `/one-on-ones/${oneOnOneToken}/entities/${entityToken}/notes/${commentToken}`,
    { text: text },
  );
}

export function deleteEntityNote(
  oneOnOneToken: OneOnOneToken,
  entityToken: AgendaItemToken | ActionItemToken,
  commentToken: string,
) {
  return del(
    `/one-on-ones/${oneOnOneToken}/entities/${entityToken}/notes/${commentToken}`,
  );
}

export function updateAgendaItem(
  oneOnOneToken: OneOnOneToken,
  agendaItemToken: AgendaItemToken,
  text: string,
) {
  return put<{ text: string }, void>(
    `/one-on-ones/${oneOnOneToken}/agenda_items/${agendaItemToken}`,
    { text: text },
  );
}

export function updateActionItem(
  oneOnOneToken: OneOnOneToken,
  actionItemToken: ActionItemToken,
  text: string,
) {
  return put<{ text: string }, void>(
    `/one-on-ones/${oneOnOneToken}/action_items/${actionItemToken}`,
    { text: text },
  );
}

export function assignActionItem(
  oneOnOneToken: OneOnOneToken,
  actionItemToken: ActionItemToken,
  assignedToToken: UserToken | null,
) {
  return put<{ assignedTo: `u_${string}` }, void>(
    `/one-on-ones/${oneOnOneToken}/action_items/${actionItemToken}/assigned`,
    { assignedTo: assignedToToken },
  );
}

export function removeAgendaItem(
  oneOnOneToken: OneOnOneToken,
  agendaItemToken: AgendaItemToken,
) {
  return del(`/one-on-ones/${oneOnOneToken}/agenda_items/${agendaItemToken}`);
}

export function removeActionItem(
  oneOnOneToken: OneOnOneToken,
  actionItemToken: ActionItemToken,
) {
  return del(`/one-on-ones/${oneOnOneToken}/action_items/${actionItemToken}`);
}

export function completeAgendaItem(
  oneOnOneToken: OneOnOneToken,
  agendaItemToken: AgendaItemToken,
) {
  return put<{}, void>(
    `/one-on-ones/${oneOnOneToken}/agenda_items/${agendaItemToken}/completed`,
  );
}

export function uncompleteAgendaItem(
  oneOnOneToken: OneOnOneToken,
  agendaItemToken: AgendaItemToken,
) {
  return del(
    `/one-on-ones/${oneOnOneToken}/agenda_items/${agendaItemToken}/completed`,
  );
}

export function completeActionItem(
  oneOnOneToken: OneOnOneToken,
  actionItemToken: ActionItemToken,
) {
  return put<{}, void>(
    `/one-on-ones/${oneOnOneToken}/action_items/${actionItemToken}/completed`,
  );
}

export function uncompleteActionItem(
  oneOnOneToken: OneOnOneToken,
  actionItemToken: ActionItemToken,
) {
  return del(
    `/one-on-ones/${oneOnOneToken}/action_items/${actionItemToken}/completed`,
  );
}
