import { IOneOnOneDetails } from '@shared/one-on-one';
import { ActionItemsList } from '@web/1on1s/ActionItemsList';
import { AgendaItemsList } from '@web/1on1s/AgendaItemsList';
import { OneOnOneProvider } from '@web/1on1s/OneOnOneProvider';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { useWebSocketEntity } from '@web/common/useWebSocketEvent';
import { Column, Spacer } from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import React from 'react';

export const OneOnOneSection: React.FC = () => {
  const { user } = useAuth();
  const { data: managerOneOnOne } =
    useApi<IOneOnOneDetails>(`/one-on-ones/manager`);
  useWebSocketEntity(managerOneOnOne ? managerOneOnOne?.token : undefined);

  if (!managerOneOnOne) {
    return null;
  }

  const otherUser =
    user.token === managerOneOnOne.ownerUser.token
      ? managerOneOnOne.counterpartUser
      : managerOneOnOne.ownerUser;
  return (
    <OneOnOneProvider oneOnOneToken={managerOneOnOne.token}>
      <Column>
        <Header3 style={{ whiteSpace: 'pre-wrap' }}>
          Prepare for your 1-on-1 with {otherUser.name} by reviewing and
          updating the agenda and actions
        </Header3>
        <Spacer size={10} />
        <Column gap={24}>
          <AgendaItemsList
            oneOnOneToken={managerOneOnOne.token}
            otherUser={otherUser}
          />
          <ActionItemsList
            oneOnOneToken={managerOneOnOne.token}
            otherUser={otherUser}
          />
        </Column>
      </Column>
    </OneOnOneProvider>
  );
};
