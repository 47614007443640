import { $createQuoteNode } from '@lexical/rich-text';
import {
  CodeBlockEditorDescriptor,
  CreateLink,
  MDXEditor,
  MDXEditorMethods,
  MultipleChoiceToggleGroup,
  SingleChoiceToggleGroup,
  codeBlockPlugin,
  corePluginHooks,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  listsPluginHooks,
  quotePlugin,
  toolbarPlugin,
  useCodeBlockEditorContext,
  useHasPlugin,
} from '@mdxeditor/editor';
import { IS_BOLD, IS_ITALIC } from '@mdxeditor/editor/dist/FormatConstants';
import ReactCodeMirror from '@uiw/react-codemirror';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface MarkdownEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
}

/**
 * The editor which is used to create the annotation. Supports formatting.
 */
export const MarkdownEditor: FC<MarkdownEditorProps> = ({
  value,
  placeholder,
  onChange,
  className,
  autoFocus,
  readOnly,
}) => {
  const editorRef = React.useRef<MDXEditorMethods>(null);

  React.useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setMarkdown(value ?? '');
    }
  }, [value]);

  return (
    <MDXEditor
      className={className}
      contentEditableClassName="mdx-editor-content"
      placeholder={placeholder}
      markdown={value ?? ''}
      onChange={onChange}
      plugins={[
        quotePlugin(),
        listsPlugin({}),
        linkPlugin(),
        headingsPlugin(),
        imagePlugin(),
        linkDialogPlugin(),
        codeBlockPlugin({
          codeBlockEditorDescriptors: [PlainTextCodeEditorDescriptor],
        }),
        // diffSourcePlugin({ viewMode: 'rich-text' }),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <BoldItalicToggles />
              <ListsToggle />
              <CreateLink />
              <BlockTypeSelect />
            </>
          ),
        }),
      ]}
      autoFocus={autoFocus}
      readOnly={readOnly}
      ref={editorRef}
      suppressHtmlProcessing
    />
  );
};

export const ListsToggle: React.FC = () => {
  const [currentListType] =
    listsPluginHooks.useEmitterValues('currentListType');
  const applyListType = listsPluginHooks.usePublisher('applyListType');
  const [iconComponentFor] =
    listsPluginHooks.useEmitterValues('iconComponentFor');
  return (
    <SingleChoiceToggleGroup
      value={currentListType || ''}
      items={[
        {
          title: 'Bulleted list',
          contents: iconComponentFor('format_list_bulleted'),
          value: 'bullet',
        },
        {
          title: 'Numbered list',
          contents: iconComponentFor('format_list_numbered'),
          value: 'number',
        },
      ]}
      onChange={applyListType}
    />
  );
};

export const BoldItalicToggles: React.FC = () => {
  const [currentFormat] = corePluginHooks.useEmitterValues('currentFormat');
  const applyFormat = corePluginHooks.usePublisher('applyFormat');

  const boldIsOn = (currentFormat & IS_BOLD) !== 0;
  const italicIsOn = (currentFormat & IS_ITALIC) !== 0;

  const boldTitle = boldIsOn ? 'Remove bold' : 'Bold';
  const italicTitle = italicIsOn ? 'Remove italic' : 'Italic';
  const [iconComponentFor] =
    corePluginHooks.useEmitterValues('iconComponentFor');

  return (
    <MultipleChoiceToggleGroup
      items={[
        {
          title: boldTitle,
          contents: iconComponentFor('format_bold'),
          active: boldIsOn,
          onChange: applyFormat.bind(null, 'bold'),
        },
        {
          title: italicTitle,
          contents: iconComponentFor('format_italic'),
          active: italicIsOn,
          onChange: applyFormat.bind(null, 'italic'),
        },
      ]}
    />
  );
};

export const BlockTypeSelect = () => {
  const convertSelectionToNode = corePluginHooks.usePublisher(
    'convertSelectionToNode',
  );
  const [currentBlockType] =
    corePluginHooks.useEmitterValues('currentBlockType');
  const hasQuote = useHasPlugin('quote');

  if (!hasQuote) {
    return null;
  }

  return (
    <SingleChoiceToggleGroup
      value={currentBlockType ?? ''}
      items={[
        {
          title: 'Quote',
          contents: <QuoteIcon />,
          value: 'quote',
        },
      ]}
      onChange={() => {
        convertSelectionToNode(() => $createQuoteNode());
      }}
    />
  );
};

const QuoteSvg: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} viewBox="0 0 18 18">
    <rect height="3" width="3" x="4" y="5"></rect>
    <rect height="3" width="3" x="11" y="5"></rect>
    <path d="M7,8c0,4.031-3,5-3,5"></path>
    <path d="M14,8c0,4.031-3,5-3,5"></path>
  </svg>
);

const QuoteIcon = styled(QuoteSvg)`
  > * {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    fill: #666;
    stroke: #666;
  }
`;

const PlainTextCodeEditorDescriptor: CodeBlockEditorDescriptor = {
  match: (language, meta) => true,
  priority: 0,
  Editor: (props: { code: string }) => {
    const cb = useCodeBlockEditorContext();
    return (
      <CodeContainer
        onKeyDown={(e) => e.nativeEvent.stopImmediatePropagation()}
      >
        <ReactCodeMirror
          value={props.code}
          onChange={(code) => {
            cb.setCode(code);
          }}
          basicSetup={{
            lineNumbers: false,
            highlightActiveLine: false,
            highlightActiveLineGutter: false,
            foldGutter: true,
          }}
        />
      </CodeContainer>
    );
  },
};

const CodeContainer = styled.div`
  .cm-gutters {
    display: none;
  }

  .cm-content {
    padding: 12px;
    background: #f5f5f5;
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: var(--default-border-radius);
    font-size: 13px;
  }

  .cm-line {
    line-height: 24px;
  }
`;

// const DiffSourceToggleWrapper: React.FC<{ children: React.ReactNode }> = ({
//   children,
// }) => {
//   // access the viewMode node value
//   const [viewMode] = diffSourcePluginHooks.useEmitterValues('viewMode');
//   // a function that will publish a new value into the viewMode node
//   const changeViewMode = diffSourcePluginHooks.usePublisher('viewMode');

//   return (
//     <>
//       {viewMode === 'rich-text' ? children : <span>Source mode</span>}

//       <div style={{ marginLeft: 'auto' }}>
//         <SingleChoiceToggleGroup
//           value={viewMode}
//           items={[
//             {
//               title: 'Rich text',
//               contents: <FileTextOutlined />,
//               value: 'rich-text',
//             },
//             {
//               title: 'Source',
//               contents: <FileMarkdownOutlined />,
//               value: 'source',
//             },
//           ]}
//           onChange={(value) => changeViewMode(value || 'rich-text')}
//         />
//       </div>
//     </>
//   );
// };
