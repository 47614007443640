import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useListOneOnOnes } from '@client/OneOnOnesClient';
import { mapByToken } from '@shared/mapByToken';
import { OneOnOneToken } from '@shared/one-on-one';
import { UserToken } from '@shared/types';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Dropdown, MenuProps } from 'antd';
import { format } from 'date-fns';
import * as React from 'react';
import { useEffect } from 'react';

export const PastOneOnOnesPicker: React.FC<{
  otherUserToken: UserToken;
  viewingOneOnOneToken: OneOnOneToken;
  onChange: (token?: OneOnOneToken) => void;
}> = ({ otherUserToken, viewingOneOnOneToken, onChange }) => {
  const { data: oneOnOnes, mutate: reloadOneOnOnes } =
    useListOneOnOnes(otherUserToken);
  useEffect(() => {
    if (!oneOnOnes) {
      return;
    }
    const position = oneOnOnes.findIndex(
      (o) => o.token === viewingOneOnOneToken,
    );
    if (position < 0) {
      void reloadOneOnOnes();
    }
  }, [oneOnOnes, viewingOneOnOneToken]);

  if (!oneOnOnes) {
    return null;
  }

  const handleChange = (token: OneOnOneToken, endedDate?: Date) => {
    onChange(endedDate ? token : null);
  };
  const items: MenuProps['items'] = [];
  for (const { token, endedDate } of oneOnOnes ?? []) {
    items.push({
      key: token,
      onClick: () => {
        handleChange(token, endedDate);
      },
      label: (
        <Text style={{ userSelect: 'none' }}>
          {endedDate ? formatOneOnOneDate(endedDate) : 'Current'}
        </Text>
      ),
    });
  }

  const position = oneOnOnes
    ? oneOnOnes.findIndex((o) => o.token === viewingOneOnOneToken)
    : -1;
  const oneOnOneMap = mapByToken(oneOnOnes);
  const viewingOneOnOne = oneOnOneMap.get(viewingOneOnOneToken);
  if (!viewingOneOnOne) {
    return null;
  }

  const viewingDate = !viewingOneOnOne.endedDate
    ? 'Current'
    : formatOneOnOneDate(viewingOneOnOne.endedDate);
  return (
    <Row gap={6}>
      <Button
        type="text"
        onClick={() => {
          const target = oneOnOnes[position + 1];
          handleChange(target.token, target.endedDate);
        }}
        disabled={position === -1 || position === items.length - 1}
        style={{ padding: '0px 8px' }}
      >
        <LeftOutlined />
      </Button>
      <Dropdown
        arrow
        menu={{ items, selectable: true, selectedKeys: [viewingOneOnOneToken] }}
        placement="bottom"
        trigger={['click']}
      >
        <Text style={{ color: 'rgba(0,0,0,0.88) ', cursor: 'pointer' }}>
          {viewingDate}
        </Text>
      </Dropdown>
      <Button
        type="text"
        onClick={() => {
          const target = oneOnOnes[position - 1];
          handleChange(target.token, target.endedDate);
        }}
        disabled={position <= 0}
        style={{ padding: '0px 8px' }}
      >
        <RightOutlined />
      </Button>
    </Row>
  );
};

export const formatOneOnOneDate = (dateOrString: string | Date) =>
  format(new Date(dateOrString), `MMM d, yyyy`);
