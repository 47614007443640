import { PlusOutlined } from '@ant-design/icons';
import {
  addBedrockChannelsAdmin,
  deleteBedrockChannelAdmin,
  deleteEnrolledBedrockUser,
  enrollBedrockUser,
  refreshBedrockEligibleChannnelsAdmin,
  updateBedrockEnrollmentAdmin,
  useBedrockConfigurationAdmin,
  useEnrolledBedrockUsers,
} from '@client/BedrockConfigurationClient';
import { jiraInstallUrl, useJiraAccess } from '@client/JiraClient';
import { update as updateUser } from '@client/UsersClient';
import { BedrockEnrollmentUpdate, IBedrockEnrollment } from '@shared/bedrock';
import { Feature } from '@shared/features';
import { formatLongDate } from '@shared/formatLongDate';
import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { EnrollUsersModal } from '@web/bedrock/EnrollUsersModal';
import { EnrollmentsTable } from '@web/bedrock/EnrollmentsTable';
import { AddChannelsButton } from '@web/bedrock/internal/AddChannelsButton';
import { ShowChannelsButton } from '@web/bedrock/internal/ShowChannelsButton';
import { useFeature } from '@web/common/useFeature';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Skeleton,
  message,
} from 'antd';
import { isEmpty } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';

import { AdminPageContent } from './AdminPageContent';

export const AdminBedrockPage: React.FC = () => {
  const { user } = useAuth();
  const { data: response, mutate: reloadConfiguration } =
    useBedrockConfigurationAdmin();
  const { data: enrollments, mutate: reloadEnrollments } =
    useEnrolledBedrockUsers();
  const { data: jiraAccessResponse } = useJiraAccess();
  const [showEnrollModal, setShowEnrollModal] = React.useState(false);
  const { booleanValue: linearEnabled } = useFeature(
    Feature.ENABLE_BEDROCK_LINEAR_PARSING,
  );
  const { booleanValue: jiraEnabled } = useFeature(
    Feature.ENABLE_BEDROCK_JIRA_PARSING,
  );
  const { booleanValue: useNewModal } = useFeature(
    Feature.BEDROCK_USE_DB_FOR_CHANNELS,
  );
  const { booleanValue: enableDirectJira } = useFeature(
    Feature.ENABLE_DIRECT_JIRA_INTEGRATION,
  );

  const handleEnroll = async (userToken: UserToken) => {
    try {
      await enrollBedrockUser(userToken);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to enroll user');
    } finally {
      setShowEnrollModal(false);
    }
  };

  const handleDelete = async (enrollment: IBedrockEnrollment) => {
    try {
      await deleteEnrolledBedrockUser(enrollment.user.token);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove user');
    }
  };

  const handleAddChannels = async (channelIds: string[]) => {
    try {
      await addBedrockChannelsAdmin(channelIds);
      await reloadConfiguration();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to add channels');
    }
  };

  const handleRefreshChannels = async () => {
    try {
      await refreshBedrockEligibleChannnelsAdmin();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to schedule channel refresh');
    }
  };

  const handleDeleteChannel = async (channelId: string) => {
    try {
      await deleteBedrockChannelAdmin(channelId);
      await reloadConfiguration();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove channel');
    }
  };

  const handleEdit = async (
    userToken: UserToken,
    updates: BedrockEnrollmentUpdate,
  ) => {
    try {
      await updateBedrockEnrollmentAdmin(userToken, updates);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to update');
    }
  };

  if (!user || !response || !jiraAccessResponse) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const items: DescriptionsProps['items'] = [
    {
      key: 'channelCount',
      label: 'Enrolled Channels',
      children: (
        <ShowChannelsButton onDelete={handleDeleteChannel}>
          View {response.enrolledChannelCount}{' '}
          {pluralize('channel', response.enrolledChannelCount)}
        </ShowChannelsButton>
      ),
    },
  ];

  return (
    <AdminPageContent>
      <Column gap={24}>
        <Column gap={12}>
          <Row>
            <Header2>Slack</Header2>
            <GrowingSpacer />
            <Row gap={6}>
              <AddChannelsButton
                useNewModal={useNewModal}
                onRefreshChannelList={handleRefreshChannels}
                onAddChannels={handleAddChannels}
              />
            </Row>
          </Row>
          <Descriptions items={items} />
        </Column>
        {enableDirectJira && (
          <Column gap={12}>
            <Row>
              <Header2>Jira</Header2>
              <GrowingSpacer />
              <LinkJiraButton type="primary" />
            </Row>
            {jiraAccessResponse.link ? (
              <Column>
                <Row gap={6}>
                  <UserMessage
                    user={jiraAccessResponse.link.linkedBy}
                    avatarSize={24}
                    fontSize={14}
                    width="auto"
                  />

                  <Text>
                    linked on{' '}
                    {formatLongDate(jiraAccessResponse.link.linkedAt, true)}
                  </Text>
                </Row>
              </Column>
            ) : (
              <Text>Not linked</Text>
            )}
          </Column>
        )}
        <Column gap={12}>
          <Row gap={6}>
            <Header2>
              Enrollment (Max: {response.configuration.enrollmentLimit})
            </Header2>
            <GrowingSpacer />
            <Button
              type="primary"
              onClick={() => {
                setShowEnrollModal(true);
              }}
              style={{ maxWidth: 200 }}
            >
              <PlusOutlined /> Enroll Users
            </Button>
          </Row>
        </Column>
      </Column>
      {enrollments ? (
        <EnrollmentsTable
          enrollments={enrollments}
          showJira={jiraEnabled}
          showLinear={linearEnabled}
          onDelete={handleDelete}
          onEditGithub={async (userToken: UserToken, githubLogin: string) => {
            await updateUser(userToken, {
              githubLogin: isEmpty(githubLogin) ? null : githubLogin.trim(),
            });
          }}
          onEdit={handleEdit}
        />
      ) : (
        <Skeleton />
      )}
      {showEnrollModal && (
        <EnrollUsersModal
          organizationToken={user.organizationToken}
          omitUserTokens={enrollments?.map(
            (enrollment) => enrollment.user.token,
          )}
          onCancel={() => {
            setShowEnrollModal(false);
          }}
          onEnroll={handleEnroll}
        />
      )}
    </AdminPageContent>
  );
};

const LinkJiraButton: React.FC<{
  type: 'primary' | undefined;
}> = ({ type }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    const { url } = await jiraInstallUrl();
    window.location.href = url;
  };
  return (
    <Row>
      <Button type={type} onClick={handleClick} loading={isLoading}>
        Link Jira
      </Button>
    </Row>
  );
};
