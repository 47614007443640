import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import {
  archiveRelationship,
  unarchiveRelationship,
  useListOneOnOnes,
  useOneOnOneDetails,
  usePastOneOnOne,
} from '@client/OneOnOnesClient';
import { useUserDetails } from '@client/UsersClient';
import { formatDate } from '@shared/formatDate';
import {
  IOneOnOneDetails,
  OneOnOneToken,
  canArchive,
  oneOnOneUrl,
} from '@shared/one-on-one';
import { UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { WebSocketEventType } from '@shared/webSocketEvents';
import { PageContent } from '@web/app/Page';
import { IF_MOBILE, useResponsive } from '@web/app/responsive';
import { tertiaryColor } from '@web/app/styles/ColorStyles';
import { useAuth } from '@web/auth/useAuth';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserAvatar } from '@web/components/UserAvatar';
import {
  Column,
  Grid,
  GrowingSpacer,
  Row,
  Spacer,
} from '@web/components/layout';
import { Text } from '@web/components/text';
import { RecentEntries } from '@web/journal/RecentEntries';
import { Button, Skeleton, message } from 'antd';
import Dropdown from 'antd/es/dropdown/dropdown';
import { DropdownProps } from 'antd/lib';
import addDays from 'date-fns/addDays';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  useWebSocketEntity,
  useWebSocketEvent,
} from '../common/useWebSocketEvent';
import { ActionItemsList } from './ActionItemsList';
import { AgendaItemsList } from './AgendaItemsList';
import { CompleteButton } from './CompleteButton';
import { OneOnOneAlignment } from './OneOnOneAlignment';
import { OneOnOneCheckIn } from './OneOnOneCheckIn';
import { OneOnOneGoals } from './OneOnOneGoals';
import { OneOnOneNotes } from './OneOnOneNotes';
import { OneOnOneProvider } from './OneOnOneProvider';
import { PastOneOnOnesPicker } from './PastOneOnOnesPicker';
import { SchedulingBanner } from './SchedulingBanner';

export const View1on1Page: React.FC = () => {
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { userToken1, userToken2, oneOnOneToken } = useParams<{
    userToken1: UserToken;
    userToken2: UserToken;
    oneOnOneToken?: OneOnOneToken;
  }>();
  const otherUserToken = user.token === userToken1 ? userToken2 : userToken1;
  const { data: otherUser } = useUserDetails(otherUserToken);
  const [showSchedule, setShowSchedule] = React.useState(false);
  const isOtherReport = isManagerOf(otherUser, user.token);
  /**
   * If this 1-on-1 is between a manager and a report, the viewingUserToken
   * corresponds to the UserToken of the report
   */
  const viewingUserToken = isManagerOf(user, otherUserToken)
    ? user.token
    : isOtherReport
    ? otherUserToken
    : undefined;
  const { data: oneOnOne, mutate: reloadOneOnOne } = oneOnOneToken
    ? usePastOneOnOne(otherUserToken, oneOnOneToken)
    : useOneOnOneDetails(otherUserToken);
  useWebSocketEntity(oneOnOneToken ?? oneOnOne?.token);
  const { configurationMenu, contextHolder } = useOneOnOneConfiguration(
    oneOnOne,
    () => {
      setShowSchedule(true);
    },
  );

  if (!otherUser || !oneOnOne) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const navigateToOneOnOne = (token?: OneOnOneToken) => {
    if (token === oneOnOne.token) {
      return;
    }

    if (!token) {
      navigate(`/1on1s/${userToken1}/${userToken2}`);
    } else {
      navigate(`/1on1s/${userToken1}/${userToken2}/${token}`);
    }
  };

  return (
    <OneOnOneProvider oneOnOneToken={oneOnOne.token}>
      {!oneOnOne.endedDate && (
        <CompletionRedirect
          oneOnOneToken={oneOnOne.token}
          userToken1={userToken1}
          userToken2={userToken2}
        />
      )}
      <PageContent>
        <PageHeader
          mobileTitle={`1-on-1 with ${otherUser.name}`}
          title={
            <Row gap={6} style={{ width: '100%' }}>
              <UserAvatar user={otherUser} size={30} />
              1-on-1 with {otherUser.name}
              <GrowingSpacer />
              <Row
                style={{
                  background: 'white',
                  borderRadius: 'var(--default-border-radius)',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0,0,0,0.88)',
                  boxShadow: '0 2px 0 rgba(0,0,0,0.02)',
                  height: '32px',
                }}
              >
                <PastOneOnOnesPicker
                  otherUserToken={otherUserToken}
                  viewingOneOnOneToken={oneOnOne.token}
                  onChange={navigateToOneOnOne}
                />
              </Row>
              {!oneOnOne.endedDate ? (
                <CompleteButton oneOnOneToken={oneOnOne.token} />
              ) : null}
              {configurationMenu.items.length > 0 && (
                <Dropdown
                  placement="bottom"
                  arrow
                  trigger={['click']}
                  menu={configurationMenu}
                >
                  <Button>
                    <SettingOutlined />
                  </Button>
                </Dropdown>
              )}
            </Row>
          }
          navigateBack
          defaultNavigateBackTo="/1on1s"
        />
        {oneOnOne.endedDate && (
          <CompletedBanner>
            <InfoCircleOutlined />
            <span>Completed {formatDate(oneOnOne.endedDate)}.</span>
            <Link to={oneOnOneUrl(user.token, otherUserToken)}>
              View current 1-on-1
            </Link>
          </CompletedBanner>
        )}
        {!isMobile &&
          !oneOnOne.endedDate &&
          (showSchedule || !oneOnOne.relationship.scheduleUpdatedDate) && (
            <SchedulingBanner
              relationship={oneOnOne.relationship}
              onChange={reloadOneOnOne}
              onClose={
                oneOnOne.relationship.scheduleUpdatedDate
                  ? () => {
                      setShowSchedule(false);
                    }
                  : undefined
              }
            />
          )}
        <Column>
          <Pane>
            <Grid columns={isMobile ? '1fr' : '60% 40%'} gap={24}>
              <Column>
                <AgendaItemsList
                  oneOnOneToken={oneOnOne.token}
                  otherUser={otherUser}
                />
                <ActionItemsList
                  oneOnOneToken={oneOnOne.token}
                  otherUser={otherUser}
                />
                <Column gap={12}>
                  <OneOnOneNotes
                    oneOnOneToken={oneOnOne.token}
                    otherUserToken={otherUserToken}
                  />
                </Column>
              </Column>
              {!isMobile && (
                <Column
                  gap={12}
                  style={{
                    padding: '24px',
                    margin: '-25px 0',
                    borderLeft: '1px solid #eee',
                  }}
                >
                  {viewingUserToken && (
                    <OneOnOneCheckIn userToken={viewingUserToken} />
                  )}
                  {viewingUserToken && (
                    <OneOnOneGoals userToken={viewingUserToken} />
                  )}
                  {viewingUserToken && (
                    <RecentEntries
                      userToken={viewingUserToken}
                      startDate={addDays(new Date(), -14)}
                      limit={10}
                    />
                  )}
                  {viewingUserToken && (
                    <OneOnOneAlignment userToken={viewingUserToken} />
                  )}
                </Column>
              )}
            </Grid>
          </Pane>
        </Column>
        {isMobile && (
          <Column>
            <Spacer />
            {!oneOnOne.endedDate ? (
              <CompleteButton oneOnOneToken={oneOnOne.token} type="primary" />
            ) : null}
            <Spacer />
          </Column>
        )}
        {contextHolder}
      </PageContent>
    </OneOnOneProvider>
  );
};

const CompletionRedirect: React.FC<{
  userToken1: UserToken;
  userToken2: UserToken;
  oneOnOneToken: OneOnOneToken;
}> = ({ oneOnOneToken, userToken1, userToken2 }) => {
  const { user } = useAuth();
  const otherUserToken = user.token === userToken1 ? userToken2 : userToken1;
  const { mutate: reloadOneOnOnes } = useListOneOnOnes(otherUserToken);
  const navigate = useNavigate();
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.ONE_ON_ONE_ENDED,
    async () => {
      await reloadOneOnOnes();
      navigate(`/1on1s/${userToken1}/${userToken2}/${oneOnOneToken}`);
    },
  );

  return null;
};

const CompletedBanner = styled(Text)`
  display: flex;
  gap: 6px;
  padding: 3px 18px;
  background: ${tertiaryColor.lighten(0.1)};
  color: black;
  border-radius: 22px;
  margin-left: 24px;
  font-size: 12px;
  align-self: flex-start;
  position: relative;
  top: -12px;

  ${IF_MOBILE} {
    top: 0;
    margin-bottom: 12px;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const useOneOnOneConfiguration = (
  oneOnOne: IOneOnOneDetails,
  onEditSchedule: () => void,
) => {
  const { confirm, contextHolder } = useModalConfirm();
  const navigate = useNavigate();
  const handleArchive = React.useMemo(
    () => async () => {
      const confirmed = await confirm('Do you want to unarchive this 1-on-1?');
      if (!confirmed) {
        return;
      }

      try {
        await archiveRelationship(oneOnOne.relationship.token);
        void message.success('Success');
        navigate(-1);
      } catch (error) {
        void message.error('Error');
      }
    },
    [oneOnOne],
  );
  const handleUnarchive = React.useMemo(
    () => async () => {
      const confirmed = await confirm('Do you want to archive this 1-on-1?');
      if (!confirmed) {
        return;
      }

      try {
        await unarchiveRelationship(oneOnOne.relationship.token);
        void message.success('Success');
        navigate(-1);
      } catch (error) {
        void message.error('Error');
      }
    },
    [oneOnOne],
  );

  const configurationMenu: DropdownProps['menu'] = {
    items: [],
  };
  if (oneOnOne && canArchive(oneOnOne)) {
    if (oneOnOne.relationship.archivedDate) {
      configurationMenu.items.push({
        key: 'unarchive',
        label: 'Unarchive 1-on-1',
        onClick: handleUnarchive,
      });
    } else {
      configurationMenu.items.push({
        key: 'archive',
        label: 'Archive 1-on-1',
        onClick: handleArchive,
      });
    }
  }
  if (
    oneOnOne &&
    !oneOnOne.endedDate &&
    oneOnOne.relationship.scheduleUpdatedDate
  ) {
    configurationMenu.items.push({
      label: 'Edit Schedule',
      key: 'edit-schedule',
      onClick: onEditSchedule,
    });
  }

  return { contextHolder, configurationMenu };
};
