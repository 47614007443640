import { QuestionType } from '@shared/QuestionType';
import { IQuestion } from '@shared/questions';

export const performanceAccomplishmentsQuestion: IQuestion = {
  token: 'q_performance_accomplishments',
  text: 'What are the top 1-2 things this person accomplished that highlight performance in their role?',
  type: QuestionType.OPEN_ENDED,
};
export const performanceStrengthsQuestion: IQuestion = {
  token: 'q_performance_strengths',
  text: 'What are 2-3 strengths that this person demonstrated?',
  type: QuestionType.OPEN_ENDED,
};
