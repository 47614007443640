var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const setupAmplitudeIntegration = ({
  apiKey
}) => {
  (function(e, t) {
    var n = e.amplitude || { _q: [], _iq: {} };
    var r = t.createElement("script");
    r.type = "text/javascript";
    r.integrity = "sha384-+EO59vL/X7v6VE2s6/F4HxfHlK0nDUVWKVg8K9oUlvffAeeaShVBmbORTC2D3UF+";
    r.crossOrigin = "anonymous";
    r.async = true;
    r.src = "https://cdn.amplitude.com/libs/amplitude-8.17.0-min.gz.js";
    r.onload = function() {
      if (!e.amplitude.runQueuedFunctions) {
        console.log("[Amplitude] Error: could not load SDK");
      }
      amplitude.getInstance().init(apiKey);
    };
    var i = t.getElementsByTagName("script")[0];
    i.parentNode.insertBefore(r, i);
    function s(e2, t2) {
      e2.prototype[t2] = function() {
        this._q.push(
          [t2].concat(Array.prototype.slice.call(arguments, 0))
        );
        return this;
      };
    }
    var o = function() {
      this._q = [];
      return this;
    };
    var a = [
      "add",
      "append",
      "clearAll",
      "prepend",
      "set",
      "setOnce",
      "unset",
      "preInsert",
      "postInsert",
      "remove"
    ];
    for (var c = 0; c < a.length; c++) {
      s(o, a[c]);
    }
    n.Identify = o;
    var u = function() {
      this._q = [];
      return this;
    };
    var l = [
      "setProductId",
      "setQuantity",
      "setPrice",
      "setRevenueType",
      "setEventProperties"
    ];
    for (var p = 0; p < l.length; p++) {
      s(u, l[p]);
    }
    n.Revenue = u;
    var d = [
      "init",
      "logEvent",
      "logRevenue",
      "setUserId",
      "setUserProperties",
      "setOptOut",
      "setVersionName",
      "setDomain",
      "setDeviceId",
      "enableTracking",
      "setGlobalUserProperties",
      "identify",
      "clearUserProperties",
      "setGroup",
      "logRevenueV2",
      "regenerateDeviceId",
      "groupIdentify",
      "onInit",
      "logEventWithTimestamp",
      "logEventWithGroups",
      "setSessionId",
      "resetSessionId"
    ];
    function v(e2) {
      function t2(t3) {
        e2[t3] = function() {
          e2._q.push(
            [t3].concat(Array.prototype.slice.call(arguments, 0))
          );
        };
      }
      for (var n2 = 0; n2 < d.length; n2++) {
        t2(d[n2]);
      }
    }
    v(n);
    n.getInstance = function(e2) {
      e2 = (!e2 || e2.length === 0 ? "$default_instance" : e2).toLowerCase();
      if (!Object.prototype.hasOwnProperty.call(n._iq, e2)) {
        n._iq[e2] = { _q: [] };
        v(n._iq[e2]);
      }
      return n._iq[e2];
    };
    e.amplitude = n;
  })(window, document);
};
var SESSION_STORAGE_KEYS = /* @__PURE__ */ ((SESSION_STORAGE_KEYS2) => {
  SESSION_STORAGE_KEYS2["RECORDING_START_TIME"] = "highlightRecordingStartTime";
  SESSION_STORAGE_KEYS2["SEGMENT_LAST_SENT_HASH_KEY"] = "HIGHLIGHT_SEGMENT_LAST_SENT_HASH_KEY";
  SESSION_STORAGE_KEYS2["SESSION_DATA"] = "sessionData";
  SESSION_STORAGE_KEYS2["SESSION_SECURE_ID"] = "sessionSecureID";
  SESSION_STORAGE_KEYS2["USER_IDENTIFIER"] = "highlightIdentifier";
  SESSION_STORAGE_KEYS2["USER_OBJECT"] = "highlightUserObject";
  SESSION_STORAGE_KEYS2["PAYLOAD_ID"] = "payloadId";
  return SESSION_STORAGE_KEYS2;
})(SESSION_STORAGE_KEYS || {});
const setupMixpanelIntegration = ({
  projectToken
}) => {
  if (window.mixpanel) {
    return;
  }
  (function(f, b) {
    if (!b.__SV) {
      var i, h;
      window.mixpanel = b;
      b._i = [];
      b.init = function(e, f2, c) {
        function g(a2, d) {
          var b2 = d.split(".");
          2 == b2.length && (a2 = a2[b2[0]], d = b2[1]);
          a2[d] = function() {
            a2.push(
              [d].concat(
                Array.prototype.slice.call(arguments, 0)
              )
            );
          };
        }
        var a = b;
        "undefined" !== typeof c ? a = b[c] = [] : c = "mixpanel";
        a.people = a.people || [];
        a.toString = function(a2) {
          var d = "mixpanel";
          "mixpanel" !== c && (d += "." + c);
          a2 || (d += " (stub)");
          return d;
        };
        a.people.toString = function() {
          return a.toString(1) + ".people (stub)";
        };
        i = "disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(
          " "
        );
        for (h = 0; h < i.length; h++)
          g(a, i[h]);
        var j = "set set_once union unset remove delete".split(" ");
        a.get_group = function() {
          function b2(c3) {
            d[c3] = function() {
              call2_args = arguments;
              call2 = [c3].concat(
                Array.prototype.slice.call(call2_args, 0)
              );
              a.push([e2, call2]);
            };
          }
          for (var d = {}, e2 = ["get_group"].concat(
            Array.prototype.slice.call(arguments, 0)
          ), c2 = 0; c2 < j.length; c2++)
            b2(j[c2]);
          return d;
        };
        b._i.push([e, f2, c]);
      };
      b.__SV = 1.2;
    }
  })(document, window.mixpanel || []);
  const script2 = document.createElement("script");
  script2.src = MixpanelBundle;
  document.head.appendChild(script2);
  script2.addEventListener("load", () => {
    var _a;
    (_a = window.mixpanel) == null ? void 0 : _a.init(projectToken);
  });
};
const MixpanelBundle = "https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";
function patch(source, name, replacement) {
  try {
    if (!(name in source)) {
      return () => {
      };
    }
    const original = source[name];
    const wrapped = replacement(original);
    if (typeof wrapped === "function") {
      wrapped.prototype = wrapped.prototype || {};
      Object.defineProperties(wrapped, {
        __rrweb_original__: {
          enumerable: false,
          value: original
        }
      });
    }
    source[name] = wrapped;
    return () => {
      source[name] = original;
    };
  } catch (e) {
    return () => {
    };
  }
}
function pathToSelector(node) {
  if (!node || !node.outerHTML) {
    return "";
  }
  let path = "";
  while (node.parentElement) {
    let name = node.localName;
    if (!name) {
      break;
    }
    name = name.toLowerCase();
    let parent = node.parentElement;
    let domSiblings = [];
    if (parent.children && parent.children.length > 0) {
      for (let i = 0; i < parent.children.length; i++) {
        let sibling = parent.children[i];
        if (sibling.localName && sibling.localName.toLowerCase) {
          if (sibling.localName.toLowerCase() === name) {
            domSiblings.push(sibling);
          }
        }
      }
    }
    if (domSiblings.length > 1) {
      name += ":eq(" + domSiblings.indexOf(node) + ")";
    }
    path = name + (path ? ">" + path : "");
    node = parent;
  }
  return path;
}
function isObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
function isObjTooDeep(obj, limit) {
  if (limit === 0) {
    return true;
  }
  const keys = Object.keys(obj);
  for (const key of keys) {
    if (isObject(obj[key]) && isObjTooDeep(obj[key], limit - 1)) {
      return true;
    }
  }
  return false;
}
function stringify$2(obj, stringifyOptions) {
  const options = {
    numOfKeysLimit: 50,
    depthOfLimit: 4
  };
  Object.assign(options, stringifyOptions);
  const stack = [];
  const keys = [];
  return JSON.stringify(obj, function(key, value) {
    if (stack.length > 0) {
      const thisPos = stack.indexOf(this);
      ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
      ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
      if (~stack.indexOf(value)) {
        if (stack[0] === value) {
          value = "[Circular ~]";
        } else {
          value = "[Circular ~." + keys.slice(0, stack.indexOf(value)).join(".") + "]";
        }
      }
    } else {
      stack.push(value);
    }
    if (value === null || value === void 0) {
      return value;
    }
    if (shouldIgnore(value)) {
      return toString(value);
    }
    if (value instanceof Event) {
      const eventResult = {};
      for (const eventKey in value) {
        const eventValue = value[eventKey];
        if (Array.isArray(eventValue)) {
          eventResult[eventKey] = pathToSelector(
            eventValue.length ? eventValue[0] : null
          );
        } else {
          eventResult[eventKey] = eventValue;
        }
      }
      return eventResult;
    } else if (value instanceof Node) {
      if (value instanceof HTMLElement) {
        return value ? value.outerHTML : "";
      }
      return value.nodeName;
    } else if (value instanceof Error) {
      return value.name + ": " + value.message;
    }
    return value;
  });
  function shouldIgnore(_obj) {
    if (isObject(_obj) && Object.keys(_obj).length > options.numOfKeysLimit) {
      return true;
    }
    if (typeof _obj === "function") {
      return true;
    }
    if (_obj instanceof Event && _obj.isTrusted === false) {
      const eventKeys = Object.keys(_obj);
      return eventKeys.length === 1;
    }
    if (isObject(_obj) && isObjTooDeep(_obj, options.depthOfLimit)) {
      return true;
    }
    return false;
  }
  function toString(_obj) {
    let str = _obj.toString();
    if (options.stringLengthLimit && str.length > options.stringLengthLimit) {
      str = `${str.slice(0, options.stringLengthLimit)}...`;
    }
    return str;
  }
}
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
}
var errorStackParser = { exports: {} };
var stackframe = { exports: {} };
var hasRequiredStackframe;
function requireStackframe() {
  if (hasRequiredStackframe)
    return stackframe.exports;
  hasRequiredStackframe = 1;
  (function(module, exports) {
    (function(root, factory) {
      {
        module.exports = factory();
      }
    })(commonjsGlobal, function() {
      function _isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }
      function _capitalize(str) {
        return str.charAt(0).toUpperCase() + str.substring(1);
      }
      function _getter(p) {
        return function() {
          return this[p];
        };
      }
      var booleanProps = ["isConstructor", "isEval", "isNative", "isToplevel"];
      var numericProps = ["columnNumber", "lineNumber"];
      var stringProps = ["fileName", "functionName", "source"];
      var arrayProps = ["args"];
      var objectProps = ["evalOrigin"];
      var props = booleanProps.concat(numericProps, stringProps, arrayProps, objectProps);
      function StackFrame(obj) {
        if (!obj)
          return;
        for (var i2 = 0; i2 < props.length; i2++) {
          if (obj[props[i2]] !== void 0) {
            this["set" + _capitalize(props[i2])](obj[props[i2]]);
          }
        }
      }
      StackFrame.prototype = {
        getArgs: function() {
          return this.args;
        },
        setArgs: function(v) {
          if (Object.prototype.toString.call(v) !== "[object Array]") {
            throw new TypeError("Args must be an Array");
          }
          this.args = v;
        },
        getEvalOrigin: function() {
          return this.evalOrigin;
        },
        setEvalOrigin: function(v) {
          if (v instanceof StackFrame) {
            this.evalOrigin = v;
          } else if (v instanceof Object) {
            this.evalOrigin = new StackFrame(v);
          } else {
            throw new TypeError("Eval Origin must be an Object or StackFrame");
          }
        },
        toString: function() {
          var fileName = this.getFileName() || "";
          var lineNumber = this.getLineNumber() || "";
          var columnNumber = this.getColumnNumber() || "";
          var functionName = this.getFunctionName() || "";
          if (this.getIsEval()) {
            if (fileName) {
              return "[eval] (" + fileName + ":" + lineNumber + ":" + columnNumber + ")";
            }
            return "[eval]:" + lineNumber + ":" + columnNumber;
          }
          if (functionName) {
            return functionName + " (" + fileName + ":" + lineNumber + ":" + columnNumber + ")";
          }
          return fileName + ":" + lineNumber + ":" + columnNumber;
        }
      };
      StackFrame.fromString = function StackFrame$$fromString(str) {
        var argsStartIndex = str.indexOf("(");
        var argsEndIndex = str.lastIndexOf(")");
        var functionName = str.substring(0, argsStartIndex);
        var args = str.substring(argsStartIndex + 1, argsEndIndex).split(",");
        var locationString = str.substring(argsEndIndex + 1);
        if (locationString.indexOf("@") === 0) {
          var parts = /@(.+?)(?::(\d+))?(?::(\d+))?$/.exec(locationString, "");
          var fileName = parts[1];
          var lineNumber = parts[2];
          var columnNumber = parts[3];
        }
        return new StackFrame({
          functionName,
          args: args || void 0,
          fileName,
          lineNumber: lineNumber || void 0,
          columnNumber: columnNumber || void 0
        });
      };
      for (var i = 0; i < booleanProps.length; i++) {
        StackFrame.prototype["get" + _capitalize(booleanProps[i])] = _getter(booleanProps[i]);
        StackFrame.prototype["set" + _capitalize(booleanProps[i])] = function(p) {
          return function(v) {
            this[p] = Boolean(v);
          };
        }(booleanProps[i]);
      }
      for (var j = 0; j < numericProps.length; j++) {
        StackFrame.prototype["get" + _capitalize(numericProps[j])] = _getter(numericProps[j]);
        StackFrame.prototype["set" + _capitalize(numericProps[j])] = function(p) {
          return function(v) {
            if (!_isNumber(v)) {
              throw new TypeError(p + " must be a Number");
            }
            this[p] = Number(v);
          };
        }(numericProps[j]);
      }
      for (var k = 0; k < stringProps.length; k++) {
        StackFrame.prototype["get" + _capitalize(stringProps[k])] = _getter(stringProps[k]);
        StackFrame.prototype["set" + _capitalize(stringProps[k])] = function(p) {
          return function(v) {
            this[p] = String(v);
          };
        }(stringProps[k]);
      }
      return StackFrame;
    });
  })(stackframe);
  return stackframe.exports;
}
(function(module, exports) {
  (function(root, factory) {
    {
      module.exports = factory(requireStackframe());
    }
  })(commonjsGlobal, function ErrorStackParser2(StackFrame) {
    var FIREFOX_SAFARI_STACK_REGEXP = /(^|@)\S+:\d+/;
    var CHROME_IE_STACK_REGEXP = /^\s*at .*(\S+:\d+|\(native\))/m;
    var SAFARI_NATIVE_CODE_REGEXP = /^(eval@)?(\[native code])?$/;
    return {
      /**
       * Given an Error object, extract the most information from it.
       *
       * @param {Error} error object
       * @return {Array} of StackFrames
       */
      parse: function ErrorStackParser$$parse(error) {
        if (typeof error.stacktrace !== "undefined" || typeof error["opera#sourceloc"] !== "undefined") {
          return this.parseOpera(error);
        } else if (error.stack && error.stack.match(CHROME_IE_STACK_REGEXP)) {
          return this.parseV8OrIE(error);
        } else if (error.stack) {
          return this.parseFFOrSafari(error);
        } else {
          throw new Error("Cannot parse given Error object");
        }
      },
      // Separate line and column numbers from a string of the form: (URI:Line:Column)
      extractLocation: function ErrorStackParser$$extractLocation(urlLike) {
        if (urlLike.indexOf(":") === -1) {
          return [urlLike];
        }
        var regExp = /(.+?)(?::(\d+))?(?::(\d+))?$/;
        var parts = regExp.exec(urlLike.replace(/[()]/g, ""));
        return [parts[1], parts[2] || void 0, parts[3] || void 0];
      },
      parseV8OrIE: function ErrorStackParser$$parseV8OrIE(error) {
        var filtered = error.stack.split("\n").filter(function(line) {
          return !!line.match(CHROME_IE_STACK_REGEXP);
        }, this);
        return filtered.map(function(line) {
          if (line.indexOf("(eval ") > -1) {
            line = line.replace(/eval code/g, "eval").replace(/(\(eval at [^()]*)|(\),.*$)/g, "");
          }
          var sanitizedLine = line.replace(/^\s+/, "").replace(/\(eval code/g, "(");
          var location = sanitizedLine.match(/ (\((.+):(\d+):(\d+)\)$)/);
          sanitizedLine = location ? sanitizedLine.replace(location[0], "") : sanitizedLine;
          var tokens = sanitizedLine.split(/\s+/).slice(1);
          var locationParts = this.extractLocation(location ? location[1] : tokens.pop());
          var functionName = tokens.join(" ") || void 0;
          var fileName = ["eval", "<anonymous>"].indexOf(locationParts[0]) > -1 ? void 0 : locationParts[0];
          return new StackFrame({
            functionName,
            fileName,
            lineNumber: locationParts[1],
            columnNumber: locationParts[2],
            source: line
          });
        }, this);
      },
      parseFFOrSafari: function ErrorStackParser$$parseFFOrSafari(error) {
        var filtered = error.stack.split("\n").filter(function(line) {
          return !line.match(SAFARI_NATIVE_CODE_REGEXP);
        }, this);
        return filtered.map(function(line) {
          if (line.indexOf(" > eval") > -1) {
            line = line.replace(/ line (\d+)(?: > eval line \d+)* > eval:\d+:\d+/g, ":$1");
          }
          if (line.indexOf("@") === -1 && line.indexOf(":") === -1) {
            return new StackFrame({
              functionName: line
            });
          } else {
            var functionNameRegex = /((.*".+"[^@]*)?[^@]*)(?:@)/;
            var matches = line.match(functionNameRegex);
            var functionName = matches && matches[1] ? matches[1] : void 0;
            var locationParts = this.extractLocation(line.replace(functionNameRegex, ""));
            return new StackFrame({
              functionName,
              fileName: locationParts[0],
              lineNumber: locationParts[1],
              columnNumber: locationParts[2],
              source: line
            });
          }
        }, this);
      },
      parseOpera: function ErrorStackParser$$parseOpera(e) {
        if (!e.stacktrace || e.message.indexOf("\n") > -1 && e.message.split("\n").length > e.stacktrace.split("\n").length) {
          return this.parseOpera9(e);
        } else if (!e.stack) {
          return this.parseOpera10(e);
        } else {
          return this.parseOpera11(e);
        }
      },
      parseOpera9: function ErrorStackParser$$parseOpera9(e) {
        var lineRE = /Line (\d+).*script (?:in )?(\S+)/i;
        var lines = e.message.split("\n");
        var result = [];
        for (var i = 2, len = lines.length; i < len; i += 2) {
          var match = lineRE.exec(lines[i]);
          if (match) {
            result.push(new StackFrame({
              fileName: match[2],
              lineNumber: match[1],
              source: lines[i]
            }));
          }
        }
        return result;
      },
      parseOpera10: function ErrorStackParser$$parseOpera10(e) {
        var lineRE = /Line (\d+).*script (?:in )?(\S+)(?:: In function (\S+))?$/i;
        var lines = e.stacktrace.split("\n");
        var result = [];
        for (var i = 0, len = lines.length; i < len; i += 2) {
          var match = lineRE.exec(lines[i]);
          if (match) {
            result.push(
              new StackFrame({
                functionName: match[3] || void 0,
                fileName: match[2],
                lineNumber: match[1],
                source: lines[i]
              })
            );
          }
        }
        return result;
      },
      // Opera 10.65+ Error.stack very similar to FF/Safari
      parseOpera11: function ErrorStackParser$$parseOpera11(error) {
        var filtered = error.stack.split("\n").filter(function(line) {
          return !!line.match(FIREFOX_SAFARI_STACK_REGEXP) && !line.match(/^Error created at/);
        }, this);
        return filtered.map(function(line) {
          var tokens = line.split("@");
          var locationParts = this.extractLocation(tokens.pop());
          var functionCall = tokens.shift() || "";
          var functionName = functionCall.replace(/<anonymous function(: (\w+))?>/, "$2").replace(/\([^)]*\)/g, "") || void 0;
          var argsRaw;
          if (functionCall.match(/\(([^)]*)\)/)) {
            argsRaw = functionCall.replace(/^[^(]+\(([^)]*)\)$/, "$1");
          }
          var args = argsRaw === void 0 || argsRaw === "[arguments not available]" ? void 0 : argsRaw.split(",");
          return new StackFrame({
            functionName,
            args,
            fileName: locationParts[0],
            lineNumber: locationParts[1],
            columnNumber: locationParts[2],
            source: line
          });
        }, this);
      }
    };
  });
})(errorStackParser);
var errorStackParserExports = errorStackParser.exports;
const ErrorStackParser = /* @__PURE__ */ getDefaultExportFromCjs(errorStackParserExports);
function ConsoleListener(callback, logOptions) {
  const loggerType = logOptions.logger;
  if (!loggerType) {
    return () => {
    };
  }
  let logger;
  if (typeof loggerType === "string") {
    logger = window[loggerType];
  } else {
    logger = loggerType;
  }
  const cancelHandlers = [];
  if (logOptions.level.includes("error")) {
    if (window) {
      const errorHandler = (event) => {
        const { message, error } = event;
        let trace = [];
        if (error) {
          trace = ErrorStackParser.parse(error);
        }
        const payload = [
          stringify$2(message, logOptions.stringifyOptions)
        ];
        callback({
          type: "Error",
          trace,
          time: Date.now(),
          value: payload
        });
      };
      window.addEventListener("error", errorHandler);
      cancelHandlers.push(() => {
        if (window)
          window.removeEventListener("error", errorHandler);
      });
    }
  }
  for (const levelType of logOptions.level) {
    cancelHandlers.push(replace(logger, levelType));
  }
  return () => {
    cancelHandlers.forEach((h) => h());
  };
  function replace(_logger, level) {
    if (!_logger[level]) {
      return () => {
      };
    }
    return patch(_logger, level, (original) => {
      return (...args) => {
        original.apply(this, args);
        try {
          const trace = ErrorStackParser.parse(new Error());
          const payload = args.map(
            (s) => stringify$2(s, logOptions.stringifyOptions)
          );
          callback({
            type: level,
            trace: trace.slice(1),
            value: payload,
            time: Date.now()
          });
        } catch (error) {
          original("highlight logger error:", error, ...args);
        }
      };
    });
  }
}
var stringify$1 = { exports: {} };
(function(module, exports) {
  exports = module.exports = stringify2;
  exports.getSerialize = serializer;
  function stringify2(obj, replacer, spaces, cycleReplacer) {
    return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces);
  }
  function serializer(replacer, cycleReplacer) {
    var stack = [], keys = [];
    if (cycleReplacer == null)
      cycleReplacer = function(key, value) {
        if (stack[0] === value)
          return "[Circular ~]";
        return "[Circular ~." + keys.slice(0, stack.indexOf(value)).join(".") + "]";
      };
    return function(key, value) {
      if (stack.length > 0) {
        var thisPos = stack.indexOf(this);
        ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
        ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
        if (~stack.indexOf(value))
          value = cycleReplacer.call(this, key, value);
      } else
        stack.push(value);
      return replacer == null ? value : replacer.call(this, key, value);
    };
  }
})(stringify$1, stringify$1.exports);
var stringifyExports = stringify$1.exports;
const stringify = /* @__PURE__ */ getDefaultExportFromCjs(stringifyExports);
function handleError(callback, event, source, error) {
  var _a, _b, _c, _d;
  const res = ErrorStackParser.parse(error);
  if (event instanceof Error) {
    event = event.message;
  }
  const framesToUse = removeHighlightFrameIfExists(res);
  callback({
    event: stringify(event),
    type: "window.onerror",
    url: window.location.href,
    source: source != null ? source : "",
    lineNumber: ((_a = framesToUse[0]) == null ? void 0 : _a.lineNumber) ? (_b = framesToUse[0]) == null ? void 0 : _b.lineNumber : 0,
    columnNumber: ((_c = framesToUse[0]) == null ? void 0 : _c.columnNumber) ? (_d = framesToUse[0]) == null ? void 0 : _d.columnNumber : 0,
    stackTrace: framesToUse,
    timestamp: (/* @__PURE__ */ new Date()).toISOString()
  });
}
const ErrorListener = (callback) => {
  if (typeof window === "undefined")
    return () => {
    };
  const initialOnError = window.onerror = (event, source, lineno, colno, error) => {
    handleError(callback, event, source, error != null ? error : Error());
  };
  const initialOnUnhandledRejection = window.onunhandledrejection = (event) => {
    var _a;
    if (event.reason) {
      const hPromise = event.promise;
      if (hPromise.getStack) {
        handleError(
          callback,
          event.reason,
          event.type,
          (_a = hPromise.getStack()) != null ? _a : Error()
        );
      } else {
        handleError(callback, event.reason, event.type, Error());
      }
    }
  };
  const initialPromise = window.Promise.constructor;
  window.Promise.constructor = function(executor) {
    this.promiseCreationError = new Error();
    initialPromise(executor);
  };
  window.Promise.prototype.getStack = function() {
    return this.promiseCreationError;
  };
  return () => {
    window.Promise.constructor = initialPromise;
    window.onunhandledrejection = initialOnUnhandledRejection;
    window.onerror = initialOnError;
  };
};
const removeHighlightFrameIfExists = (frames) => {
  var _a;
  if (frames.length === 0) {
    return frames;
  }
  const firstFrame = frames[0];
  if (firstFrame.functionName === "console.error" && ((_a = firstFrame.fileName) == null ? void 0 : _a.includes("highlight.run")) || firstFrame.functionName === "new HighlightPromise") {
    return frames.slice(1);
  }
  return frames;
};
const ALL_CONSOLE_METHODS = [
  "assert",
  "count",
  "countReset",
  "debug",
  "dir",
  "dirxml",
  "error",
  "group",
  "groupCollapsed",
  "groupEnd",
  "info",
  "log",
  "table",
  "time",
  "timeEnd",
  "timeLog",
  "trace",
  "warn"
];
const ERRORS_TO_IGNORE = [
  '["\\"Script error.\\""]',
  '[{"isTrusted":true}]',
  '["{}"]',
  '[""]',
  '["\\"\\""]'
];
const ERROR_PATTERNS_TO_IGNORE = [
  "websocket error",
  '\\"ResizeObserver loop'
];
const sanitizeRequest = (request, headersToRedact, headersToRecord) => {
  const newHeaders = sanitizeHeaders(
    headersToRedact,
    request.headers,
    headersToRecord
  );
  return __spreadProps(__spreadValues({}, request), {
    headers: newHeaders
  });
};
const sanitizeResponse = (response, headersToRedact, headersToRecord) => {
  const newHeaders = sanitizeHeaders(
    headersToRedact,
    response.headers,
    headersToRecord
  );
  return __spreadProps(__spreadValues({}, response), {
    headers: newHeaders
  });
};
const sanitizeHeaders = (headersToRedact, headers, headersToRecord) => {
  var _a, _b;
  const newHeaders = __spreadValues({}, headers);
  if (headersToRecord) {
    (_a = Object.keys(newHeaders)) == null ? void 0 : _a.forEach((header) => {
      if (![...headersToRecord].includes(header == null ? void 0 : header.toLowerCase())) {
        newHeaders[header] = "[REDACTED]";
      }
    });
    return newHeaders;
  }
  (_b = Object.keys(newHeaders)) == null ? void 0 : _b.forEach((header) => {
    if ([...SENSITIVE_HEADERS, ...headersToRedact].includes(
      header == null ? void 0 : header.toLowerCase()
    )) {
      newHeaders[header] = "[REDACTED]";
    }
  });
  return newHeaders;
};
const SENSITIVE_HEADERS = [
  "authorization",
  "cookie",
  "proxy-authorization",
  "token"
];
const DEFAULT_URL_BLOCKLIST = [
  "https://www.googleapis.com/identitytoolkit",
  "https://securetoken.googleapis.com"
];
const HIGHLIGHT_REQUEST_HEADER = "X-Highlight-Request";
const normalizeUrl = (url) => {
  let urlToMutate = url;
  if (!url.startsWith("https://") && !url.startsWith("http://")) {
    urlToMutate = `${window.location.origin}${urlToMutate}`;
  }
  return urlToMutate.replace(/\/+$/, "");
};
const matchPerformanceTimingsWithRequestResponsePair = (performanceTimings, requestResponsePairs, type) => {
  performanceTimings.sort((a, b) => a.responseEnd - b.responseEnd);
  const initialGroupedPerformanceTimings = {
    xmlhttprequest: {},
    others: {},
    fetch: {}
  };
  const groupedPerformanceTimings = performanceTimings.reduce((previous, performanceTiming) => {
    const url = normalizeUrl(performanceTiming.name);
    if (performanceTiming.initiatorType === type) {
      previous[type][url] = [
        ...previous[type][url] || [],
        performanceTiming
      ];
    } else {
      previous.others[url] = [
        ...previous.others[url] || [],
        performanceTiming
      ];
    }
    return previous;
  }, initialGroupedPerformanceTimings);
  let groupedRequestResponsePairs = {};
  groupedRequestResponsePairs = requestResponsePairs.reduce(
    (previous, requestResponsePair) => {
      const url = normalizeUrl(requestResponsePair.request.url);
      previous[url] = [...previous[url] || [], requestResponsePair];
      return previous;
    },
    groupedRequestResponsePairs
  );
  for (let url in groupedPerformanceTimings[type]) {
    const performanceTimingsForUrl = groupedPerformanceTimings[type][url];
    const requestResponsePairsForUrl = groupedRequestResponsePairs[url];
    if (!requestResponsePairsForUrl) {
      continue;
    }
    const offset = Math.max(
      performanceTimingsForUrl.length - requestResponsePairsForUrl.length,
      0
    );
    for (let i = offset; i < performanceTimingsForUrl.length; i++) {
      if (performanceTimingsForUrl[i]) {
        performanceTimingsForUrl[i].requestResponsePair = requestResponsePairsForUrl[i - offset];
      }
    }
  }
  let result = [];
  for (let type2 in groupedPerformanceTimings) {
    for (let url in groupedPerformanceTimings[type2]) {
      result = result.concat(groupedPerformanceTimings[type2][url]);
    }
  }
  return result.sort((a, b) => a.fetchStart - b.fetchStart).map((performanceTiming) => {
    performanceTiming.toJSON = function() {
      return {
        initiatorType: this.initiatorType,
        // deprecated, use the absolute versions instead
        startTime: this.startTime,
        responseEnd: this.responseEnd,
        // offset by `window.performance.timeOrigin` to get absolute timestamps
        startTimeAbs: window.performance.timeOrigin + this.startTime,
        responseEndAbs: window.performance.timeOrigin + this.responseEnd,
        name: this.name,
        transferSize: this.transferSize,
        encodedBodySize: this.encodedBodySize,
        requestResponsePairs: this.requestResponsePair
      };
    };
    return performanceTiming;
  });
};
const isHighlightNetworkResourceFilter = (name, backendUrl) => name.toLocaleLowerCase().includes(
  "https://pub.highlight.run"
) || name.toLocaleLowerCase().includes("highlight.run") || name.toLocaleLowerCase().includes(backendUrl);
const shouldNetworkRequestBeRecorded = (url, highlightBackendUrl, tracingOrigins) => {
  return !isHighlightNetworkResourceFilter(url, highlightBackendUrl) || shouldNetworkRequestBeTraced(url, tracingOrigins);
};
const shouldNetworkRequestBeTraced = (url, tracingOrigins) => {
  var _a;
  let patterns = [];
  if (tracingOrigins === true) {
    patterns = ["localhost", /^\//];
    if ((_a = window == null ? void 0 : window.location) == null ? void 0 : _a.host) {
      patterns.push(window.location.host);
    }
  } else if (tracingOrigins instanceof Array) {
    patterns = tracingOrigins;
  }
  let result = false;
  patterns.forEach((pattern) => {
    if (url.match(pattern)) {
      result = true;
    }
  });
  return result;
};
function makeId(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return result;
}
const createNetworkRequestId = () => {
  return makeId(10);
};
const getHighlightRequestHeader = (sessionSecureID, requestId) => {
  return sessionSecureID + "/" + requestId;
};
const XHRListener = (callback, backendUrl, tracingOrigins, urlBlocklist, sessionSecureID, bodyKeysToRedact, bodyKeysToRecord) => {
  const XHR = XMLHttpRequest.prototype;
  const originalOpen = XHR.open;
  const originalSend = XHR.send;
  const originalSetRequestHeader = XHR.setRequestHeader;
  XHR.open = function(method, url) {
    this._method = method;
    this._url = url;
    this._requestHeaders = {};
    this._shouldRecordHeaderAndBody = !urlBlocklist.some(
      (blockedUrl) => url.toLowerCase().includes(blockedUrl)
    );
    return originalOpen.apply(this, arguments);
  };
  XHR.setRequestHeader = function(header, value) {
    this._requestHeaders[header] = value;
    return originalSetRequestHeader.apply(this, arguments);
  };
  XHR.send = function(postData) {
    if (!shouldNetworkRequestBeRecorded(
      this._url,
      backendUrl,
      tracingOrigins
    )) {
      return originalSend.apply(this, arguments);
    }
    const requestId = createNetworkRequestId();
    if (shouldNetworkRequestBeTraced(this._url, tracingOrigins)) {
      this.setRequestHeader(
        HIGHLIGHT_REQUEST_HEADER,
        getHighlightRequestHeader(sessionSecureID, requestId)
      );
    }
    const shouldRecordHeaderAndBody = this._shouldRecordHeaderAndBody;
    const requestModel = {
      id: requestId,
      url: this._url,
      verb: this._method,
      headers: shouldRecordHeaderAndBody ? this._requestHeaders : {},
      body: void 0
    };
    if (shouldRecordHeaderAndBody) {
      if (postData) {
        const bodyData = getBodyData(postData, requestModel.url);
        if (bodyData) {
          requestModel["body"] = getBodyThatShouldBeRecorded(
            bodyData,
            bodyKeysToRedact,
            bodyKeysToRecord,
            requestModel.headers
          );
        }
      }
    }
    this.addEventListener("load", function() {
      return __async(this, null, function* () {
        const responseModel = {
          status: this.status,
          headers: {},
          body: void 0
        };
        if (shouldRecordHeaderAndBody) {
          const responseHeaders = this.getAllResponseHeaders();
          const normalizedResponseHeaders = responseHeaders.trim().split(/[\r\n]+/);
          const headerMap = {};
          normalizedResponseHeaders.forEach(function(line) {
            const parts = line.split(": ");
            const header = parts.shift();
            headerMap[header] = parts.join(": ");
          });
          responseModel.headers = headerMap;
          if (postData) {
            const bodyData = getBodyData(postData, requestModel.url);
            if (bodyData) {
              requestModel["body"] = getBodyThatShouldBeRecorded(
                bodyData,
                bodyKeysToRedact,
                bodyKeysToRecord,
                responseModel.headers
              );
            }
          }
          if (this.responseType === "" || this.responseType === "text") {
            responseModel["body"] = getBodyThatShouldBeRecorded(
              this.responseText,
              bodyKeysToRedact,
              bodyKeysToRecord,
              responseModel.headers
            );
            responseModel["size"] = this.responseText.length * 8;
          } else if (this.responseType === "blob") {
            if (this.response instanceof Blob) {
              try {
                const response = yield this.response.text();
                responseModel["body"] = getBodyThatShouldBeRecorded(
                  response,
                  bodyKeysToRedact,
                  bodyKeysToRecord,
                  responseModel.headers
                );
                responseModel["size"] = this.response.size;
              } catch (e) {
              }
            }
          } else {
            try {
              responseModel["body"] = getBodyThatShouldBeRecorded(
                this.response,
                bodyKeysToRedact,
                bodyKeysToRecord,
                responseModel.headers
              );
            } catch (e) {
            }
          }
        }
        const event = {
          request: requestModel,
          response: responseModel,
          urlBlocked: !shouldRecordHeaderAndBody
        };
        callback(event);
      });
    });
    this.addEventListener("error", function() {
      return __async(this, null, function* () {
        const responseModel = {
          status: this.status,
          headers: void 0,
          body: void 0
        };
        const event = {
          request: requestModel,
          response: responseModel,
          urlBlocked: false
        };
        callback(event);
      });
    });
    return originalSend.apply(this, arguments);
  };
  return () => {
    XHR.open = originalOpen;
    XHR.send = originalSend;
    XHR.setRequestHeader = originalSetRequestHeader;
  };
};
const getBodyData = (postData, url) => {
  if (typeof postData === "string") {
    if (!(((url == null ? void 0 : url.includes("localhost")) || (url == null ? void 0 : url.includes("highlight.run"))) && postData.includes("pushPayload"))) {
      return postData;
    }
  } else if (typeof postData === "object" || typeof postData === "number" || typeof postData === "boolean") {
    return postData.toString();
  }
  return null;
};
const DEFAULT_BODY_LIMIT = 64 * 1024;
const BODY_SIZE_LIMITS = {
  "application/json": 64 * 1024 * 1024,
  // MB
  "text/plain": 64 * 1024 * 1024
  // MB
};
const getBodyThatShouldBeRecorded = (bodyData, bodyKeysToRedact, bodyKeysToRecord, headers) => {
  var _a, _b, _c;
  let bodyLimit = DEFAULT_BODY_LIMIT;
  if (headers) {
    let contentType = "";
    if (typeof headers["get"] === "function") {
      contentType = (_a = headers.get("content-type")) != null ? _a : "";
    } else {
      contentType = (_b = headers["content-type"]) != null ? _b : "";
    }
    try {
      contentType = contentType.split(";")[0];
    } catch (e) {
    }
    bodyLimit = (_c = BODY_SIZE_LIMITS[contentType]) != null ? _c : DEFAULT_BODY_LIMIT;
  }
  if (bodyData) {
    if (bodyKeysToRedact) {
      try {
        const json = JSON.parse(bodyData);
        Object.keys(json).forEach((key) => {
          if (bodyKeysToRedact.includes(key.toLocaleLowerCase())) {
            json[key] = "[REDACTED]";
          }
        });
        bodyData = JSON.stringify(json);
      } catch (e) {
      }
    }
    if (bodyKeysToRecord) {
      try {
        const json = JSON.parse(bodyData);
        Object.keys(json).forEach((key) => {
          if (!bodyKeysToRecord.includes(key.toLocaleLowerCase())) {
            json[key] = "[REDACTED]";
          }
        });
        bodyData = JSON.stringify(json);
      } catch (e) {
      }
    }
  }
  try {
    bodyData = bodyData.slice(0, bodyLimit);
  } catch (e) {
  }
  return bodyData;
};
const FetchListener = (callback, backendUrl, tracingOrigins, urlBlocklist, sessionSecureID, bodyKeysToRedact, bodyKeysToRecord) => {
  const originalFetch = window._fetchProxy;
  window._fetchProxy = function(input, init) {
    const { method, url } = getFetchRequestProperties(input, init);
    if (!shouldNetworkRequestBeRecorded(url, backendUrl, tracingOrigins)) {
      return originalFetch.call(this, input, init);
    }
    const requestId = createNetworkRequestId();
    if (shouldNetworkRequestBeTraced(url, tracingOrigins)) {
      init = init || {};
      let headers = new Headers(init.headers);
      if (input instanceof Request) {
        [...input.headers].forEach(
          ([key, value]) => headers.set(key, value)
        );
      }
      headers.set(
        HIGHLIGHT_REQUEST_HEADER,
        getHighlightRequestHeader(sessionSecureID, requestId)
      );
      init.headers = Object.fromEntries(headers.entries());
    }
    const request = {
      id: requestId,
      headers: {},
      body: void 0,
      url,
      verb: method
    };
    const shouldRecordHeaderAndBody = !urlBlocklist.some(
      (blockedUrl) => url.toLowerCase().includes(blockedUrl)
    );
    if (shouldRecordHeaderAndBody) {
      request.headers = Object.fromEntries(
        new Headers(init == null ? void 0 : init.headers).entries()
      );
      request.body = getBodyThatShouldBeRecorded(
        init == null ? void 0 : init.body,
        bodyKeysToRedact,
        bodyKeysToRecord,
        init == null ? void 0 : init.headers
      );
    }
    let responsePromise = originalFetch.call(this, input, init);
    logRequest(
      responsePromise,
      request,
      callback,
      shouldRecordHeaderAndBody,
      bodyKeysToRedact,
      bodyKeysToRecord
    );
    return responsePromise;
  };
  return () => {
    window._fetchProxy = originalFetch;
  };
};
const getFetchRequestProperties = (input, init) => {
  const method = init && init.method || typeof input === "object" && "method" in input && input.method || "GET";
  let url;
  if (typeof input === "object") {
    if ("url" in input && input.url) {
      url = input.url;
    } else {
      url = input.toString();
    }
  } else {
    url = input;
  }
  return {
    method,
    url
  };
};
const logRequest = (responsePromise, requestPayload, callback, shouldRecordHeaderAndBody, bodyKeysToRedact, bodyKeysToRecord) => {
  const onPromiseResolveHandler = (response) => __async(void 0, null, function* () {
    let responsePayload = {
      body: void 0,
      headers: void 0,
      status: 0,
      size: 0
    };
    let requestHandled = false;
    if ("stack" in response || response instanceof Error) {
      responsePayload = __spreadProps(__spreadValues({}, responsePayload), {
        body: response.message,
        status: 0,
        size: void 0
      });
      requestHandled = true;
    } else if ("status" in response) {
      responsePayload = __spreadProps(__spreadValues({}, responsePayload), {
        status: response.status
      });
      if (shouldRecordHeaderAndBody) {
        let text;
        try {
          const clone = response.clone();
          const body = clone.body;
          if (body) {
            let reader = body.getReader();
            let utf8Decoder = new TextDecoder();
            let nextChunk;
            let result = "";
            while (!(nextChunk = yield reader.read()).done) {
              let partialData = nextChunk.value;
              result += utf8Decoder.decode(partialData);
            }
            text = result;
            text = getBodyThatShouldBeRecorded(
              text,
              bodyKeysToRedact,
              bodyKeysToRecord,
              response.headers
            );
          } else {
            text = "";
          }
        } catch (e) {
          text = `Unable to clone response: ${e}`;
        }
        responsePayload.body = text;
        responsePayload.headers = Object.fromEntries(
          response.headers.entries()
        );
        responsePayload.size = text.length * 8;
      }
      requestHandled = true;
    }
    if (requestHandled) {
      const event = {
        request: requestPayload,
        response: responsePayload,
        urlBlocked: !shouldRecordHeaderAndBody
      };
      callback(event);
    }
  });
  responsePromise.then(onPromiseResolveHandler).catch(() => {
  });
};
const WebSocketListener = (requestCallback, eventCallback, urlBlocklist) => {
  const initialHighlightWebSocketRequestCallback = window._highlightWebSocketRequestCallback;
  window._highlightWebSocketRequestCallback = requestCallback;
  const initialHighlightWebSocketEventCallback = window._highlightWebSocketEventCallback;
  window._highlightWebSocketEventCallback = (eventData) => {
    const _a = eventData, { message, size } = _a, visibleEventData = __objRest(_a, ["message", "size"]);
    const blockedUrl = urlBlocklist.some(
      (blockedUrl2) => eventData.name.toLowerCase().includes(blockedUrl2)
    );
    const redactedEventData = blockedUrl ? visibleEventData : eventData;
    eventCallback(redactedEventData);
  };
  return () => {
    window._highlightWebSocketRequestCallback = initialHighlightWebSocketRequestCallback;
    window._highlightWebSocketEventCallback = initialHighlightWebSocketEventCallback;
  };
};
const NetworkListener = ({
  xhrCallback,
  fetchCallback,
  webSocketRequestCallback,
  webSocketEventCallback,
  disableWebSocketRecording,
  headersToRedact,
  bodyKeysToRedact,
  backendUrl,
  tracingOrigins,
  urlBlocklist,
  sessionSecureID,
  bodyKeysToRecord,
  headerKeysToRecord
}) => {
  const removeXHRListener = XHRListener(
    (requestResponsePair) => {
      xhrCallback(
        sanitizeRequestResponsePair(
          requestResponsePair,
          headersToRedact,
          headerKeysToRecord
        )
      );
    },
    backendUrl,
    tracingOrigins,
    urlBlocklist,
    sessionSecureID,
    bodyKeysToRedact,
    bodyKeysToRecord
  );
  const removeFetchListener = FetchListener(
    (requestResponsePair) => {
      fetchCallback(
        sanitizeRequestResponsePair(
          requestResponsePair,
          headersToRedact,
          headerKeysToRecord
        )
      );
    },
    backendUrl,
    tracingOrigins,
    urlBlocklist,
    sessionSecureID,
    bodyKeysToRedact,
    bodyKeysToRecord
  );
  const removeWebSocketListener = !disableWebSocketRecording ? WebSocketListener(
    webSocketRequestCallback,
    webSocketEventCallback,
    urlBlocklist
  ) : () => {
  };
  return () => {
    removeXHRListener();
    removeFetchListener();
    removeWebSocketListener();
  };
};
const sanitizeRequestResponsePair = (_a, headersToRedact, headersToRecord) => {
  var _b = _a, { request, response } = _b, rest = __objRest(_b, ["request", "response"]);
  return __spreadValues({
    request: sanitizeRequest(request, headersToRedact, headersToRecord),
    response: sanitizeResponse(response, headersToRedact, headersToRecord)
  }, rest);
};
class FirstLoadListeners {
  constructor(options) {
    __publicField(this, "disableConsoleRecording");
    __publicField(this, "reportConsoleErrors");
    __publicField(this, "consoleMethodsToRecord");
    __publicField(this, "listeners");
    __publicField(this, "errors");
    __publicField(this, "messages");
    // The properties below were added in 4.0.0 (Feb 2022), and are patched in by client via setupNetworkListeners()
    __publicField(this, "options");
    __publicField(this, "hasNetworkRecording", true);
    __publicField(this, "_backendUrl");
    __publicField(this, "disableNetworkRecording");
    __publicField(this, "enableRecordingNetworkContents");
    __publicField(this, "xhrNetworkContents");
    __publicField(this, "fetchNetworkContents");
    __publicField(this, "disableRecordingWebSocketContents");
    __publicField(this, "webSocketNetworkContents");
    __publicField(this, "webSocketEventContents");
    __publicField(this, "tracingOrigins");
    __publicField(this, "networkHeadersToRedact");
    __publicField(this, "networkBodyKeysToRedact");
    __publicField(this, "networkBodyKeysToRecord");
    __publicField(this, "networkHeaderKeysToRecord");
    __publicField(this, "urlBlocklist");
    var _a;
    this.options = options;
    this.disableConsoleRecording = !!options.disableConsoleRecording;
    this.reportConsoleErrors = (_a = options.reportConsoleErrors) != null ? _a : false;
    this.consoleMethodsToRecord = options.consoleMethodsToRecord || [
      ...ALL_CONSOLE_METHODS
    ];
    this.listeners = [];
    this.errors = [];
    this.messages = [];
  }
  isListening() {
    return this.listeners.length > 0;
  }
  startListening() {
    if (this.isListening())
      return;
    const highlightThis = this;
    if (!this.disableConsoleRecording) {
      this.listeners.push(
        ConsoleListener(
          (c) => {
            var _a, _b, _c;
            if (this.reportConsoleErrors && (c.type === "Error" || c.type === "error") && c.value && c.trace) {
              const errorValue = stringify(c.value);
              if (ERRORS_TO_IGNORE.includes(errorValue) || ERROR_PATTERNS_TO_IGNORE.some(
                (pattern) => errorValue.includes(pattern)
              )) {
                return;
              }
              highlightThis.errors.push({
                event: errorValue,
                type: "console.error",
                url: window.location.href,
                source: ((_a = c.trace[0]) == null ? void 0 : _a.fileName) ? c.trace[0].fileName : "",
                lineNumber: ((_b = c.trace[0]) == null ? void 0 : _b.lineNumber) ? c.trace[0].lineNumber : 0,
                columnNumber: ((_c = c.trace[0]) == null ? void 0 : _c.columnNumber) ? c.trace[0].columnNumber : 0,
                stackTrace: c.trace,
                timestamp: (/* @__PURE__ */ new Date()).toISOString()
              });
            }
            highlightThis.messages.push(c);
          },
          {
            level: this.consoleMethodsToRecord,
            logger: "console",
            stringifyOptions: {
              depthOfLimit: 10,
              numOfKeysLimit: 100,
              stringLengthLimit: 1e3
            }
          }
        )
      );
    }
    this.listeners.push(
      ErrorListener((e) => highlightThis.errors.push(e))
    );
    FirstLoadListeners.setupNetworkListener(this, this.options);
  }
  stopListening() {
    this.listeners.forEach((stop) => stop());
    this.listeners = [];
  }
  // We define this as a static method because versions earlier than 4.0.0 (Feb 2022) don't have this code.
  // For those versions, calling this from client will monkey-patch the network listeners onto the old FirstLoadListener object.
  static setupNetworkListener(sThis, options) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
    sThis._backendUrl = (options == null ? void 0 : options.backendUrl) || "https://pub.highlight.run";
    sThis.xhrNetworkContents = [];
    sThis.fetchNetworkContents = [];
    sThis.webSocketNetworkContents = [];
    sThis.webSocketEventContents = [];
    sThis.networkHeadersToRedact = [];
    sThis.urlBlocklist = [];
    sThis.tracingOrigins = options.tracingOrigins || [];
    if ((options == null ? void 0 : options.disableNetworkRecording) !== void 0) {
      sThis.disableNetworkRecording = options == null ? void 0 : options.disableNetworkRecording;
      sThis.enableRecordingNetworkContents = false;
      sThis.disableRecordingWebSocketContents = true;
      sThis.networkHeadersToRedact = [];
      sThis.networkBodyKeysToRedact = [];
      sThis.urlBlocklist = [];
      sThis.networkBodyKeysToRecord = [];
      sThis.networkBodyKeysToRecord = [];
    } else if (typeof (options == null ? void 0 : options.networkRecording) === "boolean") {
      sThis.disableNetworkRecording = !options.networkRecording;
      sThis.enableRecordingNetworkContents = false;
      sThis.disableRecordingWebSocketContents = true;
      sThis.networkHeadersToRedact = [];
      sThis.networkBodyKeysToRedact = [];
      sThis.urlBlocklist = [];
    } else {
      if (((_a = options.networkRecording) == null ? void 0 : _a.enabled) !== void 0) {
        sThis.disableNetworkRecording = !options.networkRecording.enabled;
      } else {
        sThis.disableNetworkRecording = false;
      }
      sThis.enableRecordingNetworkContents = ((_b = options.networkRecording) == null ? void 0 : _b.recordHeadersAndBody) || false;
      sThis.disableRecordingWebSocketContents = ((_c = options.networkRecording) == null ? void 0 : _c.disableWebSocketEventRecordings) || false;
      sThis.networkHeadersToRedact = ((_e = (_d = options.networkRecording) == null ? void 0 : _d.networkHeadersToRedact) == null ? void 0 : _e.map(
        (header) => header.toLowerCase()
      )) || [];
      sThis.networkBodyKeysToRedact = ((_g = (_f = options.networkRecording) == null ? void 0 : _f.networkBodyKeysToRedact) == null ? void 0 : _g.map(
        (bodyKey) => bodyKey.toLowerCase()
      )) || [];
      sThis.urlBlocklist = ((_i = (_h = options.networkRecording) == null ? void 0 : _h.urlBlocklist) == null ? void 0 : _i.map(
        (url) => url.toLowerCase()
      )) || [];
      sThis.urlBlocklist = [
        ...sThis.urlBlocklist,
        ...DEFAULT_URL_BLOCKLIST
      ];
      sThis.networkHeaderKeysToRecord = (_j = options.networkRecording) == null ? void 0 : _j.headerKeysToRecord;
      if (sThis.networkHeaderKeysToRecord) {
        sThis.networkHeadersToRedact = [];
        sThis.networkHeaderKeysToRecord = sThis.networkHeaderKeysToRecord.map(
          (key) => key.toLocaleLowerCase()
        );
      }
      sThis.networkBodyKeysToRecord = (_k = options.networkRecording) == null ? void 0 : _k.bodyKeysToRecord;
      if (sThis.networkBodyKeysToRecord) {
        sThis.networkBodyKeysToRedact = [];
        sThis.networkBodyKeysToRecord = sThis.networkBodyKeysToRecord.map(
          (key) => key.toLocaleLowerCase()
        );
      }
    }
    if (!sThis.disableNetworkRecording && sThis.enableRecordingNetworkContents) {
      sThis.listeners.push(
        NetworkListener({
          xhrCallback: (requestResponsePair) => {
            sThis.xhrNetworkContents.push(requestResponsePair);
          },
          fetchCallback: (requestResponsePair) => {
            sThis.fetchNetworkContents.push(requestResponsePair);
          },
          webSocketRequestCallback: (event) => {
            if (sThis.webSocketNetworkContents) {
              sThis.webSocketNetworkContents.push(event);
            }
          },
          webSocketEventCallback: (event) => {
            sThis.webSocketEventContents.push(event);
          },
          disableWebSocketRecording: sThis.disableRecordingWebSocketContents,
          headersToRedact: sThis.networkHeadersToRedact,
          bodyKeysToRedact: sThis.networkBodyKeysToRedact,
          backendUrl: sThis._backendUrl,
          tracingOrigins: sThis.tracingOrigins,
          urlBlocklist: sThis.urlBlocklist,
          sessionSecureID: options.sessionSecureID,
          headerKeysToRecord: sThis.networkHeaderKeysToRecord,
          bodyKeysToRecord: sThis.networkBodyKeysToRecord
        })
      );
    }
  }
  static getRecordedNetworkResources(sThis, recordingStartTime) {
    var _a;
    let httpResources = [];
    let webSocketResources = [];
    if (!sThis.disableNetworkRecording) {
      const documentTimeOrigin = ((_a = window == null ? void 0 : window.performance) == null ? void 0 : _a.timeOrigin) || 0;
      httpResources = performance.getEntriesByType(
        "resource"
      );
      const offset = (recordingStartTime - documentTimeOrigin) * 2;
      httpResources = httpResources.filter(
        (r) => shouldNetworkRequestBeRecorded(
          r.name,
          sThis._backendUrl,
          sThis.tracingOrigins
        )
      ).map((resource) => {
        return __spreadProps(__spreadValues({}, resource.toJSON()), {
          offsetStartTime: resource.startTime - offset,
          offsetResponseEnd: resource.responseEnd - offset,
          offsetFetchStart: resource.fetchStart - offset
        });
      });
      if (sThis.enableRecordingNetworkContents) {
        httpResources = matchPerformanceTimingsWithRequestResponsePair(
          httpResources,
          sThis.xhrNetworkContents,
          "xmlhttprequest"
        );
        httpResources = matchPerformanceTimingsWithRequestResponsePair(
          httpResources,
          sThis.fetchNetworkContents,
          "fetch"
        );
      }
    }
    if (!sThis.disableRecordingWebSocketContents) {
      webSocketResources = sThis.webSocketNetworkContents || [];
    }
    return [...httpResources, ...webSocketResources];
  }
  static getRecordedWebSocketEvents(sThis) {
    let webSocketEvents = [];
    if (!sThis.disableNetworkRecording && !sThis.disableRecordingWebSocketContents) {
      webSocketEvents = sThis.webSocketEventContents;
    }
    return webSocketEvents;
  }
  static clearRecordedNetworkResources(sThis) {
    if (!sThis.disableNetworkRecording) {
      sThis.xhrNetworkContents = [];
      sThis.fetchNetworkContents = [];
      sThis.webSocketNetworkContents = [];
      sThis.webSocketEventContents = [];
      performance.clearResourceTimings();
    }
  }
}
const GenerateSecureID = () => {
  var _a;
  const ID_LENGTH = 28;
  const CHARACTER_SET = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var secureID = "";
  const hasCrypto = typeof window !== "undefined" && ((_a = window.crypto) == null ? void 0 : _a.getRandomValues);
  const cryptoRandom = new Uint32Array(ID_LENGTH);
  if (hasCrypto) {
    window.crypto.getRandomValues(cryptoRandom);
  }
  for (let i = 0; i < ID_LENGTH; i++) {
    if (hasCrypto) {
      secureID += CHARACTER_SET.charAt(
        cryptoRandom[i] % CHARACTER_SET.length
      );
    } else {
      secureID += CHARACTER_SET.charAt(
        Math.floor(Math.random() * CHARACTER_SET.length)
      );
    }
  }
  return secureID;
};
const HighlightSegmentMiddleware = ({
  next,
  payload
}) => {
  if (typeof window !== "undefined" && typeof document !== "undefined" && "H" in window) {
    if (payload.obj.type === "track") {
      const trackEventName = payload.obj.event;
      const trackEventProperties = payload.obj.properties;
      window.H.track(trackEventName, trackEventProperties);
    } else if (payload.obj.type === "identify") {
      const identifier = payload.obj.userId;
      if (identifier == null ? void 0 : identifier.length) {
        const identifyMetadata = payload.obj.traits;
        window.H.identify(identifier, identifyMetadata);
      }
    }
  }
  next(payload);
};
function configureElectronHighlight(window2) {
  var _a;
  if (window2.on && ((_a = window2.webContents) == null ? void 0 : _a.send)) {
    window2.on("focus", () => {
      window2.webContents.send("highlight.run", { visible: true });
    });
    window2.on("blur", () => {
      window2.webContents.send("highlight.run", { visible: false });
    });
    window2.on("close", () => {
      window2.webContents.send("highlight.run", { visible: false });
    });
  }
}
const firstloadVersion = "7.5.5";
const SESSION_PUSH_THRESHOLD = 1e3 * 55;
const getPreviousSessionData = () => {
  let storedSessionData = JSON.parse(
    window.sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_DATA) || "{}"
  );
  if (storedSessionData && storedSessionData.lastPushTime && Date.now() - storedSessionData.lastPushTime < SESSION_PUSH_THRESHOLD) {
    return storedSessionData;
  }
};
const initializeFetchListener = () => {
  if (typeof window !== "undefined") {
    if (typeof window._highlightFetchPatch !== "undefined") {
      return;
    }
    window._originalFetch = window.fetch;
    window._fetchProxy = (input, init) => {
      return window._originalFetch(input, init);
    };
    window._highlightFetchPatch = (input, init) => {
      return window._fetchProxy.call(window || global, input, init);
    };
    window.fetch = window._highlightFetchPatch;
  }
};
const placeholderCallback = () => null;
const initializeWebSocketListener = () => {
  if (typeof window !== "undefined") {
    if (typeof window._highlightWebSocketRequestCallback !== "undefined") {
      return;
    }
    window._highlightWebSocketRequestCallback = placeholderCallback;
    window._highlightWebSocketEventCallback = placeholderCallback;
    const WebSocketProxy = new Proxy(window.WebSocket, {
      construct(target, args) {
        const socketId = createNetworkRequestId();
        const webSocket = new target(...args);
        const openHandler = (event) => {
          window._highlightWebSocketRequestCallback({
            socketId,
            initiatorType: "websocket",
            type: "open",
            name: webSocket.url,
            startTime: event.timeStamp
          });
        };
        const closeHandler = (event) => {
          window._highlightWebSocketRequestCallback({
            socketId,
            initiatorType: "websocket",
            type: "close",
            name: webSocket.url,
            requestEnd: event.timeStamp
          });
          webSocket.removeEventListener("open", openHandler);
          webSocket.removeEventListener("error", errorHandler);
          webSocket.removeEventListener("message", messageHandler);
          webSocket.removeEventListener("close", closeHandler);
        };
        const messageHandler = (event) => {
          const { data } = event;
          const message = typeof data === "string" ? event.data : void 0;
          let size;
          if (typeof data === "string") {
            size = data.length;
          } else if (data instanceof Blob) {
            size = data.size;
          } else {
            size = data.byteLength || 0;
          }
          window._highlightWebSocketEventCallback({
            socketId,
            type: "received",
            name: webSocket.url,
            timeStamp: event.timeStamp,
            size,
            message
          });
        };
        const errorHandler = (event) => {
          window._highlightWebSocketEventCallback({
            socketId,
            type: "error",
            name: webSocket.url,
            timeStamp: event.timeStamp,
            size: 0
          });
        };
        webSocket.addEventListener("open", openHandler);
        webSocket.addEventListener("error", errorHandler);
        webSocket.addEventListener("message", messageHandler);
        webSocket.addEventListener("close", closeHandler);
        const sendProxy = new Proxy(webSocket.send, {
          apply: function(sendTarget, thisArg, args2) {
            const data = args2[0];
            const message = typeof data === "string" ? data : void 0;
            let size;
            if (typeof data === "string") {
              size = data.length;
            } else if (data instanceof Blob) {
              size = data.size;
            } else {
              size = data.byteLength || 0;
            }
            window._highlightWebSocketEventCallback({
              socketId,
              type: "sent",
              name: webSocket.url,
              timeStamp: performance.now(),
              size,
              message
            });
            sendTarget.apply(thisArg, args2);
          }
        });
        webSocket.send = sendProxy;
        return webSocket;
      }
    });
    window.WebSocket = WebSocketProxy;
  }
};
const listenToChromeExtensionMessage = () => {
  var _a, _b;
  if (typeof chrome !== "undefined" && ((_a = chrome == null ? void 0 : chrome.runtime) == null ? void 0 : _a.onMessage)) {
    (_b = chrome == null ? void 0 : chrome.runtime) == null ? void 0 : _b.onMessage.addListener(
      (message, _sender, sendResponse) => {
        const action = message.action;
        console.log(
          `[highlight] received '${action}' event from extension.`
        );
        switch (action) {
          case "init": {
            const scriptUrl = "http://localhost:8080/dist/index.js";
            console.log("url", scriptUrl);
            H.init(1, {
              debug: true,
              scriptUrl
            });
            H.getSessionURL().then((url) => {
              sendResponse({ url });
            });
            break;
          }
          case "stop": {
            H.stop();
            sendResponse({ success: true });
            break;
          }
        }
        return true;
      }
    );
  }
};
var MetricCategory = /* @__PURE__ */ ((MetricCategory2) => {
  MetricCategory2["Device"] = "Device";
  MetricCategory2["WebVital"] = "WebVital";
  MetricCategory2["Frontend"] = "Frontend";
  MetricCategory2["Backend"] = "Backend";
  return MetricCategory2;
})(MetricCategory || {});
const HighlightWarning = (context, msg) => {
  console.warn(`highlight.run warning: (${context}): `, msg);
};
const READY_WAIT_LOOP_MS = 100;
let onHighlightReadyQueue = [];
let onHighlightReadyInterval = void 0;
let script;
let highlight_obj;
let first_load_listeners;
let init_called = false;
const H = {
  options: void 0,
  init: (projectID, options) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    try {
      H.options = options;
      if (typeof window === "undefined" || typeof document === "undefined") {
        return;
      }
      if (!projectID) {
        console.info(
          "Highlight is not initializing because projectID was passed undefined."
        );
        return;
      }
      let previousSession = getPreviousSessionData();
      let sessionSecureID = GenerateSecureID();
      if (previousSession == null ? void 0 : previousSession.sessionSecureID) {
        sessionSecureID = previousSession.sessionSecureID;
      } else {
        const sessionData = __spreadProps(__spreadValues({}, previousSession), {
          projectID: +projectID,
          sessionSecureID
        });
        window.sessionStorage.setItem(
          SESSION_STORAGE_KEYS.SESSION_DATA,
          JSON.stringify(sessionData)
        );
      }
      if (init_called) {
        return { sessionSecureID };
      }
      init_called = true;
      script = document.createElement("script");
      var scriptSrc = (options == null ? void 0 : options.scriptUrl) ? options.scriptUrl : `https://static.highlight.io/v${firstloadVersion}/index.js`;
      script.setAttribute("src", scriptSrc);
      script.setAttribute("type", "text/javascript");
      document.getElementsByTagName("head")[0].appendChild(script);
      const client_options = {
        organizationID: projectID,
        debug: options == null ? void 0 : options.debug,
        backendUrl: options == null ? void 0 : options.backendUrl,
        tracingOrigins: options == null ? void 0 : options.tracingOrigins,
        disableNetworkRecording: options == null ? void 0 : options.disableNetworkRecording,
        networkRecording: options == null ? void 0 : options.networkRecording,
        disableBackgroundRecording: options == null ? void 0 : options.disableBackgroundRecording,
        disableConsoleRecording: options == null ? void 0 : options.disableConsoleRecording,
        disableSessionRecording: options == null ? void 0 : options.disableSessionRecording,
        reportConsoleErrors: options == null ? void 0 : options.reportConsoleErrors,
        consoleMethodsToRecord: options == null ? void 0 : options.consoleMethodsToRecord,
        enableSegmentIntegration: options == null ? void 0 : options.enableSegmentIntegration,
        enableStrictPrivacy: options == null ? void 0 : options.enableStrictPrivacy,
        enableCanvasRecording: options == null ? void 0 : options.enableCanvasRecording,
        enablePerformanceRecording: options == null ? void 0 : options.enablePerformanceRecording,
        samplingStrategy: options == null ? void 0 : options.samplingStrategy,
        inlineImages: options == null ? void 0 : options.inlineImages,
        inlineStylesheet: options == null ? void 0 : options.inlineStylesheet,
        recordCrossOriginIframe: options == null ? void 0 : options.recordCrossOriginIframe,
        firstloadVersion,
        environment: (options == null ? void 0 : options.environment) || "production",
        appVersion: options == null ? void 0 : options.version,
        serviceName: options == null ? void 0 : options.serviceName,
        sessionShortcut: options == null ? void 0 : options.sessionShortcut,
        sessionSecureID,
        storageMode: options == null ? void 0 : options.storageMode
      };
      first_load_listeners = new FirstLoadListeners(client_options);
      if (!(options == null ? void 0 : options.manualStart)) {
        first_load_listeners.startListening();
      }
      script.addEventListener("load", () => {
        const startFunction = () => {
          highlight_obj = new window.HighlightIO(
            client_options,
            first_load_listeners
          );
          if (!(options == null ? void 0 : options.manualStart)) {
            highlight_obj.initialize();
          }
        };
        if ("HighlightIO" in window) {
          startFunction();
        } else {
          const interval = setInterval(() => {
            if ("HighlightIO" in window) {
              startFunction();
              clearInterval(interval);
            }
          }, READY_WAIT_LOOP_MS);
        }
      });
      if (!((_b = (_a = options == null ? void 0 : options.integrations) == null ? void 0 : _a.mixpanel) == null ? void 0 : _b.disabled) && ((_d = (_c = options == null ? void 0 : options.integrations) == null ? void 0 : _c.mixpanel) == null ? void 0 : _d.projectToken)) {
        setupMixpanelIntegration(options.integrations.mixpanel);
      }
      if (!((_f = (_e = options == null ? void 0 : options.integrations) == null ? void 0 : _e.amplitude) == null ? void 0 : _f.disabled) && ((_h = (_g = options == null ? void 0 : options.integrations) == null ? void 0 : _g.amplitude) == null ? void 0 : _h.apiKey)) {
        setupAmplitudeIntegration(options.integrations.amplitude);
      }
      return { sessionSecureID };
    } catch (e) {
      HighlightWarning("init", e);
    }
  },
  snapshot: (element) => __async(void 0, null, function* () {
    try {
      if (highlight_obj && highlight_obj.ready) {
        return yield highlight_obj.snapshot(element);
      }
    } catch (e) {
      HighlightWarning("snapshot", e);
    }
  }),
  addSessionFeedback: ({
    verbatim,
    userName,
    userEmail,
    timestampOverride
  }) => {
    try {
      H.onHighlightReady(
        () => highlight_obj.addSessionFeedback({
          verbatim,
          timestamp: timestampOverride || (/* @__PURE__ */ new Date()).toISOString(),
          user_email: userEmail,
          user_name: userName
        })
      );
    } catch (e) {
      HighlightWarning("error", e);
    }
  },
  consumeError: (error, message, payload) => {
    try {
      H.onHighlightReady(
        () => highlight_obj.consumeCustomError(
          error,
          message,
          JSON.stringify(payload)
        )
      );
    } catch (e) {
      HighlightWarning("error", e);
    }
  },
  error: (message, payload) => {
    try {
      H.onHighlightReady(
        () => highlight_obj.pushCustomError(message, JSON.stringify(payload))
      );
    } catch (e) {
      HighlightWarning("error", e);
    }
  },
  track: (event, metadata = {}) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
    try {
      H.onHighlightReady(
        () => highlight_obj.addProperties(__spreadProps(__spreadValues({}, metadata), { event }))
      );
      const highlightUrl = highlight_obj == null ? void 0 : highlight_obj.getCurrentSessionURL();
      if (!((_c = (_b = (_a = H.options) == null ? void 0 : _a.integrations) == null ? void 0 : _b.mixpanel) == null ? void 0 : _c.disabled)) {
        if ((_d = window.mixpanel) == null ? void 0 : _d.track) {
          window.mixpanel.track(event, __spreadProps(__spreadValues({}, metadata), {
            highlightSessionURL: highlightUrl
          }));
        }
      }
      if (!((_g = (_f = (_e = H.options) == null ? void 0 : _e.integrations) == null ? void 0 : _f.amplitude) == null ? void 0 : _g.disabled)) {
        if ((_h = window.amplitude) == null ? void 0 : _h.getInstance) {
          window.amplitude.getInstance().logEvent(event, __spreadProps(__spreadValues({}, metadata), {
            highlightSessionURL: highlightUrl
          }));
        }
      }
      if (!((_k = (_j = (_i = H.options) == null ? void 0 : _i.integrations) == null ? void 0 : _j.intercom) == null ? void 0 : _k.disabled)) {
        if (window.Intercom) {
          window.Intercom("trackEvent", event, metadata);
        }
      }
    } catch (e) {
      HighlightWarning("track", e);
    }
  },
  start: (options) => {
    if ((highlight_obj == null ? void 0 : highlight_obj.state) === "Recording") {
      if (!(options == null ? void 0 : options.silent)) {
        console.warn(
          "Highlight is already recording. Please `H.stop()` the current session before starting a new one."
        );
      }
    } else {
      first_load_listeners.startListening();
      H.onHighlightReady(
        () => __async(void 0, null, function* () {
          yield highlight_obj.initialize(options);
        }),
        { waitForReady: false }
      );
    }
  },
  stop: (options) => {
    if ((highlight_obj == null ? void 0 : highlight_obj.state) !== "Recording") {
      if (!(options == null ? void 0 : options.silent)) {
        console.warn(
          "Highlight is already stopped. Please call `H.start()`."
        );
      }
    } else {
      H.onHighlightReady(() => highlight_obj.stopRecording(true));
    }
  },
  identify: (identifier, metadata = {}) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    try {
      H.onHighlightReady(
        () => highlight_obj.identify(identifier, metadata)
      );
    } catch (e) {
      HighlightWarning("identify", e);
    }
    if (!((_c = (_b = (_a = H.options) == null ? void 0 : _a.integrations) == null ? void 0 : _b.mixpanel) == null ? void 0 : _c.disabled)) {
      if ((_d = window.mixpanel) == null ? void 0 : _d.identify) {
        window.mixpanel.identify(
          typeof (metadata == null ? void 0 : metadata.email) === "string" ? metadata == null ? void 0 : metadata.email : identifier
        );
        if (metadata) {
          window.mixpanel.track("identify", metadata);
          window.mixpanel.people.set(metadata);
        }
      }
    }
    if (!((_g = (_f = (_e = H.options) == null ? void 0 : _e.integrations) == null ? void 0 : _f.amplitude) == null ? void 0 : _g.disabled)) {
      if ((_h = window.amplitude) == null ? void 0 : _h.getInstance) {
        window.amplitude.getInstance().setUserId(identifier);
        if (Object.keys(metadata).length > 0) {
          const amplitudeUserProperties = Object.keys(
            metadata
          ).reduce((acc, key) => {
            acc.set(key, metadata[key]);
            return acc;
          }, new window.amplitude.Identify());
          window.amplitude.getInstance().identify(amplitudeUserProperties);
        }
      }
    }
  },
  metrics: (metrics) => {
    try {
      H.onHighlightReady(
        () => highlight_obj.recordMetric(
          metrics.map((m) => __spreadProps(__spreadValues({}, m), {
            category: "Frontend"
            /* Frontend */
          }))
        )
      );
    } catch (e) {
      HighlightWarning("metrics", e);
    }
  },
  getSessionURL: () => {
    return new Promise((resolve, reject) => {
      H.onHighlightReady(() => {
        const res = highlight_obj.getCurrentSessionURL();
        if (res) {
          resolve(res);
        } else {
          reject(new Error("Unable to get session URL"));
        }
      });
    });
  },
  getSessionDetails: () => {
    return new Promise((resolve, reject) => {
      H.onHighlightReady(() => {
        const baseUrl = highlight_obj.getCurrentSessionURL();
        if (baseUrl) {
          const currentSessionTimestamp = highlight_obj.getCurrentSessionTimestamp();
          const now = (/* @__PURE__ */ new Date()).getTime();
          const url = new URL(baseUrl);
          const urlWithTimestamp = new URL(baseUrl);
          urlWithTimestamp.searchParams.set(
            "ts",
            // The delta between when the session recording started and now.
            ((now - currentSessionTimestamp) / 1e3).toString()
          );
          resolve({
            url: url.toString(),
            urlWithTimestamp: urlWithTimestamp.toString()
          });
        } else {
          reject(new Error("Could not get session URL"));
        }
      });
    });
  },
  onHighlightReady: (func, options) => __async(void 0, null, function* () {
    try {
      if (highlight_obj && ((options == null ? void 0 : options.waitForReady) === false || highlight_obj.ready)) {
        yield func();
      } else {
        onHighlightReadyQueue.push({ options, func });
        if (onHighlightReadyInterval === void 0) {
          onHighlightReadyInterval = setInterval(() => __async(void 0, null, function* () {
            var _a;
            const newOnHighlightReadyQueue = [];
            for (const f of onHighlightReadyQueue) {
              if (highlight_obj && (((_a = f.options) == null ? void 0 : _a.waitForReady) === false || highlight_obj.ready)) {
                yield f.func();
              } else {
                newOnHighlightReadyQueue.push(f);
              }
            }
            onHighlightReadyQueue = newOnHighlightReadyQueue;
            if (onHighlightReadyQueue.length == 0) {
              clearInterval(onHighlightReadyInterval);
              onHighlightReadyInterval = void 0;
            }
          }), READY_WAIT_LOOP_MS);
        }
      }
    } catch (e) {
      HighlightWarning("onHighlightReady", e);
    }
  })
};
if (typeof window !== "undefined") {
  window.H = H;
}
listenToChromeExtensionMessage();
initializeFetchListener();
initializeWebSocketListener();
export {
  H,
  HighlightSegmentMiddleware,
  MetricCategory,
  configureElectronHighlight
};
