import { CloseOutlined } from '@ant-design/icons';
import { IGoal } from '@shared/goals';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Divider } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { CollapsableSection } from './CollapsableSection';
import { ViewGoal } from './ViewGoal';

export const ViewGoals: React.FC<{
  extra?: React.ReactNode;
  goals: IGoal[];
  title: React.ReactNode;
  onRemove?: (goal: IGoal) => void;
  startEmptyExpanded?: boolean;
  emptyText?: string;
  collapsable?: boolean;
  hideDueDate?: boolean;
}> = ({
  extra,
  goals,
  onRemove,
  title,
  startEmptyExpanded = false,
  emptyText = 'No goals exist',
  collapsable = true,
  hideDueDate = false,
}) => {
  const children = isEmpty(goals) ? (
    <>
      <Divider style={{ margin: '8px 0' }} />
      <Text>{emptyText}</Text>
    </>
  ) : (
    goals.map((goal) => (
      <React.Fragment key={`goal-${goal.token}`}>
        <Divider style={{ margin: '8px 0' }} />
        <Row>
          <ViewGoal goal={goal} hideDueDate={hideDueDate} />
          {onRemove && (
            <>
              <GrowingSpacer min={6} />
              <Button
                type="text"
                size="small"
                onClick={() => {
                  onRemove(goal);
                }}
              >
                <CloseOutlined />
              </Button>
            </>
          )}
        </Row>
      </React.Fragment>
    ))
  );
  if (collapsable) {
    return (
      <CollapsableSection
        title={title}
        startExpanded={isEmpty(goals) ? startEmptyExpanded : true}
        extra={extra}
      >
        {children}
      </CollapsableSection>
    );
  }

  return (
    <Column>
      <Row>
        <Text>{title}</Text>
        <GrowingSpacer />
        {extra}
      </Row>
      {children}
    </Column>
  );
};
