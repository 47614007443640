import { GithubPullToken, IGithubPull } from '@shared/github';
import { mapByToken } from '@shared/mapByToken';
import { get } from '@web/common/api';
import { debounce } from 'lodash';
import { stringifyUrl } from 'query-string/base';
import * as React from 'react';

const SELECT_GITHUB_LIMIT = 30;
const NO_RESULTS = [];

interface SearchOptions {
  omitTokens?: GithubPullToken[];
}

export const useGithubPullSearch = (
  { omitTokens = [] }: SearchOptions,
  initialPulls?: IGithubPull[],
) => {
  const [error, setError] = React.useState();
  const [results, setResults] = React.useState<IGithubPull[]>(NO_RESULTS);
  const [pullsMap, setPullsMap] = React.useState(mapByToken(initialPulls));

  const clearSearch = () => {
    setResults(NO_RESULTS);
  };

  const search = React.useMemo(() => {
    return debounce(async (searchQuery: string) => {
      const githubPullSearchUrl = stringifyUrl({
        url: '/github/select/search',
        query: {
          limit: SELECT_GITHUB_LIMIT,
          query: searchQuery,
          omitTokens,
        },
      });

      try {
        const pullResults = await get<IGithubPull[]>(githubPullSearchUrl);
        setPullsMap(mapByToken(pullResults, pullsMap));
        setResults(pullResults);
      } catch (error) {
        setError(error);
      }
    }, 200);
  }, [omitTokens]);

  return {
    search,
    clearSearch,
    pulls: results,
    error,
    pullsMap,
  };
};
