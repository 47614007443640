import { LeftOutlined } from '@ant-design/icons';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { Small } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BackButton: React.FC<{ defaultBackTo?: string }> = ({
  defaultBackTo,
}) => {
  const navigateBack = useNavigateBack();

  return (
    <BackButtonContainer onClick={() => navigateBack(defaultBackTo)}>
      <LeftOutlined style={{ fontSize: 10 }} /> BACK
    </BackButtonContainer>
  );
};

export const BackLink: React.FC<{ to: string }> = ({ to }) => {
  return (
    <Link to={to}>
      <BackButtonContainer>
        <LeftOutlined style={{ fontSize: 10 }} /> BACK
      </BackButtonContainer>
    </Link>
  );
};

const BackButtonContainer = styled(Small)`
  line-height: 20px;
  cursor: pointer;
  width: 52px;
  padding: 0 4px 0 2px;
  white-space: nowrap;
  border-radius: 4px;
  position: relative;
  left: -2px;
}

  &:hover {
    background-color: #eee;
  }
`;
