import { IUser, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router';

import { FeedbackList } from './FeedbackList/FeedbackList';
import { useRecentFeedback } from './useRecentFeedback';

export const ViewReportsFeedbackPage: React.FC = () => {
  const { userToken } = useParams<{ userToken: UserToken }>();
  const { feedbackResponse, impactEntryMap, isLoading } =
    useRecentFeedback(userToken);

  const { data: feedbackUser } = useApi<IUser>(`/users/${userToken}`);

  const title = feedbackUser ? `Feedback for ${feedbackUser.name}` : 'Feedback';

  return (
    <PageContent>
      <PageHeader
        title={title}
        navigateBack
        defaultNavigateBackTo="/team/entries"
        mobileTitle={title}
        primaryAction={{
          label: 'Request feedback',
          linkTo: `/team/feedback/${userToken}/request`,
        }}
      />
      {isLoading || !feedbackResponse ? (
        <Skeleton />
      ) : (
        <FeedbackList
          direction="Received"
          feedbackResponse={feedbackResponse}
          impactEntryMap={impactEntryMap}
        />
      )}
    </PageContent>
  );
};
