import { Column, Row } from '@web/components/layout';
import { SubduedText } from '@web/components/typography';
import styled, { css } from 'styled-components';

export const WidgetRow = styled(Row)<{
  readonly?: boolean;
  hideBorder?: boolean;
}>`
  gap: 8px;
  height: 32px;
  margin: 0 -6px;
  padding: 0 6px;

  ${(props) =>
    !props.hideBorder
      ? css`
          // border-top: 1px solid #f0f0f0;
        `
      : ''}

  ${(props) =>
    !props.readonly
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : ''}
`;

export const Widget = styled(Column)``;

export const WidgetDate = styled(SubduedText)`
  width: 60px;
  text-align: right;
  white-space: nowrap;
`;
