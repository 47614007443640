import { SoundOutlined } from '@ant-design/icons';
import { BedrockSummarySendMode } from '@shared/bedrock';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Modal, Select, message } from 'antd';
import * as React from 'react';

export interface ISendManagerSummaryFormData {
  sendMode: BedrockSummarySendMode;
  type: SummaryType;
}

export enum SummaryType {
  INDIVIDUAL = 'INDIVIDUAL',
  MANAGER = 'MANAGER',
}

interface SummaryTypeOption {
  label: string;
  value: SummaryType;
}

interface SendModeOption {
  label: string;
  value: BedrockSummarySendMode;
}
export const SendSummaryButton: React.FC<{
  children?: React.ReactNode;
  onSend: (formData: ISendManagerSummaryFormData) => void;
}> = ({ children, onSend }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState<SummaryType | undefined>(undefined);
  const [sendMode, setSendMode] = React.useState<
    BedrockSummarySendMode | undefined
  >();

  const closeModal = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    setIsSubmitting(true);
    try {
      onSend({ type, sendMode });
      setOpen(false);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const sendModeOptions: SendModeOption[] = [
    {
      label:
        "Normal - Only resend runs that haven't been sent already (best effort)",
      value: BedrockSummarySendMode.ONCE,
    },
    {
      label: 'Force - Re-send even if already sent',
      value: BedrockSummarySendMode.FORCE,
    },
  ];

  const typeOptions: SummaryTypeOption[] = [
    {
      label: 'Individual',
      value: SummaryType.INDIVIDUAL,
    },
    {
      label: 'Manager',
      value: SummaryType.MANAGER,
    },
  ];
  return (
    <Column>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        <SoundOutlined disabled={open} />
        {children}
      </Button>
      <Modal
        title={'Send Summary'}
        open={open}
        onOk={() => {
          void handleOk();
        }}
        afterClose={closeModal}
        confirmLoading={isSubmitting}
        onCancel={closeModal}
        width="500px"
        okText="Send"
        okButtonProps={{
          disabled: isSubmitting || !sendMode || !type,
        }}
      >
        <Column gap={12}>
          <Column gap={6}>
            <Text>Send Mode</Text>
            <Select
              disabled={isSubmitting}
              options={sendModeOptions}
              onChange={(sendMode) => {
                setSendMode(sendMode);
              }}
            />
          </Column>
          <Column gap={6}>
            <Text>Type</Text>
            <Select
              disabled={isSubmitting}
              options={typeOptions}
              onChange={(type) => {
                setType(type);
              }}
            />
          </Column>
        </Column>
      </Modal>
    </Column>
  );
};
