import { CaretDownOutlined, TrophyOutlined } from '@ant-design/icons';
import { sortList, useGoalList } from '@client/GoalsClient';
import { formatDate } from '@shared/formatDate';
import {
  GoalListToken,
  GoalState,
  GoalToken,
  IGoal,
  IGoalList,
  UpdateGoalListRequest,
} from '@shared/goals';
import { mapByProp } from '@shared/mapByToken';
import { UserMapItem, UserToken } from '@shared/types';
import { InlineInput } from '@web/1on1s/InlineInput';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { del, patch, post } from '@web/common/api';
import { BackButton } from '@web/components/BackButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import {
  Column,
  Grid,
  GrowingSpacer,
  Row,
  Spacer,
} from '@web/components/layout';
import { SubduedText, Text } from '@web/components/typography';
import { Button, Result, Skeleton, message } from 'antd';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';

import { AddGoalDropdown } from './AddGoalDropdown';
import { AddGoalToListModal } from './AddGoalToListModal';
import { GoalListFollowers } from './GoalListFollowers';
import { ViewSortableGoalItem } from './ViewSortableGoalItem';

export interface SortableGoalItem {
  id: GoalToken;
  title: string;
  state: GoalState;
  archivedDate?: Date | string;
  dueDate?: Date | string;
  assignedUsers: UserMapItem[];
}

const goalToSortableItem = (goal: IGoal): SortableGoalItem => ({
  id: goal.token,
  archivedDate: goal.archivedDate,
  dueDate: goal.dueDate,
  title: goal.title,
  state: goal.state,
  assignedUsers: goal.assignedUsers,
});

const mapAndSortGoals = (goalList: IGoalList) => {
  const sortableItems: SortableGoalItem[] =
    goalList.goals.map(goalToSortableItem);
  if (goalList.sortedGoals?.length !== goalList.goals.length) {
    return sortableItems;
  }

  const itemMap = mapByProp(sortableItems, 'id');
  return goalList.sortedGoals.map((token) => itemMap.get(token));
};

export const GoalListPage: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { goalListToken } = useParams<{
    goalListToken: GoalListToken;
  }>();
  const {
    data: goalList,
    mutate: reloadGoalList,
    error,
  } = useGoalList(goalListToken);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [title, setTitle] = React.useState<string>(null);
  const [followers, setFollowers] = React.useState<UserToken[]>([]);
  const [goals, setGoals] = React.useState<GoalToken[]>([]);
  const [goalItems, setGoalsItems] = React.useState<SortableGoalItem[]>([]);

  React.useEffect(() => {
    if (goalList) {
      setTitle(goalList.title);
      setFollowers(goalList.followers.map((user) => user.token));
      setGoals(goalList.goals.map((goal) => goal.token));
      setGoalsItems(mapAndSortGoals(goalList));
    }
  }, [goalList]);

  if (!goalList && !error) {
    return (
      <PageContent>
        <Column gap={12}>
          <BackButton defaultBackTo={`/goals`} />
          <Pane>
            <Skeleton />
          </Pane>
        </Column>
      </PageContent>
    );
  } else if (error) {
    return (
      <PageContent>
        <Column gap={12}>
          <BackButton defaultBackTo={`/goals`} />
          <Pane>
            <Result
              status={404}
              title={'Cannot view goal list'}
              subTitle={
                'Either this list does not exist or you do not have access to it'
              }
              extra={
                <Link to="/goals">
                  <Button>
                    <TrophyOutlined /> Return to Goals
                  </Button>
                </Link>
              }
            />
          </Pane>
        </Column>
      </PageContent>
    );
  }

  const handleSave = async () => {
    try {
      await patch<UpdateGoalListRequest>(`/goals/lists/${goalListToken}`, {
        title,
        followers,
      });
      void reloadGoalList();
    } catch (Error) {
      void message.error('Error saving');
    }
  };

  const handleAddGoal = async (goalToken: GoalToken) => {
    try {
      await patch<UpdateGoalListRequest>(`/goals/lists/${goalListToken}`, {
        goals: [...goalItems.map((item) => item.id), goalToken],
      });
      setShowAddModal(false);
      void reloadGoalList();
    } catch (Error) {
      void message.error('Error saving');
    }
  };
  const handleRemoveGoal = async (goalToken: GoalToken) => {
    try {
      await patch<UpdateGoalListRequest>(`/goals/lists/${goalListToken}`, {
        goals: goals.filter((goal) => goal !== goalToken),
      });
      setShowAddModal(false);
      void reloadGoalList();
    } catch (Error) {
      void message.error('Error saving');
    }
  };
  const handleArchive = async () => {
    try {
      await post(`/goals/lists/${goalListToken}/archive`);
      void reloadGoalList();
    } catch (Error) {
      void message.error('Error saving');
    }
  };
  const handleUnarchive = async () => {
    try {
      await del(`/goals/lists/${goalListToken}/archive`);
      void reloadGoalList();
    } catch (Error) {
      void message.error('Error saving');
    }
  };
  const handleSetGoalItems = async (newlySortedItems: SortableGoalItem[]) => {
    if (hashIds(newlySortedItems) === hashIds(goalItems)) {
      return;
    }

    setGoalsItems(newlySortedItems);
    try {
      await sortList(
        goalListToken,
        newlySortedItems.map((item) => item.id),
      );
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <PageContent>
      <Column gap={12}>
        {isMobile ? (
          <PageHeader navigateBack mobileTitle="Goal List" />
        ) : (
          <Row>
            <BackButton defaultBackTo={`/goals`} />
            <GrowingSpacer />
            <AddGoalDropdown
              onAddExisting={() => {
                setShowAddModal(true);
              }}
              onCreateNew={() => {
                navigate(`/goals/new?list=${goalListToken}`);
              }}
            >
              <SelectContainer>
                <Text>Add a goal</Text>
                <GrowingSpacer min={6} />
                <CaretDownOutlined style={{ position: 'relative' }} />
              </SelectContainer>
            </AddGoalDropdown>
            <Spacer size={6} />
            {goalList.archivedDate ? (
              <Button
                style={{ alignSelf: 'flex-start' }}
                onClick={handleUnarchive}
              >
                Re-open
              </Button>
            ) : (
              <Button
                style={{ alignSelf: 'flex-start' }}
                onClick={handleArchive}
              >
                Archive
              </Button>
            )}
          </Row>
        )}

        <Pane>
          <FormContainer>
            <InlineInput
              value={title}
              onChange={setTitle}
              style={{ fontSize: 24 }}
              onEnter={() => {}}
              onBlur={handleSave}
            />
            <Grid gap={24} columns={isMobile ? '1fr' : '3fr 2fr'}>
              <Column gap={24} style={{ maxWidth: 800, paddingLeft: 11 }}>
                {goalList.goals.length === 0 ? (
                  <Text>No goals have been added to this list</Text>
                ) : (
                  <Column>
                    <ReactSortable
                      list={goalItems}
                      setList={handleSetGoalItems}
                      easing="cubic-bezier(0.55, 0, 1, 0.45)"
                      animation={100}
                      handle=".drag-anchor"
                    >
                      {goalItems.map((item) => (
                        <ViewSortableGoalItem
                          key={item.id}
                          item={item}
                          onRemove={(item) => {
                            void handleRemoveGoal(item.id);
                          }}
                          assignedUsers={item.assignedUsers}
                        />
                      ))}
                    </ReactSortable>
                  </Column>
                )}
              </Column>
              <Column style={{ paddingLeft: isMobile ? 11 : 0 }}>
                {!isMobile && <Spacer size={12} />}
                <GoalListFollowers list={goalList} onChange={reloadGoalList} />
                {goalList.archivedDate && (
                  <>
                    <Spacer />
                    <SubduedText>
                      Archived on {formatDate(goalList.archivedDate)}
                    </SubduedText>
                  </>
                )}
              </Column>
            </Grid>
          </FormContainer>
        </Pane>
      </Column>
      {showAddModal && (
        <AddGoalToListModal
          onCancel={() => {
            setShowAddModal(false);
          }}
          onSave={handleAddGoal}
          omitGoals={goalItems.map((item) => item.id)}
        />
      )}
    </PageContent>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  cursor: text;
`;

const hashIds = (items: SortableGoalItem[]) =>
  items.map((item) => item.id).join(':');

const SelectContainer = styled.button`
  padding: 0 16px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 34px;
  appearance: none;
  outline: 0;
  border: 1px solid #e5e5e5;
  border-radius: 17px;
  color: #666;
  cursor: pointer;
`;
