import { GetJiraAccessResponse, IAccessibleResource } from '@shared/jira';
import { OrganizationToken } from '@shared/types';
import { get } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useJiraInstallUrl() {
  const url = stringifyUrl({
    url: `/jira/install`,
    query: {},
  });

  return useApi<{ url: string }>(url);
}

export function jiraInstallUrl() {
  const url = stringifyUrl({
    url: `/jira/install`,
    query: {},
  });

  return get<{ url: string }>(url);
}

export function useJiraAccess() {
  const url = stringifyUrl({
    url: `/jira/access`,
    query: {},
  });

  return useApi<GetJiraAccessResponse>(url);
}

export function jiraAccess() {
  const url = stringifyUrl({
    url: `/jira/access`,
    query: {},
  });

  return get<GetJiraAccessResponse>(url);
}

export function useInternalJiraAccess(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/jira/organizations/${organizationToken}/access`,
    query: {},
  });

  return useApi<GetJiraAccessResponse>(url);
}

export function internalJiraAccess(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/jira/organizations/${organizationToken}/access`,
    query: {},
  });

  return get<GetJiraAccessResponse>(url);
}

export function useInternalLinkedSites(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/jira/organizations/${organizationToken}/linked-sites`,
    query: {},
  });

  return useApi<IAccessibleResource[]>(url);
}

export function internalLinkedSites(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/jira/organizations/${organizationToken}/linked-sites`,
    query: {},
  });

  return get<IAccessibleResource[]>(url);
}
