import { usePageDetails } from '@web/common/useBackNavigation';
import * as React from 'react';
import styled from 'styled-components';

import { Extras, MobilePageHeader } from './MobilePageHeader';
import { PageActions } from './PageActions';
import { IPageDetails, PageContext } from './PageContext';

export interface IPageProps {
  children: React.ReactNode;
}

export const MobilePage: React.FC<IPageProps> = ({ children }) => {
  const [pageDetails, setPageDetails] = React.useState<IPageDetails>({});

  return (
    <PageContext.Provider value={{ pageDetails, setPageDetails }}>
      <MobilePageLayout>
        <MobilePageHeader />
        {children}
      </MobilePageLayout>
    </PageContext.Provider>
  );
};

const MobilePageLayout: React.FC<IPageProps> = ({ children }) => {
  const { primaryAction, secondaryActions } = usePageDetails();
  return (
    <Layout>
      <Children>{children}</Children>
      <Extras>
        <PageActions
          primaryAction={primaryAction}
          secondaryActions={secondaryActions}
        />
      </Extras>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  .ant-drawer-content {
    background: #333 !important;
  }
`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;
