import { formatDate } from '@shared/formatDate';
import { UserMapItem } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { IF_MOBILE, useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { Pane } from '@web/components/Pane';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { Header2, Header3 } from '../../components/typography';

export const UserFeedback: React.FC<{
  date: Date | string;
  giver: UserMapItem;
  receiver: UserMapItem;
  children: React.ReactNode;
  tag?: React.ReactNode;
}> = ({ date, giver, receiver, children, tag }) => {
  const { isMobile } = useResponsive();
  const messageUser = useDisplayUser(giver, receiver);

  return (
    <Container>
      <UserMessageContainer>
        <Pane style={{ flex: 1 }}>
          <Column style={{ gap: 12 }}>
            <Row gap={12} style={{ alignItems: 'center' }}>
              <FeedbackAvatar receiver={receiver} giver={giver} />
              <Header2>{messageUser.name}</Header2>
              {tag}
              <GrowingSpacer />
              <MessageDate>{formatDate(date)}</MessageDate>
            </Row>
            <Row gap={12} style={{ width: '100%' }}>
              {!isMobile && <Spacer size={30} />}
              <Column style={{ gap: 12, width: '100%' }}>{children}</Column>
            </Row>
          </Column>
        </Pane>
      </UserMessageContainer>
    </Container>
  );
};
const FeedbackAvatar: React.FC<{
  receiver: UserMapItem;
  giver: UserMapItem;
}> = ({ receiver, giver }) => {
  const avatarUser = useDisplayUser(giver, receiver);

  return <UserAvatar user={avatarUser} size={30} />;
};

/**
 * The user associated with a feedback item depends on who's viewing it and where:
 *
 * 1. If on the view feedback page, show the receiver of the feedback
 * 2. If the receiver, show the giver of the feedback
 * 3. If the manager, show the giver of the feedback
 * 4. If the giver of feedback, show the receiver
 *
 */
const useDisplayUser = (giver: UserMapItem, receiver: UserMapItem) => {
  const { user } = useAuth();
  const viewFeedbackMatch = useMatch('/requests/given/:taskToken');
  const viewGivenFeedback = useMatch('/feedback/given');

  if (viewFeedbackMatch || viewGivenFeedback) {
    return receiver;
  }

  const isManager = isManagerOf(receiver, user.token);
  const isGiver = user.token === giver.token;

  return isGiver && !isManager ? receiver : giver;
};

const MessageDate = styled(Header3)`
  color: #888;
`;

const Container = styled.div`
  display: flex;
  gap: 7px;
  flex-direction: column;
  padding: 24px 24px 12px 24px;
  margin: 0 -24px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border: 0;
  }
`;

const UserMessageContainer = styled.div`
  ${IF_MOBILE} {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 24px;
  }
`;
