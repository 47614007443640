import { ImpactEntryMap } from '@shared/impacts';
import { ReviewCycleToken } from '@shared/review-cycles';
import {
  IRecentFeedbackResponse,
  UserToken,
  isImpactToken,
} from '@shared/types';
import { useApi } from '@web/common/useApi';
import { uniq } from 'lodash';

export const useRecentFeedback = (
  userToken?: UserToken,
  reviewCycleToken?: ReviewCycleToken,
) => {
  const searchString = reviewCycleToken
    ? `?reviewCycle=${reviewCycleToken}`
    : '';
  const { data: feedbackResponse } = useApi<IRecentFeedbackResponse>(
    userToken
      ? `/feedback/${userToken}/manager/recent${searchString}`
      : `/feedback/recent${searchString}`,
  );
  const impactTokens = getFeedbackImpactTokens(feedbackResponse);
  const { data: impactEntryMap } = useApi<ImpactEntryMap>(
    impactTokens.length > 0
      ? `/impacts/map?impactTokens=${impactTokens.join(',')}`
      : null,
  );
  const isLoading =
    !feedbackResponse || (impactTokens.length > 0 && !impactEntryMap);

  return { feedbackResponse, impactEntryMap, isLoading };
};

function getFeedbackImpactTokens(feedbackResponse?: IRecentFeedbackResponse) {
  if (!feedbackResponse) {
    return [];
  }

  const allFeedback = [
    ...(feedbackResponse.feedback ?? []),
    ...(feedbackResponse.given ?? []),
  ];
  return uniq(allFeedback.map((f) => f.entityToken).filter(isImpactToken));
}
