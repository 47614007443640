import { TaskResult } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Button, Skeleton } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ViewFeedback } from './FeedbackList/ViewFeedback';

export const ViewTaskFeedbackPage: React.FC = () => {
  const { taskToken } = useParams();
  const { data: taskResult, error: taskError } = useApi<
    TaskResult,
    ServerResponseError
  >(`/tasks/${taskToken}`);

  if (!taskResult && !taskError) {
    return (
      <PageContent>
        <PageHeader
          title="View feedback"
          mobileTitle="View feedback"
          navigateBack
          defaultNavigateBackTo="/feedback"
        />
        <Skeleton />
      </PageContent>
    );
  }

  if (taskError) {
    return (
      <ErrorPageContent
        statusCode={taskError.statusCode}
        title={
          taskError.statusCode === 404 ? 'This page does not exist' : undefined
        }
        extra={
          <Link to="/feedback">
            <Button>Back to Feedback</Button>
          </Link>
        }
      />
    );
  }

  const { task, userMap } = taskResult ?? {};

  const feedbackRequest = task?.feedbackRequests?.[0];
  if (!feedbackRequest?.feedback) {
    return null;
  }
  const receiver = userMap?.[feedbackRequest.receiverToken];
  const giver = userMap?.[feedbackRequest.giverToken];
  // the /tasks/:taskToken endpoint does not return feedbackRequest on feedback as required
  // to display the given feedback, so we set it here manually
  feedbackRequest.feedback.feedbackRequest = feedbackRequest;

  if (!giver || !receiver) {
    return null;
  }

  return (
    <PageContent>
      <PageHeader
        title="View feedback"
        mobileTitle="View feedback"
        navigateBack
        defaultNavigateBackTo="/feedback"
      />
      <ViewFeedback feedback={feedbackRequest.feedback} userMap={userMap} />
    </PageContent>
  );
};
