import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { format } from 'date-fns';
import { DebouncedFunc, debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

export interface AutoSaveLabelProps {
  saveDate?: Date;
  lastState: AutoSaveState;
  generateLabel?: (saveDate?: Date) => string;
}
export const AutoSaveLabel: React.FC<AutoSaveLabelProps> = ({
  saveDate,
  lastState,
  generateLabel,
}) => {
  if (!saveDate) {
    return;
  }

  const label = generateLabel
    ? generateLabel(saveDate)
    : `Draft saved at ${format(saveDate, 'h:mm a')}`;
  return (
    <AutoSaveContainer gap={6}>
      <Text>{label}</Text>
      <AutoSaveStatus lastState={lastState} />
    </AutoSaveContainer>
  );
};
export type AutoSaveState = 'loading' | 'error' | 'success' | null;
const AutoSaveStatus: React.FC<{
  lastState: AutoSaveState;
}> = ({ lastState }) => {
  if (!lastState) {
    return;
  }
  if (lastState === 'success') {
    return <CheckOutlined />;
  }
  if (lastState === 'error') {
    return <CloseOutlined style={{ color: 'var(--color-error)' }} />;
  }
  return <LoadingOutlined />;
};
const AutoSaveContainer = styled(Row)`
  p {
    margin-bottom: 0;
    color: #888;
  }
`;

export interface IAutoSaveProps {
  debounceWaitMillis?: number;
}
export const useAutoSave = (
  props?: IAutoSaveProps,
): {
  autoSaveState: AutoSaveState;
  lastAutoSave: Date | null;
  handleAutosave: DebouncedFunc<
    (saveFunc: () => Promise<void>) => Promise<void>
  >;
} => {
  const { debounceWaitMillis = 500 } = props ?? {};

  const [autoSaveState, setAutoSaveState] = useState<AutoSaveState>(null);
  const [lastAutoSave, setLastAutoSave] = useState<Date>(null);
  const handleAutosave = useMemo(
    () =>
      debounce(async (saveFunc: () => Promise<void>) => {
        try {
          setAutoSaveState('loading');
          await saveFunc();
          setAutoSaveState('success');
          setLastAutoSave(new Date());
        } catch (error) {
          setAutoSaveState('error');
        }
      }, debounceWaitMillis),
    [],
  );

  return {
    autoSaveState,
    lastAutoSave,
    handleAutosave,
  };
};
