import { EditOutlined, RobotOutlined } from '@ant-design/icons';
import {
  createPerformanceSummary,
  generatePerformanceSummary,
  performanceEntries,
  useSummary,
} from '@client/PerformanceClient';
import { IChatCompletion } from '@shared/completions';
import {
  IPerformanceSummary,
  PerformanceSummaryToken,
} from '@shared/performance';
import { IUser, OrganizationToken, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useDefault as usePartial } from '@web/common/useApi';
import { EditTextModal } from '@web/components/EditTextModal';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { SelectDateRange } from '@web/components/SelectDate';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUser } from '@web/components/users/SelectUser';
import { Button, Skeleton, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { addMonths, format } from 'date-fns';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import removeMarkdown from 'remove-markdown';

import {
  performanceAccomplishmentsQuestion,
  performanceStrengthsQuestion,
} from '../questions';
import { SaveSummaryModal } from './SaveSummaryModal';

export const EditSummaryPage: React.FC = () => {
  const { performanceSummaryToken } = useParams<{
    performanceSummaryToken: PerformanceSummaryToken;
  }>();
  const { data: summary } = performanceSummaryToken
    ? useSummary(performanceSummaryToken)
    : usePartial<IPerformanceSummary>({});
  if (!summary) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  return <EditSummary summary={summary} />;
};

export const EditSummary: React.FC<{ summary?: IPerformanceSummary }> = ({
  summary,
}) => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  const existingStrengthResponse = summary?.responses?.find(
    (response) => response.questionToken === performanceStrengthsQuestion.token,
  );
  const existingAccomplishmentsResponse = summary?.responses?.find(
    (response) =>
      response.questionToken === performanceAccomplishmentsQuestion.token,
  );
  const navigate = useNavigate();
  const [generating, setGenerating] = React.useState(false);
  const [showNameModal, setShowNameModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(summary?.user);
  const [entriesJson, setEntriesJson] = React.useState(
    summary?.entriesJson ?? '',
  );
  const [editJournalEntries, setEditJournalEntries] = React.useState(false);
  const [editQuestion1Prompt, setEditQuestion1Prompt] = React.useState(false);
  const [question1Prompt, setQuestion1Prompt] = React.useState(
    existingAccomplishmentsResponse?.prompt ?? DEFAULT_QUESTION1_PROMPT,
  );
  const [question1Completion, setQuestion1Completion] =
    React.useState<IChatCompletion>();
  const [question1Response, setQuestion1Response] = React.useState(
    existingAccomplishmentsResponse?.response ?? '',
  );
  const [editQuestion2Prompt, setEditQuestion2Prompt] = React.useState(false);
  const [question2Prompt, setQuestion2Prompt] = React.useState(
    existingStrengthResponse?.prompt ?? DEFAULT_QUESTION2_PROMPT,
  );
  const [question2Completion, setQuestion2Completion] =
    React.useState<IChatCompletion>();
  const [question2Response, setQuestion2Response] = React.useState(
    existingStrengthResponse?.response ?? '',
  );
  const [startDate, setStartDate] = React.useState(
    summary?.startDate ?? format(addMonths(new Date(), -3), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = React.useState(
    summary?.endDate ?? format(new Date(), 'yyyy-MM-dd'),
  );

  const handleGenerateQuestion1 = async () => {
    if (generating || !selectedUser) {
      return;
    }

    setGenerating(true);
    void message.loading('Working...', 0);
    try {
      const completion = await generatePerformanceSummary(
        selectedUser.token,
        question1Prompt,
        entriesJson,
      );
      setQuestion1Completion(completion);
      setQuestion1Response(completion.response ?? '');
      void message.destroy();
    } catch (error) {
      void message.destroy();
      void message.error(error.message ?? 'Error');
    } finally {
      setGenerating(false);
    }
  };
  const handleGenerateQuestion2 = async () => {
    if (generating || !selectedUser) {
      return;
    }

    setGenerating(true);
    void message.loading('Working...', 0);
    try {
      const completion = await generatePerformanceSummary(
        selectedUser.token,
        question2Prompt,
        entriesJson,
      );
      setQuestion2Completion(completion);
      setQuestion2Response(completion.response ?? '');
      void message.destroy();
    } catch (error) {
      void message.destroy();
      void message.error(error.message ?? 'Error');
    } finally {
      setGenerating(false);
    }
  };
  const handleSaveClicked = () => {
    setShowNameModal(true);
  };
  const handleSave = async (name: string) => {
    if (!selectedUser || !question1Completion || !question2Completion) {
      void message.error('Error - bad state');
      return;
    }

    try {
      const summary = await createPerformanceSummary(
        {
          name,
          startDate,
          endDate,
          entriesJson,
          userToken: selectedUser.token,
        },
        [
          {
            questionToken: performanceAccomplishmentsQuestion.token,
            completionToken: question1Completion.token,
            response: question1Response,
            prompt: question1Prompt,
          },
          {
            questionToken: performanceStrengthsQuestion.token,
            completionToken: question2Completion.token,
            response: question2Response,
            prompt: question2Prompt,
          },
        ],
      );
      navigate(`../${summary.token}`, { replace: true });
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSelectUser = (user: IUser) => {
    setSelectedUser(user);
    void reloadEntries(user.token, startDate, endDate);
  };
  const handleDateRangeChanged = (newStart: string, newEnd: string) => {
    setStartDate(newStart);
    setEndDate(newEnd);
    if (selectedUser?.token) {
      void reloadEntries(selectedUser.token, newStart, newEnd);
    }
  };

  const reloadEntries = async (
    userToken: UserToken,
    journalStartDate: string,
    journalEndDate: string,
  ) => {
    const entries = await performanceEntries(
      userToken,
      journalStartDate,
      journalEndDate,
    );
    const json = JSON.stringify(
      entries.map((entry) => ({
        Title: entry.title,
        Description: removeMarkdown(entry.descriptionMarkdown),
        Date: entry.date,
      })),
      ' ' as any,
      2,
    );
    setEntriesJson(json);
  };

  const canGenerate = !!selectedUser && !!startDate && !!endDate;
  return (
    <PageContent>
      <PageHeader
        title={summary?.name ?? summary ? 'View Summary' : 'New Summary'}
        primaryAction={{
          label: 'Save New',
          onClick: handleSaveClicked,
          disabled: !question1Completion || !question2Completion,
        }}
        navigateBack
      />
      <Pane>
        <Column gap={24} style={{ maxWidth: 900 }}>
          <Column style={{ maxWidth: 300 }}>
            <Text>User</Text>
            <SelectUser
              initialUser={summary?.user}
              organizationToken={organizationToken}
              onChange={(_, user) => {
                void handleSelectUser(user);
              }}
            />
            <Spacer />
            <Text>Date Range</Text>
            <SelectDateRange
              start={startDate}
              end={endDate}
              onChange={handleDateRangeChanged}
            />
            <Spacer />
            <Text>Journal Entries</Text>
            <Button
              size="small"
              disabled={!canGenerate}
              onClick={() => {
                setEditJournalEntries(true);
              }}
            >
              <EditOutlined /> Edit JSON
            </Button>
          </Column>
          <Column>
            <Text>{performanceAccomplishmentsQuestion.text}</Text>
            <Column gap={6}>
              <TextArea
                value={question1Response}
                onChange={(e) => {
                  setQuestion1Response(e.currentTarget.value);
                }}
                style={{ height: 300 }}
              />
              <Row style={{ alignSelf: 'flex-end' }}>
                <Button
                  disabled={!canGenerate || generating}
                  onClick={() => {
                    setEditQuestion1Prompt(true);
                  }}
                  type="text"
                >
                  <EditOutlined /> Edit Prompt
                </Button>
                <Button
                  disabled={!canGenerate || generating}
                  onClick={handleGenerateQuestion1}
                  type="text"
                  style={{ alignSelf: 'center' }}
                >
                  <RobotOutlined /> Generate
                </Button>
              </Row>
            </Column>
          </Column>
          <Column>
            <Text>{performanceStrengthsQuestion.text}</Text>
            <Column gap={6}>
              <TextArea
                value={question2Response}
                onChange={(e) => {
                  setQuestion2Response(e.currentTarget.value);
                }}
                style={{ height: 300 }}
              />
              <Row style={{ alignSelf: 'flex-end' }}>
                <Button
                  disabled={!canGenerate || generating}
                  onClick={() => {
                    setEditQuestion2Prompt(true);
                  }}
                  type="text"
                >
                  <EditOutlined /> Edit Prompt
                </Button>
                <Button
                  disabled={!canGenerate || generating}
                  onClick={handleGenerateQuestion2}
                  type="text"
                  style={{ alignSelf: 'center' }}
                >
                  <RobotOutlined /> Generate
                </Button>
              </Row>
            </Column>
          </Column>
        </Column>
      </Pane>
      {editQuestion1Prompt && (
        <EditTextModal
          title="Edit accomplishments prompt"
          initialText={question1Prompt}
          onSave={(newPrompt) => {
            setQuestion1Prompt(newPrompt);
            setEditQuestion1Prompt(false);
          }}
          onCancel={() => {
            setEditQuestion1Prompt(false);
          }}
        />
      )}
      {editQuestion2Prompt && (
        <EditTextModal
          title="Edit strengths prompt"
          initialText={question2Prompt}
          onSave={(newPrompt) => {
            setQuestion2Prompt(newPrompt);
            setEditQuestion2Prompt(false);
          }}
          onCancel={() => {
            setEditQuestion2Prompt(false);
          }}
        />
      )}
      {editJournalEntries && (
        <EditTextModal
          title="Journal Entries"
          initialText={entriesJson}
          onSave={(newJson) => {
            setEntriesJson(newJson);
            setEditJournalEntries(false);
          }}
          onCancel={() => {
            setEditJournalEntries(false);
          }}
        />
      )}
      {showNameModal && (
        <SaveSummaryModal
          onSave={handleSave}
          onCancel={() => {
            setShowNameModal(false);
          }}
        />
      )}
    </PageContent>
  );
};

const DEFAULT_QUESTION1_PROMPT = `Using the list of my work described in the JSON file, determine the two biggest, most important Accomplishments that represent as much of the work as possible. Write a self review that summarizes each accomplishment, including a heading and a few examples for each. Write the review in the first person using "I".
Here is a list of work completed, in JSON:
`;
const DEFAULT_QUESTION2_PROMPT = `Using the list of the work I completed described in the JSON file, determine my 2 or 3 biggest strengths. Focus on skills and capabilities that I should use in future work. Don't include a title in the response.
Here is a list of work completed, in JSON:
`;
