import { EditOutlined } from '@ant-design/icons';
import { updateGoal } from '@client/GoalsClient';
import { IGoal } from '@shared/goals';
import { UserToken } from '@shared/types';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { message } from 'antd';
import * as React from 'react';

import { SelectUsersModal } from '../components/SelectUsersModal';

export const GoalAssignedUsers: React.FC<{
  goal: IGoal;
  onChange: () => void;
}> = ({ goal, onChange }) => {
  const [showEditAssigned, setShowEditAssigned] = React.useState(false);

  const handleUpdateAssigned = async (userTokens: UserToken[]) => {
    try {
      await updateGoal({ assignedUsers: userTokens }, goal.token);
      onChange();
      setShowEditAssigned(false);
    } catch (error) {
      void message.error('Error updating goal');
    }
  };
  return (
    <Row style={{ alignItems: 'flex-start' }}>
      <Text style={{ minWidth: 120 }}>
        Assigned{' '}
        {goal.isPublic && (
          <EditOutlined
            onClick={() => {
              setShowEditAssigned(true);
            }}
          />
        )}
      </Text>
      <Column gap={6} style={{ padding: '3px 0' }}>
        {goal.assignedUsers?.map((user) => (
          <UserMessage
            key={`assigned_${user.token}`}
            style={{ gap: 6 }}
            avatarSize={18}
            user={user}
          />
        ))}
        {goal.assignedUsers?.length === 0 && <Text>None</Text>}
      </Column>
      {showEditAssigned && (
        <SelectUsersModal
          title="Update Assigned"
          label="Include individuals that this goal is assigned to"
          initialUsers={goal.assignedUsers}
          onSave={handleUpdateAssigned}
          onCancel={() => {
            setShowEditAssigned(false);
          }}
        />
      )}
    </Row>
  );
};
