import { ConfigProvider } from 'antd';
import * as React from 'react';

import { primaryColor } from './ColorStyles';
import { GlobalStyles } from './GlobalStyles';

export const StylesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <>
    <GlobalStyles />
    <ConfigProvider
      theme={{
        token: {
          linkDecoration: 'none',
          colorLink: 'var(--ant-primary)',
          fontFamily: `'Poppins', sans-serif`,
          colorPrimary: primaryColor.string(),
          colorTextPlaceholder: '#aaa',
        },
      }}
    >
      {children}
    </ConfigProvider>
  </>
);
