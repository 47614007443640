import { ILabeledMetric, METRIC_COLORS } from '@shared/analytics';
import { Column } from '@web/components/layout';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { max, min } from 'lodash';
import React from 'react';

export const LineGraphMetric: React.FC<{
  labeledMetrics: ILabeledMetric[];
  width?: number;
  height?: number;
  hideLabels?: boolean;
}> = ({ labeledMetrics, width = 280, height = 100, hideLabels = false }) => {
  const xAxisData: string[] = [];
  const seriesData: Array<number | null> = [];
  for (const labeledMetric of labeledMetrics) {
    xAxisData.push(labeledMetric.label);
    seriesData.push(labeledMetric.value);
  }

  const chartOptions = createLineChartOptions(
    xAxisData,
    seriesData,
    hideLabels,
  );
  return (
    <Column style={{ alignItems: 'center' }}>
      <ReactECharts
        style={{ width, height, padding: 0 }}
        option={chartOptions}
      />
    </Column>
  );
};
const createLineChartOptions = (
  xAxisData: string[],
  seriesData: Array<number | null>,
  hideLabels: boolean,
): EChartsOption => {
  const yAxisMin = min(seriesData);
  const yAxisMax = max(seriesData);
  return {
    xAxis: {
      type: 'category',
      data: xAxisData,
      show: !hideLabels,
    },
    yAxis: {
      type: 'value',
      show: false,
      min: Math.max(yAxisMin - 1, 0),
      max: yAxisMax + 1,
    },
    series: [
      {
        data: seriesData,
        type: 'line',
        lineStyle: { color: '#888' },
        itemStyle: {
          color: METRIC_COLORS[2],
        },
        symbol: 'circle',
        symbolSize: 6,
        label: {
          show: true,
          position: 'top',
          color: METRIC_COLORS[2],
          fontSize: 11,
        },
        smooth: true,
      },
    ] as EChartsOption['series'][number],
    grid: {
      left: '16px',
      right: '16px',
      top: '20px',
      bottom: '20px',
    },
    tooltip: {
      trigger: 'axis',
      show: 'true',
    },
  };
};
