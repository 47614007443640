export enum QuestionType {
  RATING = 'RATING',
  OPEN_ENDED = 'OPEN_ENDED',
  LABEL = 'LABEL',
  RANK = 'RANK',
}

export const isQuestionType = (type: any): type is QuestionType => {
  return Object.values(QuestionType).includes(type);
};

export const QuestionTypeLabels: Record<QuestionType, string> = {
  [QuestionType.RATING]: 'Likert scale',
  [QuestionType.OPEN_ENDED]: 'Open-ended',
  [QuestionType.LABEL]: 'Instructions',
  [QuestionType.RANK]: 'Ranking',
};
