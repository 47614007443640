import { times } from 'lodash';

import { QuestionType } from './QuestionType';
import { RatingScale } from './rating_scales';
import { OrganizationToken } from './types';

export type QuestionToken = `q_${string}`;
export function isQuestionToken(token: any): token is QuestionToken {
  return !!token?.startsWith('q_');
}
export interface IQuestionText {
  text: string;
  context?: string;
}
export interface IRankOption {
  token: `ro_${string}`;
  label: string;
  description: string;
}
export type RankingResponse = Record<IRankOption['token'], number>;
export const validateRanking = (
  rankingOptions: IRankOption[],
  ranking: RankingResponse,
) => {
  const missing = new Set<number>(times(rankingOptions.length, (n) => n + 1));
  const duplicates = new Set<IRankOption['token']>();
  const usedRanks = new Set<number>();
  for (const option of rankingOptions) {
    const currentRank = ranking[option.token];
    missing.delete(currentRank);
    if (usedRanks.has(currentRank)) {
      duplicates.add(option.token);
    } else if (currentRank) {
      usedRanks.add(currentRank);
    }
  }
  return { duplicates, missing };
};
export interface IRawQuestion {
  token: QuestionToken;
  type?: QuestionType;
  texts?: IQuestionText[];
  text?: string;
  scale?: RatingScale;
  rankingOptions?: IRankOption[];
  position?: number;
  required?: boolean;
  organizationToken?: OrganizationToken;
  correlationToken?: QuestionToken;
  counterpartQuestionToken?: QuestionToken;
  createdDate?: Date;
  updatedDate?: Date;
  deletedDate?: Date;
}

export type IQuestion =
  | IOpenEndedQuestion
  | IRatingQuestion
  | ILabelQuestion
  | IRankQuestion;

export interface IBaseQuestion {
  token: QuestionToken;
  type: QuestionType;
  text: string;
  position?: number;
  correlationToken?: QuestionToken;
  counterpartQuestionToken?: QuestionToken;
  required?: boolean;
  createdDate?: Date;
  updatedDate?: Date;
  deletedDate?: Date;
}

export interface IOpenEndedQuestion extends IBaseQuestion {
  type: QuestionType.OPEN_ENDED;
  required?: boolean;
}

export interface IRankQuestion extends IBaseQuestion {
  type: QuestionType.RANK;
  rankingOptions?: IRankOption[];
  required?: boolean;
}

export interface ILabelQuestion extends IBaseQuestion {
  type: QuestionType.LABEL;
}

export function isQuestionLabel(
  question: IQuestion,
): question is ILabelQuestion {
  return question.type === QuestionType.LABEL;
}

export function isOpenEndedQuestion(
  question: IQuestion,
): question is IOpenEndedQuestion {
  return question.type === QuestionType.OPEN_ENDED;
}

export interface IRatingQuestion extends IBaseQuestion {
  type: QuestionType.RATING;
  required?: boolean;
  scale: RatingScale;
  // Whether to include the value as a prefix to the question. Default: true.
  includeValueInLabel?: boolean;
}

export function isRatingQuestion(
  question: IQuestion,
): question is IRatingQuestion {
  return question?.type === QuestionType.RATING;
}

export function isRankQuestion(
  question?: IQuestion,
): question is IRankQuestion {
  return question?.type === QuestionType.RANK;
}

export const MIN_WORDS_REQUIRED = 3;

export const impactStrengthQuestion: IQuestion = {
  token: 'q_impact_strength',
  text: 'What did they do well and should continue doing?',
  type: QuestionType.OPEN_ENDED,
};

export const impactOpportunityQuestion: IQuestion = {
  token: 'q_impact_opportunity',
  text: 'What could they do differently in the future to have more impact?',
  type: QuestionType.OPEN_ENDED,
};

export const feedbackRequestQuestions: IQuestion[] = [
  {
    token: 'q_feedback_request_strengths',
    type: QuestionType.OPEN_ENDED,
    text: 'What do you think are my strengths, and how can I build on those strengths to be even more effective?',
  },
  {
    token: 'q_feedback_request_role',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to improve and grow in my role?',
  },
  {
    token: 'q_feedback_request_team_support',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to be provide more support to my team?',
  },
  {
    token: 'q_feedback_request_communication',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to improve my communication?',
  },
  {
    token: 'q_feedback_request_collaboration',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to be a better collaborator?',
  },
  {
    token: 'q_feedback_request_openness',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to help me be more receptive to constructive feedback?',
  },
  {
    token: 'q_feedback_request_problem_solving',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to improve my problem solving skills?',
  },
  {
    token: 'q_feedback_request_impact',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me so that I could make a bigger impact on the team?',
  },
  {
    token: 'q_feedback_request_planning',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to improve my planning skills?',
  },
  {
    token: 'q_feedback_request_productivity',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to help me be more productive?',
  },
  {
    token: 'q_feedback_request_ownership',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to take better ownership over my work?',
  },
  {
    token: 'q_feedback_request_respect',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to ensure that our workplace is inclusive and respectful for everyone?',
  },
  {
    token: 'q_feedback_request_trust',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to increase the level of trust within the team?',
  },
  {
    token: 'q_feedback_request_career_development',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to create a culture of continuous learning and development within the team?',
  },
  {
    token: 'q_feedback_request_fairness',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to ensure that our team operates fairly and equitably?',
  },
  {
    token: 'q_feedback_request_team_effectiveness',
    type: QuestionType.OPEN_ENDED,
    text: "What advice would you give me about how can I grow my team's effectiveness?",
  },
];
