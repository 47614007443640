import {
  GetReflectionResponse,
  ReflectionState,
  isManagerReflection,
} from '@shared/reflections';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Spacer } from '@web/components/layout';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReflectionForm } from './ReflectionForm';
import { pageDescription, pageTitle } from './reflections';

export const EditReflectionPage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { reflectionToken } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showTour, setShowTour] = useState(false);

  const {
    data: response,
    error,
    mutate,
  } = useApi<GetReflectionResponse, ServerResponseError>(
    `/reflections/${reflectionToken}`,
  );
  const reflection = response?.reflection;
  const questionSet = response?.questionSet;
  const isCycleReflection = !!reflection?.reviewCycleToken;
  useEffect(() => {
    if (reflection && isCycleReflection) {
      if (isManagerReflection(reflection)) {
        navigate(
          `/cycles/${reflection.reviewCycleToken}/users/${reflection.receiverToken}/reflection/edit`,
          { replace: true },
        );
      } else if (!reflection.canEdit) {
        navigate(`/cycles/${reflection.reviewCycleToken}`, {
          replace: true,
        });
      }
    }
  }, [response]);

  if (error) {
    return <ErrorPageContent statusCode={error.statusCode} />;
  } else if (!response?.reflection) {
    return (
      <PageContent>
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  if (isCycleReflection && isManagerReflection(reflection)) {
    return (
      <PageContent>
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }
  let defaultNavigateBackTo =
    reflection.state === ReflectionState.SHARED
      ? `/reflections/${reflection.token}`
      : `/team/${reflection.receiverToken}/reflections`;
  if (reflection.reviewCycleToken) {
    defaultNavigateBackTo = `/cycles/${reflection.reviewCycleToken}`;
  }

  const handleChange = async () => {
    await mutate();
  };

  const handleCloseTour = () => {
    setShowTour(false);
  };

  return (
    <PageContent>
      <PageHeader
        title={pageTitle(user, reflection)}
        mobileTitle={pageTitle(user, reflection)}
        description={isMobile ? undefined : pageDescription(reflection)}
        navigateBack
        defaultNavigateBackTo={defaultNavigateBackTo}
        secondaryActions={
          isCycleReflection
            ? undefined
            : [
                {
                  label: 'Show Tour',
                  onClick: () => {
                    setShowTour(true);
                  },
                },
              ]
        }
      />
      <ReflectionForm
        onChange={handleChange}
        showTour={showTour}
        onCloseTour={handleCloseTour}
        existingReflection={reflection}
        questionSet={questionSet}
        defaultNavigateBackTo={defaultNavigateBackTo}
      />
      {isMobile && <Spacer />}
    </PageContent>
  );
};
