import { ITask, TaskType } from '@shared/types';
import { put } from '@web/common/api';
import { StopPropagation } from '@web/components/StopPropagation';
import { Button, Modal, message } from 'antd';
import * as React from 'react';

interface IDeclineRequestButtonProps {
  className?: string;
  task: ITask;
  onDecline: () => void;
}
export const DeclineRequestButton: React.FC<IDeclineRequestButtonProps> = ({
  className,
  task,
  onDecline,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const handleDeclineClicked = async () => {
    const content =
      task.type === TaskType.SUGGESTED_ENTRY
        ? 'Are you sure you want to decline this suggestion to add an entry to your journal?'
        : task.type === TaskType.REFLECTION
        ? `Are you sure you want to skip submitting a reflection?`
        : 'Are you sure you want to decline this request for feedback?';
    const confirm = await modal.confirm({
      title: 'Decline request',
      content,
      icon: null,
      okText: 'Continue',
    });
    if (confirm) {
      try {
        await put(`/tasks/${task.token}/decline`);
        void message.success('Request has been declined');
        onDecline();
      } catch (error) {
        void message.error('Error');
      }
    }
  };

  return (
    <StopPropagation>
      <Button className={className} size="small" onClick={handleDeclineClicked}>
        Decline
      </Button>
      {contextHolder}
    </StopPropagation>
  );
};
