import { EditOutlined } from '@ant-design/icons';
import { ITag, UpdateTagRequest, UpdateTagResponse } from '@shared/tags';
import { del, put } from '@web/common/api';
import { Column, GrowingSpacer, Spacer } from '@web/components/layout';
import { Empty, Modal, Row, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';

import { TagForm, TagFormFields } from './TagForm';
import { DeleteTagButton } from './ViewTagGroupPage';

export const TagsTable: React.FC<{
  onMutate: () => void;
  tags: ITag[];
}> = ({ onMutate, tags }) => {
  const [editTarget, setEditTarget] = useState<ITag | null>(null);

  const handleCancel = () => {
    setEditTarget(null);
  };

  const handleEditTag = async (tag: ITag) => {
    setEditTarget(tag);
  };

  const handleDeleteTag = async (tag: ITag) => {
    await del(`/tags/${tag.token}`);
    await onMutate();
  };

  const handleSave = async (fields: TagFormFields) => {
    const request: UpdateTagRequest = {
      token: editTarget.token,
      name: fields.name,
      description: fields.description,
    };
    await put<UpdateTagRequest, UpdateTagResponse>(
      `/tags/${editTarget.token}`,
      request,
    );
    await onMutate();
    setEditTarget(null);
  };

  const columns: ColumnsType<ITag> = [
    {
      title: 'Tag',
      dataIndex: 'label',
      key: 'label',
      render: (label) => <Tag style={{ fontSize: '12px' }}>{label}</Tag>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description) =>
        description ? (
          <Typography.Text>{description}</Typography.Text>
        ) : (
          <Typography.Text italic>No description</Typography.Text>
        ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (_, tag: ITag) => (
        <span>
          <Row>
            <GrowingSpacer />
            <EditOutlined
              onClick={() => {
                void handleEditTag(tag);
              }}
            />
            <Spacer size={4} />
            <DeleteTagButton
              onConfirm={() => {
                void handleDeleteTag(tag);
              }}
            />
          </Row>
        </span>
      ),
    },
  ];

  return (
    <Column>
      <Table
        columns={columns}
        dataSource={tags}
        pagination={false}
        locale={{ emptyText: <Empty description="No tags" /> }}
        rowKey="token"
      />
      <Modal
        title="Edit Tag"
        open={!!editTarget}
        footer={null}
        onCancel={handleCancel}
      >
        <TagForm
          initialTag={editTarget}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      </Modal>
    </Column>
  );
};
