import { useTeamsManagedByMe } from '@client/TeamClient';
import {
  IUser,
  OrganizationToken,
  UserMapItem,
  UserToken,
} from '@shared/types';
import { Select } from 'antd';
import { filter, sortBy, uniqBy } from 'lodash';
import * as React from 'react';

interface Props {
  disabled?: boolean;
  initialUser?: UserMapItem;
  onChange: (newValue: UserToken, user: IUser | null) => void;
  organizationToken?: OrganizationToken;
  style?: React.CSSProperties;
}

export const SelectUserFromTeams: React.FC<Props> = ({
  disabled,
  initialUser,
  onChange,
  style,
}) => {
  const { data: teams } = useTeamsManagedByMe();
  const users: IUser[] = sortBy(
    uniqBy(
      teams?.flatMap((team) => filter([team.manager, ...team.members])) ?? [],
      'token',
    ),
    'name',
  ) as any;

  const selectRef = React.useRef<{ blur: () => void }>();

  const userOptions = users.map((user) => ({
    value: user.token,
    label: user.name,
  }));

  const handleSelectChange = (option: { label: string; value: UserToken }) => {
    const userToken = option?.value ?? null;
    const user = userToken ? users.find((u) => u.token === userToken) : null;
    onChange(userToken, user ?? null);
    selectRef.current?.blur();
  };

  return (
    <Select
      placeholder="Select a team member"
      disabled={disabled}
      notFoundContent={null}
      defaultValue={
        initialUser
          ? { label: initialUser.name, value: initialUser.token }
          : undefined
      }
      style={style}
      options={userOptions}
      filterOption={false}
      onChange={handleSelectChange as any}
      labelInValue={true}
      choiceTransitionName=""
      transitionName=""
      animation=""
      defaultActiveFirstOption={false}
      ref={
        selectRef as any
      } /** Not sure how to impact BaseSelectRef from rc-select */
    />
  );
};
