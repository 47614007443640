import { ITeamMetricsResponse } from '@shared/teams';
import { UserToken } from '@shared/types';
import { get } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useTeamSummary(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/team-summary/${userToken}/summary`,
    query: {},
  });

  return useApi<ITeamMetricsResponse>(url);
}

export function teamSummary(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/team-summary/${userToken}/summary`,
    query: {},
  });

  return get<ITeamMetricsResponse>(url);
}
