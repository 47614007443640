import { updateGoal } from '@client/GoalsClient';
import { IGoal } from '@shared/goals';
import { RichTextArea } from '@web/components/RichTextArea';
import { Column, Row } from '@web/components/layout';
import { Button, Input, message } from 'antd';
import * as React from 'react';

interface Props {
  onSave: (goal: IGoal) => void;
  onCancel: () => void;
  goal?: IGoal;
}
export const EditGoal: React.FC<Props> = ({
  onSave,
  onCancel,
  goal: initialGoal,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [title, setTitle] = React.useState(initialGoal.title);
  const [content, setContent] = React.useState(initialGoal.content);

  const handleSave = async () => {
    if (!title || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      const goal = await updateGoal(
        {
          title,
          content,
        },
        initialGoal.token,
      );
      void message.success('Success');
      onSave(goal);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const canSave = !!title;

  return (
    <Column gap={12}>
      <Input
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        autoFocus
      />
      <RichTextArea
        initialValue={content}
        onChange={setContent}
        showToolbar
        placeholder="Add a description"
      />
      <Row gap={6}>
        <Button type="primary" onClick={handleSave} disabled={!canSave}>
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Row>
    </Column>
  );
};
