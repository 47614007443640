import { useSurveySummary } from '@client/SurveyCyclesClient';
import {
  SurveyCycleToken,
  SurveyParticipantToken,
  sortedSurveyQuestions,
} from '@shared/surveys';
import { UserMapItem } from '@shared/types';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AdminPageContent } from '../../admin/AdminPageContent';
import { AdminSurveyContent } from './AdminSurveyContent';
import { ResponseSummaries } from './ResponseSummaries';

const AdminSurveyCycleResponsesPage: React.FC = () => {
  const { surveyCycleToken } = useParams<{
    surveyCycleToken: SurveyCycleToken;
  }>();
  const { data: response } = useSurveySummary(surveyCycleToken as any);
  if (!response) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const participantMap: Record<SurveyParticipantToken, UserMapItem> = {};
  response.surveyCycle.participants?.forEach((participant) => {
    participantMap[participant.token] = participant.user;
  });
  const sortedQuestions = sortedSurveyQuestions(response.surveyCycle);
  return (
    <AdminSurveyContent surveyCycle={response.surveyCycle}>
      <ResponseSummaries
        questions={sortedQuestions}
        summaries={response.summaries}
        participantMap={participantMap}
        totalParticipants={response.totalParticipants}
        participants={
          response.surveyCycle.anonymous
            ? undefined
            : response.surveyCycle.participants
        }
        currentSurveyCycleToken={surveyCycleToken}
        averageRatingTrendMap={response.averageRatingTrendMap}
      />
    </AdminSurveyContent>
  );
};

export default AdminSurveyCycleResponsesPage;
