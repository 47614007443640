import { SearchOutlined } from '@ant-design/icons';
import { GetManagersResponse } from '@shared/teams';
import { IUser, UserToken } from '@shared/types';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { useApi } from '@web/common/useApi';
import { Column } from '@web/components/layout';
import { Button, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
  onSelect: (user: IUser) => void;
  children: React.ReactNode;
  buttonStyle?: React.CSSProperties;
  modalTitle?: string;
  icon?: React.ReactNode;
  omitUserTokens?: UserToken[];
}

export const SelectManagerButton: React.FC<Props> = ({
  onSelect,
  children,
  buttonStyle,
  modalTitle,
  icon,
  omitUserTokens,
}) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleUserSelected = (user: IUser) => {
    onSelect(user);
    setOpen(false);
  };

  return (
    <>
      <Button
        icon={icon}
        type="default"
        onClick={showModal}
        style={buttonStyle}
      >
        {children}
      </Button>
      <AddUserModal
        open={open}
        onSelect={handleUserSelected}
        onCancel={handleCancel}
        title={modalTitle}
        omitUserTokens={omitUserTokens}
      />
    </>
  );
};

interface AddUserModalProps {
  onCancel: () => void;
  onSelect: (user: IUser) => void;
  open: boolean;
  title?: string;
  omitUserTokens?: UserToken[];
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  onSelect,
  onCancel,
  open,
  title = 'Select a user',
  omitUserTokens,
}) => {
  const omittedUserTokens = omitUserTokens ?? [];
  const [selectedUser, setSelectedUser] = React.useState<IUser>(null);
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState<IUser[]>([]);
  const { data: response } = useApi<GetManagersResponse>(`/teams/managers`);

  useEffect(() => {
    if (!response?.managers) {
      return;
    }
    setResults(
      response.managers.filter(
        (u) =>
          !omittedUserTokens.includes(u.token) &&
          u.name.toLowerCase().includes(query.toLowerCase()),
      ),
    );
  }, [response, omittedUserTokens, query]);

  const handleClose = () => {
    onCancel();
    setQuery('');
  };

  const handleSelect = (user: IUser) => {
    if (user.token === selectedUser?.token) {
      setSelectedUser(null);
    } else {
      setSelectedUser(user);
    }
  };

  const handleOk = () => {
    onSelect(selectedUser);
    setSelectedUser(null);
    setQuery('');
  };

  return (
    <Modal
      title={title}
      open={open}
      afterClose={handleClose}
      onCancel={onCancel}
      width="400px"
      okButtonProps={{
        disabled: !selectedUser,
        style: { width: 100 },
      }}
      cancelButtonProps={{ style: { width: 100 } }}
      onOk={handleOk}
    >
      <Column gap={12}>
        <Input
          value={query}
          onChange={async (e) => {
            setQuery(e.currentTarget.value);
          }}
          autoFocus
          allowClear
          prefix={<SearchOutlined />}
        />
        <Results>
          {results.map((user) => (
            <UserResult
              key={user.token}
              onClick={() => {
                handleSelect(user);
              }}
              className={user.token === selectedUser?.token ? 'selected' : null}
            >
              {user.name}
            </UserResult>
          ))}
        </Results>
      </Column>
    </Modal>
  );
};

const Results = styled(Column)`
  border: 1px solid #ddd;
  border-radius: var(--default-border-radius);
  height: 182px;
  overflow: auto;
`;
const UserResult = styled(Row)`
  padding: 0 12px;
  line-height: 36px;
  cursor: pointer;

  &:hover {
    background: ${complimentaryColor.lighten(0.9)};
  }

  &.selected {
    background: ${complimentaryColor.lighten(0.7)};
  }
`;
