import { IReflection, ReflectionToken } from './reflections';
import {
  FeedbackToken,
  IFeedback,
  IUser,
  OrganizationToken,
  UserToken,
} from './types';

export type GoalToken = `g_${string}`;
export enum GoalState {
  OPEN = 'OPEN',
  ON_TRACK = 'ON_TRACK',
  AT_RISK = 'AT_RISK',
  OFF_TRACK = 'OFF_TRACK',
  COMPLETED = 'COMPLETED',
  OBSOLETE = 'OBSOLETE',
}

export type GoalListToken = `gl_${string}`;
export interface IGoalList {
  token: GoalListToken;
  title: string;
  organizationToken: OrganizationToken;
  user: IUser;
  userToken: UserToken;
  followers: IUser[];
  goals: IGoal[];
  sortedGoals: GoalToken[];
  archivedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
}

export interface IGoal {
  token: GoalToken;
  title: string;
  content?: string;
  userToken: UserToken;
  user?: IUser;
  authorToken: UserToken;
  author?: IUser;
  organizationToken?: OrganizationToken;
  state: GoalState;
  dueDate?: Date;
  feedbackToken?: FeedbackToken;
  feedback?: IFeedback;
  reflectionToken?: ReflectionToken;
  reflection?: IReflection;
  isPublic: boolean;
  assignedUsers: IUser[];
  followers: IUser[];
  parents: IGoal[];
  supporting: IGoal[];
  goalLists: IGoalList[];
  archivedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}
export function isGoalToken(token: string | undefined): token is GoalToken {
  return !!token?.startsWith('g_');
}
export interface CreateGoalRequest {
  title: string;
  content: string;
  userToken?: UserToken;
  dueDate?: string;
  isPublic: boolean;
  assignedUsers?: UserToken[];
  followers?: UserToken[];
  parent?: GoalToken;
  lists?: GoalListToken[];
  state?: GoalState;
}
export interface UpdateGoalRequest {
  title?: string;
  content?: string;
  state?: GoalState;
  dueDate?: string | null;
  assignedUsers?: UserToken[];
  followers?: UserToken[];
  parent?: GoalToken;
}
export interface CreateGoalListRequest {
  title: string;
  followers?: UserToken[];
  goals?: GoalToken[];
}
export interface UpdateGoalListRequest {
  title?: string;
  followers?: UserToken[];
  goals?: GoalToken[];
}
export const isPersonalGoal = (goal) => !goal.isPublic;
export const isAssignedGoal = (userToken: UserToken) => (goal) =>
  goal.isPublic &&
  goal.assignedUsers.some((assigned) => assigned.token === userToken);
export const isFollowingGoal = (userToken: UserToken) => (goal) =>
  goal.isPublic &&
  goal.followers.some((follower) => follower.token === userToken);
