import { TypographyColors, TypographyCss } from '@web/components/typography';
import { Dropdown, MenuProps } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const AddGoalDropdown: React.FC<{
  onAddExisting: () => void;
  onCreateNew: () => void;
  children?: React.ReactNode;
}> = ({ children, onAddExisting, onCreateNew }) => {
  const actionItems: MenuProps['items'] = [
    {
      label: (
        <ActionButton
          onClick={() => {
            onAddExisting();
          }}
        >
          Add an existing goal
        </ActionButton>
      ),
      key: 'add-existing',
    },
    {
      label: (
        <ActionButton
          onClick={() => {
            onCreateNew();
          }}
        >
          Create a new goal
        </ActionButton>
      ),
      key: 'create-new',
    },
  ];
  return (
    <Dropdown arrow placement="top" menu={{ items: actionItems }}>
      {children}
    </Dropdown>
  );
};

const ActionButton = styled.button`
  appearance: none;
  border: 0;
  ${TypographyCss.Text}
  color: ${TypographyColors.text}!important;
  background: unset;
  cursor: pointer;
`;
