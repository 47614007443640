import { CheckCircleFilled } from '@ant-design/icons';
import { useTeamSummary } from '@client/TeamSummaryClient';
import { Feature } from '@shared/features';
import { UserMapItem, UserToken } from '@shared/types';
import { ManagerOneOnOneTypeLabels } from '@web/1on1s/ManagerOneOnOneTypeLabels';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { successColor } from '@web/app/styles/ColorStyles';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { Pane } from '@web/components/Pane';
import { UserAvatar } from '@web/components/UserAvatar';
import { Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { filter, sortBy } from 'lodash';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TeamPageHeader } from './TeamPageHeader';

const TeamMembersPage: React.FC = () => {
  const navigate = useNavigate();
  const { booleanValue: teamMetricsEnabled } = useFeature(Feature.TEAM_METRICS);
  const { user: currentUser } = useAuth();
  const { isMobile } = useResponsive();
  const { userToken } = useParams<{ userToken?: UserToken }>();
  const { data: metricsResponse, error } = useTeamSummary(
    `${userToken ?? currentUser.token}`,
  );

  if (error) {
    return <ErrorPageContent />;
  }

  const users: UserMapItem[] = metricsResponse
    ? sortBy(Object.values(metricsResponse.userMap), 'name')
    : [];
  const columns: ColumnsType<UserMapItem> = filter([
    {
      title: 'Team member',
      dataIndex: 'name',
      key: 'name',
      render: (name, user) => {
        return (
          <Row gap={12}>
            <UserAvatar user={user} size={32} />
            <Text
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 500,
                lineHeight: '20px',
              }}
            >
              {name}
            </Text>
          </Row>
        );
      },
    },
    teamMetricsEnabled
      ? {
          title: 'Recently completed 1-on-1',
          key: 'completed-1on1',
          width: '160px',
          align: 'center',
          render: (_, user) => {
            return metricsResponse.metrics[user.token].completedOneOnOnes >
              0 ? (
              <CheckCircleFilled style={{ color: successColor.string() }} />
            ) : (
              '-'
            );
          },
        }
      : (null as any),
    teamMetricsEnabled
      ? {
          title: '1-on-1 with manager',
          key: 'manager-1on1',
          width: '160px',
          align: 'center',
          render: (_, user) => {
            return metricsResponse.metrics[user.token].managerType
              ? ManagerOneOnOneTypeLabels[
                  metricsResponse.metrics[user.token].managerType
                ]
              : '-';
          },
        }
      : (null as any),
  ]);

  return (
    <PageContent>
      <TeamPageHeader path="members" />
      <Spacer />
      <Pane>
        {!metricsResponse ? (
          <Skeleton />
        ) : users.length > 0 ? (
          <Table
            rowKey="token"
            columns={columns}
            dataSource={users}
            pagination={false}
            onRow={(row) => {
              return {
                onClick: () => navigate(`/profile/${row.token}`),
                style: { cursor: 'pointer' },
              };
            }}
          />
        ) : (
          <Text>There are no members on this team</Text>
        )}
      </Pane>
      {isMobile && <Spacer />}
    </PageContent>
  );
};

export default TeamMembersPage;
