import { WarningOutlined } from '@ant-design/icons';
import { BedrockMechanism, BedrockRunOpts } from '@shared/bedrock';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { InputNumber, Modal, Tooltip, message } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import * as React from 'react';

import { SelectMechanism } from './SelectMechanism';

export interface PrepareRunFormData {
  runOpts: BedrockRunOpts;
  mechanism: BedrockMechanism;
}

export const PrepareRunModal: React.FC<{
  open: boolean;
  onSubmit: (formData: PrepareRunFormData) => Promise<void>;
  onCancel: () => void;
}> = ({ open, onSubmit, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [adjacentMessageCount, setAdjacentMessageCount] = React.useState(1);
  const [maxEntryCount, setMaxEntryCount] = React.useState(10);
  const [suggestedEntryCount, setSuggestedEntryCount] = React.useState(5);
  const [mechanism, setMechanism] = React.useState<
    BedrockMechanism | undefined
  >('journal');
  const [savePrompt, setSavePrompt] = React.useState(false);
  const [minimumMessages, setMinimumMessages] = React.useState(1);

  const handleOk = async () => {
    setIsSubmitting(true);
    try {
      const formData: PrepareRunFormData = {
        runOpts: {
          suggestedEntryCount,
          maxEntryCount,
          adjacentMessageCount,
          savePrompt,
          minimumRequiredMessagesPerReport: minimumMessages,
        },
        mechanism,
      };
      await onSubmit(formData);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleClose = () => {
    setIsSubmitting(false);
    onCancel();
  };

  const handleSavePromptChecked = () => {
    setSavePrompt(!savePrompt);
  };

  return (
    <Modal
      title="Prepared Run"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      okText="Prepare Run"
      afterClose={handleClose}
      onCancel={onCancel}
      okButtonProps={{
        disabled: isSubmitting || !mechanism,
      }}
    >
      <Column gap={12}>
        <Column gap={6}>
          <Text>Maximum Entries</Text>
          <InputNumber
            disabled={isSubmitting}
            value={maxEntryCount}
            onChange={(count) => {
              setMaxEntryCount(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Suggested Entries</Text>
          <InputNumber
            disabled={isSubmitting}
            value={suggestedEntryCount}
            onChange={(count) => {
              setSuggestedEntryCount(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Adjacent Message Count</Text>
          <InputNumber
            disabled={isSubmitting}
            value={adjacentMessageCount}
            onChange={(count) => {
              setAdjacentMessageCount(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Tooltip
            title="The minimum number of messages required to produce a non-empty report, accounting for message grouping and message adjacency "
            placement="left"
          >
            <Text>Minimum Messages</Text>
          </Tooltip>
          <InputNumber
            disabled={isSubmitting}
            value={minimumMessages}
            onChange={(count) => {
              setMinimumMessages(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Mechanism</Text>
          <SelectMechanism
            disabled={isSubmitting}
            value={mechanism}
            onChange={setMechanism}
          />
        </Column>
        <Column gap={6}>
          <Tooltip
            placement="left"
            title="This will save potentially sensitive data that may persist in database backups."
          >
            <Checkbox checked={savePrompt} onChange={handleSavePromptChecked}>
              <Row gap={6}>
                <WarningOutlined style={{ color: 'var(--color-error)' }} />
                <Text>Save Prompt</Text>
              </Row>
            </Checkbox>
          </Tooltip>
        </Column>
      </Column>
    </Modal>
  );
};
