import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Feature } from '@shared/features';
import { formatDate } from '@shared/formatDate';
import { IImpact } from '@shared/impacts';
import { IQuestionSet, sortQuestions } from '@shared/questionSets';
import {
  QuestionToken,
  isQuestionLabel,
  isRankQuestion,
  validateRanking,
} from '@shared/questions';
import {
  IReflection,
  ReflectionResponseInput,
  ReflectionState,
  UpdateReflectionRequest,
  UpdateReflectionResponse,
  isRankResponse,
  isRatingResponse,
  isTextResponse,
} from '@shared/reflections';
import {
  UpdateCycleReflectionRequest,
  UpdateCycleReflectionResponse,
} from '@shared/review-cycles';
import { TagToken } from '@shared/tags';
import { IUser, UserToken } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import { put } from '@web/common/api';
import { useFeature } from '@web/common/useFeature';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { FormButtons } from '@web/components/FormButtons';
import { MarkdownEditor } from '@web/components/MarkdownEditor';
import { Pane } from '@web/components/Pane';
import {
  Absolute,
  Column,
  GrowingSpacer,
  Relative,
  ResponsiveRow,
  Row,
  Spacer,
} from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import {
  Button,
  Divider,
  Form,
  Tooltip,
  Tour,
  TourStepProps,
  message,
} from 'antd';
import React, { useCallback, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { renderReceiverQuestionText } from '../questions/renderReceiverQuestionText';
import { AutoSaveLabel, AutoSaveState } from './AutoSaveState';
import { JournalEntryContextList } from './JournalEntryContextList';
import { ReflectionQuestionInput } from './ReflectionQuestionInput';
import { ReflectionSharedDate } from './ReflectionSharedDate';
import { SelectTags } from './tags/SelectTags';

export interface ReflectionFormProps {
  onChange: () => void;
  prefill?: Prefill;
  existingReflection: IReflection;
  showTour: boolean;
  onCloseTour: () => void;
  defaultNavigateBackTo?: string;
  questionSet: IQuestionSet;
}
interface Prefill {
  receiver?: IUser;
  entry?: IImpact;
}

interface InternalReflectionFormFields {
  receiverToken: UserToken;
  textContext: string;
  content: string;
  privateNote: string;
  tags: TagToken[];
  responses: Record<QuestionToken, ReflectionResponseInput>;
}

const shareButtonId = 'share-button';
const reflectionSectionTitleId = 'reflection-section-title';
const summaryOfWorkTitleId = 'summary-of-work';

export const ReflectionForm: React.FC<ReflectionFormProps> = ({
  onChange,
  prefill,
  existingReflection: reflection,
  showTour,
  onCloseTour,
  defaultNavigateBackTo,
  questionSet,
}) => {
  const navigateBack = useNavigateBack();
  const { isMobile } = useResponsive();
  const isCycleReflection = reflection.reviewCycleToken;
  const isDraft = reflection?.state === ReflectionState.DRAFT;
  const { booleanValue: reflectionTagsEnabled } = useFeature(
    Feature.REFLECTION_TAGS_ENABLED,
  );
  const [form] = Form.useForm<InternalReflectionFormFields>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(isDraft);
  const [lastAutoSaveState, setLastAutoSaveState] =
    useState<AutoSaveState>(null);
  const [lastAutoSave, setLastAutoSave] = useState<Date | null>(null);

  const [showAdditionalWork, setShowAdditionalWork] = useState(
    !!reflection?.textContext,
  );

  const findResponse = useCallback(
    (token: QuestionToken): ReflectionResponseInput | null => {
      const rawResponse = reflection.responses.find(
        (r) => r.questionToken === token,
      );
      if (rawResponse?.content) {
        return {
          type: 'text',
          questionToken: rawResponse.questionToken,
          question: rawResponse.question,
          content: rawResponse.content,
        };
      } else if (rawResponse?.rating) {
        return {
          type: 'rating',
          questionToken: rawResponse.questionToken,
          question: rawResponse.question,
          rating: rawResponse.rating,
        };
      } else if (rawResponse?.ranking) {
        return {
          type: 'rank',
          questionToken: rawResponse.questionToken,
          question: rawResponse.question,
          ranking: rawResponse.ranking,
        };
      }
      return null;
    },
    [reflection],
  );

  if (prefill && reflection) {
    throw new Error('cannot prefill a reflection being edited.');
  }

  const goBack = () => {
    navigateBack(defaultNavigateBackTo);
  };

  const validateRankResponses = (responses: ReflectionResponseInput[]) => {
    for (const response of responses) {
      if (!isRankResponse(response)) {
        continue;
      }

      const question = response.question;
      // this should never happen
      if (!isRankQuestion(question)) {
        return false;
      }

      const { duplicates, missing } = validateRanking(
        question.rankingOptions,
        response.ranking,
      );
      if (duplicates.size > 0 || missing.size > 0) {
        return false;
      }
    }

    return true;
  };

  const handleSave = async ({
    draft,
    autoSave,
  }: {
    draft: boolean;
    autoSave: boolean;
  }) => {
    setIsSavingDraft(draft);
    if (!draft) {
      setHasSubmitted(true);
    }

    if (autoSave) {
      setLastAutoSaveState('loading');
    } else {
      setIsSaving(true);
    }
    let internalFields: InternalReflectionFormFields;
    try {
      internalFields = await form.validateFields({ validateOnly: autoSave });
      if (!draft) {
        const hasRankError = !validateRankResponses(
          Object.values(internalFields.responses),
        );
        if (hasRankError) {
          setIsSaving(false);
          return;
        }
      }
    } catch (error) {
      if (autoSave) {
        setLastAutoSaveState('error');
      } else {
        setIsSaving(false);
      }
      return;
    }
    try {
      if (isCycleReflection) {
        const request: UpdateCycleReflectionRequest = {
          ...internalFields,
          draft,
          responses: Object.values(internalFields.responses),
        };
        await put<UpdateCycleReflectionRequest, UpdateCycleReflectionResponse>(
          `/review-cycles/reflections/${reflection.token}`,
          request,
        );
      } else {
        const request: UpdateReflectionRequest = {
          ...internalFields,
          draft,
          responses: Object.values(internalFields.responses),
        };
        await put<UpdateReflectionRequest, UpdateReflectionResponse>(
          `/reflections/${reflection.token}`,
          request,
        );
      }
      onChange();
      if (autoSave) {
        setLastAutoSave(new Date());
        setLastAutoSaveState('success');
      }
      if (!autoSave) {
        void message.success('Success');
        goBack();
      }
    } catch (error) {
      void message.error('Error');
      setLastAutoSaveState('error');
    } finally {
      if (!autoSave) {
        setIsSaving(false);
      }
    }
  };

  const [dirty, setDirty] = useState(false);
  useInterval(
    async () => {
      if (!dirty || isSaving) {
        return false;
      }

      setDirty(false);
      await handleSave({ draft: true, autoSave: true });
    },
    isDraft ? 5000 : null,
  );

  const handleCloseAdditionalWork = () => {
    setShowAdditionalWork(false);
    form.setFieldValue('textContext', '');
  };

  const sortedQuestions = sortQuestions(questionSet);
  return (
    <Form
      form={form}
      layout="vertical"
      disabled={isSaving}
      onValuesChange={() => {
        setDirty(true);
      }}
      requiredMark={false}
    >
      {reflection.includeWork && (
        <Pane style={{ marginBottom: '12px' }}>
          <Column gap={12}>
            <Row gap={6}>
              <Header2 id={summaryOfWorkTitleId}>Summary of Work </Header2>
              <Tooltip
                title={`Journal entries created by ${
                  reflection.receiver.name
                } between ${formatDate(reflection.startDate)} and ${formatDate(
                  reflection.endDate,
                )}`}
              >
                <QuestionCircleOutlined
                  style={{ cursor: 'help', color: '#888' }}
                />
              </Tooltip>
            </Row>
            <JournalEntryContextList entries={reflection.accomplishments} />
            {showAdditionalWork && (
              <Form.Item
                label={
                  <Row
                    style={{
                      paddingTop: 12,
                      width: '100%',
                    }}
                  >
                    <Column>
                      <Text>
                        Describe any work or accomplishments not covered in
                        journal entries
                      </Text>
                    </Column>
                    <Spacer size={8} />
                    <CloseOutlined onClick={handleCloseAdditionalWork} />
                  </Row>
                }
                name="textContext"
                initialValue={reflection?.textContext}
                style={{
                  marginBottom: 0,
                  width: '100%',
                  borderTop: '1px solid #eee',
                }}
              >
                <MarkdownEditor readOnly={isSaving} />
              </Form.Item>
            )}
            {!showAdditionalWork && (
              <Button
                onClick={() => {
                  setShowAdditionalWork(true);
                }}
                style={{ alignSelf: 'flex-start' }}
              >
                Add additional work
              </Button>
            )}
          </Column>
        </Pane>
      )}
      <Pane id="new-reflection-form-container">
        <Relative>
          {isCycleReflection ? (
            <Absolute top={isMobile ? -100 : -6} right={0}>
              <ReflectionSharedDate reflection={reflection} />
            </Absolute>
          ) : (
            <Row gap={6}>
              <Header2 id={reflectionSectionTitleId}>Reflection</Header2>
              <Tooltip
                title={`Reflect on ${reflection.receiver.name}’s accomplishments or other work. Highlight their strengths and areas of development. Align on the impact, and quality of their work.`}
              >
                <QuestionCircleOutlined
                  style={{ color: '#888', cursor: 'help' }}
                />
              </Tooltip>
              <GrowingSpacer />
              <ReflectionSharedDate reflection={reflection} />
            </Row>
          )}
          {sortedQuestions.map((question) =>
            isQuestionLabel(question) ? (
              <Column key={question.token} style={{ maxWidth: 800 }}>
                <Text style={{ whiteSpace: 'pre-wrap' }}>{question.text}</Text>
                <Divider />
              </Column>
            ) : (
              <Form.Item
                name={['responses', question.token]}
                key={question.token}
                style={{ maxWidth: 800 }}
                label={
                  <Text style={{ whiteSpace: 'pre-wrap' }}>
                    {renderReceiverQuestionText(
                      question,
                      reflection.receiver?.name,
                    )}{' '}
                    {question.required ? '' : '(optional)'}
                  </Text>
                }
                initialValue={
                  findResponse(question.token) ?? {
                    questionToken: question.token,
                    content: '',
                    question,
                  }
                }
                rules={
                  isRankQuestion(question)
                    ? undefined
                    : [
                        {
                          required: !isSavingDraft && question.required,
                          message: isDraft
                            ? 'Response is required when submitting'
                            : 'Response is required',
                          transform: (response: ReflectionResponseInput) => {
                            if (isRatingResponse(response)) {
                              return `${response.rating}`;
                            } else if (isTextResponse(response)) {
                              return response.content;
                            }
                          },
                        },
                      ]
                }
              >
                <ReflectionQuestionInput
                  question={question}
                  hasSubmitted={hasSubmitted}
                />
              </Form.Item>
            ),
          )}
          {reflection.includePrivateNote && (
            <Form.Item
              id="private-notes"
              label="Private Notes"
              name="privateNote"
              tooltip="Responses in this section will not be shared"
              initialValue={reflection?.privateNote}
            >
              <MarkdownEditor readOnly={isSaving} />
            </Form.Item>
          )}
          {reflectionTagsEnabled && (
            <Form.Item
              label="Tags"
              name="tags"
              initialValue={reflection?.tags?.map((tag) => tag.token)}
              valuePropName="value"
            >
              <SelectTags />
            </Form.Item>
          )}
          <ResponsiveRow gap={12}>
            <FormButtons
              style={{ width: isMobile ? '100%' : undefined, marginTop: 0 }}
            >
              <Button
                id={shareButtonId}
                type="primary"
                disabled={isSaving}
                onClick={() => {
                  void handleSave({ draft: false, autoSave: false });
                }}
              >
                {completeFormButtonText(reflection)}
              </Button>
              {isDraft && (
                <Button
                  disabled={isSaving}
                  onClick={() => {
                    void handleSave({ draft: true, autoSave: false });
                  }}
                >
                  Save Draft
                </Button>
              )}
            </FormButtons>
            <AutoSaveLabel
              saveDate={lastAutoSave}
              lastState={lastAutoSaveState}
            />
          </ResponsiveRow>
        </Relative>
      </Pane>
      <ReflectionFormTour
        open={showTour}
        onClose={onCloseTour}
        reflection={reflection}
      />
    </Form>
  );
};

const completeFormButtonText = (reflection: IReflection) => {
  const isDraft = reflection.state === ReflectionState.DRAFT;
  const isCycleReflection = !!reflection.reviewCycleToken;

  if (isDraft) {
    if (isCycleReflection) {
      return 'Submit';
    }
    return 'Share';
  }

  return 'Update';
};

const findPageTitle = () => document.getElementById('page-title');
const findSummaryOfWork: () => HTMLElement = () =>
  document.querySelector(`#${summaryOfWorkTitleId}`);
const findReflectionSection = () =>
  document.getElementById(reflectionSectionTitleId);
const findPrivateNotesLabel: () => HTMLElement = () =>
  document.querySelector('label[for="privateNote"]');
const findShareButton = () => document.getElementById(shareButtonId);

const ReflectionFormTour: React.FC<{
  open: boolean;
  onClose: () => void;
  reflection: IReflection;
}> = ({ open, onClose, reflection }) => {
  if (!open) {
    return null;
  }

  const receiverName = reflection.receiver.name;
  const tourSteps: TourStepProps[] = [
    {
      title: 'Introducing Reflections',
      description: (
        <Column gap={24}>
          <Text>
            A reflection is the easiest way to build alignment with a member of
            your team. Reflections give you a clear way to discuss their impact
            and performance, while helping you coach and guide them.
          </Text>
        </Column>
      ),
      placement: 'bottom',
      target: findPageTitle,
      nextButtonProps: {
        children: 'Continue',
      },
    },
    reflection.includeWork
      ? {
          title: 'Summary of Work',
          description: (
            <Column gap={24}>
              <Text>
                You can view the work that was done during the time period here.
                If there are projects that aren’t covered in the journal
                entries, you can add them manually.
              </Text>
            </Column>
          ),
          placement: 'right',
          target: findSummaryOfWork,
          nextButtonProps: {
            children: 'Continue',
          },
        }
      : undefined,
    {
      title: 'Reflection',
      description: (
        <Column gap={24}>
          <Text>
            Respond to the questions with your thoughts and assessment. When you
            are ready, these will be shared with {receiverName}. You may prefer
            to discuss your thoughts in a 1-on-1 first.
          </Text>
        </Column>
      ),
      placement: 'top',
      target: findReflectionSection,
      nextButtonProps: {
        children: 'Continue',
      },
    },
    reflection.includePrivateNote
      ? {
          title: 'Private Notes',
          description: (
            <Column gap={24}>
              <Text>
                You can record any private notes here. These can be great to
                guide a conversation or track ideas before they are ready to be
                shared.
              </Text>
            </Column>
          ),
          placement: 'right',
          target: findPrivateNotesLabel,
          nextButtonProps: {
            children: 'Continue',
          },
        }
      : undefined,
    {
      title: 'Share',
      description: (
        <Column gap={24}>
          <Text>
            When you are ready, share this reflection with {receiverName}. They
            will have the ability to read, comment on, and react to each
            response in the reflection.
          </Text>
        </Column>
      ),
      placement: 'right',
      target: findShareButton,
      nextButtonProps: {
        children: 'Continue',
      },
    },
  ];
  return (
    <Tour open={open} onClose={onClose} steps={tourSteps.filter((s) => !!s)} />
  );
};
