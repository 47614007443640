import { SwapOutlined } from '@ant-design/icons';
import { IOrganization } from '@shared/types';
import { useApi } from '@web/common/useApi';
import { GrowingSpacer } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { useOrganizationFilter } from './organizationFilter';

export const OrganizationPicker: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const { organizationTokenFilter } = useOrganizationFilter();
  const { data: organization } = useApi<IOrganization>(
    organizationTokenFilter
      ? `/organizations/${organizationTokenFilter}`
      : null,
  );

  return (
    <Tooltip title="Manage another organization">
      <OrganizationPickerContainer onClick={onClick}>
        <Text>{organization?.name ?? 'None'}</Text>
        <GrowingSpacer />
        <SwapOutlined style={{ fontSize: 18 }} />
      </OrganizationPickerContainer>
    </Tooltip>
  );
};
const OrganizationPickerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 25px;
  cursor: pointer;

  .ant-typography {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .ant-btn svg {
    font-size: 14px;
  }

  &:hover {
    background: #eee;
  }
`;
