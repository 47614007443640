import { possessive } from '@shared/possessive';
import { FeedbackVisibility, UserMapItem } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { useAuth } from '@web/auth/useAuth';
import * as React from 'react';

import { Small } from '../../components/typography';

export const PrivateFeedbackTag: React.FC<{
  visibility: FeedbackVisibility;
  receiver: UserMapItem;
}> = ({ visibility, receiver }) => {
  const { user } = useAuth();
  const isManager = isManagerOf(receiver, user.token);
  const isPrivate =
    visibility === FeedbackVisibility.MANAGER_ONLY ||
    visibility === FeedbackVisibility.RECEIVER_ONLY;

  if (!isPrivate) {
    return null;
  }

  return (
    <div style={{ display: 'inline-block', marginTop: -6 }}>
      <Small>{createPrivacyLabel(visibility, receiver, user, isManager)}</Small>
    </div>
  );
};

const createPrivacyLabel = (
  visibility: FeedbackVisibility,
  receiver: UserMapItem,
  currentUser: UserMapItem,
  isManager: boolean,
) => {
  const isReceiver = currentUser.token === receiver.token;

  /**
   * FeedbackVisibility.SYSTEM is generated as a result of additional comments
   * on campaign feedback. This visibility has implicitely the same visibility
   * as FeedbackVisibility.RECEIVER_MANAGER
   */
  if (
    visibility === FeedbackVisibility.RECEIVER_MANAGER ||
    visibility === FeedbackVisibility.SYSTEM
  ) {
    if (isReceiver) {
      return `Visible to you and your manager`;
    } else if (isManager) {
      return `Visible to you and ${receiver.name}`;
    } else {
      return `Visible to ${receiver.name} and their manager`;
    }
  } else if (visibility === FeedbackVisibility.MANAGER_ONLY) {
    if (isManager) {
      return `Visible to you only`;
    } else {
      return `Visible to ${possessive(receiver.name)} manager only`;
    }
  } else {
    if (isReceiver) {
      return `Visible to you only`;
    } else {
      return `Visible to ${receiver.name} only`;
    }
  }
};
