import { PlusOutlined } from '@ant-design/icons';
import { ITag, TagToken } from '@shared/tags';
import { Button } from 'antd';
import { uniq } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

import { AddTagModal } from './AddTagModal';
import { StyledTag } from './StyledTag';
import { useTags } from './useTags';

export const SelectTags: React.FC<{
  value?: TagToken[];
  onChange?: (newValue: TagToken[]) => void;
}> = ({ value = [], onChange }) => {
  const { tagMap, isLoading } = useTags();
  const [addingTag, setAddingTag] = useState(false);
  const [tags, setTags] = useState<ITag[]>([]);
  React.useEffect(() => {
    if (!isLoading) {
      const initialTags = value.map((tagToken) => tagMap.get(tagToken));
      setTags(initialTags);
    }
  }, [tagMap, isLoading]);

  const handleAddTagClick = () => {
    setAddingTag(true);
  };

  const handleAddTagClose = () => {
    setAddingTag(false);
  };

  const handleAddTag = (tag: ITag) => {
    const newTags = uniq([...tags, tag]);
    setTags(newTags);
    onChange(newTags.map((tag) => tag.token));
    setAddingTag(false);
  };

  const handleRemoveTag = (removedTag: ITag) => {
    const newTags = tags.filter((tag) => tag.token !== removedTag.token);
    onChange(newTags.map((tag) => tag.token));
    setTags(newTags);
  };

  return (
    <div>
      {tags.map((tag) => (
        <StyledTag key={tag.token} tag={tag} onClose={handleRemoveTag} />
      ))}
      <AddButton onClick={handleAddTagClick} size="small">
        <PlusOutlined />
        Add tag
      </AddButton>
      <AddTagModal
        open={addingTag}
        onClose={handleAddTagClose}
        onSelect={handleAddTag}
      />
    </div>
  );
};

const AddButton = styled(Button)`
  &.ant-btn.ant-btn-sm {
    border-radius: 12px;
    font-size: 12px;
  }
`;
