export const REACT_APP_BUILD_HASH =
  process.env.NODE_ENV === 'development'
    ? 'development'
    : process.env.RENDER_GIT_COMMIT;
const NANOSECONDS_PER_MILLISECOND = 1000000;
export const REACT_APP_BUILD_TIME =
  process.env.NODE_ENV === 'development'
    ? new Date().getTime()
    : Math.round(
        parseInt(process.env.REACT_APP_BUILD_TIME) /
          NANOSECONDS_PER_MILLISECOND,
      );
