import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { IUser } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { patch } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Checkbox, Divider } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const UserNotificationSettings: React.FC = () => {
  const { managesPeople } = useAuth();
  const { data } = useApi<{ user: IUser; manager?: IUser }>(`/users/me`);

  if (!data) {
    return null;
  }

  const { user } = data;
  return (
    <Column style={{ gap: 12 }}>
      {managesPeople && (
        <Column>
          <Header3>Your Journal</Header3>
          <Divider style={{ margin: '12px 0' }} />
        </Column>
      )}
      <UserNotificationSetting
        user={user}
        label="Feedback on your journal entries"
        property="sendImpactFeedbackNotifications"
      />
      <UserNotificationSetting
        user={user}
        label="Weekly reminder to add journal entries"
        property="sendCreateImpactReminderNotifications"
      />
      {managesPeople && (
        <>
          <Spacer size={12} />
          <Column>
            <Header3>Team</Header3>
            <Divider style={{ margin: '12px 0' }} />
          </Column>
          <UserNotificationSetting
            user={user}
            label="Newly added journal entries by your direct reports"
            property="sendReportImpactCreatedNotifications"
          />
          <UserNotificationSetting
            user={user}
            label="Feedback on journal entries for your direct reports"
            property="sendReportImpactFeedbackNotifications"
          />
          <UserNotificationSetting
            user={user}
            label="Weekly summary of the journal entries of your direct reports"
            property="sendManagerWeeklyImpactTrackerSummary"
          />
          <UserNotificationSetting
            user={user}
            label="Weekly reminder of your direct reports who have not added a journal entry"
            property="sendReportImpactInactivityNotifications"
          />
        </>
      )}
    </Column>
  );
};

const UserNotificationSetting: React.FC<{
  user: IUser;
  property: keyof IUser;
  label: string;
}> = ({ user, property, label }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [value, setValue] = React.useState(!!user[property]);

  const toggleSetting = async () => {
    setUpdated(false);
    setLoading(true);
    setError(false);
    const oldValue = value;
    const newValue = !oldValue;
    setValue(newValue);
    try {
      await patch(`/users/${user.token}`, { [property]: newValue });
      setUpdated(true);
    } catch (error) {
      setError(true);
      setValue(oldValue);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row
      style={{ gap: 10, cursor: 'pointer', alignItems: 'flex-start' }}
      onClick={() => {
        void toggleSetting();
      }}
    >
      <Checkbox checked={value} />
      <NotificationText>
        {label}
        {loading && <LoadingOutlined />}
        {updated && <CheckOutlined style={{ color: 'var(--color-success)' }} />}
        {error && <CloseOutlined style={{ color: 'var(--color-error)' }} />}
      </NotificationText>
    </Row>
  );
};

const NotificationText = styled(Text)`
  svg {
    margin-left: 10px;
  }
`;
