import { CheckCircleFilled, GithubOutlined } from '@ant-design/icons';
import { IGithubInstallResponse } from '@shared/integrations';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { useApi } from '@web/common/useApi';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Header3 } from '@web/components/typography';
import { GithubMemberTable } from '@web/github/GithubMembersTable';
import { Button, Divider, Skeleton } from 'antd';
import * as React from 'react';

import { AdminPageContent } from './AdminPageContent';

const AdminGithubPage: React.FC = () => {
  const { data: githubInstall } =
    useApi<IGithubInstallResponse>(`/github/installation`);

  return (
    <AdminPageContent>
      <Text>
        Flint integrates with Github in order to make suggestions on Journal
        entries based on recent work
      </Text>
      {githubInstall?.installationId && (
        <Row
          gap={12}
          style={{
            padding: '12px',
            background: complimentaryColor.lighten(0.9),
            borderRadius: 'var(--default-border-radius)',
          }}
        >
          <CheckCircleFilled
            style={{
              fontSize: 24,
              color: 'var(--complimentary-color)',
            }}
          />
          <Column>
            <Header3 style={{ lineHeight: '20px' }}>
              Flint App is installed on Github
            </Header3>
          </Column>
        </Row>
      )}
      {!githubInstall ? (
        <Skeleton />
      ) : (
        <Column>
          <GithubMemberTable />
          <Divider />
          {githubInstall.repositories && (
            <Column gap={12} style={{ marginTop: 12 }}>
              <Header3>Repository access:</Header3>
              <Column gap={6}>
                {githubInstall.repositories?.map((repo) => (
                  <Text key={repo.name}>• {repo.name}</Text>
                ))}
              </Column>
            </Column>
          )}
          <Spacer size={12} />
          <Row gap={12}>
            <a href={githubInstall.installUrl}>
              <Button
                type={githubInstall.installationId ? 'default' : 'primary'}
              >
                <GithubOutlined
                  style={{
                    top: -2,
                    position: 'relative',
                  }}
                />
                {githubInstall.installationId
                  ? 'Update repository access on Github'
                  : 'Install Flint on Github'}
              </Button>
            </a>
          </Row>
        </Column>
      )}
    </AdminPageContent>
  );
};

export default AdminGithubPage;
