import { createTeam, updateTeam } from '@client/TeamClient';
import { Feature } from '@shared/features';
import {
  ExistingTeamResolution,
  ITeam,
  IUser,
  TeamToken,
  UserRole,
  UserToken,
} from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { PageHeader } from '@web/components/PageHeader';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Button, Skeleton, message } from 'antd';
import { sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { SelectUserButton } from '../../admin/SelectUserButton';
import { useModalConfirm } from '../../common/useModalConfirm';
import { ConfirmAddUserModal } from './ConfirmAddUserModal';
import { TeamMember } from './TeamMember';

export const EditTeamForm: React.FC<{
  onSave: () => void;
  onCancel: () => void;
  teamToken: TeamToken;
  modalButtonMode?: boolean;
}> = ({ onSave, onCancel, teamToken, modalButtonMode }) => {
  const { booleanValue: usingHRBPs } = useFeature(Feature.USE_HRBPS);
  const isEdit = !!teamToken;
  const { data: team } = useApi(isEdit ? `/teams/${teamToken}` : null);
  const { confirm, contextHolder } = useModalConfirm();
  const [hasChanges, setHasChanges] = React.useState(false);
  const [managerToken, setManagerToken] = React.useState<UserToken>(null);
  const [hrbpToken, setHrbpToken] = React.useState<UserToken>(null);
  const [userMap, setUserMap] = React.useState<Record<string, IUser>>({});
  const [teamTokens, setTeamTokens] = React.useState([]);
  const [existingMemberResolutions, setExistingMemberResolutions] =
    React.useState<Record<UserToken, ExistingTeamResolution>>({});
  const [userWithExistingTeam, setUserWithExistingTeam] =
    React.useState<IUser>(null);
  React.useEffect(() => {
    if (team) {
      team.members.forEach((member) => {
        member.team = team;
      });
      setManagerToken(team.manager?.token ?? null);
      setHrbpToken(team.hrbp?.token ?? null);
      setTeamTokens(team.members.map((member) => member.token));
      setUserMap(createMapFromTeam(team));
    }
  }, [team]);

  if (isEdit && !team) {
    return (
      <PageContent>
        <PageHeader title={isEdit ? 'Edit team' : 'Add a team'} navigateBack />
        <Skeleton />
      </PageContent>
    );
  }

  const hasManagerChanged = isEdit
    ? managerToken !== (team.manager?.token ?? null)
    : !!managerToken;
  const hasHrbpChanged = isEdit
    ? hrbpToken !== (team.hrbp?.token ?? null)
    : !!hrbpToken;
  const haveMembersChanged = isEdit
    ? hashTokens(teamTokens) !==
      hashTokens(team?.members?.map((member) => member.token))
    : teamTokens.length > 0;
  const hasTeamChanged =
    hasManagerChanged || hasHrbpChanged || haveMembersChanged;

  const handleManagerSelected = (manager: IUser) => {
    setManagerToken(manager.token);
    setHasChanges(true);
    setUserMap({
      ...userMap,
      [manager.token]: manager,
    });
  };
  const handleHrbpSelected = (hrbp: IUser) => {
    setHrbpToken(hrbp.token);
    setHasChanges(true);
    setUserMap({
      ...userMap,
      [hrbp.token]: hrbp,
    });
  };

  const handleClearManager = async () => {
    const wasPreviouslyOnThisTeam = team?.manager.token === managerToken;
    if (wasPreviouslyOnThisTeam) {
      const manager = userMap[managerToken];
      if (
        !(await confirm(
          `This will remove ${manager.name} as manager of this team and unassign all team members from reporting to them.`,
        ))
      ) {
        return;
      }
    }

    setManagerToken(null);
    setHasChanges(true);
  };

  const handleClearHrbp = async () => {
    const wasPreviouslyOnThisTeam = team?.hrbp.token === hrbpToken;
    if (wasPreviouslyOnThisTeam) {
      const hrbp = userMap[hrbpToken];
      if (
        !(await confirm(
          `This will remove ${hrbp.name} as HRBP of this team and they will no longer have access to data for its team members.`,
        ))
      ) {
        return;
      }
    }

    setHrbpToken(null);
    setHasChanges(true);
  };

  const handleTeamUserSelected = async (user: IUser) => {
    if (teamTokens.includes(user.token)) {
      return;
    }
    if (user.teams?.length > 0) {
      setUserWithExistingTeam(user);
    } else {
      addTeamMember(user);
    }
  };

  const handleMoveUser = async () => {
    addTeamMember(userWithExistingTeam);
    setUserWithExistingTeam(null);
    setExistingMemberResolutions({
      ...existingMemberResolutions,
      [userWithExistingTeam.token]: ExistingTeamResolution.MOVE_TEAMS,
    });
  };

  const handleAddManager = async () => {
    addTeamMember(userWithExistingTeam);
    setUserWithExistingTeam(null);
    setExistingMemberResolutions({
      ...existingMemberResolutions,
      [userWithExistingTeam.token]: ExistingTeamResolution.ADD_MANAGER,
    });
  };

  const addTeamMember = (user: IUser) => {
    setHasChanges(true);
    setTeamTokens([...teamTokens, user.token]);
    setUserMap({
      ...userMap,
      [user.token]: user,
    });
  };

  const handleRemoveTeamMember = async (token: UserToken) => {
    if (!teamTokens.includes(token)) {
      return;
    }
    const member = userMap[token];
    const wasPreviouslyOnThisTeam =
      isEdit && member.teams?.some((team) => team.token === teamToken);
    if (
      wasPreviouslyOnThisTeam &&
      !(await confirm(
        `This will remove ${member.name} from this team and unassign this manager.`,
      ))
    ) {
      return;
    }

    setExistingMemberResolutions({
      ...existingMemberResolutions,
      [token]: undefined,
    });
    setHasChanges(true);
    setTeamTokens(teamTokens.filter((teamToken) => teamToken !== token));
  };

  const handleSaveTeam = async () => {
    try {
      if (isEdit) {
        await updateTeam(
          teamToken,
          managerToken,
          hrbpToken,
          teamTokens,
          existingMemberResolutions,
          null,
        );
        void message.success('Team saved');
        onSave();
      } else {
        await createTeam(
          managerToken,
          hrbpToken,
          teamTokens,
          existingMemberResolutions,
          null,
        );
        void message.success('Team saved');
        onSave();
      }
    } catch (error) {
      void message.error('Error saving team');
    }
  };

  const handleCancel = async () => {
    if (
      !hasChanges ||
      (await confirm('Unsaved changes will be lost if you continue.'))
    ) {
      onCancel();
    }
  };

  const manager = managerToken ? userMap[managerToken] : null;
  const hrbp = hrbpToken ? userMap[hrbpToken] : null;
  const allTeamUserTokens: UserToken[] = [
    hrbpToken,
    managerToken,
    ...teamTokens,
  ].filter((token) => !!token);
  const allTeamMembers = sortBy(
    teamTokens.map((userToken) => userMap[userToken]),
    'name',
  );

  return (
    <Column gap={24} style={{ maxWidth: 600 }}>
      <Column gap={12} style={{ alignItems: 'flex-start' }}>
        <Text>Manager</Text>
        {manager ? (
          <TeamMember user={manager} onRemove={handleClearManager} />
        ) : (
          <SelectUserButton
            onSelect={handleManagerSelected}
            modalTitle="Select a manager"
            buttonStyle={{ width: 160 }}
            omitUserTokens={allTeamUserTokens}
            forEnrollment={true}
          >
            Select manager
          </SelectUserButton>
        )}
      </Column>
      {usingHRBPs && (
        <Column gap={12} style={{ alignItems: 'flex-start' }}>
          <Text>HRBP</Text>
          {hrbp ? (
            <TeamMember user={hrbp} onRemove={handleClearHrbp} />
          ) : (
            <SelectUserButton
              onSelect={handleHrbpSelected}
              modalTitle="Select an HRBP"
              buttonStyle={{ width: 160 }}
              omitUserTokens={allTeamUserTokens}
              roleFilter={UserRole.HRBP}
              forEnrollment={true}
            >
              Select an HRBP
            </SelectUserButton>
          )}
        </Column>
      )}
      <Column gap={12}>
        <Text>Team members</Text>
        {teamTokens.length > 0 && (
          <Column gap={6} style={{ alignItems: 'flex-start' }}>
            {allTeamMembers.map((user) => (
              <TeamMember
                key={user.token}
                user={user}
                onRemove={handleRemoveTeamMember}
              />
            ))}
          </Column>
        )}
        <SelectUserButton
          onSelect={handleTeamUserSelected}
          modalTitle="Add to team"
          buttonStyle={{ width: 160 }}
          omitUserTokens={allTeamUserTokens}
          forEnrollment={true}
        >
          Add member
        </SelectUserButton>
      </Column>
      <PaneButtons
        style={{
          marginTop: 12,
          flexDirection: modalButtonMode ? 'row-reverse' : 'row',
        }}
      >
        <Button
          type="primary"
          disabled={!hasTeamChanged}
          onClick={handleSaveTeam}
        >
          Save
        </Button>
        {modalButtonMode && <GrowingSpacer />}
        <Button onClick={handleCancel}>Cancel</Button>
      </PaneButtons>
      {contextHolder}
      {userWithExistingTeam && (
        <ConfirmAddUserModal
          onCancel={() => {
            setUserWithExistingTeam(null);
          }}
          onAddManager={handleAddManager}
          onMoveTeam={handleMoveUser}
          user={userWithExistingTeam}
        />
      )}
    </Column>
  );
};

export const createMapFromTeam = (team?: ITeam) => {
  if (!team) {
    return {};
  }

  const userMap: Record<UserToken, IUser> = {};
  if (team.manager) {
    userMap[team.manager.token] = team.manager;
  }
  if (team.hrbp) {
    userMap[team.hrbp.token] = team.hrbp;
  }
  for (const member of team.members) {
    userMap[member.token] = member;
  }
  return userMap;
};

export const PaneButtons = styled(Row)`
  gap: 6px;

  .ant-btn {
    width: 120px;
  }
`;

export const hashTokens = (tokens?: string[]) => {
  if (!tokens) {
    return '';
  }
  const sorted = tokens.sort();
  return sorted.join(':');
};
