import { useGoalLists, useListGoals } from '@client/GoalsClient';
import { Feature } from '@shared/features';
import {
  IGoal,
  IGoalList,
  isAssignedGoal,
  isFollowingGoal,
  isPersonalGoal,
} from '@shared/goals';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Row } from '@web/components/layout';
import { Button, Divider, Skeleton } from 'antd';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { EmptyGoals } from './EmptyGoals';
import { ViewGoal } from './ViewGoal';
import { ViewGoals } from './ViewGoals';
import { AddGoalListModal } from './lists/AddGoalListModal';
import { ViewGoalLists } from './lists/ViewGoalLists';

export const GoalsPage: React.FC<{ archived?: boolean }> = ({
  archived = false,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const [showAddGoalList, setShowAddGoalList] = React.useState(false);
  const { booleanValue: publicGoalsEnabled } = useFeature(Feature.PUBLIC_GOALS);
  const { user } = useAuth();
  const { data: goalLists, mutate: reloadGoalLists } = useGoalLists(archived);
  const { data: goalsResponse } = useListGoals(archived);
  const personalGoals: IGoal[] =
    goalsResponse?.goals?.filter(isPersonalGoal) ?? [];
  const assignedGoals: IGoal[] =
    goalsResponse?.goals?.filter(isAssignedGoal(user.token)) ?? [];
  const followingGoals: IGoal[] =
    goalsResponse?.goals?.filter(isFollowingGoal(user.token)) ?? [];
  const isLoading = !goalsResponse?.goals || !goalLists;
  const showEmpty =
    !isLoading && goalsResponse.goals.length === 0 && goalLists.length === 0;
  if (!publicGoalsEnabled) {
    return (
      <PageContent>
        <PageHeader
          title={archived ? 'Archived Goals' : 'Goals'}
          mobileTitle={archived ? 'Archived Goals' : 'Goals'}
          primaryAction={{ label: 'Add Goal', linkTo: '/goals/new' }}
          secondaryActions={[
            archived
              ? {
                  label: 'View Active Goals',
                  linkTo: `/goals`,
                }
              : {
                  label: 'View Archived Goals',
                  linkTo: `/goals/archived`,
                },
          ]}
        />
        <Pane>
          <Column>
            {isLoading ? (
              <Skeleton />
            ) : (
              personalGoals.map((goal, index) => (
                <React.Fragment key={`goal-${goal.token}`}>
                  {index !== 0 && <Divider style={{ margin: '12px 0' }} />}
                  <ViewGoal goal={goal} />
                </React.Fragment>
              ))
            )}
            {!isLoading && personalGoals?.length === 0 && <EmptyGoals />}
          </Column>
        </Pane>
      </PageContent>
    );
  }

  const handleGoalListAdded = (goalList: IGoalList) => {
    void reloadGoalLists();
    navigate(`/goals/lists/${goalList.token}`);
  };

  return (
    <PageContent>
      <Row>
        <PageHeader
          title={archived ? 'Archived Goals' : 'Goals'}
          mobileTitle={archived ? 'Archived Goals' : 'Goals'}
          primaryAction={
            isMobile ? { label: 'New Goal', linkTo: '/goals/new' } : undefined
          }
          secondaryActions={
            isMobile
              ? [
                  {
                    label: 'New Goal List',
                    onClick: () => {
                      setShowAddGoalList(true);
                    },
                  },
                  archived
                    ? {
                        label: 'View Active Goals',
                        linkTo: `/goals`,
                      }
                    : {
                        label: 'View Archived Goals',
                        linkTo: `/goals/archived`,
                      },
                ]
              : undefined
          }
        />
        {!isMobile && (
          <Row gap={6} style={{ marginBottom: 20 }}>
            <Link to="/goals/new">
              <Button>New Goal</Button>
            </Link>
            <Button
              onClick={() => {
                setShowAddGoalList(true);
              }}
            >
              New Goal List
            </Button>
            {archived ? (
              <Link to="/goals">
                <Button>View Active Goals</Button>
              </Link>
            ) : (
              <Link to="/goals/archived">
                <Button>View Archived Goals</Button>
              </Link>
            )}
          </Row>
        )}
      </Row>
      <Pane key={archived ? 'archived-goals' : 'active-goals'}>
        {isLoading ? (
          <Skeleton />
        ) : showEmpty ? (
          <EmptyGoals />
        ) : (
          <Column gap={36}>
            <ViewGoals
              goals={personalGoals}
              title={`Personal (${personalGoals.length})`}
            />
            <ViewGoals
              goals={assignedGoals}
              title={`Assigned (${assignedGoals.length})`}
            />
            <ViewGoals
              goals={followingGoals}
              title={`Following (${followingGoals.length})`}
            />
            <ViewGoalLists
              goalLists={goalLists}
              title={`Lists (${goalLists.length})`}
            />
          </Column>
        )}
      </Pane>
      {showAddGoalList && (
        <AddGoalListModal
          onAdd={handleGoalListAdded}
          onCancel={() => {
            setShowAddGoalList(false);
          }}
        />
      )}
    </PageContent>
  );
};
