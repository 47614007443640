import { EditOutlined } from '@ant-design/icons';
import { IGoal } from '@shared/goals';
import { RichText } from '@web/components/RichText';
import { Column } from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { Button } from 'antd';
import * as React from 'react';

import { PersonalGoalBadge } from './PersonalGoalBadge';

export const GoalInfo: React.FC<{
  goal: IGoal;
  onEdit?: () => void;
  isReadonly: boolean;
}> = ({ goal, onEdit, isReadonly }) => {
  return (
    <Column>
      {!goal.isPublic && <PersonalGoalBadge />}
      <Header1>
        {goal.title}{' '}
        {!isReadonly && onEdit && (
          <Button
            type="text"
            size="small"
            onClick={onEdit}
            style={{ position: 'relative', top: -4 }}
          >
            <EditOutlined />
          </Button>
        )}
      </Header1>
      {isRichTextEmpty(goal.content) ? (
        <Text style={{ fontStyle: 'italic' }}>No description</Text>
      ) : (
        <RichText value={goal.content} />
      )}
    </Column>
  );
};

const isRichTextEmpty = (text: string) => !text || text === '<p></p>';
