import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useResponsive } from '@web/app/responsive';
import { Row } from '@web/components/layout';
import { Header1 } from '@web/components/typography';
import { Button, Input, message } from 'antd';
import * as React from 'react';

export const InlineInputTitle: React.FC<{
  value: string;
  onSave: (value: string) => Promise<void>;
}> = ({ value: propValue, onSave }) => {
  const { isMobile } = useResponsive();
  const [currentValue, setCurrentValue] = React.useState(propValue ?? '');
  const [value, setValue] = React.useState(currentValue ?? '');
  const [editing, setEditing] = React.useState(false);

  const handleSave = async () => {
    try {
      await onSave(value);
      setEditing(false);
      setCurrentValue(value);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleCancel = () => {
    setValue(currentValue);
    setEditing(false);
  };

  if (editing) {
    return (
      <Row gap={6}>
        <Input
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          style={{ width: isMobile ? 320 : 500 }}
        />
        <Button type="primary" onClick={handleSave}>
          <CheckOutlined />
        </Button>
        <Button onClick={handleCancel}>
          <CloseOutlined />
        </Button>
      </Row>
    );
  }

  return (
    <Row gap={6}>
      <Header1>{value}</Header1>
      <Button
        type="text"
        onClick={() => {
          setEditing(true);
        }}
      >
        <EditOutlined />
      </Button>
    </Row>
  );
};
