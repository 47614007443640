import { IQuestionResponse } from '@shared/question_response';
import { IRatingQuestion } from '@shared/questions';
import { FeedbackVisibility, UserMapItem } from '@shared/types';
import { SelectVisibility } from '@web/components/SelectVisibility';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text, TypographyColors } from '@web/components/typography';
import { Radio } from 'antd';
import { sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { QuestionText } from './QuestionText';

export const InputRatingQuestion: React.FC<{
  receiver?: UserMapItem;
  question: IRatingQuestion;
  response: IQuestionResponse;
  onResponseChanged: (newResponse: IQuestionResponse) => void;
  showVisibilityOptions?: boolean;
  disabled?: boolean;
  error?: string;
}> = ({
  receiver,
  question,
  response,
  onResponseChanged,
  showVisibilityOptions,
  disabled,
  error,
}) => {
  const handleVisibilityChanged = (visibility: FeedbackVisibility) => {
    onResponseChanged({
      ...response,
      visibility,
    });
  };
  const handleChange = (rating: number) => {
    const newResponse: IQuestionResponse = {
      ...response,
      rating,
    };
    onResponseChanged(newResponse);
  };
  const scale = sortBy(question.scale ?? [], 'value');
  return (
    <Column>
      <QuestionText text={question.text} receiver={receiver} />
      <Spacer size={10} />
      <Column gap={3}>
        <Column gap={12}>
          {scale.map((rating) => (
            <Row
              gap={6}
              key={`rating_option_${rating.value}`}
              style={{ cursor: 'pointer', paddingLeft: 12 }}
              onClick={() => {
                handleChange(rating.value);
              }}
            >
              <StyledRadio
                checked={response.rating === rating.value}
                disabled={disabled}
              />
              <Text>
                {rating.value} - {rating.label}
              </Text>
            </Row>
          ))}
        </Column>
        {showVisibilityOptions && (
          <SelectVisibility
            userName={receiver?.name}
            value={response.visibility}
            onChange={handleVisibilityChanged}
            style={{ alignSelf: 'flex-start' }}
          />
        )}
        {error && (
          <Text style={{ color: TypographyColors.error }}>{error}</Text>
        )}
      </Column>
    </Column>
  );
};

const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    border-color: #888;
  }
`;
