import { Header2 } from '@web/components/typography';
import { Collapse } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export interface Collapsable {
  setExpanded: (value: boolean) => void;
}

interface Props {
  title: string;
  startExpanded?: boolean;
  children: React.ReactNode;
  ref?: React.Ref<Collapsable>;
}
// eslint-disable-next-line react/display-name
export const CollapsableSection = React.forwardRef<Collapsable, Props>(
  ({ title, startExpanded = false, children }, ref) => {
    const [expanded, setExpanded] = React.useState(startExpanded);
    React.useImperativeHandle(ref, () => ({
      setExpanded(value: boolean) {
        setExpanded(value);
      },
    }));

    return (
      <CollapsablePanelContainer>
        <Collapse
          activeKey={expanded ? ['1'] : undefined}
          onChange={(key) => {
            setExpanded(key.length > 0);
          }}
        >
          <Collapse.Panel key={1} header={<Header2>{title}</Header2>}>
            {children}
          </Collapse.Panel>
        </Collapse>
      </CollapsablePanelContainer>
    );
  },
);

const CollapsablePanelContainer = styled.section`
  .ant-collapse {
    border: 0;
    background: none;

    .ant-collapse-item {
      border: 0;

      .ant-collapse-header {
        padding: 0;
      }

      .ant-collapse-content {
        border-top: 0;
      }

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`;
