import { createGoal } from '@client/GoalsClient';
import { Feature } from '@shared/features';
import {
  GoalListToken,
  GoalState,
  GoalToken,
  IGoal,
  IGoalList,
} from '@shared/goals';
import { IUser, UserMapItem, UserRole, UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { RichTextArea } from '@web/components/RichTextArea';
import { SelectDate } from '@web/components/SelectDate';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { Button, Input, Switch, message } from 'antd';
import { size } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PersonalGoalBadge } from './PersonalGoalBadge';
import { SelectGoal } from './SelectGoal';
import { SelectState } from './SelectState';
import { SelectGoalLists } from './lists/SelectGoalLists';

interface Props {
  onSave: (goal: IGoal) => void;
  onCancel: () => void;
  userDetails?: UserMapItem;
  initialList?: IGoalList;
  initialParent?: IGoal;
  initialAssigned?: UserMapItem[];
  initialFollowers?: UserMapItem[];
}
export const CreateGoal: React.FC<Props> = ({
  onSave,
  onCancel,
  userDetails,
  initialList,
  initialParent,
  initialAssigned,
  initialFollowers,
}) => {
  const { confirm, contextHolder } = useModalConfirm();
  const { booleanValue: publicGoalsEnabled } = useFeature(Feature.PUBLIC_GOALS);
  const { user } = useAuth();
  const { userToken } = useParams<{
    userToken?: UserToken;
  }>();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isPublic, setIsPublic] = React.useState(true);
  const [parent, setParent] = React.useState<GoalToken>(
    initialParent?.token ?? null,
  );
  const [lists, setLists] = React.useState<GoalListToken[]>(
    initialList ? [initialList.token] : [],
  );
  const [assignedUsers, setAssignedUsers] = React.useState<UserToken[]>(
    initialAssigned?.map((user) => user.token) ?? [],
  );
  const [followers, setFollowers] = React.useState<UserToken[]>(
    initialFollowers?.map((user) => user.token) ?? [],
  );
  const [dueDate, setDueDate] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');
  const [state, setState] = React.useState<GoalState | null>(
    GoalState.ON_TRACK,
  );

  const handleSave = async () => {
    if (!title || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      const assignedUserTokens: UserToken[] = isPublic
        ? assignedUsers
        : userToken
        ? [userToken]
        : [user.token];
      const goal = await createGoal({
        title,
        content,
        userToken,
        dueDate: dueDate ? (`${dueDate}T23:59:59.999Z` as any) : null,
        isPublic,
        assignedUsers: assignedUserTokens,
        followers: isPublic ? followers : undefined,
        parent,
        lists,
        state,
      });
      void message.success('Success');
      onSave(goal);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };
  const handleBeforeAssignedChange = async (newAssignedUsers: UserToken[]) => {
    if (
      assignedUsers.includes(user.token) &&
      !newAssignedUsers.includes(user.token) &&
      !followers.includes(user.token)
    ) {
      return await confirm(
        'Unassigning yourself will prevent this goal from showing up on your Goals listing unless you add yourself as a follower. Do you wish to continue?',
      );
    }
    return true;
  };
  const handleBeforeFollowersChange = async (newFollowers: UserToken[]) => {
    if (
      followers.includes(user.token) &&
      !newFollowers.includes(user.token) &&
      !assignedUsers.includes(user.token)
    ) {
      return await confirm(
        'Removing yourself as a followering will remove this goal from your Goals listing. Do you wish to continue?',
      );
    }
    return true;
  };

  const canSave = !!title;

  return (
    <Column gap={24}>
      <FormElement>
        <Text>Title</Text>
        <Input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          autoFocus
        />
      </FormElement>
      <FormElement>
        <Text>Description</Text>
        <RichTextArea
          initialValue={content}
          onChange={setContent}
          showToolbar
        />
      </FormElement>
      <FormElement>
        <Text>State</Text>
        <SelectState
          state={state}
          onChange={(newState: GoalState) => {
            setState(newState);
          }}
        />
      </FormElement>
      <FormElement>
        <Text>Due Date</Text>
        <SelectDate
          value={dueDate}
          onChange={(newValue) => {
            setDueDate(newValue);
          }}
        />
      </FormElement>
      {publicGoalsEnabled && (
        <>
          <FormRow>
            <Switch
              checked={isPublic}
              onChange={(newValue) => {
                setIsPublic(newValue);
              }}
              disabled={!!initialList}
            />
            <Text>This goal is publicly visible</Text>
          </FormRow>
          {isPublic ? (
            <>
              <FormElement>
                <Text>Lists</Text>
                <SelectGoalLists
                  initialLists={initialList ? [initialList] : undefined}
                  onChange={setLists}
                />
              </FormElement>
              <FormElement>
                <Text>Parent goal</Text>
                <SelectGoal initialValue={parent} onChange={setParent} />
              </FormElement>
              <FormElement>
                <Text>Assigned to</Text>
                <SelectUsers
                  initialUsers={initialAssigned}
                  onBeforeChange={handleBeforeAssignedChange}
                  onChange={(newAssignedUsers) => {
                    setAssignedUsers(newAssignedUsers);
                  }}
                />
              </FormElement>
              <FormElement>
                <Text>Followers</Text>
                <SelectUsers
                  initialUsers={initialFollowers}
                  onBeforeChange={handleBeforeFollowersChange}
                  onChange={(newFollowers) => {
                    setFollowers(newFollowers);
                  }}
                />
              </FormElement>
            </>
          ) : (
            <FormElement>
              <PersonalGoalHelp userDetails={userDetails} />
            </FormElement>
          )}
        </>
      )}
      <Row gap={6}>
        <Button type="primary" onClick={handleSave} disabled={!canSave}>
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Row>
      {contextHolder}
    </Column>
  );
};

const FormElement = styled(Column)`
  gap: 12px;
  max-width: 600px;
`;
const FormRow = styled(Row)`
  gap: 12px;
  max-width: 600px;
`;

const PersonalGoalHelp: React.FC<{ userDetails?: UserMapItem }> = ({
  userDetails,
}) => {
  const { user } = useAuth();

  return (
    <Column gap={6}>
      <PersonalGoalBadge />
      <StyledText>{visibilityText(userDetails, user)}</StyledText>
      <StyledText>
        Personal goals cannot have a parent goal or supporting goals, and can
        {"'"}t be added to lists.
      </StyledText>
    </Column>
  );
};

const visibilityText = (
  userDetails: UserMapItem | undefined,
  currentUser: IUser,
) => {
  if (!userDetails) {
    return 'This goal is visible to you and your manager.';
  }

  const isHrAdmin = currentUser.role === UserRole.HR_ADMIN;
  const isManager = isManagerOf(userDetails, currentUser.token);
  const managerCount = size(userDetails.managerTokens ?? []);
  if (isManager) {
    const suffix = managerCount === 1 ? 'you' : 'their managers';
    return `This goal is visible to ${userDetails.name} and ${suffix}`;
  } else if (isHrAdmin) {
    return `This goal is visible to ${userDetails.name} and their ${pluralize(
      'manager',
      managerCount,
    )}`;
  }
};

const StyledText = styled(Text)`
  line-height: 20px;
`;
