import { DeleteOutlined } from '@ant-design/icons';
import {
  AddEnrolledManagerRequest,
  AddEnrolledManagerResponse,
  CreateNextReflectionEventResponse,
  GetReflectionConfigurationResponse,
  IReflectionScheduleEvent,
  ReflectionScheduleEventToken,
  SetReflectionsEnabledRequest,
  SetReflectionsEnabledResponse,
} from '@shared/reflections';
import { IUser, UserToken } from '@shared/types';
import { ServerResponseError, del, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { StopPropagation } from '@web/components/StopPropagation';
import { Column, Row } from '@web/components/layout';
import { TeamMember } from '@web/components/teams/TeamMember';
import { Subheading, Text } from '@web/components/text';
import { Button, Modal, Skeleton, Table, Tag, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format, isAfter } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AdminPageContent } from './AdminPageContent';
import { EditReflectionEventButton } from './EditReflectionEventButton';
import { SelectManagerButton } from './SelectManagerButton';

export const AdminReflectionsPage: React.FC = () => {
  const {
    data: response,
    error,
    mutate,
  } = useApi<GetReflectionConfigurationResponse, ServerResponseError>(
    `/reflection-configuration`,
  );

  const enrolledManagerTokens = React.useMemo(() => {
    if (!response) {
      return [];
    }
    return response.enrolled.map((u) => u.token);
  }, [response]);

  if (!response) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  } else if (error) {
    return (
      <ErrorPageContent
        statusCode={error.statusCode}
        extra={
          <Link to="/admin">
            <Button>Back</Button>
          </Link>
        }
      />
    );
  }

  const setEnabled = async (enabled: boolean) => {
    try {
      await put<SetReflectionsEnabledRequest, SetReflectionsEnabledResponse>(
        `/reflection-configuration/`,
        {
          enabled,
        },
      );
      await mutate();
      void message.success(`Reflections ${enabled ? 'enabled' : 'disabled'}`);
    } catch (error) {
      void message.error(
        `Failed to ${enabled ? 'enable' : 'disable'} reflections`,
      );
    }
  };

  const addEnrolledManager = async (userToken: UserToken) => {
    try {
      await post<AddEnrolledManagerRequest, AddEnrolledManagerResponse>(
        `/reflection-configuration/enrollment`,
        {
          userToken,
        },
      );
      await mutate();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed');
    }
  };

  const removeEnrolledManager = async (userToken: UserToken) => {
    try {
      await del(`/reflection-configuration/enrollment/${userToken}`);
      await mutate();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed');
    }
  };

  return (
    <AdminPageContent>
      <Column gap={32}>
        <Column gap={6}>
          <Subheading>Configuration</Subheading>
          <Column gap={12}>
            <Text>
              Reflections are monthly performance check-ins that build alignment
              between each person and their manager.
            </Text>
            <GetStartedButton
              enabled={response.enabled}
              onConfirm={async () => {
                await setEnabled(!response.enabled);
              }}
            />
          </Column>
        </Column>
        <Column gap={6}>
          <Subheading>Enrolled Managers</Subheading>
          <Column gap={12}>
            {response.enrolled.length > 0 ? (
              <Column gap={6} style={{ alignItems: 'flex-start' }}>
                {response.enrolled.map((user: IUser) => (
                  <TeamMember
                    key={user.token}
                    user={user}
                    onRemove={async (token) => {
                      await removeEnrolledManager(token);
                    }}
                  />
                ))}
              </Column>
            ) : (
              <Text italic>No teams enrolled</Text>
            )}
            <Row>
              <SelectManagerButton
                onSelect={async (user) => {
                  await addEnrolledManager(user.token);
                }}
                modalTitle="Enroll manager"
                omitUserTokens={enrolledManagerTokens}
              >
                Enroll manager
              </SelectManagerButton>
            </Row>
          </Column>
        </Column>
        <Column gap={6}>
          <Subheading>Schedule</Subheading>
          <ReflectionScheduleEventTable
            events={response.schedule}
            onChange={async () => {
              await mutate();
            }}
          />
        </Column>
      </Column>
    </AdminPageContent>
  );
};
const ReflectionScheduleEventTable: React.FC<{
  events: IReflectionScheduleEvent[];
  onChange: () => void;
}> = ({ onChange, events }) => {
  const deleteEvent = async (token: ReflectionScheduleEventToken) => {
    try {
      await del(`/reflection-configuration/event/${token}`);
      await onChange();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed');
    }
  };

  const columns: ColumnsType<IReflectionScheduleEvent> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, event: IReflectionScheduleEvent) => {
        const now = new Date();
        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        if (isAfter(now, endDate)) {
          return <StatusTag>Due</StatusTag>;
        }
        if (isAfter(now, startDate)) {
          return <StatusTag>Current</StatusTag>;
        }
        return <StatusTag>Upcoming</StatusTag>;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date) => formatDate(date),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date) => formatDate(date),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (date) => formatDate(date),
    },
    {
      key: 'actions',
      align: 'right',
      render: (_, event: IReflectionScheduleEvent) => (
        <Row gap={6} style={{ justifyContent: 'flex-end' }}>
          <EditReflectionEventButton event={event} onChange={onChange} />
          {event.draftCreatedDate || event.taskCreatedDate ? (
            <Tooltip title="Active reflections cannot be deleted">
              <DeleteOutlined />
            </Tooltip>
          ) : (
            <StopPropagation>
              <DeleteOutlined
                onClick={async () => {
                  await deleteEvent(event.token);
                }}
              />
            </StopPropagation>
          )}
        </Row>
      ),
    },
  ];
  const createNextEvent = async () => {
    try {
      await post<CreateNextReflectionEventResponse>(
        `/reflection-configuration/event/create`,
        {},
      );
      await onChange();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed');
    }
  };

  return (
    <Column gap={12}>
      <Table
        rowKey="token"
        dataSource={events}
        columns={columns}
        pagination={false}
      />
      <Row>
        <Button onClick={createNextEvent}>Add</Button>
      </Row>
    </Column>
  );
};

const formatDate = (dateOrString: Date | string): string => {
  return format(new Date(dateOrString), 'MMM dd, yyyy');
};

const GetStartedButton: React.FC<{
  enabled: boolean;
  onConfirm: () => Promise<void>;
}> = ({ enabled, onConfirm }) => {
  const [modal, contextHolder] = Modal.useModal();
  const handleClick = async () => {
    const title = enabled ? 'Disable Reflections' : 'Getting Started';
    const content = enabled
      ? 'When disabled, managers will not receive new reflection requests.'
      : "When enabled, managers will receive periodic requests to reflect on their team members' work.";
    const okText = enabled ? 'Disable' : 'Enable';

    const confirm = await modal.confirm({
      title,
      content,
      okText,
    });
    if (confirm) {
      await onConfirm();
    }
  };
  return (
    <Row>
      <Button type={enabled ? 'default' : 'primary'} onClick={handleClick}>
        {enabled ? 'Disable reflections' : 'Get started'}
      </Button>
      {contextHolder}
    </Row>
  );
};

const StatusTag = styled(Tag)`
  & {
    text-align: center;
    width: 80px;
  }
`;
