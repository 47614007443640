import { mapByToken } from './mapByToken';
import { IQuestion, QuestionToken } from './questions';
import { OrganizationToken } from './types';

export type QuestionSetToken =
  | `frt_${string}`
  | `ft_${string}`
  | `qs_${string}`
  | `rqs_${string}`;

export interface IQuestionSet {
  token: QuestionSetToken;
  name?: string;
  /**
   * Most often you should avoid accessing questions directly, and instead
   * use `sortQuestions(questionSet)` to get the sorted list of questions
   */
  questions: IQuestion[];
  sortedQuestions?: QuestionToken[];
  organizationToken?: OrganizationToken;
  readonly?: boolean;
  createdDate?: Date | string;
  updatedDate?: Date | string;
  deletedDate?: Date | string;
}

export const sortQuestions = (questionSet: IQuestionSet): IQuestion[] => {
  const questionsMap = mapByToken(questionSet.questions);
  const sortedQuestionTokens =
    questionSet.sortedQuestions ?? questionSet.questions.map((q) => q.token);
  return (sortedQuestionTokens?.map((questionToken) =>
    questionsMap.get(questionToken),
  ) ?? []) as any;
};

export const getQuestionPositions = (questionSet: IQuestionSet) => {
  const questionPositions: Record<QuestionToken, number> = {};
  const sortedQuestionTokens =
    questionSet.sortedQuestions ?? questionSet.questions.map((q) => q.token);
  for (let position = 0; position < sortedQuestionTokens.length; position++) {
    questionPositions[sortedQuestionTokens[position]] = position;
  }

  return questionPositions;
};
