import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app/App';
import { REACT_APP_BUILD_HASH, REACT_APP_BUILD_TIME } from './app/build';
import { initEventTracking } from './app/events';
import { initQuill } from './app/quill';

console.log(
  `Start Flint web application. build=${REACT_APP_BUILD_HASH}@${REACT_APP_BUILD_TIME}`,
);
initEventTracking();
initQuill();

const root = createRoot(document.getElementById('root'));
root.render(<App />);
