import { QuestionType } from '@shared/QuestionType';
import { IQuestionResponse } from '@shared/question_response';
import { IQuestion, QuestionToken } from '@shared/questions';
import { FeedbackVisibility } from '@shared/types';
import { useMap } from 'usehooks-ts';

export const useQuestionResponseMap = (
  questions: IQuestion[],
  responses?: IQuestionResponse[],
) => {
  const [responseMap, responseMapActions] = useMap<
    QuestionToken,
    IQuestionResponse
  >(createInitialResponseMap(questions, responses));

  const handleQuestionResponse = (
    question: IQuestion,
    response: IQuestionResponse,
  ) => {
    const currentResponse = responseMap.get(question.token);
    responseMapActions.set(question.token, {
      ...currentResponse,
      ...response,
    });
  };

  const validResponses = questions
    .filter((question) => {
      const response = responseMap.get(question.token);
      return (
        (question.type === QuestionType.RATING && !!response?.rating) ||
        (question.type === QuestionType.OPEN_ENDED && !!response?.text)
      );
    })
    .map((question) => responseMap.get(question.token));

  return {
    responseMap,
    responseMapActions,
    handleQuestionResponse,
    validResponses,
  };
};

const createInitialResponseMap = (
  questions: IQuestion[],
  responses?: IQuestionResponse[],
) => {
  const responseMap = new Map<QuestionToken, IQuestionResponse>();
  for (const question of questions) {
    const response: IQuestionResponse =
      responses?.find(
        (response) => response.questionToken === question.token,
      ) ??
      ({
        visibility: FeedbackVisibility.RECEIVER_MANAGER,
        questionToken: question.token,
      } as any);
    responseMap.set(question.token, response);
  }
  return responseMap;
};
