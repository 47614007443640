import { useGetProfile } from '@client/UsersClient';
import { Feature } from '@shared/features';
import { IUser } from '@shared/types';
import { isManagerOf, primaryManager } from '@shared/users';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header1, Header2, Text } from '@web/components/typography';
import { Button, Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const UserProfile: React.FC<{
  user: IUser;
  hideOneOnOneLink?: boolean;
}> = ({ user, hideOneOnOneLink = false }) => {
  const { user: currentUser } = useAuth();
  const { data: profile } = useGetProfile(user.token);
  const { booleanValue: oneOnOnesEnabled } = useFeature(Feature.ENABLE_1ON1s);
  const { isMobile } = useResponsive();
  const avatarSize = isMobile ? 64 : 92;
  const NameHeader = isMobile ? Header2 : Header1;

  if (!profile) {
    return <Skeleton />;
  }

  let oneOnOneLink: string;
  if (oneOnOnesEnabled && !isMobile && isManagerOf(user, currentUser.token)) {
    const tokens = [user.token, currentUser.token].sort();
    oneOnOneLink = `/1on1s/${tokens[0]}/${tokens[1]}`;
  }

  const manager = primaryManager(profile.managers);
  return (
    <Row style={{ maxWidth: '100%', overflow: 'auto' }}>
      <UserAvatar user={user} size={avatarSize} />
      <Spacer />
      <Column style={{ overflow: 'hidden' }}>
        <NameHeader title={user.name} style={textOverflowStyles}>
          {user.name}
        </NameHeader>
        <Text style={textOverflowStyles} title={user.email}>
          {user.email}
        </Text>
        {manager && (
          <Row gap={6}>
            <Text style={{ fontWeight: 500 }}>Manager:</Text>
            <Text style={textOverflowStyles}>{manager.name} </Text>
            {/* {profile.managers.length > 1 && (
              <Tooltip
                title={`${profile.managers
                  .slice(1)
                  .map((manager) => manager.name)
                  .join(', ')}`}
              >
                <Text>+{profile.managers.length - 1}</Text>
              </Tooltip>
            )} */}
          </Row>
        )}
      </Column>
      <GrowingSpacer />
      {!hideOneOnOneLink && oneOnOneLink && (
        <Link to={oneOnOneLink}>
          <Button>View 1-on-1</Button>
        </Link>
      )}
    </Row>
  );
};

const textOverflowStyles: React.CSSProperties = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};
