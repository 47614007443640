import { useSurveyParticipant } from '@client/SurveyCyclesClient';
import { formatDate } from '@shared/formatDate';
import { SurveyParticipantToken, sortedSurveyQuestions } from '@shared/surveys';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Row } from '@web/components/layout';
import { Header1, SubduedText, Text } from '@web/components/typography';
import { QuestionResponses } from '@web/questions/QuestionResponses';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

export const ViewCheckinPage: React.FC = () => {
  const { participantToken } = useParams<{
    participantToken: SurveyParticipantToken;
  }>();
  const { data } = useSurveyParticipant(participantToken as any);

  if (!data) {
    return (
      <AdminPageContent>
        <Pane>
          <Skeleton />
        </Pane>
      </AdminPageContent>
    );
  }

  if (!data.participant) {
    return (
      <PageContent>
        <Pane>
          <Text>Participant not found</Text>
        </Pane>
      </PageContent>
    );
  }

  const sortedQuestions = sortedSurveyQuestions(data.participant.cycle);
  return (
    <PageContent>
      <PageHeader
        navigateBack
        title={
          <Row gap={12}>
            <Header1>{data.participant.cycle.name}</Header1>
            <SubduedText>
              {formatDate(data.participant.submittedDate as any)}
            </SubduedText>
          </Row>
        }
      />
      <Pane>
        <Column gap={12}>
          <UserMessage user={data.participant.user} />
          <QuestionResponses
            questions={sortedQuestions}
            responses={data.participant?.responses}
            hideNullResponses
          />
        </Column>
      </Pane>
    </PageContent>
  );
};
