import { ITasksResponse } from '@shared/types';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { RequestListItem } from '@web/requests/RequestListItem';
import * as React from 'react';

export const ReviewCycleTasks: React.FC<{
  tasksResponse: ITasksResponse;
  reloadTasks: () => void;
}> = ({ tasksResponse, reloadTasks }) => {
  return (
    <Column gap={12}>
      {tasksResponse?.tasks.length > 0 ? (
        tasksResponse?.tasks.map((task) => (
          <RequestListItem
            key={task.token}
            task={task}
            userMap={tasksResponse.userMap}
            onChange={reloadTasks}
          />
        ))
      ) : (
        <Text>No open requests</Text>
      )}
    </Column>
  );
};
