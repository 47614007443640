import {
  ISurveyCycle,
  SurveyCycleToken,
  SurveyParticipantToken,
} from '@shared/surveys';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { useApi } from '@web/common/useApi';
import { BackButton } from '@web/components/BackButton';
import { UserMessage } from '@web/components/UserMessage';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { QuestionResponses } from '@web/questions/QuestionResponses';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AdminSurveyContent } from './AdminSurveyContent';

export const AdminSurveyCycleViewResponsesPage: React.FC = () => {
  const { surveyCycleToken, surveyParticipantToken } = useParams<{
    surveyCycleToken?: SurveyCycleToken;
    surveyParticipantToken?: SurveyParticipantToken;
  }>();
  const { data: surveyCycle, mutate: reloadSurveyCycle } = useApi<ISurveyCycle>(
    `/survey-cycles/${surveyCycleToken}`,
  );

  if (!surveyCycle) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const participant = surveyCycle.participants?.find(
    (p) => p.token === surveyParticipantToken,
  );

  if (!participant) {
    return (
      <AdminSurveyContent
        surveyCycle={surveyCycle}
        onChange={reloadSurveyCycle}
      >
        <Text>Participant not found</Text>
      </AdminSurveyContent>
    );
  }

  return (
    <AdminSurveyContent surveyCycle={surveyCycle} onChange={reloadSurveyCycle}>
      <Column gap={12}>
        <BackButton />
        <UserMessage user={participant.user} />
        <QuestionResponses
          questions={surveyCycle.questions}
          responses={participant?.responses}
          hideNullResponses
        />
      </Column>
    </AdminSurveyContent>
  );
};
