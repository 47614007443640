export const getItem = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const setItem = (key: string, value: string): boolean => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const removeItem = (key: string, value: string): boolean => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export default {
  getItem,
  setItem,
  removeItem,
};
