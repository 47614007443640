import { SearchOutlined } from '@ant-design/icons';
import { IOrganization, ISearchResults } from '@shared/types';
import { useOrganizationFilter } from '@web/app/organizationFilter';
import { get } from '@web/common/api';
import { useLocalStorage } from '@web/common/useLocalStorage';
import { Column, Row } from '@web/components/layout';
import { Input, InputRef } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { debounce, uniqBy } from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AddOrganizationButton } from './AddOrganizationButton';
import { AddSandboxButton } from './AddSandboxButton';
import { SelectOrganization } from './SelectOrganization';

const PAGE_SIZE = 6;

interface Props {
  onClose: () => void;
  open: boolean;
}
const OrganizationsModal: React.FC<Props> = ({ onClose, open }) => {
  const navigate = useNavigate();
  const { setOrganizationTokenFilter } = useOrganizationFilter();
  const [page, setPage] = React.useState(1);
  const searchRef = React.useRef<InputRef>();
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState<ISearchResults<IOrganization>>({
    results: [],
    total: 0,
  });
  const [recentOrganizations, setRecentOrganizations] = useLocalStorage(
    'recentOrganizations',
    [],
  );
  const { results: organizations, total } = results;

  const searchOrganizations = async () => {
    let newResults: ISearchResults<IOrganization>;
    if (query.length > 0 || !recentOrganizations) {
      newResults = await get<ISearchResults<IOrganization>>(
        `/organizations?skip=${
          (page - 1) * PAGE_SIZE
        }&limit=${PAGE_SIZE}&query=${query}&sandboxes=true`,
      );
    } else {
      newResults = {
        results: recentOrganizations,
        total: recentOrganizations.length,
      };
    }
    setResults(newResults);
  };
  const debouncedSearch = debounce(searchOrganizations, 300);

  React.useEffect(() => {
    void debouncedSearch();
  }, [page, query]);

  React.useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  const handleoOrganizationAdded = (organization: IOrganization) => {
    void searchOrganizations();
    addToRecents(organization);
    navigate(`/organizations/${organization.token}/details`);
    onClose();
  };

  const handleQueryChanged = (newQuery: string) => {
    setQuery(newQuery);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const addToRecents = (organization: IOrganization) => {
    const newRecents = uniqBy(
      [organization, ...(recentOrganizations ?? [])],
      'token',
    );
    setRecentOrganizations(newRecents.slice(0, 6));
  };

  const handleSelect = (organization: IOrganization) => {
    setOrganizationTokenFilter(organization.token);
    navigate(`/organizations/${organization.token}/details`);
    onClose();
    addToRecents(organization);
  };

  const handleCancel = () => {
    onClose();
    setQuery('');
    setPage(1);
  };

  return (
    <Modal
      title="Select an organization"
      open={open}
      onCancel={handleCancel}
      width="800px"
      footer={null}
    >
      <Column gap={12}>
        <Row gap={12}>
          <Input
            placeholder="Search for an organization"
            ref={searchRef}
            prefix={<SearchOutlined />}
            allowClear
            value={query}
            onChange={(e) => {
              handleQueryChanged(e.currentTarget.value);
            }}
          />
          <AddOrganizationButton onAdd={handleoOrganizationAdded} />
          <AddSandboxButton onAdd={handleoOrganizationAdded} />
        </Row>
        <SelectOrganization
          organizations={organizations}
          total={total}
          page={page}
          pageSize={PAGE_SIZE}
          onPageChange={handlePageChange}
          onSelect={handleSelect}
        />
      </Column>
    </Modal>
  );
};

export default OrganizationsModal;
