import { SearchUrlParams } from '@shared/createSearchUrl';
import { ISearchResults, IUser } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { ServerResponseError, post } from '@web/common/api';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Spacer } from '@web/components/layout';
import { message } from 'antd';
import { debounce, pickBy } from 'lodash';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { AdminTabs } from './AdminTabs';
import { AdminUsersPageExtras } from './AdminUsersPageExtras';
import { AdminUsersTable } from './AdminUsersTable';

const PAGE_SIZE = 10;

const AdminUsersPage: React.FC = () => {
  const [searchResults, setSearchResults] =
    React.useState<ISearchResults<IUser>>(null);
  const [error, setError] = React.useState<ServerResponseError>(null);
  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
  });
  const params = pickBy({
    page: searchParams.get('page'),
    query: searchParams.get('query'),
  });
  const page = parseInt(params.page ?? '1');
  const query = params.query ?? '';

  const searchUsers = async () => {
    try {
      const results = await post<SearchUrlParams, ISearchResults<IUser>>(
        '/users/search',
        {
          skip: (page - 1) * PAGE_SIZE,
          limit: PAGE_SIZE,
          search: query,
        },
      );
      setSearchResults(results);
    } catch (error) {
      setError(error);
    }
  };
  React.useEffect(() => {
    void searchUsers();
  }, [query, page]);

  if (error) {
    void message.error('Failed to load users');
    setError(null);
  }

  const handlePageChange = (newPage: number) => {
    setSearchParams({ ...params, page: `${newPage}` });
  };

  const handleSearchQueryChanged = debounce((newQuery: string) => {
    const newParams = pickBy({ ...params, query: `${newQuery}`, page: '1' });
    setSearchParams(newParams);
  }, 300);

  return (
    <PageContent>
      <PageHeader title="Admin" />
      <AdminTabs />
      <Pane>
        <AdminUsersPageExtras
          searchQuery={query}
          onSearchQueryChanged={handleSearchQueryChanged}
          onChange={() => {
            void searchUsers();
          }}
        />
        <Spacer />
        <AdminUsersTable
          onChange={searchUsers}
          searchResults={searchResults}
          page={page}
          pageSize={PAGE_SIZE}
          onPageChange={handlePageChange}
        />
      </Pane>
    </PageContent>
  );
};

export default AdminUsersPage;
