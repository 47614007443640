import { BedrockMechanism, BedrockReportSendMode } from '@shared/bedrock';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Modal, message } from 'antd';
import * as React from 'react';

import { SelectMechanism } from './SelectMechanism';
import { SelectSendMode } from './SelectSendMode';

export const SendPreparedRunModal: React.FC<{
  open: boolean;
  title: string;
  onSubmit: (
    mechanism: BedrockMechanism,
    sendMode: BedrockReportSendMode,
  ) => Promise<void>;
  onCancel: () => void;
}> = ({ open, title, onSubmit, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [mechanism, setMechanism] = React.useState<
    BedrockMechanism | undefined
  >('journal');
  const [sendMode, setSendMode] = React.useState<
    BedrockReportSendMode | undefined
  >(BedrockReportSendMode.ONCE);

  const handleClose = () => {
    setIsSubmitting(false);
    onCancel();
  };

  const handleOk = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit(mechanism, sendMode);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={title}
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSubmitting}
      onCancel={onCancel}
      width="500px"
      okText="Send"
      okButtonProps={{
        disabled: isSubmitting || !mechanism || !sendMode,
      }}
    >
      <Column gap={12}>
        <Column gap={6}>
          <Text>Mechanism</Text>
          <SelectMechanism
            disabled={isSubmitting}
            value={mechanism}
            onChange={setMechanism}
          />
        </Column>
        <Column gap={6}>
          <Text>Send Mode</Text>
          <SelectSendMode
            disabled={isSubmitting}
            value={sendMode}
            onChange={setSendMode}
          />
        </Column>
      </Column>
    </Modal>
  );
};
