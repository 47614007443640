import { HolderOutlined } from '@ant-design/icons';
import { UserToken } from '@shared/types';
import { StopPropagationSpan } from '@web/components/StopPropagation';
import { UserAvatar } from '@web/components/UserAvatar';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import Avatar from 'antd/es/avatar/avatar';
import Dropdown from 'antd/es/dropdown/dropdown';
import humanparser from 'humanparser';
import * as React from 'react';
import styled from 'styled-components';

import { ITaskItem } from './TaskList';

export const TaskOwner: React.FC<{
  taskItem: ITaskItem;
  onAssign?: (assignedToToken: UserToken | null) => void;
}> = ({ taskItem, onAssign }) => {
  if (taskItem.assignableOwners && onAssign) {
    const assignableMenu = {
      items: taskItem.assignableOwners.map((user) => {
        const { firstName } = humanparser.parseName(user.name);
        return {
          key: user.token,
          label: (
            <Row gap={6}>
              <UserAvatar size={20} user={user} />
              <Text>Assign to {firstName}</Text>
            </Row>
          ),
          onClick: () => {
            onAssign(user.token);
          },
        };
      }),
    };
    if (taskItem.owner) {
      assignableMenu.items = assignableMenu.items.filter(
        (item) => item.key !== taskItem.owner.token,
      );
      assignableMenu.items.push({
        key: 'u_nassign',
        label: (
          <Row gap={6}>
            <Avatar size={20} />
            <Text>Unassign</Text>
          </Row>
        ),
        onClick: () => {
          onAssign(null);
        },
      });
      return (
        <StopPropagationSpan>
          <Dropdown placement="bottom" arrow menu={assignableMenu}>
            <Avatar
              icon={<img src={taskItem.owner.slackImageUrl} />}
              size={20}
              style={{
                flexGrow: 0,
                flexShrink: 0,
                border: 0,
                marginRight: 6,
              }}
            />
          </Dropdown>
        </StopPropagationSpan>
      );
    }

    return (
      <StopPropagationSpan>
        <Dropdown placement="bottom" arrow menu={assignableMenu}>
          <Avatar size={20} style={{ marginRight: 6 }}>
            +
          </Avatar>
        </Dropdown>
      </StopPropagationSpan>
    );
  }

  if (taskItem.owner) {
    return (
      <UserAvatar user={taskItem.owner} size={20} style={{ marginRight: 6 }} />
    );
  }

  return null;
};

export const RowItem = styled.div`
  gap: 0 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const DragAnchor = styled(HolderOutlined)`
  opacity: 0;
  font-size: 18px;
  color: #888;
  cursor: grab;
`;
export const NewRow = styled(Row)`
  align-items: flex-start;
  margin: 2px 0 8px 0;
`;
