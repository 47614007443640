import {
  completeActionItem,
  uncompleteActionItem,
  useAssignedActionItems,
} from '@client/OneOnOnesClient';
import { formatDate } from '@shared/formatDate';
import { IActionItem, oneOnOneUrl } from '@shared/one-on-one';
import { IUser } from '@shared/types';
import { ActionItemDrawer } from '@web/1on1s/ActionItemDrawer';
import { useAuth } from '@web/auth/useAuth';
import { StopPropagationSpan } from '@web/components/StopPropagation';
import { Column, NoWrap, Spacer } from '@web/components/layout';
import {
  Header2,
  Text,
  TruncateText,
  TypographyColors,
} from '@web/components/typography';
import { Skeleton, Tag, message } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import humanparser from 'humanparser';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Widget, WidgetRow } from './Widget';

export const ActionItemsWidget: React.FC = () => {
  const { user } = useAuth();
  const [selectedActionItem, setSelectedActionItem] =
    React.useState<IActionItem>(null);
  const { data: actionItems, mutate: reloadActionItems } =
    useAssignedActionItems();
  React.useEffect(() => {
    if (selectedActionItem) {
      if (!actionItems.some((ai) => ai.token === selectedActionItem.token)) {
        setSelectedActionItem(null);
      }
    }
  }, [actionItems]);

  const { oneOnOne, otherUser } = selectedActionItem
    ? actionItemDetails(selectedActionItem, user)
    : { oneOnOne: undefined, otherUser: undefined };
  return (
    <Column>
      <Header2>Open Action Items</Header2>
      <Spacer size={6} />
      {!actionItems ? (
        <Skeleton />
      ) : actionItems.length > 0 ? (
        <Widget>
          {actionItems.map((actionItem, index) => (
            <ActionItemWidgetRow
              hideBorder={index === 0}
              key={actionItem.token}
              actionItem={actionItem}
              onClick={() => {
                setSelectedActionItem(actionItem);
              }}
              onChange={() => {
                void reloadActionItems();
              }}
            />
          ))}
        </Widget>
      ) : (
        <Text>No open action items</Text>
      )}
      {selectedActionItem && otherUser && oneOnOne && (
        <ActionItemDrawer
          actionItem={selectedActionItem}
          onChange={() => {
            void reloadActionItems();
          }}
          onClose={() => {
            setSelectedActionItem(null);
          }}
          oneOnOneToken={oneOnOne.token}
          otherUser={otherUser}
          publishPresence={false}
          extra={<ActionItemPill actionItem={selectedActionItem} />}
        />
      )}
    </Column>
  );
};
const ActionItemWidgetRow: React.FC<{
  actionItem: IActionItem;
  hideBorder: boolean;
  onClick: () => void;
  onChange: () => void;
}> = ({ actionItem, hideBorder, onChange, onClick }) => {
  const { user } = useAuth();
  const handleResolveClicked = async () => {
    try {
      const { oneOnOne } = actionItemDetails(actionItem, user);
      if (actionItem.completedDate) {
        await uncompleteActionItem(oneOnOne.token, actionItem.token);
      } else {
        await completeActionItem(oneOnOne.token, actionItem.token);
      }
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <WidgetRow
      readonly
      hideBorder={hideBorder}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <StopPropagationSpan>
        <Checkbox
          onClick={handleResolveClicked}
          checked={!!actionItem.completedDate}
        />
      </StopPropagationSpan>
      <ActionItemPill actionItem={actionItem} />
      <TruncateText
        style={{
          textDecoration: actionItem.completedDate ? 'line-through' : undefined,
        }}
      >
        {actionItem.text}
      </TruncateText>
      <NoWrap style={{ color: TypographyColors.subdued }}>
        {formatDate(actionItem.updatedDate)}
      </NoWrap>
    </WidgetRow>
  );
};
const ActionItemPill: React.FC<{ actionItem: IActionItem }> = ({
  actionItem,
}) => {
  const { user } = useAuth();
  const { otherUser } = actionItemDetails(actionItem, user);
  const { firstName } = otherUser
    ? humanparser.parseName(otherUser.name)
    : null;
  return firstName ? (
    <StopPropagationSpan>
      <Link to={oneOnOneUrl(user.token, otherUser.token)}>
        <Tag style={{ margin: 0 }}>1-on-1 with {firstName}</Tag>
      </Link>
    </StopPropagationSpan>
  ) : null;
};

const actionItemDetails = (actionItem: IActionItem, user: IUser) => {
  const oneOnOne = actionItem.oneOnOnes?.[0];
  const otherUser =
    oneOnOne.ownerUser.token === user.token
      ? oneOnOne.counterpartUser
      : oneOnOne.ownerUser;

  return { oneOnOne, otherUser };
};
