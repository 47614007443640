import { BulbOutlined } from '@ant-design/icons';
import { ISearchImpactsResults } from '@shared/impacts';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { ReactionSummaryContext } from '@web/reactions/ReactionSummaryContext';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { JournalEntryList } from './JournalEntryList';

export const JournalManagerPage: React.FC = () => {
  const { userToken } = useParams();
  const { data: searchResults } = useApi<ISearchImpactsResults>(
    `/impacts?userToken=${userToken}`,
  );

  if (!searchResults) {
    return (
      <PageContent>
        <PageHeader
          title="Journal"
          mobileTitle="Journal"
          navigateBack
          defaultNavigateBackTo="/team"
        />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  const impactUser = searchResults.userMap[userToken];
  const { reactionSummaryMap, userMap } = searchResults;
  return (
    <ReactionSummaryContext.Provider value={{ reactionSummaryMap, userMap }}>
      <PageContent>
        <PageHeader
          title={`Journal for ${impactUser.name}`}
          mobileTitle={`Journal for ${impactUser.name}`}
          navigateBack
          defaultNavigateBackTo="/team"
          primaryAction={{
            icon: <BulbOutlined />,
            label: 'Suggest a journal entry',
            linkTo: `/team/${userToken}/journal/suggest`,
          }}
        />
        <Pane>
          <JournalEntryList impacts={searchResults.impacts} />
        </Pane>
      </PageContent>
    </ReactionSummaryContext.Provider>
  );
};
