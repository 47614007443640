import { PlusOutlined } from '@ant-design/icons';
import { ISearchImpactsResults } from '@shared/impacts';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Spacer } from '@web/components/layout';
import { ReactionSummaryContext } from '@web/reactions/ReactionSummaryContext';
import { Skeleton } from 'antd';
import * as React from 'react';

import { JournalEntryList } from './JournalEntryList';
import { JournalGetStarted } from './JournalGetStarted';
import { Banners } from './banners/Banners';
import { JournalInactivityBanner } from './banners/JournalInactivityBanner';

export const JournalPage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { data: searchResults } = useApi<ISearchImpactsResults>('/impacts');
  if (!searchResults) {
    return (
      <PageContent>
        <PageHeader
          title="Journal"
          mobileTitle="Journal"
          description="Keep a record of your work, provide regular progress updates, and get feedback on your journal entries.
"
        />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  const hasImpactEntries = searchResults.impacts?.length > 0;
  const { reactionSummaryMap, userMap } = searchResults;
  return (
    <ReactionSummaryContext.Provider
      value={{
        reactionSummaryMap,
        userMap,
      }}
    >
      <PageContent>
        <PageHeader
          title="Journal"
          mobileTitle="Journal"
          description="Keep a record of your work, provide regular progress updates, and get feedback on your journal entries."
          primaryAction={{
            label: isMobile ? undefined : 'New entry',
            icon: isMobile ? <PlusOutlined /> : undefined,
            linkTo: '/journal/new',
          }}
          secondaryActions={[
            {
              label: 'Suggest an entry',
              linkTo: '/journal/suggest',
            },
          ]}
        />
        <Banners>
          <JournalInactivityBanner />
        </Banners>
        {hasImpactEntries ? (
          <Pane>
            <JournalEntryList impacts={searchResults?.impacts} />
          </Pane>
        ) : (
          <Pane>
            <JournalGetStarted />
          </Pane>
        )}
        {isMobile && <Spacer size={60} />}
      </PageContent>
    </ReactionSummaryContext.Provider>
  );
};
