import { ImpactEntryMap } from '@shared/impacts';
import { IRecentFeedbackResponse } from '@shared/types';
import { Pane } from '@web/components/Pane';
import { Column } from '@web/components/layout';
import { ReactionSummaryContext } from '@web/reactions/ReactionSummaryContext';
import { Empty } from 'antd';
import { groupBy } from 'lodash';
import * as React from 'react';

import { ViewFeedback } from './ViewFeedback';

interface Props {
  direction: 'Received' | 'Given';
  feedbackResponse: IRecentFeedbackResponse;
  impactEntryMap?: ImpactEntryMap;
}

export const FeedbackList: React.FC<Props> = ({
  direction,
  feedbackResponse,
  impactEntryMap,
}) => {
  const feedback =
    direction === 'Received'
      ? feedbackResponse.feedback
      : feedbackResponse.given;
  const filteredFeedback = feedback;
  const groupedFeedback = groupBy(filteredFeedback, (f) =>
    f.entityToken
      ? `${f.entityToken}:${f.giverToken}`
      : `${f.token}:${f.giverToken}`,
  );

  return (
    <ReactionSummaryContext.Provider
      value={{
        reactionSummaryMap: feedbackResponse.reactionSummaryMap,
        userMap: feedbackResponse.userMap,
      }}
    >
      <Column>
        {Object.keys(groupedFeedback).map((groupedToken) => (
          <ViewFeedback
            key={`feedback-card-${groupedToken}`}
            userMap={feedbackResponse?.userMap}
            feedback={groupedFeedback[groupedToken][0]}
            impactEntryMap={impactEntryMap}
          />
        ))}
        {filteredFeedback.length === 0 && (
          <Pane>
            <Empty
              description={
                direction === 'Received'
                  ? 'No feedback received'
                  : 'No feedback given'
              }
            />
          </Pane>
        )}
      </Column>
    </ReactionSummaryContext.Provider>
  );
};
