export const runTemplate = (
  template: string | undefined,
  parameters: Record<string, string>,
) => {
  if (!template) {
    return;
  }

  for (const parameter in parameters) {
    template = template.replaceAll(`{${parameter}}`, parameters[parameter]);
  }
  return template;
};
