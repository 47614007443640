import {
  IUser,
  OrganizationToken,
  UserMapItem,
  UserRole,
  UserToken,
} from '@shared/types';
import { Select } from 'antd';
import * as React from 'react';

import { useSelectUserSearch } from './useSelectUserSearch';

interface Props {
  disabled?: boolean;
  initialUser?: UserMapItem;
  onChange?: (newValue: UserToken, user: IUser) => void;
  organizationToken?: OrganizationToken;
  options?: IUser[];
  omitUserTokens?: UserToken[];
  reportsOnly?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  allowClear?: boolean;
  roleFilter?: UserRole;
  forEnrollment?: boolean;
}

export const SelectUser: React.FC<Props> = ({
  disabled,
  initialUser,
  onChange,
  organizationToken,
  omitUserTokens = [],
  reportsOnly = false,
  style,
  placeholder,
  allowClear,
  roleFilter,
  forEnrollment,
}) => {
  const { users, search, clearSearch } = useSelectUserSearch({
    organizationToken,
    reportsOnly,
    omitUserTokens,
    roleFilter,
    forEnrollment,
  });
  const selectRef = React.useRef<{ blur: () => void }>();

  const userOptions = users.map((user) => ({
    value: user.token,
    label: user.name,
  }));

  const handleSelectChange = (option: { label: string; value: UserToken }) => {
    clearSearch();
    const userToken = option?.value ?? null;
    const user = userToken ? users.find((u) => u.token === userToken) : null;
    onChange(userToken, user);
    selectRef.current?.blur();
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      clearSearch();
    }
  };

  return (
    <Select
      allowClear={allowClear}
      placeholder={placeholder}
      showSearch
      disabled={disabled}
      notFoundContent={null}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      defaultValue={
        initialUser
          ? { label: initialUser.name, value: initialUser.token }
          : undefined
      }
      onSearch={search}
      style={style}
      options={userOptions}
      filterOption={false}
      onChange={handleSelectChange as any}
      labelInValue={true}
      choiceTransitionName=""
      transitionName=""
      animation=""
      defaultActiveFirstOption={false}
      ref={
        selectRef as any
      } /** Not sure how to impact BaseSelectRef from rc-select */
    />
  );
};
