import { OrganizationToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Column } from '@web/components/layout';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { GithubPullTable } from './GithubPullTable';
import { GithubTabs } from './GithubTabs';

export const GithubPullsPage: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  return (
    <PageContent>
      <PageHeader title={'Github'} />
      <Column>
        <GithubTabs />
        <GithubPullTable organizationToken={organizationToken} />
      </Column>
    </PageContent>
  );
};
