import { UserFeedbackResponse } from '@shared/feedback';
import { QuestionToken } from '@shared/questions';
import { ReviewCycleToken } from '@shared/review-cycles';
import {
  FeedbackRequestToken,
  FeedbackToken,
  FeedbackVisibility,
  IRecentFeedbackResponse,
  UpdateFeedbackResponse,
  UserToken,
} from '@shared/types';
import { get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function requestFeedback(
  text: string,
  giverToken: UserToken,
  questionToken: QuestionToken,
  visibility: FeedbackVisibility,
  receiverToken?: UserToken,
) {
  return post<{
    text: string;
    giverToken: `u_${string}`;
    questionToken: `q_${string}`;
    visibility: FeedbackVisibility;
    receiverToken: `u_${string}`;
  }>(`/feedback/request`, {
    text: text,
    giverToken: giverToken,
    questionToken: questionToken,
    visibility: visibility,
    receiverToken: receiverToken,
  });
}

export function giveFeedbackResponse(
  feedbackRequestToken: FeedbackRequestToken,
  responses: UserFeedbackResponse[],
) {
  return put<UserFeedbackResponse[]>(
    `/feedback/template/${feedbackRequestToken}`,
    responses,
  );
}

export function giveFeedback(
  text: string,
  receiverToken: UserToken,
  visibility: FeedbackVisibility,
  questionToken?: QuestionToken,
) {
  return post<{
    text: string;
    receiverToken: `u_${string}`;
    visibility: FeedbackVisibility;
    questionToken: `q_${string}`;
  }>(`/feedback/basic`, {
    text: text,
    receiverToken: receiverToken,
    visibility: visibility,
    questionToken: questionToken,
  });
}

export function useGetRecentFeedbackForManager(
  userToken: UserToken,
  reviewCycleToken: ReviewCycleToken,
) {
  const url = stringifyUrl({
    url: `/feedback/${userToken}/manager/recent`,
    query: {
      reviewCycle: reviewCycleToken,
    },
  });

  return useApi<IRecentFeedbackResponse>(url);
}

export function getRecentFeedbackForManager(
  userToken: UserToken,
  reviewCycleToken: ReviewCycleToken,
) {
  const url = stringifyUrl({
    url: `/feedback/${userToken}/manager/recent`,
    query: {
      reviewCycle: reviewCycleToken,
    },
  });

  return get<IRecentFeedbackResponse>(url);
}

export function updateBasicFeedback(
  feedbackToken: FeedbackToken,
  text: string,
) {
  return patch<{ text: string }, UpdateFeedbackResponse>(
    `/feedback/${feedbackToken}/question`,
    { text: text },
  );
}

export function updateFeedback(
  feedbackToken: FeedbackToken,
  questionToken: QuestionToken,
  text: string,
) {
  return patch<{ text: string }>(
    `/feedback/${feedbackToken}/question/${questionToken}`,
    { text: text },
  );
}

export function useGetRecentFeedback(
  reviewCycleToken: ReviewCycleToken,
  limit?: number,
  userToken?: UserToken,
) {
  const url = stringifyUrl({
    url: `/feedback/recent`,
    query: {
      reviewCycle: reviewCycleToken,
      limit: limit,
      userToken: userToken,
    },
  });

  return useApi<IRecentFeedbackResponse>(url);
}

export function getRecentFeedback(
  reviewCycleToken: ReviewCycleToken,
  limit?: number,
  userToken?: UserToken,
) {
  const url = stringifyUrl({
    url: `/feedback/recent`,
    query: {
      reviewCycle: reviewCycleToken,
      limit: limit,
      userToken: userToken,
    },
  });

  return get<IRecentFeedbackResponse>(url);
}
