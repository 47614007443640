export type GithubPullToken = `gp_${string}`;
export interface IGithubPull {
  token: GithubPullToken;
  url: string;
  title: string;
  description: string;
  additions: number;
  userLogin: string;
  createdDate: Date;
  updatedDate: Date;
  mergedDate: Date;
}

export const parsePullUrl = (url: string) => {
  const parts = url.split('/');
  const owner = parts[3];
  const repo = parts[4];
  const number = parseInt(parts[6]);
  return {
    owner,
    repo,
    number,
  };
};
