import {
  completeActionItem,
  removeActionItem,
  uncompleteActionItem,
  updateActionItem,
} from '@client/OneOnOnesClient';
import { IActionItem, OneOnOneToken } from '@shared/one-on-one';
import { UserMapItem } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { publishPresenceEvent } from '@web/common/useWebSocketEvent';
import { Column, Row } from '@web/components/layout';
import { Checkbox, Drawer, message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { DrawerTitle } from './DrawerTitle';
import { InlineInput } from './InlineInput';
import { ItemDates } from './ItemDates';
import { NotesList } from './notes/NotesList';

export const ActionItemDrawer: React.FC<{
  oneOnOneToken: OneOnOneToken;
  actionItem: IActionItem;
  onClose: () => void;
  otherUser: UserMapItem;
  onChange?: () => void;
  publishPresence?: boolean;
  extra?: React.ReactNode;
}> = ({
  actionItem,
  onChange,
  onClose,
  oneOnOneToken,
  otherUser,
  publishPresence = true,
  extra,
}) => {
  const { user } = useAuth();
  const { confirm, contextHolder } = useModalConfirm();
  const [originalText, setOriginalText] = React.useState(actionItem.text ?? '');
  const [text, setText] = React.useState(actionItem.text ?? '');
  React.useEffect(() => {
    if (!publishPresence) {
      return;
    }

    publishPresenceEvent(oneOnOneToken, actionItem.token, user.token);

    return () => {
      publishPresenceEvent(oneOnOneToken, null, user.token);
    };
  }, []);

  const resolved = !!actionItem.completedDate;
  const handleSaveText = async () => {
    if (!text || text === originalText) {
      return;
    }

    try {
      await updateActionItem(oneOnOneToken, actionItem.token, text);
      setOriginalText(text);
      onChange?.();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleClose = async () => {
    await handleSaveText();
    onClose();
  };

  const toggleResolved = async () => {
    try {
      if (!resolved) {
        await completeActionItem(oneOnOneToken, actionItem.token);
      } else {
        await uncompleteActionItem(oneOnOneToken, actionItem.token);
      }
      onChange?.();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleDelete = async () => {
    const confirmed = await confirm(
      'Did you mean to resolve this action item instead? Removing this action item will remove all associated comments and is irreversible.',
      { title: 'Remove Action Item' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await removeActionItem(oneOnOneToken, actionItem.token);
      onChange?.();
      void handleClose();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleEnterPressed = async () => {
    changeFocus();
  };

  const handleTextBlur = async () => {
    await handleSaveText();
    changeFocus();
  };

  const changeFocus = () => {
    const closeButton: HTMLButtonElement =
      document.querySelector('.ant-drawer-close');
    closeButton?.focus();
  };

  return (
    <Drawer
      title={<DrawerTitle onDelete={handleDelete} />}
      placement="right"
      onClose={handleClose}
      open={true}
      width={500}
      bodyStyle={{ overflow: 'hidden', paddingTop: 0 }}
    >
      <NotesList
        entityToken={actionItem.token}
        oneOnOneToken={oneOnOneToken}
        otherUser={otherUser}
      >
        <TitleContainer>
          <Row gap={6} style={{ alignItems: 'flex-start', paddingTop: 6 }}>
            <Checkbox checked={resolved} onClick={toggleResolved} />
            <InlineInput
              value={text}
              onChange={setText}
              onEnter={handleEnterPressed}
              onBlur={handleTextBlur}
              placeholder="Enter action"
              style={{
                fontSize: 18,
                lineHeight: '22px',
                marginTop: -5,
                textDecoration: actionItem.completedDate
                  ? 'line-through'
                  : undefined,
              }}
            />
          </Row>
          <Row gap={6}>
            <ItemDates
              item={actionItem}
              extra={extra}
              style={{ marginTop: -6 }}
            />
          </Row>
        </TitleContainer>
      </NotesList>
      {contextHolder}
    </Drawer>
  );
};

const TitleContainer = styled(Column)`
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`;
