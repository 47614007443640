import { DeleteOutlined, HolderOutlined } from '@ant-design/icons';
import { StopPropagation } from '@web/components/StopPropagation';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { Button, Popconfirm, Tag } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const SurveySectionItemRow: React.FC<{
  tag: string;
  label: string;
  onClick?: () => void;
  onDelete?: () => void;
  position: number;
}> = ({ tag, label, onClick, onDelete, position }) => {
  return (
    <SortableRow
      style={{
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={() => {
        onClick?.();
      }}
    >
      <ItemDragAnchor>
        <DragAnchor className="drag-anchor" />
      </ItemDragAnchor>
      <Spacer size={12} />
      <ItemPosition>{position}</ItemPosition>
      <Spacer size={12} />
      <Tag style={{ width: 90, minWidth: 90, textAlign: 'center' }}>{tag}</Tag>
      <Spacer size={6} />
      <ItemText>{label}</ItemText>
      <GrowingSpacer />
      {onDelete && (
        <StopPropagation>
          <Popconfirm
            title="Do you want to delete this section?"
            onConfirm={() => {
              onDelete?.();
            }}
          >
            <Button size="small" type="text">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </StopPropagation>
      )}
    </SortableRow>
  );
};
const ItemPosition = styled(Small)`
  height: 20px;
  width: 20px;
  min-width: 20px;
  background: #888;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin: 2px 0;
`;
const ItemText = styled(Text)`
  line-height: 24px;
`;
const ItemDragAnchor = styled(Column)`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DragAnchor = styled(HolderOutlined)`
  font-size: 18px;
  color: #888;
  cursor: grab;
`;
const SortableRow = styled(Row)`
  align-items: flex-start;
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    ${DragAnchor} {
      cursor: grab;

      &:hover {
        color: #333;
      }
    }
  }
`;
