import { exhaustiveCheck } from './exhaustiveCheck';
import { IImpact, ImpactToken } from './impacts';
import { IQuestionSet, QuestionSetToken } from './questionSets';
import { IQuestion, IRankOption, QuestionToken } from './questions';
import { EntityReactionSummaryMap } from './reactions';
import { IReviewCycle, ReviewCycleToken } from './review-cycles';
import { ITag, TagToken } from './tags';
import {
  IEntity,
  ITask,
  IUser,
  OrganizationToken,
  UserMap,
  UserToken,
} from './types';

export type ReflectionToken = `r_${string}`;

export enum ReflectionState {
  DRAFT = 'DRAFT',
  COMPLETE = 'COMPLETE',
  SHARED = 'SHARED',
}

export interface NewReflectionRequest {
  receiverToken: UserToken;
  title: string;
  accomplishmentTokens: ImpactToken[];
  textContext: string;
  privateNote: string;
  tags: TagToken[];
  startDate: Date;
  endDate: Date;
  responses: ReflectionResponseInput[];
}

export interface NewReflectionResponse {
  reflection: IReflection;
}

export interface IReflection extends IEntity {
  token: ReflectionToken;
  organizationToken: OrganizationToken;
  title: string;
  receiverToken: UserToken;
  receiver: IUser;
  authorToken: UserToken;
  author: IUser;
  privateNote: string;
  accomplishments: IImpact[];
  textContext: string;
  tags: ITag[];
  state: ReflectionState;
  startDate: Date;
  endDate: Date;
  responses: IReflectionResponse[];
  completedDate?: Date;
  sharedDate?: Date;
  questionSetToken?: QuestionSetToken;
  includeWork: boolean;
  includePrivateNote: boolean;
  reviewCycleToken?: ReviewCycleToken;
  reviewCycle?: IReviewCycle;
  task?: ITask;
  productName: ProductName;
  canEdit: boolean;
}

export enum ProductName {
  REVIEW = 'REVIEW',
  REFLECTION = 'REFLECTION',
}

export interface IProductDetails {
  titleCase: string;
  lowerCase: string;
  preposition: string;
}
export const productDetails = (productName: ProductName): IProductDetails => {
  switch (productName) {
    case ProductName.REFLECTION:
      return {
        titleCase: 'Reflection',
        lowerCase: 'reflection',
        preposition: 'on',
      };
    case ProductName.REVIEW:
      return {
        titleCase: 'Review',
        lowerCase: 'review',
        preposition: 'of',
      };
    default:
      exhaustiveCheck(productName);
  }

  throw new Error(`Invalid productName ${productName as any}`);
};

export interface GetReflectionResponse {
  reflection: IReflection;
  questionSet?: IQuestionSet;
  reactions: {
    reactionSummaryMap: EntityReactionSummaryMap;
    userMap: UserMap;
  };
}

export interface ListReflectionsResponse {
  reflections: IReflection[];
}

export interface UpdateReflectionRequest
  extends Omit<
    NewReflectionRequest,
    'receiverToken' | 'accomplishmentTokens' | 'startDate' | 'endDate' | 'title'
  > {
  draft: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateReflectionResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeleteReflectionResponse {}

export function isReflectionToken(token: string): token is ReflectionToken {
  return !!token?.startsWith('r_');
}

export type ReflectionResponseToken = `rr_${string}`;
export interface IReflectionResponse extends IEntity {
  token: ReflectionResponseToken;
  organizationToken: OrganizationToken;
  questionToken: QuestionToken;
  question: IQuestion;
  content?: string;
  rating?: number;
  ranking?: Record<IRankOption['token'], number>;
}

export type ReflectionResponseInput =
  | ReflectionResponseRatingInput
  | ReflectionResponseTextInput
  | ReflectionResponseRankInput;

interface BaseReflectionResponseInput {
  type: string;
  questionToken: QuestionToken;
  question: IQuestion;
}

export interface ReflectionResponseRatingInput
  extends BaseReflectionResponseInput {
  type: 'rating';
  rating: number;
}

export function isRatingResponse(
  response?: ReflectionResponseInput,
): response is ReflectionResponseRatingInput {
  return response?.type === 'rating';
}

export interface ReflectionResponseTextInput
  extends BaseReflectionResponseInput {
  type: 'text';
  content: string;
}

export interface ReflectionResponseRankInput
  extends BaseReflectionResponseInput {
  type: 'rank';
  ranking: Record<IRankOption['token'], number>;
}

export function isTextResponse(
  response?: ReflectionResponseInput,
): response is ReflectionResponseTextInput {
  return response?.type === 'text';
}

export function isRankResponse(
  response?: ReflectionResponseInput,
): response is ReflectionResponseRankInput {
  return response?.type === 'rank';
}

export const REFLECTION_EXPECTATIONS_QUESTION_TOKEN =
  'q_reflection_expectations';
export const REFLECTION_PROGRESS_QUESTION_TOKEN = 'q_reflection_progress';
export const REFLECTION_ADDITIONAL_THOUGHTS_QUESTION_TOKEN =
  'q_reflection_additional_thoughts';

export interface IReflectionConfiguration {
  enabled: boolean;
  enrolled: IUser[];
  schedule: IReflectionScheduleEvent[];
}

export type ReflectionConfigurationToken = `rc_${string}`;

export interface IReflectionScheduleEvent {
  token: ReflectionScheduleEventToken;
  title: string;
  startDate: Date;
  endDate: Date;
  dueDate: Date;
  draftDate: Date;
  draftCreatedDate: Date;
  taskDate: Date;
  taskCreatedDate: Date;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateScheduledEventResponse {}

export interface UpdateScheduledEventRequest {
  title: string;
  startDate: Date;
  endDate: Date;
  dueDate: Date;
  draftDate: Date;
  taskDate: Date;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RemoveEnrolledManagerResponse {}

export interface AddEnrolledManagerRequest {
  userToken: UserToken;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AddEnrolledManagerResponse {}

export interface SetReflectionsEnabledRequest {
  enabled: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SetReflectionsEnabledResponse {}

export interface GetReflectionConfigurationResponse {
  enabled: boolean;
  enrolled: IUser[];
  schedule: IReflectionScheduleEvent[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateNextReflectionEventResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SendReflectionDraftsResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SendReflectionTasksResponse {}

export type ReflectionScheduleEventToken = `rse_${string}`;

export type ReflectionEnrollmentToken = `re_${string}`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateSelfReflectionRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateSelfReflectionResponse {}

export const isManagerReflection = (reflection: IReflection) =>
  reflection.authorToken !== reflection.receiverToken;
