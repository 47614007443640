import { DeleteOutlined } from '@ant-design/icons';
import { IUser } from '@shared/types';
import { UserAvatar } from '@web/components/UserAvatar';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sortBy } from 'lodash';
import * as React from 'react';

interface Props {
  users: IUser[];
  onDelete: (user: IUser) => void;
}

export const EnrolledUsersTable: React.FC<Props> = ({ users, onDelete }) => {
  const columns: ColumnsType<IUser> = [
    {
      dataIndex: 'slackImageUrl',
      key: 'slackImageUrl',
      width: 50,
      render(_, user) {
        return <UserAvatar user={user} size={24} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (_, user) => {
        return (
          <Button
            type="text"
            onClick={() => {
              onDelete(user);
            }}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const sortedUsers = sortBy(users, 'name');
  return <Table rowKey="token" columns={columns} dataSource={sortedUsers} />;
};
