import { IReviewCycle } from '@shared/review-cycles';
import { Tag } from 'antd';
import React from 'react';

export const ReviewCycleStateTag: React.FC<{ reviewCycle: IReviewCycle }> = ({
  reviewCycle,
}) => {
  if (!reviewCycle.closedDate) {
    return null;
  }
  return <Tag>Closed</Tag>;
};
