import { SearchOutlined } from '@ant-design/icons';
import { useAdminTeamDashboard } from '@client/ReviewCyclesClient';
import { productDetails } from '@shared/reflections';
import { IReviewCycle, IReviewCycleTeamSummary } from '@shared/review-cycles';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Row } from '@web/components/layout';
import { Small } from '@web/components/typography';
import { Button, Empty, Progress, Skeleton, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { filter } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';

const DEFAULT_COLUMN_WIDTH = '150px';

export const TeamsTableSummary: React.FC<{
  reviewCycle: IReviewCycle;
}> = ({ reviewCycle }) => {
  const { data: teamSummaries } = useAdminTeamDashboard(reviewCycle.token);
  if (!teamSummaries) {
    return <Skeleton />;
  }

  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );
  const selfReviewProductName = `Self ${selfReviewProduct.lowerCase}`;
  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );
  const managerReviewProductName = `Manager ${managerReviewProduct.lowerCase}`;

  const rows = Object.values(teamSummaries);
  const columns: ColumnsType<IReviewCycleTeamSummary> = [
    {
      title: 'Team Manager',
      dataIndex: 'team',
      key: 'manager',
      render: (team) => {
        return team.manager ? (
          <Row gap={6}>
            <UserMessage
              user={team.manager}
              style={{ whiteSpace: 'nowrap' }}
              width="auto"
              avatarSize={24}
            />
            <Tooltip title={`View team's cycle dashboard`}>
              <Link
                to={`/cycles/${reviewCycle.token}/teams/${team.manager.token}`}
              >
                <Button type="text" size="small">
                  <SearchOutlined />
                </Button>
              </Link>
            </Tooltip>
          </Row>
        ) : (
          'N/A'
        );
      },
    },
    reviewCycle.peerReviewCycleEnabled
      ? {
          title: 'Peers selected',
          key: 'peers-selected',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <ProgressCell
              count={row.peersSelected}
              total={row.totalParticipants}
            />
          ),
        }
      : undefined,
    reviewCycle.peerReviewCycleEnabled
      ? {
          title: 'Peers approved',
          key: 'peers-approved',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <ProgressCell
              count={row.peersApproved}
              total={row.totalParticipants}
            />
          ),
        }
      : undefined,
    reviewCycle.peerReviewCycleEnabled
      ? {
          title: 'Feedback received',
          key: 'feedback-received',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <ProgressCell
              count={row.feedbackReceived}
              total={row.totalParticipants}
            />
          ),
        }
      : undefined,
    reviewCycle.selfReflectionCycleEnabled
      ? {
          title: selfReviewProductName,
          key: 'self-reflection',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <ProgressCell
              count={row.selfReflectionCompleted}
              total={row.totalParticipants}
            />
          ),
        }
      : undefined,
    reviewCycle.managerReflectionCycleEnabled
      ? {
          title: managerReviewProductName,
          key: 'manager-reflection',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <ProgressCell
              count={row.managerReflectionCompleted}
              total={row.totalParticipants}
            />
          ),
        }
      : undefined,
  ];

  return (
    <Column>
      <Table<IReviewCycleTeamSummary>
        columns={filter(columns)}
        dataSource={rows}
        locale={{
          emptyText: <Empty description="No teams found" />,
        }}
        rowKey="token"
        tableLayout="auto"
        sticky={true}
        scroll={{ x: true }}
      />
    </Column>
  );
};

const ProgressCell: React.FC<{ count: number; total: number }> = ({
  count,
  total,
}) => {
  const percent = Math.round((count / total) * 100);
  return (
    <Row style={{ justifyContent: 'center' }} gap={12}>
      <Progress
        showInfo={false}
        style={{ margin: 0, width: 35 }}
        percent={percent}
      />
      <Small style={{ whiteSpace: 'nowrap' }}>
        {count}/{total}
      </Small>
    </Row>
  );
};
