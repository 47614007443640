import { SuggestedEntryType } from '@shared/impacts';
import { possessive } from '@shared/possessive';
import { isManagerReflection } from '@shared/reflections';
import { SurveyType } from '@shared/surveys';
import {
  FLINT_USER,
  GITHUB_USER,
  ITask,
  TaskType,
  UserMap,
  UserMapItem,
} from '@shared/types';

export const getTaskInfo = (task: ITask, userMap: UserMap) => {
  let title: string = '???';
  let linkTo: string = '???';
  let user: UserMapItem;
  let isCycleRequest = false;

  if (task.type === TaskType.FEEDBACK_REQUEST) {
    const feedbackRequest = task.feedbackRequests?.[0];
    if (!feedbackRequest) {
      // skip over bad data
      return null;
    }
    isCycleRequest = !!feedbackRequest.reviewCycleToken;

    const baseUrl = '/feedback/give';
    linkTo = task.completedDate
      ? `/requests/given/${task.token}`
      : `${baseUrl}?t=${task.token}`;

    user = userMap[feedbackRequest.receiverToken];
    const requester = userMap[feedbackRequest.requesterToken];
    const receiver = userMap[feedbackRequest.receiverToken];
    if (isCycleRequest) {
      title = task.reviewCycle
        ? `Provide feedback for the ${task.reviewCycle.name}`
        : `Provide feedback for ${receiver.name}`;
    } else if (feedbackRequest.questionSetToken === 'frt_growth_advice') {
      title = `${requester.name} requested your feedback about ${user.name} on: Growth`;
    } else if (feedbackRequest.questionSetToken === 'frt_collab_advice') {
      title = `${requester.name} requested your feedback about ${user.name} on: Collaboration`;
    } else if (feedbackRequest.text) {
      title = `${feedbackRequest.text}`;
    } else {
      title = `${requester.name} requested your feedback`;
    }
  } else if (task.type === TaskType.IMPACT_ENTRY_FEEDBACK) {
    const impactEntry = task.impact;
    if (!impactEntry) {
      // skip over bad data
      return null;
    }

    title = impactEntry.title;
    linkTo = `/journal/${impactEntry.token}`;
    user = userMap[impactEntry.user.token];
  } else if (task.type === TaskType.SUGGESTED_ENTRY) {
    const suggestedEntry = task.suggestedEntry;

    linkTo = `/journal/new?suggestion=${suggestedEntry.token}`;

    if (suggestedEntry.type === SuggestedEntryType.MANAGER_TEAM_ENTRY) {
      title = 'Introduce your team to Flint by sharing an entry with them';
      user = FLINT_USER;
    } else if (suggestedEntry.type === SuggestedEntryType.GITHUB_PULL_REQUEST) {
      title = suggestedEntry.title;
      user = GITHUB_USER;
    } else {
      user = userMap[suggestedEntry.suggestor.token];
      title = suggestedEntry.title;
    }
  } else if (task.type === TaskType.REFLECTION) {
    const reflection = task.reflection;
    const isSelfReview = reflection.authorToken === reflection.receiverToken;
    isCycleRequest = !!reflection.reviewCycleToken;

    user = userMap[reflection.receiverToken];
    title = isSelfReview
      ? `Complete and submit your self review`
      : isCycleRequest
      ? `Complete your review for ${user.name} and submit`
      : `Complete your ${reflection.title} on ${possessive(user.name)} work`;
    if (task.declinedDate) {
      linkTo = `/team/${reflection.receiverToken}/reflections`;
    } else if (task.completedDate) {
      linkTo =
        isCycleRequest && isManagerReflection(reflection)
          ? `/cycles/${reflection.reviewCycleToken}/users/${reflection.receiverToken}/reflection`
          : `/reflections/${reflection.token}`;
    } else {
      linkTo =
        isCycleRequest && isManagerReflection(reflection)
          ? `/cycles/${reflection.reviewCycleToken}/users/${reflection.receiverToken}/reflection/edit`
          : `/reflections/${reflection.token}/edit`;
    }
  } else if (task.type === TaskType.SURVEY) {
    const surveyCycle = task.surveyCycle;
    isCycleRequest = true;
    user = FLINT_USER;
    if (task.completedDate) {
      title =
        surveyCycle.type === SurveyType.CHECK_IN
          ? `Completed check-in`
          : `Completed ${surveyCycle.name} survey`;
      linkTo = `/surveys/${surveyCycle.token}/view`;
    } else {
      title =
        surveyCycle.type === SurveyType.CHECK_IN
          ? `Complete your check-in`
          : `Complete ${surveyCycle.name} survey`;
      linkTo = `/surveys/${surveyCycle.token}`;
    }
  }

  return {
    title,
    user,
    linkTo,
    isCycleRequest,
  };
};
