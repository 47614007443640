import { IF_MOBILE } from '@web/app/responsive';
import styled from 'styled-components';

export const Banner = styled.div<{ background: string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props.background};
  border-radius: var(--default-border-radius);
  padding: 8px 16px;

  a {
    font-weight: bold;
    text-decoration: underline;
    color: #333;
  }

  a:hover {
    text-decoration: underline;
  }
`;
export const Banners = styled.section`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: stretch;

  > :last-child {
    margin-bottom: 24px;
  }

  ${IF_MOBILE} {
    > :first-child {
      margin-top: 12px;
    }
  }
`;

export const BannerEmoji = styled.span`
  font-size: 22px;
  position: relative;
  top: -1px;
`;
