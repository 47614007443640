import { Column, Grid, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import * as React from 'react';
import styled from 'styled-components';

export const PieChartMetric: React.FC<{
  data: IPieChartData[];
  circleSize?: number;
}> = ({ data, circleSize = 100 }) => {
  const defaultColors = createPieChartColors(data.length);
  const color = data.map((d, index) => d.color ?? defaultColors[index]);
  const option: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        type: 'pie',
        top: '-30%',
        left: '-30%',
        right: '-25%',
        bottom: '-25%',
        radius: '60%',
        data,
        color,
        label: {
          show: false,
          position: 'center',
        },
        emphasis: false,
      },
    ],
  };
  return (
    <Grid
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gap={12}
      columns={'1fr 1fr'}
    >
      <ChartContainer
        style={{
          width: circleSize,
          height: circleSize,
          justifySelf: 'flex-end',
        }}
      >
        <ReactECharts
          option={option}
          style={{ width: circleSize, height: circleSize, padding: 0 }}
        />
      </ChartContainer>
      <MetricLegend data={data} />
    </Grid>
  );
};

export const MetricLegend: React.FC<{ data: IPieChartData[] }> = ({ data }) => {
  const defaultColors = createPieChartColors(data.length);
  const color = data.map((d, index) => d.color ?? defaultColors[index]);

  return (
    <Column>
      {data.map((dataItem, index) => (
        <Row key={dataItem.name} gap={6}>
          <PieChartMetricDot style={{ backgroundColor: color[index] }} />
          <PieChartMetricLabel>{dataItem.name}:</PieChartMetricLabel>
          <PieChartMetricValue>{dataItem.value}</PieChartMetricValue>
        </Row>
      ))}
    </Column>
  );
};

const ChartContainer = styled.div`
  width: 100px;
  height: 100px;
`;

const PieChartMetricDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 7px;
`;
const PieChartMetricValue = styled(Text)`
  white-space: nowrap;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
`;
const PieChartMetricLabel = styled(PieChartMetricValue)`
  text-align: left;
`;

export interface IPieChartData {
  name: string;
  value: number;
  color?: string;
}

// palette created from here: https://www.learnui.design/tools/data-color-picker.html
export const PIE_CHART_COLORS = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
];
const createPieChartColors = (numColors: number) => {
  if (numColors === 2) {
    return [PIE_CHART_COLORS[0], PIE_CHART_COLORS[7]];
  } else if (numColors === 3) {
    return [PIE_CHART_COLORS[1], PIE_CHART_COLORS[4], PIE_CHART_COLORS[7]];
  }
};
