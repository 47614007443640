import { DeleteOutlined } from '@ant-design/icons';
import { IOrganizationMember, UserToken } from '@shared/types';
import { UserMessage } from '@web/components/UserMessage';
import React from 'react';
import styled from 'styled-components';

export const TeamMember: React.FC<{
  user: IOrganizationMember;
  onRemove?: (token: UserToken) => void;
}> = ({ user, onRemove }) => (
  <TeamMemberContainer>
    <UserMessage avatarSize={24} user={user} />
    {onRemove && (
      <DeleteOutlined
        onClick={() => {
          onRemove(user.token);
        }}
      />
    )}
  </TeamMemberContainer>
);
const TeamMemberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 0 12px 0 6px;
  height: 32px;
  font-size: 12px;
`;
