import { formatDate } from '@shared/formatDate';
import { IReflection, ReflectionState } from '@shared/reflections';
import { Header3, Text } from '@web/components/typography';
import { Tag } from 'antd';
import React from 'react';

export const ReflectionSharedDate: React.FC<{ reflection: IReflection }> = ({
  reflection,
}) => {
  if (reflection.state === ReflectionState.SHARED) {
    return (
      <Header3 style={{ whiteSpace: 'nowrap', color: '#888' }}>
        {formatDate(reflection.sharedDate)}
      </Header3>
    );
  }
  const isDraft = reflection.state === ReflectionState.DRAFT;
  return (
    <Tag style={{ marginRight: 0, padding: '0px 12px' }}>
      <Text>{isDraft ? 'Draft' : 'Not Shared'}</Text>
    </Tag>
  );
};
