import { IOrganizationMember, UserToken } from '@shared/types';
import { Column, Row, WrapRow } from '@web/components/layout';
import { TeamMember } from '@web/components/teams/TeamMember';
import { Header2, Text } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Widget } from './Widget';

export const TeamWidget: React.FC<{
  managerToken: UserToken;
  reports: IOrganizationMember[];
}> = ({ managerToken, reports }) => {
  return (
    <Column gap={12}>
      <Row>
        <Link to={`/team/${managerToken}/members`}>
          <Header2>Team</Header2>
        </Link>
      </Row>
      {!reports ? (
        <Skeleton />
      ) : reports.length > 0 ? (
        <Widget>
          <WrapRow gap={6}>
            {reports.map((report) => (
              <Link key={report.token} to={`/profile/${report.token}`}>
                <TeamMember user={report} />
              </Link>
            ))}
          </WrapRow>
        </Widget>
      ) : (
        <Text>You have reports</Text>
      )}
    </Column>
  );
};
