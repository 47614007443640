import { PeerReviewToken } from '@shared/review-cycles';
import { OrganizationToken, UserMapItem } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { Button, ButtonProps } from 'antd';
import React, { useState } from 'react';

import { SelectPeersModal } from './SelectPeersModal';

interface Props {
  organizationToken: OrganizationToken;
  peerReviewToken: PeerReviewToken;
  user?: UserMapItem;
  onChange?: () => void;
  style?: React.CSSProperties;
  type?: ButtonProps['type'];
  size?: ButtonProps['size'];
}

export const SelectPeersButton: React.FC<Props> = ({
  organizationToken,
  peerReviewToken,
  onChange,
  user,
  style,
  size,
  type,
}) => {
  const { isSuper } = useAuth();
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    onChange?.();
    setOpen(false);
  };

  return (
    <>
      <Button
        type={type}
        size={size}
        onClick={showModal}
        disabled={isSuper && organizationToken === null}
        style={style}
      >
        Select peers
      </Button>
      {open && (
        <SelectPeersModal
          organizationToken={organizationToken}
          peerReviewToken={peerReviewToken}
          onSave={handleSave}
          onCancel={handleCancel}
          user={user}
        />
      )}
    </>
  );
};
