import { ReadOutlined } from '@ant-design/icons';
import { formatDate } from '@shared/formatDate';
import { IImpact } from '@shared/impacts';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { SubduedText, Text } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const JournalEntryContextList: React.FC<{ entries: IImpact[] }> = ({
  entries,
}) => {
  if (entries.length === 0) {
    return <SubduedText>No journal entries</SubduedText>;
  }
  return (
    <Column gap={12}>
      {entries.map((entry) => (
        <Row key={entry.token} gap={12} style={{ alignItems: 'flex-start' }}>
          <ReadOutlined style={{ marginTop: 2 }} />
          <JournalLink to={`/journal/${entry.token}`}>
            <Text style={{ lineHeight: '18px' }}>{entry.title}</Text>
          </JournalLink>
          <GrowingSpacer />
          <Text style={{ width: 80, textAlign: 'right', lineHeight: '18px' }}>
            {formatDate(entry.date)}
          </Text>
        </Row>
      ))}
    </Column>
  );
  // return ;
};

const JournalLink = styled(Link)`
  display: flex;
  flex-direction: column;
  :hover {
    text-decoration: underline;
  }
`;
