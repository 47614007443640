import { ITasksResponse, TaskFilter } from '@shared/types';
import { useApi } from '@web/common/useApi';
import { useLocalStorage } from '@web/common/useLocalStorage';
import { useSearchParams } from 'react-router-dom';

export const useAssignedTasks = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [defaultAssignedTaskFilter, setDefaultAssignedTasksFilter] =
    useLocalStorage('lastAssignedTasksFilter', TaskFilter.ALL);
  const filter: TaskFilter =
    (searchParams.get('filter') as any) ??
    defaultAssignedTaskFilter ??
    TaskFilter.ALL;
  const {
    data: response,
    mutate: reloadTasks,
    error,
  } = useApi<ITasksResponse>(filter ? `/tasks?filter=${filter}` : '/tasks');

  const setFilter = (value: TaskFilter) => {
    setDefaultAssignedTasksFilter(value);
    setSearchParams((prev) => {
      prev.set('filter', value);
      return prev;
    });
  };

  const clearFilter = () => {
    setFilter(TaskFilter.ALL);
  };

  const { tasks, userMap } = response ?? {};

  return {
    tasks,
    userMap,
    reloadTasks,
    isLoading: !response && !error,
    error,
    filter,
    setFilter,
    clearFilter,
  };
};
