import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { TabLink, Tabs } from '@web/components/Tabs';
import { Column, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { MetricsOverview } from './MetricsOverview';
import { SelectMetrics, useEnabledMetrics } from './SelectMetrics';
import { SelectTeam, useSelectedTeam } from './SelectTeam';
import { TEAM_PAGE_PARAM, TeamMetricsTable } from './TeamMetricsTable';
import { USER_PAGE_PARAM, UserMetricsTable } from './UserMetricsTable';

export enum AnalyticsTab {
  OVERVIEW = 'OVERVIEW',
  TEAMS = 'TEAMS',
  INDIVIDUALS = 'INDIVIDUALS',
}

export const AnalyticsPage: React.FC<{ tab: AnalyticsTab }> = ({ tab }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { teamToken, isLoadingTeams } = useSelectedTeam();
  React.useEffect(() => {
    setSearchParams((prev) => {
      prev.delete(USER_PAGE_PARAM);
      prev.delete(TEAM_PAGE_PARAM);
      return prev;
    });
  }, [teamToken]);
  const { metricTypesToShow } = useEnabledMetrics();
  if (isLoadingTeams) {
    return (
      <PageContent>
        <PageHeader title="Analytics" />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <PageHeader title="Analytics" />
      <Pane>
        <Column gap={12} style={{ width: '100%', maxWidth: 800 }}>
          <Column style={{ justifyContent: 'stretch' }}>
            <Text>Team</Text>
            <SelectTeam />
          </Column>
          <Column>
            <Text>Metrics</Text>
            <SelectMetrics />
          </Column>
        </Column>
      </Pane>
      <Spacer />
      <Tabs>
        <TabLink to={`/analytics/overview?${searchParams.toString()}`}>
          Overview
        </TabLink>
        <TabLink to={`/analytics/teams?${searchParams.toString()}`}>
          Teams
        </TabLink>
        <TabLink to={`/analytics/individuals?${searchParams.toString()}`}>
          People
        </TabLink>
      </Tabs>
      <Pane>
        <Spacer size={12} />
        {tab === AnalyticsTab.OVERVIEW && (
          <MetricsOverview
            teamToken={teamToken}
            metricTypes={metricTypesToShow}
          />
        )}
        {tab === AnalyticsTab.TEAMS && (
          <TeamMetricsTable
            teamToken={teamToken}
            metricTypes={metricTypesToShow}
          />
        )}
        {tab === AnalyticsTab.INDIVIDUALS && (
          <UserMetricsTable
            teamToken={teamToken}
            metricTypes={metricTypesToShow}
          />
        )}
      </Pane>
    </PageContent>
  );
};
