import useSWR from 'swr';

import { get } from './api';

export const useApi = <TResponse = any, TError = any>(path: string | null) =>
  useSWR<TResponse, TError>(path, fetcher);

export function useDefault<T>(data?: Partial<T>): {
  data: T;
  mutate: () => void;
} {
  return { data, mutate: () => {} } as any;
}
async function fetcher(url: string): Promise<any> {
  const responseBody = await get<any>(url);

  if (responseBody.statusCode >= 300) {
    throw responseBody;
  }

  return responseBody;
}
