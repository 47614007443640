import { useFindOneGoal } from '@client/GoalsClient';
import { IAgendaItem } from '@shared/one-on-one';
import { Pane } from '@web/components/Pane';
import { GoalCard } from '@web/goals/GoalCard';
import * as React from 'react';

export const GoalDetails: React.FC<{ agendaItem: IAgendaItem }> = ({
  agendaItem,
}) => {
  const goalToken =
    agendaItem.goals.length > 0 ? agendaItem.goals[0].token : null;
  if (!goalToken) {
    return null;
  }

  const { data: goalResponse } = useFindOneGoal(goalToken);

  return goalToken ? (
    <Pane>
      {goalResponse?.goal ? <GoalCard goal={goalResponse.goal} /> : null}
    </Pane>
  ) : null;
};
