import { endOneOnOne } from '@client/OneOnOnesClient';
import { OneOnOneToken } from '@shared/one-on-one';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Button, message } from 'antd';
import { ButtonProps } from 'antd/lib';
import * as React from 'react';

export const CompleteButton: React.FC<{
  oneOnOneToken: OneOnOneToken;
  onComplete?: () => void;
  type?: ButtonProps['type'];
}> = ({ oneOnOneToken, onComplete, type }) => {
  const { confirm, contextHolder } = useModalConfirm();

  const handleCompleteClicked = async () => {
    const confirmed = await confirm(
      'This will create your next 1-on-1 and copy over any unresolved items. You will still be able to view and update this 1-on-1. Do you want to continue?',
      { title: 'Complete 1-on-1?' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await endOneOnOne(oneOnOneToken);
      void message.success('Success');
      onComplete?.();
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <>
      <Button type={type} onClick={handleCompleteClicked}>
        Complete this 1-on-1
      </Button>
      {contextHolder}
    </>
  );
};
