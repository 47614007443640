import { OrganizationToken, UserToken } from './types';

export enum NotificationType {
  SANDBOX_INVITE = 'SANDBOX_INVITE',
  FEEDBACK_REQUESTED = 'FEEDBACK_REQUESTED',
  FEEDBACK_REQUESTED_REMINDER = 'FEEDBACK_REQUESTED_REMINDER',
  CYCLE_FEEDBACK_REQUESTED_OVERDUE = 'CYCLE_FEEDBACK_REQUESTED_OVERDUE',
  FEEDBACK_RECEIVED = 'FEEDBACK_RECEIVED',
  MANAGER_FEEDBACK_RECEIVED = 'MANAGER_FEEDBACK_RECEIVED',
  IMPACT_ENTRY_CREATE_REMINDER = 'IMPACT_ENTRY_CREATE_REMINDER',
  IMPACT_ENTRY_CREATED_MANAGER = 'IMPACT_ENTRY_CREATED_MANAGER',
  IMPACT_ENTRY_FEEDBACK_REQUESTED = 'IMPACT_ENTRY_FEEDBACK_REQUESTED',
  IMPACT_ENTRY_FEEDBACK_REQUESTED_REMINDER = 'IMPACT_ENTRY_FEEDBACK_REQUESTED_REMINDER',
  IMPACT_ENTRY_COMMENT_RECEIVED = 'IMPACT_ENTRY_COMMENT_RECEIVED',
  IMPACT_ENTRY_COMMENT_RECEIVED_MANAGER = 'IMPACT_ENTRY_COMMENT_RECEIVED_MANAGER',
  IMPACT_ENTRY_FEEDBACK_RECEIVED = 'IMPACT_ENTRY_FEEDBACK_RECEIVED',
  IMPACT_ENTRY_FEEDBACK_RECEIVED_MANAGER = 'IMPACT_ENTRY_FEEDBACK_RECEIVED_MANAGER',
  IMPACT_ENTRY_MANAGER_INACTIVITY = 'IMPACT_ENTRY_MANAGER_INACTIVITY',
  IMPACT_ENTRY_MANAGER_WEEKLY_SUMMARY = 'IMPACT_ENTRY_MANAGER_WEEKLY_SUMMARY',
  GITHUB_SUGGESTED_ENTRY_READY = 'GITHUB_SUGGESTED_ENTRY_READY',
  SUGGESTED_ENTRY = 'SUGGESTED_ENTRY',
  MANAGER_REQUEST_FEEDBACK_RECEIVED_MANAGER = 'MANAGER_REQUEST_FEEDBACK_RECEIVED_MANAGER',
  REFLECTION_RECEIVED = 'REFLECTION_RECEIVED',
  REFLECTION_COMMENT_RECEIVED = 'REFLECTION_COMMENT_RECEIVED',
  SELF_REVIEW_COMMENT_RECEIVED = 'SELF_REVIEW_COMMENT_RECEIVED',
  MANAGER_REVIEW_COMMENT_RECEIVED = 'MANAGER_REVIEW_COMMENT_RECEIVED',
  REFLECTION_TASK_RECEIVED = 'REFLECTION_TASK_RECEIVED',
  REFLECTION_DUE_REMINDER = 'REFLECTION_DUE_REMINDER', // A `ReflectionConfiguration` powered due date reminder.
  MANAGER_REFLECTION_REMINDER = 'MANAGER_REFLECTION_REMINDER', // A cycle reflection reminder.
  MANAGER_REFLECTION_RELEASE_REMINDER = 'MANAGER_REFLECTION_RELEASE_REMINDER', // A cycle reflection reminder.
  SELF_REFLECTION_CREATED = 'SELF_REFLECTION_CREATED',
  SELF_REFLECTION_RECEIVED = 'SELF_REFLECTION_RECEIVED',
  SELF_REFLECTION_REMINDER = 'SELF_REFLECTION_REMINDER',
  REFLECTION_CREATED = 'REFLECTION_CREATED',
  PEER_REVIEW_CYCLE_CREATED = 'PEER_REVIEW_CYCLE_CREATED',
  PEER_REVIEW_CYCLE_REMINDER = 'PEER_REVIEW_CYCLE_REMINDER',
  REPORTS_APPROVAL_NEEDED = 'REPORTS_APPROVAL_NEEDED',
  REPORTS_APPROVAL_NEEDED_REMINDER = 'REPORTS_APPROVAL_NEEDED_REMINDER',
  REPORT_PEERS_SELECTED = 'REPORT_PEERS_SELECTED',
  REPORT_PEERS_SELECTED_REMINDER = 'REPORT_PEERS_SELECTED_REMINDER',
  REVIEW_CYCLE_SHARED = 'REVIEW_CYCLE_SHARED',
  REVIEW_CYCLE_STARTED = 'REVIEW_CYCLE_STARTED',
  SUGGEST_ENTRY = 'SUGGEST_ENTRY',
  SURVEY_STARTED = 'SURVEY_STARTED',
  SURVEY_REMINDER = 'SURVEY_REMINDER',
  SURVEY_ENDED_MANAGER = 'SURVEY_ENDED_MANAGER',
  SURVEY_ENDED = 'SURVEY_ENDED',
  FEEDBACK_WIZARD = 'FEEDBACK_WIZARD',
  CUSTOM_USER_DEFINED_MESSAGE = 'CUSTOM_USER_DEFINED_MESSAGE',
  GOAL_CREATED = 'GOAL_CREATED',
  GOAL_UPDATED = 'GOAL_UPDATED',
  GOAL_COMMENTED = 'GOAL_COMMENTED',
  GOAL_CLOSED = 'GOAL_CLOSED',
  BASIC_ONE_ON_ONE_REMINDER = 'BASIC_ONE_ON_ONE_REMINDER',
  GOALS_ACTIVITY = 'GOALS_ACTIVITY',
  ALIGNMENT_JOURNAL_WARNING = 'ALIGNMENT_JOURNAL_WARNING',
  ALIGNMENT_JOURNAL_MANAGER_WARNING = 'ALIGNMENT_JOURNAL_MANAGER_WARNING',
  ALIGNMENT_ONE_ON_ONE_WARNING = 'ALIGNMENT_ONE_ON_ONE_WARNING',
  ALIGNMENT_ONE_ON_ONE_MANAGER_WARNING = 'ALIGNMENT_ONE_ON_ONE_MANAGER_WARNING',
  ALIGNMENT_ENROLLED_MANAGER_INTRO = 'ALIGNMENT_ENROLLED_MANAGER_INTRO',
  ALIGNMENT_MANAGER_INTRO = 'ALIGNMENT_MANAGER_INTRO',
  ALIGNMENT_REPORT_INTRO = 'ALIGNMENT_REPORT_INTRO',
  FLINT_WELCOME = 'FLINT_WELCOME',
  FLINT_WELCOME_NO_MANAGER = 'FLINT_WELCOME_NO_MANAGER',
  SUMMARY_OF_WORK_PREVIEW = 'SUMMARY_OF_WORK_PREVIEW',
  FLINT_SCRIBE_WEEKLY_MANAGER_REPORT = 'FLINT_SCRIBE_WEEKLY_MANAGER_REPORT',
  FLINT_SCRIBE_WEEKLY_INDIVIDUAL_REPORT = 'FLINT_SCRIBE_WEEKLY_INDIVIDUAL_REPORT',
}

export enum NotificationTransport {
  SLACK = 'SLACK',
  EMAIL = 'EMAIL',
  NONE = 'NONE',
}

export enum NotificationStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  DEBUG_SENT = 'DEBUG_SENT',
  ERROR = 'ERROR',
  SKIP = 'SKIP',
}

export interface INotification {
  token: string;
  type: NotificationType;
  status: NotificationStatus;
  userToken?: UserToken;
  organizationToken: OrganizationToken;
  slackChannel?: string;
  transport: NotificationTransport;
  error?: string;
  content: string;
  resentFrom?: string;
  resentBy?: string;
  createdDate: Date;
  updatedDate: Date;
}

export interface INotificationTemplate {
  token: string;
  type: string;
  blocks: string;
  createdDate: Date;
  updatedDate: Date;
}
