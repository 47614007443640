import { EntityReactionSummaryMap } from '@shared/reactions';
import { UserMap } from '@shared/types';
import { createContext } from 'react';

export interface IReactionSummaryContextValue {
  reactionSummaryMap?: EntityReactionSummaryMap;
  userMap?: UserMap;
}

export const ReactionSummaryContext =
  createContext<IReactionSummaryContextValue>({});
