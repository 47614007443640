import { adminCreateUser } from '@client/UsersClient';
import { IUser, UserRole } from '@shared/types';
import { Form, Input, Modal, Select, message } from 'antd';
import React from 'react';

interface Props {
  onCancel: () => void;
  onAdd: (user: IUser) => void;
}

export const AdminAddUserModal: React.FC<Props> = ({ onAdd, onCancel }) => {
  const [form] = Form.useForm<IUser>();
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);
    let user: IUser;
    try {
      user = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      const savedUser = await adminCreateUser(user.name, user.email, user.role);
      void message.success('Success');
      form.resetFields();
      onAdd(savedUser);
    } catch (error) {
      void message.error(error.displayMessage ?? 'Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Add Person"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          role: UserRole.ENROLLED,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input disabled={isSaving} autoFocus />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email' },
          ]}
        >
          <Input disabled={isSaving} />
        </Form.Item>

        <Form.Item label="Role" name="role">
          <SelectRole disabled={isSaving} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const SelectRole: React.FC<{
  value?: UserRole;
  onChange?: (newValue: UserRole) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  return (
    <Select disabled={disabled} value={value} onChange={onChange}>
      <Select.Option value={UserRole.ENROLLED}>Employee</Select.Option>
      <Select.Option value={UserRole.HR_ADMIN}>Admin</Select.Option>
    </Select>
  );
};
