import { ITag, TagToken } from '@shared/tags';
import { Column, Row } from '@web/components/layout';
import Modal from 'antd/es/modal/Modal';
import * as React from 'react';
import styled from 'styled-components';

import { useTags } from './useTags';

interface SelectTagModalProps {
  onSelect: (tag: ITag) => void;
  onClose: () => void;
  open: boolean;
}
export const AddTagModal: React.FC<SelectTagModalProps> = ({
  onSelect,
  onClose,
  open,
}) => {
  const { groups, tagMap, isLoading } = useTags();
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const handleTagSelect = (tagToken: TagToken) => {
    if (isLoading) {
      return;
    }

    onSelect(tagMap.get(tagToken));
  };

  React.useEffect(() => {
    if (groups?.length > 0) {
      setSelectedGroup(0);
    }
  }, [groups]);

  const groupOptions = groups.map((group, index) => ({
    value: index,
    label: group.name,
  }));
  const tagOptions =
    selectedGroup === null || !groups
      ? []
      : groups[selectedGroup].tags.map((tag) => ({
          label: tag.label,
          value: tag.token,
        }));
  return (
    <Modal
      title="Add Tag"
      open={open}
      onCancel={onClose}
      width="800px"
      footer={null}
    >
      <Column gap={12}>
        <Row gap={12}>
          <ListSelect
            options={groupOptions}
            value={selectedGroup}
            onChange={(groupIndex) => {
              setSelectedGroup(groupIndex);
            }}
          />
          <ListSelect
            options={tagOptions}
            onChange={(tagToken) => {
              handleTagSelect(tagToken);
            }}
          />
        </Row>
      </Column>
    </Modal>
  );
};

interface IListSelectOption<TOptionValue> {
  value: TOptionValue;
  label: string;
}
interface IListSelectProps<TOptionValue> {
  options: Array<IListSelectOption<TOptionValue>>;
  value?: TOptionValue;
  onChange: (value: TOptionValue) => void;
}
const ListSelect = <TOptionValue,>({
  options,
  value,
  onChange,
}: IListSelectProps<TOptionValue>) => {
  const handleOptionSelect = (option: IListSelectOption<TOptionValue>) => {
    onChange(option.value);
  };

  return (
    <ListSelectContainer>
      {options.map((option) => (
        <ListSelectOption
          key={option.label}
          onClick={() => {
            handleOptionSelect(option);
          }}
          className={option.value === value ? 'selected' : ''}
        >
          {option.label}
        </ListSelectOption>
      ))}
    </ListSelectContainer>
  );
};

const ListSelectOption = styled.div`
  padding: 6px 12px;
  cursor: pointer;

  &.selected {
    background: var(--primary-color) !important;
    color: white !important;
  }
`;

const ListSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 240px;
  border: 1px solid #ccc;
  overflow: auto;
  flex: 1;
  outline: 0;

  option {
    padding: 6px 12px;
    cursor: pointer;

    &:checked {
      background: var(--primary-color) !important;
      color: white !important;
    }
  }

  &:focus {
    border: 1px solid #ccc;

    option:checked {
      background: var(--primary-color),
        linear-gradient(
          0deg,
          var(--primary-color) 0%,
          var(--primary-color) 100%
        );
      color: white !important;
    }
  }
`;
