import { createGoalList } from '@client/GoalsClient';
import { GoalToken, IGoalList } from '@shared/goals';
import { UserToken } from '@shared/types';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { Form, Input, Modal, message } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface Props {
  onCancel: () => void;
  onAdd: (goalList: IGoalList) => void;
  goals?: GoalToken[];
}

export const AddGoalListModal: React.FC<Props> = ({
  goals,
  onAdd,
  onCancel,
}) => {
  const [form] = Form.useForm<IGoalList>();
  const [isSaving, setIsSaving] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [followers, setFollowers] = React.useState<UserToken[]>([]);

  const handleSave = async () => {
    if (!title || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      const goalList = await createGoalList({
        title,
        goals,
        followers,
      });
      void message.success('Success');
      onAdd(goalList);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Add Goal List"
      open={true}
      onOk={() => {
        void handleSave();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Add"
    >
      <Column gap={12}>
        <FormElement>
          <Text>Title</Text>
          <Input
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            autoFocus
          />
        </FormElement>
        <FormElement>
          <Text>Followers</Text>
          <SelectUsers
            onChange={(newFollowers) => {
              setFollowers(newFollowers);
            }}
          />
        </FormElement>
      </Column>
    </Modal>
  );
};

const FormElement = styled(Column)`
  gap: 6px;
  max-width: 600px;
`;
