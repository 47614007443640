import { PlusOutlined } from '@ant-design/icons';
import { ITeam } from '@shared/types';
import { useApi } from '@web/common/useApi';
import { GrowingSpacer, Row } from '@web/components/layout';
import { TeamsTable } from '@web/components/teams/TeamsTable';
import { Text } from '@web/components/text';
import { Button } from 'antd';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AdminPageContent } from './AdminPageContent';

const AdminTeamsPage: React.FC = () => {
  const { data: teams, mutate: reloadTeams } = useApi<ITeam[]>('/teams');
  const navigate = useNavigate();

  const handleTeamClick = (team: ITeam) => {
    navigate(`/admin/teams/${team.token}`);
  };

  const handleTeamRemove = () => {
    void reloadTeams();
  };

  return (
    <AdminPageContent>
      <Row gap={6}>
        <Text>Teams in Flint should reflect your org chart.</Text>
        <GrowingSpacer />
        <Link to="/admin/teams/new">
          <Button type="primary" style={{ maxWidth: 200 }}>
            <PlusOutlined /> Add team
          </Button>
        </Link>
      </Row>
      <TeamsTable
        teams={teams}
        onTeamClick={handleTeamClick}
        onTeamRemove={handleTeamRemove}
      />
    </AdminPageContent>
  );
};

export default AdminTeamsPage;
