import { DownOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { FeedbackVisibility } from '@shared/types';
import { Dropdown, Select, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const VisibilityButton = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  padding: 0;
  width: auto;
  color: #888;
  line-height: 14px;
  margin-top: 3px;

  &:hover {
    border-bottom: 1px solid #333;
    cursor: pointer;
  }
`;
interface SelectVisibilityProps {
  userName?: string;
  value?: FeedbackVisibility;
  onChange?: (newValue: FeedbackVisibility) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  size?: 'narrow' | 'normal';
  options?: FeedbackVisibility[];
}
export const SelectVisibility: React.FC<SelectVisibilityProps> = ({
  userName = 'recipient',
  disabled,
  value,
  onChange,
  style,
  size = 'narrow',
  options,
}) => {
  const items = [
    {
      key: FeedbackVisibility.RECEIVER_MANAGER,
      label: (
        <Typography.Text
          onClick={() => {
            onChange?.(FeedbackVisibility.RECEIVER_MANAGER);
          }}
        >
          Visible to {userName} and their manager
        </Typography.Text>
      ),
    },
    {
      key: FeedbackVisibility.MANAGER_ONLY,
      label: (
        <Typography.Text
          onClick={() => {
            onChange?.(FeedbackVisibility.MANAGER_ONLY);
          }}
        >
          Visible to manager only
        </Typography.Text>
      ),
    },
    {
      key: FeedbackVisibility.RECEIVER_ONLY,
      label: (
        <Typography.Text
          onClick={() => {
            onChange?.(FeedbackVisibility.RECEIVER_ONLY);
          }}
        >
          Visible to {userName} only
        </Typography.Text>
      ),
    },
  ].filter((item) => !options || options.some((fv) => fv === item.key));

  if (size === 'narrow') {
    return (
      <Dropdown disabled={disabled} trigger={['click']} menu={{ items }}>
        <VisibilityButton
          style={
            disabled
              ? {
                  ...style,
                  borderBottom: '1px solid transparent',
                  cursor: 'initial',
                }
              : style
          }
        >
          <Typography.Text style={{ fontSize: 12 }}>
            <EyeInvisibleOutlined />{' '}
            {items.find((item) => item.key === value)?.label ?? items[0].label}
          </Typography.Text>
          {!disabled && <DownOutlined style={{ fontSize: 8 }} />}
        </VisibilityButton>
      </Dropdown>
    );
  }
  return (
    <Select disabled={disabled} onChange={onChange} defaultValue={value}>
      {items.map((item) => (
        <Select.Option key={item.key} value={item.key}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
};
