import { formatDate } from '@shared/formatDate';
import { IImpact } from '@shared/impacts';
import { useResponsive } from '@web/app/responsive';
import {
  Column,
  GrowingSpacer,
  NoWrap,
  Row,
  Spacer,
} from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { ReactionsSummary } from '@web/reactions/ReactionSummary';
import { Empty } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ImpactEntryCollaborators } from './ImpactEntryCollaborators';

interface Props {
  impacts: IImpact[];
}

export const JournalEntryList: React.FC<Props> = ({ impacts }) => {
  return (
    <Column gap={12}>
      {impacts.map((impact) => (
        <JournalEntryListItem key={impact.token} impact={impact} />
      ))}
      {impacts.length === 0 && (
        <Empty style={{ margin: 20 }} description="No entries created" />
      )}
    </Column>
  );
};

const JournalEntryListItem: React.FC<{
  impact: IImpact;
}> = ({ impact }) => {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <ImpactLink to={impact.token} style={{ padding: 12 }}>
        <Column gap={12}>
          <Header3 style={{ lineHeight: '22px' }}>
            {impact.title}&nbsp;&nbsp;
            <NoWrap style={{ display: 'inline-block' }}>
              <Text>{formatDate(impact.date)}</Text>
            </NoWrap>
          </Header3>
          <Row style={{ alignItems: 'flex-start', width: '100%' }}>
            <ReactionsSummary
              readonly
              entityToken={impact.token}
              style={{ flex: 1 }}
              placeholder={<GrowingSpacer />}
            />
            <Spacer size={12} />
            <ImpactEntryCollaborators collaborators={impact.collaborators} />
          </Row>
        </Column>
      </ImpactLink>
    );
  }

  return (
    <ImpactLink to={impact.token}>
      <Row style={{ alignItems: 'flex-start' }}>
        <Header3>{impact.title}</Header3>
        <GrowingSpacer min={10} />
        <Header3>{formatDate(impact.date)}</Header3>
      </Row>
      <Spacer size={6} />
      <ImpactEntryMetaInfo>
        <ReactionsSummary
          readonly
          entityToken={impact.token}
          style={{ flex: 1 }}
        />
        <Column>
          <ImpactEntryCollaborators
            collaborators={impact.collaborators}
            alignLeft={isMobile}
          />
        </Column>
      </ImpactEntryMetaInfo>
    </ImpactLink>
  );
};

const ImpactLink = styled(Link)`
  padding: 24px;
  border: 1px solid #eee;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
  border-radius: var(--default-border-radius);

  h5 {
    margin: 0;
  }

  .hide-on-hover {
    display: block;
  }

  .show-on-hover {
    display: none;
  }

  &:hover {
    background-color: #eaf8ff8a;

    .show-on-hover {
      display: block;
    }

    .hide-on-hover {
      display: none;
    }
  }
`;

const ImpactEntryMetaInfo = styled(Row)`
  gap: 6px;
  width: 100%;
  justify-content: flex-end;
`;
