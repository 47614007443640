import { $createListItemNode } from "@lexical/list";
const MdastListItemVisitor = {
  testNode: "listItem",
  visitNode({ mdastNode, actions }) {
    actions.addAndStepInto($createListItemNode(mdastNode.checked ?? void 0));
  }
};
export {
  MdastListItemVisitor
};
