import { formatDate } from '@shared/formatDate';
import { IGithubPull } from '@shared/github';
import { IGithubInstallResponse } from '@shared/integrations';
import {
  ISearchResults,
  OrganizationToken,
  UserMap,
  UserMapItem,
} from '@shared/types';
import { errorColor } from '@web/app/styles/ColorStyles';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { usePagination } from '@web/common/usePagination';
import { Pane } from '@web/components/Pane';
import { PullRequestShortItem } from '@web/components/PullRequestItem';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import * as React from 'react';

import { SeedPullRequestsButton } from './SeedPullRequestsButton';

interface IGithubPullSearchRequest {
  skip?: number;
  limit?: number;
  organizationToken?: OrganizationToken;
}

export const GithubPullTable: React.FC<{
  organizationToken: OrganizationToken;
}> = ({ organizationToken }) => {
  const [searchError, setSearchError] = React.useState<string>('Oooooops');
  const [searchResults, setSearchResults] =
    React.useState<ISearchResults<IGithubPull>>(null);
  const { pagination, page, pageSize } = usePagination(searchResults?.total);
  const { data: githubInstall } = useApi<IGithubInstallResponse>(
    `/github/${organizationToken}/installation`,
  );

  const searchPullRequests = async () => {
    setSearchError(null);
    try {
      const skip = (page - 1) * pageSize;
      const limit = pageSize;
      const results = await post<
        IGithubPullSearchRequest,
        ISearchResults<IGithubPull>
      >(`/github/pulls/search`, { limit, skip, organizationToken });
      setSearchResults(results);
    } catch (error) {
      setSearchError(error.message);
    }
  };

  React.useEffect(() => {
    void searchPullRequests();
  }, [organizationToken, page, pageSize]);

  const { results: notifications, userMap } = searchResults ?? {
    results: [],
    total: 0,
    userMap: {},
  };
  const userLoginMap = createUserLoginMap(userMap);
  const columns: ColumnsType<IGithubPull> = [
    {
      title: 'Pull Request',
      key: 'pullRequest',
      render: (_, pull) => <PullRequestShortItem pull={pull} />,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'User',
      dataIndex: 'userLogin',
      key: 'userLogin',
      render: (userLogin) =>
        userLoginMap[userLogin] ? (
          <Row gap={10}>
            <UserAvatar size={28} user={userLoginMap[userLogin]} />{' '}
            <Text>{userLoginMap[userLogin].name}</Text>
          </Row>
        ) : (
          userLogin
        ),
    },
    {
      title: 'Merged',
      dataIndex: 'mergedDate',
      key: 'mergedDate',
      render: (mergedDate) => formatDate(mergedDate, true),
    },
  ];
  return (
    <Pane>
      <Column gap={24}>
        <Row>
          {githubInstall?.installationId ? (
            <Text>Installation Id: {githubInstall?.installationId}</Text>
          ) : (
            <Text>Flint App is not installed on Github</Text>
          )}
          <GrowingSpacer />
          <SeedPullRequestsButton
            organizationToken={organizationToken}
            onChange={searchPullRequests}
            disabled={!githubInstall?.installationId}
          />
        </Row>
        <Column gap={12}>
          {searchError && (
            <Row
              gap={12}
              style={{
                padding: '12px 18px',
                background: errorColor.lighten(0.5),
                borderRadius: 'var(--default-border-radius)',
              }}
            >
              {searchError}
            </Row>
          )}
          <Table
            rowKey="token"
            columns={columns}
            dataSource={notifications}
            pagination={pagination}
          />
        </Column>
      </Column>
    </Pane>
  );
};

const createUserLoginMap = (userMap: UserMap = {}) => {
  const userLoginMap: Record<string, UserMapItem> = {};
  for (const user of Object.values(userMap)) {
    userLoginMap[user.githubLogin] = user;
  }
  return userLoginMap;
};
