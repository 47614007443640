import { Feature } from '@shared/features';
import { ListReflectionsResponse } from '@shared/reflections';
import { IUser, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Row } from '@web/components/layout';
import { Header1 } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router';

import { ReflectionItem } from './ReflectionItem';
import { ReflectionUserPicker } from './ReflectionUserPicker';

export const ViewReportsReflectionsPage: React.FC = () => {
  const { userToken } = useParams<{ userToken: UserToken }>();
  const { booleanValue: reflectionTagsEnabled } = useFeature(
    Feature.REFLECTION_TAGS_ENABLED,
  );
  const { data: reflectionUser, error: userError } = useApi<IUser>(
    `/users/${userToken}`,
  );
  const { data: response, error } = useApi<
    ListReflectionsResponse,
    ServerResponseError
  >(`/reflections?userToken=${userToken}`);

  if (userError) {
    return <ErrorPageContent statusCode={userError.statusCode} />;
  }

  const title = reflectionUser
    ? `Reflections for ${reflectionUser.name}`
    : 'Reflections';

  if (error) {
    return <ErrorPageContent statusCode={error.statusCode} />;
  } else if (!reflectionUser || !response?.reflections) {
    return (
      <PageContent>
        <PageHeader
          title={title}
          navigateBack
          defaultNavigateBackTo="/team/entries"
          mobileTitle={title}
        />
        <Skeleton />
      </PageContent>
    );
  }

  return (
    <PageContent>
      <PageHeader
        title={
          <Row gap={12} style={{ marginBottom: '8px' }}>
            <Header1
              style={{
                margin: 0,
                lineHeight: '40px',
              }}
            >
              {title}
            </Header1>
            <ReflectionUserPicker />
          </Row>
        }
        navigateBack
        defaultNavigateBackTo="/team/entries"
        mobileTitle={title}
        description={`Ideas, opportunities and thoughts about performance that you provide on their work. Reflections help build alignment between you and ${reflectionUser.name}.`}
        primaryAction={
          reflectionTagsEnabled && {
            label: 'Manage Tags',
            linkTo: `/tags`,
          }
        }
      />
      {response.reflections.length > 0 ? (
        <Pane>
          {response.reflections.map((reflection) => (
            <ReflectionItem key={reflection.token} reflection={reflection} />
          ))}
        </Pane>
      ) : undefined}
    </PageContent>
  );
};
