import { PlusOutlined } from '@ant-design/icons';
import { mapByProp } from '@shared/mapByToken';
import { UserMapItem, UserToken } from '@shared/types';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { Button, Skeleton, message } from 'antd';
import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { OneOnOneContext } from '../OneOnOneProvider';
import { NewTaskItem } from './NewTaskItem';
import { TaskItemRow } from './TaskItemRow';

export interface ITaskItem<IdType = string | number> {
  id: IdType;
  text: string;
  resolved: boolean;
  owner?: UserMapItem;
  assignableOwners?: UserMapItem[];
}

export interface ITaskService<IdType = string | number> {
  taskItems?: Array<ITaskItem<IdType>>;
  add: (text: string) => Promise<ITaskItem<IdType>>;
  resolve: (updatedItem: ITaskItem<IdType>, resolved: boolean) => Promise<void>;
  sort: (newlySortedItems: Array<ITaskItem<IdType>>) => Promise<void>;
  select: (id: IdType) => void;
  assign?: (
    updatedItem: ITaskItem<IdType>,
    assignedToToken: UserToken,
  ) => Promise<void>;
  unassign?: (updatedItem: ITaskItem<IdType>) => Promise<void>;
}

export const TaskList: React.FC<{
  title: string;
  taskService: ITaskService;
  otherUser?: UserMapItem;
}> = ({ taskService, title, otherUser }) => {
  const { userPresence } = React.useContext(OneOnOneContext);
  const { taskItems: initialTaskItems } = taskService;
  const [taskItems, setTaskItems] = React.useState<ITaskItem[]>(null);
  const [showAdd, setShowAdd] = React.useState(false);
  React.useEffect(() => {
    if (initialTaskItems) {
      setTaskItems(initialTaskItems);
    }
  }, [initialTaskItems]);

  if (!taskItems) {
    return (
      <Column gap={12}>
        <Header2>{title}</Header2>
        <Skeleton />
      </Column>
    );
  }

  const taskItemMap = mapByProp(taskItems, 'id');
  const handleAddClicked = async () => {
    setShowAdd(true);
  };
  const handleAdded = async (text: string) => {
    try {
      const taskItem = await taskService.add(text);
      setTaskItems([...taskItems, taskItem]);
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleResolved = async (updatedItem: ITaskItem, resolved: boolean) => {
    try {
      await taskService.resolve(updatedItem, resolved);
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleSortTaskItems = async (newlySortedItems: ITaskItem[]) => {
    if (hashIds(newlySortedItems) === hashIds(taskItems)) {
      return;
    }

    setTaskItems(newlySortedItems);
    try {
      await taskService.sort(newlySortedItems);
    } catch (error) {
      void message.error('Error');
    }
  };

  const hasTaskItems = taskItems.length > 0;
  const lastTaskItemToken = hasTaskItems
    ? taskItems[taskItems.length - 1].id
    : null;
  return (
    <Column>
      <Row gap={12}>
        <Header2>{title}</Header2>
        <Button type="text" size="small" onClick={handleAddClicked}>
          <PlusOutlined style={{ position: 'relative', top: -1 }} />
        </Button>
      </Row>
      <Spacer size={6} />
      <Column>
        {!hasTaskItems && !showAdd && <Text>No items</Text>}
        {hasTaskItems && (
          <ReactSortable
            list={taskItems}
            setList={handleSortTaskItems}
            easing="cubic-bezier(0.55, 0, 1, 0.45)"
            animation={100}
            handle=".drag-anchor"
          >
            {taskItems.map((taskItem) => (
              <TaskItemRow
                userViewing={
                  otherUser && userPresence[otherUser.token] === taskItem.id
                    ? otherUser
                    : undefined
                }
                key={taskItem.id}
                taskItem={taskItemMap.get(taskItem.id)}
                onResolve={handleResolved}
                onClick={() => {
                  taskService.select(taskItem.id);
                }}
                onAssign={(assignedToToken: UserToken | null) => {
                  void taskService.assign(taskItem, assignedToToken);
                }}
              />
            ))}
          </ReactSortable>
        )}
        {showAdd ? (
          <NewTaskItem
            key={`new-task-item-${lastTaskItemToken}`}
            onAdd={handleAdded}
            onHide={() => {
              setShowAdd(false);
            }}
          />
        ) : (
          <Spacer size={8} />
        )}
      </Column>
    </Column>
  );
};

const hashIds = (taskItems: ITaskItem[]) =>
  taskItems.map((item) => item.id).join(':');
