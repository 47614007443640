import { useSelectGoalsSearch } from '@client/GoalsClient';
import { GoalToken } from '@shared/goals';
import { Select } from 'antd';
import * as React from 'react';

export const SelectGoal: React.FC<{
  initialValue?: GoalToken;
  onChange: (goalToken: GoalToken) => void;
  omitValues?: GoalToken[];
  style?: React.CSSProperties;
}> = ({ initialValue, onChange, omitValues = [], style }) => {
  const { data: relevantGoals } = useSelectGoalsSearch();
  if (!relevantGoals) {
    return null;
  }

  const filteredGoals = relevantGoals.filter(
    (goal) => !omitValues.includes(goal.token),
  );
  return (
    <Select value={initialValue} onChange={onChange} style={style}>
      {filteredGoals.map((goal) => (
        <Select.Option value={goal.token} key={goal.token}>
          {goal.title}
        </Select.Option>
      ))}
    </Select>
  );
};
