import { EditOutlined } from '@ant-design/icons';
import { Feature } from '@shared/features';
import { GetReflectionResponse, IReflection } from '@shared/reflections';
import { isManagerOf } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { CollapsableSection } from '@web/comments/CollapsableSection';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { EditedLabel } from '@web/components/EditedLabel';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { Markdown } from '@web/components/Markdown';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header2, Header3, SubduedText } from '@web/components/typography';
import { ReactionSummaryContext } from '@web/reactions/ReactionSummaryContext';
import { Skeleton, Typography } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { JournalEntryContextList } from './JournalEntryContextList';
import { ReflectionResponseList } from './ReflectionResponseList';
import { ReflectionSharedDate } from './ReflectionSharedDate';
import { pageDescription, pageTitle } from './reflections';
import { TagList } from './tags/TagList';

export const ViewReflectionPage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const { booleanValue: reflectionTagsEnabled } = useFeature(
    Feature.REFLECTION_TAGS_ENABLED,
  );
  const { reflectionToken } = useParams();

  const { data: response, error } = useApi<
    GetReflectionResponse,
    ServerResponseError
  >(`/reflections/${reflectionToken}`);

  if (error) {
    return <ErrorPageContent statusCode={error.statusCode} />;
  } else if (!response?.reflection || !response?.questionSet) {
    return (
      <PageContent>
        <PageHeader />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }
  const reflection = response.reflection;
  const isCycleReview = !!reflection.reviewCycleToken;
  const isAuthor = reflection.authorToken === user.token;
  const isManager = isManagerOf(reflection.receiver, user.token);
  const isReceiver = reflection.receiver.token === user.token;
  const isSelfReview = reflection.receiverToken === reflection.authorToken;

  return (
    <ReactionSummaryContext.Provider value={response.reactions}>
      <PageContent>
        <PageHeader
          title={pageTitle(user, reflection)}
          mobileTitle={pageTitle(user, reflection)}
          description={pageDescription(reflection)}
          navigateBack
          defaultNavigateBackTo={
            reflection.reviewCycleToken
              ? `/cycles/${reflection.reviewCycleToken}`
              : '/reflections'
          }
          primaryAction={
            reflection.canEdit && reflection.author.token === user.token
              ? {
                  label: 'Edit',
                  icon: <EditOutlined />,
                  linkTo: `/reflections/${reflectionToken}/edit`,
                }
              : undefined
          }
        />
        <Column gap={12}>
          {reflection.includeWork && <SummaryOfWork reflection={reflection} />}
          {!isMobile && (
            <Header2 style={{ marginTop: 12 }}>
              {!isCycleReview ? 'Reflection ' : null}
              <EditedLabel
                entity={{
                  createdDate:
                    reflection.sharedDate ?? reflection.completedDate,
                  updatedDate: reflection.updatedDate,
                }}
              />
            </Header2>
          )}
          <Pane>
            <Section>
              <Row style={{ alignItems: 'flex-start' }}>
                <Column gap={24}>
                  {!isSelfReview && (
                    <UserMessage user={reflection.author} large />
                  )}
                  <ReflectionResponseList
                    questionSet={response.questionSet}
                    responses={reflection.responses}
                    receiverName={
                      isSelfReview ? undefined : reflection.receiver?.name
                    }
                    allowReply={isReceiver || isManager}
                  />
                </Column>
                <GrowingSpacer />
                {!isMobile && <ReflectionSharedDate reflection={reflection} />}
              </Row>
            </Section>
            {isAuthor && reflection.includePrivateNote ? (
              <Section>
                <CollapsableSection title="Private Notes" startExpanded>
                  {reflection.privateNote ? (
                    <Markdown value={reflection.privateNote} />
                  ) : (
                    <SubduedText>No private notes</SubduedText>
                  )}
                </CollapsableSection>
              </Section>
            ) : undefined}
            {reflectionTagsEnabled && reflection.tags.length > 0 ? (
              <Section>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Tags
                </Typography.Title>
                <Spacer size={2} />
                <TagList reflection={reflection} />
              </Section>
            ) : undefined}
          </Pane>
        </Column>
      </PageContent>
    </ReactionSummaryContext.Provider>
  );
};

const Section = styled(Column)`
  margin-bottom: 8px;
`;

export const SummaryOfWork: React.FC<{ reflection: IReflection }> = ({
  reflection,
}) => {
  return (
    <Pane>
      <Column gap={12}>
        <Column gap={12}>
          <Header2>Summary of Work</Header2>
          <JournalEntryContextList entries={reflection.accomplishments} />
        </Column>
        {reflection.textContext && (
          <Column>
            <Header3>Additional work</Header3>

            <Markdown value={reflection.textContext} />
          </Column>
        )}
      </Column>
    </Pane>
  );
};
