import { EditOutlined } from '@ant-design/icons';
import { CreateTagGroupRequest, CreateTagGroupResponse } from '@shared/tags';
import { PageContent } from '@web/app/Page';
import { post } from '@web/common/api';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Row, Spacer } from '@web/components/layout';
import { Divider, Modal, Skeleton, Typography } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { StyledTag } from './StyledTag';
import { TagGroupForm, TagGroupFormFields } from './TagGroupForm';
import { useTags } from './useTags';

export const ViewTagGroupsPage: React.FC = () => {
  const title = 'Tag Admin';

  const navigate = useNavigate();
  const { groups, isLoading } = useTags();
  const [open, setOpen] = useState(false);

  if (isLoading) {
    return (
      <PageContent>
        <PageHeader title={title} />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (fields: TagGroupFormFields) => {
    const request: CreateTagGroupRequest = {
      name: fields.name,
    };
    const response = await post<CreateTagGroupRequest, CreateTagGroupResponse>(
      `/tags/groups`,
      request,
    );
    navigate(`/tags/group/${response.group.token}`);
  };

  return (
    <PageContent>
      <PageHeader
        title={title}
        primaryAction={{ label: 'Add Tag Group', onClick: openModal }}
        navigateBack
      />
      <Pane>
        {groups.map((group) => (
          <Column key={group.token}>
            <Row>
              <Column>
                <Typography.Text style={{ fontWeight: 600 }}>
                  {group.name}
                </Typography.Text>
              </Column>
              <Spacer size={4} />
              <Column>
                <Link to={`/tags/group/${group.token}`}>
                  <EditButton />
                </Link>
              </Column>
            </Row>
            <Spacer size={4} />
            <Row>
              {group.tags.map((tag) => (
                <Column key={tag.token}>
                  <StyledTag tag={tag} />
                </Column>
              ))}
            </Row>
            <Divider />
          </Column>
        ))}
      </Pane>
      <Modal
        title="Create Tag Group"
        open={open}
        onCancel={handleClose}
        footer={null}
      >
        <TagGroupForm onSubmit={handleSave} onCancel={handleClose} />
      </Modal>
    </PageContent>
  );
};

const EditButton = styled(EditOutlined)`
  cursor: pointer;
`;
