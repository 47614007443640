import { PeerReviewToken } from '@shared/review-cycles';
import { OrganizationToken, UserMapItem } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib';
import React, { useState } from 'react';

import { ApprovePeersModal } from './ApprovePeersModal';

interface Props {
  organizationToken: OrganizationToken;
  peerReviewToken: PeerReviewToken;
  user?: UserMapItem;
  onChange?: () => void;
  type?: ButtonProps['type'];
  buttonText?: string;
  modalTitle?: string;
}

export const ApprovePeersButton: React.FC<Props> = ({
  organizationToken,
  peerReviewToken,
  onChange,
  user,
  type,
  buttonText = 'Approve peers',
  modalTitle,
}) => {
  const { isSuper } = useAuth();
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    onChange?.();
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        onClick={showModal}
        disabled={isSuper && organizationToken === null}
        type={type}
      >
        {buttonText}
      </Button>
      {open && (
        <ApprovePeersModal
          organizationToken={organizationToken}
          peerReviewToken={peerReviewToken}
          onSave={handleSave}
          onCancel={handleCancel}
          user={user}
          modalTitle={modalTitle}
        />
      )}
    </>
  );
};
