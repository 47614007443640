import { CheckOutlined } from '@ant-design/icons';
import { useLatestCheckin } from '@client/SurveyCyclesClient';
import { Feature } from '@shared/features';
import { formatDate } from '@shared/formatDate';
import { UserToken } from '@shared/types';
import { useFeature } from '@web/common/useFeature';
import { Column, Spacer, WrapRow } from '@web/components/layout';
import { Header2, SubduedText, Text } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const OneOnOneCheckIn: React.FC<{ userToken: UserToken }> = ({
  userToken,
}) => {
  const { booleanValue: checkinsEnabled } = useFeature(
    Feature.ENABLE_CHECK_INS,
  );
  if (!checkinsEnabled) {
    return null;
  }

  const { data } = useLatestCheckin(userToken);
  if (!data) {
    return null;
  }

  return (
    <Column>
      <Header2>Latest Check-In</Header2>
      <Spacer size={8} />
      {data.participant ? (
        <Link to={`/checkins/${data.participant.token}`}>
          <WrapRow key={data.participant.token}>
            <CheckmarkContainer>
              <CheckOutlined />
            </CheckmarkContainer>
            <Spacer size={14} />
            <Text style={{ lineHeight: '18px' }}>
              {data.participant.cycle.name}
            </Text>
            <Spacer size={6} />
            <SubduedText style={{ whiteSpace: 'nowrap', lineHeight: '18px' }}>
              {formatDate(data.participant.submittedDate)}
            </SubduedText>
          </WrapRow>
        </Link>
      ) : (
        'None submitted'
      )}
    </Column>
  );
};

const CheckmarkContainer = styled.div`
  width: 20px;
  height: 20px;
  background: var(--complimentary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
`;
