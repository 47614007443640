import * as amplitude from '@amplitude/analytics-browser';

export const initEventTracking = () => {
  amplitude.init('57b3fff0b6c5aa932e3a282bbc9517a3', undefined, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
};

export const trackEvent = (eventName: string) => {
  amplitude.track(eventName);
};
