import { DeleteOutlined } from '@ant-design/icons';
import { GrowingSpacer, Row } from '@web/components/layout';
import { Button } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const DrawerTitle: React.FC<{ onDelete: () => void }> = ({
  onDelete,
}) => {
  return (
    <DrawerTitleContainer gap={6} style={{ height: 38 }}>
      <GrowingSpacer />
      <Button type="text" onClick={onDelete}>
        <DeleteOutlined style={{ fontSize: 16 }} />
      </Button>
    </DrawerTitleContainer>
  );
};
const DrawerTitleContainer = styled(Row)`
  height: 38px;
  gap: 6px;

  .ant-btn svg {
    font-size: 15px;
  }
`;
