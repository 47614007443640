import { DeleteOutlined } from '@ant-design/icons';
import {
  CreateTagRequest,
  CreateTagResponse,
  GetTagGroupResponse,
  TagGroupToken,
  UpdateTagGroupRequest,
  UpdateTagGroupResponse,
} from '@shared/tags';
import { PageContent } from '@web/app/Page';
import { ServerResponseError, del, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ConfirmTitle } from '@web/components/ConfirmButton';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Button, Modal, Popconfirm, Skeleton, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TagForm, TagFormFields } from './TagForm';
import { TagGroupForm, TagGroupFormFields } from './TagGroupForm';
import { TagsTable } from './TagsTable';

export const ViewTagGroupPage: React.FC = () => {
  const title = 'Edit Tag Group';
  const defaultNavigateBackTo = `/tags`;

  const { token } = useParams<{ token: TagGroupToken }>();
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);

  const {
    data: response,
    error,
    mutate,
  } = useApi<GetTagGroupResponse, ServerResponseError>(`/tags/group/${token}`);
  if (error) {
    return <ErrorPageContent statusCode={error.statusCode} />;
  } else if (!response?.group) {
    return (
      <PageContent>
        <PageHeader
          navigateBack
          defaultNavigateBackTo={defaultNavigateBackTo}
          title={title}
        />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  const handleDelete = async () => {
    try {
      await del(`/tags/group/${token}`);
      void message.success('Success');
      navigate(`/tags`);
    } catch (error) {
      void message.error('Failed to delete tag group');
    }
  };

  const handleSubmit = async (fields: TagGroupFormFields) => {
    const request: UpdateTagGroupRequest = {
      name: fields.name,
    };
    const response = await put<UpdateTagGroupRequest, UpdateTagGroupResponse>(
      `/tags/group/${token}`,
      request,
    );
    await mutate({ group: response.group });
    void message.success('Success');
  };

  const handleMutate = async () => {
    await mutate();
  };

  const setCreating = () => {
    setIsCreating(true);
  };

  const handleCreateSave = async (fields: TagFormFields) => {
    const request: CreateTagRequest = {
      groupToken: response.group.token,
      name: fields.name,
      description: fields.description,
    };
    await post<CreateTagRequest, CreateTagResponse>(`/tags`, request);
    await mutate();
    setIsCreating(false);
  };

  const handleCancel = () => {
    setIsCreating(false);
  };

  return (
    <PageContent>
      <PageHeader
        navigateBack
        defaultNavigateBackTo={defaultNavigateBackTo}
        title={title}
        primaryAction={{ label: 'Delete Tag Group', onClick: handleDelete }}
      />
      <Pane>
        <TagGroupForm
          onSubmit={handleSubmit}
          initialTagGroup={response.group}
        />
      </Pane>
      <Pane style={{ marginTop: '16px' }}>
        <Row gap={6}>
          <GrowingSpacer />
          <Button onClick={setCreating}>Add New Tag</Button>
        </Row>
        <Spacer size={16} />
        <TagsTable onMutate={handleMutate} tags={response.group.tags} />
      </Pane>
      <Modal
        title="Create Tag"
        open={isCreating}
        footer={null}
        onCancel={handleCancel}
      >
        <TagForm onSave={handleCreateSave} onCancel={handleCancel} />
      </Modal>
    </PageContent>
  );
};

export const DeleteTagButton: React.FC<{ onConfirm: () => void }> = ({
  onConfirm,
}) => {
  return (
    <Popconfirm
      title={
        <ConfirmTitle
          title="Are you sure?"
          description="Deleting this tag will remove it from all reflections"
        />
      }
      onConfirm={onConfirm}
    >
      <DeleteOutlined style={{ cursor: 'pointer' }} />
    </Popconfirm>
  );
};
