import { generateToken } from '@backend/common/generateToken';

import { formatDate } from './formatDate';
import { GroupToken } from './groups';
import { IUser, OrganizationToken, UserToken } from './types';

export enum AlignmentState {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}
export const ALIGNMENT_STATE_NAMES: Record<AlignmentState, string> = {
  [AlignmentState.GREEN]: 'On Track',
  [AlignmentState.YELLOW]: 'At Risk',
  [AlignmentState.RED]: 'Off Track',
};

export type AlignmentIndicatorToken = `ali_${string}`;

export enum AlignmentType {
  ACTIVE_ONE_ON_ONES = 'ACTIVE_ONE_ON_ONES',
  ACTIVE_JOURNAL = 'ACTIVE_JOURNAL',
}

export const ALIGNMENT_INDICATOR_NAMES: Record<AlignmentType, string> = {
  [AlignmentType.ACTIVE_ONE_ON_ONES]: 'Regular 1-on-1s',
  [AlignmentType.ACTIVE_JOURNAL]: 'Accomplishments',
};
export const ALIGNMENT_DESCRIPTION_TEXT: Record<AlignmentType, string> = {
  [AlignmentType.ACTIVE_ONE_ON_ONES]:
    'This indicator ensures that you are having regular 1-on-1 meetings with your manager to stay aligned.',
  [AlignmentType.ACTIVE_JOURNAL]:
    "This indicator ensures that you have a record of the work you've completed and the impact you've made.",
};

export interface IBaseAlignmentIndicator {
  token: AlignmentIndicatorToken;
  userToken: UserToken;
  user: IUser;
  managerToken?: UserToken;
  type: AlignmentType;
  state: AlignmentState;
  lastEvaluatedDate: Date;
  data: IAlignmentIndicatorData;
}

export type IAlignmentIndicatorData =
  | undefined
  | IJournalAlignmentIndicatorData;

export interface IOneOnOneAlignmentIndicator extends IBaseAlignmentIndicator {
  type: AlignmentType.ACTIVE_ONE_ON_ONES;
}

export interface IJournalAlignmentIndicator extends IBaseAlignmentIndicator {
  type: AlignmentType.ACTIVE_JOURNAL;
  data: IJournalAlignmentIndicatorData;
}

export interface IJournalAlignmentIndicatorData {
  missingJournalDateRanges: Array<readonly [Date, Date]>;
}

export type IAlignmentIndicator =
  | IOneOnOneAlignmentIndicator
  | IJournalAlignmentIndicator;

export type HistoricalAlignmentIndicatorToken = `hai_${string}`;
export interface IHistoricalAlignmentIndicator
  extends Omit<IBaseAlignmentIndicator, 'token'> {
  token: HistoricalAlignmentIndicatorToken;
  alignmentIndicatorToken: AlignmentIndicatorToken;
}

export type AlignmentConfigurationToken = `ac_${string}`;
export interface IAlignmentConfiguration {
  token: AlignmentConfigurationToken;
  groupToken: GroupToken;
  organizationToken: OrganizationToken;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}
export const generateAlignmentConfigurationToken =
  generateToken<AlignmentConfigurationToken>('ac_');
export function isAlignmentConfigurationToken(
  token: string | undefined,
): token is AlignmentConfigurationToken {
  return !!token?.startsWith('ac_');
}

export type TeamAlignmentIndicators = Record<
  UserToken,
  {
    user: IUser;
    indicators: IAlignmentIndicator[];
  }
>;

export const createJournalEntryLink = (
  data: IJournalAlignmentIndicator['data'] | null,
): string => {
  if (!data) {
    return `/journal/new`;
  }
  const timestamp = suggestedJournalEntryTimestamp(data);

  return timestamp ? `/journal/new?date=${timestamp}` : `/journal/new`;
};

export const suggestJournalEntryLink = (
  indicator: IJournalAlignmentIndicator,
): string => {
  if (!indicator.data) {
    return `/team/${indicator.userToken}/journal/suggest`;
  }
  const timestamp = suggestedJournalEntryTimestamp(indicator.data);

  return timestamp
    ? `/team/${indicator.userToken}/journal/suggest?date=${timestamp}`
    : `/team/${indicator.userToken}/journal/suggest`;
};

export const suggestedJournalEntryTimestamp = (
  data: IJournalAlignmentIndicator['data'],
): number | null => {
  if (
    !data.missingJournalDateRanges ||
    data.missingJournalDateRanges.length === 0
  ) {
    return null;
  }
  const dateRange = data.missingJournalDateRanges[0];
  const startDate = new Date(dateRange[0]);
  const endDate = new Date(dateRange[1]);

  return (
    startDate.getTime() +
    Math.floor((endDate.getTime() - startDate.getTime()) / 2)
  );
};

export const ALIGNMENT_TYPE_ORDER = [
  AlignmentType.ACTIVE_ONE_ON_ONES,
  AlignmentType.ACTIVE_JOURNAL,
];
export const journalAlignmentCallToAction = (
  isReport: boolean,
  indicator: IJournalAlignmentIndicator,
) => {
  if (indicator.state === AlignmentState.GREEN) {
    return '';
  }

  const startDate = formatDate(indicator.data.missingJournalDateRanges[0][0]);
  const endDate = formatDate(indicator.data.missingJournalDateRanges[0][1]);

  if (isReport) {
    return `You don't have any Journal entries between ${startDate} and ${endDate}. Create one or more Journal entries covering your work during this time period.`;
  }
  return `${indicator.user.name} doesn't have any Journal entries between ${startDate} and ${endDate}. Suggest one or more Journal entries covering their work during this time period.`;
};
export const oneOnOneAlignmentCallToAction = (
  isCurrentUser: boolean,
  indicator: IOneOnOneAlignmentIndicator,
): string => {
  return isCurrentUser
    ? `Complete a 1-on-1 with your manager`
    : `Complete a 1-on-1 with ${indicator.user.name}`;
};

export type AlignmentEnrollmentToken = `ale_${string}`;

export const DEFAULT_JOURNAL_THRESHOLD_DAYS = 9;
export interface IAlignmentSettings {
  journalAlignmentEnabled: boolean;
  journalAlignmentThreshold?: number;
  oneOnOnesAlignmentEnabled: boolean;
}
