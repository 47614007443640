import { BedrockMechanism } from '@shared/bedrock';
import { Select } from 'antd';
import * as React from 'react';

interface MechanismOption {
  label: string;
  value: BedrockMechanism;
}

export const SelectMechanism: React.FC<{
  disabled: boolean;
  value?: BedrockMechanism;
  onChange: (mechanism: BedrockMechanism) => void;
}> = ({ disabled, value, onChange }) => {
  const options: MechanismOption[] = [
    {
      label: 'Journal Entries',
      value: 'journal',
    },
    {
      label: 'Direct Message Users',
      value: 'dm',
    },
  ];

  return (
    <Select
      disabled={disabled}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
