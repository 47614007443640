import { deleteEntityNote } from '@client/OneOnOnesClient';
import { IComment } from '@shared/comments';
import {
  ActionItemToken,
  AgendaItemToken,
  OneOnOneToken,
} from '@shared/one-on-one';
import { UserMapItem } from '@shared/types';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { message } from 'antd';
import { debounce } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { Column } from '../../components/layout';
import { NoteDateCluster, createDateClusters } from './createDateClusters';

const scrollDistanceFromBottom = (element: HTMLElement) => {
  const { scrollHeight, scrollTop, offsetHeight } = element;
  return scrollHeight - scrollTop - offsetHeight;
};

export interface IDateCluster {
  dateLabel: string;
  clusters: INoteCluster[];
}

export interface INoteCluster {
  user: UserMapItem;
  notes: IComment[];
}

export const NotesScroller: React.FC<{
  notes: IComment[];
  children?: React.ReactNode | React.ReactNode[];
  maxHeight: number | string;
  oneOnOneToken: OneOnOneToken;
  entityToken: AgendaItemToken | ActionItemToken;
}> = ({ notes, children, maxHeight, oneOnOneToken, entityToken }) => {
  const { confirm, contextHolder } = useModalConfirm();
  const [scrollLock, setScrollLock] = React.useState(true);
  const containerRef = React.useRef<HTMLDivElement>();
  const scrollToBottom = () => {
    if (containerRef.current && scrollLock) {
      const distance = scrollDistanceFromBottom(containerRef.current);
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: distance < 100 ? 'smooth' : 'instant',
      });
    }
  };
  React.useEffect(() => {
    scrollToBottom();
  }, [notes.length]);

  const updateScrollLock = debounce((newScrollLock: boolean) => {
    setScrollLock(newScrollLock);
  }, 100);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const distance = scrollDistanceFromBottom(e.currentTarget);
    if (distance < 24) {
      updateScrollLock(true);
    } else {
      updateScrollLock(false);
    }
  };
  const handleDeleteNote = async (token: string) => {
    const confirmed = await confirm('Do you want to remove this note?', {
      title: 'Delete Note',
    });
    if (!confirmed) {
      return;
    }

    try {
      await deleteEntityNote(oneOnOneToken, entityToken, token);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const dateClusters = createDateClusters(notes);
  return (
    <NotesScrollerContainer
      ref={containerRef}
      onScroll={handleScroll}
      style={{ maxHeight }}
    >
      {children}
      {dateClusters.map((dateCluster) => (
        <NoteDateCluster
          key={dateCluster.dateLabel}
          dateCluster={dateCluster}
          onDelete={handleDeleteNote}
          oneOnOneToken={oneOnOneToken}
          onScrollNeeded={scrollToBottom}
        />
      ))}
      {contextHolder}
    </NotesScrollerContainer>
  );
};

const NotesScrollerContainer = styled(Column)`
  gap: 12px;
  overflow: auto;
  margin: 0 -24px;
  padding: 0 24px;
`;
