import {
  IReviewCycleParticipantSummary,
  ReviewCycleToken,
} from '@shared/review-cycles';
import { UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { useLocalStorage } from '@web/common/useLocalStorage';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header2 } from '@web/components/typography';
import { TeamPicker } from '@web/team/TeamPicker';
import { Select, Skeleton } from 'antd';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReviewCycleDashboard } from './ReviewCycleDashboard';

enum ShowingOption {
  REPORTS_ONLY = 'REPORTS_ONLY',
  ALL = 'ALL',
}

export const ReviewCycleTeamPage: React.FC = () => {
  const navigate = useNavigate();
  const { reviewCycleToken, userToken } = useParams<{
    reviewCycleToken?: ReviewCycleToken;
    userToken?: UserToken;
  }>();
  const [cycleShowingFilter, setCycleShowingFilter] =
    useLocalStorage<ShowingOption>(
      'cycleShowingFilter',
      ShowingOption.REPORTS_ONLY,
    );

  const { data: reviewCycleSummary, mutate: reloadSummary } =
    useApi<IReviewCycleParticipantSummary>(
      `/review-cycles/${reviewCycleToken}/participants/${userToken}/summary`,
    );

  if (!reviewCycleSummary) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const handleDashboardChange = () => {
    void reloadSummary();
  };

  const handleTeamChange = (userToken: UserToken) => {
    navigate(`/cycles/${reviewCycleToken}/teams/${userToken}`);
  };

  const {
    reviewCycle,
    dashboardRows,
    participant: teamManager,
  } = reviewCycleSummary;
  const filteredRows =
    cycleShowingFilter === ShowingOption.ALL
      ? dashboardRows
      : dashboardRows.filter((row) => isManagerOf(row.participant, userToken));

  return (
    <PageContent>
      <PageHeader
        title={reviewCycle.name}
        mobileTitle={reviewCycle.name}
        navigateBack
      />
      <Column gap={24}>
        <Pane>
          <Row>
            <Header2>Team</Header2>
            <Spacer size={12} />
            <TeamPicker onChange={handleTeamChange} />
            <GrowingSpacer />
            <Select
              value={cycleShowingFilter}
              onChange={(newValue) => {
                setCycleShowingFilter(newValue);
              }}
              style={{ maxWidth: 240 }}
            >
              <Select.Option value={ShowingOption.ALL}>
                Show all team members
              </Select.Option>
              <Select.Option value={ShowingOption.REPORTS_ONLY}>
                Show direct reports only
              </Select.Option>
            </Select>
          </Row>
          <Spacer />
          <ReviewCycleDashboard
            dashboardRows={filteredRows}
            onChange={handleDashboardChange}
            reviewCycle={reviewCycle}
            emptyText={`No participants from ${teamManager.name}'${
              teamManager.name.endsWith('s') ? '' : 's'
            } team`}
          />
        </Pane>
      </Column>
    </PageContent>
  );
};
