import { IGoalList } from '@shared/goals';
import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { CollapsableSection } from '../CollapsableSection';
import { ViewGoalList } from './ViewGoalList';

export const ViewGoalLists: React.FC<{
  goalLists: IGoalList[];
  title: string;
  onRemove?: (goalList: IGoalList) => void;
  extra?: React.ReactNode;
}> = ({ extra, goalLists, title, onRemove }) => {
  if (isEmpty(goalLists)) {
    return null;
  }

  return (
    <CollapsableSection title={title} startExpanded extra={extra}>
      {goalLists.map((list) => (
        <React.Fragment key={`goal-${list.token}`}>
          <Divider style={{ margin: '8px 0' }} />
          <ViewGoalList
            goalList={list}
            onRemove={
              onRemove
                ? () => {
                    onRemove(list);
                  }
                : undefined
            }
          />
        </React.Fragment>
      ))}
    </CollapsableSection>
  );
};
