import { AlertOutlined } from '@ant-design/icons';
import { useAuth } from '@web/auth/useAuth';
import { useLocalStorage } from '@web/common/useLocalStorage';
import { Spacer } from '@web/components/layout';
import * as React from 'react';
import useKeypress from 'react-use-keypress';
import styled from 'styled-components';

import { useResponsive } from './responsive';

export const ImpersonationBanner: React.FC = () => {
  const { isMobile } = useResponsive();
  const { user, impersonator, unimpersonate, isLoading } = useAuth();
  const [showBanner, setShowBanner] = useLocalStorage(
    'showImpersonationBanner',
    true,
  );
  useKeypress(['Q', 'q'], (e) => {
    if (e.ctrlKey && e.shiftKey) {
      setShowBanner(!showBanner);
    }
  });

  const bottom = !impersonator || !showBanner ? '-30px' : 0;

  if (isLoading || !user || isMobile) {
    return null;
  }

  return (
    <Container style={{ bottom }}>
      <InnerContainer>
        <AlertOutlined />
        <Spacer size={5} />
        You are impersonating {user.name} -
        <Spacer size={5} />
        <a href="#" onClick={unimpersonate}>
          Unimpersonate
        </a>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: -30px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 0.1s linear;
`;

const InnerContainer = styled.div`
  background: red;
  width: auto;
  color: white;
  padding: 5px 20px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: underline;
    color: white;
  }
`;
