import { OrganizationToken, UserToken } from '@shared/types';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUser } from '@web/components/users/SelectUser';
import { Modal } from 'antd';
import * as React from 'react';
import { useState } from 'react';

export const EnrollUsersModal: React.FC<{
  organizationToken: OrganizationToken;
  omitUserTokens: UserToken[];
  onCancel: () => void;
  onEnroll: (userToken: UserToken) => Promise<void>;
}> = ({ organizationToken, omitUserTokens, onCancel, onEnroll }) => {
  const [enrollUser, setEnrollUser] = useState<UserToken | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);

    try {
      await onEnroll(enrollUser);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleUsersSelected = (userToken: UserToken) => {
    setEnrollUser(userToken);
  };

  return (
    <Modal
      title="Enroll Users"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Add"
    >
      <Column gap={12}>
        <Text>Select someone to enroll to bedrock.</Text>
        <SelectUser
          style={{ width: '100%' }}
          organizationToken={organizationToken}
          onChange={handleUsersSelected}
          omitUserTokens={omitUserTokens}
        />
      </Column>
    </Modal>
  );
};
