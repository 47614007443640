import {
  EntityReactionSummary,
  IReactionsResponse,
  createReactionSummary,
} from '@shared/reactions';
import { UserMap, UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { get, put } from '@web/common/api';
import * as React from 'react';
import styled from 'styled-components';

import { AddEmojiButton } from './AddEmojiButton';
import { Reaction } from './Reaction';
import { ReactionSummaryContext } from './ReactionSummaryContext';

export const ReactionsSummary: React.FC<{
  entityToken: string;
  readonly?: boolean;
  style?: React.CSSProperties;
  placeholder?: React.ReactNode;
  children?: React.ReactNode;
}> = ({ entityToken, readonly, style, children, placeholder = null }) => {
  const { user } = useAuth();
  const { reactionSummaryMap, userMap: contextUserMap } = React.useContext(
    ReactionSummaryContext,
  );
  const [reactionSummary, setReactionSummary] =
    React.useState<EntityReactionSummary>(
      reactionSummaryMap?.[entityToken] ?? null,
    );
  React.useEffect(() => {
    const reactionSummary = reactionSummaryMap?.[entityToken];
    setReactionSummary(reactionSummary ?? null);
  }, [reactionSummaryMap]);
  const [userMap, setUserMap] = React.useState<UserMap>(contextUserMap ?? {});

  const loadReactions = async () => {
    const response = await get<IReactionsResponse>(`/reactions/${entityToken}`);
    const reactionSummary = createReactionSummary(response.reactions);
    setReactionSummary(reactionSummary);
    setUserMap(response.userMap);
  };

  React.useEffect(() => {
    if (!reactionSummaryMap?.[entityToken]) {
      void loadReactions();
    }
  }, [entityToken, reactionSummaryMap]);

  if (reactionSummary === null) {
    return <>{placeholder}</>;
  }

  const handleToggleReaction = async (emoji) => {
    if (readonly) {
      return;
    }

    const newReactionSummary = toggleReaction(
      emoji,
      user.token,
      reactionSummary,
    );
    setReactionSummary(newReactionSummary);
    const ownReactions = getOwnReactions(newReactionSummary, user.token);
    void put(`/reactions/${entityToken}`, ownReactions);
  };

  const emojis = Object.keys(reactionSummary).filter(
    (emoji) => reactionSummary[emoji].length > 0,
  );

  if (readonly && emojis.length === 0) {
    return <>{placeholder}</>;
  }

  return (
    <ReactionSummaryContainer style={style}>
      {emojis.map((emoji) => (
        <Reaction
          emoji={emoji}
          key={emoji}
          userTokens={reactionSummary[emoji]}
          userMap={userMap}
          onClick={handleToggleReaction}
          readonly={readonly}
        />
      ))}
      {!readonly && <AddEmojiButton onAdd={handleToggleReaction} />}
      {children}
    </ReactionSummaryContainer>
  );
};

const ReactionSummaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const toggleReaction = (
  emoji: string,
  userToken: UserToken,
  reactionSummary: EntityReactionSummary,
): EntityReactionSummary => {
  let newUserTokens: UserToken[];
  const currentUserTokens = reactionSummary[emoji];
  if (!currentUserTokens) {
    newUserTokens = [userToken];
  } else if (currentUserTokens.includes(userToken)) {
    newUserTokens = currentUserTokens.filter((token) => token !== userToken);
  } else {
    newUserTokens = [...currentUserTokens, userToken];
  }

  return {
    ...reactionSummary,
    [emoji]: newUserTokens,
  };
};

const getOwnReactions = (
  reactionSummary: EntityReactionSummary,
  userToken: UserToken,
) => {
  const emojis: string[] = [];
  if (reactionSummary) {
    for (const emojiKey of Object.keys(reactionSummary)) {
      const userTokens = reactionSummary[emojiKey];
      if (userTokens.includes(userToken)) {
        emojis.push(emojiKey);
      }
    }
  }

  return emojis;
};
