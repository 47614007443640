import linkifyHtml from 'linkify-html';
import Quill from 'quill';
import randomstring from 'randomstring';
import * as React from 'react';
import styled from 'styled-components';

import { TypographyCss } from './typography';

export interface Props {
  value?: string;
  style?: React.CSSProperties;
}

export const RichText: React.FC<Props> = ({ value, style }) => {
  const elementId = React.useMemo(
    () => `quill_${randomstring.generate(8)}`,
    [],
  );
  const elementRef = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (elementRef.current) {
      const quill = new Quill(`#${elementId}`, {
        theme: 'snow',
        readOnly: true,
        modules: {
          toolbar: false,
        },
      });
      if (value) {
        const html = linkifyHtml(value);
        const delta = quill.clipboard.convert({ html });
        quill.setContents(delta, 'silent');
      }
    }
  }, [elementRef.current]);

  if (!value) {
    return null;
  }

  return (
    <Container>
      <div
        id={elementId}
        ref={elementRef}
        spellCheck={false}
        style={style}
      ></div>
    </Container>
  );
};

const Container = styled.div`
  > .ql-container {
    border: 0;
  }

  .ql-container .ql-editor {
    padding: 0;
    ${TypographyCss.Text}

    img {
      max-width: 100%;
    }

    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }
  }

  .ql-hidden {
    display: none;
  }
`;
