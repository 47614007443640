import { IUser } from '@shared/types';
import { Column, Row } from '@web/components/layout';
import { NotificationsTable } from '@web/notifications/NotificationsTable';
import { Typography } from 'antd';
import * as React from 'react';

export const UserNotifications: React.FC<{ user: IUser }> = ({ user }) => {
  return (
    <Column>
      <Row>
        <Typography.Title level={5} style={{ fontWeight: 400 }}>
          Notifications sent
        </Typography.Title>
      </Row>
      <NotificationsTable
        userToken={user.token}
        visibleColumns={['date', 'notification', 'action', 'status']}
        organizationToken={user.organizationToken}
      />
    </Column>
  );
};
