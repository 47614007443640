import { UserMapItem } from '@shared/types';
import { Center, Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Empty } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const EmptyGoals: React.FC<{ user?: UserMapItem }> = ({ user }) => {
  return (
    <Center>
      <Column gap={12} style={{ maxWidth: 500 }}>
        <Column>
          <Text style={{ fontStyle: 'italic' }}>
            “Setting goals is the first step in turning the invisible into the
            visible.”
          </Text>
          <Text style={{ textAlign: 'center' }}>- Tony Robbins</Text>
        </Column>
        <Empty
          description={
            <Column style={{ alignItems: 'center' }} gap={12}>
              {!user ? (
                <Text>You don{"'"}t have any goals yet</Text>
              ) : (
                <Text>
                  {user.name} does{"'"}t have any goals yet
                </Text>
              )}
              <Link to={user ? `/team/${user.token}/goals/new` : `/goals/new`}>
                <Button style={{ maxWidth: 200 }} type="primary">
                  Create one now
                </Button>
              </Link>
            </Column>
          }
        />
      </Column>
    </Center>
  );
};
