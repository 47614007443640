import { ITag, ListTagGroupsResponse, TagToken } from '@shared/tags';
import { useApi } from '@web/common/useApi';
import { useMemo } from 'react';

export const useTags = () => {
  const { data: response } = useApi<ListTagGroupsResponse>('/tags/groups');
  const isLoading = !response;
  const groups = response?.groups ?? [];
  const tags = useMemo(() => groups.flatMap((g) => g.tags), [groups]);
  const tagMap = useMemo(() => {
    const map = new Map<TagToken, ITag>();
    for (const tag of tags) {
      map.set(tag.token, tag);
    }
    return map;
  }, [tags]);

  return { groups, tags, tagMap, isLoading };
};
