import { ISuggestEntryRequest, ISuggestedEntry } from '@shared/impacts';
import { IUser, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { IF_MOBILE } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { MarkdownEditor } from '@web/components/MarkdownEditor';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Spacer } from '@web/components/layout';
import { SelectUser } from '@web/components/users/SelectUser';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { Button, DatePicker, Form, Input, Skeleton, message } from 'antd';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingDetails } from './LoadingDetails';
import { SuggestedEntryTour } from './SuggestedEntryTour';

interface SuggestEntryFields {
  title: string;
  description: string;
  date?: dayjs.Dayjs;
  collaboratorTokens: UserToken[];
  userToken: UserToken;
}

export const SuggestEntryPage: React.FC = () => {
  const navigateBack = useNavigateBack();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const completion = searchParams.get('completion');
  const date = searchParams.get('date');
  const { userToken } = useParams<{
    userToken?: UserToken;
  }>();
  const { data: suggestionUser } = useApi<IUser>(
    userToken ? `/users/${userToken}` : null,
  );
  const [generatingSuggestion, setGeneratingSuggestion] = React.useState(false);
  const { data: slackSuggestion, mutate: reloadSlackSuggestion } =
    useApi<ISuggestedEntry>(
      completion ? `/impacts/slack/suggestion/${completion}` : null,
    );
  React.useEffect(() => {
    if (slackSuggestion && !slackSuggestion.title) {
      setGeneratingSuggestion(true);
      setTimeout(() => {
        void reloadSlackSuggestion();
      }, 1500);
    }
  }, [slackSuggestion]);

  const [form] = Form.useForm<SuggestEntryFields>();
  const [isSaving, setIsSaving] = React.useState(false);
  const selectedUserToken = Form.useWatch('userToken', form);

  const isLoading =
    (userToken && !suggestionUser) || (completion && !slackSuggestion?.title);

  const handleNavigateBack = () => {
    const backToPath = suggestionUser
      ? `/team/${suggestionUser.token}/journal`
      : `/journal`;
    navigateBack(backToPath);
  };

  const handleSave = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    let impactFields: SuggestEntryFields;
    try {
      impactFields = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      const body: ISuggestEntryRequest = {
        suggestedEntry: {
          title: impactFields.title,
          date: impactFields.date.toDate(),
          description: impactFields.description,
        },
        userToken: suggestionUser?.token ?? impactFields.userToken,
        collaboratorTokens: impactFields.collaboratorTokens,
      };
      await post<ISuggestEntryRequest, ISuggestedEntry>(
        '/impacts/suggestions',
        body,
      );
      void message.success('Suggestion has been sent');
      form.resetFields();
      handleNavigateBack();
    } catch (error) {
      void message.error('Error');
      setIsSaving(false);
    }
  };

  let initialValues: Partial<SuggestEntryFields> = {
    collaboratorTokens: [user.token],
    date: dayjs(date ? new Date(parseInt(date)) : new Date()),
  };
  let initialCollaborators = [user];
  let initialUser;
  if (slackSuggestion) {
    const collaborators = slackSuggestion.collaborators
      ? uniqBy([...slackSuggestion.collaborators, user], 'token')
      : [user];
    initialUser =
      slackSuggestion.user && slackSuggestion.user.token !== user.token
        ? slackSuggestion.user
        : undefined;
    initialCollaborators = collaborators.filter(
      (collaborator) => collaborator.token !== slackSuggestion.user?.token,
    );
    initialValues = {
      title: slackSuggestion.title,
      date: dayjs(
        slackSuggestion.date
          ? new Date(slackSuggestion.date)
          : date
          ? new Date(parseInt(date))
          : new Date(),
      ),
      description: slackSuggestion.description,
      collaboratorTokens: initialCollaborators.map((user) => user.token),
      userToken: initialUser?.token,
    };
  }

  if (isLoading) {
    return (
      <PageContent>
        <PageHeader
          navigateBack
          title={'Suggest a journal entry'}
          mobileTitle={'Suggest a journal entry'}
        />
        <Pane>
          {generatingSuggestion ? (
            <LoadingDetails>Generating suggestion details...</LoadingDetails>
          ) : (
            <Skeleton />
          )}
        </Pane>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <PageHeader
        navigateBack
        title={'Suggest a journal entry'}
        mobileTitle={'Suggest a journal entry'}
      />
      <Pane>
        <Form
          form={form}
          name="basic"
          autoComplete="off"
          layout="vertical"
          initialValues={initialValues}
        >
          {suggestionUser ? (
            <UserMessage user={suggestionUser} style={{ marginBottom: 24 }} />
          ) : (
            <Form.Item
              label="Who would you like to send your suggestion to?"
              name="userToken"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <SelectUser
                omitUserTokens={[user.token]}
                initialUser={initialUser}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Suggest a title"
            name="title"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Input disabled={isSaving} />
          </Form.Item>
          <Form.Item label="Date" name="date">
            <DatePicker disabled={isSaving} />
          </Form.Item>
          <Form.Item
            label="Optionally, suggest a description"
            name="description"
          >
            <MarkdownEditor />
          </Form.Item>
          <Form.Item
            label="Optionally, suggest who to share this entry with"
            name="collaboratorTokens"
            valuePropName="userTokens"
          >
            <SelectUsers
              initialUsers={initialCollaborators}
              placeholder="e.g. peers, collaborators and stakeholders"
              hideUsers={[suggestionUser?.token ?? selectedUserToken]}
            />
          </Form.Item>
          <FormButtons>
            <Button
              type="primary"
              disabled={isSaving}
              onClick={() => {
                void handleSave();
              }}
            >
              Send suggestion
            </Button>
            <Button disabled={isSaving} onClick={handleNavigateBack}>
              Cancel
            </Button>
          </FormButtons>
          <Spacer size={30} />
        </Form>
      </Pane>
      {completion && <SuggestedEntryTour />}
    </PageContent>
  );
};

const FormButtons = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;

  ${IF_MOBILE} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
