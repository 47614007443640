import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageActions } from '@web/app/PageActions';
import { IPageAction, PageContext } from '@web/app/PageContext';
import { useResponsive } from '@web/app/responsive';
import { Header1, Text } from '@web/components/typography';
import React from 'react';
import styled from 'styled-components';

import { useBackNavigation } from '../common/useBackNavigation';
import { Column, GrowingSpacer, Row } from './layout';

export const PageHeader: React.FC<{
  title?: React.ReactNode;
  mobileTitle?: string;
  description?: React.ReactNode;
  navigateBack?: boolean;
  defaultNavigateBackTo?: string;
  primaryAction?: IPageAction;
  secondaryActions?: Array<IPageAction | null>;
}> = ({
  title,
  mobileTitle,
  description,
  navigateBack,
  defaultNavigateBackTo,
  primaryAction,
  secondaryActions,
}) => {
  const { setPageDetails } = React.useContext(PageContext);
  const { isMobile } = useResponsive();

  React.useLayoutEffect(() => {
    setPageDetails({
      title: mobileTitle,
      navigateBack,
      defaultNavigateBackTo,
      primaryAction,
      secondaryActions,
    });
  }, [mobileTitle, navigateBack]);

  const { goBack } = useBackNavigation(
    navigateBack ?? false,
    defaultNavigateBackTo,
  );

  const nonNullSecondaryActions: IPageAction[] | undefined =
    secondaryActions?.filter((action) => !!action);
  const showHeader =
    !!goBack || !!primaryAction || !!nonNullSecondaryActions || !!title;

  if (isMobile || !showHeader) {
    return null;
  }

  return (
    <Row style={{ alignItems: 'flex-start', marginBottom: 20, flex: 1 }}>
      {goBack && (
        <BackButton onClick={goBack}>
          <ArrowLeftOutlined />
        </BackButton>
      )}
      <Column style={{ width: '100%' }}>
        <Row>
          {title && (
            <Header1
              id="page-title"
              style={{
                margin: 0,
                lineHeight: '40px',
              }}
            >
              {title}
            </Header1>
          )}
          <GrowingSpacer min={10} />
          <PageActions
            primaryAction={primaryAction}
            secondaryActions={nonNullSecondaryActions}
          />
        </Row>
        {description && (
          <Text style={{ marginTop: 0, marginLeft: 2 }}>{description}</Text>
        )}
      </Column>
    </Row>
  );
};

export const BackButton = styled.button`
  appearance: none;
  height: 40px;
  width: 24px;
  padding: 0;
  font-size: 14px;
  color: var(--color-text);
  border: 0;
  background: transparent;
  cursor: pointer;
  text-align: left;
`;
