import { DashboardRowFilter, IReviewCycle } from '@shared/review-cycles';
import { Row } from '@web/components/layout';
import { Button, Select } from 'antd';
import * as React from 'react';

const NO_FILTER = 'NO_FILTER';

export const SelectParticipantFilter: React.FC<{
  reviewCycle: IReviewCycle;
  filter: DashboardRowFilter;
  setFilter: (value: DashboardRowFilter) => void;
  clearFilter: () => void;
}> = ({ reviewCycle, filter, setFilter, clearFilter }) => {
  const handleFilterChange = (filterValue: string) => {
    if (filterValue === NO_FILTER) {
      clearFilter();
    } else {
      setFilter(filterValue as any);
    }
  };

  const rawLabels: Array<readonly [DashboardRowFilter, string] | undefined> = [
    reviewCycle.peerReviewCycleEnabled
      ? [
          DashboardRowFilter.MISSING_SELECTED_PEERS,
          'Participants missing selected peers',
        ]
      : undefined,
    reviewCycle.peerReviewCycleEnabled
      ? [
          DashboardRowFilter.MISSING_APPROVED_PEERS,
          'Participants with unapproved peers',
        ]
      : undefined,
    reviewCycle.peerReviewCycleEnabled
      ? [DashboardRowFilter.MISSING_FEEDBACK, 'Participants missing feedback']
      : undefined,
    reviewCycle.selfReflectionCycleEnabled
      ? [
          DashboardRowFilter.MISSING_SELF_REVIEW,
          'Participants without a self review',
        ]
      : undefined,
    reviewCycle.managerReflectionCycleEnabled
      ? [
          DashboardRowFilter.MISSING_MANAGER_REVIEW,
          'Participants without a manager review',
        ]
      : undefined,
  ];
  const filterLabels = new Map<DashboardRowFilter, string>(
    rawLabels.filter((l) => !!l),
  );

  return (
    <Row gap={6}>
      <Select
        value={filter ?? NO_FILTER}
        style={{ width: '340px' }}
        onChange={handleFilterChange}
      >
        <Select.Option value={NO_FILTER}>Show all participants</Select.Option>
        {[...filterLabels.keys()].map((filterValue) => (
          <Select.Option value={filterValue} key={filterValue}>
            {filterLabels.get(filterValue)}
          </Select.Option>
        ))}
      </Select>
      <Button onClick={clearFilter}>Reset</Button>
    </Row>
  );
};
