import { IUser } from '@shared/types';
import { patch } from '@web/common/api';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Modal, Switch } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const BooleanField: React.FC<{
  label: string;
  property: keyof IUser;
  user: IUser;
  onChange: () => void;
}> = ({ label, property, user, onChange }) => {
  const [modal, contextHolder] = Modal.useModal();
  const [value, setValue] = React.useState(user[property] as any);

  const handleToggle = async () => {
    const proceed = await modal.confirm({
      icon: null,
      title: 'Are you sure?',
      content: `This will update this user's ${label.toLowerCase()} to ${!value}.`,
    });
    if (!proceed) {
      return;
    }

    await patch<IUser>(`/users/${user.token}`, {
      [property]: !value,
    });

    setValue(!value);
    onChange();
  };

  return (
    <BooleanFieldContainer>
      <Text style={{ width: 336 }}>{label}</Text>
      <Switch checked={value} onClick={handleToggle} />
      {contextHolder}
    </BooleanFieldContainer>
  );
};

const BooleanFieldContainer = styled(Row)`
  gap: 0;
  margin: 0;
  border-bottom: 1px solid #eee;
  padding: 12px;

  &:hover {
    background-color: #f9f9f9;
  }

  &:last-child {
    border-bottom: 0;
  }
`;
