import { GoalState } from '@shared/goals';
import { Column } from '@web/components/layout';
import { Dropdown } from 'antd';
import { MenuProps } from 'antd/lib';
import * as React from 'react';

import { GoalStateIndicator } from './GoalStateIndicator';

export const SelectState: React.FC<{
  state: GoalState;
  onChange: (newState: GoalState) => void;
  width?: number | string;
  disabled?: boolean;
}> = ({ state = GoalState.OPEN, onChange, width = 120, disabled }) => {
  if (disabled) {
    return (
      <Column style={{ width }}>
        <GoalStateIndicator state={state} />
      </Column>
    );
  }

  const items: MenuProps['items'] = Object.values(GoalState).map(
    (stateOption, index): MenuProps['items'][number] => ({
      key: index,
      label: <GoalStateIndicator state={stateOption} />,
      onClick: () => {
        onChange(stateOption);
      },
      style: state === stateOption ? { backgroundColor: '#f3f3f3' } : {},
    }),
  );

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      placement="bottom"
      arrow={{ pointAtCenter: true }}
    >
      <Column style={{ width, cursor: 'pointer' }}>
        <GoalStateIndicator state={state} />
      </Column>
    </Dropdown>
  );
};
