import { useAssignedGoals } from '@client/GoalsClient';
import { formatDate } from '@shared/formatDate';
import { IGoal } from '@shared/goals';
import { IUser } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header2, SubduedText, Text } from '@web/components/typography';
import { GoalStateIndicator } from '@web/goals/GoalStateIndicator';
import { Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Widget, WidgetRow } from './Widget';

export const GoalsWidget: React.FC<{
  user: IUser;
  extra?: React.ReactNode;
  useTeamLink?: boolean;
}> = ({ user, extra, useTeamLink }) => {
  const { user: currentUser } = useAuth();
  const { data: goals } = useAssignedGoals(user.token);
  const isReceiver = currentUser.token === user.token;

  return (
    <Column>
      <Link to={isReceiver ? '/goals' : `/team/${user.token}/goals`}>
        <Row gap={3}>
          <Header2>Goals</Header2> {extra}
        </Row>
      </Link>
      <Spacer size={6} />
      {!goals ? (
        <Skeleton />
      ) : goals.length > 0 ? (
        <Widget gap={6}>
          {goals.map((goal, index) => (
            <GoalsEntryWidgetRow
              hideBorder={index === 0}
              key={goal.token}
              goal={goal}
              useTeamLink={useTeamLink}
            />
          ))}
        </Widget>
      ) : (
        <Text>No active goals</Text>
      )}
    </Column>
  );
};
const GoalsEntryWidgetRow: React.FC<{
  goal: IGoal;
  hideBorder: boolean;
  useTeamLink?: boolean;
}> = ({ goal, hideBorder, useTeamLink = false }) => {
  return (
    <Link
      to={
        useTeamLink
          ? `/team/${goal.userToken}/goals/${goal.token}`
          : `/goals/${goal.token}`
      }
    >
      <GoalWidgetRow hideBorder={hideBorder} style={{ height: 'unset' }}>
        <GoalStateIndicator hideLabel state={goal.state} />
        <Text>
          <span style={{ marginRight: 6 }}>{goal.title}</span>{' '}
          {goal.dueDate && (
            <SubduedText
              style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
            >
              {formatDate(goal.dueDate)}
            </SubduedText>
          )}
        </Text>
      </GoalWidgetRow>
    </Link>
  );
};

const GoalWidgetRow = styled(WidgetRow)`
  height: unset;
  justify-content: flex-start;
  align-items: flex-start;
`;
