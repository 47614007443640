import { MenuOutlined } from '@ant-design/icons';
import {
  runCycleAgain,
  sendUpwardFeedbackRequestReminders,
  startUpwardReviewCycle,
} from '@client/ReviewCyclesClient';
import { Feature } from '@shared/features';
import { productDetails } from '@shared/reflections';
import {
  IReviewCycle,
  ReconcileManagerReviewsRequest,
  ReconcileManagerReviewsResponse,
  ReviewCycleToken,
  isReleasable,
} from '@shared/review-cycles';
import { useAuth } from '@web/auth/useAuth';
import { post, put } from '@web/common/api';
import { useFeature } from '@web/common/useFeature';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Column, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Dropdown, MenuProps, Modal, Skeleton, message } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const DashboardActionsMenu: React.FC<{
  reviewCycle: IReviewCycle;
  onChange: () => void;
}> = ({ reviewCycle, onChange }) => {
  const navigate = useNavigate();
  const { organization } = useAuth();
  const { booleanValue: reviewCycleClosingEnabled } = useFeature(
    Feature.ENABLE_REVIEW_CYCLE_CLOSING,
  );
  const { confirm, confirmThenDo, contextHolder } = useModalConfirm();

  const { isImpersonating } = useAuth();

  const hasParticipants = reviewCycle.participants?.length > 0;
  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );
  const selfReviewProductName = `self ${selfReviewProduct.lowerCase}`;
  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );
  const managerReviewProductName = `manager ${managerReviewProduct.lowerCase}`;

  const handleSendPeerFeedbackRequests = async () => {
    if (!(await confirm('Do you wish to send peer feedback requests?'))) {
      return;
    }

    try {
      await put(`/review-cycles/${reviewCycle.token}/peerFeedbackRequests`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendUpwardFeedbackRequests = async () => {
    if (!(await confirm('Do you wish to send upward feedback requests?'))) {
      return;
    }

    try {
      await put(`/review-cycles/${reviewCycle.token}/upwardFeedbackRequests`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleStartSelfReflectionCycle = async () => {
    if (!(await confirm(`Do you wish to start ${selfReviewProductName}s?`))) {
      return;
    }
    try {
      await put(`/review-cycles/${reviewCycle.token}/self-reflection-cycle`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleStartManagerReflectionCycle = async () => {
    if (
      !(await confirm(`Do you wish to start ${managerReviewProductName}s?`))
    ) {
      return;
    }

    try {
      await put(`/review-cycles/${reviewCycle.token}/manager-reflection-cycle`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendReleaseReviewReminder = async () => {
    if (!(await confirm('Do you wish to send release reminders?'))) {
      return;
    }

    try {
      await put(
        `/review-cycles/${reviewCycle.token}/send-release-reviews-reminders`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleCloseCycle = async () => {
    if (!(await confirm('Are you sure you want to close the cycle?'))) {
      return;
    }

    try {
      await post(`/review-cycles/${reviewCycle.token}/close`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handeRunCycleAgain = async () => {
    if (
      !(await confirm(
        reviewCycle.startedDate
          ? 'Are you sure you want to run this cycle again?'
          : 'Are you sure you want to duplicate this cycle?',
      ))
    ) {
      return;
    }

    try {
      const cycle = await runCycleAgain(reviewCycle.token);
      void navigate(`/admin/review-cycles/${cycle.token}/settings`);
      void message.success('A new cycle has been created');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const peerReviewItems =
    !reviewCycle.peerReviewCycleEnabled || !isImpersonating
      ? []
      : [
          {
            key: 'peer-review-cycle-items',
            label: 'Peer review',
            children: [
              {
                key: 'start-peer-review-cycle',
                label: <ItemLabel>Start peer review cycle</ItemLabel>,
                onClick: confirmThenDo(
                  'Do you wish to start the peer review cycle?',
                  async () => {
                    await put(
                      `/review-cycles/${reviewCycle.token}/peerReviewCycle`,
                    );
                    onChange();
                  },
                ),
                disabled:
                  !hasParticipants ||
                  !!reviewCycle.peerReviewCycleStarted ||
                  !reviewCycle.startedDate,
              },
              {
                key: 'send-peer-selection-reminders',
                label: <ItemLabel>Send peer selection reminders</ItemLabel>,
                onClick: confirmThenDo(
                  'Do you wish to send peer selection reminders?',
                  async () => {
                    await put(
                      `/review-cycles/${reviewCycle.token}/send-peer-selection-reminders`,
                    );
                  },
                ),
                disabled:
                  !hasParticipants || !reviewCycle.peerReviewCycleStarted,
              },
              {
                key: 'send-peer-approval-reminders',
                label: <ItemLabel>Send peer approval reminders</ItemLabel>,
                onClick: confirmThenDo(
                  'Do you wish to send peer approval reminders?',
                  async () => {
                    await put(
                      `/review-cycles/${reviewCycle.token}/send-peer-approval-reminders`,
                    );
                  },
                ),
                disabled:
                  !hasParticipants || !reviewCycle.peerReviewCycleStarted,
              },
              {
                key: 'send-peer-feedback-requests',
                label: <ItemLabel>Send peer feedback requests</ItemLabel>,
                onClick: handleSendPeerFeedbackRequests,
                disabled:
                  !reviewCycle.peerReviewCycleStarted ||
                  !!reviewCycle.peerReviewFeedbackRequested,
              },
              {
                key: 'send-peer-feedback-request-reminders',
                label: (
                  <ItemLabel>Send peer feedback request reminders</ItemLabel>
                ),
                onClick: confirmThenDo(
                  'Do you wish to send peer feedback request reminders?',
                  async () => {
                    await put(
                      `/review-cycles/${reviewCycle.token}/peer-feedback-request-reminders?timing=in-progress`,
                    );
                  },
                ),
                disabled: !reviewCycle.peerReviewFeedbackRequested,
              },
              {
                key: 'send-peer-feedback-request-due-reminders',
                label: (
                  <ItemLabel>
                    Send peer feedback request DUE reminders
                  </ItemLabel>
                ),
                onClick: confirmThenDo(
                  'Do you wish to send peer feedback request DUE reminders?',
                  async () => {
                    await put(
                      `/review-cycles/${reviewCycle.token}/peer-feedback-request-reminders?timing=due`,
                    );
                  },
                ),
                disabled: !reviewCycle.peerReviewFeedbackRequested,
              },
              {
                key: 'send-peer-feedback-request-overdue-reminders',
                label: (
                  <ItemLabel>
                    Send peer feedback request OVERDUE reminders
                  </ItemLabel>
                ),
                onClick: confirmThenDo(
                  'Do you wish to send peer feedback request OVERDUE reminders?',
                  async () => {
                    await put(
                      `/review-cycles/${reviewCycle.token}/peer-feedback-request-reminders?timing=overdue`,
                    );
                  },
                ),
                disabled: !reviewCycle.peerReviewFeedbackRequested,
              },
            ],
          },
        ];

  const upwardReviewItems =
    !reviewCycle.upwardReviewCycleEnabled || !isImpersonating
      ? []
      : [
          {
            key: 'upward-review-cycle-items',
            label: 'Upward feedback',
            children: [
              {
                key: 'start-upward-review-cycle',
                label: <ItemLabel>Start feedback cycle</ItemLabel>,
                onClick: confirmThenDo(
                  'Do you wish to start the upward feedback cycle?',
                  async () => {
                    await startUpwardReviewCycle(reviewCycle.token);
                    onChange();
                  },
                ),
                disabled:
                  !hasParticipants ||
                  !!reviewCycle.upwardReviewCycleStarted ||
                  !reviewCycle.startedDate,
              },
              {
                key: 'send-upward-feedback-requests',
                label: <ItemLabel>Send feedback requests</ItemLabel>,
                onClick: handleSendUpwardFeedbackRequests,
                disabled:
                  !reviewCycle.upwardReviewCycleStarted ||
                  !!reviewCycle.upwardReviewFeedbackRequested,
              },
              {
                key: 'send-upward-feedback-request-reminders',
                label: <ItemLabel>Send feedback request reminders</ItemLabel>,
                onClick: confirmThenDo(
                  'Do you wish to send feedback request reminders?',
                  async () => {
                    await sendUpwardFeedbackRequestReminders(
                      reviewCycle.token,
                      'in-progress',
                    );
                  },
                ),
                disabled: !reviewCycle.upwardReviewFeedbackRequested,
              },
              {
                key: 'send-upward-feedback-request-due-reminders',
                label: (
                  <ItemLabel>Send feedback request DUE reminders</ItemLabel>
                ),
                onClick: confirmThenDo(
                  'Do you wish to send feedback request DUE reminders?',
                  async () => {
                    await sendUpwardFeedbackRequestReminders(
                      reviewCycle.token,
                      'due',
                    );
                  },
                ),
                disabled: !reviewCycle.upwardReviewFeedbackRequested,
              },
              {
                key: 'send-upward-feedback-request-overdue-reminders',
                label: (
                  <ItemLabel>Send feedback request OVERDUE reminders</ItemLabel>
                ),
                onClick: confirmThenDo(
                  'Do you wish to send feedback request OVERDUE reminders?',
                  async () => {
                    await sendUpwardFeedbackRequestReminders(
                      reviewCycle.token,
                      'overdue',
                    );
                  },
                ),
                disabled: !reviewCycle.upwardReviewFeedbackRequested,
              },
            ],
          },
        ];

  const items: MenuProps['items'] = [
    !isImpersonating
      ? null
      : {
          key: 'manage-batch-notifications',
          label: (
            <Link to={`/admin/review-cycles/${reviewCycle.token}/schedule`}>
              <ItemLabel>Manage schedule</ItemLabel>
            </Link>
          ),
        },
    !isImpersonating
      ? null
      : {
          key: 'start-review-cycle',
          label: <ItemLabel>Start cycle</ItemLabel>,
          onClick: confirmThenDo(
            'Do you wish to start this cycle? This will not notify participants.',
            async () => {
              await put(`/review-cycles/${reviewCycle.token}/start`);
              onChange();
            },
          ),
          disabled:
            !hasParticipants ||
            !!reviewCycle.startedDate ||
            !organization.activationDate,
        },
    !isImpersonating
      ? null
      : {
          key: 'send-introduction',
          label: <ItemLabel>Send introduction message</ItemLabel>,
          onClick: confirmThenDo(
            'Do you wish to notify all participants that the cycle has started?',
            async () => {
              await put(
                `/review-cycles/${reviewCycle.token}/send-cycle-introduction`,
              );
              onChange();
            },
          ),
          disabled:
            !hasParticipants ||
            !reviewCycle.startedDate ||
            !organization.activationDate ||
            !!reviewCycle.closedDate,
        },
    ...peerReviewItems,
    ...upwardReviewItems,
    !reviewCycle.selfReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'send-self-reflections',
          label: <ItemLabel>Send {selfReviewProductName} requests</ItemLabel>,
          onClick: handleStartSelfReflectionCycle,
          disabled:
            !hasParticipants ||
            !!reviewCycle.selfReflectionCycleStarted ||
            !reviewCycle.startedDate ||
            !!reviewCycle.closedDate,
        },
    !reviewCycle.selfReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'send-self-reflection-reminder',
          label: <ItemLabel>Send {selfReviewProductName} reminder</ItemLabel>,
          onClick: confirmThenDo(
            `Do you wish to send ${selfReviewProductName} reminders?`,
            async () => {
              await put(
                `/review-cycles/${reviewCycle.token}/self-reflection-reminder?timing=in-progress`,
              );
            },
          ),
          disabled:
            !reviewCycle.selfReflectionCycleStarted || !!reviewCycle.closedDate,
        },
    !reviewCycle.selfReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'send-self-reflection-due-reminder',
          label: (
            <ItemLabel>Send {selfReviewProductName} DUE reminder</ItemLabel>
          ),
          onClick: confirmThenDo(
            `Do you wish to send ${selfReviewProductName} DUE reminders?`,
            async () => {
              await put(
                `/review-cycles/${reviewCycle.token}/self-reflection-reminder?timing=due`,
              );
            },
          ),
          disabled:
            !reviewCycle.selfReflectionCycleStarted || !!reviewCycle.closedDate,
        },
    !reviewCycle.selfReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'send-self-reflection-overdue-reminder',
          label: (
            <ItemLabel>Send {selfReviewProductName} OVERDUE reminder</ItemLabel>
          ),
          onClick: confirmThenDo(
            `Do you wish to send ${selfReviewProductName} OVERDUE reminders?`,
            async () => {
              await put(
                `/review-cycles/${reviewCycle.token}/self-reflection-reminder?timing=overdue`,
              );
            },
          ),
          disabled:
            !reviewCycle.selfReflectionCycleStarted || !!reviewCycle.closedDate,
        },
    !reviewCycle.managerReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'send-manager-reflections',
          label: (
            <ItemLabel>Send {managerReviewProductName} requests</ItemLabel>
          ),
          onClick: handleStartManagerReflectionCycle,
          disabled:
            !hasParticipants ||
            !!reviewCycle.managerReflectionCycleStarted ||
            !reviewCycle.startedDate ||
            !!reviewCycle.closedDate,
        },
    !reviewCycle.managerReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'send-manager-reflection-reminder',
          label: (
            <ItemLabel>Send {managerReviewProductName} reminder</ItemLabel>
          ),
          onClick: confirmThenDo(
            `Do you wish to send ${managerReviewProductName} reminders?`,
            async () => {
              await put(
                `/review-cycles/${reviewCycle.token}/manager-reflection-reminder?timing=in-progress`,
              );
            },
          ),
          disabled:
            !reviewCycle.managerReflectionCycleStarted ||
            !!reviewCycle.closedDate,
        },
    !reviewCycle.managerReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'send-manager-reflection-due-reminder',
          label: (
            <ItemLabel>Send {managerReviewProductName} DUE reminder</ItemLabel>
          ),
          onClick: confirmThenDo(
            `Do you wish to send ${managerReviewProductName} due reminders?`,
            async () => {
              await put(
                `/review-cycles/${reviewCycle.token}/manager-reflection-reminder?timing=due`,
              );
            },
          ),
          disabled:
            !reviewCycle.managerReflectionCycleStarted ||
            !!reviewCycle.closedDate,
        },
    !reviewCycle.managerReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'send-manager-reflection-overdue-reminder',
          label: (
            <ItemLabel>
              Send {managerReviewProductName} OVERDUE reminder
            </ItemLabel>
          ),
          onClick: confirmThenDo(
            `Do you wish to send ${managerReviewProductName} overdue reminders?`,
            async () => {
              await put(
                `/review-cycles/${reviewCycle.token}/manager-reflection-reminder?timing=overdue`,
              );
            },
          ),
          disabled:
            !reviewCycle.managerReflectionCycleStarted ||
            !!reviewCycle.closedDate,
        },
    (!reviewCycle.peerReviewCycleEnabled &&
      !reviewCycle.managerReflectionCycleEnabled) ||
    !isImpersonating
      ? null
      : {
          key: 'send-release-reviews-reminder',
          label: <ItemLabel>Send reminder to release reviews</ItemLabel>,
          onClick: handleSendReleaseReviewReminder,
          disabled: !isReleasable(reviewCycle) || !!reviewCycle.closedDate,
        },
    !reviewCycle.managerReflectionCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'reconcile-managers-button',
          label: (
            <ReconcileManagerReviewsButton
              reviewCycleToken={reviewCycle.token}
              disabled={
                !reviewCycle.managerReflectionCycleStarted ||
                !!reviewCycle.closedDate
              }
            />
          ),
          disabled:
            !reviewCycle.managerReflectionCycleStarted ||
            !!reviewCycle.closedDate,
        },
    reviewCycle.peerReviewCycleEnabled || !reviewCycleClosingEnabled
      ? null
      : {
          key: 'close-cycle',
          label: <ItemLabel>Close cycle</ItemLabel>,
          onClick: handleCloseCycle,
          disabled: !!reviewCycle.closedDate || !reviewCycle.startedDate,
        },
    {
      key: 'rerun',
      label: (
        <ItemLabel>
          {reviewCycle.startedDate ? 'Run cycle again' : 'Duplicate cycle'}
        </ItemLabel>
      ),
      onClick: handeRunCycleAgain,
    },
    {
      key: 'export',
      label: (
        <a
          href={`${process.env.API_ROOT ?? ''}/api/review-cycles/${
            reviewCycle.token
          }/export`}
          target="_blank"
          rel="noreferrer"
        >
          <ItemLabel>Export</ItemLabel>
        </a>
      ),
      disabled: !reviewCycle.startedDate,
    },
  ].filter((item) => item !== null);

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <Row gap={6} style={{ cursor: 'pointer' }}>
          <Text>Actions</Text>
          <MenuOutlined />
        </Row>
      </Dropdown>
      {contextHolder}
    </>
  );
};

const ItemLabel = styled(Text)`
  line-height: 40px;
`;

const ReconcileManagerReviewsButton: React.FC<{
  reviewCycleToken: ReviewCycleToken;
  disabled: boolean;
}> = ({ reviewCycleToken, disabled }) => {
  const [hasRun, setHasRun] = useState(false);

  const [planningError, setPlanningError] = useState(false);
  const [plannedChanges, setPlannedChanges] = useState<string[] | undefined>(
    undefined,
  );
  const [error, setError] = useState(false);
  const [changes, setChanges] = useState<string[] | undefined>(undefined);

  const [open, setOpen] = useState(false);
  const handleOnClick = async () => {
    if (disabled) {
      return;
    }
    setOpen(true);
    try {
      const response = await post<
        ReconcileManagerReviewsRequest,
        ReconcileManagerReviewsResponse
      >(`/review-cycles/${reviewCycleToken}/reviews/reconcile`, {
        dryRun: true,
      });
      setPlannedChanges(response.changes);
    } catch {
      setPlanningError(true);
    }
  };

  const reset = () => {
    setOpen(false);

    setHasRun(false);

    setPlanningError(false);
    setPlannedChanges(undefined);

    setError(false);
    setChanges(undefined);
  };

  const handleOk = async () => {
    // We already ran and showed the user the changes, so we can close the modal
    if (hasRun) {
      reset();
      return;
    }
    setHasRun(true);
    try {
      const response = await post<
        ReconcileManagerReviewsRequest,
        ReconcileManagerReviewsResponse
      >(`/review-cycles/${reviewCycleToken}/reviews/reconcile`, {
        dryRun: false,
      });
      setChanges(response.changes);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Column>
      <ItemLabel onClick={handleOnClick}>Reconcile manager reviews</ItemLabel>
      <Modal
        title="Reconcile Manager Reviews"
        open={open}
        onCancel={() => {
          reset();
        }}
        okText={hasRun ? 'Close' : 'Run'}
        okButtonProps={{ disabled: !plannedChanges }}
        onOk={handleOk}
      >
        <Column gap={6}>
          <Column>
            <Header3>Plan</Header3>
            {planningError ? (
              <Text>Failed to plan changes</Text>
            ) : (
              <ReconcileChangesDescription changes={plannedChanges} />
            )}
          </Column>
          {hasRun && (
            <Column>
              <Header3>Actual</Header3>
              {error ? (
                <Text>Failed to reconcile manager reviews.</Text>
              ) : (
                <ReconcileChangesDescription changes={changes} />
              )}
            </Column>
          )}
        </Column>
      </Modal>
    </Column>
  );
};

const ReconcileChangesDescription: React.FC<{
  changes: string[] | undefined;
}> = ({ changes }) => {
  if (!changes) {
    return <Skeleton />;
  }
  if (changes.length === 0) {
    return <Text>No changes</Text>;
  }
  return (
    <Column>
      <ol>
        {changes.map((change, index) => (
          <li key={index}>
            <Text>{change}</Text>
          </li>
        ))}
      </ol>
    </Column>
  );
};
