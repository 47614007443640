import { useAuth } from '@web/auth/useAuth';
import { patch } from '@web/common/api';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Tour, TourProps } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import React, { useState } from 'react';

export const SlackJournalEntryTour: React.FC = () => {
  const { user, reloadSession } = useAuth();
  const [showTour, setShowTour] = useState(user.showSlackJournalEntryTour);
  const [showSlackJournalEntryTour, setShowSlackJournalEntryTour] = useState(
    user.showSlackJournalEntryTour,
  );

  const handleClose = () => {
    if (!showSlackJournalEntryTour) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      patch(`/users/me`, { showSlackJournalEntryTour }).then(() => {
        void reloadSession();
      });
    }
    setShowTour(false);
  };

  const tourSteps: TourProps['steps'] = [
    {
      title: 'Creating a journal entry from Slack',
      description: (
        <Column gap={24}>
          <Text>
            Flint is suggesting this entry based on the details of the Slack
            message you selected. Review, update, and share the entry.
          </Text>
          <Row gap={12}>
            <Checkbox
              checked={!showSlackJournalEntryTour}
              onChange={() => {
                setShowSlackJournalEntryTour(!showSlackJournalEntryTour);
              }}
            />
            <Text>Do not show me this again</Text>
          </Row>
        </Column>
      ),
      placement: 'center',
      nextButtonProps: {
        children: 'Continue',
      },
    },
  ];

  return <Tour open={showTour} onClose={handleClose} steps={tourSteps} />;
};
