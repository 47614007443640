import { DeleteOutlined } from '@ant-design/icons';
import { hidePerformanceSummary } from '@client/PerformanceClient';
import { IPerformanceSummary } from '@shared/performance';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { StopPropagation } from '@web/components/StopPropagation';
import { UserAvatar } from '@web/components/UserAvatar';
import { NoWrap, Row } from '@web/components/layout';
import { Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const SummariesTable: React.FC<{
  summaries: IPerformanceSummary[];
  onChange: () => void;
}> = ({ summaries, onChange }) => {
  const navigate = useNavigate();

  const columns: ColumnsType<IPerformanceSummary> = [
    {
      title: 'Person',
      dataIndex: 'user',
      key: 'user',
      render(user) {
        return (
          <Row gap={6}>
            <UserAvatar user={user} size={20} />
            <NoWrap>{user.name}</NoWrap>
          </Row>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date) => <NoWrap>{date}</NoWrap>,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date) => <NoWrap>{date}</NoWrap>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, summary) => (
        <StopPropagation>
          <ConfirmButton
            onConfirm={async () => {
              try {
                await hidePerformanceSummary(summary.token);
                void message.success('Success');
                onChange();
              } catch (error) {
                void message.error('Error');
              }
            }}
            title="Remove summary"
            description="This will permanently remove this performance summary. Do you want to continue?"
          >
            <DeleteOutlined />
          </ConfirmButton>
        </StopPropagation>
      ),
    },
  ];

  return (
    <Table
      rowKey="token"
      columns={columns}
      dataSource={summaries}
      onRow={(row) => ({
        onClick: () => {
          navigate(row.token);
        },
        style: {
          cursor: 'pointer',
        },
      })}
    />
  );
};
