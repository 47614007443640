import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  MetricType,
  MetricTypeDescriptions,
  MetricTypeLabels,
} from '@shared/analytics';
import { NoWrap } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const MetricColumnTitle: React.FC<{
  metricType: MetricType;
  isUserTable?: boolean;
}> = ({ metricType }) => {
  const descriptions = MetricTypeDescriptions;
  const labels = MetricTypeLabels;
  switch (metricType) {
    case MetricType.GOALS:
      return (
        <MetricLabel>
          <NoWrap>
            Goals{' '}
            <Tooltip title={descriptions[metricType]}>
              <TitleTooltipIcon />
            </Tooltip>
          </NoWrap>
        </MetricLabel>
      );
    case MetricType.JOURNAL_WEEKLY_ENTRIES:
      return (
        <MetricLabel>
          <NoWrap>Weekly Journal</NoWrap>
          <NoWrap>
            Entries{' '}
            <Tooltip title={descriptions[metricType]}>
              <TitleTooltipIcon />
            </Tooltip>
          </NoWrap>
        </MetricLabel>
      );
    case MetricType.JOURNAL_USER_WITH_ENTRIES:
      return (
        <MetricLabel>
          Journal
          <NoWrap>
            Activity{' '}
            <Tooltip title={descriptions[metricType]}>
              <TitleTooltipIcon />
            </Tooltip>
          </NoWrap>
        </MetricLabel>
      );
    case MetricType.ONE_ON_ONES_ALIGNMENT:
      return (
        <MetricLabel>
          <NoWrap>1-on-1s</NoWrap>
          <NoWrap>
            Alignment{' '}
            <Tooltip title={descriptions[metricType]}>
              <TitleTooltipIcon />
            </Tooltip>
          </NoWrap>
        </MetricLabel>
      );
    case MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED:
      return (
        <MetricLabel>
          <NoWrap>Employees</NoWrap>
          <NoWrap>Completing</NoWrap>
          <NoWrap>
            1-on-1s{' '}
            <Tooltip title={descriptions[metricType]}>
              <TitleTooltipIcon />
            </Tooltip>
          </NoWrap>
        </MetricLabel>
      );
    case MetricType.MANAGER_ONE_ON_ONES_SCHEDULED:
      return (
        <MetricLabel>
          Scheduled Manager{' '}
          <NoWrap>
            1-on-1s{' '}
            <Tooltip title={descriptions[metricType]}>
              <TitleTooltipIcon />
            </Tooltip>
          </NoWrap>
        </MetricLabel>
      );
    default:
      return (
        <MetricLabel>
          {labels[metricType]}{' '}
          <Tooltip title={descriptions[metricType]}>
            <TitleTooltipIcon />
          </Tooltip>
        </MetricLabel>
      );
  }
};

export const MetricLabel = styled(Text)`
  font-weight: 500;
  line-height: 18px;
`;

export const TitleTooltipIcon = styled(QuestionCircleOutlined)`
  font-size: 13px;
  color: #888;
`;
