import { UserMapItem, UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { Modal } from 'antd';
import React, { useState } from 'react';

export const SelectUsersModal: React.FC<{
  label: string;
  initialUsers: UserMapItem[];
  onCancel: () => void;
  onSave: (userTokens: UserToken[]) => Promise<void>;
  placeholder?: string;
  title: string;
}> = ({ label, initialUsers, onSave, onCancel, placeholder, title }) => {
  const { user } = useAuth();
  const [userTokens, setUserTokens] = useState<UserToken[]>(
    initialUsers.map(toToken),
  );
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    if (!user) {
      return;
    }

    setIsSaving(true);

    try {
      await onSave(userTokens);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    onCancel();
  };

  return (
    <Modal
      title={title}
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="500px"
      okText="Save"
    >
      <Column gap={12}>
        <Text>{label}</Text>
        <SelectUsers
          initialUsers={initialUsers}
          placeholder={placeholder}
          onChange={setUserTokens}
        />
      </Column>
    </Modal>
  );
};

const toToken = (user: UserMapItem) => user.token;
