import { CloseOutlined, HolderOutlined } from '@ant-design/icons';
import { formatDate } from '@shared/formatDate';
import { UserMapItem } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import {
  GrowingSpacer,
  ResponsiveRow,
  Row,
  Spacer,
} from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Tooltip } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MiniGoalStateIndicator } from '../GoalStateIndicator';
import { GoalAssignedAvatars } from './GoalAssignedAvatars';
import { SortableGoalItem } from './GoalListPage';

export const ViewSortableGoalItem: React.FC<{
  assignedUsers?: UserMapItem[];
  item: SortableGoalItem;
  linkTo?: string;
  onRemove: (item: SortableGoalItem) => void;
}> = ({ assignedUsers, item, linkTo, onRemove }) => {
  const { isMobile } = useResponsive();
  const dateText = item.archivedDate
    ? `Archived ${formatDate(item.archivedDate)}`
    : item.dueDate
    ? `Due ${formatDate(item.dueDate)}`
    : null;

  return (
    <StyledLink to={linkTo ?? `/goals/${item.id}`}>
      <Row style={{ flex: 1 }}>
        {!isMobile && (
          <>
            <DragAnchor className="drag-anchor" />
            <Spacer size={3} />
          </>
        )}
        <Row style={{ justifyContent: 'flex-start' }}>
          <MiniGoalStateIndicator state={item.state} />
        </Row>
        <Spacer size={6} />
        <ResponsiveRow style={isMobile ? { alignItems: 'flex-start' } : {}}>
          <Text
            style={{
              lineHeight: '20px',
              textDecoration: item.archivedDate ? 'line-through' : 'none',
            }}
          >
            <span style={{ marginRight: 6 }}>{item.title}</span>
            {dateText && (
              <span style={{ fontSize: 12, color: '#888' }}>{dateText}</span>
            )}
          </Text>
        </ResponsiveRow>
        <GrowingSpacer />
        <GoalAssignedAvatars assignedUsers={assignedUsers} />
        {!isMobile && <Spacer size={6} />}
        <Tooltip title="Remove goal from list">
          <RemoveButton
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onRemove(item);
            }}
          >
            <CloseOutlined style={{ top: -1, position: 'relative' }} />
          </RemoveButton>
        </Tooltip>
      </Row>
    </StyledLink>
  );
};
const StyledLink = styled(Link)`
  display: flex;
  height: 50px;
  border-bottom: 1px solid var(--border-color);

  &:last-child {
    border: 0;
  }
`;
const DragAnchor = styled(HolderOutlined)`
  font-size: 18px;
  color: #888;
  cursor: grab;
`;
const RemoveButton = styled(Button)`
  &.ant-btn {
    padding: 4px 8px;
  }
`;
