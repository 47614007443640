import { TaskFilter } from '@shared/types';
import { Row } from '@web/components/layout';
import { Button, Select } from 'antd';
import * as React from 'react';

export const SelectTaskFilter: React.FC<{
  filter: TaskFilter;
  setFilter: (value: TaskFilter) => void;
  clearFilter: () => void;
}> = ({ filter, setFilter, clearFilter }) => {
  const handleFilterChange = (filterValue: TaskFilter) => {
    if (filterValue === 'all') {
      clearFilter();
    } else {
      setFilter(filterValue as any);
    }
  };

  return (
    <Row gap={6}>
      <Select<TaskFilter>
        value={filter ?? TaskFilter.ALL}
        style={{ width: '340px' }}
        onChange={handleFilterChange}
      >
        {Object.values(TaskFilter).map((filterValue) => (
          <Select.Option value={filterValue} key={filterValue}>
            {FILTER_LABELS[filterValue]}
          </Select.Option>
        ))}
      </Select>
      <Button onClick={clearFilter}>Reset</Button>
    </Row>
  );
};
const FILTER_LABELS: Record<TaskFilter, string> = {
  [TaskFilter.ALL]: 'Show all requests',
  [TaskFilter.OPEN]: 'Show only open requests',
  [TaskFilter.COMPLETED]: 'Show only completed requests',
  [TaskFilter.DECLINED]: 'Show only declined requests',
};
