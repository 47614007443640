import { sortResponses } from '@shared/impacts';
import { IQuestionResponse } from '@shared/question_response';
import { UserMapItem } from '@shared/types';
import { CommentsList } from '@web/comments/CommentsList';
import { UserMessage } from '@web/components/UserMessage';
import { Column } from '@web/components/layout';
import { FeedbackItem } from '@web/feedback/FeedbackList/FeedbackItem';
import * as React from 'react';

export const JournalEntryFeedback: React.FC<{
  responses: IQuestionResponse[];
  giver: UserMapItem;
  receiver: UserMapItem;
  onEdit?: () => void;
  readonly?: boolean;
}> = ({ responses, giver, receiver, onEdit, readonly = false }) => {
  // TODO: questions can be independently updated so have very different `updatedDate`s.
  //       we should figure out how to present this, or which date to use.
  const date = responses?.[0]?.updatedDate ?? undefined;
  const orderedResponses = sortResponses(responses);
  return (
    <UserMessage
      user={giver}
      date={date}
      fontSize={12}
      avatarSize={24}
      onEdit={onEdit}
    >
      <Column style={{ gap: 24 }}>
        {orderedResponses.map((response) => (
          <JournalEntryResponse
            key={response.token}
            response={response}
            receiver={receiver}
            readonly={readonly}
          />
        ))}
      </Column>
    </UserMessage>
  );
};

const JournalEntryResponse: React.FC<{
  response: IQuestionResponse;
  receiver: UserMapItem;
  readonly?: boolean;
}> = ({ response, receiver, readonly = false }) => {
  const [showNewComment, setShowNewComment] = React.useState(false);

  return (
    <Column gap={6}>
      <FeedbackItem
        response={response}
        receiver={receiver}
        onReply={() => {
          setShowNewComment(true);
        }}
        readonly={readonly}
      />
      <CommentsList
        isReply
        entityToken={response.token}
        showNewComment={showNewComment}
        onHideNewComment={() => {
          setShowNewComment(false);
        }}
        readonly={readonly}
      />
    </Column>
  );
};
