import { Feature } from '@shared/features';
import { IImpact } from '@shared/impacts';
import { possessive } from '@shared/possessive';
import { IQuestionSet, sortQuestions } from '@shared/questionSets';
import { QuestionToken, isQuestionLabel } from '@shared/questions';
import {
  IReflection,
  IReflectionResponse,
  ReflectionResponseInput,
  ReflectionState,
  isRatingResponse,
  isTextResponse,
} from '@shared/reflections';
import {
  UpdateCycleReflectionRequest,
  UpdateCycleReflectionResponse,
} from '@shared/review-cycles';
import { TagToken } from '@shared/tags';
import { IUser, UserToken } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import { put } from '@web/common/api';
import { useFeature } from '@web/common/useFeature';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { FormButtons } from '@web/components/FormButtons';
import { MarkdownEditor } from '@web/components/MarkdownEditor';
import { Pane } from '@web/components/Pane';
import {
  Absolute,
  Column,
  Relative,
  ResponsiveRow,
  Row,
} from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { renderReceiverQuestionText } from '@web/questions/renderReceiverQuestionText';
import { AutoSaveLabel, AutoSaveState } from '@web/reflections/AutoSaveState';
import { JournalEntryContextList } from '@web/reflections/JournalEntryContextList';
import { ReflectionQuestionInput } from '@web/reflections/ReflectionQuestionInput';
import { ReflectionResponseItem } from '@web/reflections/ReflectionResponseList';
import { ReflectionSharedDate } from '@web/reflections/ReflectionSharedDate';
import { Button, Divider, Form, Popover, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { useInterval } from 'usehooks-ts';

export interface ReflectionFormProps {
  onChange: () => void;
  existingReflection: IReflection;
  counterpartResponses: Record<QuestionToken, IReflectionResponse>;
  defaultNavigateBackTo?: string;
  recentJournalEntries?: IImpact[];
  questionSet: IQuestionSet;
}

interface InternalReflectionFormFields {
  receiverToken: UserToken;
  textContext: string;
  content: string;
  privateNote: string;
  tags: TagToken[];
  responses: ReflectionResponseInput[];
}

const shareButtonId = 'share-button';

export const EditReviewForm: React.FC<ReflectionFormProps> = ({
  onChange,
  existingReflection: reflection,
  counterpartResponses,
  defaultNavigateBackTo,
  recentJournalEntries,
  questionSet,
}) => {
  const navigateBack = useNavigateBack();
  const { isMobile } = useResponsive();
  const isDraft = reflection?.state === ReflectionState.DRAFT;

  const [form] = Form.useForm<InternalReflectionFormFields>();
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(isDraft);
  const [lastAutoSaveState, setLastAutoSaveState] =
    useState<AutoSaveState>(null);
  const [lastAutoSave, setLastAutoSave] = useState<Date | null>(null);
  const { booleanValue: previewEnabled } = useFeature(Feature.REVIEW_PREVIEW);
  const findResponse = useCallback(
    (token: QuestionToken): ReflectionResponseInput | null => {
      const rawResponse = reflection.responses.find(
        (r) => r.questionToken === token,
      );
      if (rawResponse?.content) {
        return {
          type: 'text',
          questionToken: rawResponse.questionToken,
          content: rawResponse.content,
          question: rawResponse.question,
        };
      } else if (rawResponse?.rating) {
        return {
          type: 'rating',
          questionToken: rawResponse.questionToken,
          rating: rawResponse.rating,
          question: rawResponse.question,
        };
      }
      return null;
    },
    [reflection],
  );

  const goBack = () => {
    navigateBack(defaultNavigateBackTo);
  };

  const handleSave = async ({
    draft,
    autoSave,
  }: {
    draft: boolean;
    autoSave: boolean;
  }) => {
    setIsSavingDraft(draft);

    if (autoSave) {
      setLastAutoSaveState('loading');
    } else {
      setIsSaving(true);
    }
    let internalFields: InternalReflectionFormFields;
    try {
      internalFields = await form.validateFields({ validateOnly: autoSave });
    } catch (error) {
      if (autoSave) {
        setLastAutoSaveState('error');
      } else {
        setIsSaving(false);
      }
      return;
    }
    try {
      const request: UpdateCycleReflectionRequest = {
        ...internalFields,
        draft,
        responses: Object.values(internalFields.responses),
      };
      await put<UpdateCycleReflectionRequest, UpdateCycleReflectionResponse>(
        `/review-cycles/reflections/${reflection.token}`,
        request,
      );
      onChange();
      if (autoSave) {
        setLastAutoSave(new Date());
        setLastAutoSaveState('success');
      }
      if (!autoSave) {
        void message.success('Success');
        goBack();
      }
    } catch (error) {
      void message.error('Error');
      setLastAutoSaveState('error');
    } finally {
      if (!autoSave) {
        setIsSaving(false);
      }
    }
  };

  const [dirty, setDirty] = useState(false);
  useInterval(
    async () => {
      if (!dirty || isSaving) {
        return false;
      }

      setDirty(false);
      await handleSave({ draft: true, autoSave: true });
    },
    isDraft ? 5000 : null,
  );

  const sortedQuestions = sortQuestions(questionSet);
  return (
    <Form
      form={form}
      layout="vertical"
      disabled={isSaving}
      onValuesChange={() => {
        setDirty(true);
      }}
      requiredMark={false}
    >
      {recentJournalEntries && recentJournalEntries.length > 0 ? (
        <Pane style={{ marginBottom: '12px' }}>
          <Column gap={12}>
            <Row gap={6}>
              <Header2>Recent Journal Entries</Header2>
            </Row>
            <JournalEntryContextList entries={recentJournalEntries} />
          </Column>
        </Pane>
      ) : undefined}
      <Pane id="new-reflection-form-container">
        <Relative>
          <Absolute top={isMobile ? -100 : -6} right={0}>
            <ReflectionSharedDate reflection={reflection} />
          </Absolute>
          {sortedQuestions.map((question) =>
            isQuestionLabel(question) ? (
              <Column key={question.token} style={{ maxWidth: 800 }}>
                <Text style={{ whiteSpace: 'pre-wrap' }}>{question.text}</Text>
                <Divider />
              </Column>
            ) : (
              <Form.Item
                name={['responses', question.token]}
                key={question.token}
                style={{ maxWidth: 800 }}
                label={
                  <Row gap={6}>
                    <Text>
                      {renderReceiverQuestionText(
                        question,
                        reflection.receiver?.name,
                      )}{' '}
                      {question.required ? '' : '(optional)'}
                    </Text>
                    {previewEnabled && (
                      <CounterpartResponsePreviewButton
                        counterpartResponse={
                          counterpartResponses[question.token]
                        }
                        counterpart={reflection.receiver}
                      />
                    )}
                  </Row>
                }
                initialValue={
                  findResponse(question.token) ?? {
                    questionToken: question.token,
                    content: '',
                  }
                }
                rules={[
                  {
                    required: !isSavingDraft && question.required,
                    message: isDraft
                      ? 'Response is required when submitting'
                      : 'Response is required',
                    transform: (response: ReflectionResponseInput) => {
                      if (isRatingResponse(response)) {
                        return `${response.rating}`;
                      } else if (isTextResponse(response)) {
                        return response.content;
                      }
                    },
                  },
                ]}
              >
                <ReflectionQuestionInput question={question} />
              </Form.Item>
            ),
          )}
          {reflection.includePrivateNote && (
            <Form.Item
              id="private-notes"
              label="Private Notes"
              name="privateNote"
              tooltip="Responses in this section will not be shared"
              initialValue={reflection?.privateNote}
            >
              <MarkdownEditor readOnly={isSaving} />
            </Form.Item>
          )}
          <ResponsiveRow gap={12}>
            <FormButtons
              style={{ width: isMobile ? '100%' : undefined, marginTop: 0 }}
            >
              <Button
                id={shareButtonId}
                type="primary"
                disabled={isSaving}
                onClick={() => {
                  void handleSave({ draft: false, autoSave: false });
                }}
              >
                {isDraft ? 'Submit' : 'Update'}
              </Button>
              {isDraft && (
                <Button
                  disabled={isSaving}
                  onClick={() => {
                    void handleSave({ draft: true, autoSave: false });
                  }}
                >
                  Save Draft
                </Button>
              )}
            </FormButtons>
            <AutoSaveLabel
              saveDate={lastAutoSave}
              lastState={lastAutoSaveState}
            />
          </ResponsiveRow>
        </Relative>
      </Pane>
    </Form>
  );
};

export const CounterpartResponsePreviewButton: React.FC<{
  counterpartResponse?: IReflectionResponse;
  counterpart: IUser;
}> = ({ counterpartResponse, counterpart }) => {
  const [open, setOpen] = useState(false);

  if (!counterpartResponse) {
    return null;
  }

  return (
    <Popover
      open={open}
      content={
        <Row style={{ maxWidth: 300 }}>
          <ReflectionResponseItem
            response={counterpartResponse}
            allowReply={false}
          />
        </Row>
      }
    >
      <Button
        onClick={() => {
          setOpen((open) => !open);
        }}
      >
        {`${possessive(counterpart.name)} Response`}
      </Button>
    </Popover>
  );
};
