import { groupBy, mapValues } from 'lodash';

import { UserMap, UserToken } from './types';

export interface IReaction {
  userToken: UserToken;
  entityToken: string;
  emoji: string;
  createdDate: Date;
}

export type ReactionCounts = Record<string, number>;

export interface IReactionsResponse {
  reactions: IReaction[];
  userMap: UserMap;
}

// a list of user tokens per emoji reaction on a specific entity
export type EntityReactionSummary = Record<string, UserToken[]>;

// a map of reaction summaries by entity token
export type EntityReactionSummaryMap = Record<string, EntityReactionSummary>;

export const createReactionSummary = (
  reactions: IReaction[],
): EntityReactionSummary => {
  if (!reactions || reactions.length === 0) {
    return {};
  }

  const reactionSummary = mapValues(
    groupBy(reactions, 'emoji'),
    (emojiReactions) => emojiReactions.map((r) => r.userToken),
  );

  return reactionSummary;
};
