import { mapByProp } from '@shared/mapByToken';
import { IQuestionResponse } from '@shared/question_response';
import { IQuestion, QuestionToken } from '@shared/questions';
import { RatingTrendMap } from '@shared/rating_scales';
import { ISurveyParticipant, SurveyCycleToken } from '@shared/surveys';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import React from 'react';

import { SurveyResponse } from './SurveyResponse';

export const SurveyResponses: React.FC<{
  participant: ISurveyParticipant;
  questions: IQuestion[];
  trendMap?: RatingTrendMap;
  currentSurveyCycleToken?: SurveyCycleToken;
}> = ({ participant, questions, trendMap, currentSurveyCycleToken }) => {
  const responseMap = mapByProp<QuestionToken, IQuestionResponse>(
    participant.responses,
    'questionToken',
  );
  return (
    <Pane>
      <UserMessage user={participant.user} date={participant.submittedDate} />
      <Spacer />
      {participant.submittedDate ? (
        <Column gap={24}>
          {questions.map((question) => (
            <SurveyResponse
              key={question.token}
              question={question}
              trend={trendMap?.[question.correlationToken as any]}
              response={responseMap.get(question.token)}
              currentSurveyCycleToken={currentSurveyCycleToken}
            />
          ))}
        </Column>
      ) : (
        <Text>No responses submitted</Text>
      )}
    </Pane>
  );
};
