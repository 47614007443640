import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Spacer } from '@web/components/layout';
import { Empty, Skeleton } from 'antd';
import * as React from 'react';

import { RequestListItem } from './RequestListItem';
import { SelectTaskFilter } from './SelectTaskFilter';
import { useAssignedTasks } from './useAssignedTasks';

export const RequestsPage: React.FC = () => {
  const { isMobile } = useResponsive();

  const {
    tasks,
    userMap,
    reloadTasks,
    isLoading,
    filter,
    setFilter,
    clearFilter,
  } = useAssignedTasks();

  const handleTaskChanged = () => {
    void reloadTasks();
  };

  return (
    <PageContent>
      <PageHeader
        title="Requests"
        mobileTitle="Requests"
        description="Provide peer feedback and respond to other requests for your input."
      />
      <Pane>
        {isMobile && <Spacer />}
        <SelectTaskFilter
          filter={filter}
          setFilter={setFilter}
          clearFilter={clearFilter}
        />
        <Spacer />
        <Column gap={12}>
          {isLoading ? (
            <Skeleton />
          ) : (
            tasks.map((task) => (
              <RequestListItem
                key={task.token}
                task={task}
                userMap={userMap}
                onChange={handleTaskChanged}
              />
            ))
          )}
        </Column>
        {!isLoading && tasks?.length === 0 && (
          <Empty style={{ marginTop: 10 }} description="No requests found" />
        )}
      </Pane>
    </PageContent>
  );
};
