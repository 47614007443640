import { InfoCircleOutlined } from '@ant-design/icons';
import { ButtonAction } from '@backend/slack_interactions/interactions';
import { formatDate } from '@shared/formatDate';
import { INotification, NotificationStatus } from '@shared/notitifications';
import { ISearchResults, OrganizationToken, UserToken } from '@shared/types';
import {
  ActionsBlock,
  KnownBlock,
  Button as SlackButtonType,
} from '@slack/types';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { IS_PRODUCTION } from '@web/common/const';
import { usePagination } from '@web/common/usePagination';
import { UserAvatar } from '@web/components/UserAvatar';
import { NoWrap, Row } from '@web/components/layout';
import { Button, Table, Tag, Tooltip, Typography, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import { ResendNotificationButton } from './ResendNotificationButton';
import { SelectPeersButton } from './SelectPeersButton';

interface INotificationSearchRequest {
  skip?: number;
  limit?: number;
  userToken?: UserToken;
  organizationToken?: OrganizationToken;
}

export const NotificationsTable: React.FC<{
  visibleColumns?: string[];
  organizationToken: OrganizationToken;
  userToken?: UserToken;
}> = ({
  organizationToken,
  userToken,
  visibleColumns = [
    'date',
    'user',
    'notification',
    'action',
    'status',
    'transport',
    'resend',
    'token',
  ],
}) => {
  const [searchResults, setSearchResults] =
    React.useState<ISearchResults<INotification>>(null);
  const { pagination, page, pageSize } = usePagination(searchResults?.total);

  const searchNotifications = async () => {
    const skip = (page - 1) * pageSize;
    const limit = pageSize;
    const results = await post<
      INotificationSearchRequest,
      ISearchResults<INotification>
    >(`/notifications/search`, { limit, skip, userToken, organizationToken });
    setSearchResults(results);
  };

  React.useEffect(() => {
    void searchNotifications();
  }, [organizationToken, userToken, page, pageSize]);

  const handleOnCopy = () => {
    void message.success('Copied to clipboard');
  };

  const { results: notifications, userMap } = searchResults ?? {
    results: [],
    total: 0,
  };
  const columns: ColumnsType<INotification> = [
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
      key: 'date',
      render: (createdDate) => <NoWrap>{formatDate(createdDate, true)}</NoWrap>,
    },
    {
      title: 'User',
      dataIndex: 'userToken',
      key: 'user',
      render: (userToken) =>
        userMap[userToken] ? (
          <Row gap={10} style={{ whiteSpace: 'nowrap' }}>
            <UserAvatar size={28} user={userMap[userToken]} />{' '}
            <Typography.Text>{userMap[userToken].name}</Typography.Text>
          </Row>
        ) : (
          'n/a'
        ),
    },
    {
      title: 'Notification',
      dataIndex: 'type',
      key: 'notification',
      render: (type, notification) => {
        const formattedJson = JSON.stringify(
          JSON.parse(notification.content),
          ' ' as any,
          4,
        );
        return (
          <Tooltip
            overlayInnerStyle={{
              width: 500,
              maxHeight: 500,
              overflowY: 'auto',
            }}
            title={<NotificationContent>{formattedJson}</NotificationContent>}
          >
            <Row gap={3} style={{ whiteSpace: 'nowrap' }}>
              {type}
              <CopyToClipboard text={formattedJson} onCopy={handleOnCopy}>
                <InfoCircleOutlined />
              </CopyToClipboard>
            </Row>
          </Tooltip>
        );
      },
    },
    IS_PRODUCTION
      ? null
      : {
          title: 'Actions',
          key: 'action',
          render: (_, notification) => (
            <ActionButtons
              notification={notification}
              organizationToken={organizationToken}
            />
          ),
        },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, notification: INotification) => {
        if (notification.resentBy) {
          return (
            <Tooltip title={`Resent by ${notification.resentBy}`}>
              <Tag color="warning">RESENT</Tag>
            </Tooltip>
          );
        } else if (status === NotificationStatus.ERROR) {
          return (
            <Tooltip title={notification.error}>
              <Tag color="error">{status}</Tag>
            </Tooltip>
          );
        } else if (status === NotificationStatus.SKIP) {
          return <Tag color="warning">{status}</Tag>;
        } else if (status === NotificationStatus.SENT) {
          return <Tag color="success">{status}</Tag>;
        } else {
          return <Tag>{status}</Tag>;
        }
      },
    },
    {
      title: 'Transport',
      key: 'transport',
      dataIndex: 'transport',
    },
    {
      title: 'Resent From',
      key: 'resend',
      dataIndex: 'resentFrom',
      align: 'right',
      render: (resentFrom) => {
        if (!resentFrom) {
          return null;
        }

        return resentFrom;
      },
    },
    {
      title: 'Resend',
      key: 'resend',
      dataIndex: 'status',
      align: 'center',
      render: (_, notification) => {
        return (
          <ResendNotificationButton
            onChange={() => {
              void searchNotifications();
            }}
            notification={notification}
          />
        );
      },
    } as any,
  ].filter((column) => column && visibleColumns.includes(column.key));

  return (
    <Table
      rowKey="token"
      columns={columns}
      dataSource={notifications}
      pagination={pagination}
      scroll={{ x: true }}
    />
  );
};

const NotificationContent = styled.div`
  white-space: pre-wrap;
  font-family: monospace;
`;

const ActionButtons: React.FC<{
  notification: INotification;
  organizationToken: OrganizationToken;
}> = ({ notification, organizationToken }) => {
  const content: KnownBlock[] = JSON.parse(notification.content);
  if (!content.find) {
    return <span>-</span>;
  }

  const actions = content.find(
    (block) => block.type === 'actions',
  ) as ActionsBlock;
  if (!actions || actions?.elements?.length === 0) {
    return null;
  }

  const button = actions.elements.find(
    (element) => element.type === 'button',
  ) as SlackButtonType;
  if (!button) {
    return null;
  }

  return (
    <Row gap={6}>
      {actions.elements.map((action, index) => (
        <ActionButton
          key={index}
          action={action}
          userToken={notification.userToken}
          organizationToken={organizationToken}
        />
      ))}
    </Row>
  );
};

const ActionButton: React.FC<{
  userToken: UserToken;
  action: ActionsBlock['elements'][number];
  organizationToken: OrganizationToken;
}> = ({ action, userToken, organizationToken }) => {
  const { impersonate } = useAuth();

  if (action.type !== 'button') {
    return null;
  } else if (action.action_id === ButtonAction.SELECT_PEERS) {
    return (
      <SelectPeersButton
        size="small"
        organizationToken={organizationToken}
        peerReviewToken={action.value as any}
        user={{ token: userToken, name: 'n/a' } as any}
      />
    );
  }

  const handleActionClicked = async () => {
    await impersonate(userToken, action.url);
  };
  return (
    <Button size="small" onClick={handleActionClicked}>
      {action.text.text}
    </Button>
  );
};
