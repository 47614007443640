import { IF_MOBILE } from '@web/app/responsive';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const InlineTabs = styled.div`
  display: flex;
  gap: 6px;

  ${IF_MOBILE} {
    margin: 0 -20px;
    padding: 20px 20px 0;
    background: linear-gradient(180deg, #ccc 0%, #ddd 2%, #eee 10%, #eee 100%);
  }
`;

const InlineTabCss = css`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  cursor: pointer;
  color: #333;
  border: 1px solid #e0e0e0;
  border-bottom: 0;

  &:hover {
    background: #d0d0d0;
  }
`;

export const InlineTabLink = styled(NavLink)<{ active?: boolean }>`
  ${InlineTabCss}
  background: #e0e0e0;

  &.active {
    background-color: white;
  }
`;

export const InlineTabButton = styled.div<{ active?: boolean }>`
  ${InlineTabCss}
  background: ${(props) => (props.active ? 'white' : '#e0e0e0')};
`;
