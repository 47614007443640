import { BedrockReportType } from '@shared/bedrock';
import { Select } from 'antd';
import * as React from 'react';

interface ReportOption {
  label: string;
  value: BedrockReportType;
}
export const SelectReportType: React.FC<{
  disabled: boolean;
  onChange: (type: BedrockReportType) => void;
}> = ({ disabled, onChange }) => {
  const options: ReportOption[] = [
    {
      label: BedrockReportType.BASIC,
      value: BedrockReportType.BASIC,
    },
    {
      label: BedrockReportType.WITH_MESSAGE,
      value: BedrockReportType.WITH_MESSAGE,
    },
    {
      label: BedrockReportType.SYNTHETIC_PULL_REQUESTS,
      value: BedrockReportType.SYNTHETIC_PULL_REQUESTS,
    },
    {
      label: BedrockReportType.GROUPED_PULL_REQUESTS,
      value: BedrockReportType.GROUPED_PULL_REQUESTS,
    },
    {
      label: BedrockReportType.EXPLICIT_USER_ID,
      value: BedrockReportType.EXPLICIT_USER_ID,
    },
    {
      label: BedrockReportType.SYNTHETIC_PR_AND_TICKET,
      value: BedrockReportType.SYNTHETIC_PR_AND_TICKET,
    },
  ];

  return <Select disabled={disabled} options={options} onChange={onChange} />;
};
