import {
  IReviewCycleParticipantSummary,
  ReviewCycleToken,
} from '@shared/review-cycles';
import { UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column } from '@web/components/layout';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { IndividualReviewCycleStatus } from './IndividualReviewCycleStatus';

const IndividualReviewCyclePage: React.FC = () => {
  const { reviewCycleToken, userToken } = useParams<{
    reviewCycleToken?: ReviewCycleToken;
    userToken?: UserToken;
  }>();
  const { user } = useAuth();
  const { data: reviewCycleSummary, mutate: reloadSummary } =
    useApi<IReviewCycleParticipantSummary>(
      `/review-cycles/${reviewCycleToken}/participants/${userToken}/summary`,
    );

  if (!reviewCycleSummary) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const { reviewCycle } = reviewCycleSummary;

  return (
    <PageContent>
      <PageHeader
        title={reviewCycle.name}
        navigateBack
        defaultNavigateBackTo="/cycles"
      />
      <Pane>
        <Column gap={24}>
          <UserMessage user={user} large />
          <IndividualReviewCycleStatus
            reviewCycleSummary={reviewCycleSummary}
            onChange={reloadSummary}
          />
        </Column>
      </Pane>
    </PageContent>
  );
};

export default IndividualReviewCyclePage;
