import { IQuestionResponse } from '@shared/question_response';
import { IQuestion, QuestionToken } from '@shared/questions';
import { ScheduledEventUpdate } from '@shared/scheduled_event';
import {
  IListSurveysResponse,
  ISurveyCycle,
  ISurveyParticipant,
  ISurveySummaryResponse,
  ITeamSurveySummaryResponse,
  SurveyCycleToken,
  SurveyParticipantToken,
  SurveySectionType,
} from '@shared/surveys';
import { UserToken } from '@shared/types';
import { del, get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useSurveyList() {
  const url = stringifyUrl({
    url: `/survey-cycles/list`,
    query: {},
  });

  return useApi<IListSurveysResponse>(url);
}

export function surveyList() {
  const url = stringifyUrl({
    url: `/survey-cycles/list`,
    query: {},
  });

  return get<IListSurveysResponse>(url);
}

export function useTeamCheckins() {
  const url = stringifyUrl({
    url: `/survey-cycles/checkins`,
    query: {},
  });

  return useApi<ISurveyParticipant[]>(url);
}

export function teamCheckins() {
  const url = stringifyUrl({
    url: `/survey-cycles/checkins`,
    query: {},
  });

  return get<ISurveyParticipant[]>(url);
}

export function useAdminSurveyList() {
  const url = stringifyUrl({
    url: `/survey-cycles/admin/list`,
    query: {},
  });

  return useApi<IListSurveysResponse>(url);
}

export function adminSurveyList() {
  const url = stringifyUrl({
    url: `/survey-cycles/admin/list`,
    query: {},
  });

  return get<IListSurveysResponse>(url);
}

export function useLatestCheckin(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/${userToken}/latest-checkin`,
    query: {},
  });

  return useApi<{ participant: ISurveyParticipant | null }>(url);
}

export function latestCheckin(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/${userToken}/latest-checkin`,
    query: {},
  });

  return get<{ participant: ISurveyParticipant | null }>(url);
}

export function useSurveyParticipant(participantToken: SurveyParticipantToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/participants/${participantToken}`,
    query: {},
  });

  return useApi<{ participant: ISurveyParticipant | null }>(url);
}

export function surveyParticipant(participantToken: SurveyParticipantToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/participants/${participantToken}`,
    query: {},
  });

  return get<{ participant: ISurveyParticipant | null }>(url);
}

export function useGet(surveyCycleToken: SurveyCycleToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/${surveyCycleToken}`,
    query: {},
  });

  return useApi<ISurveyCycle>(url);
}

export function getter(surveyCycleToken: SurveyCycleToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/${surveyCycleToken}`,
    query: {},
  });

  return get<ISurveyCycle>(url);
}

export function useTeamSurveySummary(surveyCycleToken: SurveyCycleToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/${surveyCycleToken}/team`,
    query: {},
  });

  return useApi<ITeamSurveySummaryResponse>(url);
}

export function teamSurveySummary(surveyCycleToken: SurveyCycleToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/${surveyCycleToken}/team`,
    query: {},
  });

  return get<ITeamSurveySummaryResponse>(url);
}

export function useSurveySummary(surveyCycleToken: SurveyCycleToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/${surveyCycleToken}/summary`,
    query: {},
  });

  return useApi<ISurveySummaryResponse>(url);
}

export function surveySummary(surveyCycleToken: SurveyCycleToken) {
  const url = stringifyUrl({
    url: `/survey-cycles/${surveyCycleToken}/summary`,
    query: {},
  });

  return get<ISurveySummaryResponse>(url);
}

export function update(
  surveyCycleToken: SurveyCycleToken,
  startEvent: ScheduledEventUpdate,
  reminderEvent: ScheduledEventUpdate | null,
  finalReminderEvent: ScheduledEventUpdate | null,
  endEvent: ScheduledEventUpdate,
  anonymous: boolean,
) {
  return put<{
    startEvent: ScheduledEventUpdate;
    reminderEvent: ScheduledEventUpdate;
    finalReminderEvent: ScheduledEventUpdate;
    endEvent: ScheduledEventUpdate;
    anonymous: boolean;
  }>(`/survey-cycles/${surveyCycleToken}`, {
    startEvent: startEvent,
    reminderEvent: reminderEvent,
    finalReminderEvent: finalReminderEvent,
    endEvent: endEvent,
    anonymous: anonymous,
  });
}

export function updateSurveyName(
  surveyCycleToken: SurveyCycleToken,
  name: string,
) {
  return put<{ name: string }>(`/survey-cycles/${surveyCycleToken}/name`, {
    name: name,
  });
}

export function remove(surveyCycleToken: SurveyCycleToken) {
  return del(`/survey-cycles/${surveyCycleToken}`);
}

export function createSurvey(name: string, anonymous: boolean) {
  return post<
    {
      name: string;
      anonymous: boolean;
    },
    ISurveyCycle
  >(`/survey-cycles`, {
    name: name,
    anonymous: anonymous,
  });
}

export function createCheckIn(name: string) {
  return post<{ name: string }, ISurveyCycle>(`/survey-cycles/check-in`, {
    name: name,
  });
}

export function runSurveyCycleAgain(token: SurveyCycleToken) {
  return post<{}>(`/survey-cycles/${token}/rerun`);
}

export function addParticipants(
  surveyCycleToken: SurveyCycleToken,
  participant: UserToken,
  includeTeam: boolean,
) {
  return post<{
    participant: `u_${string}`;
    includeTeam: boolean;
  }>(`/survey-cycles/${surveyCycleToken}/participants`, {
    participant: participant,
    includeTeam: includeTeam,
  });
}

export function removeParticipant(
  surveyCycleToken: SurveyCycleToken,
  participantToken: SurveyParticipantToken,
) {
  return del(
    `/survey-cycles/${surveyCycleToken}/participants/${participantToken}`,
  );
}

export function startCycle(surveyCycleToken: SurveyCycleToken) {
  return put<{}>(`/survey-cycles/${surveyCycleToken}/start`);
}

export function endCycle(surveyCycleToken: SurveyCycleToken) {
  return put<{}>(`/survey-cycles/${surveyCycleToken}/end`);
}

export function submitResponses(
  surveyCycleToken: SurveyCycleToken,
  responses: IQuestionResponse[],
) {
  return post<{ responses: IQuestionResponse[] }>(
    `/survey-cycles/${surveyCycleToken}/responses`,
    { responses: responses },
  );
}

export function mockResponses(
  surveyCycleToken: SurveyCycleToken,
  surveyParticipantToken: SurveyParticipantToken,
) {
  return post<{}>(
    `/survey-cycles/${surveyCycleToken}/participants/${surveyParticipantToken}/mockResponses`,
  );
}

export function sortSurveyQuestions(
  surveyCycleToken: SurveyCycleToken,
  questionPositions: Record<QuestionToken, number>,
) {
  return put<Record<`q_${string}`, number>>(
    `/survey-cycles/${surveyCycleToken}/questions/positions`,
    questionPositions,
  );
}

export function addQuestion(
  surveyCycleToken: SurveyCycleToken,
  questionAttributes: Partial<IQuestion>,
) {
  return post<Partial<IQuestion>>(
    `/survey-cycles/${surveyCycleToken}/questions`,
    questionAttributes,
  );
}

export function cloneQuestion(
  surveyCycleToken: SurveyCycleToken,
  questionToken: QuestionToken,
) {
  return post<{}, IQuestion>(
    `/survey-cycles/${surveyCycleToken}/questions/${questionToken}/clone`,
  );
}

export function updateQuestion(
  surveyCycleToken: SurveyCycleToken,
  questionToken: QuestionToken,
  questionAttributes: Partial<IQuestion>,
) {
  return patch<Partial<IQuestion>>(
    `/survey-cycles/${surveyCycleToken}/questions/${questionToken}`,
    questionAttributes,
  );
}

export function deleteQuestion(
  surveyCycleToken: SurveyCycleToken,
  questionToken: QuestionToken,
) {
  return del(`/survey-cycles/${surveyCycleToken}/questions/${questionToken}`);
}

export function sortSurveySections(
  surveyCycleToken: SurveyCycleToken,
  sortedSections: ISurveyCycle['sortedSections'],
) {
  return put<{ sortedSections: (`q_${string}` | SurveySectionType)[] }>(
    `/survey-cycles/${surveyCycleToken}/sections/positions`,
    { sortedSections: sortedSections },
  );
}

export function addSurveySection(
  surveyCycleToken: SurveyCycleToken,
  surveySection: SurveySectionType,
) {
  return post<{ surveySection: SurveySectionType }>(
    `/survey-cycles/${surveyCycleToken}/sections`,
    { surveySection: surveySection },
  );
}

export function deleteSurveySection(
  surveyCycleToken: SurveyCycleToken,
  surveySection: SurveySectionType,
) {
  return del(`/survey-cycles/${surveyCycleToken}/sections/${surveySection}`);
}
