import { formatDate } from '@shared/formatDate';
import { ISurveyParticipant } from '@shared/surveys';
import { Column } from '@web/components/layout';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const CheckinsTable: React.FC<{
  participants: ISurveyParticipant[];
}> = ({ participants }) => {
  const navigate = useNavigate();

  const columns: ColumnsType<ISurveyParticipant> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, participant) => {
        return participant.user.name;
      },
    },
    {
      title: 'Check-in',
      key: 'checkin',
      render: (_, participant) => {
        return participant.cycle.name;
      },
    },
    {
      title: 'Submitted',
      key: 'submitted',
      render: (_, participant) => {
        return formatDate(participant.submittedDate);
      },
    },
  ];

  return (
    <Column>
      <Table
        columns={columns}
        dataSource={participants}
        pagination={false}
        locale={{
          emptyText: <Empty description="No check-ins have been submitted" />,
        }}
        onRow={(participant) => ({
          onClick: () => {
            navigate(`/checkins/${participant.token}`);
          },
          style: {
            cursor: 'pointer',
          },
        })}
        rowKey="token"
      />
    </Column>
  );
};
