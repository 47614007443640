import { IUser, UserMapItem } from '@shared/types';
import { patch } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { UserMessage } from '@web/components/UserMessage';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Modal, Select, message } from 'antd';
import React from 'react';

interface Props {
  onCancel: () => void;
  onChange: () => void;
  user?: UserMapItem;
}

interface GithubMember {
  login: string;
  avatar_url: string;
}

export const GithubAssignLoginModal: React.FC<Props> = ({
  onChange,
  onCancel,
  user,
}) => {
  const { data: members } = useApi<GithubMember[]>(
    `/github/${user.organizationToken}/members`,
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const [githubLogin, setGithubLogin] = React.useState(user.githubLogin);

  const handleOk = async () => {
    setIsSaving(true);

    try {
      await patch<IUser>(`/users/${user.token}`, { githubLogin });
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const memberOptions =
    members?.map((member) => ({
      value: member.login,
      label: member.login,
    })) ?? [];
  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      title={<UserMessage user={user} />}
      open={true}
      onOk={() => {
        void handleOk();
      }}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Update"
    >
      <Column gap={24} style={{ padding: '12px 0' }}>
        <Column gap={6}>
          <Text>Github Login</Text>
          <Select
            value={githubLogin}
            onChange={(login) => {
              setGithubLogin(login);
            }}
            options={memberOptions}
            disabled={!memberOptions}
            showSearch
            filterOption={filterOption}
          />
        </Column>
      </Column>
    </Modal>
  );
};
