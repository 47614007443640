import { IUpwardReview, UpwardReviewToken } from '@shared/review-cycles';
import { OrganizationToken, UserMapItem, UserToken } from '@shared/types';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { Modal, Skeleton, message } from 'antd';
import React, { useEffect, useState } from 'react';

export const ApproveReportsModal: React.FC<{
  onCancel: () => void;
  onSave: () => void;
  organizationToken: OrganizationToken;
  upwardReviewToken: UpwardReviewToken;
  user: UserMapItem;
  modalTitle?: string;
}> = ({
  onSave,
  onCancel,
  organizationToken,
  upwardReviewToken,
  user,
  modalTitle,
}) => {
  const { data: upwardReview, mutate: reloadUpwardReview } =
    useApi<IUpwardReview>(`/upward-reviews/${upwardReviewToken}`);
  const [approvedReports, setApprovedReports] = useState<UserToken[]>([]);
  const [isSaving, setIsSaving] = React.useState(false);
  useEffect(() => {
    if (upwardReview?.approvedReports) {
      const initialReportTokens = upwardReview.approvedReports.map(
        (report) => report.token,
      );
      setApprovedReports(initialReportTokens);
    }
  }, [upwardReview?.approvedReports]);

  const handleOk = async () => {
    setIsSaving(true);

    try {
      await post<{ approvedReports: UserToken[] }>(
        `/upward-reviews/${upwardReviewToken}/approvedReports`,
        { approvedReports },
      );
      void message.success('Success');
      void reloadUpwardReview();
      onSave();
    } catch (error) {
      void message.error('Failed to change reports');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleUsersSelected = (userTokens: UserToken[]) => {
    setApprovedReports(userTokens);
  };

  return (
    <Modal
      title={modalTitle ?? 'Change Reports'}
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Submit"
      okButtonProps={{
        loading: !upwardReview,
        disabled: approvedReports.length === 0,
      }}
    >
      {upwardReview ? (
        <SelectUsers
          style={{ width: '100%' }}
          organizationToken={organizationToken}
          onChange={handleUsersSelected}
          initialUsers={upwardReview?.approvedReports}
          hideUsers={[user.token]}
        />
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
};
