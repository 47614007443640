import { ReflectionState } from '@shared/reflections';
import { GetManagerReviewResponse } from '@shared/review-cycles';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Spacer } from '@web/components/layout';
import { pageDescription, pageTitle } from '@web/reflections/reflections';
import { Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EditReviewForm } from './EditReviewForm';

export const EditReviewPage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { reviewCycleToken, userToken } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  const {
    data: response,
    error,
    mutate,
  } = useApi<GetManagerReviewResponse, ServerResponseError>(
    `/review-cycles/${reviewCycleToken}/reflection/${userToken}`,
  );

  useEffect(() => {
    if (!response) {
      return;
    }
    if (!response.review.canEdit) {
      navigate(`/cycles/${response.review.reviewCycleToken}`, {
        replace: true,
      });
    }
  }, [response]);

  if (error) {
    return <ErrorPageContent statusCode={error.statusCode} />;
  } else if (!response?.review || !response?.review?.canEdit) {
    return (
      <PageContent>
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }
  // TODO: fix links / title
  const reflection = response.review;
  const defaultNavigateBackTo =
    reflection.state === ReflectionState.SHARED
      ? `/cycles/${reflection.reviewCycleToken}/users/${reflection.receiverToken}/reflection`
      : `/cycles/${reflection.reviewCycleToken}`;

  const handleChange = async () => {
    await mutate();
  };

  return (
    <PageContent>
      <PageHeader
        title={pageTitle(user, reflection)}
        mobileTitle={pageTitle(user, reflection)}
        description={isMobile ? undefined : pageDescription(reflection)}
        navigateBack
        defaultNavigateBackTo={defaultNavigateBackTo}
      />
      <EditReviewForm
        onChange={handleChange}
        existingReflection={reflection}
        counterpartResponses={response.counterpartResponses}
        defaultNavigateBackTo={defaultNavigateBackTo}
        recentJournalEntries={response.recentJournalEntries}
        questionSet={response.questionSet}
      />
      {isMobile && <Spacer />}
    </PageContent>
  );
};
