import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import {
  Feature,
  IFeatureDetailsResponse,
  IUpdateFeatureOverridesRequest,
} from '@shared/features';
import { IGithubInstallResponse } from '@shared/integrations';
import { IOrganization, OrganizationToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { errorColor, successColor } from '@web/app/styles/ColorStyles';
import { patch } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Divider, message } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { GithubLoadingPage } from './GithubLoadingPage';
import { GithubTabs } from './GithubTabs';

export const GithubFeaturePage: React.FC = () => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSavingActive, setIsSavingActive] = React.useState(false);
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  const { data: featureDetails, mutate: reloadFeatures } =
    useApi<IFeatureDetailsResponse>(
      `/features/organization/${organizationToken}`,
    );
  const { data: githubInstall } = useApi<IGithubInstallResponse>(
    `/github/${organizationToken}/installation`,
  );
  const { data: organization, mutate: reloadOrganization } =
    useApi<IOrganization>(`/organizations/${organizationToken}`);

  if (!githubInstall || !featureDetails || !organization) {
    return <GithubLoadingPage />;
  }

  const updateGithubIntegration = async (enabled: boolean) => {
    setIsSaving(true);
    try {
      await patch<IUpdateFeatureOverridesRequest>(
        `/features/organization/${organizationToken}`,
        {
          overrides: [
            {
              entityToken: organizationToken,
              feature: Feature.GITHUB_INTEGRATION,
              booleanValue: enabled,
            },
          ],
        },
      );
      void message.success('Success');
      void reloadFeatures();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };
  const handleDisable = () => {
    void updateGithubIntegration(false);
  };
  const handleEnable = () => {
    void updateGithubIntegration(true);
  };

  const updateGithubFeatureActive = async (active: boolean) => {
    setIsSavingActive(true);
    try {
      await patch<IOrganization>(`/organizations/${organizationToken}`, {
        githubFeatureActive: active,
      });
      void message.success('Success');
      void reloadOrganization();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSavingActive(false);
    }
  };
  const handleDeactivate = () => {
    void updateGithubFeatureActive(false);
  };
  const handleActivate = () => {
    void updateGithubFeatureActive(true);
  };

  const githubIntegrationEnabled =
    featureDetails.featureOverrides.GITHUB_INTEGRATION?.booleanValue ?? false;

  return (
    <PageContent>
      <PageHeader title={'Github'} />
      <Column>
        <GithubTabs />
        <Pane>
          <Column gap={24}>
            {githubIntegrationEnabled ? (
              <Row gap={12}>
                <CheckCircleFilled
                  style={{ color: successColor.string(), fontSize: 24 }}
                />
                <Text>Feature flag GITHUB_INTEGRATION is true</Text>
                <ConfirmButton
                  description="Are you sure?"
                  onConfirm={handleDisable}
                  loading={isSaving}
                >
                  Disable
                </ConfirmButton>
              </Row>
            ) : (
              <Row gap={12}>
                <CloseCircleFilled
                  style={{ color: errorColor.darken(0.2), fontSize: 24 }}
                />
                <Text>Feature flag GITHUB_INTEGRATION is false</Text>
                <ConfirmButton
                  description="Are you sure?"
                  onConfirm={handleEnable}
                  loading={isSaving}
                >
                  Enable
                </ConfirmButton>
              </Row>
            )}
            {githubInstall.installationId ? (
              <Row gap={12}>
                <CheckCircleFilled
                  style={{ color: successColor.string(), fontSize: 24 }}
                />
                <Text>
                  Flint App has been installed: #{githubInstall.installationId}
                </Text>
              </Row>
            ) : (
              <Row gap={12}>
                <CloseCircleFilled
                  style={{ color: errorColor.darken(0.2), fontSize: 24 }}
                />
                <Text>Flint App has been not been installed yet</Text>
              </Row>
            )}
            {organization.githubFeatureActive ? (
              <Row gap={12}>
                <CheckCircleFilled
                  style={{ color: successColor.string(), fontSize: 24 }}
                />
                <Text>Github feature is active</Text>
                <ConfirmButton
                  description="Are you sure?"
                  onConfirm={handleDeactivate}
                  loading={isSavingActive}
                >
                  Deactivate
                </ConfirmButton>
              </Row>
            ) : (
              <Row gap={12}>
                <CloseCircleFilled
                  style={{ color: errorColor.darken(0.2), fontSize: 24 }}
                />
                <Text>Github feature is inactive</Text>
                <ConfirmButton
                  description="Are you sure?"
                  onConfirm={handleActivate}
                  loading={isSavingActive}
                  disabled={!githubInstall.installationId}
                >
                  Activate
                </ConfirmButton>
              </Row>
            )}
            {githubInstall.repositories && (
              <>
                <Divider style={{ margin: 0 }} />
                <Column gap={12}>
                  <Header3>Repository access:</Header3>
                  <Column gap={6}>
                    {githubInstall.repositories?.map((repo) => (
                      <Text key={repo.name}>• {repo.name}</Text>
                    ))}
                  </Column>
                </Column>
              </>
            )}
          </Column>
        </Pane>
      </Column>
    </PageContent>
  );
};
