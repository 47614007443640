import {
  completeAgendaItem,
  createAgendaItem,
  sortAgendaItems,
  uncompleteAgendaItem,
  useAgendaItems,
} from '@client/OneOnOnesClient';
import { mapByToken } from '@shared/mapByToken';
import {
  AgendaItemToken,
  IAgendaItem,
  OneOnOneToken,
} from '@shared/one-on-one';
import { FLINT_USER, UserMapItem } from '@shared/types';
import { WebSocketEventType } from '@shared/webSocketEvents';
import * as React from 'react';

import { useWebSocketEvent } from '../common/useWebSocketEvent';
import { AgendaItemDrawer } from './AgendaItemDrawer';
import { ITaskItem, TaskList } from './items/TaskList';

export const AgendaItemsList: React.FC<{
  oneOnOneToken: OneOnOneToken;
  otherUser: UserMapItem;
}> = ({ oneOnOneToken, otherUser }) => {
  const { selectedAgendaItem, reloadData, ...service } =
    useAgendaTaskItems(oneOnOneToken);
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.AGENDA_ITEMS_UPDATED,
    () => {
      void reloadData();
    },
  );

  const handleClose = () => {
    service.select(null);
  };

  return (
    <>
      <TaskList title="Agenda" taskService={service} otherUser={otherUser} />
      {selectedAgendaItem && (
        <AgendaItemDrawer
          otherUser={otherUser}
          agendaItem={selectedAgendaItem}
          onClose={handleClose}
          oneOnOneToken={oneOnOneToken}
        />
      )}
    </>
  );
};

const useAgendaTaskItems = (oneOnOneToken: OneOnOneToken) => {
  const { data: sortedAgendaItems, mutate: reloadData } =
    useAgendaItems(oneOnOneToken);
  const [selectedAgendaItemToken, setSelectedAgendaItemToken] =
    React.useState<AgendaItemToken>(null);

  const add = async (text: string): Promise<ITaskItem<AgendaItemToken>> => {
    const agendaItem = await createAgendaItem(oneOnOneToken, text);
    await reloadData();
    return agendaToTask(agendaItem);
  };

  const resolve = async (
    updatedItem: ITaskItem<AgendaItemToken>,
    resolved: boolean,
  ): Promise<void> => {
    if (resolved) {
      await completeAgendaItem(oneOnOneToken, updatedItem.id);
    } else {
      await uncompleteAgendaItem(oneOnOneToken, updatedItem.id);
    }
    await reloadData();
  };

  const sort = async (
    newlySortedItems: Array<ITaskItem<AgendaItemToken>>,
  ): Promise<void> => {
    await sortAgendaItems(
      oneOnOneToken,
      newlySortedItems.map((item) => item.id),
    );
    await reloadData();
  };

  const select = (taskItemId: AgendaItemToken = null) => {
    setSelectedAgendaItemToken(taskItemId);
  };

  const agendaItemMap = mapByToken(sortedAgendaItems);
  const selectedAgendaItem = selectedAgendaItemToken
    ? agendaItemMap.get(selectedAgendaItemToken)
    : undefined;

  const taskItems: Array<ITaskItem<AgendaItemToken>> = sortedAgendaItems
    ? sortedAgendaItems.map(agendaToTask)
    : undefined;

  return {
    add,
    resolve,
    sort,
    taskItems,
    select,
    selectedAgendaItem,
    reloadData,
  };
};

const agendaToTask = (agenda: IAgendaItem): ITaskItem<AgendaItemToken> => ({
  id: agenda.token,
  resolved: !!agenda.completedDate,
  text: agenda.text,
  owner: agenda.createdBy ?? FLINT_USER,
});
