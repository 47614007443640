import {
  Button,
  ButtonProps,
  Popconfirm,
  PopconfirmProps,
  Typography,
} from 'antd';
import * as React from 'react';

import { Column } from './layout';

interface IConfirmButtonProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  onConfirm: PopconfirmProps['onConfirm'];
  okText?: PopconfirmProps['okText'];
  cancelText?: PopconfirmProps['cancelText'];
  size?: ButtonProps['size'];
  children: React.ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties;
  type?: ButtonProps['type'];
  loading?: boolean;
}
export const ConfirmButton: React.FC<IConfirmButtonProps> = ({
  title,
  description,
  onConfirm,
  okText,
  cancelText,
  size,
  children,
  disabled,
  style,
  type,
  loading,
}) => (
  <Popconfirm
    title={<ConfirmTitle title={title} description={description} />}
    okText={okText}
    cancelText={cancelText}
    icon={null}
    onConfirm={onConfirm}
    overlayClassName="confirm-button-popup"
    disabled={disabled}
  >
    <Button
      disabled={disabled}
      size={size}
      style={style}
      type={type}
      loading={loading}
    >
      {children}
    </Button>
  </Popconfirm>
);

export const ConfirmTitle: React.FC<{
  title: React.ReactNode;
  description: React.ReactNode;
}> = ({ title, description }) => {
  return (
    <Column style={{ gap: 3 }}>
      {title && (
        <Typography.Text style={{ fontWeight: 'bold', fontSize: 16 }}>
          {title}
        </Typography.Text>
      )}
      {description && <Typography.Text>{description}</Typography.Text>}
    </Column>
  );
};
