import { IReviewCycle, ReviewCycleToken } from '@shared/review-cycles';
import { useApi } from '@web/common/useApi';
import { Row } from '@web/components/layout';
import { Subheading } from '@web/components/text';
import { Skeleton } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AdminPageContent } from '../admin/AdminPageContent';
import { EditReviewCycleForm } from './EditReviewCycleForm';

export const AdminEditReviewCyclePage: React.FC = () => {
  const navigate = useNavigate();
  const { reviewCycleToken } = useParams<{
    reviewCycleToken?: ReviewCycleToken;
  }>();

  const isEdit = !!reviewCycleToken;
  const { data: reviewCycle } = useApi<IReviewCycle>(
    isEdit ? `/review-cycles/${reviewCycleToken}` : null,
  );

  if (isEdit && !reviewCycle) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const handleSave = async ({ token }: IReviewCycle) => {
    navigate(`/admin/review-cycles/${token}/dashboard`);
  };

  const handleCancel = async () => {
    navigate(-1);
  };

  return (
    <AdminPageContent>
      <Row>
        <Subheading>{isEdit ? 'Edit cycle' : 'Add a cycle'}</Subheading>
      </Row>
      <EditReviewCycleForm
        reviewCycleToken={reviewCycleToken}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </AdminPageContent>
  );
};
