import { useListGoals } from '@client/GoalsClient';
import { IGoal, isAssignedGoal, isPersonalGoal } from '@shared/goals';
import { UserRole, UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column } from '@web/components/layout';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { EmptyGoals } from './EmptyGoals';
import { ViewGoals } from './ViewGoals';

export const ReportsGoalsPage: React.FC = () => {
  const { user } = useAuth();
  const { userToken } = useParams<{ userToken: UserToken }>();
  const { data: goalsResponse } = useListGoals(false, userToken);
  if (!goalsResponse) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const personalGoals: IGoal[] =
    goalsResponse?.goals?.filter(isPersonalGoal) ?? [];
  const assignedGoals: IGoal[] =
    goalsResponse?.goals?.filter(isAssignedGoal(userToken)) ?? [];

  const isManager = isManagerOf(goalsResponse?.user, user.token);
  return (
    <PageContent>
      <PageHeader
        title={`Goals for ${goalsResponse.user.name}`}
        mobileTitle={`Goals for ${goalsResponse.user.name}`}
        primaryAction={
          isManager || user.role === UserRole.HR_ADMIN
            ? {
                label: 'Add Goal',
                linkTo: `/team/${userToken}/goals/new`,
              }
            : undefined
        }
        navigateBack
      />
      <Pane>
        {goalsResponse.goals.length > 0 ? (
          <Column gap={36}>
            <ViewGoals
              goals={personalGoals}
              title={`Personal (${personalGoals.length})`}
            />
            <ViewGoals
              goals={assignedGoals}
              title={`Assigned (${assignedGoals.length})`}
            />
          </Column>
        ) : (
          <EmptyGoals user={goalsResponse.user} />
        )}
      </Pane>
    </PageContent>
  );
};
