import { dateString } from '@shared/dateString';
import { IGoal } from '@shared/goals';
import { useAuth } from '@web/auth/useAuth';
import { RichText } from '@web/components/RichText';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { GoalStateIndicator } from './GoalStateIndicator';

export const GoalCard: React.FC<{ goal: IGoal }> = ({ goal }) => {
  const { user } = useAuth();
  const isAuthor = user.token === goal.userToken;

  return (
    <Column>
      <InputContainer editMode={false}>
        <Link
          to={
            isAuthor
              ? `/goals/${goal.token}`
              : `/team/${goal.userToken}/goals/${goal.token}`
          }
        >
          <Text
            style={{
              color: '#333',
              margin: '4px 11px',
              border: 0,
              fontSize: 18,
            }}
          >
            {goal.title}
          </Text>
        </Link>
        <Row gap={6} style={{ paddingLeft: '11px' }}>
          <GoalStateIndicator state={goal.state} />
        </Row>

        {goal.dueDate ? (
          <Row gap={6} style={{ paddingLeft: '11px' }}>
            <Text>Due</Text>
            <Text>{dateString(goal.dueDate)}</Text>
          </Row>
        ) : null}
        {goal.content ? (
          <RichText value={goal.content} style={{ border: 0 }} />
        ) : null}
      </InputContainer>
    </Column>
  );
};
const InputContainer = styled.section<{ editMode?: boolean }>`
  border: 1px solid ${(props) => (props.editMode ? '#ddd' : 'transparent')};
  border-radius: var(--default-border-radius);
  position: relative;
  margin-bottom: 40px;
  margin-right: 24px;

  .ant-picker .ant-picker-input > input {
    cursor: pointer;
  }

  .ant-input:disabled,
  .ant-textarea:disabled {
    color: #333;
    cursor: text;
  }
`;
