import { useSession } from '@client/AuthClient';
import { IUser, UserRole, UserToken } from '@shared/types';
import { setErrorSession } from '@web/app/ErrorBoundary';
import { REACT_APP_BUILD_HASH, REACT_APP_BUILD_TIME } from '@web/app/build';
import { message } from 'antd';
import { useEffect } from 'react';

import { del, post, put } from '../common/api';

export const useAuth = () => {
  const {
    data: session,
    error,
    mutate: reloadSession,
  } = useSession(REACT_APP_BUILD_TIME, REACT_APP_BUILD_HASH);
  useEffect(() => {
    // set error session to be used by bugsnag error reporting
    setErrorSession(session);
  }, [session]);
  const user: IUser = session?.user;
  const managers = session?.managers;
  const managesPeople = session?.managesPeople;
  const managesManagers = session?.managesManagers;
  const features = session?.features;
  const organization = session?.organization;
  const impersonator = session?.impersonator;
  const isLoading = error === undefined && session === undefined;
  const isLoggedIn = user !== undefined;
  const validateRole = (requiredRole: UserRole): boolean => {
    return user?.role === requiredRole;
  };
  const isAdmin = user?.role === UserRole.ADMIN;
  const isHrAdmin = user?.role === UserRole.HR_ADMIN;
  const isSuper = user?.role === UserRole.SUPER;
  const isOperations = user?.role === UserRole.OPERATIONS;
  const isExternal = user?.role === UserRole.EMPLOYEE;
  const isInternal = isSuper || isOperations;
  const isImpersonating = !!impersonator;

  return {
    isAdmin,
    isHrAdmin,
    isSuper,
    isInternal,
    isOperations,
    isExternal,
    isLoggedIn,
    isLoading,
    isImpersonating,
    user,
    impersonator,
    organization,
    error,
    login,
    loginWithCode,
    logout,
    impersonate,
    unimpersonate,
    validateRole,
    reloadSession,
    features,
    managesPeople,
    managesManagers,
    managers,
    needsRefresh: session?.needsRefresh,
  };
};

async function logout(): Promise<void> {
  try {
    await del('/auth/session');
    window.location.replace('/auth/login');
  } catch (error) {
    void message.error('Error');
  }
}

async function impersonate(
  userToken: UserToken,
  navigateTo?: string,
): Promise<void> {
  localStorage.impersonateReturnPath = window.location.pathname;
  try {
    await put(`/auth/impersonate/${userToken}`);
    window.location.href = navigateTo ?? '/redirect';
  } catch (error) {
    void message.error('Failed');
  }
}

async function unimpersonate(): Promise<void> {
  await del(`/auth/impersonate`);
  window.location.href = localStorage.impersonateReturnPath
    ? localStorage.impersonateReturnPath
    : '/users';
}

async function login(email: string, password: string): Promise<IUser> {
  return await post<IUser>(`/auth/login`, { email, password });
}

async function loginWithCode(email: string, code: string): Promise<IUser> {
  return await post(`/auth/loginWithCode`, { email, code });
}
