import { SmileOutlined } from '@ant-design/icons';
import { useEmojiSelector } from '@web/app/EmojiSelectorProvider';
import { Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const AddEmojiButton: React.FC<{ onAdd: (emoji: string) => void }> = ({
  onAdd,
}) => {
  const buttonRef = React.useRef<HTMLDivElement>(null);
  const { selectEmoji } = useEmojiSelector();

  const handleAddClicked = async () => {
    if (!buttonRef.current) {
      return;
    }

    const emoji = await selectEmoji(buttonRef.current);
    if (emoji) {
      onAdd(emoji);
    }
  };

  return (
    <Tooltip title="Add reaction...">
      <ButtonContainer ref={buttonRef} onClick={handleAddClicked}>
        <SmileAddOutlined />
      </ButtonContainer>
    </Tooltip>
  );
};

const SmileAddOutlined: React.FC = () => {
  return (
    <SmileAddContainer>
      <StyledSmileOutlined />
      <StyledPlusOutlined>+</StyledPlusOutlined>
    </SmileAddContainer>
  );
};

const StyledSmileOutlined = styled(SmileOutlined)`
  font-size: 16px;
  position: absolute;
  top: 0;
`;
const StyledPlusOutlined = styled.span`
  position: absolute;
  font-size: 8px;
  width: 7px;
  height: 7px;
  bottom: 0;
  right: -1px;
  border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  line-height: 7px;
  font-weight: bold;
  padding-top: 1px;
  user-select: none;
`;

const SmileAddContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
`;

const ButtonContainer = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-flex;
  padding: 0;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 16px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    color: #666;
  }

  ${StyledPlusOutlined} {
    background: #f5f5f5;
    color: #666;
  }

  &:hover {
    background: white;
    border-color: #aaa;

    svg {
      color: #333;
    }

    ${StyledPlusOutlined} {
      background: white;
      color: #333;
    }
  }
`;
