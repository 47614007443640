import { TypographyCss } from '@web/components/typography';
import { css } from 'styled-components';

export const QuillEditorStyles = css`
  .ql-editor ol,
  .ql-editor ul {
    padding-left: 0;
  }
  .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }
  .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }
  .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 6em;
  }
  .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }
  .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 9em;
  }
  .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }
  .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 12em;
  }
  .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }
  .ql-toolbar.ql-snow {
    ${TypographyCss.Text}
    border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
    border-color: var(--border-color);
    background-color: white;
    z-index: 1;
  }
  .ql-container.ql-snow {
    ${TypographyCss.Text}
    height: auto;
    font-size: 14px;
    border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
    border-color: var(--border-color);

    .ql-tooltip {
      z-index: 1;
    }
  }
  .ql-snow a {
    color: var(--primary-color);
  }

  .ql-editor .ql-size-huge {
    font-size: 2.5em;
    line-height: 1.3em;
  }

  .ql-editor .ql-size-large {
    font-size: 1.5em;
    line-height: 1.5em;
  }

  .ql-editor a {
    color: var(--primary-color);
    text-decoration: underline;
  }

  .ql-container {
    p {
      margin: 0;
      min-height: 24px;
    }
  }

  .ql-snow .ql-stroke {
    stroke: #888;
  }
  .ql-snow.ql-toolbar button:hover {
    .ql-stroke {
      stroke: #333;
    }
  }
`;
