import { SyncOutlined } from '@ant-design/icons';
import {
  IReviewCycleParticipantSummary,
  ReviewCycleToken,
} from '@shared/review-cycles';
import { UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { useLocalStorage } from '@web/common/useLocalStorage';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header2 } from '@web/components/typography';
import { TeamPicker } from '@web/team/TeamPicker';
import { Button, Select, Skeleton } from 'antd';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { IndividualReviewCycleStatus } from './IndividualReviewCycleStatus';
import { ReviewCycleDashboard } from './ReviewCycleDashboard';
import { ReviewCycleStateTag } from './ReviewCycleStateTag';
import { ReviewCycleTasks } from './ReviewCycleTasks';

enum ShowingOption {
  REPORTS_ONLY = 'REPORTS_ONLY',
  ALL = 'ALL',
}

const ReviewCycleDashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const { reviewCycleToken } = useParams<{
    reviewCycleToken?: ReviewCycleToken;
  }>();
  const { managesManagers, user } = useAuth();
  const [cycleShowingFilter, setCycleShowingFilter] =
    useLocalStorage<ShowingOption>(
      'cycleShowingFilter',
      ShowingOption.REPORTS_ONLY,
    );

  const {
    data: reviewCycleSummary,
    mutate: reloadSummary,
    error: reviewCycleSummaryError,
  } = useApi<IReviewCycleParticipantSummary>(
    `/review-cycles/${reviewCycleToken}/participants/${user.token}/summary`,
  );

  if (reviewCycleSummaryError) {
    return (
      <ErrorPageContent
        title="Cycle not found"
        subTitle="Sorry, we could not find this cycle. Please try another page."
        extra={
          <Link to="/cycles">
            <Button>
              <SyncOutlined /> Return to cycles
            </Button>
          </Link>
        }
      />
    );
  }
  if (!reviewCycleSummary) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const handleDashboardChange = () => {
    void reloadSummary();
  };

  const handleTeamChange = (userToken: UserToken) => {
    navigate(`/cycles/${reviewCycleToken}/teams/${userToken}`);
  };

  const { isParticipant, reviewCycle, dashboardRows, openTasksResponse } =
    reviewCycleSummary;
  const hasTeamParticipants = dashboardRows?.length > 0;
  const filteredRows =
    cycleShowingFilter === ShowingOption.ALL
      ? dashboardRows
      : dashboardRows.filter((row) => isManagerOf(row.participant, user.token));

  return (
    <PageContent>
      <PageHeader
        title={
          <Row gap={6}>
            {reviewCycle.name} <ReviewCycleStateTag reviewCycle={reviewCycle} />
          </Row>
        }
        mobileTitle={reviewCycle.name}
        navigateBack
      />
      <Column gap={24}>
        {isParticipant && (
          <Pane>
            <Column gap={24}>
              <UserMessage user={user} large />
              <IndividualReviewCycleStatus
                reviewCycleSummary={reviewCycleSummary}
                onChange={reloadSummary}
              />
            </Column>
          </Pane>
        )}
        {hasTeamParticipants && (
          <Pane>
            <Row>
              <Header2>Team</Header2>
              <Spacer size={12} />
              <TeamPicker onChange={handleTeamChange} />
              <GrowingSpacer />
              {managesManagers && (
                <Select
                  value={cycleShowingFilter}
                  onChange={(newValue) => {
                    setCycleShowingFilter(newValue);
                  }}
                  style={{ maxWidth: 240 }}
                >
                  <Select.Option value={ShowingOption.ALL}>
                    Show all team members
                  </Select.Option>
                  <Select.Option value={ShowingOption.REPORTS_ONLY}>
                    Show direct reports only
                  </Select.Option>
                </Select>
              )}
            </Row>
            <Spacer />
            <ReviewCycleDashboard
              dashboardRows={filteredRows}
              onChange={handleDashboardChange}
              reviewCycle={reviewCycle}
            />
          </Pane>
        )}
        {reviewCycle.peerReviewCycleEnabled && (
          <Pane>
            <Header2 id="peer-feedback-requests">
              Open Feedback Requests
            </Header2>
            <Spacer />
            <ReviewCycleTasks
              tasksResponse={openTasksResponse}
              reloadTasks={reloadSummary}
            />
          </Pane>
        )}
      </Column>
    </PageContent>
  );
};

export default ReviewCycleDashboardPage;
