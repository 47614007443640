import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  PlayCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  createReviewCycleBatchEvent,
  deleteReviewCycleBatchEvent,
  updateReviewCycleBatchEvent,
} from '@client/ReviewCyclesClient';
import { processScheduledEvent } from '@client/ScheduledEventsClient';
import { dateString } from '@shared/dateString';
import { formatDate } from '@shared/formatDate';
import { productDetails } from '@shared/reflections';
import {
  IReviewCycle,
  IReviewCycleBatchEvent,
  ReviewCycleEventType,
  ReviewCycleToken,
} from '@shared/review-cycles';
import { Colors } from '@web/app/styles/ColorStyles';
import { useApi } from '@web/common/useApi';
import { BackLink } from '@web/components/BackButton';
import { SelectDateHour } from '@web/components/SelectDateHour';
import { Column, Grid, GrowingSpacer, Row } from '@web/components/layout';
import { Subheading } from '@web/components/text';
import { Header3, Text } from '@web/components/typography';
import { useScheduledEvent } from '@web/surveys/cycles/useScheduledEvent';
import {
  Button,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Table,
  Tooltip,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { sortBy } from 'lodash';
import pluralize from 'pluralize';
import randomstring from 'randomstring';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';

import { AdminPageContent } from '../admin/AdminPageContent';
import { BorderedPane } from './BorderedPane';
import { countCycleComponents } from './countCycleComponents';

enum EventGroupType {
  REVIEW_CYCLE = 'REVIEW_CYCLE',
  PEER_REVIEW = 'PEER_REVIEW',
  UPWARD_FEEDBACK = 'UPWARD_FEEDBACK',
  SELF_REVIEW = 'SELF_REVIEW',
  MANAGER_REVIEW = 'MANAGER_REVIEW',
}

const REVIEW_CYCLE_GROUP = 'Review Cycle';
const PEER_REVIEW_GROUP = 'Peer Reviews';
const UPWARD_FEEDBACK_GROUP = 'Upward Feedback';
const SELF_REVIEW_GROUP = 'Self Reviews';
const MANAGER_REVIEW_GROUP = 'Manager Reviews';

const ALL_NOTIFICATIONS: Record<ReviewCycleEventType, NotificationDetails> = {
  [ReviewCycleEventType.START_REVIEW_CYCLE]: {
    name: 'Start Cycle',
    group: EventGroupType.REVIEW_CYCLE,
    required: true,
  },
  [ReviewCycleEventType.CYCLE_INTRO]: {
    name: 'Introduction',
    group: EventGroupType.REVIEW_CYCLE,
    required: true,
  },
  [ReviewCycleEventType.START_PEER_REVIEW_CYCLE]: {
    name: 'Start Peer Review Cycle',
    group: EventGroupType.PEER_REVIEW,
    required: true,
  },
  [ReviewCycleEventType.START_UPWARD_REVIEW_CYCLE]: {
    name: 'Start Upward Feedback Cycle',
    group: EventGroupType.UPWARD_FEEDBACK,
    required: true,
  },
  [ReviewCycleEventType.PEER_SELECTION_REMINDER]: {
    name: 'Peer Selection Reminder',
    group: EventGroupType.PEER_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.PEER_APPROVAL_REMINDER]: {
    name: 'Peer Approval Reminder',
    group: EventGroupType.PEER_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.SEND_PEER_FEEDBACK_REQUESTS]: {
    name: 'Send Peer Feedback Requests',
    group: EventGroupType.PEER_REVIEW,
    required: true,
  },
  [ReviewCycleEventType.FEEDBACK_REQUEST_REMINDER]: {
    name: 'Feedback Request Reminder',
    group: EventGroupType.PEER_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.FEEDBACK_REQUEST_DUE_REMINDER]: {
    name: 'Feedback Request Due Reminder',
    group: EventGroupType.PEER_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.FEEDBACK_REQUEST_OVERDUE_REMINDER]: {
    name: 'Feedback Request Overdue Reminder',
    group: EventGroupType.PEER_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.SEND_UPWARD_FEEDBACK_REQUESTS]: {
    name: 'Send Upward Feedback Requests',
    group: EventGroupType.UPWARD_FEEDBACK,
    required: true,
  },
  [ReviewCycleEventType.UPWARD_FEEDBACK_REQUEST_REMINDER]: {
    name: 'Upward Feedback Request Reminder',
    group: EventGroupType.UPWARD_FEEDBACK,
    required: false,
  },
  [ReviewCycleEventType.UPWARD_FEEDBACK_REQUEST_DUE_REMINDER]: {
    name: 'Upward Feedback Request Due Reminder',
    group: EventGroupType.UPWARD_FEEDBACK,
    required: false,
  },
  [ReviewCycleEventType.UPWARD_FEEDBACK_REQUEST_OVERDUE_REMINDER]: {
    name: 'Upward Feedback Request Overdue Reminder',
    group: EventGroupType.UPWARD_FEEDBACK,
    required: false,
  },
  [ReviewCycleEventType.START_SELF_REVIEWS]: {
    name: 'Start Self Reviews',
    group: EventGroupType.SELF_REVIEW,
    required: true,
  },
  [ReviewCycleEventType.SELF_REVIEW_REMINDER]: {
    name: 'Self Review Reminder',
    group: EventGroupType.SELF_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.SELF_REVIEW_DUE_REMINDER]: {
    name: 'Self Review Due Reminder',
    group: EventGroupType.SELF_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.SELF_REVIEW_OVERDUE_REMINDER]: {
    name: 'Self Review Overdue Reminder',
    group: EventGroupType.SELF_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.START_MANAGER_REVIEWS]: {
    name: 'Start Manager Reviews',
    group: EventGroupType.MANAGER_REVIEW,
    required: true,
  },
  [ReviewCycleEventType.MANAGER_REVIEW_REMINDER]: {
    name: 'Manager Review Reminder',
    group: EventGroupType.MANAGER_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.MANAGER_REVIEW_DUE_REMINDER]: {
    name: 'Manager Review Due Reminder',
    group: EventGroupType.MANAGER_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.MANAGER_REVIEW_OVERDUE_REMINDER]: {
    name: 'Manager Review Overdue Reminder',
    group: EventGroupType.MANAGER_REVIEW,
    required: false,
  },
  [ReviewCycleEventType.RELEASE_REMINDER]: {
    name: 'Release Reminder',
    group: EventGroupType.REVIEW_CYCLE,
    required: false,
  },
};

interface NotificationDetails {
  name: string;
  group: EventGroupType;
  required: boolean;
}

export const AdminEditCycleSchedulePage: React.FC = () => {
  const { reviewCycleToken } = useParams<{
    reviewCycleToken: ReviewCycleToken;
  }>();

  const { data: reviewCycle, mutate: reloadReviewCycle } = useApi<IReviewCycle>(
    `/review-cycles/${reviewCycleToken}`,
  );

  if (!reviewCycle?.events) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const {
    peerReviewCycleEnabled,
    selfReflectionCycleEnabled,
    managerReflectionCycleEnabled,
  } = reviewCycle;
  const gridColumnCount = countCycleComponents(reviewCycle);
  const gridColumns = new Array<string>(gridColumnCount).fill('1fr').join(' ');

  const columns: ColumnsType<IReviewCycleBatchEvent> = [
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
    },
    {
      title: 'Scheduled Time',
      key: 'scheduled-time',
      render: (_, row) => {
        if (!row.scheduledEvent) {
          return 'N/A';
        }
        const hourDate = new Date();
        hourDate.setHours(row.scheduledEvent.hour, 0, 0, 0);
        const time = format(hourDate, 'p');
        const date = formatDate(
          new Date(`${row.scheduledEvent.date}T23:59:59.999Z`),
        );
        return `${date} ${time}`;
      },
    },
    {
      title: 'Events',
      key: 'events',
      render: (_, row) => {
        return (
          <ol style={{ margin: 0, paddingLeft: 16 }}>
            {row.events.map((e, index) => {
              const details = ALL_NOTIFICATIONS[e];
              return (
                <li key={index} style={{ margin: 0 }}>
                  {details.name}
                </li>
              );
            })}
          </ol>
        );
      },
    },
    {
      title: 'Processed Time',
      key: 'processed-time',
      render: (_, row) => {
        return (
          <Text>
            {row.scheduledEvent?.processedDate
              ? formatDate(row.scheduledEvent.processedDate, true)
              : 'N/A'}
          </Text>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, row) => {
        const handleDelete = async () => {
          try {
            await deleteReviewCycleBatchEvent(reviewCycle.token, row.token);
            void reloadReviewCycle();
            void message.success('Success');
          } catch (error) {
            void message.error('Failed');
          }
        };
        const handleRunNow = async () => {
          try {
            await processScheduledEvent(row.scheduledEventToken);
            void reloadReviewCycle();
            void message.success('Success');
          } catch (error) {
            void message.error('Failed');
          }
        };
        return (
          <Row gap={6}>
            <AddBatchEventButton
              disabled={!!row.scheduledEvent.processedDate}
              reviewCycle={reviewCycle}
              onSave={() => {
                void reloadReviewCycle();
              }}
              batch={row}
            />
            <Popconfirm
              title="Delete"
              description="Are you sure you want to delete this event?"
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={!!row.scheduledEvent.processedDate}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
            {process.env.NODE_ENV !== 'production' && (
              <Popconfirm
                title="Run now"
                description="Are you sure you want to run this event now?"
                onConfirm={handleRunNow}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={!!row.scheduledEvent.processedDate}
                  icon={<PlayCircleOutlined />}
                />
              </Popconfirm>
            )}
          </Row>
        );
      },
    },
  ];

  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );
  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );

  const presentEvents = new Set<ReviewCycleEventType>(
    reviewCycle.batchEvents.flatMap((batch) => batch.events),
  );

  const missingRequiredEvents = relevantEvents(reviewCycle)
    .filter((eventType) => {
      return (
        !presentEvents.has(eventType) && ALL_NOTIFICATIONS[eventType].required
      );
    })
    .map((eventType) => {
      return ALL_NOTIFICATIONS[eventType].name;
    })
    .join(', ');

  const missingOptionalEvents = relevantEvents(reviewCycle)
    .filter((eventType) => {
      return (
        !presentEvents.has(eventType) && !ALL_NOTIFICATIONS[eventType].required
      );
    })
    .map((eventType) => {
      return ALL_NOTIFICATIONS[eventType].name;
    })
    .join(', ');

  const sortedBatches = sortBy(reviewCycle.batchEvents, [
    'scheduledEvent.date',
    'scheduledEvent.hour',
  ]);
  return (
    <AdminPageContent>
      <Column gap={6}>
        <Row>
          <BackLink
            to={`/admin/review-cycles/${reviewCycle.token}/dashboard`}
          />
        </Row>
        <Row>
          <Subheading>{'Manage Schedule'}</Subheading>
        </Row>
      </Column>
      <Column gap={24}>
        <Grid gap={12} columns={gridColumns}>
          {peerReviewCycleEnabled && (
            <BorderedPane>
              <Column gap={12}>
                <Header3>Peer Feedback</Header3>
                <Column>
                  <Text>Peer Selection</Text>
                  <Text>
                    {dateString(reviewCycle.peerSelectionStartDate)} -{' '}
                    {dateString(reviewCycle.peerSelectionEndDate)}
                  </Text>
                </Column>
                <Column>
                  <Text>Peer Approval</Text>
                  <Text>
                    {dateString(reviewCycle.peerApprovalStartDate)} -{' '}
                    {dateString(reviewCycle.peerApprovalEndDate)}
                  </Text>
                </Column>

                <Column>
                  <Text>Feedback Requests</Text>
                  <Text>
                    {dateString(reviewCycle.peerFeedbackStartDate)} -{' '}
                    {dateString(reviewCycle.peerFeedbackEndDate)}
                  </Text>
                </Column>
              </Column>
            </BorderedPane>
          )}
          {selfReflectionCycleEnabled && (
            <BorderedPane>
              <Header3>Self {pluralize(selfReviewProduct.titleCase)}</Header3>
              <Column>
                <Text>Schedule</Text>
                <Text>
                  {dateString(reviewCycle.selfReviewStartDate)} -{' '}
                  {dateString(reviewCycle.selfReviewEndDate)}
                </Text>
              </Column>
            </BorderedPane>
          )}
          {managerReflectionCycleEnabled && (
            <BorderedPane>
              <Header3>
                Manager {pluralize(managerReviewProduct.titleCase)}
              </Header3>
              <Column>
                <Text>Schedule</Text>
                <Text>
                  {dateString(reviewCycle.managerReviewStartDate)} -{' '}
                  {dateString(reviewCycle.managerReviewEndDate)}
                </Text>
              </Column>
            </BorderedPane>
          )}
        </Grid>
        <BorderedPane>
          <Column gap={6}>
            <Row>
              <Row gap={6}>
                <Header3>Events</Header3>
                {missingRequiredEvents.length > 0 ? (
                  <Tooltip
                    title={`Some required events aren't scheduled: ${missingRequiredEvents}`}
                  >
                    <WarningOutlined
                      style={{ fontSize: '18px', color: 'var(--color-error)' }}
                    />
                  </Tooltip>
                ) : null}
                {missingOptionalEvents.length > 0 ? (
                  <Tooltip
                    title={`Some optional events aren't scheduled: ${missingOptionalEvents}`}
                  >
                    <WarningOutlined
                      style={{ fontSize: '18px', color: Colors.supernova }}
                    />
                  </Tooltip>
                ) : null}
              </Row>
              <GrowingSpacer />
              <AddBatchEventButton
                reviewCycle={reviewCycle}
                onSave={async () => {
                  await reloadReviewCycle();
                }}
              >
                Add Event
              </AddBatchEventButton>
            </Row>
            <Table<IReviewCycleBatchEvent>
              dataSource={sortedBatches}
              columns={columns}
              pagination={false}
            ></Table>
          </Column>
        </BorderedPane>
      </Column>
    </AdminPageContent>
  );
};

const AddBatchEventButton: React.FC<{
  reviewCycle: IReviewCycle;
  onSave: () => void;
  children?: React.ReactNode | React.ReactNode[];
  batch?: IReviewCycleBatchEvent;
  disabled?: boolean;
}> = ({ reviewCycle, onSave, children, batch, disabled }) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    onSave();
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        icon={<EditOutlined />}
        type="default"
        onClick={showModal}
      >
        {children}
      </Button>
      {open && (
        <AddBatchEventModal
          reviewCycle={reviewCycle}
          open={open}
          onCancel={handleCancel}
          onSave={handleSave}
          batch={batch}
        />
      )}
    </>
  );
};

const AddBatchEventModal: React.FC<{
  reviewCycle: IReviewCycle;
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
  batch?: IReviewCycleBatchEvent;
}> = ({ reviewCycle, open, onSave, onCancel, batch }) => {
  const initialEvents = batch
    ? batch.events.reduce((lst, eventType) => {
        return addSortableEvent(lst, eventType);
      }, [])
    : [];
  const latestEvent = sortBy(reviewCycle.batchEvents ?? [], [
    'scheduledEvent.date',
    'scheduledEvent.hour',
  ]).at(-1);

  const state = useScheduledEvent(
    batch?.scheduledEvent ?? latestEvent?.scheduledEvent,
  );
  const [sortableEvents, setSortableEvents] =
    useState<ISortableEvent[]>(initialEvents);
  const handleSave = async () => {
    try {
      const update = state.getEntity();
      // Explicit comparison against `false` since `strictNullChecks` aren't enabled.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
      if (update.manual !== false) {
        throw new Error('cannot schedule manual events');
      }

      const events = sortableEvents.map((e) => e.event);
      if (batch) {
        await updateReviewCycleBatchEvent(
          reviewCycle.token,
          batch.token,
          events,
          update,
        );
      } else {
        await createReviewCycleBatchEvent(reviewCycle.token, events, update);
      }
      setSortableEvents([]);
      onSave();
    } catch (error) {
      console.log(error);
      void message.error('Failed');
    }
  };

  if (state.manual) {
    state.setManual(false);
    return (
      <Modal open={open} title={batch ? 'Edit Event' : 'Schedule Event'}>
        <Skeleton />
      </Modal>
    );
  }
  const hasEvents = sortableEvents.length > 0;

  const options = relevantEvents(reviewCycle).map((type) => {
    const details = ALL_NOTIFICATIONS[type];

    return {
      value: type,
      label: details.name,
    };
  });

  const optionGroups = [
    [EventGroupType.REVIEW_CYCLE, REVIEW_CYCLE_GROUP],
    [EventGroupType.PEER_REVIEW, PEER_REVIEW_GROUP],
    [EventGroupType.UPWARD_FEEDBACK, UPWARD_FEEDBACK_GROUP],
    [EventGroupType.SELF_REVIEW, SELF_REVIEW_GROUP],
    [EventGroupType.MANAGER_REVIEW, MANAGER_REVIEW_GROUP],
  ]
    .map(([groupType, groupName]: [EventGroupType, string]) => {
      return {
        label: <span>{groupName}</span>,
        title: groupName,
        options: options.filter(
          (opt) => ALL_NOTIFICATIONS[opt.value].group === groupType,
        ),
      };
    })
    .filter((group) => group.options.length > 0);

  return (
    <Modal
      open={open}
      title={batch ? 'Edit Event' : 'Schedule Event'}
      onCancel={onCancel}
      onOk={handleSave}
    >
      <Column gap={12}>
        <SelectDateHour
          date={state.date}
          hour={state.hour}
          onChange={state.onChange}
          timezone={state.timezone}
        />
        <Column gap={3}>
          <Text>Events</Text>
          {hasEvents ? (
            <ReactSortable
              list={sortableEvents}
              setList={(sortedEvents: ISortableEvent[]) => {
                setSortableEvents([...sortedEvents]);
              }}
              easing="cubic-bezier(0.55, 0, 1, 0.45)"
              animation={100}
              handle=".drag-anchor"
            >
              {sortableEvents.map((sortableEvent) => (
                <SortableEventRow
                  key={sortableEvent.id}
                  item={sortableEvent}
                  onDelete={() => {
                    setSortableEvents(
                      sortableEvents.filter((e) => e.id !== sortableEvent.id),
                    );
                  }}
                />
              ))}
            </ReactSortable>
          ) : (
            <Text>No events</Text>
          )}
        </Column>
        <Column gap={3}>
          <Text>Add Event</Text>
          <Select<ReviewCycleEventType>
            options={optionGroups}
            value={null}
            onChange={(value: ReviewCycleEventType) => {
              setSortableEvents(addSortableEvent(sortableEvents, value));
            }}
          />
        </Column>
      </Column>
    </Modal>
  );
};

const addSortableEvent = (
  sortableEvents: ISortableEvent[],
  toAdd: ReviewCycleEventType,
): ISortableEvent[] => {
  let nextId = randomstring.generate(8);
  while (sortableEvents.some((e) => e.id === nextId)) {
    nextId = randomstring.generate(8);
  }
  return [...sortableEvents, { id: nextId, event: toAdd }];
};

const SortableEventRow: React.FC<{
  item: ISortableEvent;
  onDelete: () => void;
}> = ({ item, onDelete }) => {
  const details = ALL_NOTIFICATIONS[item.event];
  return (
    <SortableRow gap={6}>
      <Row>
        <RowItem>
          <DragAnchor className="drag-anchor" />
        </RowItem>
      </Row>
      <RowItem style={{ flex: 1 }}>
        <ItemText>{details.name}</ItemText>
      </RowItem>
      <RowItem>
        <DeleteOutlined onClick={onDelete} />
      </RowItem>
    </SortableRow>
  );
};

const ItemText = styled(Text)`
  line-height: 18px;
  margin-top: 2px;
`;

const RowItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const DragAnchor = styled(HolderOutlined)`
  opacity: 0;
  font-size: 18px;
  color: #888;
  cursor: grab;
`;
const NewRow = styled(Row)`
  align-items: flex-start;
  margin-bottom: 6px;
`;
const SortableRow = styled(NewRow)`
  min-height: 24px;

  &:hover {
    ${DragAnchor} {
      opacity: 1;
      cursor: grab;

      &:hover {
        color: #333;
      }
    }
  }
`;

interface ISortableEvent {
  id: string;
  event: ReviewCycleEventType;
}

const relevantEvents = ({
  peerReviewCycleEnabled,
  upwardReviewCycleEnabled,
  selfReflectionCycleEnabled,
  managerReflectionCycleEnabled,
}: IReviewCycle): ReviewCycleEventType[] => {
  return Object.keys(ALL_NOTIFICATIONS).filter((type: ReviewCycleEventType) => {
    const groupType = ALL_NOTIFICATIONS[type].group;
    return (
      groupType === EventGroupType.REVIEW_CYCLE ||
      (groupType === EventGroupType.PEER_REVIEW && peerReviewCycleEnabled) ||
      (groupType === EventGroupType.UPWARD_FEEDBACK &&
        upwardReviewCycleEnabled) ||
      (groupType === EventGroupType.SELF_REVIEW &&
        selfReflectionCycleEnabled) ||
      (groupType === EventGroupType.MANAGER_REVIEW &&
        managerReflectionCycleEnabled)
    );
  }) as ReviewCycleEventType[];
};
