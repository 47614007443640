import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDebugReport } from '@client/BedrockConfigurationClient';
import {
  BedrockConfigDebugRunToken,
  IBedrockConfigDebugRun,
} from '@shared/bedrock';
import { formatLongDate } from '@shared/formatLongDate';
import { OrganizationToken, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Row } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { Skeleton, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Table, { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useParams } from 'react-router-dom';

import { BedrockTabs } from './BedrockTabs';

export const BedrockConfigDebugRunPage: React.FC = () => {
  const { organizationToken, configDebugRunToken } = useParams<{
    organizationToken: OrganizationToken;
    configDebugRunToken: BedrockConfigDebugRunToken;
  }>();

  const { data: run } = useDebugReport(organizationToken, configDebugRunToken);

  return (
    <PageContent>
      <PageHeader title="Scribe AI" />
      <BedrockTabs />
      <Pane>
        <Column gap={24}>
          <Column gap={12}>
            <Header2>Debug Config Run</Header2>
          </Column>
          <ViewConfigDebugReport
            run={run}
            defaultShowLinear={true}
            defaultShowJira={true}
            defaultShowGithub={true}
          />
        </Column>
      </Pane>
    </PageContent>
  );
};

interface ConfigDebugReportRow {
  token: UserToken;
  name: string;
  linearName?: string;
  jiraName?: string;
  githubUser?: string;
  linear: boolean;
  jira: boolean;
  github: boolean;
}

const MatchStatus: React.FC<{ matched: boolean }> = ({ matched }) => {
  if (!matched) {
    return <CloseOutlined style={{ color: 'var(--color-error)' }} />;
  }
  return <CheckOutlined style={{ color: 'var(--color-success)' }} />;
};

export const ViewConfigDebugReport: React.FC<{
  run?: IBedrockConfigDebugRun;
  defaultShowLinear: boolean;
  defaultShowJira: boolean;
  defaultShowGithub: boolean;
}> = ({ run, defaultShowLinear, defaultShowJira, defaultShowGithub }) => {
  const [showLinear, setShowLinear] = React.useState(defaultShowLinear);
  const [showJira, setShowJira] = React.useState(defaultShowJira);
  const [showGithub, setShowGithub] = React.useState(defaultShowGithub);

  const report = run?.report;
  const rows = report?.users?.map(
    (u): ConfigDebugReportRow => ({
      ...u,
      ...(report.matches[u.token] ?? {
        linear: false,
        jira: false,
        github: false,
      }),
    }),
  );

  const linearColumns = showLinear
    ? [
        {
          dataIndex: 'linearName',
          title: 'Linear Name',
          render(linearName: string | undefined) {
            return linearName ?? '-';
          },
        },
        {
          dataIndex: 'linear',
          title: 'Linear Match',
          render(linear: boolean) {
            return <MatchStatus matched={linear} />;
          },
        },
      ]
    : [];
  const jiraColumns = showJira
    ? [
        {
          dataIndex: 'jiraName',
          title: 'Jira Name',
          render(jiraName: string | undefined) {
            return jiraName ?? '-';
          },
        },
        {
          dataIndex: 'jira',
          title: 'Jira Match',
          render(jira: boolean) {
            return <MatchStatus matched={jira} />;
          },
        },
      ]
    : [];
  const githubColumns = showGithub
    ? [
        {
          dataIndex: 'githubUser',
          title: 'GitHub User',
          render(githubUser: string | undefined) {
            return githubUser ?? '-';
          },
        },
        {
          dataIndex: 'github',
          title: 'GitHub Match',
          render(github: boolean) {
            return <MatchStatus matched={github} />;
          },
        },
      ]
    : [];

  const columns: ColumnsType<ConfigDebugReportRow> = [
    {
      dataIndex: 'name',
      title: 'Name',
    },
    ...linearColumns,
    ...jiraColumns,
    ...githubColumns,
  ];
  return (
    <Column gap={12}>
      <Row gap={12}>
        <Row gap={6}>
          <Text strong>Start Date</Text>
          <Text>
            {run?.startDate ? (
              formatLongDate(run.startDate, true)
            ) : (
              <Skeleton paragraph={{ rows: 1 }} />
            )}
          </Text>
        </Row>
        <Row gap={6}>
          <Text strong>End Date</Text>
          <Text>
            {run?.endDate ? (
              formatLongDate(run.endDate, true)
            ) : (
              <Skeleton paragraph={{ rows: 1 }} />
            )}
          </Text>
        </Row>
      </Row>
      <Row gap={12}>
        <LabeledSwitch
          label="Jira"
          value={showJira}
          onChange={(value) => {
            setShowJira(value);
          }}
        />
        <LabeledSwitch
          label="Linear"
          value={showLinear}
          onChange={(value) => {
            setShowLinear(value);
          }}
        />
        <LabeledSwitch
          label="GitHub"
          value={showGithub}
          onChange={(value) => {
            setShowGithub(value);
          }}
        />
      </Row>

      <Table
        rowKey="token"
        columns={columns}
        dataSource={rows}
        loading={!rows}
        pagination={false}
      />

      <Column gap={6}>
        <Column gap={3}>
          <Text>Unmatched Linear Names</Text>
          <TextArea value={report?.unknownLinearNames?.join('\n')} />
        </Column>
        <Column gap={3}>
          <Text>Unmatched Jira Names</Text>
          <TextArea value={report?.unknownJiraNames?.join('\n')} />
        </Column>
        <Column gap={3}>
          <Text>Unmatched GitHub Users</Text>
          <TextArea value={report?.unknownGithubUsers?.join('\n')} />
        </Column>
      </Column>
    </Column>
  );
};

const LabeledSwitch: React.FC<{
  label: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}> = ({ label, value, onChange }) => {
  return (
    <Column>
      <Text>{label}</Text>
      <Row>
        <Switch checked={value} onChange={onChange} />
      </Row>
    </Column>
  );
};
