import {
  MDXEditor,
  MDXEditorMethods,
  codeBlockPlugin,
  headingsPlugin,
  imagePlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
} from '@mdxeditor/editor';
import * as React from 'react';
import styled from 'styled-components';

export const Markdown: React.FC<{ value: string }> = ({ value }) => {
  const editorRef = React.useRef<MDXEditorMethods>(null);

  React.useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setMarkdown(value ?? '');
    }
  }, [value]);

  return (
    <MDXEditor
      className="mdxeditor-readonly"
      contentEditableClassName="mdx-editor-content"
      markdown={value ?? ''}
      plugins={[
        quotePlugin(),
        listsPlugin({}),
        linkPlugin(),
        headingsPlugin(),
        imagePlugin(),
        codeBlockPlugin({
          codeBlockEditorDescriptors: [
            {
              priority: 0,
              match: () => true,
              Editor: (props: any) => <CodeViewer>{props.code}</CodeViewer>,
            },
          ],
        }),
      ]}
      readOnly
      ref={editorRef}
      suppressHtmlProcessing
    />
  );
};

const CodeViewer = styled.pre`
  background: #f5f5f5;
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: var(--default-border-radius);
  font-size: 13px;
`;
