import { ITeamMetrics, TeamMetrics, UserMetrics } from '@shared/analytics';
import { ITeam, IUser, TeamToken } from '@shared/types';
import { get } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useOverviewAnalytics(teamToken?: TeamToken) {
  const url = stringifyUrl({
    url: `/analytics/overview`,
    query: {
      team: teamToken,
    },
  });

  return useApi<ITeamMetrics>(url);
}

export function overviewAnalytics(teamToken?: TeamToken) {
  const url = stringifyUrl({
    url: `/analytics/overview`,
    query: {
      team: teamToken,
    },
  });

  return get<ITeamMetrics>(url);
}

export function useTeamAnalytics(teamToken?: TeamToken) {
  const url = stringifyUrl({
    url: `/analytics/team`,
    query: {
      team: teamToken,
    },
  });

  return useApi<{ teamMetrics: TeamMetrics; teams: ITeam[] }>(url);
}

export function teamAnalytics(teamToken?: TeamToken) {
  const url = stringifyUrl({
    url: `/analytics/team`,
    query: {
      team: teamToken,
    },
  });

  return get<{ teamMetrics: TeamMetrics; teams: ITeam[] }>(url);
}

export function useIndividualAnalytics(teamToken?: TeamToken) {
  const url = stringifyUrl({
    url: `/analytics/individual`,
    query: {
      team: teamToken,
    },
  });

  return useApi<{ userMetrics: UserMetrics; users: IUser[]; teams: ITeam[] }>(
    url,
  );
}

export function individualAnalytics(teamToken?: TeamToken) {
  const url = stringifyUrl({
    url: `/analytics/individual`,
    query: {
      team: teamToken,
    },
  });

  return get<{ userMetrics: UserMetrics; users: IUser[]; teams: ITeam[] }>(url);
}
