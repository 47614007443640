import { ISearchResults, OrganizationToken, UserMapItem } from '@shared/types';
import { errorColor } from '@web/app/styles/ColorStyles';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { usePagination } from '@web/common/usePagination';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Button, Switch } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import * as React from 'react';

import { GithubAssignLoginModal } from './GithubAssignLoginModal';
import { GithubMember } from './GithubMember';

interface IGithubMemberSearchRequest {
  skip?: number;
  limit?: number;
  showAllUsers?: boolean;
  organizationToken?: OrganizationToken;
}

export const GithubMemberTable: React.FC<{
  organizationToken?: OrganizationToken;
}> = ({ organizationToken }) => {
  const { user } = useAuth();
  const [assigningUser, setAssigningUser] = React.useState<UserMapItem>(null);
  const [showAllUsers, setShowAllUsers] = React.useState(false);
  const [searchError, setSearchError] = React.useState<string>();
  const [searchResults, setSearchResults] =
    React.useState<ISearchResults<UserMapItem>>(null);
  const { pagination, page, pageSize, setPage } = usePagination(
    searchResults?.total,
  );
  const searchOrganizationToken = organizationToken ?? user.organizationToken;
  const searchMembers = async () => {
    setSearchError(null);
    try {
      const skip = (page - 1) * pageSize;
      const limit = pageSize;
      const results = await post<
        IGithubMemberSearchRequest,
        ISearchResults<UserMapItem>
      >(`/github/members/search`, {
        limit,
        skip,
        organizationToken: searchOrganizationToken,
        showAllUsers,
      });
      setSearchResults(results);
    } catch (error) {
      setSearchError(error.message);
    }
  };

  React.useEffect(() => {
    void searchMembers();
  }, [searchOrganizationToken, page, pageSize, showAllUsers]);

  const handleToggleShowAllUsers = () => {
    setShowAllUsers(!showAllUsers);
    setPage(1);
  };

  const handleAssignmentChange = () => {
    void searchMembers();
    setAssigningUser(null);
  };

  const { results: members } = searchResults ?? {
    results: [],
  };
  const columns: ColumnsType<UserMapItem> = [
    {
      title: 'User',
      key: 'user',
      render: (_, user) => (
        <Row gap={10}>
          <UserAvatar size={28} user={user} /> <Text>{user.name}</Text>
        </Row>
      ),
    },
    {
      title: 'Github Login',
      dataIndex: 'userLogin',
      key: 'userLogin',
      render: (_, user) =>
        user.githubLogin ? <GithubMember login={user.githubLogin} /> : null,
    },
    {
      title: null,
      key: 'actions',
      align: 'right',
      render: (_, user) => (
        <Button
          onClick={() => {
            setAssigningUser(user);
          }}
          size="small"
        >
          Assign Login
        </Button>
      ),
    },
  ];
  return (
    <Column gap={24}>
      <Header3>Members</Header3>
      <Row gap={12}>
        <Column>
          <Row gap={12}>
            <Text>Show only users with an assigned Github login</Text>
            <Switch
              checked={!showAllUsers}
              onChange={handleToggleShowAllUsers}
            />
          </Row>
        </Column>
        <GrowingSpacer />
        {/* <Button type="primary">Auto-assign logins</Button> */}
      </Row>
      <Column gap={12}>
        {searchError && (
          <Row
            gap={12}
            style={{
              padding: '12px 18px',
              background: errorColor.lighten(0.5),
              borderRadius: 'var(--default-border-radius)',
            }}
          >
            {searchError}
          </Row>
        )}
        <Table
          rowKey="token"
          columns={columns}
          dataSource={members}
          pagination={pagination}
        />
      </Column>
      {assigningUser && (
        <GithubAssignLoginModal
          user={assigningUser}
          onChange={handleAssignmentChange}
          onCancel={() => {
            setAssigningUser(null);
          }}
        />
      )}
    </Column>
  );
};
