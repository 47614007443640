import { CloseOutlined } from '@ant-design/icons';
import { IGoalList } from '@shared/goals';
import { useResponsive } from '@web/app/responsive';
import { GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const ViewGoalList: React.FC<{
  goalList: IGoalList;
  linkTo?: string;
  onRemove?: () => void;
}> = ({ goalList, linkTo, onRemove }) => {
  const { isMobile } = useResponsive();

  return (
    <Link to={linkTo ?? `/goals/lists/${goalList.token}`}>
      <Row>
        <Text>{goalList.title}</Text>
        {isMobile ? <Spacer size={6} /> : <GrowingSpacer />}
        {onRemove && (
          <Button
            type="text"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onRemove();
            }}
          >
            <CloseOutlined />
          </Button>
        )}
      </Row>
    </Link>
  );
};
