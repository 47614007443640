import { DeleteOutlined } from '@ant-design/icons';
import { Feature } from '@shared/features';
import { ITeam, IUser } from '@shared/types';
import { del } from '@web/common/api';
import { useFeature } from '@web/common/useFeature';
import { Column, NoWrap, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Button, Empty, Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';

import { useModalConfirm } from '../../common/useModalConfirm';
import { stopPropagation } from '../StopPropagation';
import { UserAvatar } from '../UserAvatar';

export const TeamsTable: React.FC<{
  teams: ITeam[];
  onTeamClick: (team: ITeam) => void;
  onTeamRemove: (team: ITeam) => void;
}> = ({ teams, onTeamClick, onTeamRemove }) => {
  const { booleanValue: usingHRBPs } = useFeature(Feature.USE_HRBPS);
  const { confirm, contextHolder } = useModalConfirm();
  const handleRemoveTeam = async (team: ITeam) => {
    if (
      await confirm(
        `Removing this team will also unassign the manager for all members of the team. Members can be readded to another team at any time.`,
      )
    ) {
      try {
        await del(`/teams/${team.token}`);
        void message.success('Team has been removed');
        onTeamRemove(team);
      } catch (error) {
        void message.error('Failed to remove team');
      }
    }
  };

  const columns: ColumnsType<ITeam> = [
    {
      title: 'Manager',
      dataIndex: 'manager',
      key: 'manager',
      render: (manager) =>
        manager ? (
          <Row gap={12}>
            <UserAvatar user={manager} size={24} />
            <NoWrap>{manager.name}</NoWrap>
          </Row>
        ) : (
          '-'
        ),
    },
    usingHRBPs
      ? {
          title: 'HRBP',
          dataIndex: 'hrbp',
          key: 'hrbp',
          render: (hrbp) =>
            hrbp ? (
              <Row gap={12}>
                <UserAvatar user={hrbp} size={24} />
                <NoWrap>{hrbp.name}</NoWrap>
              </Row>
            ) : (
              '-'
            ),
        }
      : null,
    {
      title: 'Team Members',
      dataIndex: 'members',
      key: 'teamMembers',
      render: (members: IUser[]) => (
        <Text>{members.map((user) => user.name).join(', ')}</Text>
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (_, team: ITeam) => (
        <span onClick={stopPropagation}>
          <Button
            type="link"
            onClick={() => {
              void handleRemoveTeam(team);
            }}
          >
            <DeleteOutlined />
          </Button>
        </span>
      ),
    },
  ].filter((column) => !!column) as any;

  return (
    <Column>
      <Table
        columns={columns}
        dataSource={teams}
        pagination={false}
        locale={{
          emptyText: <Empty description="No teams exist" />,
        }}
        onRow={(team) => ({
          onClick: () => {
            onTeamClick(team);
          },
        })}
        rowKey="token"
      />
      {contextHolder}
    </Column>
  );
};
