import {
  IOrganizationMember,
  IUser,
  OrganizationToken,
  UserMapItem,
  UserRole,
  UserToken,
} from '@shared/types';
import { del, get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function create(userAttributes: Partial<IUser>) {
  return post<Partial<IUser>>(`/users`, userAttributes);
}

export function adminCreateUser(name: string, email: string, role: UserRole) {
  return post<
    {
      name: string;
      email: string;
      role: UserRole;
    },
    IUser
  >(`/users/admin/create`, {
    name: name,
    email: email,
    role: role,
  });
}

export function useGetTeamMembers(managerToken?: UserToken) {
  const url = stringifyUrl({
    url: `/users/reports`,
    query: {
      managerToken: managerToken,
    },
  });

  return useApi<IUser[]>(url);
}

export function getTeamMembers(managerToken?: UserToken) {
  const url = stringifyUrl({
    url: `/users/reports`,
    query: {
      managerToken: managerToken,
    },
  });

  return get<IUser[]>(url);
}

export function updateSelf(userAttributes: Partial<IUser>) {
  return patch<Partial<IUser>>(`/users/me`, userAttributes);
}

export function update(userToken: UserToken, userAttributes: Partial<IUser>) {
  return patch<Partial<IUser>>(`/users/${userToken}`, userAttributes);
}

export function updatePassword(userToken: UserToken, password: string) {
  return put<{ password: string }>(`/users/${userToken}/password`, {
    password: password,
  });
}

export function resetFirstLogin(userToken: UserToken) {
  return del(`/users/${userToken}/firstLogin`);
}

export function useSelf() {
  const url = stringifyUrl({
    url: `/users/me`,
    query: {},
  });

  return useApi<{ user: IUser; manager?: IUser }>(url);
}

export function self() {
  const url = stringifyUrl({
    url: `/users/me`,
    query: {},
  });

  return get<{ user: IUser; manager?: IUser }>(url);
}

export function useGetProfile(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/users/${userToken}/profile`,
    query: {},
  });

  return useApi<{
    user: IUser;
    managesPeople: boolean;
    managesManagers: boolean;
    organizationMembers: IOrganizationMember[];
    managers: IUser[];
  }>(url);
}

export function getProfile(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/users/${userToken}/profile`,
    query: {},
  });

  return get<{
    user: IUser;
    managesPeople: boolean;
    managesManagers: boolean;
    organizationMembers: IOrganizationMember[];
    managers: IUser[];
  }>(url);
}

export function useUserDetails(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/users/${userToken}`,
    query: {},
  });

  return useApi<UserMapItem>(url);
}

export function userDetails(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/users/${userToken}`,
    query: {},
  });

  return get<UserMapItem>(url);
}

export function updatePrimaryManager(
  userToken: UserToken,
  primaryManager: UserToken,
) {
  return put<{ primaryManager: `u_${string}` }>(
    `/users/${userToken}/primaryManager`,
    { primaryManager: primaryManager },
  );
}

export function useOrganizationAdmins(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/users/${organizationToken}/admins`,
    query: {},
  });

  return useApi<IUser[]>(url);
}

export function organizationAdmins(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/users/${organizationToken}/admins`,
    query: {},
  });

  return get<IUser[]>(url);
}

export function userSearch(
  skip: number,
  limit: number,
  searchQuery?: string,
  organizationToken?: OrganizationToken,
  userRole?: UserRole,
) {
  return post<{
    skip: number;
    limit: number;
    search: string;
    organization: `o_${string}`;
    role: UserRole;
  }>(`/users/search`, {
    skip: skip,
    limit: limit,
    search: searchQuery,
    organization: organizationToken,
    role: userRole,
  });
}

export function remove(userToken: UserToken) {
  return del(`/users/${userToken}`);
}
