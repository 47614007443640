import { createCheckIn } from '@client/SurveyCyclesClient';
import { IGoalList } from '@shared/goals';
import { ISurveyCycle } from '@shared/surveys';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Form, Input, Modal, message } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface Props {
  onCancel: () => void;
  onAdd: (survey: ISurveyCycle) => void;
}

export const AddCheckInModal: React.FC<Props> = ({ onAdd, onCancel }) => {
  const [form] = Form.useForm<IGoalList>();
  const [isSaving, setIsSaving] = React.useState(false);
  const [name, setName] = React.useState('');

  const handleSave = async () => {
    if (!name?.trim() || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      const survey = await createCheckIn(name.trim());
      void message.success('Success');
      onAdd(survey);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Add Check-in"
      open={true}
      onOk={() => {
        void handleSave();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Add"
      okButtonProps={{
        disabled: !name?.trim(),
      }}
    >
      <Column gap={12}>
        <FormElement>
          <Text>Name</Text>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            autoFocus
          />
        </FormElement>
      </Column>
    </Modal>
  );
};

const FormElement = styled(Column)`
  gap: 6px;
  max-width: 600px;
`;
