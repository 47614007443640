import { useSelectGoalsSearch } from '@client/GoalsClient';
import { GoalToken, IGoal } from '@shared/goals';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Modal, Select } from 'antd';
import React, { useState } from 'react';

export const SelectGoalModal: React.FC<{
  title: string;
  instructions: string;
  placeholder?: string;
  initialGoal?: IGoal;
  okText: string;
  omitGoals?: GoalToken[];
  onCancel: () => void;
  onSave: (goal: IGoal) => Promise<void>;
}> = ({
  title,
  initialGoal,
  instructions,
  placeholder,
  onSave,
  onCancel,
  okText,
  omitGoals,
}) => {
  const [selectedGoal, setSelectedGoal] = useState<GoalToken>(
    initialGoal?.token ?? null,
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: publicGoals } = useSelectGoalsSearch();

  const handleOk = async () => {
    setIsSaving(true);

    try {
      const goal = selectedGoal
        ? publicGoals.find((g) => g.token === selectedGoal)
        : null;
      await onSave(goal);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleGoalChange = (token?: GoalToken) => {
    setSelectedGoal(token ?? null);
  };

  if (!publicGoals) {
    return null;
  }

  const visibleGoals = omitGoals
    ? publicGoals.filter((goal) => !omitGoals.includes(goal.token))
    : publicGoals;
  return (
    <Modal
      title={title}
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText={okText}
      okButtonProps={{
        disabled: !selectedGoal,
      }}
    >
      <Column gap={12}>
        {instructions && <Text>{instructions}</Text>}
        <Select
          onChange={handleGoalChange}
          value={selectedGoal}
          allowClear
          placeholder={placeholder}
        >
          {visibleGoals.map((goal) => (
            <Select.Option value={goal.token} key={goal.token}>
              {goal.title}
            </Select.Option>
          ))}
        </Select>
      </Column>
    </Modal>
  );
};
