import { formatDate } from '@shared/formatDate';
import { IGoal } from '@shared/goals';
import { useResponsive } from '@web/app/responsive';
import { Row } from '@web/components/layout';
import { SubduedText, Text } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { MiniGoalStateIndicator } from './GoalStateIndicator';

export const ViewGoal: React.FC<{
  goal: IGoal;
  linkTo?: string;
  hideDueDate?: boolean;
}> = ({ goal, linkTo, hideDueDate = false }) => {
  const { isMobile } = useResponsive();

  return (
    <Link to={linkTo ?? `/goals/${goal.token}`}>
      <Row gap={6} style={isMobile ? { alignItems: 'flex-start' } : {}}>
        <Row style={{ justifyContent: 'flex-start' }}>
          <MiniGoalStateIndicator state={goal.state} />
        </Row>
        <Text
          style={{
            textDecoration: goal.archivedDate ? 'line-through' : undefined,
            lineHeight: '18px',
          }}
        >
          {goal.title}
        </Text>
        {!hideDueDate && (
          <Row
            style={
              isMobile
                ? { alignSelf: 'stretch', flexDirection: 'row-reverse' }
                : {}
            }
          >
            {goal.archivedDate ? (
              <SubduedText style={{ whiteSpace: 'nowrap' }}>
                Archived {formatDate(goal.archivedDate)}
              </SubduedText>
            ) : goal.dueDate ? (
              <SubduedText style={{ whiteSpace: 'nowrap' }}>
                Due {formatDate(goal.dueDate)}
              </SubduedText>
            ) : null}
          </Row>
        )}
      </Row>
    </Link>
  );
};
