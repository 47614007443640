import {
  useBedrockChannelInfoAdmin,
  useBedrockChannelInfoInternal,
  useBedrockEligibleChannelsAdmin,
  useBedrockEligibleChannelsInternal,
} from '@client/BedrockConfigurationClient';
import {
  IBedrockChannelInfo,
  MIN_TIME_BETWEEN_CHANNEL_REFRESH_MS,
} from '@shared/bedrock';
import { formatLongDate } from '@shared/formatLongDate';
import { OrganizationToken } from '@shared/types';
import { Column } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { Button, Modal, Skeleton, Tooltip } from 'antd';
import * as React from 'react';

import { SelectChannels } from './SelectChannels';

export const AddChannelsModalV2: React.FC<{
  organizationToken?: OrganizationToken;
  onRefresh?: () => Promise<void>;
  onAdd: (channelIds: string[]) => Promise<void>;
  onCancel: () => void;
  staleAge?: number;
}> = ({
  organizationToken,
  staleAge = MIN_TIME_BETWEEN_CHANNEL_REFRESH_MS,
  onRefresh,
  onAdd,
  onCancel,
}) => {
  const { data: channelInfo, mutate: reloadChannelInfo } = organizationToken
    ? useBedrockChannelInfoInternal(organizationToken)
    : useBedrockChannelInfoAdmin();

  const [isSaving, setIsSaving] = React.useState(false);
  const [channelIds, setChannelIds] = React.useState<string[]>();
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);
    try {
      await onAdd(channelIds);
    } finally {
      setIsSaving(false);
    }
  };
  const handleClose = () => {
    setIsSaving(false);
    onCancel();
  };
  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await onRefresh();
      await reloadChannelInfo();
    } finally {
      setIsRefreshing(false);
    }
  };

  const isRefreshDisabled =
    !channelInfo || isRefreshing || !isRefreshable(channelInfo, staleAge);
  return (
    <Modal
      title="Add Channels"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="500px"
      okText="Save"
      footer={() => {
        if (!channelInfo) {
          return <Skeleton />;
        }
        return (
          <>
            {!channelInfo || isRefreshable(channelInfo, staleAge) ? (
              <Button
                loading={isChannelListRefreshing(channelInfo)}
                onClick={handleRefresh}
                disabled={isRefreshDisabled}
              >
                Refresh channel list
              </Button>
            ) : (
              <Tooltip title="Channel list can be refreshed every 2 minutes">
                <Button disabled loading={isChannelListRefreshing(channelInfo)}>
                  Refresh channel list
                </Button>
              </Tooltip>
            )}

            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" onClick={handleOk}>
              Save
            </Button>
          </>
        );
      }}
    >
      {channelInfo ? (
        <Column gap={12}>
          <Column gap={3}>
            <Text>Channels</Text>
            <SelectChannels
              channels={channelInfo?.channels ? channelInfo.channels : []}
              excludeChannelIds={[]}
              onChange={setChannelIds}
            />
          </Column>
          {channelInfo?.lastUpdated ? (
            <Small>
              Channel list updated on{' '}
              {formatLongDate(channelInfo.lastUpdated, true)}
            </Small>
          ) : undefined}
        </Column>
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
};

const isChannelListRefreshing = (info: IBedrockChannelInfo): boolean => {
  const lastUpdateScheduled = info.lastUpdateScheduled
    ? new Date(info.lastUpdateScheduled)
    : info.lastUpdateScheduled;
  const lastUpdated = info.lastUpdated
    ? new Date(info.lastUpdated)
    : info.lastUpdated;

  if (!lastUpdateScheduled) {
    return false;
  }
  if (!lastUpdated) {
    return true;
  }
  return lastUpdated.getTime() < lastUpdateScheduled.getTime();
};

const isRefreshable = (info: IBedrockChannelInfo, maxAge: number): boolean => {
  if (maxAge < 0) {
    return true;
  }

  const lastUpdateScheduled = info.lastUpdateScheduled
    ? new Date(info.lastUpdateScheduled)
    : info.lastUpdateScheduled;

  if (!lastUpdateScheduled) {
    return true;
  }
  return new Date().getTime() - lastUpdateScheduled.getTime() > maxAge;
};

export const AddChannelsModal: React.FC<{
  organizationToken?: OrganizationToken;
  onAdd: (channelIds: string[]) => Promise<void>;
  onCancel: () => void;
}> = ({ organizationToken, onAdd, onCancel }) => {
  const { data: channels } = organizationToken
    ? useBedrockEligibleChannelsInternal(organizationToken)
    : useBedrockEligibleChannelsAdmin();
  const [isSaving, setIsSaving] = React.useState(false);
  const [channelIds, setChannelIds] = React.useState<string[]>();

  const handleOk = async () => {
    setIsSaving(true);
    try {
      await onAdd(channelIds);
    } finally {
      setIsSaving(false);
    }
  };
  const handleClose = () => {
    setIsSaving(false);
    onCancel();
  };

  return (
    <Modal
      title="Add Channels"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="500px"
      okText="Save"
    >
      <Column gap={12}>
        <Column gap={3}>
          <Text>Channels</Text>
          <SelectChannels
            channels={channels}
            excludeChannelIds={[]}
            onChange={setChannelIds}
          />
        </Column>
      </Column>
    </Modal>
  );
};
