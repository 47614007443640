import { CheckCircleOutlined } from '@ant-design/icons';
import {
  AlignmentState,
  AlignmentType,
  IAlignmentIndicator,
  journalAlignmentCallToAction,
  oneOnOneAlignmentCallToAction,
} from '@shared/alignment';
import { primaryManagerTokenOf } from '@shared/users';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { journalAlignmentCallToActionLink } from './journalAlignmentCallToActionLink';

export const AlignmentCallToAction: React.FC<{
  isIndicatorUser: boolean;
  isManager: boolean;
  indicator: IAlignmentIndicator;
}> = ({ isIndicatorUser, isManager, indicator }) => {
  const navigate = useNavigate();
  if (indicator.state === AlignmentState.GREEN) {
    return null;
  }

  switch (indicator.type) {
    case AlignmentType.ACTIVE_JOURNAL:
      return (
        <Column
          gap={6}
          style={{
            padding: '12px 18px',
            background: '#f5f5f5',
            borderRadius: 'var(--default-border-radius)',
          }}
        >
          <StyledText style={{ fontWeight: 500 }}>
            <CheckCircleOutlined /> Get Aligned
          </StyledText>
          <StyledText>
            {journalAlignmentCallToAction(isIndicatorUser, indicator)}
          </StyledText>
          <Button
            style={{ alignSelf: 'flex-start' }}
            size="small"
            onClick={() => {
              navigate(
                journalAlignmentCallToActionLink(isIndicatorUser, indicator),
              );
            }}
          >
            {isIndicatorUser ? 'Create an Entry' : 'Suggest an Entry'}
          </Button>
        </Column>
      );
    case AlignmentType.ACTIVE_ONE_ON_ONES: {
      const isActionable = isIndicatorUser || isManager;
      const ctaText = isActionable
        ? oneOnOneAlignmentCallToAction(isIndicatorUser, indicator)
        : `${indicator.user.name} should complete a 1-on-1 with their manager`;
      return (
        <Column
          gap={6}
          style={{
            padding: '12px 18px',
            background: '#f5f5f5',
            borderRadius: 'var(--default-border-radius)',
          }}
        >
          <StyledText style={{ fontWeight: 500 }}>
            <CheckCircleOutlined /> Get Aligned
          </StyledText>
          <StyledText>{ctaText}</StyledText>
          {isActionable && (
            <Button
              style={{
                alignSelf: 'flex-start',
              }}
              size="small"
              onClick={() => {
                navigate(
                  `/1on1s/${indicator.userToken}/${primaryManagerTokenOf(
                    indicator.user,
                  )}`,
                );
              }}
            >
              Open 1-on-1
            </Button>
          )}
        </Column>
      );
    }
  }
};

const StyledText = styled(Text)`
  line-height: 20px;
`;
