import { HolderOutlined } from '@ant-design/icons';
import { UserMapItem, UserToken } from '@shared/types';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { StopPropagation } from '@web/components/StopPropagation';
import { UserAvatar } from '@web/components/UserAvatar';
import { Row, Spacer } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { Checkbox, Tooltip } from 'antd';
import { formatDistance } from 'date-fns';
import humanparser from 'humanparser';
import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import { OneOnOneContext } from '../OneOnOneProvider';
import { ITaskItem } from './TaskList';
import { TaskOwner } from './TaskOwner';

export const TaskItemRow: React.FC<{
  taskItem: ITaskItem;
  onResolve: (taskItem: ITaskItem, resolved: boolean) => void;
  onClick?: () => void;
  onAssign?: (assignedToToken: UserToken) => void;
  userViewing?: UserMapItem;
}> = ({ taskItem, onAssign, onClick, onResolve, userViewing }) => {
  const { entityNotes, lastUpdates } = React.useContext(OneOnOneContext);
  const [resolved, setResolved] = React.useState(taskItem.resolved);
  const handleCheckboxClicked = async () => {
    const newResolved = !resolved;
    setResolved(newResolved);
    onResolve(taskItem, newResolved);
  };
  React.useEffect(() => {
    setResolved(taskItem.resolved);
  }, [taskItem.resolved]);

  const noteCount = entityNotes[taskItem.id]?.length ?? 0;
  const highlightColour = complimentaryColor.lighten(0.9);
  const userViewingName = userViewing
    ? humanparser.parseName(userViewing.name).firstName
    : '';

  return (
    <Tooltip
      title={`${userViewingName} is viewing this`}
      open={userViewing ? undefined : false}
      trigger={['hover']}
      placement="topRight"
    >
      <SortableRow
        gap={6}
        style={{
          cursor: 'pointer',
          background: userViewing ? highlightColour : undefined,
          borderRadius: userViewing ? 0 : 8,
          padding: userViewing ? '6px 27px 6px 24px' : '6px 6px 6px 0px',
          margin: userViewing ? '0 -24px' : 0,
          position: 'relative',
        }}
        onClick={() => {
          onClick();
        }}
      >
        {userViewing && (
          <PresenceAvatar>
            <UserAvatar user={userViewing} size={20} />
          </PresenceAvatar>
        )}
        <Row>
          <RowItem>
            <DragAnchor className="drag-anchor" />
          </RowItem>
          <RowItem>
            <StopPropagation>
              <Checkbox checked={resolved} onChange={handleCheckboxClicked} />
            </StopPropagation>
          </RowItem>
        </Row>
        <Spacer size={1} />
        <RowItem style={{ flex: 1, minWidth: 0 }}>
          <ItemText
            style={{
              textDecoration: resolved ? 'line-through' : undefined,
              minWidth: 0,
            }}
          >
            <TaskOwner taskItem={taskItem} onAssign={onAssign} />
            <span style={{ wordWrap: 'break-word' }}>{taskItem.text} </span>
            {noteCount > 0 && (
              <span style={{ whiteSpace: 'nowrap' }}>
                <ItemInfoText>
                  {noteCount} {pluralize('note', noteCount)}
                </ItemInfoText>{' '}
                <ItemInfoText>•</ItemInfoText>{' '}
                <ItemInfoText>
                  {formatDistance(new Date(), lastUpdates[taskItem.id])} ago
                </ItemInfoText>
              </span>
            )}
          </ItemText>
        </RowItem>
      </SortableRow>
    </Tooltip>
  );
};

const PresenceAvatar = styled.div`
  position: absolute;
  top: 0;
  right: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  gap: 3px;
  font-size: 16px;
  color: ${complimentaryColor.string()};
`;
const ItemText = styled(Text)`
  line-height: 18px;
  margin-top: 2px;
`;
const ItemInfoText = styled(Small)`
  line-height: 16px;
  margin-top: 2px;
  color: #999;
`;
export const RowItem = styled.div`
  gap: 0 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const DragAnchor = styled(HolderOutlined)`
  opacity: 0;
  font-size: 18px;
  color: #888;
  cursor: grab;
`;
export const NewRow = styled(Row)`
  align-items: flex-start;
  margin: 2px 0 8px 0;
`;
const SortableRow = styled(NewRow)`
  min-height: 24px;

  &:hover {
    ${DragAnchor} {
      opacity: 1;
      cursor: grab;

      &:hover {
        color: #333;
      }
    }
  }
`;
