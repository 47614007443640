/* eslint-disable @typescript-eslint/ban-ts-comment */
// import TextArea from 'antd/lib/input/TextArea';
import linkifyHtml from 'linkify-html';
import Quill from 'quill';
import randomstring from 'randomstring';
import * as React from 'react';
import styled from 'styled-components';

import { TypographyCss } from './typography';

export interface RichTextAreaProps {
  initialValue?: string;
  onChange?: (newValue: string) => void;
  style?: React.CSSProperties;
  placeholder?: string;
  showToolbar?: boolean;
  autoFocus?: boolean;
  bounds?: string;
  disabled?: boolean;
  ref?: React.Ref<RichTextAreaRef>;
}

export interface RichTextAreaRef {
  setContents: (html: string) => void;
  clearContents: () => void;
}

// eslint-disable-next-line react/display-name
export const RichTextArea: React.FC<RichTextAreaProps> = React.forwardRef<
  RichTextAreaRef,
  RichTextAreaProps
>(
  (
    {
      initialValue,
      onChange,
      style,
      placeholder,
      showToolbar = false,
      autoFocus,
      bounds,
      disabled = false,
    },
    ref,
  ) => {
    const elementId = React.useMemo(
      () => `quill_${randomstring.generate(8)}`,
      [],
    );
    const elementRef = React.useRef<HTMLDivElement>();
    const [quill, setQuill] = React.useState<Quill>(null);
    React.useImperativeHandle(ref, () => ({
      setContents(newHtml: string) {
        const html = linkifyHtml(newHtml);
        const delta = quill.clipboard.convert({ html });
        quill.setContents(delta, 'silent');
      },
      clearContents() {
        const delta = quill.clipboard.convert({ html: '<p></p>' });
        quill.setContents(delta, 'silent');
      },
    }));
    React.useEffect(() => {
      if (elementRef.current && !quill) {
        const toolbarOptions = showToolbar
          ? [
              ['bold', 'italic'],
              ['link', 'blockquote'],
              [{ list: 'bullet' }, { list: 'ordered' }],
            ]
          : false;
        const quillInstance = new Quill(`#${elementId}`, {
          placeholder,
          theme: 'snow',
          bounds,
          readOnly: disabled,
          modules: {
            toolbar: toolbarOptions,
          },
        });
        if (initialValue) {
          const html = linkifyHtml(initialValue);
          const delta = quillInstance.clipboard.convert({ html });
          quillInstance.setContents(delta, 'silent');
        }
        quillInstance.on('text-change', () => {
          const newValue = getQuillHtml(quillInstance);
          onChange(newValue);
        });
        setQuill(quillInstance);
      }
    }, [elementRef.current]);
    React.useEffect(() => {
      if (autoFocus && quill) {
        quill.focus();
      }
    }, [quill, autoFocus]);

    return (
      <Container hideToolbar={!showToolbar}>
        <div
          id={elementId}
          ref={elementRef}
          spellCheck={false}
          style={style}
        ></div>
      </Container>
    );
  },
);

const getQuillHtml = (quill?: Quill) => {
  if (!quill) {
    return;
  }

  const html = quill.editor.getHTML(0, quill.getLength());
  return html;
};

const Container = styled.div<{ hideToolbar?: boolean; disabled?: boolean }>`
  .ql-container.ql-snow {
    background: white;

    .ql-editor {
      ${TypographyCss.Text}
    }
    ${(props) =>
      props.hideToolbar ? 'border-radius: var(--default-border-radius)' : ''}
  }
`;
