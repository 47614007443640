import { useGoalLists } from '@client/GoalsClient';
import { GoalListToken, IGoalList } from '@shared/goals';
import { Select } from 'antd';
import * as React from 'react';

interface Option {
  label: string;
  value: GoalListToken;
}

const createOptions = (lists: IGoalList[]) => {
  return (
    lists?.map((list) => ({
      value: list.token,
      label: list.title,
    })) ?? []
  );
};

export const SelectGoalLists: React.FC<{
  disabled?: boolean;
  initialLists?: IGoalList[];
  onChange?: (newValue: GoalListToken[]) => void;
  style?: React.CSSProperties;
  placeholder?: string;
}> = ({ onChange, style, placeholder, initialLists = [] }) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>(
    createOptions(initialLists),
  );
  const { data: goalLists } = useGoalLists();

  const handleSelectChange = async (newOptions: Option[]) => {
    const newTokens = newOptions.map((option) => option.value);
    setSearchQuery('');
    setSelectedOptions(newOptions);
    onChange(newTokens);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      setSearchQuery('');
    }
  };

  const handleSearch = (search: string) => {
    setSearchQuery(search ?? '');
  };

  const options = createOptions(goalLists).filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <Select
      placeholder={placeholder}
      showSearch
      mode="multiple"
      notFoundContent={null}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      value={selectedOptions}
      labelInValue
      onSearch={handleSearch}
      style={style}
      options={options}
      filterOption={false}
      onChange={handleSelectChange}
      choiceTransitionName=""
      transitionName=""
      animation=""
      defaultActiveFirstOption={false}
    />
  );
};
