import {
  CheckOutlined,
  CloseOutlined,
  FrownOutlined,
  MehOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import { GoalState } from '@shared/goals';
import {
  complimentaryColor,
  errorColor,
  successColor,
  warningColor,
} from '@web/app/styles/ColorStyles';
import { Small } from '@web/components/typography';
import { Tooltip } from 'antd';
import Color from 'color';
import * as React from 'react';
import styled from 'styled-components';

export const GoalStateIndicator: React.FC<{
  state?: GoalState;
  hideLabel?: boolean;
}> = ({ state, hideLabel = false }) => {
  const GoalIcon = Icons[state];
  return (
    <GoalStateContainer
      style={{
        borderColor: Color(BackgroundColors[state]).darken(0.05),
        backgroundColor: BackgroundColors[state],
        minWidth: hideLabel ? 'unset' : 120,
      }}
    >
      {GoalIcon}
      {!hideLabel && (
        <Small style={{ whiteSpace: 'nowrap' }}>{Labels[state]}</Small>
      )}
    </GoalStateContainer>
  );
};

export const MiniGoalStateIndicator: React.FC<{
  state?: GoalState;
}> = ({ state }) => {
  const GoalIcon = Icons[state];
  return (
    <Tooltip title={Labels[state]}>
      <MiniGoalStateContainer
        style={{
          borderColor: Color(BackgroundColors[state]).darken(0.05),
          backgroundColor: BackgroundColors[state],
        }}
      >
        {GoalIcon}
      </MiniGoalStateContainer>
    </Tooltip>
  );
};

const GoalStateContainer = styled.div`
  padding: 0 6px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  border: 1px solid transparent;
`;

const MiniGoalStateContainer = styled(GoalStateContainer)`
  height: 24px;
  padding: 0 3px;
`;

const CircleOutlined = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid #888;
  border-radius: 8px;
`;

const Icons: Record<GoalState, React.ReactNode> = {
  [GoalState.OPEN]: <CircleOutlined />,
  [GoalState.ON_TRACK]: (
    <SmileOutlined style={{ fontSize: 16, color: successColor.string() }} />
  ),
  [GoalState.AT_RISK]: (
    <MehOutlined
      style={{
        fontSize: 16,
        color: warningColor.darken(0.5),
        top: -1,
        position: 'relative',
      }}
    />
  ),
  [GoalState.OFF_TRACK]: (
    <FrownOutlined
      style={{
        fontSize: 16,
        color: errorColor.darken(0.2),
      }}
    />
  ),
  [GoalState.COMPLETED]: (
    <CheckOutlined
      style={{ fontSize: 16, color: complimentaryColor.string() }}
    />
  ),
  [GoalState.OBSOLETE]: (
    <CloseOutlined style={{ fontSize: 16, color: '#666' }} />
  ),
};

const Labels: Record<GoalState, string> = {
  [GoalState.OPEN]: 'Open',
  [GoalState.ON_TRACK]: 'On Track',
  [GoalState.AT_RISK]: 'At Risk',
  [GoalState.OFF_TRACK]: 'Off Track',
  [GoalState.COMPLETED]: 'Complete',
  [GoalState.OBSOLETE]: 'Not Planned',
};

const BackgroundColors: Record<GoalState, string> = {
  [GoalState.OPEN]: '#f4f4f4',
  [GoalState.ON_TRACK]: successColor.lighten(0.9),
  [GoalState.AT_RISK]: warningColor.lighten(0.2),
  [GoalState.OFF_TRACK]: errorColor.lighten(0.5),
  [GoalState.COMPLETED]: complimentaryColor.lighten(0.9),
  [GoalState.OBSOLETE]: '#f4f4f4',
};
