import { ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatDate } from '@shared/formatDate';
import { IUser } from '@shared/types';
import { patch } from '@web/common/api';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Button, Modal, Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const DateField: React.FC<{
  label: string;
  property: keyof IUser;
  user: IUser;
  onChange?: () => void;
  extra?: React.ReactNode;
}> = ({ label, property, user, onChange, extra }) => {
  const [modal, contextHolder] = Modal.useModal();

  const handleSetToNow = async () => {
    const proceed = await modal.confirm({
      icon: null,
      title: 'Are you sure?',
      content: `This will update this user's ${label.toLowerCase()} to right now.`,
    });
    if (!proceed) {
      return;
    }

    await patch<IUser>(`/users/${user.token}`, {
      [property]: new Date(),
    });
    onChange();
  };

  const handleRemove = async () => {
    const proceed = await modal.confirm({
      icon: null,
      title: 'Are you sure?',
      content: `This will remove this user's ${label.toLowerCase()} value.`,
    });
    if (!proceed) {
      return;
    }

    await patch<IUser>(`/users/${user.token}`, {
      [property]: null,
    });
    onChange();
  };

  const date: string = user[property] as any;
  return (
    <DateFieldContainer>
      <Text style={{ width: 160 }}>{label}</Text>
      <Text style={{ width: 160 }}>
        {date ? formatDate(date, true) : 'n/a'}
      </Text>
      {onChange && (
        <Row>
          <Tooltip title={`Set ${label.toLowerCase()} to now`}>
            <Button type="text" onClick={handleSetToNow}>
              <ClockCircleOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={`Remove ${label.toLowerCase()}`}>
            <Button type="text" onClick={handleRemove}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
          {extra}
        </Row>
      )}
      {contextHolder}
    </DateFieldContainer>
  );
};
const DateFieldContainer = styled(Row)`
  gap: 0;
  margin: 0;
  border-bottom: 1px solid #eee;
  padding: 12px;

  &:hover {
    background-color: #f9f9f9;
  }

  &:last-child {
    border-bottom: 0;
  }
`;
