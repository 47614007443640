import {
  ALIGNMENT_DESCRIPTION_TEXT,
  ALIGNMENT_INDICATOR_NAMES,
  IAlignmentIndicator,
} from '@shared/alignment';
import { isManagerOf } from '@shared/users';
import { AlignmentStateIndicator } from '@web/1on1s/AlignmentStateIndicator';
import { useAuth } from '@web/auth/useAuth';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import React from 'react';
import styled from 'styled-components';

import { AlignmentCallToAction } from './AlignmentCallToAction';

export const AlignmentStatus: React.FC<{
  indicator: IAlignmentIndicator;
  style?: React.CSSProperties;
}> = ({ indicator, style }) => {
  const { user } = useAuth();
  return (
    <Column style={style}>
      <Row>
        <Header2>{ALIGNMENT_INDICATOR_NAMES[indicator.type]}</Header2>
        <GrowingSpacer />
        <AlignmentStateIndicator state={indicator.state} />
      </Row>
      <Spacer size={6} />
      <StyledText>{ALIGNMENT_DESCRIPTION_TEXT[indicator.type]}</StyledText>
      <Spacer size={14} />
      <AlignmentCallToAction
        isIndicatorUser={user.token === indicator.userToken}
        isManager={isManagerOf(indicator.user, user.token)}
        indicator={indicator}
      />
    </Column>
  );
};

const StyledText = styled(Text)`
  line-height: 20px;
`;
