import { IUser, UserRole, UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { patch } from '@web/common/api';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUser } from '@web/components/users/SelectUser';
import { Modal, message } from 'antd';
import React, { useState } from 'react';

interface Props {
  onCancel: () => void;
  onAdd: (userToken: UserToken) => void;
}

export const AdminAddUsersFromSlackModal: React.FC<Props> = ({
  onAdd,
  onCancel,
}) => {
  const { user } = useAuth();
  const [userToken, setUserToken] = useState<UserToken | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);
    try {
      const userFields = { role: UserRole.ENROLLED };
      await patch<IUser>(`/users/${userToken}`, userFields);
      void message.success('Success');
      onAdd(userToken);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleUserSelected = (userToken: UserToken) => {
    setUserToken(userToken);
  };

  return (
    <Modal
      title="Add User"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Add"
      okButtonProps={{
        disabled: !userToken,
      }}
    >
      <Column gap={12}>
        <Text>Select a user to add.</Text>
        <SelectUser
          style={{ width: '100%' }}
          roleFilter={UserRole.EMPLOYEE}
          forEnrollment={true}
          organizationToken={user.organizationToken}
          onChange={handleUserSelected}
          allowClear={true}
        />
      </Column>
    </Modal>
  );
};
