import { EditOutlined } from '@ant-design/icons';
import { updateGoal } from '@client/GoalsClient';
import { IGoal } from '@shared/goals';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { message } from 'antd';
import * as React from 'react';

import { SelectGoalModal } from './SelectGoalModal';
import { ViewGoal } from './ViewGoal';

export const GoalParents: React.FC<{
  goal: IGoal;
  onChange: () => void;
}> = ({ goal, onChange }) => {
  const parent = goal?.parents?.length > 0 ? goal.parents[0] : null;
  const [showEdit, setShowEdit] = React.useState(false);

  const handleSave = async (parent: IGoal | null) => {
    try {
      await updateGoal({ parent: parent ? parent.token : null }, goal.token);
      onChange();
      setShowEdit(false);
    } catch (error) {
      void message.error('Error updating goal');
    }
  };
  return (
    <Row style={{ alignItems: 'flex-start' }}>
      <Text style={{ minWidth: 120 }}>
        Parent{' '}
        {goal.isPublic && (
          <EditOutlined
            onClick={() => {
              setShowEdit(true);
            }}
          />
        )}
      </Text>
      {parent ? <ViewGoal goal={parent} hideDueDate /> : <Text>None</Text>}
      {showEdit && (
        <SelectGoalModal
          title="Update Parent Goal"
          instructions="Select the parent for this goal."
          placeholder="No parent"
          okText="Save"
          initialGoal={parent}
          onSave={handleSave}
          onCancel={() => {
            setShowEdit(false);
          }}
          omitGoals={[goal.token]}
        />
      )}
    </Row>
  );
};
