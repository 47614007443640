import { SearchOutlined } from '@ant-design/icons';
import { IUser, UserRole, UserToken } from '@shared/types';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column } from '@web/components/layout';
import { Small } from '@web/components/typography';
import { useSelectUserSearch } from '@web/components/users/useSelectUserSearch';
import { Button, Input, InputRef, Modal, Row } from 'antd';
import { indexOf } from 'lodash';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  onSelect: (user: IUser) => void;
  children: React.ReactNode;
  buttonStyle?: React.CSSProperties;
  modalTitle?: string;
  icon?: React.ReactNode;
  omitUserTokens?: UserToken[];
  roleFilter?: UserRole;
  forEnrollment?: boolean;
}

export const SelectUserButton: React.FC<Props> = ({
  onSelect,
  children,
  buttonStyle,
  modalTitle,
  icon,
  omitUserTokens,
  roleFilter,
  forEnrollment,
}) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleUserSelected = (user: IUser) => {
    onSelect(user);
    setOpen(false);
  };

  return (
    <>
      <Button
        icon={icon}
        type="default"
        onClick={showModal}
        style={buttonStyle}
      >
        {children}
      </Button>
      {open && (
        <AddUserModal
          onSelect={handleUserSelected}
          onCancel={handleCancel}
          title={modalTitle}
          omitUserTokens={omitUserTokens}
          roleFilter={roleFilter}
          forEnrollment={forEnrollment}
        />
      )}
    </>
  );
};

interface AddUserModalProps {
  onCancel: () => void;
  onSelect: (user: IUser) => void;
  title?: string;
  omitUserTokens?: UserToken[];
  roleFilter?: UserRole;
  forEnrollment?: boolean;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  onSelect,
  onCancel,
  title = 'Select a user',
  omitUserTokens,
  roleFilter,
  forEnrollment,
}) => {
  const searchInputRef = useRef<InputRef>();
  const [selectedUser, setSelectedUser] = React.useState<IUser>(null);
  const [query, setQuery] = React.useState('');
  const { users, search } = useSelectUserSearch({
    allowEmptySearch: true,
    omitUserTokens,
    roleFilter,
    forEnrollment,
  });
  React.useEffect(() => {
    if (searchInputRef) {
      searchInputRef.current.focus();
    }
  }, [searchInputRef]);
  React.useEffect(() => {
    void search(query);
  }, [query, omitUserTokens]);
  React.useEffect(() => {
    if (users?.length === 1) {
      setSelectedUser(users[0]);
    }
  }, [users]);

  const handleClose = () => {
    onCancel();
    setQuery('');
  };

  const handleSelect = (user: IUser) => {
    if (user.token === selectedUser?.token) {
      setSelectedUser(null);
    } else {
      setSelectedUser(user);
    }
  };

  const handleOk = () => {
    onSelect(selectedUser);
    setSelectedUser(null);
    setQuery('');
  };
  const handleArrowUp = () => {
    if (!users || users.length === 0) {
      return;
    }

    let newSelectedUser: IUser;
    if (!selectedUser) {
      newSelectedUser = users[users.length - 1];
    } else {
      const currentIndex = indexOf(users, selectedUser);
      newSelectedUser =
        currentIndex - 1 === 0
          ? users[users.length - 1]
          : users[currentIndex - 1];
    }

    if (newSelectedUser) {
      selectUser(newSelectedUser);
    }
  };
  const handleArrowDown = () => {
    if (!users || users.length === 0) {
      return;
    }
    let newSelectedUser: IUser;
    if (!selectedUser) {
      newSelectedUser = users[0];
    } else {
      const currentIndex = indexOf(users, selectedUser);
      newSelectedUser =
        currentIndex + 1 === users.length ? users[0] : users[currentIndex + 1];
    }

    if (newSelectedUser) {
      selectUser(newSelectedUser);
    }
  };

  const selectUser = (user: IUser) => {
    setSelectedUser(user);
    document
      .querySelector(`[data-user-result="${user.token}"]`)
      ?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Modal
      title={title}
      open={true}
      afterClose={handleClose}
      onCancel={onCancel}
      width="600px"
      okButtonProps={{
        disabled: !selectedUser,
        style: { width: 100 },
      }}
      cancelButtonProps={{ style: { width: 100 } }}
      onOk={handleOk}
    >
      <Column
        gap={12}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleOk();
          } else if (e.key === 'ArrowUp') {
            handleArrowUp();
          } else if (e.key === 'ArrowDown') {
            handleArrowDown();
          }
        }}
      >
        <Input
          value={query}
          onChange={(e) => {
            setQuery(e.currentTarget.value);
          }}
          autoFocus
          allowClear
          prefix={<SearchOutlined />}
          ref={searchInputRef}
        />
        <Results>
          {users.map((user) => (
            <UserResult
              key={user.token}
              data-user-result={user.token}
              onClick={() => {
                handleSelect(user);
              }}
              className={user.token === selectedUser?.token ? 'selected' : null}
            >
              <UserAvatar user={user} size={20} /> {user.name}{' '}
              <Small>{user.email}</Small>
            </UserResult>
          ))}
        </Results>
      </Column>
    </Modal>
  );
};

const Results = styled(Column)`
  border: 1px solid #ddd;
  border-radius: var(--default-border-radius);
  height: 182px;
  overflow: auto;
`;
const UserResult = styled(Row)`
  padding: 6px 12px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0 12px;

  &:hover {
    background: ${complimentaryColor.lighten(0.9)};
  }

  &.selected {
    background: ${complimentaryColor.lighten(0.7)};
  }
`;
