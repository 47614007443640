import { Modal } from 'antd';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const LoadingModal: React.FC<Props> = ({ children }) => {
  return (
    <Modal
      title={null}
      open={true}
      footer={null}
      width="360px"
      closeIcon={null}
    >
      {children}
    </Modal>
  );
};
