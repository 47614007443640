import * as React from 'react';
import {
  Params,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { URLSearchParams } from 'url';

export const Redirect: React.FC<{
  to: (params: Params<string>, searchParams: URLSearchParams) => string;
}> = ({ to }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(to(params, searchParams), { replace: true });
  }, [params, to]);
  return null;
};
