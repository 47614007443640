import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { FeedbackList } from './FeedbackList/FeedbackList';
import { useRecentFeedback } from './useRecentFeedback';

export const ViewGivenFeedbackPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { feedbackResponse, impactEntryMap, isLoading } = useRecentFeedback(
    undefined,
    searchParams.get('reviewCycle') as any,
  );
  return (
    <PageContent>
      <PageHeader
        title="Feedback given"
        mobileTitle="Feedback given"
        navigateBack
        defaultNavigateBackTo={'/feedback'}
      />
      {isLoading || !feedbackResponse ? (
        <Skeleton />
      ) : (
        <FeedbackList
          direction="Given"
          feedbackResponse={feedbackResponse}
          impactEntryMap={impactEntryMap}
        />
      )}
    </PageContent>
  );
};
