import { UserToken } from './types';

interface IUserLike {
  token?: UserToken;
  managerTokens?: UserToken[];
}

export const hasManager = (user: IUserLike) => {
  if (!user?.managerTokens) {
    return false;
  }

  return user.managerTokens.length > 0;
};

export const isManagerOf = (user: IUserLike, managerToken: UserToken) => {
  if (!user?.managerTokens) {
    return false;
  }

  return user.managerTokens.includes(managerToken);
};

export const isPrimaryManagerOf = (
  user: IUserLike,
  managerToken: UserToken,
) => {
  return primaryManagerTokenOf(user) === managerToken;
};

export const primaryManagerTokenOf = (user: IUserLike): UserToken | null => {
  if (!hasManager(user)) {
    return null;
  }

  return user.managerTokens?.[0] ?? null;
};
export const otherManagerTokensOf = (user: IUserLike): UserToken[] | null => {
  if (!hasManager(user)) {
    return null;
  }

  return user.managerTokens?.slice(1) ?? null;
};
export const primaryManager = <T extends IUserLike>(managers?: T[]) =>
  managers?.[0];
