import { DownOutlined } from '@ant-design/icons';
import { UserMapItem, UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { UserAvatar } from '@web/components/UserAvatar';
import { Button, Dropdown, MenuProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Row } from './layout';
import { Text } from './text';
import { SubduedText } from './typography';

export interface UserPickerProps {
  users: UserMapItem[];
  selectedUserToken: UserToken;
  onChange?: (userToken: UserToken) => void;
}

export const UserPicker: React.FC<UserPickerProps> = ({
  onChange,
  users,
  selectedUserToken,
}) => {
  if (users.length === 0) {
    return null;
  }

  const selectedUser = users.find((user) => user.token === selectedUserToken);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    onChange?.(key as any);
  };

  const items = users
    .filter((user) => user.token !== selectedUserToken)
    .map((user) => ({
      label: <UserPickerLabel user={user} />,
      key: user.token,
    }));

  return (
    <Dropdown
      menu={{ items, onClick }}
      trigger={['click']}
      placement="bottomCenter"
      arrow
    >
      <ButtonContainer>
        <Row gap={6}>
          <UserPickerLabel user={selectedUser} />
          <DownOutlined />
        </Row>
      </ButtonContainer>
    </Dropdown>
  );
};

export const UserPickerLabel: React.FC<{ user: UserMapItem }> = ({ user }) => {
  const { user: currentUser } = useAuth();
  const isSelf = currentUser.token === user.token;

  return (
    <Row gap={6} style={{ minWidth: 180, height: 30 }}>
      <UserAvatar size={20} user={user} />
      <Text style={{ fontSize: 12 }}>{user.name}</Text>
      {isSelf && <SubduedText style={{ fontSize: 12 }}>You</SubduedText>}
    </Row>
  );
};

const ButtonContainer = styled(Button)`
  height: auto;
  padding: 2px 14px;
`;
