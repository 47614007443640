import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { updateEntityNote } from '@client/OneOnOnesClient';
import { IComment } from '@shared/comments';
import { OneOnOneToken } from '@shared/one-on-one';
import { useAuth } from '@web/auth/useAuth';
import { Button, Tooltip, message } from 'antd';
import linkifyHtml from 'linkify-html';
import * as React from 'react';
import styled from 'styled-components';

import { TypographyCss } from '../../components/typography';
import { InputNote } from './InputNote';

export const ViewEditNote: React.FC<{
  oneOnOneToken: OneOnOneToken;
  onDelete: (token: string) => void;
  onEditModeChange: () => void;
  note: IComment;
}> = ({ note, onDelete, oneOnOneToken, onEditModeChange }) => {
  const { user } = useAuth();
  const [editing, setEditing] = React.useState(false);
  const handleSaveNote = async (newText: string) => {
    try {
      await updateEntityNote(
        oneOnOneToken,
        note.entityToken as any,
        note.token,
        newText,
      );
      setEditing(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  if (editing) {
    return (
      <EditNoteContainer>
        <InputNote
          initialValue={note.text}
          onSubmit={handleSaveNote}
          autoFocus
          onCancel={() => {
            setEditing(false);
          }}
        />
      </EditNoteContainer>
    );
  }

  const isNoteAuthor = note.authorToken === user.token;

  return (
    <ViewNoteContainer>
      {isNoteAuthor && (
        <FloatingButtons>
          <Tooltip title="Edit note">
            <Button
              type="text"
              size="small"
              onClick={() => {
                setEditing(true);
                setTimeout(() => {
                  onEditModeChange?.();
                }, 100);
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Delete note">
            <Button
              type="text"
              size="small"
              onClick={() => {
                onDelete(note.token);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </FloatingButtons>
      )}
      <NoteHtml
        dangerouslySetInnerHTML={{
          __html: linkifyHtml(note.text, { target: '_blank' }),
        }}
      />
    </ViewNoteContainer>
  );
};
const FloatingButtons = styled.section`
  position: absolute;
  top: -14px;
  right: 6px;
  border: 1px solid #ddd;
  border-radius: var(--default-border-radius);
  background: white;
  display: none;
  padding: 2px;
`;
const ViewNoteContainer = styled.section`
  position: relative;
  margin-left: 6px;
  padding-left: 6px;
  border-radius: var(--default-border-radius);

  &:hover {
    background: #f5f5f5;

    ${FloatingButtons} {
      display: block;
    }
  }
`;
const EditNoteContainer = styled.section`
  margin-left: 6px;
  padding-left: 6px;
`;
const NoteHtml = styled.section`
  ${TypographyCss.Text}
  line-height: 20px;
  padding-top: 2px;

  p,
  ul,
  ol {
    margin-bottom: 0px;
    word-wrap: break-word;
    min-height: 24px;
  }

  a {
    color: var(--primary-color);
  }

  *:last-child {
    margin-bottom: 0;
  }
`;
