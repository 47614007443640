import { Checkbox, Typography } from 'antd';
import * as React from 'react';

import { Row } from './layout';

export const LabelledCheckbox: React.FC<{
  value?: boolean;
  label: string;
  onChange?: (newValue: boolean) => void;
}> = ({ value, label, onChange }) => {
  return (
    <Row
      style={{ gap: 10, cursor: 'pointer', alignItems: 'flex-start' }}
      onClick={() => {
        onChange?.(!value);
      }}
    >
      <Checkbox checked={value} />
      {label && <Typography.Text>{label}</Typography.Text>}
    </Row>
  );
};
