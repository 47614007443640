import { InputRef } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';

export const InlineInput: React.FC<{
  value: string;
  onChange: (newValue: string) => void;
  style?: React.CSSProperties;
  autofocus?: boolean;
  onEnter?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
}> = ({
  value,
  onChange,
  style = {},
  autofocus = false,
  onEnter,
  onBlur,
  placeholder,
  disabled,
}) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<InputRef>();

  React.useLayoutEffect(() => {
    if (inputRef.current && autofocus) {
      setEditing(true);
      setTimeout(() => {
        inputRef.current.focus();
      });
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && onEnter) {
      e.stopPropagation();
      e.preventDefault();
      onEnter?.();
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      inputRef.current?.blur();
    }
  };

  const handleBlur = () => {
    setEditing(false);
    onBlur?.();
  };

  return (
    <TextArea
      autoSize
      ref={inputRef}
      value={value}
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={() => {
        setEditing(true);
      }}
      placeholder={placeholder}
      bordered={editing}
      style={editing ? style : { ...style, border: '1px solid transparent' }}
      spellCheck={false}
      disabled={disabled}
    />
  );
};
