import { GithubOutlined } from '@ant-design/icons';
import { Logo } from '@web/app/Logo/Logo';
import { IF_MOBILE, useResponsive } from '@web/app/responsive';
import useFetch from '@web/common/useFetch';
import { Center, Spacer } from '@web/components/layout';
import { Button, Row, Typography } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const InstallGithubPage: React.FC = () => {
  const { data: installGithubUrl } = useFetch<{ url: string }>(
    '/github/install',
  );
  const { isMobile } = useResponsive();

  if (!installGithubUrl) {
    return null;
  }

  return (
    <PageContainer>
      <Spacer size={!isMobile ? 100 : 0} />
      <Center>
        <PagePane>
          <Row style={{ alignItems: 'center' }}>
            <Logo />
            <Spacer size={20} />
            <Typography.Title level={3} style={{ margin: 0 }}>
              +
            </Typography.Title>
            <Spacer size={20} />
            <GithubOutlined style={{ fontSize: '28px' }} />
          </Row>
          <Spacer size={20} />
          <Typography.Title level={5}>Welcome!</Typography.Title>
          <Typography.Text>
            Get started by connecting Flint to your Github organization
          </Typography.Text>
          <Spacer size={20} />
          <a href={installGithubUrl.url}>
            <Button type="default">
              <Typography.Title level={5}>Add to Github</Typography.Title>
            </Button>
          </a>
          <Spacer size={40} />
        </PagePane>
      </Center>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  background: white;
  height: 100vh;

  ${IF_MOBILE} {
    background: white;
  }
`;

const PagePane = styled.section`
  width: 700px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-btn {
    width: 250px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    h5 {
      margin: 0;
    }
  }

  ${IF_MOBILE} {
    box-shadow: none;
    border: 0;
  }
`;
