/* eslint-disable @typescript-eslint/naming-convention */
import { CloseOutlined } from '@ant-design/icons';
import { GithubPullToken, IGithubPull } from '@shared/github';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button } from 'antd';
import React from 'react';

import { PullRequestItem } from '../../components/PullRequestItem';

export const WorkItems: React.FC<{
  pulls: IGithubPull[];
  onRemove: (token: GithubPullToken) => void;
}> = ({ pulls, onRemove }) => {
  return (
    <Column gap={12} style={{ alignItems: 'flex-start' }}>
      {pulls.length === 0 && <Text>No pull requests selected</Text>}
      {pulls.map((pull) => (
        <WorkItem
          pull={pull}
          key={pull.token}
          onRemove={() => {
            onRemove(pull.token);
          }}
        />
      ))}
    </Column>
  );
};

export const WorkItem: React.FC<{
  pull: IGithubPull;
  onRemove?: () => void;
  onClick?: () => void;
}> = ({ pull, onRemove, onClick }) => {
  return (
    <Row gap={6} onClick={onClick}>
      <a href={pull.url} target="_blank" rel="noreferrer">
        <PullRequestItem pull={pull} />
      </a>
      {onRemove && (
        <Button
          type="text"
          size="small"
          onClick={() => {
            onRemove();
          }}
        >
          <CloseOutlined />
        </Button>
      )}
    </Row>
  );
};
