import { useTeamCheckins } from '@client/SurveyCyclesClient';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Skeleton } from 'antd';
import * as React from 'react';

import { CheckinsTable } from './CheckinsTable';

export const CheckinsPage: React.FC = () => {
  const { data: participants } = useTeamCheckins();

  return (
    <PageContent>
      <PageHeader title="Check-ins" mobileTitle="Check-ins" />
      {participants ? (
        <CheckinsTable participants={participants} />
      ) : (
        <Skeleton />
      )}
    </PageContent>
  );
};
